import React, { memo } from "react";
import QRCode from "react-qr-code";

import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Autocomplete,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

import { useMutation, useLazyQuery, useReactiveVar } from "@apollo/client";

import { apiUrl } from "../../../../../config";
import {
  onlineVar,
  permissionsVar,
} from "../../../../../graphql/localVariables/user";
import { EDIT_SITE } from "../../../../../graphql/mutations/admin/planning-site";
import { GET_SITE_INFO } from "../../../../../graphql/queries/admin/planning-site";
import removeDuplicates from "../../../../../utils/removeDuplicates";
import { states } from "../../../../../utils/states";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../../CustomComponents/OnErrorFunction";
import { CustomSwitch } from "../../../../CustomComponents/Switch";
import { StyledTab, StyledTabs } from "../../../../CustomComponents/Tabs";
import { CustomTextField } from "../../../../CustomStyles/LightTextField";
import { yellowButton } from "../../../../CustomStyles/buttons";
import { CustomDialog } from "../../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../../CustomStyles/pageTitle";
import { COMPANY_ADMIN_OSP } from "../../../Company/companyAdminQueries";
import {
  ContractorAccordion,
  CrewAccordion,
  TemplateAccordion,
  UserAccordion,
} from "./SiteAccordions";

const EditSite = memo(function EditSite({
  row,
  open,
  handleClose,
  company,
  siteData,
}) {
  const online = useReactiveVar(onlineVar);
  const permissions = useReactiveVar(permissionsVar);
  const [getSiteInfo, { data: site, loading }] = useLazyQuery(GET_SITE_INFO);
  const [getCompanyContractors, { data: companyContractors }] =
    useLazyQuery(COMPANY_ADMIN_OSP);
  const [getParentContractors, { data: parentContractors }] =
    useLazyQuery(COMPANY_ADMIN_OSP);

  const [contractors, setContractors] = React.useState([]);

  const siteId = row.site ? row.site.id : row.id;

  React.useEffect(() => {
    if (open) {
      getSiteInfo({
        variables: { siteId: Number(siteId) },
        fetchPolicy: online ? "network-only" : "cache-only",
      });
      if (company) {
        getCompanyContractors({
          variables: { id: Number(company.id) },
          fetchPolicy: "network-only",
        });
        if (company.parent) {
          getParentContractors({
            variables: { id: Number(company.parent.id) },
            fetchPolicy: "network-only",
          });
        }
      }
    }
  }, [open]);

  React.useEffect(() => {
    const removeDuplicateContractors = (contractors) => {
      const uniqueIds = [];
      const filtered = contractors.filter((c) => {
        const isDuplicate = uniqueIds.includes(c.contractor.id);
        if (!isDuplicate) {
          uniqueIds.push(c.contractor.id);
          return true;
        }
        return false;
      });
      return filtered;
    };

    if (company) {
      let contractors = [];
      if (companyContractors) {
        companyContractors.ownerSiteProjects.forEach((d) => {
          contractors = [
            ...contractors,
            ...d.ownerSiteProjectContractor.map((ospc) => ({
              owner: d.owner,
              ...ospc,
            })),
          ];
        });
      }

      if (parentContractors) {
        parentContractors.ownerSiteProjects.forEach((d) => {
          contractors = [
            ...contractors,
            ...d.ownerSiteProjectContractor.map((ospc) => ({
              owner: d.owner,
              ...ospc,
            })),
          ];
        });
      }

      contractors = removeDuplicateContractors(contractors).filter(
        (c) => c.contractor.id !== company.id
      );
      setContractors(contractors.map((c) => c.contractor));
    }
  }, [companyContractors, parentContractors]);

  const [editSite, { loading: loadingEdit }] = useMutation(EDIT_SITE, {
    onCompleted() {
      onClear();
      handleClose();
      onCompletedFunc("Site has been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [
      company && {
        query: COMPANY_ADMIN_OSP,
        variables: { id: Number(company.id) },
      },
      { query: GET_SITE_INFO, variables: { siteId: Number(siteId) } },
    ],
  });

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [editDisabled, setEditDisabled] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [owner, setOwner] = React.useState(null);
  const [name, setName] = React.useState("");
  const [addr1, setAddr1] = React.useState("");
  const [addr2, setAddr2] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [zip, setZip] = React.useState("");
  const [note, setNote] = React.useState("");

  const [siteUsers, setSiteUsers] = React.useState([]);
  const [addedUsers, setAddedUsers] = React.useState([]);
  const [removedUsers, setRemovedUsers] = React.useState([]);
  const [supervisors, setSupervisors] = React.useState([]);

  const [siteContractors, setSiteContractors] = React.useState([]);
  const [addedContractors, setAddedContractors] = React.useState([]);
  const [removedContractors, setRemovedContractors] = React.useState([]);

  const [siteCrew, setSiteCrew] = React.useState([]);
  const [addedCrew, setAddedCrew] = React.useState([]);
  const [removedCrew, setRemovedCrew] = React.useState([]);

  const [siteTemplates, setSiteTemplates] = React.useState([]);
  const [addedTemplates, setAddedTemplates] = React.useState([]);
  const [removedTemplates, setRemovedTemplates] = React.useState([]);

  React.useEffect(() => {
    if (!permissions.includes("EDIT_SITE")) {
      setEditDisabled(true);
    }
  }, []);

  React.useEffect(() => {
    if (site) {
      setIsActive(site.site.isActive);
      setName(site.site.name);
      setAddr1(site.site.addrLine1);
      setAddr2(site.site.addrLine2);
      setCity(site.site.addrCity);
      setState(site.site.addrState);
      setZip(site.site.addrZip);
      setNote(site.site.note);
      if (site.ownerSiteProjects.length > 0) {
        const owner = company
          ? site.ownerSiteProjects.find(
              (osp) => Number(osp.owner.id) === Number(company.id)
            )
          : site.ownerSiteProjects[0];
        if (owner) {
          setOwner(owner.owner);
          setSiteUsers(owner.ownerSiteProjectUser.filter((o) => o.isActive));
          setSupervisors(
            owner.supervision
              .filter((s) => s.isActive)
              .map((s) => Number(s.supervisor.id))
          );
          setSiteContractors(
            owner.ownerSiteProjectContractor.filter((o) => o.isActive)
          );
          setSiteCrew(
            owner.ownerSiteProjectCrewMember.filter((o) => o.isActive)
          );
          setSiteTemplates(
            owner.ownerSiteProjectTemplate.filter((o) => o.isActive)
          );
        }
      }
    }
  }, [site]);

  const onEdit = () => {
    if (owner && owner.name !== "" && name !== "" && state !== "" && state) {
      editSite({
        variables: {
          isActive,
          addrCity: city,
          addrLine1: addr1,
          addrLine2: addr2,
          addrState: state,
          addrZip: zip,
          company: Number(owner.id),
          id: Number(siteId),
          name: name,
          note,
          associatedUsers: [...siteUsers.map((u) => u.user), ...addedUsers].map(
            (u) => Number(u.id)
          ),
          unassociatedUsers: removedUsers.map((u) => Number(u.user.id)),
          supervisors,
          associatedTemplates: [
            ...siteTemplates.map((t) => t.template),
            ...addedTemplates,
          ].map((t) => Number(t.id)),
          unassociatedTemplates: removedTemplates.map((t) => Number(t.id)),
          associatedCrewMembers: [
            ...siteCrew.map((c) => c.crewMember),
            ...addedCrew,
          ].map((c) => Number(c.id)),
          unassociatedCrewMembers: removedCrew.map((c) =>
            Number(c.crewMember.id)
          ),
          associatedContractors: [
            ...siteContractors.map((c) => c.contractor),
            ...addedContractors,
          ].map((c) => Number(c.id)),
          unassociatedContractors: removedContractors.map((c) =>
            Number(c.contractor.id)
          ),
        },
      });
    } else {
      onErrorFunc(
        "Make sure to select a site owner, name and state for your site."
      );
    }
  };

  const onCancel = () => {
    handleClose();
    onClear();
  };

  const onClear = () => {
    if (site) {
      setIsActive(site.site.isActive);
      setName(site.site.name);
      setAddr1(site.site.addrLine1);
      setAddr2(site.site.addrLine2);
      setCity(site.site.addrCity);
      setState(site.site.addrState);
      setZip(site.site.addrZip);
      setNote(site.site.note);
      setOwner(site.ownerSiteProjects[0].owner);

      setSiteUsers(
        site.ownerSiteProjects[0].ownerSiteProjectUser.filter((o) => o.isActive)
      );
      setSupervisors(
        site.ownerSiteProjects[0].supervision.map((s) =>
          Number(s.supervisor.id)
        )
      );
      setSiteContractors(
        site.ownerSiteProjects[0].ownerSiteProjectContractor.filter(
          (o) => o.isActive
        )
      );
      setSiteCrew(
        site.ownerSiteProjects[0].ownerSiteProjectCrewMember.filter(
          (o) => o.isActive
        )
      );
      setSiteTemplates(
        site.ownerSiteProjects[0].ownerSiteProjectTemplate.filter(
          (o) => o.isActive
        )
      );
    }
    setAddedContractors([]);
    setRemovedContractors([]);
    setAddedCrew([]);
    setRemovedCrew([]);
    setAddedUsers([]);
    setRemovedUsers([]);
    setAddedTemplates([]);
    setRemovedTemplates([]);
  };

  const download = () => {
    const svg = document.getElementById("svg");
    if (svg) {
      // Create image
      const img = new Image();
      const serializer = new XMLSerializer();
      const svgStr = serializer.serializeToString(svg);
      img.src = "data:image/svg+xml;base64," + window.btoa(svgStr);
      img.onload = function () {
        // Draw svg
        const canvas = document.createElement("canvas");
        const w = 400;
        const h = 400;
        canvas.width = w;
        canvas.height = h;
        canvas.getContext("2d").drawImage(img, 0, 0, w, h);
        const imgURL = canvas.toDataURL("image/png");
        // Download
        const dlLink = document.createElement("a");
        dlLink.download = `${site.site.name} [${site.ownerSiteProjects[0].owner.name}] QR Code`;
        dlLink.href = imgURL;
        document.body.appendChild(dlLink);
        dlLink.click();
        document.body.removeChild(dlLink);
      };
    }
  };

  return (
    <CustomDialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle
        style={{
          ...pageTitleStyles,
          fontSize: "2rem",
        }}
      >
        SITE: <span style={{ color: "#FFB700" }}> {site?.site.name}</span>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Grid container justifyContent="center">
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <>
            <Grid container spacing={2} style={{ marginBottom: "20px" }}>
              <Grid item xs={12} container alignItems="center">
                <FormControlLabel
                  style={{ color: "white" }}
                  control={
                    <CustomSwitch
                      checked={isActive}
                      onChange={(event) => setIsActive(event.target.checked)}
                      light
                      disabled={editDisabled}
                    />
                  }
                  label={isActive ? "ACTIVE" : "INACTIVE"}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="select-site-owner"
                  options={
                    siteData && siteData.owners
                      ? [...siteData.owners.companies].sort((a, b) =>
                          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                        )
                      : []
                  }
                  value={owner}
                  onChange={(event, value) => setOwner(value)}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disableClearable
                  loading={siteData.loadingOwners}
                  disabled={editDisabled}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      style={{ color: "#fff" }}
                      variant="standard"
                      label={"Site Owner:"}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  label="Site Name:"
                  variant="standard"
                  style={{ width: "100%" }}
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  disabled={editDisabled}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  label="Address Line 1:"
                  variant="standard"
                  style={{ width: "100%" }}
                  value={addr1}
                  onChange={(event) => setAddr1(event.target.value)}
                  disabled={editDisabled}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  label="Address Line 2:"
                  variant="standard"
                  style={{ width: "100%" }}
                  value={addr2}
                  onChange={(event) => setAddr2(event.target.value)}
                  disabled={editDisabled}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  label="City:"
                  variant="standard"
                  style={{ width: "100%" }}
                  value={city}
                  onChange={(event) => setCity(event.target.value)}
                  disabled={editDisabled}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="select-site-state"
                  options={states}
                  value={state}
                  onChange={(event, value) => setState(value)}
                  disableClearable
                  disabled={editDisabled}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      style={{ color: "#fff" }}
                      variant="standard"
                      label={"State:"}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  label="Zipcode:"
                  variant="standard"
                  style={{ width: "100%" }}
                  value={zip}
                  onChange={(event) => setZip(event.target.value)}
                  type="number"
                  inputProps={{ maxLength: 5 }}
                  disabled={editDisabled}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  label="Note:"
                  variant="standard"
                  style={{ width: "100%" }}
                  value={note}
                  onChange={(event) => setNote(event.target.value)}
                  disabled={editDisabled}
                />
              </Grid>
            </Grid>
            <Paper>
              <StyledTabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
              >
                <StyledTab label="USERS" />
                <StyledTab label="CONTRACTORS" />
                <StyledTab label="CREWMEMBERS" />
                <StyledTab label="TEMPLATES" />
              </StyledTabs>
              <UserAccordion
                value={value}
                users={siteData.users}
                loading={siteData.loadingUsers}
                siteUsers={siteUsers}
                setSiteUsers={setSiteUsers}
                setRemovedUsers={setRemovedUsers}
                addedUsers={addedUsers}
                setAddedUsers={setAddedUsers}
                supervisors={supervisors}
                setSupervisors={setSupervisors}
                disabled={editDisabled}
              />
              <ContractorAccordion
                value={value}
                contractors={{
                  companies: removeDuplicates(
                    [...siteData.companies.companies, ...contractors],
                    "id"
                  ),
                }}
                loading={siteData.loadingCompanies}
                siteContractors={siteContractors}
                setSiteContractors={setSiteContractors}
                setRemovedContractors={setRemovedContractors}
                addedContractors={addedContractors}
                setAddedContractors={setAddedContractors}
                disabled={editDisabled}
              />
              <CrewAccordion
                value={value}
                crew={siteData.crew}
                loading={siteData.loadingCrew}
                siteCrew={siteCrew}
                setSiteCrew={setSiteCrew}
                addedCrew={addedCrew}
                setAddedCrew={setAddedCrew}
                setRemovedCrew={setRemovedCrew}
                disabled={editDisabled}
              />
              <TemplateAccordion
                value={value}
                templates={siteData.templates}
                loading={siteData.loadingTemplates}
                siteTemplates={siteTemplates}
                setSiteTemplates={setSiteTemplates}
                addedTemplates={addedTemplates}
                setAddedTemplates={setAddedTemplates}
                setRemovedTemplates={setRemovedTemplates}
                disabled={editDisabled}
              />
            </Paper>

            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              style={{ margin: "15px 0px" }}
            >
              <Grid item>
                <div
                  id="svg-container"
                  style={{
                    height: "auto",

                    maxWidth: 150,
                    width: "100%",
                  }}
                >
                  {site && site.ownerSiteProjects.length > 0 ? (
                    <QRCode
                      size={256}
                      id="svg"
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={
                        site
                          ? `${apiUrl.replace("/api", "")}crewmembers/${
                              site.ownerSiteProjects[0].id
                            }/${site.site.id}/${site.site.name}/${
                              site.ownerSiteProjects[0].owner.name
                            }`.replace(/ /g, "%20")
                          : ""
                      }
                      viewBox={"0 0 256 256"}
                    />
                  ) : (
                    <Typography>
                      There was an issue generating the QR code.
                    </Typography>
                  )}
                </div>
              </Grid>
              <Grid item>
                <Button
                  sx={{
                    ...yellowButton,
                    margin: "auto",
                    width: "150px",
                    marginLeft: "20px",
                  }}
                  onClick={download}
                >
                  Download QR Code
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          sx={{ marginRight: "10px", ...yellowButton }}
          onClick={onEdit}
          loading={loadingEdit}
          disabled={editDisabled}
        >
          SAVE
        </LoadingButton>
        <Button variant="contained" onClick={onCancel}>
          CANCEL
        </Button>
      </DialogActions>
    </CustomDialog>
  );
});

export default EditSite;
