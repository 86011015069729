import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Button,
  useTheme,
  Grid,
} from "@mui/material";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

export default function Comment({ comment, handleChange }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    if (comment) {
      setValue(comment);
    }
  }, [comment]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
    handleChange(value, "comment");
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div style={{ display: "contents" }}>
      <IconButton onClick={handleOpen}>
        <TextSnippetIcon
          style={{
            color: value !== "" ? theme.palette.yellow0.main : "inherit",
          }}
        />
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogContent>
          <Grid item xs={12}>
            <TextField
              value={value}
              onChange={(event) => setValue(event.target.value)}
              multiline
              rows={11}
              inputProps={{ maxLength: 300 }}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12}>
            {value.length} / {300}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleSave}>SAVE</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
