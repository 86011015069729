import React from "react";

import {
  Grid,
  Typography,
  FormControl,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

import { useReactiveVar } from "@apollo/client";
import { useQuery } from "@apollo/client";

import {
  createObservationVar,
  editObservationVar,
} from "../../../graphql/localVariables/observation";
import { onlineVar } from "../../../graphql/localVariables/user";
import { observationMutations } from "../../../graphql/mutations";
import { GET_OUTCOMES } from "../../../graphql/queries";

export default function OutcomeInjuries({ type }) {
  // Global Variables
  const editObs = useReactiveVar(editObservationVar);
  const createObs = useReactiveVar(createObservationVar);
  const form = type === "EDIT" ? editObs : createObs;
  const online = useReactiveVar(onlineVar);

  // Mutations
  const { updateKeyEditObservation, updateKeyCreateObservation } =
    observationMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditObservation : updateKeyCreateObservation;

  // Queries
  const { data } = useQuery(GET_OUTCOMES, {
    fetchPolicy: online ? "cache-first" : "cache-only",
  });

  const [state, setState] = React.useState([]);

  React.useEffect(() => {
    if (data && data.outcomes && form.outcomes) {
      const newState = form.outcomes;
      setState(
        data.outcomes.map((o) => {
          if (newState.find((s) => o.id === s.id)) {
            return {
              name: o.name,
              checked: true,
              id: o.id,
            };
          } else {
            return {
              name: o.name,
              checked: false,
              id: o.id,
            };
          }
        })
      );
    } else if (data && data.outcomes) {
      setState(data.outcomes);
    }
  }, [data, form.outcomes]);

  const handleChange = (event) => {
    setState((prevState) => {
      const filteredState = prevState.filter(
        (i) => i.name !== event.target.name
      );
      const newState = [
        ...filteredState,
        {
          name: event.target.name,
          checked: event.target.checked,
          id: event.target.id,
        },
      ];
      updateObsKeyFunction(
        "outcomes",
        newState.filter((i) => i.checked)
      );
      return newState;
    });
  };

  const renderInjuryCheckbox = (injuryType) => {
    return (
      <FormControlLabel
        key={injuryType.id}
        control={
          <Checkbox
            checked={injuryType.checked}
            onChange={handleChange}
            name={injuryType.name}
            id={`${injuryType.id}`}
            value={injuryType.checked}
          />
        }
        label={injuryType.name}
      />
    );
  };

  return (
    <>
      <Grid item xs={12} style={{ marginTop: "20px" }}>
        <Typography>
          Please select the outcomes that apply to this event:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
          <FormGroup>
            {[...state]
              .filter(
                (o) =>
                  ![
                    "Non-Issue - Requiring Correction",
                    "Safe",
                    "Near Miss",
                    "Unsafe Work Condition",
                  ].includes(o.name)
              )
              .sort((a, b) => a.id - b.id)
              .map((i) => renderInjuryCheckbox(i))}
          </FormGroup>
        </FormControl>
      </Grid>
    </>
  );
}
