import { gql } from "@apollo/client";

export function createUpdateKeyCreateIncident(createIncidentVar) {
  return (key, value) => {
    const obsCopy = { ...createIncidentVar() };
    obsCopy[key] = value;

    createIncidentVar(obsCopy);
  };
}

export function createUpdateAllCreateIncident(createIncidentVar) {
  return (value) => {
    createIncidentVar(value);
  };
}

export function createUpdateKeyEditIncident(editIncidentVar) {
  return (key, value) => {
    const obsCopy = { ...editIncidentVar() };
    obsCopy[key] = value;

    editIncidentVar(obsCopy);
  };
}

export function createUpdateAllEditIncident(editIncidentVar) {
  return (value) => {
    editIncidentVar(value);
  };
}

export function updateCreateIncidentImages(createIncidentImagesVar) {
  return (value) => {
    createIncidentImagesVar(value);
  };
}

export function updateEditIncidentImages(editIncidentImagesVar) {
  return (value) => {
    editIncidentImagesVar(value);
  };
}

export function updateKeyViewIncidentFilter(viewIncidentFilterVar) {
  return (key, value) => {
    const obsCopy = { ...viewIncidentFilterVar() };
    obsCopy[key] = value;

    viewIncidentFilterVar(obsCopy);
  };
}

export function updateAllViewIncidentFilter(viewIncidentFilterVar) {
  return (value) => {
    viewIncidentFilterVar(value);
  };
}

export const INCIDENT_CREATE = gql`
  mutation incidentCreate($input: CreateIncidentReportInput!) {
    incidentCreate(input: $input) {
      incidentReport {
        id
      }
      message {
        success
        errors
        info
      }
    }
  }
`;

export const INCIDENT_EDIT = gql`
  mutation incidentEdit($id: ID!, $input: UpdateIncidentReportInput!) {
    incidentEdit(id: $id, input: $input) {
      incidentReport {
        id
        isCompleted
      }
      message {
        success
        errors
        info
      }
    }
  }
`;

export const INCIDENT_EMAIL = gql`
  mutation emailIncident($id: ID, $file: Upload!) {
    incidentEmail(id: $id, file: $file) {
      message {
        success
        errors
        info
      }
    }
  }
`;
