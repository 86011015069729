import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

export const CustomSwitch = styled(Switch, {
  shouldForwardProp: (prop) => prop !== "light",
})(({ light }) => ({
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      "& .MuiSwitch-thumb:before": {
        backgroundColor: "#FDBC02",
        width: 22,
        height: 21,
        left: 8,
        top: 9,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#FFCCAA",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 20,
    height: 20,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "20px",
      height: "20px",
      left: 9,
      top: 9,
      backgroundColor: light ? "white" : "black",
      borderRadius: 20,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 0.5,
    backgroundColor: light ? "white" : "#00000075",
  },
}));
