import React from "react";
import { useNavigate } from "react-router-dom";

import { FilterList } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  Paper,
  TextField,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useTheme,
  FormControlLabel,
} from "@mui/material";

import { useMutation, useQuery, useReactiveVar } from "@apollo/client";

import {
  onlineVar,
  permissionsVar,
} from "../../../graphql/localVariables/user";
import { MUTATE_PERMISSION } from "../../../graphql/mutations/admin/permissions";
import {
  GET_ACTIVE_PERMISSIONS,
  GET_PERMISSION_GROUPS,
} from "../../../graphql/queries/permissions";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import { CustomSwitch } from "../../CustomComponents/Switch";
import TabPanel from "../../CustomComponents/TabPanels";
import { StyledTab, StyledTabs } from "../../CustomComponents/Tabs";
import { CustomTextField } from "../../CustomStyles/LightTextField";
import { blueButton, yellowButton } from "../../CustomStyles/buttons";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import Layout from "../../Layout";
import CreateGroup from "./CreateGroup";
import CreatePermission from "./CreatePermission";
import PermissionGroup from "./PermissionGroup";

function Permission({ permission }) {
  const theme = useTheme();
  const [updatePermission] = useMutation(MUTATE_PERMISSION, {
    onCompleted() {
      onCompletedFunc("Permission has been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [{ query: GET_ACTIVE_PERMISSIONS }],
  });
  const [name, setName] = React.useState(permission.name);
  const [description, setDescription] = React.useState(permission.description);
  const [isActive, setIsActive] = React.useState(permission.isActive);

  const onSave = () => {
    updatePermission({
      variables: {
        input: {
          id: Number(permission.id),
          name,
          description,
          isActive,
        },
      },
    });
  };

  const onCancel = () => {
    setName(permission.name);
    setDescription(permission.description);
    setIsActive(permission.isActive);
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
        aria-controls="permissions-content"
        id="permissions-header"
        style={{
          backgroundColor: isActive
            ? theme.palette.grey1.main
            : theme.palette.grey0.main,
          color: theme.palette.white.main,
        }}
      >
        {permission.name}
      </AccordionSummary>
      <AccordionDetails
        style={{
          backgroundColor: isActive
            ? theme.palette.grey1.main
            : theme.palette.grey0.main,
        }}
      >
        <Grid container direction="column">
          <CustomTextField
            variant="standard"
            label="Name:"
            value={name}
            onChange={(event) => setName(event.target.value)}
            style={{ width: "75%" }}
            disabled
          />
          <CustomTextField
            variant="standard"
            label="Description:"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            style={{ width: "75%", marginTop: "20px" }}
          />
        </Grid>
        <Grid container alignItems="center" style={{ marginTop: "20px" }}>
          <FormControlLabel
            style={{ color: "White" }}
            control={
              <CustomSwitch
                checked={isActive}
                onChange={(event) => setIsActive(event.target.checked)}
              />
            }
            label={isActive ? "ACTIVE" : "INACTIVE"}
          />
        </Grid>
        <Grid container style={{ margin: "10px 0px" }}>
          <Button
            onClick={onSave}
            style={{ ...yellowButton, marginRight: "10px" }}
          >
            SAVE
          </Button>
          <Button variant="contained" onClick={onCancel}>
            CANCEL
          </Button>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default function PermissionsAdmin() {
  const navigate = useNavigate();
  const permissionsState = useReactiveVar(permissionsVar);
  const [open, setOpen] = React.useState(false);
  const [openPermissions, setOpenPermissions] = React.useState(false);
  const online = useReactiveVar(onlineVar);
  const { data: groups } = useQuery(GET_PERMISSION_GROUPS, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const { data: permissions } = useQuery(GET_ACTIVE_PERMISSIONS, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  const [search, setSearch] = React.useState("");
  const [searchPerm, setSearchPerm] = React.useState("");
  const [showInActive, setShowInActive] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (!permissionsState.includes("VIEW_PERMISSION_ADMINISTRATION")) {
      navigate("/");
    }
  }, []);

  return (
    <Layout>
      <Grid
        style={{
          height: "100%",
        }}
      >
        <Typography sx={pageTitleStyles}>PERMISSION ADMINISTRATION.</Typography>
        <Grid container style={{ margin: "20px 0px" }}>
          <Button
            variant="contained"
            style={{ marginRight: "10px", ...blueButton }}
            onClick={() => setOpen(true)}
          >
            Create Permission Group
          </Button>
          <Button
            variant="contained"
            style={{ marginRight: "10px", ...blueButton }}
            onClick={() => navigate("/admin/user")}
          >
            User Permissions
          </Button>
        </Grid>
        <Paper style={{ padding: "20px" }}>
          <StyledTabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            style={{ color: "white" }}
          >
            <StyledTab label="GROUPS" />
            <StyledTab label="PERMISSIONS" />
          </StyledTabs>
          <TabPanel value={value} index={0}>
            <Grid container justifyContent="space-between" alignItems="center">
              <TextField
                label="SEARCH:"
                variant="standard"
                style={{ margin: "10px 0px" }}
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
              <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
                <FilterList />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={() => setAnchorEl(null)}
              >
                <Grid style={{ padding: "20px" }}>
                  <Typography>Show Inactive?</Typography>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <CustomSwitch
                        checked={showInActive}
                        onChange={(event) =>
                          setShowInActive(event.target.checked)
                        }
                      />
                    }
                    label={showInActive ? "YES" : "NO"}
                  />
                </Grid>
              </Menu>
            </Grid>
            {permissions && groups && (
              <>
                {groups.PermissionGroups.filter(
                  (pg) =>
                    pg.name.toLowerCase().includes(search.toLowerCase()) &&
                    pg.isActive
                )
                  .sort((a, b) =>
                    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                  )
                  .map((pg) => (
                    <PermissionGroup
                      key={pg.id}
                      permissionGroup={pg}
                      allPermissions={permissions}
                    />
                  ))}

                {showInActive &&
                  groups.PermissionGroups.filter(
                    (pg) =>
                      pg.name.toLowerCase().includes(search.toLowerCase()) &&
                      !pg.isActive
                  )
                    .sort((a, b) =>
                      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                    )
                    .map((pg) => (
                      <PermissionGroup
                        key={pg.id}
                        permissionGroup={pg}
                        allPermissions={permissions}
                      />
                    ))}
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container justifyContent="space-between" alignItems="center">
              <TextField
                label="SEARCH:"
                variant="standard"
                style={{ margin: "10px 0px" }}
                value={searchPerm}
                onChange={(event) => setSearchPerm(event.target.value)}
              />
              <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
                <FilterList />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={() => setAnchorEl(null)}
              >
                <Grid style={{ padding: "20px" }}>
                  <Typography>Show Inactive?</Typography>
                  <FormControlLabel
                    style={{ color: "black" }}
                    control={
                      <CustomSwitch
                        checked={showInActive}
                        onChange={(event) =>
                          setShowInActive(event.target.checked)
                        }
                      />
                    }
                    label={showInActive ? "YES" : "NO"}
                  />
                </Grid>
              </Menu>
            </Grid>
            {permissions && (
              <>
                {permissions.permissions
                  .filter(
                    (p) =>
                      p.name.toLowerCase().includes(searchPerm.toLowerCase()) &&
                      p.isActive
                  )
                  .sort((a, b) =>
                    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                  )
                  .map((p) => (
                    <Permission key={p.id} permission={p} />
                  ))}

                {showInActive &&
                  permissions.permissions
                    .filter(
                      (p) =>
                        p.name
                          .toLowerCase()
                          .includes(searchPerm.toLowerCase()) && !p.isActive
                    )
                    .sort((a, b) =>
                      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                    )
                    .map((p) => <Permission key={p.id} permission={p} />)}
              </>
            )}
          </TabPanel>
        </Paper>
      </Grid>
      <CreateGroup
        open={open}
        handleClose={() => setOpen(false)}
        permissions={permissions}
      />
      <CreatePermission
        open={openPermissions}
        handleClose={() => setOpenPermissions(false)}
      />
    </Layout>
  );
}
