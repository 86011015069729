import React from "react";

import { useReactiveVar } from "@apollo/client";
import MuiPhoneNumber from "material-ui-phone-number";

import {
  createIncidentVar,
  editIncidentVar,
} from "../../../../graphql/localVariables/investigation";
import { investigationMutations } from "../../../../graphql/mutations";

export default function EmployeePhone({ type }) {
  // Global Variables
  const editObs = useReactiveVar(editIncidentVar);
  const createObs = useReactiveVar(createIncidentVar);
  const form = type === "EDIT" ? editObs : createObs;

  // Mutations
  const { updateKeyEditIncident, updateKeyCreateIncident } =
    investigationMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditIncident : updateKeyCreateIncident;

  const handleChange = (date) => {
    updateObsKeyFunction("employeePhoneNumber", date);
  };

  return (
    <MuiPhoneNumber
      defaultCountry={"us"}
      value={form.employeePhoneNumber}
      onChange={handleChange}
      label={"Employee Phone Number:"}
      style={{ width: "100%" }}
    />
  );
}
