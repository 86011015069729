export const filterData = (arr, type, searchTerm, inactive) => {
  let filteredArr = searchFilter(arr, searchTerm, type);
  filteredArr = isActiveFilter(filteredArr, inactive, type);
  return filteredArr;
};

const getName = (type, item) => {
  switch (type) {
    case "Categories":
      return item.name;
    case "Actions":
      return item.arm.a.name;
    case "Risks":
    case "Mitigators":
      return item.name;
  }
};

const getIsActive = (type, item) => {
  switch (type) {
    case "Categories":
      return item.isActive;
    case "Actions":
      return item.isActive;
    case "Risks":
    case "Mitigators":
      return item.isActive;
  }
};

// searchTerm - string
// arr - array of objects
const searchFilter = (arr, searchTerm, type) => {
  return arr.filter((a) =>
    getName(type, a).toLowerCase().includes(String(searchTerm).toLowerCase())
  );
};

const isActiveFilter = (arr, inactive, type) => {
  return arr.filter((a) => (inactive ? true : getIsActive(type, a)));
};
