export const filterData = (
  arr,
  searchTerm,
  site,
  afterDate,
  beforeDate,
  username,
  type
) => {
  let filteredArr = searchFilter(arr, searchTerm);
  if (site.owner.name !== "") {
    filteredArr = siteFilter(filteredArr, site);
  }
  if (afterDate) {
    filteredArr = dateFilter(filteredArr, "after", afterDate);
  }
  if (beforeDate) {
    filteredArr = dateFilter(filteredArr, "before", beforeDate);
  }
  if (username.username !== "") {
    filteredArr = usernameFilter(filteredArr, username);
  }
  if (type !== "") {
    filteredArr = typeFilter(filteredArr, type);
  }
  return filteredArr;
};

// searchTerm - string
// arr - array of objects
const searchFilter = (arr, searchTerm) => {
  searchTerm = String(searchTerm).toLowerCase();
  return arr.filter((o) =>
    o.submitter.username.toLowerCase().includes(searchTerm)
  );
};

const siteFilter = (arr, site) => {
  return arr.filter((o) => o.ownerSiteProject.site.id === site.site.id);
};

const dateFilter = (arr, order, date) => {
  return arr.filter((o) =>
    order === "before" ? new Date(o.date) < date : new Date(o.date) >= date
  );
};

const usernameFilter = (arr, username) => {
  return arr.filter((o) => o.submitter.username === username.username);
};

const typeFilter = (arr, type) => {
  return arr.filter((o) => o.type === type);
};
