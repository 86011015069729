import { makeVar } from "@apollo/client";

import makeVarPersisted from "../makeVarPersisted";

export const authVar = makeVar({
  isAuthenticated: JSON.parse(localStorage.getItem("isLoggedIn")),
});

export const onlineVar = makeVar(true);

export const snackbarVar = makeVar({
  open: false,
  message: null,
  severity: "success",
});

export const loadingVar = makeVar(false);

export const permissionsVar = makeVar(
  JSON.parse(localStorage.getItem("permissions"))
    ? JSON.parse(localStorage.getItem("permissions"))
    : []
);

export const cacheVar = makeVarPersisted(true);
