import React from "react";
import { Outlet, Navigate } from "react-router-dom";

import { useApolloClient, useReactiveVar } from "@apollo/client";

import handleLogout from "./components/SideNav/handleLogout";
import { authVar } from "./graphql/localVariables/user";

const AuthorizationProtectedRoute = () => {
  /*
   * If the user is signed in then return the child route.
   * Else redirect the user to login page.
   */
  const client = useApolloClient();
  const isLoggedIn = useReactiveVar(authVar);

  React.useEffect(() => {
    if (!isLoggedIn.isAuthenticated) {
      handleLogout(client);
    }
  }, [isLoggedIn]);

  return isLoggedIn.isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default AuthorizationProtectedRoute;
