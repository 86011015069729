import React, { memo } from "react";

import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import * as _ from "lodash";

import { CustomTextField } from "../CustomStyles/LightTextField";

const UnselectedItem = memo(function UnselectedItem({
  item,
  index,
  idKey,
  itemType,
  focused,
  select,
}) {
  return (
    <Grid onClick={() => select(item)} key={`${item[idKey]["name"]}-${index}`}>
      <Grid
        container
        justifyContent="space-between"
        style={{
          backgroundColor: focused ? "#90ee90" : "white",
          padding: "10px",
        }}
      >
        <Grid item xs={10}>
          <Typography style={{ maxWidth: "90%" }}>
            {itemType === "risk"
              ? `${item["r"]["name"]} [${item["a"]["name"]}]`
              : itemType === "mitigator" || itemType === "correction"
              ? `${item["m"]["name"]} [${item["r"]["name"]}] [${item["a"]["name"]}]`
              : `${item[idKey]["name"]}`}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <CheckBoxOutlineBlankIcon
            style={{
              color: "black",
              fontSize: "24px",
              cursor: "pointer",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});

const SelectedItem = memo(function SelectedItem({
  item,
  index,
  idKey,
  itemType,
  focused,
  unselect,
}) {
  return (
    <Grid
      onClick={() => unselect(item)}
      key={`${item[idKey]["name"]}-${index}`}
    >
      <Grid
        container
        justifyContent="space-between"
        style={{
          backgroundColor: focused ? "#90ee90" : "white",
          padding: "10px",
        }}
      >
        <Grid item xs={10}>
          <Typography style={{ maxWidth: "90%" }}>
            {itemType === "risk"
              ? `${item["r"]["name"]} [${item["a"]["name"]}]`
              : itemType === "mitigator" || itemType === "correction"
              ? `${item["m"]["name"]} [${item["r"]["name"]}] [${item["a"]["name"]}]`
              : `${item[idKey]["name"]}`}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <CheckIcon
            style={{
              color: "green",
              fontSize: "24px",
              cursor: "pointer",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});

export default function SelectedUnselected({
  selected,
  unselected,
  setSelected,
  setUnselected,
  updateState,
  idKey,
  itemType,
  focusList,
  label,
  selectedLabel,
  showSearch,
  hideMobileSearch,
  loadingUnselected,
}) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [searching, setSearching] = React.useState("");
  const [search, setSearch] = React.useState("");

  const timer = React.useRef(null);

  React.useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setSearch(searching);
    }, 1000);
  }, [searching]);

  let focusSelect = [];
  let expectedSelect = [];
  let unfocusSelect = [];
  selected.forEach((s) => {
    const focused = focusList.includes(Number(s.id));
    if (focused) {
      focusSelect.push(s);
    } else if (s.isExpected && !focused) {
      expectedSelect.push(s);
    } else {
      unfocusSelect.push(s);
    }
  });

  const mobileSelected = [...focusSelect, ...unfocusSelect].map((u) => ({
    ...u,
    selected: true,
  }));

  let focusUnselect = [];
  let unfocusUnselect = [];
  unselected.forEach((u) => {
    if (focusList.includes(Number(u.id))) {
      focusUnselect.push(u);
    } else {
      unfocusUnselect.push(u);
    }
  });

  const mobileUnSelected = [...focusUnselect, ...unfocusUnselect].map((u) => ({
    ...u,
    selected: false,
  }));

  const select = (item) => {
    const stateSelected = [...selected, item];
    const oldStateUnselected = [...unselected];
    const newState = oldStateUnselected.filter(
      (prevItems) => prevItems.id !== item.id
    );
    updateState(stateSelected, newState);
    setSelected(stateSelected);
    setUnselected(newState);
  };

  const unselect = (item) => {
    const stateUnselected = [...unselected, item];
    const oldStateSelected = [...selected];
    const newState = oldStateSelected.filter(
      (prevItems) => prevItems.id !== item.id
    );
    updateState(newState, stateUnselected);
    setSelected(newState);
    setUnselected(stateUnselected);
  };

  const getSearchedItems = (list) => {
    if (search === "") return list;
    else {
      return _.filter(list, function (l) {
        return l[idKey]["name"].toLowerCase().includes(search.toLowerCase());
      });
    }
  };

  return (
    <div>
      {mobile ? (
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <Typography style={{ fontSize: "0.8rem" }}>{label}:</Typography>
              {!hideMobileSearch && (
                <TextField
                  value={searching}
                  onChange={(event) => setSearching(event.target.value)}
                  variant="standard"
                  label="Search:"
                  style={{ marginBottom: "10px", width: "100%" }}
                />
              )}
              <div
                style={{
                  border: "1px solid #8297a0",
                  borderRadius: "4px",
                  maxHeight: "200px",
                  overflowY: "auto",
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                {/* Expected risks at the top */}
                {selected
                  .filter(
                    (s) =>
                      s[idKey]["name"]
                        .toLowerCase()
                        .includes(search.toLowerCase()) &&
                      s.isExpected &&
                      itemType === "risk"
                  )
                  .sort((a, b) =>
                    a[idKey]["name"] > b[idKey]["name"] ? 1 : -1
                  )
                  .map((item, index) => {
                    return (
                      <Grid
                        key={`${item[idKey]["name"]}-${index}`}
                        style={{
                          backgroundColor: focusList.includes(item.id)
                            ? "#90ee90"
                            : "white",
                        }}
                      >
                        <Typography
                          style={{
                            fontStyle: "italic",
                            color: "#FDBC02",
                            fontWeight: "bold",
                            padding: 10,
                          }}
                        >
                          {itemType === "risk"
                            ? `${item["r"]["name"]} [${item["a"]["name"]}]`
                            : itemType === "mitigator" ||
                              itemType === "correction"
                            ? `${item["m"]["name"]} [${item["r"]["name"]}] [${item["a"]["name"]}]`
                            : `${item[idKey]["name"]}`}
                        </Typography>
                      </Grid>
                    );
                  })}

                {/* Items from HA arms second */}
                {[
                  ...mobileUnSelected,
                  ...mobileSelected.filter(
                    (s) => !(s.isExpected && itemType === "risk")
                  ),
                ]
                  .filter(
                    (s) =>
                      s[idKey]["name"]
                        .toLowerCase()
                        .includes(search.toLowerCase()) &&
                      focusList.includes(s.id)
                  )
                  .sort((a, b) =>
                    a[idKey]["name"] > b[idKey]["name"] ? 1 : -1
                  )
                  .map((item, index) => {
                    if (!item.selected) {
                      return (
                        <UnselectedItem
                          key={`${item[idKey]["name"]}-${index}`}
                          item={item}
                          index={index}
                          idKey={idKey}
                          itemType={itemType}
                          focused
                          select={select}
                        />
                      );
                    } else
                      return (
                        <SelectedItem
                          key={`${item[idKey]["name"]}-${index}`}
                          item={item}
                          index={index}
                          idKey={idKey}
                          itemType={itemType}
                          focused
                          unselect={unselect}
                        />
                      );
                  })}

                {/* All other items third */}
                {[
                  ...mobileUnSelected,
                  ...mobileSelected.filter(
                    (s) => !(s.isExpected && itemType === "risk")
                  ),
                ]
                  .filter(
                    (s) =>
                      s[idKey]["name"]
                        .toLowerCase()
                        .includes(search.toLowerCase()) &&
                      !focusList.includes(s.id)
                  )
                  .sort((a, b) =>
                    a[idKey]["name"] > b[idKey]["name"] ? 1 : -1
                  )
                  .map((item, index) => {
                    if (!item.selected) {
                      return (
                        <UnselectedItem
                          key={`${item[idKey]["name"]}-${index}`}
                          item={item}
                          index={index}
                          idKey={idKey}
                          itemType={itemType}
                          select={select}
                        />
                      );
                    } else
                      return (
                        <SelectedItem
                          key={`${item[idKey]["name"]}-${index}`}
                          item={item}
                          index={index}
                          idKey={idKey}
                          itemType={itemType}
                          unselect={unselect}
                        />
                      );
                  })}
              </div>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography style={{ fontSize: "0.8rem" }}>
                  Unselected {label}:
                </Typography>
              </Grid>
              {showSearch && (
                <CustomTextField
                  value={searching}
                  onChange={(e) => setSearching(e.target.value)}
                  variant="standard"
                  label={`Search ${label}:`}
                  style={{ marginBottom: "10px", width: "100%" }}
                />
              )}
              <div
                style={{
                  border: "1px solid #8297a0",
                  borderRadius: "4px",
                  maxHeight: "200px",
                  overflowY: "auto",
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                {loadingUnselected && (
                  <Grid container justifyContent="center" sx={{ p: 2 }}>
                    <CircularProgress color="secondary" />
                  </Grid>
                )}
                {!loadingUnselected && unselected.length === 0 ? (
                  <Grid style={{ padding: 10 }}>
                    <Typography>None unselected</Typography>
                  </Grid>
                ) : null}
                {focusUnselect.length > 0 &&
                  getSearchedItems(focusUnselect)
                    .sort((a, b) =>
                      a[idKey]["name"] > b[idKey]["name"] ? 1 : -1
                    )
                    .map((item, index) => {
                      return (
                        <Grid key={`${item[idKey]["name"]}-${index}`}>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            style={{
                              backgroundColor: "#90ee90",
                              padding: "10px",
                            }}
                          >
                            <Grid item xs={10}>
                              <Typography style={{ maxWidth: "90%" }}>
                                {itemType === "risk"
                                  ? `${item["r"]["name"]} [${item["a"]["name"]}]`
                                  : itemType === "mitigator" ||
                                    itemType === "correction"
                                  ? `${item["m"]["name"]} [${item["r"]["name"]}] [${item["a"]["name"]}]`
                                  : `${item[idKey]["name"]}`}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton onClick={() => select(item)}>
                                <AddIcon
                                  style={{
                                    color: "#FDBC02",
                                    fontSize: "24px",
                                  }}
                                />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}

                {getSearchedItems(unfocusUnselect)
                  .sort((a, b) =>
                    a[idKey]["name"] > b[idKey]["name"] ? 1 : -1
                  )
                  .map((item, index) => {
                    return (
                      <Grid key={`${item[idKey]["name"]}-${index}`}>
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          style={{
                            backgroundColor: "white",
                            padding: "10px",
                          }}
                        >
                          <Grid item xs={10}>
                            <Typography style={{ maxWidth: "90%" }}>
                              {itemType === "risk"
                                ? `${item["r"]["name"]} [${item["a"]["name"]}]`
                                : itemType === "mitigator" ||
                                  itemType === "correction"
                                ? `${item["m"]["name"]} [${item["r"]["name"]}] [${item["a"]["name"]}]`
                                : `${item[idKey]["name"]}`}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton onClick={() => select(item)}>
                              <AddIcon
                                style={{
                                  color: "#FDBC02",
                                  fontSize: "24px",
                                }}
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
              </div>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ fontSize: "0.8rem" }}>
                {selectedLabel
                  ? "Selected"
                  : label === "Actions"
                  ? "Observed"
                  : "Present"}{" "}
                {label}:
              </Typography>
              <div
                style={{
                  border: "1px solid #8297a0",
                  borderRadius: "4px",
                  maxHeight: "200px",
                  overflowY: "auto",
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                {selected.length === 0 && (
                  <Grid style={{ padding: 10 }}>
                    <Typography>None selected</Typography>
                  </Grid>
                )}
                {[...expectedSelect, ...focusSelect]
                  .sort((a, b) =>
                    a[idKey]["name"] > b[idKey]["name"] ? 1 : -1
                  )
                  .map((item, index) => {
                    if (item.isExpected && itemType === "risk") {
                      return (
                        <Grid
                          key={`${item[idKey]["name"]}-${index}`}
                          style={{
                            backgroundColor: focusList.includes(item.id)
                              ? "#90ee90"
                              : "white",
                          }}
                        >
                          <Typography
                            style={{
                              fontStyle: "italic",
                              color: "#FDBC02",
                              fontWeight: "bold",
                              padding: 10,
                            }}
                          >
                            {itemType === "risk"
                              ? `${item["r"]["name"]} [${item["a"]["name"]}]`
                              : itemType === "mitigator" ||
                                itemType === "correction"
                              ? `${item["m"]["name"]} [${item["r"]["name"]}] [${item["a"]["name"]}]`
                              : `${item[idKey]["name"]}`}
                          </Typography>
                        </Grid>
                      );
                    } else {
                      return (
                        <Grid
                          onClick={() => unselect(item)}
                          key={`${item[idKey]["name"]}-${index}`}
                        >
                          <Grid
                            container
                            justifyContent="space-between"
                            style={{
                              backgroundColor: focusList.includes(item.id)
                                ? "#90ee90"
                                : "white",
                              padding: "10px",
                            }}
                          >
                            <Grid item xs={10}>
                              <Typography style={{ maxWidth: "90%" }}>
                                {itemType === "risk"
                                  ? `${item["r"]["name"]} [${item["a"]["name"]}]`
                                  : itemType === "mitigator" ||
                                    itemType === "correction"
                                  ? `${item["m"]["name"]} [${item["r"]["name"]}] [${item["a"]["name"]}]`
                                  : `${item[idKey]["name"]}`}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <CloseIcon
                                style={{
                                  color: "#FDBC02",
                                  fontSize: "24px",
                                  cursor: "pointer",
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    }
                  })}

                {unfocusSelect
                  .sort((a, b) =>
                    a[idKey]["name"] > b[idKey]["name"] ? 1 : -1
                  )
                  .map((item, index) => {
                    return (
                      <Grid
                        onClick={() => unselect(item)}
                        key={`${item[idKey]["name"]}-${index}`}
                      >
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          style={{
                            backgroundColor: "white",
                            padding: "10px",
                          }}
                        >
                          <Grid item xs={10}>
                            <Typography style={{ maxWidth: "90%" }}>
                              {itemType === "risk"
                                ? `${item["r"]["name"]} [${item["a"]["name"]}]`
                                : itemType === "mitigator" ||
                                  itemType === "correction"
                                ? `${item["m"]["name"]} [${item["r"]["name"]}] [${item["a"]["name"]}]`
                                : `${item[idKey]["name"]}`}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton>
                              <CloseIcon
                                style={{
                                  color: "#FDBC02",
                                  fontSize: "24px",
                                  cursor: "pointer",
                                }}
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
              </div>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
}
