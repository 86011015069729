import React from "react";

import CreateProject from "../../../../Planning/Project/CreateProject";

export default function CreateProjectDialog({ open, setOpen, company }) {
  return (
    <CreateProject
      open={open}
      handleClose={() => setOpen(false)}
      company={company}
    />
  );
}
