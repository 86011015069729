import React from "react";
import { useNavigate } from "react-router-dom";

import AddCircleRounded from "@mui/icons-material/AddCircleRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  List,
  ListItemText,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItemButton,
} from "@mui/material";
import { useTheme } from "@mui/styles";

export default function ObservationItems({
  iconSelectedColor,
  textSelectedColor,
  endBarSelectedColor,
  classes,
  permissions,
  keyHook,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const expandIconColor = open ? endBarSelectedColor : theme.palette.grey1.main;

  let createObsItem;
  if (permissions.includes("OBSERVATION")) {
    createObsItem = (
      <ListItemButton onClick={() => navigate("/create-obs")}>
        <ListItemText primary="Observation" className={classes.listText} />
      </ListItemButton>
    );
  }

  let createHAItem;
  if (permissions.includes("HA")) {
    createHAItem = (
      <ListItemButton onClick={() => navigate("/create-ha")}>
        <ListItemText primary="HA" className={classes.listText} />
      </ListItemButton>
    );
  }

  let createAuditItem;
  if (permissions.includes("AUDIT")) {
    createAuditItem = (
      <ListItemButton onClick={() => navigate("/create-audit")}>
        <ListItemText primary="Audit" className={classes.listText} />
      </ListItemButton>
    );
  }

  let createInvestigationItem;
  if (permissions.includes("INCIDENT_REPORT")) {
    createInvestigationItem = (
      <ListItemButton onClick={() => navigate("/create-incident")}>
        <ListItemText
          primary="Incident Investigation"
          className={classes.listText}
        />
      </ListItemButton>
    );
  }

  return (
    <Accordion disableGutters>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={{
              color: expandIconColor,
            }}
          />
        }
        aria-controls="create-content"
        id="create-header"
        style={{
          backgroundColor: theme.palette.black0.main,
          color: theme.palette.grey1.main,
        }}
        onClick={handleClick}
      >
        <AddCircleRounded
          className={classes.listIcons}
          style={{
            color: open || keyHook === "create" ? iconSelectedColor : undefined,
          }}
        />
        <Typography
          className={classes.listText}
          style={{
            color: open || keyHook === "create" ? textSelectedColor : undefined,
          }}
        >
          Create
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{
          backgroundColor: theme.palette.black0.main,
          paddingBottom: "0px",
        }}
      >
        <List>
          {createObsItem}
          {createHAItem}
          {createAuditItem}
          {createInvestigationItem}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}
