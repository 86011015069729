import * as React from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { Typography } from "@mui/material";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";

const styles = () => ({
  root: {},
  imageContainer: {
    position: "relative",
    zIndex: 10,
    textAlign: "center",
    "&:hover $image": {
      opacity: 0.3,
    },
    "&:hover $removeButton": {
      opacity: 1,
    },
    padding: "20px",
  },
  image: {
    maxWidth: "100%",
    width: "auto",
    maxHeight: "100%",
    height: "100px",
  },
  removeButton: {
    transition: ".5s ease",
    position: "absolute",
    top: 0,
    right: 0,
    opacity: 0,
    width: 40,
    height: 40,
    "&:focus": {
      opacity: 1,
    },
  },
});

function PreviewList({
  fileObjects,
  classes,
  handleFilesChange,
  setRemovedPhotos,
  hideDelete,
}) {
  const isImage = (file) => {
    if (file.type && file.type.split("/")[0] === "image") {
      return true;
    }
  };

  const getPreviewIcon = (fileObject, classes) => {
    if (
      fileObject.mediaFile &&
      (fileObject.mediaFile.file.includes("png") ||
        fileObject.mediaFile.file.includes("jpg") ||
        fileObject.mediaFile.file.includes("jpeg"))
    ) {
      return (
        <>
          <a
            href={fileObject.mediaFile?.file}
            target="_blank"
            rel="noreferrer"
            download
            style={{ color: "white" }}
          >
            <img
              className={classes.image}
              src={fileObject.mediaFile.file}
              alt="Project image"
            />
          </a>
          <Typography style={{ fontSize: "12px" }}>
            {fileObject.name
              ? fileObject.name
              : fileObject.mediaFile.file.split("/")[
                  fileObject.mediaFile.file.split("/").length - 1
                ]}
          </Typography>
        </>
      );
    }

    if (isImage(fileObject)) {
      return (
        <img
          className={classes.image}
          src={URL.createObjectURL(fileObject)}
          alt="Project image"
        />
      );
    }

    return (
      <>
        {fileObject.mediaFile ? (
          <a
            href={fileObject.mediaFile?.file}
            target="_blank"
            rel="noreferrer"
            download
            style={{ color: "white" }}
          >
            <AttachFileIcon className={classes.image} />
            <Typography style={{ fontSize: "12px" }}>
              {fileObject.name
                ? fileObject.name
                : fileObject.mediaFile.file.split("/")[
                    fileObject.mediaFile.file.split("/").length - 1
                  ]}
            </Typography>
          </a>
        ) : (
          <>
            <AttachFileIcon className={classes.image} />
            <Typography style={{ fontSize: "12px" }}>
              {fileObject.name}
            </Typography>
          </>
        )}
      </>
    );
  };

  const handleRemove = (fileIndex) => (event) => {
    event.stopPropagation();
    const arr = [...fileObjects];
    if (setRemovedPhotos && arr[fileIndex].mediaFile) {
      setRemovedPhotos((prev) => [...prev, Number(arr[fileIndex].id)]);
    }
    arr.splice(fileIndex, 1);
    handleFilesChange(arr);
  };

  return (
    <Grid container>
      {fileObjects &&
        fileObjects.map((fileObject, i) => {
          return (
            <Grid
              item
              xs={3}
              key={`${fileObject.file?.name ?? "file"}-${i}`}
              className={classes.imageContainer}
            >
              {getPreviewIcon(fileObject, classes)}

              {!hideDelete && (
                <Fab
                  onClick={handleRemove(i)}
                  aria-label="Delete"
                  className={classes.removeButton}
                >
                  <DeleteIcon />
                </Fab>
              )}
            </Grid>
          );
        })}
    </Grid>
  );
}

export default withStyles(styles, { name: "MuiDropzonePreviewList" })(
  PreviewList
);
