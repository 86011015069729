import makeVarPersisted from "../makeVarPersisted";

// CREATE HAZARD ANALYSIS //
export const initialState = {
  name: "",
  date: new Date(),
  startTime: null,
  location: null,
  siteId: null,
  ownerId: null,
  projects: [],
  ospIds: [],
  templates: [],
  actions: [],
  risks: [],
  mitigators: [],
  crewMembers: [],
  comment: "",
  hasInjury: false,
  injuryText: "",
  contractorOSPIds: [],
};
export const createHAVar = makeVarPersisted(initialState, "createHAVar");

// EDIT HAZARD ANALYSIS //
const initialEditState = {
  id: null,
  name: "",
  date: new Date(),
  startTime: new Date(),
  location: null,
  siteId: null,
  ownerId: null,
  projects: [],
  ospIds: [],
  templates: [],
  actions: [],
  risks: [],
  mitigators: [],
  crewMembers: [],
  comment: "",
  hasInjury: false,
  injuryText: "",
  contractorOSPIds: [],
};
export const editHAVar = makeVarPersisted(initialEditState, "editHAVar");

export const viewHAFiltersVar = makeVarPersisted(
  {
    site: { owner: { name: "" } },
    projects: [],
    templates: [],
    afterDate: null,
    beforeDate: null,
    status: "",
    incident: "",
    username: [],
    company: { name: "" },
  },
  "viewHAFilters"
);
