import { gql } from "@apollo/client";

// ARMS
export const GET_COMPANY_ARMS = gql`
  query companyArmAdmin($companyId: Int, $rNull: Boolean, $mNull: Boolean) {
    companyArms(
      company_Id: $companyId
      isActive: true
      arm_IsActive: true
      arm_M_Isnull: $mNull
      arm_R_Isnull: $rNull
    ) {
      isActive
      arm {
        isActive
        isExpected
        riskScore
        id
        m {
          id
          name
          isActive
        }
        a {
          id
          name
          isActive
        }
        r {
          id
          name
          isActive
        }
      }
    }
  }
`;

// AUDITS
export const GET_AUDITS = gql`
  query audits {
    audits(isActive: true) {
      id
      name
      topics(isActive: true) {
        id
        name
        questions(isActive: true) {
          id
          ordering
          name
          standardNumber
          score
          penalty
        }
        ordering
      }
    }
  }
`;

// CONTRACTORS
export const GET_CONTRACTORS_AT_OWNER_SITE_PROJECT = gql`
  query ownerSiteProjectContractor {
    ownerSiteProjectContractors(isActive: true) {
      ownerSiteProject {
        project {
          id
        }
        site {
          id
        }
        owner {
          id
        }
      }
      contractor {
        id
        name
      }
    }
  }
`;

// CREWMEMBERS
export const GET_CREW_PAGINATED = gql`
  query paginatedCrewMembers($after: String) {
    paginatedCrewMembers(first: 750, after: $after, isActive: true) {
      totalCount
      edges {
        cursor
        node {
          signatureBase64
          firstName
          lastName
          id
          phoneNumber
          company {
            name
          }
          isActive
          lastUpdated
          ownerSiteProjectCrewMemberIds(
            isActive: true
            ownerSiteProject_IsActive: true
          )
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

// OSP
export const GET_OSP = gql`
  query ownerSiteProjects {
    ownerSiteProjects(isActive: true, isCompanyDashboard: true) {
      id
      project {
        id
      }
      site {
        id
      }
      owner {
        id
      }
      projectId @client
    }
  }
`;

// OUTCOMES
export const GET_OUTCOMES = gql`
  query outcomes {
    outcomes(isActive: true) {
      name
      id
    }
  }
`;

// PROJECTS
export const GET_PROJECTS = gql`
  query projects {
    ownerSiteProjects(isActive: true, project_Isnull: false) {
      id
      owner {
        id
        name
      }
      site {
        id
        name
      }
      project {
        id
        name
      }
    }
  }
`;

// SITES
export const GET_SITES = gql`
  query sites {
    ownerSiteProjects(
      site_Isnull: false
      project_Isnull: true
      isActive: true
      owner_IsActive: true
      site_IsActive: true
    ) {
      id
      owner {
        id
        name
      }
      site {
        id
        name
      }
      location @client
    }
  }
`;

// TEMPLATES
export const GET_HA_TEMPLATES_PAGINATED = gql`
  query paginatedHATemplates($ospIdList: [Int], $after: String) {
    paginatedOwnerSiteProjectTemplatesHa(
      first: 200
      after: $after
      ownerSiteProject_Id_In: $ospIdList
      isActive: true
      template_IsActive: true
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          isParentTemplate
          ownerSiteProject {
            id
          }
          template {
            id
            name
            startDate
            endDate
            templateArmIds(isActive: true)
          }
        }
      }
    }
  }
`;
