import * as React from "react";

import CheckBox from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Button,
  Grid,
  Autocomplete,
  TextField,
  Badge,
  Checkbox,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import { useQuery, useReactiveVar } from "@apollo/client";

import { viewAuditFiltersVar } from "../../../../graphql/localVariables/audit";
import { onlineVar } from "../../../../graphql/localVariables/user";
import { auditMutations } from "../../../../graphql/mutations";
import { GET_SITES } from "../../../../graphql/queries";
import { GET_USERNAMES } from "../../../../graphql/queries";
import { blueButton, blackButton } from "../../../CustomStyles/buttons";
import {
  Search,
  StyledInputBase,
  SearchIconWrapper,
} from "../../../CustomStyles/searchBar";

// PROPS
// searchTerm - string
// onSearchChange - function
// title - string
export default function ReusableTableToolbar(props) {
  const { updateKeyViewAuditFilters } = auditMutations;
  const filters = useReactiveVar(viewAuditFiltersVar);
  const { title, clearFilters } = props;
  const online = useReactiveVar(onlineVar);
  const { data: sites } = useQuery(GET_SITES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const { data: usernames } = useQuery(GET_USERNAMES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const [open, setOpen] = React.useState(false);

  const getBadgeCount = () => {
    let count = 0;
    if (filters.site && filters.site.owner.name !== "") count++;
    if (filters.usernames && filters.usernames.length > 0) count++;
    if (filters.afterDate && filters.afterDate !== null) count++;
    if (filters.beforeDate && filters.beforeDate !== null) count++;
    return count;
  };

  return (
    <Toolbar
      sx={{
        pl: {
          sm: 2,
        },
        pr: {
          xs: 1,
          sm: 1,
        },
      }}
    >
      <Typography
        sx={{
          flex: "1 1 100%",
        }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {title ? title : null}
      </Typography>
      <Tooltip title="Filter">
        <Badge badgeContent={getBadgeCount()} color="secondary">
          <IconButton onClick={() => setOpen(true)}>
            <FilterListIcon />
          </IconButton>
        </Badge>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <Grid container>
            <Grid item xs={12} md={6} style={{ padding: "5px" }}>
              <Autocomplete
                id="audit-filter-site"
                options={
                  sites && sites.ownerSiteProjects
                    ? [...sites.ownerSiteProjects].sort((a, b) =>
                        a.site.name > b.site.name ? 1 : -1
                      )
                    : []
                }
                getOptionLabel={(option) =>
                  option.site
                    ? `${option.site?.name} [${option.owner?.name}]`
                    : option.owner?.name
                }
                value={filters.site || { owner: { name: "" } }}
                onChange={(event, newValue) =>
                  updateKeyViewAuditFilters(
                    "site",
                    newValue || { owner: { name: "" } }
                  )
                }
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.site
                        ? `${option.site?.name} [${option.owner?.name}]`
                        : option.owner?.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Filter by Site [Site Owner]"
                    value={params}
                    id={params.id}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "5px" }}>
              <Autocomplete
                id="audit-filter-users"
                options={
                  usernames && usernames.users
                    ? [...usernames.users].sort((a, b) =>
                        a.username > b.username ? 1 : -1
                      )
                    : []
                }
                getOptionLabel={(option) => {
                  return option.username;
                }}
                value={filters.usernames || []}
                multiple
                limitTags={4}
                disableCloseOnSelect
                onChange={(event, newValue) =>
                  updateKeyViewAuditFilters("usernames", newValue || [])
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlank />}
                      checkedIcon={<CheckBox />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.username}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Filter by Submitter"
                    value={params}
                    id={params.id}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} style={{ padding: "5px" }} container>
              <DesktopDatePicker
                label="Filter After This Date"
                inputFormat="MMM DD, yyyy"
                value={filters.afterDate || null}
                onChange={(date) =>
                  updateKeyViewAuditFilters("afterDate", date)
                }
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "47%", marginRight: "3%" }}
                    variant="standard"
                    {...params}
                  />
                )}
                disableMaskedInput
              />
              <DesktopDatePicker
                label="Filter Before This Date"
                inputFormat="MMM DD, yyyy"
                value={filters.beforeDate || null}
                onChange={(date) =>
                  updateKeyViewAuditFilters("beforeDate", date)
                }
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "50%" }}
                    variant="standard"
                    {...params}
                  />
                )}
                disableMaskedInput
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} style={{ ...blueButton }}>
            FILTER
          </Button>
          <Button onClick={clearFilters} style={{ ...blackButton }}>
            CLEAR
          </Button>
        </DialogActions>
      </Dialog>
      <Tooltip title="Search">
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{
              "aria-label": "search",
            }}
            value={filters.searchterm || ""}
            onChange={(event) =>
              updateKeyViewAuditFilters("searchterm", event.target.value)
            }
          />
        </Search>
      </Tooltip>
    </Toolbar>
  );
}
