import React from "react";

import EditProject from "../../../../Planning/Project/Table/EditProject";

export default function EditProjectDialog({ data, open, setOpen, company }) {
  return (
    <EditProject
      row={data.project}
      open={open}
      handleClose={() => setOpen(false)}
      company={company}
    />
  );
}
