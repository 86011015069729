import * as React from "react";

import removeDuplicates from "../../../../../../../../utils/removeDuplicates";
import TableMain from "./ReusableTableLayout";
import ReusableTableToolbar from "./ReusableTableToolbar";
import TableBodyTemplate from "./TableBodyTemplate";
import TableHeadTemplate from "./TableHeadTemplate";
import { filterData } from "./searchFilterFunction";

// Props
// hasPagination - boolean
// data - array of objects
// 3 children component
//    If using the ReusableTableToolbar
//    - title - string - displayed at the top
//    - onDeleteClick - when trash can on toolbar is clicked
//    - onSearchChange - when input in search bar
//    - searchTerm - string
export default function Table({ data, company }) {
  const onSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const clearFilters = () => {};

  const [searchTerm, setSearchTerm] = React.useState("");

  const [filteredData, setFilteredData] = React.useState([]);

  let templates = [];
  data.forEach((d) => {
    templates = [...templates, d.template];
  });
  templates = removeDuplicates(templates, "id");

  // useEffect for filter update
  React.useEffect(() => {
    setFilteredData(filterData(templates, searchTerm));
  }, [searchTerm, data]);

  return (
    <TableMain
      CustomTableHeader={<TableHeadTemplate />}
      CustomTableBody={<TableBodyTemplate company={company} />}
      CustomTableToolBar={
        <ReusableTableToolbar
          searchTerm={searchTerm}
          onSearchChange={onSearchChange}
          clearFilters={clearFilters}
          title={"Templates"}
        />
      }
      hasPagination={true}
      data={filteredData}
    />
  );
}
