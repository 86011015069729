import { makeStyles } from "@mui/styles";

const useStyles = () =>
  makeStyles((theme) => ({
    root: {
      height: "100%",
      width: "100%",
      padding: "10px",
    },
    title: {
      fontSize: "1.5em",
      color: theme.palette.grey3.main,
      fontFamily: "barlowextrabold",
    },
    titleDot: {
      fontSize: "1.5em",
      color: theme.palette.yellow0.main,
      fontFamily: "barlowextrabold",
    },
  }));

export default useStyles;
