import * as React from "react";

import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import { useReactiveVar } from "@apollo/client";

import { createAuditVar } from "../../../graphql/localVariables/audit";
import { auditMutations } from "../../../graphql/mutations";

export default function BasicDateTimePicker() {
  const updateAudit = useReactiveVar(createAuditVar);
  const { updateCreateAudit } = auditMutations;

  const handleChange = (date) => {
    updateCreateAudit("date", date);
  };

  return (
    <MobileDatePicker
      label="Date:"
      inputFormat="MM/DD/YYYY"
      value={updateAudit.date}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          style={{
            width: "100%",
          }}
          variant="standard"
        />
      )}
    />
  );
}
