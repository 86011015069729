import * as React from "react";

import { useQuery, useReactiveVar } from "@apollo/client";

import { onlineVar } from "../../../../../graphql/localVariables/user";
import { authMutations } from "../../../../../graphql/mutations";
import { COMPANY_DASHBOARD } from "../../../../../graphql/queries/admin/company";
import TableMain from "./ReusableTableLayout";
import ReusableTableToolbar from "./ReusableTableToolbar";
import TableBodyTemplate from "./TableBodyTemplate";
import TableHeadTemplate from "./TableHeadTemplate";
import { filterData } from "./searchFilterFunction";

// Props
// hasPagination - boolean
// data - array of objects
// 3 children component
//    If using the ReusableTableToolbar
//    - title - string - displayed at the top
//    - onDeleteClick - when trash can on toolbar is clicked
//    - onSearchChange - when input in search bar
//    - searchTerm - string
export default function Table() {
  const online = useReactiveVar(onlineVar);
  const { data, loading } = useQuery(COMPANY_DASHBOARD, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const { updateLoading } = authMutations;

  const onSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const onContractorChange = (event, newValue) => {
    setContractorFilter(newValue || "");
  };

  const onNixnUserChange = (event, newValue) => {
    setNixnUserFilter(newValue || "");
  };

  const onActiveChange = (event, newValue) => {
    setActiveFilter(newValue || "");
  };

  const clearFilters = () => {
    setContractorFilter("");
    setNixnUserFilter("");
    setActiveFilter("");
  };

  const [searchTerm, setSearchTerm] = React.useState("");
  const [contractor, setContractorFilter] = React.useState("");
  const [nixnuser, setNixnUserFilter] = React.useState("");
  const [active, setActiveFilter] = React.useState("");

  const [filteredData, setFilteredData] = React.useState(
    data ? data.companies : []
  );

  // useEffect for filter update
  React.useEffect(() => {
    setFilteredData(
      filterData(
        data && data.companies ? data.companies : [],
        searchTerm,
        contractor,
        nixnuser,
        active
      )
    );
  }, [searchTerm, contractor, nixnuser, active, data]);

  // show loading
  React.useEffect(() => {
    updateLoading(loading);
  }, [loading]);

  return (
    <TableMain
      CustomTableHeader={<TableHeadTemplate />}
      CustomTableBody={<TableBodyTemplate />}
      CustomTableToolBar={
        <ReusableTableToolbar
          searchTerm={searchTerm}
          onSearchChange={onSearchChange}
          contractor={contractor}
          onContractorChange={onContractorChange}
          nixnuser={nixnuser}
          onNixnUserChange={onNixnUserChange}
          active={active}
          onActiveChange={onActiveChange}
          clearFilters={clearFilters}
          title={"Companies"}
        />
      }
      hasPagination={true}
      data={filteredData}
    />
  );
}
