import { gql } from "@apollo/client";

export const GET_SUPERVISORS = gql`
  query supervisors {
    users(
      supervise_Isnull: false
      supervise_IsActive: true
      isActive: true
      supervise_OwnerSiteProject_IsActive: true
    ) {
      username
      firstName
      lastName
      id
      lastLogin
      email
      image
      riskMitigated
      supervise {
        id
        isActive
        supervisee {
          id
          firstName
          lastName
          username
        }
        ownerSiteProject {
          id
          owner {
            id
            name
          }
          site {
            id
            name
          }
          project {
            id
            name
          }
        }
      }
    }
  }
`;
