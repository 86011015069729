import React from "react";

import { Grid, TextField, Typography } from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import {
  createIncidentVar,
  editIncidentVar,
} from "../../../../graphql/localVariables/investigation";

// Not multiline
export default function TextInputQuestion({ question, type, updateState }) {
  // Global Variables
  const editObs = useReactiveVar(editIncidentVar);
  const createObs = useReactiveVar(createIncidentVar);
  const form = type === "EDIT" ? editObs : createObs;

  const [foundRes, setFoundRes] = React.useState({
    incidentQuestion: { id: question.id },
    textInput: "",
  });

  const handleChange = (event) => {
    setFoundRes({
      incidentQuestion: { id: question.id },
      textInput: event.target.value,
    });
  };

  const handleBlur = () => {
    updateState({
      incidentQuestion: { id: question.id },
      textInput: foundRes.textInput,
    });
  };

  React.useEffect(() => {
    const doesExist = form.incidentQuestionResponses.find(
      (res) => question.id === res.incidentQuestion?.id
    );
    if (doesExist) {
      setFoundRes(doesExist);
    } else {
      setFoundRes({
        incidentQuestion: { id: question.id },
        textInput: "",
      });
    }
  }, [form.incidentQuestionResponses]);

  return (
    <Grid item xs={12} md={4} sx={{ padding: "10px 10px 50px 10px" }}>
      {question.name.length > 40 && (
        <Typography style={{ fontSize: "0.8rem" }}>{question.name}</Typography>
      )}
      <TextField
        id={`${question.id}-${question.type}`}
        variant="standard"
        label={question.name.length > 40 ? "" : question.name}
        multiline={false}
        style={{
          width: "100%",
        }}
        value={foundRes.textInput}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </Grid>
  );
}
