import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useReactiveVar } from "@apollo/client";
import { snackbarVar } from "../../graphql/localVariables/user";
import { authMutations } from "../../graphql/mutations";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomSnackbar() {
  const { updateSnackbar } = authMutations;
  const snackbar = useReactiveVar(snackbarVar);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState("error");

  React.useEffect(() => {
    if (snackbar.open) {
      setOpen(true);
      setSeverity(snackbar.severity);
    }
  }, [snackbar]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    updateSnackbar({ open: false, message: null });
  };

  return (
    <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
}
