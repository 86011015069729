import React from "react";

import { Button, Grid, Typography } from "@mui/material";

import { useMutation, useLazyQuery, useReactiveVar } from "@apollo/client";

import { onlineVar } from "../../../../../../graphql/localVariables/user";
import { USER_SITES } from "../../../../../../graphql/mutations/userSettings";
import {
  GET_COMPANY_SITES,
  GET_COMPANY_USER_DATA,
} from "../../../../../../graphql/queries/admin/company";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../../../CustomComponents/OnErrorFunction";
import SelectedUnselected from "../../../../../CustomComponents/SelectedUnselectedAdmin";
import { yellowButton } from "../../../../../CustomStyles/buttons";

export default function EditUserSites({ setOpen, data, disabled }) {
  // Queries
  const [getSites, { data: sites }] = useLazyQuery(GET_COMPANY_SITES);
  const online = useReactiveVar(onlineVar);

  // User Site Mutation
  const [mutateUserSites] = useMutation(USER_SITES, {
    onCompleted() {
      onCompletedFunc("User's sites have been updated");
    },
    onError(error) {
      onErrorFunc(error);
      siteClear();
    },
    refetchQueries: [
      {
        query: GET_COMPANY_USER_DATA,
        variables: { id: Number(data.user.id) },
        fetchPolicy: online ? "network-only" : "cache-only",
      },
    ],
  });

  React.useEffect(() => {
    if (data) {
      getSites();
    }
  }, [data]);

  // Sites state
  const [selectedSites, setSelectedSites] = React.useState([]);
  const [unselectedSites, setUnselectedSites] = React.useState([]);

  // Set unselected sites query updates
  React.useEffect(() => {
    if (
      sites &&
      sites.ownerSiteProjects &&
      data &&
      data.user &&
      data.user.ownerSiteProjectUsers
    ) {
      const filtered = sites.ownerSiteProjects.filter(
        (os) =>
          data.user.ownerSiteProjectUsers.findIndex(
            (osSel) => osSel.ownerSiteProject.site.id === os.site.id
          ) < 0
      );
      setUnselectedSites(filtered);
    }
  }, [sites, data]);

  // On Done: closes modal and resets
  const onDone = () => {
    setOpen(false);
  };

  const onSave = () => {
    mutateUserSites({
      variables: {
        userId: Number(data.user.id),
        // some have ospId, some are OSP with id
        associatedSites: selectedSites.map((s) =>
          s.ospId ? Number(s.ospId) : Number(s.id)
        ),
        unassociatedSites: unselectedSites.map((s) =>
          s.ospId ? Number(s.ospId) : Number(s.id)
        ),
      },
    });
  };

  const renderDisplayText = (item) => {
    return (
      <Typography
        style={{
          maxWidth: "90%",
        }}
      >
        {item.site ? `${item.site?.name} [${item.owner?.name}]` : item.location}
      </Typography>
    );
  };

  React.useEffect(() => {
    if (data && data.user && data.user.ownerSiteProjectUsers) {
      setSelectedSites(
        data.user.ownerSiteProjectUsers
          .filter((ospu) => ospu.ownerSiteProject.site)
          .map((ospu) => {
            // adding ospId to site for mutation
            const site = { ...ospu.ownerSiteProject?.site };
            site.ospId = ospu.ownerSiteProject?.id;
            site.location = `${ospu.ownerSiteProject?.site.name} [${ospu.ownerSiteProject?.owner.name}]`;
            return site;
          })
      );
    }
  }, [data]);

  // Site functions
  const siteClear = () => {
    setSelectedSites(
      data?.user?.ownerSiteProjectUsers.map(
        (ospu) => ospu.ownerSiteProject.site
      )
    );
  };

  const renderButtons = () => {
    return (
      <Grid sx={{ textAlignLast: "end", paddingTop: "50px" }}>
        <Button
          variant="contained"
          style={{ marginRight: "10px", ...yellowButton }}
          onClick={onSave}
          disabled={disabled}
        >
          SAVE
        </Button>
        <Button
          variant="contained"
          style={{ marginRight: "10px" }}
          onClick={onDone}
        >
          DONE
        </Button>
      </Grid>
    );
  };

  return (
    <>
      <Grid container>
        <Grid item xs={1}></Grid>
        <Grid item xs={10} style={{ marginTop: "10px" }}>
          <SelectedUnselected
            selected={[...selectedSites].sort((a, b) => {
              const aName = a.ownerSiteProject
                ? a.ownerSiteProject.site.name
                : a.site
                ? a.site.name
                : a.name;
              const bName = b.ownerSiteProject
                ? b.ownerSiteProject.site.name
                : b.site
                ? b.site.name
                : b.name;
              return aName > bName ? 1 : -1;
            })}
            unselected={[...unselectedSites].sort((a, b) => {
              const aName = a.ownerSiteProject
                ? a.ownerSiteProject.site.name
                : a.site
                ? a.site.name
                : a.location;
              const bName = b.ownerSiteProject
                ? b.ownerSiteProject.site.name
                : b.site
                ? b.site.name
                : b.location;
              return aName > bName ? 1 : -1;
            })}
            setSelected={setSelectedSites}
            setUnselected={setUnselectedSites}
            updateState={(e) => {}}
            idKey={"Sites"}
            label={"Sites"}
            renderDisplay={renderDisplayText}
            searchItem={(item, searchTerm) => {
              const name = item.ownerSiteProject
                ? item.ownerSiteProject.site.name
                : item.site
                ? item.site.name
                : item.location;

              return name.toLowerCase().includes(searchTerm.toLowerCase());
            }}
            getUniqueId={(item) => (item.ospId ? item.ospId : item.id)}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      {renderButtons()}
    </>
  );
}
