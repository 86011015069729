import { gql } from "@apollo/client";

export const CREATE_NEW_USER = gql`
  mutation createNewUser(
    $username: String!
    $email: String
    $password: String!
    $companyId: ID
    $image: Upload
  ) {
    createUser(
      username: $username
      email: $email
      password: $password
      companyId: $companyId
      image: $image
    ) {
      user {
        username
      }
    }
  }
`;

export const BATCH_CREATE_USER = gql`
  mutation batchCreateUser($bulkUser: [BulkUserInput]!) {
    createBulkUser(bulkUser: $bulkUser) {
      users {
        id
        username
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $userId: ID!
    $password: String!
    $oldPassword: String!
  ) {
    updateUser(
      userId: $userId
      password: $password
      oldPassword: $oldPassword
      changePassword: true
    ) {
      user {
        username
      }
    }
  }
`;

export const CHANGE_EMAIL = gql`
  mutation CHANGE_EMAIL($userId: ID!, $email: String!, $password: String!) {
    updateUser(userId: $userId, email: $email, password: $password) {
      user {
        username
        email
      }
    }
  }
`;

export const CHANGE_NAME = gql`
  mutation CHANGE_NAME($userId: ID!, $firstName: String, $lastName: String) {
    updateUser(userId: $userId, firstName: $firstName, lastName: $lastName) {
      user {
        firstName
        lastName
      }
    }
  }
`;

export const CHANGE_PROFILE_PIC = gql`
  mutation CHANGE_PROFILE_PIC($userId: ID!, $image: Upload!) {
    updateUser(userId: $userId, image: $image) {
      user {
        image
        id
      }
    }
  }
`;

export const CHANGE_USER_COMPANY = gql`
  mutation CHANGE_USER_COMPANY($userId: ID!, $company: ID!) {
    updateUser(userId: $userId, company: $company) {
      user {
        company {
          name
          id
        }
        id
      }
    }
  }
`;

export const USER_SITES = gql`
  mutation USER_SITES(
    $userId: ID!
    $associatedSites: [ID]
    $unassociatedSites: [ID]
  ) {
    updateUser(
      userId: $userId
      associatedOSP: $associatedSites
      unassociatedOSP: $unassociatedSites
    ) {
      user {
        id
      }
    }
  }
`;

export const CHANGE_USER_PROFILE = gql`
  mutation updateUserProfile(
    $userId: ID!
    $firstName: String
    $lastName: String
    $username: String
    $email: String
  ) {
    updateUser(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      username: $username
      email: $email
    ) {
      user {
        id
        firstName
        lastName
        email
        username
      }
    }
  }
`;

export const BULK_UPDATE_USERS = gql`
  mutation BULK_UPDATE_USERS($bulk_user: [BulkUserInput]!) {
    updateBulkUser(bulkUsers: $bulk_user) {
      users {
        id
        username
        email
        firstName
        lastName
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const UPDATE_RECEIVE_OTHER_REPORTS = gql`
  mutation UPDATE_RECEIVE_OTHER_REPORTS($id: ID, $ror: Boolean) {
    updateUser(userId: $id, receiveOtherReports: $ror) {
      user {
        id
      }
    }
  }
`;
