import * as React from "react";
import { useNavigate } from "react-router-dom";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import moment from "moment";

export default function TableBodyTemplate(props) {
  // these props are available in table body
  const { order, orderBy, page, rowsPerPage, getComparator, stableSort, data } =
    props;
  const navigate = useNavigate();

  if (
    stableSort(data, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    ).length === 0
  ) {
    return (
      <TableRow>
        <TableCell style={{ borderBottom: "transparent" }} colSpan={5}>
          No Results Found
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {stableSort(data, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => {
          const d = row.date ? new Date(row.date) : null;
          const utc = d ? d.getTime() + d.getTimezoneOffset() * 60000 : null;
          const convertedDate = utc ? new Date(utc) : null;

          var time = new Date();
          const arr = row.time ? row.time.split(":") : [0, 0, 0];
          time.setHours(arr[0], arr[1], arr[2], 0);

          return (
            <TableRow
              hover
              onClick={() => navigate(`/view-audit/${row.id}`)}
              key={row.id}
              style={{ cursor: "pointer" }}
            >
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell>
                {convertedDate
                  ? moment(convertedDate).format("MM/DD/YYYY")
                  : ""}{" "}
                {row.time ? moment(time).format("hh:mm A") : ""}
              </TableCell>
              <TableCell>{`${moment(new Date(row.timeCreated)).format(
                "MM/DD/YYYY hh:mm A"
              )}`}</TableCell>
              <TableCell>{row.submitter?.username}</TableCell>
              <TableCell>
                {row.ownerSiteProject
                  ? `${row.ownerSiteProject?.site?.name} [${row.ownerSiteProject?.owner?.name}]`
                  : ""}
              </TableCell>
              <TableCell>{row.audit?.name}</TableCell>
            </TableRow>
          );
        })}
    </>
  );
}
