import React from "react";

import { Grid, TextField } from "@mui/material";

import { useStyles } from "./styles";

export default function TextInputView(props) {
  const {
    label,
    disabled = false,
    questionKey,
    multiline = false,
    value,
    xs = 12,
    md = 6,
    lg = 3,
    updateFunction,
  } = props;
  const classes = useStyles()();
  const [val, setVal] = React.useState("");

  React.useEffect(() => {
    // set val initially after updateHA loads
    if (val === "" && value) {
      setVal(value);
    }

    // clear input
    if (value === "") {
      setVal("");
    }
  }, [value]);

  const onChange = (event) => {
    setVal(event.target.value);
  };

  const onUpdate = () => {
    updateFunction(val);
  };

  return (
    <Grid
      item
      key={questionKey}
      xs={xs}
      sm={md}
      lg={lg}
      className={classes.borderedBox}
    >
      <TextField
        disabled={disabled}
        value={val}
        variant="standard"
        label={label}
        multiline={multiline}
        onChange={onChange}
        onBlur={onUpdate}
        style={{
          width: "100%",
        }}
      />
    </Grid>
  );
}
