import { makeStyles } from "@mui/styles";

const useStyles = () =>
  makeStyles(() => ({
    root: {
      maxHeight: "100%",
      width: "100%",
    },
  }));

export default useStyles;
