import * as React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import AttachFileIcon from "@mui/icons-material/Delete";
import { Typography } from "@mui/material";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";

const styles = () => ({
  root: {},
  imageContainer: {
    position: "relative",
    zIndex: 10,
    textAlign: "center",
    "&:hover $image": {
      opacity: 0.3,
    },
    "&:hover $removeButton": {
      opacity: 1,
    },
    padding: "20px",
  },
  image: {
    maxWidth: "100%",
    width: "auto",
    maxHeight: "100%",
    height: "100px",
  },
  removeButton: {
    transition: ".5s ease",
    position: "absolute",
    top: 0,
    right: 0,
    opacity: 0,
    width: 40,
    height: 40,
    "&:focus": {
      opacity: 1,
    },
  },
});

function PreviewList({
  form,
  fileObjects,
  classes,
  handleFilesChange,
  updateObsKeyFunction,
}) {
  const isImage = (file) => {
    if (file.type && file.type.split("/")[0] === "image") {
      return true;
    }
  };

  const getPreviewIcon = (fileObject, classes) => {
    if (isImage(fileObject.originalFile)) {
      return (
        <img
          className={classes.image}
          src={URL.createObjectURL(fileObject.originalFile)}
          alt="Observation image"
        />
      );
    }

    if (fileObject.originalFile.id) {
      return (
        <img
          className={classes.image}
          src={fileObject.originalFile.file}
          alt="Observation image"
        />
      );
    }

    return <AttachFileIcon className={classes.image} />;
  };

  const handleRemove = (fileIndex) => (event) => {
    event.stopPropagation();
    const arr = [...fileObjects];
    if (arr[fileIndex].originalFile) {
      updateObsKeyFunction(
        "removeMediaFiles",
        form.removeMediaFiles
          ? [...form.removeMediaFiles, arr[fileIndex].originalFile.id]
          : [arr[fileIndex].originalFile.id]
      );
    }
    arr.splice(fileIndex, 1);
    handleFilesChange(arr);
  };

  const getLabel = (item) => {
    if (item.m && item.m.id) {
      return `${item.m.name} [${item.r.name}] [${item.a.name}]`;
    } else if (item.r && item.r.id) {
      return `${item.r.name} [${item.a.name}]`;
    } else {
      return `${item.a.name}`;
    }
  };

  return (
    <>
      {fileObjects &&
        fileObjects.map((fileObject, i) => {
          return (
            <Grid
              item={true}
              key={`${fileObject.file?.name ?? "file"}-${i}`}
              className={classes.imageContainer}
            >
              {getPreviewIcon(fileObject, classes)}
              <Typography style={{ fontSize: "10px", maxWidth: "100px" }}>
                {getLabel(fileObject.associatedItem)}
              </Typography>

              <Fab
                onClick={handleRemove(i)}
                aria-label="Delete"
                className={classes.removeButton}
              >
                <DeleteIcon />
              </Fab>
            </Grid>
          );
        })}
    </>
  );
}

export default withStyles(styles, { name: "MuiDropzonePreviewList" })(
  PreviewList
);
