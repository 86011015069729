import React from "react";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Grid, IconButton, useTheme } from "@mui/material";

import { useQuery } from "@apollo/client";

import { GET_COMPANIES } from "../../../../../graphql/queries";
import {
  COMPANY_DASHBOARD,
  GET_USERS,
} from "../../../../../graphql/queries/admin/company";
import { GET_CREWMEMBERS } from "../../../../../graphql/queries/admin/planning-site";
import { GET_SEARCH_TEMPLATES } from "../../../../../graphql/queries/ha";
import removeDuplicates from "../../../../../utils/removeDuplicates";
import {
  COMPANY_ADMIN_CONTRACTED_SITES,
  COMPANY_ADMIN_CREWMEMBERS,
  COMPANY_ADMIN_OSP,
  COMPANY_ADMIN_TEMPLATES,
  COMPANY_ADMIN_USERS,
} from "../../companyAdminQueries";
import DashboardCard from "./CarouselCard";
import useStyles from "./styles";

const MainCard = ({ card, index, activeIndex, company, extraData }) => {
  if (index >= activeIndex && index < activeIndex + 2) {
    return (
      <Grid item xs={5} style={{ paddingTop: "0px" }}>
        <DashboardCard
          title={card.name}
          subtitle={card.name}
          data={card.data}
          company={company}
          loading={card.loading}
          extraData={extraData}
        />
      </Grid>
    );
  }
  return null;
};

export default function Carousel({ company }) {
  const classes = useStyles();
  const theme = useTheme();

  // COMPANY DATA
  const { data: osp } = useQuery(COMPANY_ADMIN_OSP, {
    variables: { id: Number(company.id) },
  });
  const { data: users } = useQuery(COMPANY_ADMIN_USERS, {
    variables: { id: Number(company.id) },
  });
  const { data: templates } = useQuery(COMPANY_ADMIN_TEMPLATES, {
    variables: { id: Number(company.id) },
  });
  const { data: observedSites } = useQuery(COMPANY_ADMIN_CONTRACTED_SITES, {
    variables: { id: Number(company.id) },
  });
  const { data: crew } = useQuery(COMPANY_ADMIN_CREWMEMBERS, {
    variables: { id: Number(company.id) },
  });

  // EXTRA DATA FOR CREATE/EDIT
  const { data: owners, loading: loadingOwners } = useQuery(COMPANY_DASHBOARD, {
    fetchPolicy: "network-only",
  });
  const { data: allUsers, loading: loadingUsers } = useQuery(GET_USERS, {
    fetchPolicy: "network-only",
  });
  const { data: companies, loading: loadingCompanies } = useQuery(
    GET_COMPANIES,
    { fetchPolicy: "network-only" }
  );
  const { data: allCrew, loading: loadingCrew } = useQuery(GET_CREWMEMBERS, {
    fetchPolicy: "network-only",
  });
  const { data: allTemplates, loading: loadingTemplates } = useQuery(
    GET_SEARCH_TEMPLATES,
    { fetchPolicy: "network-only" }
  );

  const [activeIndex, changeActive] = React.useState(0);
  const [cardList, setCardList] = React.useState([
    {
      name: "Users",
      data: [],
      loading: true,
    },
    {
      name: "Supervisors",
      data: [],
      loading: true,
    },
    {
      name: "Owned Sites",
      data: [],
      loading: true,
    },
    {
      name: "Projects",
      data: [],
      loading: true,
    },
    {
      name: "Observed Sites",
      data: [],
      loading: true,
    },
    {
      name: "Contractors",
      data: [],
      loading: true,
    },
    {
      name: "Templates",
      data: [],
      loading: true,
    },
    {
      name: "CrewMembers",
      data: [],
      loading: true,
    },
  ]);

  React.useEffect(() => {
    if (osp) {
      setCardList((prev) =>
        prev.map((p) => {
          if (p.name === "Owned Sites") {
            return {
              ...p,
              data: osp.ownerSiteProjects
                .filter((osp) => !osp.project && osp.site)
                .sort((a, b) => (a.site.name > b.site.name ? 1 : -1)),
              loading: false,
            };
          } else return p;
        })
      );

      const removeDuplicateProjects = (projects) => {
        const uniqueIds = [];
        const filtered = projects.filter((c) => {
          const isDuplicate = uniqueIds.includes(c.project.id);
          if (!isDuplicate) {
            uniqueIds.push(c.project.id);
            return true;
          }
          return false;
        });
        return filtered;
      };

      setCardList((prev) =>
        prev.map((p) => {
          if (p.name === "Projects") {
            return {
              ...p,
              data: removeDuplicateProjects(
                osp.ownerSiteProjects
                  .filter((osp) => osp.project)
                  .sort((a, b) => (a.project.name > b.project.name ? 1 : -1))
              ),
              loading: false,
            };
          } else return p;
        })
      );

      const removeDuplicateContractors = (contractors) => {
        const uniqueIds = [];
        const filtered = contractors.filter((c) => {
          const isDuplicate = uniqueIds.includes(c.contractor.id);
          if (!isDuplicate) {
            uniqueIds.push(c.contractor.id);
            return true;
          }
          return false;
        });
        return filtered;
      };

      let contractors = [];
      osp.ownerSiteProjects.forEach((d) => {
        contractors = [
          ...contractors,
          ...d.ownerSiteProjectContractor.map((ospc) => ({
            owner: d.owner,
            ...ospc,
          })),
        ];
      });
      contractors = removeDuplicateContractors(contractors)
        .filter((c) => c.contractor.id !== company.id)
        .sort((a, b) => (a.contractor.name > b.contractor.name ? 1 : -1));

      setCardList((prev) =>
        prev.map((p) => {
          if (p.name === "Contractors") {
            return {
              ...p,
              data: contractors,
              loading: false,
            };
          } else return p;
        })
      );
    }
  }, [osp]);

  React.useEffect(() => {
    if (users) {
      setCardList((prev) =>
        prev.map((p) => {
          if (p.name === "Users") {
            return {
              ...p,
              data: [...users.users].sort((a, b) =>
                a.username.toLowerCase() > b.username.toLowerCase() ? 1 : -1
              ),
              loading: false,
            };
          } else return p;
        })
      );

      setCardList((prev) =>
        prev.map((p) => {
          if (p.name === "Supervisors") {
            return {
              ...p,
              data: users.users
                .filter((u) => u.supervise.filter((s) => s.isActive).length > 0)
                .sort((a, b) =>
                  a.username.toLowerCase() > b.username.toLowerCase() ? 1 : -1
                ),
              loading: false,
            };
          } else return p;
        })
      );
    }
  }, [users]);

  React.useEffect(() => {
    if (templates) {
      setCardList((prev) =>
        prev.map((p) => {
          if (p.name === "Templates") {
            return {
              ...p,
              data: removeDuplicates(
                [...templates.ownerSiteProjectTemplates].sort((a, b) =>
                  a.template.name > b.template.name ? 1 : -1
                ),
                "template",
                "id"
              ),
              loading: false,
            };
          } else return p;
        })
      );
    }
  }, [templates]);

  React.useEffect(() => {
    if (observedSites) {
      setCardList((prev) =>
        prev.map((p) => {
          if (p.name === "Observed Sites") {
            return {
              ...p,
              data: [...observedSites.ownerSiteProjectContractors].sort(
                (a, b) =>
                  a.ownerSiteProject.site.name > b.ownerSiteProject.site.name
                    ? 1
                    : -1
              ),
              loading: false,
            };
          } else return p;
        })
      );
    }
  }, [observedSites]);

  React.useEffect(() => {
    if (crew) {
      setCardList((prev) =>
        prev.map((p) => {
          if (p.name === "CrewMembers") {
            return {
              ...p,
              data: [...crew.crewMembers].sort((a, b) =>
                `${a.firstName.toLowerCase}${a.lastName.toLowerCase}` >
                `${b.firstName.toLowerCase}${b.lastName.toLowerCase}`
                  ? 1
                  : -1
              ),
              loading: false,
            };
          } else return p;
        })
      );
    }
  }, [crew]);

  const handleRightClick = () => {
    if (activeIndex + 2 < cardList.length) {
      changeActive(activeIndex + 2);
    } else {
      changeActive(0);
    }
  };

  const handleLeftClick = () => {
    if (activeIndex - 2 >= 0) {
      changeActive(activeIndex - 2);
    } else {
      let remainder = cardList.length % 2;
      if (remainder === 0) {
        remainder = 2;
      }
      changeActive(cardList.length - remainder);
    }
  };

  const renderDots = () => {
    let numDots = cardList.length / 2;
    if (cardList.length === 0) {
      numDots = 0;
    } else if (numDots < 1) {
      numDots = 1;
    } else {
      numDots = Math.ceil(numDots);
    }

    const arr = new Array(numDots).fill("");
    const currentPage = Math.floor(activeIndex / 2);
    return arr.map((dot, index) => {
      if (index === currentPage) {
        return (
          <div
            key={`current-${index}`}
            style={{
              backgroundColor: "#F3CA3E",
              borderRadius: 10,
              width: "10px",
              height: "10px",
              margin: "10px 5px",
            }}
          />
        );
      } else {
        return (
          <div
            key={`other-${index}`}
            style={{
              backgroundColor: "#CDCDCD",
              borderRadius: 10,
              width: "10px",
              height: "10px",
              margin: "10px 5px",
            }}
          />
        );
      }
    });
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.root}
      spacing={3}
    >
      <Grid item xs={1} container justifyContent="flex-end">
        <IconButton onClick={handleLeftClick}>
          <ArrowBackIosNewIcon style={{ color: theme.palette.grey2.main }} />
        </IconButton>
      </Grid>
      {cardList.map((card, index) => (
        <MainCard
          key={`card-${index}`}
          card={card}
          index={index}
          activeIndex={activeIndex}
          company={company}
          extraData={{
            owners: owners ? owners : { companies: [] },
            loadingOwners,
            templates: allTemplates ? allTemplates : { templates: [] },
            loadingTemplates,
            crew: allCrew ? allCrew : { crewMembers: [] },
            loadingCrew,
            users: allUsers ? allUsers : { users: [] },
            loadingUsers,
            companies: companies ? companies : { companies: [] },
            loadingCompanies,
          }}
        />
      ))}
      <Grid item xs={1} style={{ paddingLeft: "18px" }}>
        <IconButton onClick={handleRightClick}>
          <ArrowForwardIosIcon style={{ color: theme.palette.grey2.main }} />
        </IconButton>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: "0px" }}>
        <Grid container justifyContent="center">
          {renderDots()}
        </Grid>
      </Grid>
    </Grid>
  );
}
