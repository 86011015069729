import React from "react";
import { useNavigate } from "react-router-dom";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  List,
  ListItemText,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItemButton,
} from "@mui/material";
import { useTheme } from "@mui/styles";

export default function Planning({
  textSelectedColor,
  endBarSelectedColor,
  classes,
  permissions,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const expandIconColor = open ? endBarSelectedColor : theme.palette.grey1.main;

  let siteItem;
  if (
    permissions.includes("CREATE_SITE") ||
    permissions.includes("EDIT_SITE")
  ) {
    siteItem = (
      <ListItemButton onClick={() => navigate("/admin/planning/site")}>
        <ListItemText primary="Site" className={classes.listText} />
      </ListItemButton>
    );
  }

  let projectItem;
  if (
    permissions.includes("CREATE_PROJECT") ||
    permissions.includes("EDIT_PROJECT")
  ) {
    projectItem = (
      <ListItemButton onClick={() => navigate("/admin/planning/project")}>
        <ListItemText primary="Project" className={classes.listText} />
      </ListItemButton>
    );
  }

  let sopItem;
  if (
    permissions.includes("CREATE_TEMPLATE") ||
    permissions.includes("EDIT_TEMPLATE")
  ) {
    sopItem = (
      <ListItemButton onClick={() => navigate("/admin/planning/template")}>
        <ListItemText primary="Template" className={classes.listText} />
      </ListItemButton>
    );
  }

  return (
    <Accordion disableGutters style={{ width: "100%" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: expandIconColor }} />}
        aria-controls="planning-content"
        id="planning-header"
        style={{
          backgroundColor: theme.palette.black0.main,
          color: theme.palette.grey1.main,
          padding: 0,
        }}
        onClick={handleClick}
      >
        <Typography
          className={classes.listText}
          style={{
            color: open ? textSelectedColor : undefined,
            paddingLeft: "16px",
          }}
        >
          Planning
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{
          backgroundColor: theme.palette.black0.main,
          paddingBottom: "0px",
        }}
      >
        <List>
          {siteItem}
          {projectItem}
          {sopItem}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}
