import React from "react";
import { useParams } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";

import CheckIcon from "@mui/icons-material/Check";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";

import { permissionsVar } from "../../../../graphql/localVariables/user";
import {
  MUTATE_ACTION_RISK_MITIGATOR,
  MUTATE_RISK,
} from "../../../../graphql/mutations/admin/arm";
import {
  GET_MIT_BY_COMPANY_RISK_ADMIN,
  GET_RISKS_BY_COMPANY_ACTION_ADMIN,
} from "../../../../graphql/queries/admin/arm";
import { GET_CURRENT_USER } from "../../../../graphql/queries/auth";
import {
  onErrorFunc,
  onCompletedFunc,
} from "../../../CustomComponents/OnErrorFunction";
import SearchField from "../../../CustomComponents/SearchField";
import { CustomSwitch } from "../../../CustomComponents/Switch";
import { yellowButton } from "../../../CustomStyles/buttons";
import { CustomDialog } from "../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../CustomStyles/pageTitle";
import RiskScoreInput from "../RiskScoreInput";
import { mapScoreToString } from "./Action";

function AssociatedMitigator({ mitigator, remove, changeValue }) {
  const handleChange = (newVal) => {
    changeValue(mitigator.m.id, "riskScore", newVal);
  };

  return (
    <Grid
      key={mitigator.id}
      container
      style={{
        padding: "10px",
        height: "fit-content",
      }}
      alignItems="center"
      justifyContent="flex-start"
    >
      <Grid item xs={1}>
        <IconButton
          style={{ marginRight: "10px" }}
          onClick={() => remove(mitigator)}
        >
          <CheckIcon />
        </IconButton>
      </Grid>
      <Grid item xs={6}>
        <Typography style={{ color: "black" }}>{mitigator.m.name}</Typography>
      </Grid>
      <Grid item xs={5}>
        <RiskScoreInput
          value={mitigator.riskScore}
          onChange={handleChange}
          range={mitigator.riskLevelRange}
        />
      </Grid>
    </Grid>
  );
}

export default function ActionRiskMitigators({
  action,
  risk,
  riskScore,
  mitigators,
  open: openDialog,
  handleClose: handleCloseDialog,
}) {
  const { companyId } = useParams();
  const permissions = useReactiveVar(permissionsVar);
  const [getAssociatedMitigators, { data: associatedMit, loading }] =
    useLazyQuery(GET_MIT_BY_COMPANY_RISK_ADMIN, {
      fetchPolicy: "network-only",
    });
  const { data: user } = useQuery(GET_CURRENT_USER);

  const [mutateRisk] = useMutation(MUTATE_RISK, {
    onCompleted() {
      onCompletedFunc("Risk has been updated");
      setOpen(false);
    },
    onError(error) {
      onErrorFunc(error);
    },
  });

  React.useEffect(() => {
    if (openDialog && user) {
      getAssociatedMitigators({
        variables: {
          riskId: Number(risk.r.id),
          actionId: Number(action.a.id),
          companyId: companyId
            ? Number(companyId)
            : Number(user.currentUser.company.id),
        },
      });
    }
  }, [openDialog, user]);

  const [mutateRiskBatchMit] = useMutation(MUTATE_ACTION_RISK_MITIGATOR, {
    onCompleted() {
      onCompletedFunc("Associated mitigators have been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [
      {
        query: GET_RISKS_BY_COMPANY_ACTION_ADMIN,
        fetchPolicy: "network-only",
        variables: {
          companyId: companyId
            ? Number(companyId)
            : Number(user.currentUser.company.id),
          actionId: Number(action.a.id),
        },
      },
    ],
  });

  // RISK STATE
  const [isActive, setIsActive] = React.useState(risk.r.isActive);
  const [riskName, setRiskName] = React.useState(risk.r.name);
  const [associatedMitigators, setAssociatedMitigators] = React.useState([]);
  const [addedMitigators, setAddedMitigators] = React.useState([]);
  const [mitSearch, setMitSearch] = React.useState("");

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (associatedMit) {
      setAssociatedMitigators(
        associatedMit.companyArms.map((carm) => ({
          ...carm.arm,
          riskLevelRange: carm.riskLevelRange,
        }))
      );
    }
  }, [associatedMit]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseRisk = () => {
    setAddedMitigators([]);
    handleCloseDialog();
  };

  const changeExistingValue = (id, key, val) => {
    setAssociatedMitigators((prev) =>
      prev.map((p) => {
        if (p.m.id === id) {
          const copy = { ...p };
          copy[key] = val;
          return copy;
        } else return p;
      })
    );
  };

  const changeAddedValue = (id, key, val) => {
    setAddedMitigators((prev) =>
      prev.map((p) => {
        if (p.m.id === id) {
          const copy = { ...p };
          copy[key] = val;
          return copy;
        } else return p;
      })
    );
  };

  const onSave = () => {
    const assoArmInfo = associatedMitigators.map((r) => {
      return {
        mitigatorId: Number(r.m.id),
        riskScore: r.riskScore !== null ? Number(r.riskScore) : null,
      };
    });
    const addedArmInfo = addedMitigators.map((r) => {
      return {
        mitigatorId: Number(r.m.id),
        riskScore: r.riskScore !== null ? Number(r.riskScore) : null,
      };
    });
    const unassociated =
      associatedMit &&
      associatedMit.companyArms
        .filter(
          (m) =>
            associatedMitigators.findIndex((arm) => arm.m.id === m.arm.m.id) <
              0 &&
            addedMitigators.findIndex((arm) => arm.m.id === m.arm.m.id) < 0
        )
        .map((m) => Number(m.arm.id));

    const allMitigators = [...assoArmInfo, ...addedArmInfo];

    if (
      permissions.includes("EDIT_ARM_RISK_SCORES") &&
      allMitigators.find((r) => r.riskScore === null)
    ) {
      onErrorFunc("Score all mitigators before you can save your changes.");
    } else if (
      permissions.includes("EDIT_ARM_RISK_SCORES") &&
      allMitigators.find((r) =>
        r.riskScore !== null ? r.riskScore > riskScore : false
      )
    ) {
      onErrorFunc(
        `Scores for the mitigators must be less than or equal to the risk's score ${mapScoreToString(
          riskScore
        )}. Fix this before you can save your changes.`
      );
    } else {
      mutateRiskBatchMit({
        variables: {
          action: Number(action.a.id),
          risk: Number(risk.r.id),
          armInfo: allMitigators,
          unassociatedRisks: unassociated,
          company: companyId
            ? Number(companyId)
            : user && user.currentUser
            ? Number(user.currentUser.company.id)
            : null,
        },
      });

      handleCloseRisk();
    }
  };

  const saveEdit = () => {
    mutateRisk({
      variables: {
        input: {
          id: risk.id,
          name: riskName,
          isActive,
        },
      },
    });
    handleClose();
  };

  const onCancel = () => {
    setAssociatedMitigators([]);
    setAddedMitigators([]);
    handleCloseDialog();
  };

  const add = (m) => {
    setAddedMitigators((prev) => [
      ...prev,
      { riskScore: null, m: m.m, added: true },
    ]);
  };

  const remove = (m) => {
    setAddedMitigators((prev) => prev.filter((p) => p.m.id !== m.m.id));
    setAssociatedMitigators((prev) => prev.filter((p) => p.m.id !== m.m.id));
  };

  const [filteredData, setFilteredData] = React.useState([]);

  // useEffect for filter update
  React.useEffect(() => {
    setFilteredData(
      mitigators
        ? mitigators.filter(
            (m) =>
              associatedMitigators.findIndex(
                (arm) => Number(arm.m.id) === Number(m.m.id)
              ) < 0 &&
              addedMitigators.findIndex(
                (arm) => Number(arm.m.id) === Number(m.m.id)
              ) < 0 &&
              m.m.name.toLowerCase().includes(mitSearch.toLowerCase())
          )
        : []
    );
  }, [mitigators, mitSearch, associatedMitigators, addedMitigators]);

  const getRow = (item) => {
    return (
      <Grid
        item
        xs={12}
        key={item.id}
        style={{
          padding: "10px",
        }}
        container
        alignItems="center"
      >
        <Grid item xs={2}>
          <IconButton style={{ marginRight: "10px" }} onClick={() => add(item)}>
            <CheckBoxOutlineBlankIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <Typography>{item.m.name}</Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <CustomDialog
        open={openDialog}
        fullWidth
        maxWidth="lg"
        onClose={handleCloseRisk}
      >
        <DialogTitle
          style={{
            ...pageTitleStyles,
          }}
        >
          EDIT RISK: {risk.r.name.toUpperCase()} [{action.a.name.toUpperCase()}
          ].
        </DialogTitle>
        <DialogContent>
          <Grid container>
            {user && user.currentUser.isStaff && (
              <Grid item xs={12} container justifyContent="flex-end">
                <Grid item>
                  <Button
                    variant="contained"
                    style={{ zIndex: 1000, ...yellowButton }}
                    endIcon={<EditIcon />}
                    onClick={handleOpen}
                  >
                    EDIT GLOBAL
                  </Button>
                </Grid>
              </Grid>
            )}
            <Grid item xs={7}>
              <Typography style={{ color: "white" }}>
                ASSOCIATED MITIGATORS:
              </Typography>
              {loading ? (
                <Grid
                  container
                  style={{ width: "100%" }}
                  justifyContent="center"
                >
                  <CircularProgress color="secondary" />
                </Grid>
              ) : (
                <Grid
                  container
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    backgroundColor: "white",
                    color: "black",
                    width: "90%",
                    marginTop: "10px",
                    borderRadius: 5,
                  }}
                >
                  {associatedMitigators.length === 0 &&
                    addedMitigators.length === 0 && (
                      <Typography style={{ padding: "20px" }}>
                        No associated mitigators.
                      </Typography>
                    )}
                  {[...associatedMitigators, ...addedMitigators]
                    .sort((a, b) => (a.m.name > b.m.name ? 1 : -1))
                    .map(
                      (mit) =>
                        mit.m && (
                          <AssociatedMitigator
                            key={mit.m.id}
                            mitigator={mit}
                            remove={remove}
                            changeValue={
                              mit.added ? changeAddedValue : changeExistingValue
                            }
                            riskScore={riskScore}
                          />
                        )
                    )}
                </Grid>
              )}
            </Grid>
            <Grid item xs={5}>
              <Typography style={{ color: "white" }}>
                UNASSOCIATED MITIGATORS:
              </Typography>
              <SearchField value={mitSearch} setValue={setMitSearch} />
              <Grid
                container
                style={{
                  backgroundColor: "white",
                  color: "black",
                  marginTop: "10px",
                  borderRadius: 5,
                }}
              >
                <Grid item xs={12}>
                  <Virtuoso
                    style={{ height: 300 }}
                    data={filteredData}
                    itemContent={(index, item) => getRow(item)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ marginRight: "10px", ...yellowButton }}
            onClick={onSave}
          >
            SAVE
          </Button>
          <Button variant="contained" onClick={onCancel}>
            CANCEL
          </Button>
        </DialogActions>
      </CustomDialog>
      <Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose}>
        <DialogTitle style={{ ...pageTitleStyles, color: "black" }}>
          EDIT RISK.
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems="center">
            {permissions.includes("ARM") && (
              <Grid item container alignItems="center">
                <FormControlLabel
                  style={{ color: "black" }}
                  control={
                    <CustomSwitch
                      checked={isActive}
                      onChange={(event) => setIsActive(event.target.checked)}
                    />
                  }
                  label={isActive ? "ACTIVE" : "INACTIVE"}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                variant="standard"
                value={riskName}
                label="Risk Name:"
                onChange={(event) => setRiskName(event.target.value)}
                style={{ width: "100%" }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ marginRight: "10px", ...yellowButton }}
            onClick={saveEdit}
          >
            SAVE
          </Button>
          <Button
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={handleClose}
          >
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
