import React from "react";

import {
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  Typography,
} from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import {
  createIncidentVar,
  editIncidentVar,
} from "../../../../graphql/localVariables/investigation";

export default function RadioButtonsGroup({ question, type, updateState }) {
  // Global Variables
  const editObs = useReactiveVar(editIncidentVar);
  const createObs = useReactiveVar(createIncidentVar);
  const form = type === "EDIT" ? editObs : createObs;

  const [foundRes, setFoundRes] = React.useState({
    incidentQuestion: { id: question.id },
    boolean: null,
  });

  const handleChange = (event) => {
    // update State

    updateState({
      incidentQuestion: { id: question.id },
      boolean: event.target.value,
    });

    setFoundRes({
      incidentQuestion: { id: question.id },
      boolean: event.target.value,
    });
  };

  React.useEffect(() => {
    const doesExist = form.incidentQuestionResponses.find(
      (res) =>
        question.id === res.incidentQuestion?.id &&
        question.type === res.incidentQuestion?.type
    );
    if (doesExist) {
      setFoundRes({
        incidentQuestion: { id: question.id },
        boolean: doesExist.boolean,
      });
    } else {
      setFoundRes({
        incidentQuestion: { id: question.id },
        boolean: null,
      });
    }
  }, [form.incidentTopicResponses]);

  return (
    <Grid item xs={12} md={4} sx={{ padding: "10px 10px 50px 10px" }}>
      <Typography id={`${question.id}-text`}>{question.name}</Typography>
      <FormControl id={`${question.id}-options`}>
        <RadioGroup
          aria-labelledby="boolean-radio-buttons-group-label"
          defaultValue="options"
          name="boolean-radio-buttons-group"
          onChange={handleChange}
          value={foundRes.boolean}
        >
          <FormControlLabel
            key={`${question.id}-True`}
            value={true}
            control={<Radio />}
            label={"Yes"}
          />
          <FormControlLabel
            key={`${question.id}-False`}
            value={false}
            control={<Radio />}
            label={"No"}
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}
