import React from "react";

import { Grid, Typography, TextField, FormControlLabel } from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import { createHAVar, editHAVar } from "../../../graphql/localVariables/ha";
import { haMutations } from "../../../graphql/mutations";
import { CustomSwitch } from "../../CustomComponents/Switch";
import { useStyles } from "./styles";

export default function InjuryView({ type }) {
  const editHA = useReactiveVar(editHAVar);
  const createHA = useReactiveVar(createHAVar);
  const updateHA = type === "EDIT" ? editHA : createHA;
  const { updateCreateHA, updateEditHA } = haMutations;
  const updateHAFunction = type === "EDIT" ? updateEditHA : updateCreateHA;

  const [text, setText] = React.useState(updateHA.injuryText || "");

  const classes = useStyles()();

  const handleChange = (event) => {
    updateHAFunction("hasInjury", event.target.checked);
  };

  const onChangeText = (event) => {
    setText(event.target.value);
  };

  const setState = () => {
    updateHAFunction("injuryText", text);
  };

  return (
    <Grid container className={classes.borderedBox}>
      <Grid item xs={6}>
        <Typography>
          Were there any incidents associated with this HA?
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          style={{ color: "black" }}
          control={
            <CustomSwitch
              checked={updateHA.hasInjury || false}
              onChange={handleChange}
            />
          }
          label={updateHA.hasInjury || false ? "YES" : "NO"}
        />
      </Grid>

      <Grid item key={"incident"} xs={12} style={{ marginTop: "10px" }}>
        <TextField
          value={text}
          variant="standard"
          label={"Please describe the incident:"}
          multiline={true}
          onChange={onChangeText}
          onBlur={setState}
          style={
            updateHA.hasInjury
              ? {
                  width: "100%",
                }
              : {
                  display: "none",
                  width: "100%",
                }
          }
        />
      </Grid>
    </Grid>
  );
}
