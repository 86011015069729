import React from "react";
import { useNavigate } from "react-router-dom";

import { Button, Grid, Typography } from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import { permissionsVar } from "../../../../graphql/localVariables/user";
import { yellowButton } from "../../../CustomStyles/buttons";
import { pageTitleStyles } from "../../../CustomStyles/pageTitle";
import Layout from "../../../Layout";
import CreateTemplate from "./CreateTemplate";
import Table from "./Table";

export default function TemplateAdmin() {
  const navigate = useNavigate();
  const permissions = useReactiveVar(permissionsVar);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (
      permissions.length === 0 ||
      (permissions.length > 0 &&
        !permissions.includes("VIEW_PLANNING_ADMINISTRATION"))
    ) {
      navigate("/");
    }
  }, []);

  return (
    <Layout>
      <Grid
        style={{
          height: "100%",
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography sx={pageTitleStyles}>
            PLANNING ADMIN - TEMPLATE.
          </Typography>

          {permissions.includes("CREATE_TEMPLATE") && (
            <Button
              variant="contained"
              style={{
                marginRight: "10px",
                ...yellowButton,
                height: "fit-content",
              }}
              onClick={() => setOpen(true)}
            >
              CREATE TEMPLATE
            </Button>
          )}
        </Grid>
        <Table />
        <CreateTemplate open={open} handleClose={() => setOpen(false)} />
      </Grid>
    </Layout>
  );
}
