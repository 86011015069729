import { gql } from "@apollo/client";

export const MUTATE_ARM = gql`
  mutation mutateARM($input: ActionRiskMitigatorMutationInput!) {
    actionRiskMitigator(input: $input) {
      actionRiskMitigator {
        id
      }
      errors {
        messages
      }
    }
  }
`;

export const MUTATE_CATEGORY = gql`
  mutation mutateCategory($input: CategoryMutationInput!) {
    category(input: $input) {
      category {
        id
      }
      errors {
        messages
      }
    }
  }
`;

/*
Used in:
frontend/src/components/Admin/ARM/Action/Action.jsx

armInfo: [
  {riskId: 11, riskScore: 0.3}
  {riskId: 12, isExpected: true}
]
*/
export const MUTATE_ACTION_MUTATION = gql`
  mutation mutateAction(
    $id: ID
    $isActive: Boolean
    $riskScore: Float
    $company: ID
  ) {
    actionMutations(
      id: $id
      isActive: $isActive
      riskScore: $riskScore
      company: $company
    ) {
      action {
        id
      }
    }
  }
`;

export const MUTATE_ACTION_RISKS = gql`
  mutation mutateActionRisks(
    $action: ID
    $armInfo: [ARMInputType]
    $unassociatedRisks: [ID]
    $company: ID
  ) {
    riskRelationship(
      action: $action
      armInfo: $armInfo
      unassociatedRisks: $unassociatedRisks
      company: $company
    ) {
      arms {
        id
      }
      errors {
        messages
      }
    }
  }
`;

export const MUTATE_ACTION = gql`
  mutation globalActionMutation($name: String, $id: ID, $isActive: Boolean) {
    globalActionMutation(name: $name, id: $id, isActive: $isActive) {
      success
    }
  }
`;

export const MUTATE_RISK = gql`
  mutation globalRiskMutation($name: String, $id: ID, $isActive: Boolean) {
    globalRiskMutation(name: $name, id: $id, isActive: $isActive) {
      success
    }
  }
`;

export const MUTATE_MITIGATOR = gql`
  mutation globalMitigatorMutation($name: String, $id: ID, $isActive: Boolean) {
    globalMitigatorMutation(name: $name, id: $id, isActive: $isActive) {
      success
    }
  }
`;

export const APPROVE_ARM = gql`
  mutation approveARM($input: CompanyARMMutationInput!) {
    companyArm(input: $input) {
      companyARM {
        isApproved
        company {
          id
          name
        }
        arm {
          a {
            name
          }
          r {
            name
          }
          m {
            name
          }
        }
      }
    }
  }
`;

// isActive is required
export const CREATE_CATEGORY = gql`
  mutation createCategory($input: CategoryMutationInput!) {
    category(input: $input) {
      category {
        id
        name
        isActive
      }
    }
  }
`;

export const CREATE_ACTION = gql`
  mutation createAction(
    $categories: [ID]
    $company: ID!
    $companyArm_IsApproved: Boolean!
    $companyArm_Exclusive: Boolean!
    $id: ID
    $isActive: Boolean
    $name: String!
    $riskScore: Float
    $user: ID
  ) {
    actionMutations(
      categories: $categories
      company: $company
      companyArm_IsApproved: $companyArm_IsApproved
      companyArm_Exclusive: $companyArm_Exclusive
      id: $id
      name: $name
      riskScore: $riskScore
      isActive: $isActive
      user: $user
    ) {
      arm {
        id
        isActive
        isExpected
        a {
          id
          name
        }
        r {
          id
          name
        }
        m {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_RISK = gql`
  mutation createRisk(
    $action: ID!
    $company: ID!
    $companyArm_IsApproved: Boolean!
    $companyArm_Exclusive: Boolean!
    $id: ID
    $isActive: Boolean
    $name: String!
    $isExpected: Boolean!
    $riskScore: Float
    $user: ID
  ) {
    riskMutations(
      action: $action
      company: $company
      companyArm_IsApproved: $companyArm_IsApproved
      companyArm_Exclusive: $companyArm_Exclusive
      id: $id
      name: $name
      riskScore: $riskScore
      isExpected: $isExpected
      isActive: $isActive
      user: $user
    ) {
      arm {
        id
        isActive
        isExpected
        a {
          id
          name
        }
        r {
          id
          name
        }
        m {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_MITIGATOR = gql`
  mutation createMitigator(
    $action: ID!
    $risk: ID!
    $id: ID
    $company: ID!
    $companyArm_IsApproved: Boolean!
    $companyArm_Exclusive: Boolean!
    $isActive: Boolean
    $name: String!
    $riskScore: Float
    $user: ID
  ) {
    mitigatorMutations(
      action: $action
      risk: $risk
      id: $id
      company: $company
      companyArm_IsApproved: $companyArm_IsApproved
      companyArm_Exclusive: $companyArm_Exclusive
      name: $name
      riskScore: $riskScore
      isActive: $isActive
      user: $user
    ) {
      arm {
        id
        isActive
        isExpected
        a {
          id
          name
        }
        r {
          id
          name
        }
        m {
          id
          name
        }
      }
    }
  }
`;

export const MUTATE_CATEGORY_ACTION = gql`
  mutation categoryActions(
    $category: ID
    $associatedActions: [ID]
    $unassociatedActions: [ID]
  ) {
    categoryActionMutations(
      category: $category
      associatedActions: $associatedActions
      unassociatedActions: $unassociatedActions
    ) {
      categoryAction {
        id
      }
    }
  }
`;

/*
armInfo: [
  {mitigatorId: 29}
  {mitigatorId: 30, riskScore: 0.3}
]
*/
export const MUTATE_ACTION_RISK_MITIGATOR = gql`
  mutation actionRiskMitigator(
    $action: ID
    $risk: ID
    $armInfo: [ARMInputType]
    $unassociatedMitigators: [ID]
    $company: ID
  ) {
    mitigatorRelationship(
      action: $action
      risk: $risk
      armInfo: $armInfo
      unassociatedMitigators: $unassociatedMitigators
      company: $company
    ) {
      arms {
        id
      }
      errors {
        messages
      }
    }
  }
`;

export const ACTIVATE_ARM = gql`
  mutation actionRiskMitigator($arm: ID, $isActive: Boolean) {
    armMutations(arm: $arm, isActive: $isActive) {
      arm {
        id
      }
    }
  }
`;

export const ARM_SHARE = gql`
  mutation armShare(
    $category: ID
    $companies: [ID]
    $exclusive: Boolean
    $removedArmIds: [ID]
  ) {
    armShare(
      category: $category
      companies: $companies
      companyArm_Exclusive: $exclusive
      removedArmIds: $removedArmIds
    ) {
      __typename
      task
    }
  }
`;

export const MIT_MERGE = gql`
  mutation mitMerge($idKeep: ID, $idRemove: ID) {
    mergeMitigator(idToKeep: $idKeep, idToRemove: $idRemove) {
      message {
        success
        errors
      }
    }
  }
`;

export const BULK_APPROVE_COMPANY_ARM = gql`
  mutation BulkEditApprovedCompanyArm(
    $compArmsToApprove: [ID]
    $compArmsToDeny: [ID]
  ) {
    bulkCompanyArmIsApproved(
      compArmsToApprove: $compArmsToApprove
      compArmsToDeny: $compArmsToDeny
    ) {
      compArmsApproved {
        id
      }
      compArmsDenied {
        id
      }
    }
  }
`;

export const CAT_ACTION_COMP_ARM = gql`
  mutation catactioncomparm($cat: ID, $comp: ID) {
    categoryActionCompanyArm(category: $cat, company: $comp) {
      companyArm {
        id
        company {
          name
          id
        }
        arm {
          id
        }
      }
    }
  }
`;

export const CUSTOM_COMP_ARM_TOGGLE = gql`
  mutation CustomCompARMToggle(
    $company: ID
    $action: ID
    $risk: ID
    $mitigator: ID
    $isActive: Boolean
  ) {
    customCompArmToggle(
      company: $company
      action: $action
      risk: $risk
      mitigator: $mitigator
      isActive: $isActive
    ) {
      compArm {
        isActive
        company {
          name
          id
        }
        arm {
          a {
            name
            id
          }
          r {
            name
            id
          }
          m {
            name
            id
          }
        }
      }
    }
  }
`;

export const BULK_EDIT_RISK_SCORES = gql`
  mutation BulkEditRiskScores($armInputs: [ARMInputType]) {
    bulkEditRiskScores(armInputs: $armInputs) {
      errors {
        messages
      }
    }
  }
`;
