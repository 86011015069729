import { authMutations } from "../../graphql/mutations";

const { updateSnackbar } = authMutations;

export const onErrorFunc = (error) => {
  if (typeof error === "string") {
    updateSnackbar({
      open: true,
      message: error,
      severity: "error",
    });
  } else {
    if (error.errors && error.graphQLErrors[0].code === "unauthorized") {
      window.location.pathname = "/";
    }
    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      updateSnackbar({
        open: true,
        message: error.graphQLErrors[0].message,
        severity: "error",
      });
    } else {
      updateSnackbar({
        open: true,
        message: "There was an error. Please try again.",
        severity: "error",
      });
    }
  }
};

export const showErrorMessage = (message) => {
  updateSnackbar({
    open: true,
    message,
    severity: "error",
  });
};

export const onCompletedFunc = (message) => {
  updateSnackbar({
    open: true,
    message: message,
    severity: "success",
  });
};
