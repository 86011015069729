import React from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Grid, TextField, Autocomplete, Checkbox } from "@mui/material";

import { useLazyQuery, useReactiveVar } from "@apollo/client";
import moment from "moment";

import { createHAVar, editHAVar } from "../../../graphql/localVariables/ha";
import { onlineVar } from "../../../graphql/localVariables/user";
import { haMutations } from "../../../graphql/mutations";
import { GET_OFFLINE_HA_TEMPLATES } from "../../../graphql/queries/ha";
import { GET_TEMPLATE, GET_HA_TEMPLATES } from "../../../graphql/queries/ha";
import removeDuplicates from "../../../utils/removeDuplicates";
import { useStyles } from "./styles";

export default function TemplateInputView({ type, actions, risks }) {
  const editHA = useReactiveVar(editHAVar);
  const createHA = useReactiveVar(createHAVar);
  const updateHA = type === "EDIT" ? editHA : createHA;
  const online = useReactiveVar(onlineVar);
  const { updateCreateHA, updateEditHA } = haMutations;
  const updateHAFunction = type === "EDIT" ? updateEditHA : updateCreateHA;

  // Gets all parent templates PLUS templates for OSP IDs sent
  const [getHATemplates, { data, loading }] = useLazyQuery(GET_HA_TEMPLATES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const [
    getOfflineHATemplates,
    { data: offlineTemplates, loading: offlineTemplatesLoading },
  ] = useLazyQuery(GET_OFFLINE_HA_TEMPLATES);

  const [getTemp, { data: template }] = useLazyQuery(GET_TEMPLATE);

  const [offlineTemplateArms, setOfflineTemplateArms] = React.useState([]);
  const [templateData, setTemplateData] = React.useState([]);

  const classes = useStyles()();

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  React.useEffect(() => {
    let list = [];
    // Project OSP IDs
    if (updateHA.ospIds) {
      updateHA.ospIds.map((id) => Number(id)).forEach((id) => list.push(id));
    }
    if (updateHA.siteOSPId) {
      list.push(Number(updateHA.siteOSPId));
    }
    if (updateHA.ownerOSPId) {
      list.push(Number(updateHA.ownerOSPId));
    }

    if (online) {
      getHATemplates({
        variables: { ospIdList: list.map((l) => Number(l)) },
      });
    } else {
      getOfflineHATemplates({
        variables: { ospIdList: list.map((l) => Number(l)) },
      });
    }
  }, [updateHA.siteOSPId, updateHA.ownerOSPId, updateHA.ospIds]);

  const findRisks = (a) => {
    const allRisks = risks?.companyArms
      .map((carm) => carm.arm)
      .filter((haArm) => Number(haArm.a?.id) === Number(a?.id));

    const unique = (arr) =>
      arr
        ? arr.reduce((results, item) => {
            if (
              !results.some(
                (i) => i.r.name === item.r.name && i.r.id === item.r.id
              )
            ) {
              results.push(item);
            }
            return results;
          }, [])
        : [];
    return unique(allRisks);
  };

  React.useEffect(() => {
    if (online && template && template.templates) {
      const actionsToAdd = [];
      template.templates.forEach((temp) =>
        temp.templateArms.forEach((tArm) => {
          if (
            !tArm.arm.r &&
            !tArm.arm.m &&
            updateHA.actions.findIndex((a) => a.a.id === tArm.arm.a.id) < 0
          ) {
            actionsToAdd.push(tArm.arm);
          }
        })
      );
      updateHAFunction(
        "actions",
        [...updateHA.actions, ...actionsToAdd].map((arm, index) => ({
          ...arm,
          actionOrdering: index,
        }))
      );
    }
  }, [template, online]);

  React.useEffect(() => {
    if (!online && offlineTemplateArms && actions) {
      const actionsToAdd = [];
      const allActions = actions.companyArms.map((carm) => carm.arm);
      offlineTemplateArms.forEach((temp) => {
        if (temp.templateArmIds) {
          temp.templateArmIds.forEach((id) => {
            const foundArm = allActions.find(
              (a) => Number(a.id) === Number(id)
            );
            if (
              foundArm &&
              updateHA.actions.findIndex((a) => a.a.id === foundArm.a.id) < 0
            ) {
              actionsToAdd.push(foundArm);
            }
          });
        }
      });
      updateHAFunction(
        "actions",
        [...updateHA.actions, ...actionsToAdd].map((arm, index) => ({
          ...arm,
          actionOrdering: index,
        }))
      );
    }
  }, [online, actions, offlineTemplateArms]);

  // Updates expected risks from template
  React.useEffect(() => {
    if (risks) {
      const allExpectedRisks = [];
      updateHA.actions.forEach((action) => {
        const allRisks = findRisks(action.a);
        allRisks.forEach((r) => {
          if (
            r.isExpected &&
            updateHA.risks &&
            updateHA.risks.findIndex((risk) => risk.id === r.id) < 0
          ) {
            allExpectedRisks.push(r);
          }
        });
      });
      updateHAFunction("risks", [...updateHA.risks, ...allExpectedRisks]);
    }
  }, [updateHA.actions, risks]);

  React.useEffect(() => {
    if (updateHA.templates) {
      let tempIds = [];
      if (updateHA.templatesFromPDF) {
        tempIds = updateHA.templates.filter(
          (t) =>
            updateHA.templatesFromPDF.findIndex((temp) => temp.id === t.id) < 0
        );
      } else {
        tempIds = updateHA.templates;
      }
      if (online) {
        getTemp({
          variables: { ids: tempIds.map((t) => Number(t.id)) },
        });
      } else {
        setOfflineTemplateArms(tempIds);
      }
    }
  }, [updateHA.templates]);

  const handleChange = (event, newValue) => {
    updateHAFunction("templates", newValue);
  };

  const filterByDate = (arr) => {
    return arr.filter((a) => {
      if (!a.startDate && !a.endDate) return true;
      const startDate = moment(a.startDate);
      const endDate = moment(a.endDate);
      const todayDate = moment();

      return (
        todayDate.isSameOrAfter(startDate) && todayDate.isSameOrBefore(endDate)
      );
    });
  };

  React.useEffect(() => {
    setTemplateData(
      online
        ? data
          ? filterByDate([
              ...data.ownerSiteProjectTemplatesHa.map((ospt) => ospt.template),
            ]).sort((a, b) => (a.name > b.name ? 1 : -1))
          : []
        : offlineTemplates && offlineTemplates.filterHATemplates
        ? filterByDate([
            ...offlineTemplates.filterHATemplates.map((ospt) => ospt.template),
          ]).sort((a, b) => (a.name > b.name ? 1 : -1))
        : []
    );
  }, [online, data, offlineTemplates]);

  return (
    <Grid
      item
      key={"template"}
      xs={12}
      sm={6}
      lg={3}
      className={classes.borderedBox}
    >
      <Autocomplete
        disablePortal
        id="ha-select-templates"
        options={removeDuplicates(templateData, "id")}
        value={
          updateHA.templates ? removeDuplicates(updateHA.templates, "id") : []
        }
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={`${option.name}-${option.id}`}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </li>
        )}
        multiple
        loading={online ? loading : offlineTemplatesLoading}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={"Templates (Optional):"}
          />
        )}
      />
    </Grid>
  );
}

// function Contractor({ contractorID, type }) {
//   const editHA = useReactiveVar(editHAVar);
//   const createHA = useReactiveVar(createHAVar);
//   const updateHA = type === "EDIT" ? editHA : createHA;
//   const { updateCreateHA, updateEditHA } = haMutations;
//   const updateHAFunction = type === "EDIT" ? updateEditHA : updateCreateHA;

//   const [getOSP] = useLazyQuery(GET_OWNER_OSP_ID, {
//     onCompleted(data) {
//       updateHAFunction("contractorOSPIds", [
//         ...updateHA.contractorOSPIds,
//         data.ownerSiteProjects[0].id,
//       ]);
//     },
//   });

//   React.useEffect(() => {
//     if (contractorID) {
//       getOSP({
//         variables: {
//           ownerId: Number(contractorID),
//         },
//       });
//     }
//   }, [contractorID]);
//   return null;
// }
