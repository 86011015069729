import { gql } from "@apollo/client";

export const CREATE_SINGLE_CREWMEMBER = gql`
  mutation CREATE(
    $phoneNumber: String!
    $contactNumber: String
    $firstName: String
    $lastName: String
    $company: ID!
    $OSP: ID!
    $email: String
    $jobTitle: ID
    $craft: ID
    $years: ID
    $signature: String
    $hireDate: Date
    $expirationDate: Date
    $contactName: String
    $other: String
  ) {
    crewMemberSingleMutation(
      input: {
        phoneNumber: $phoneNumber
        contactNumber: $contactNumber
        firstName: $firstName
        company: $company
        ownerSiteProject: $OSP
        lastName: $lastName
        email: $email
        jobTitle: $jobTitle
        craftType: $craft
        yrsOfExp: $years
        signatureBase64: $signature
        hireDate: $hireDate
        drugTestExpiry: $expirationDate
        contactName: $contactName
        other: $other
      }
    ) {
      crewMember {
        phoneNumber
      }
      message {
        success
        errors
        info
      }
    }
  }
`;

export const UPDATE_SINGLE_CREWMEMBER = gql`
  mutation UPDATE(
    $id: ID!
    $phoneNumber: String!
    $contactNumber: String
    $firstName: String
    $lastName: String
    $company: ID!
    $OSP: ID!
    $email: String
    $jobTitle: ID
    $craft: ID
    $years: ID
    $signature: String
    $hireDate: Date
    $expirationDate: Date
    $contactName: String
    $other: String
  ) {
    crewMemberUpdateMutation(
      id: $id
      input: {
        phoneNumber: $phoneNumber
        contactNumber: $contactNumber
        firstName: $firstName
        company: $company
        ownerSiteProject: $OSP
        lastName: $lastName
        email: $email
        jobTitle: $jobTitle
        craftType: $craft
        yrsOfExp: $years
        signatureBase64: $signature
        hireDate: $hireDate
        drugTestExpiry: $expirationDate
        contactName: $contactName
        other: $other
      }
    ) {
      crewMember {
        phoneNumber
      }
      message {
        success
        errors
        info
      }
    }
  }
`;

export const UPDATE_SINGLE_CREWMEMBER_GENERAL = gql`
  mutation updateCrewmemberGeneralInfo(
    $id: ID!
    $phoneNumber: String!
    $contactNumber: String
    $firstName: String
    $lastName: String
    $email: String
    $contactName: String
    $company: ID!
  ) {
    crewMemberUpdateMutation(
      id: $id
      input: {
        phoneNumber: $phoneNumber
        contactNumber: $contactNumber
        firstName: $firstName
        lastName: $lastName
        email: $email
        contactName: $contactName
        company: $company
      }
    ) {
      crewMember {
        phoneNumber
        id
        crewMemberContact {
          contact {
            id
            name
          }
        }
        firstName
        lastName
        email
      }
      message {
        success
        errors
        info
      }
    }
  }
`;

export const UPDATE_SINGLE_CREWMEMBER_JOB = gql`
  mutation updateCrewmemberJobInfo(
    $id: ID!
    $jobTitle: ID
    $craftType: ID
    $yrsOfExp: ID
    $hireDate: Date
    $expirationDate: Date
    $other: String
    $company: ID!
    $phoneNumber: String!
  ) {
    crewMemberUpdateMutation(
      id: $id
      input: {
        jobTitle: $jobTitle
        craftType: $craftType
        yrsOfExp: $yrsOfExp
        hireDate: $hireDate
        drugTestExpiry: $expirationDate
        other: $other
        company: $company
        phoneNumber: $phoneNumber
      }
    ) {
      crewMember {
        phoneNumber
        jobTitle {
          name
        }
        craftType {
          name
        }
        yrsOfExp {
          name
        }
        hireDate
        id
        drugTestExpiry
        other
      }
      message {
        success
        errors
        info
      }
    }
  }
`;

export const CREWMEMBER_BULK = gql`
  mutation BULK($bulk_CM: [BulkCrewMemberInput]!) {
    bulkCrewMemberMutation(bulkCrewMember: $bulk_CM) {
      CrewMembersAdded {
        id
        phoneNumber
      }
      CrewMembersUpdated {
        phoneNumber
      }
      OwnerSiteProjectCrewMemberCreated {
        ownerSiteProject {
          owner {
            name
            id
          }
          site {
            name
            id
          }
          project {
            name
            id
          }
        }
        crewMember {
          phoneNumber
          id
        }
      }
      OwnerSiteProjectCrewMemberUpdated {
        ownerSiteProject {
          owner {
            name
            id
          }
          site {
            name
            id
          }
          project {
            name
            id
          }
        }
        crewMember {
          phoneNumber
          id
        }
      }
      message {
        field
        messages
      }
    }
  }
`;

export const CM_TO_OSP = gql`
  mutation CM_TO_OSP(
    $crewMember: ID!
    $unassociatedOSP: [ID]
    $associatedOSP: [ID]
  ) {
    crewMemberToOsp(
      crewMember: $crewMember
      unassociatedOSP: $unassociatedOSP
      associatedOSP: $associatedOSP
    ) {
      crewMembersOSP {
        crewMember {
          id
          firstName
          lastName
        }
        ownerSiteProject {
          id
          owner {
            id
            name
          }
          site {
            id
            name
          }
          project {
            id
            name
          }
        }
      }
    }
  }
`;

export const UPLOAD_CREWMEMBER_CSV = gql`
  mutation UPDATE($file: Upload!, $ospId: ID!) {
    uploadCrewMemberMutation(file: $file, ospId: $ospId) {
      message {
        success
        errors
        info
      }
    }
  }
`;
