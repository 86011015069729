import * as React from "react";

import TextField from "@mui/material/TextField";

import { useReactiveVar } from "@apollo/client";

import {
  createIncidentVar,
  editIncidentVar,
} from "../../../../graphql/localVariables/investigation";

export default function Weather({ type }) {
  // Global Variables
  const editObs = useReactiveVar(editIncidentVar);
  const createObs = useReactiveVar(createIncidentVar);
  const form = type === "EDIT" ? editObs : createObs;

  return (
    <TextField
      value={form.weather}
      disabled
      variant="standard"
      label="Weather"
      sx={{ width: "100%" }}
    />
  );
}
