import React from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Grid, Typography } from "@mui/material";

import { useMutation, useLazyQuery } from "@apollo/client";

import { CM_TO_OSP } from "../../../../../../graphql/mutations/crewmembers";
import { GET_COMPANY_SITES } from "../../../../../../graphql/queries/admin/company";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../../../CustomComponents/OnErrorFunction";
import SelectedUnselected from "../../../../../CustomComponents/SelectedUnselectedAdmin";
import { yellowButton } from "../../../../../CustomStyles/buttons";

export default function EditCrewSites({ open, setOpen, d, disabled }) {
  // Queries
  const [getSites, { data: sites, loading: loadingSites }] =
    useLazyQuery(GET_COMPANY_SITES);

  // Site Mutation
  const [mutateCrewMemberSites, { loading }] = useMutation(CM_TO_OSP, {
    onCompleted() {
      onCompletedFunc("CrewMember has been updated");
    },
    onError(error) {
      onErrorFunc(error);
      siteClear();
    },
  });

  React.useEffect(() => {
    if (open) {
      getSites();
    }
  }, [open]);

  // Sites state
  const [selectedSites, setSelectedSites] = React.useState([]);
  const [unselectedSites, setUnselectedSites] = React.useState([]);

  // Set unselected sites query updates
  React.useEffect(() => {
    if (sites && d) {
      const filtered = sites.ownerSiteProjects.filter(
        (os) =>
          d.crewMember.ownerSiteProjectCrewMembers.findIndex(
            (osSel) => osSel.ownerSiteProject.id === os.id
          ) < 0
      );
      setUnselectedSites(filtered);

      setSelectedSites(
        d.crewMember.ownerSiteProjectCrewMembers.map((ospc) => ({
          ...ospc.ownerSiteProject,
        }))
      );
    }
  }, [sites, d]);

  // On Done: closes modal and resets
  const onDone = () => {
    setOpen(false);
  };

  // On Save: chooses mutation based on tab index
  const onSave = () => {
    const filtered = d.crewMember.ownerSiteProjectCrewMembers.filter(
      (osSel) =>
        selectedSites.findIndex((os) => osSel.ownerSiteProject.id === os.id) < 0
    );

    mutateCrewMemberSites({
      variables: {
        crewMember: Number(d.crewMember.id),
        associatedOSP: selectedSites.map((s) =>
          Number(s.ownerSiteProject ? s.ownerSiteProject.id : s.id)
        ),
        unassociatedOSP: filtered.map((s) =>
          Number(s.ownerSiteProject ? s.ownerSiteProject.id : s.id)
        ),
      },
    });
  };

  const renderDisplayText = (item) => {
    return (
      <Typography
        style={{
          maxWidth: "90%",
        }}
      >
        {item.ownerSiteProject
          ? `${item.ownerSiteProject.site.name} [${item.ownerSiteProject.owner.name}]`
          : `${item.site.name} [${item.owner.name}]`}
      </Typography>
    );
  };

  // Site functions
  const siteClear = () => {
    setSelectedSites(
      d.crewMember.ownerSiteProjectCrewMembers.map((ospc) => ({
        ownerSiteProject: ospc.ownerSiteProject,
      }))
    );

    if (sites && d) {
      const filtered = sites.ownerSiteProjects.filter(
        (os) =>
          d.crewMember.ownerSiteProjectCrewMembers.findIndex(
            (osSel) => osSel.ownerSiteProject.id === os.id
          ) < 0
      );
      setUnselectedSites(filtered);
    }
  };

  const renderButtons = () => {
    return (
      <Grid sx={{ textAlignLast: "end", paddingTop: "50px" }}>
        <LoadingButton
          variant="contained"
          style={{ marginRight: "10px", ...yellowButton }}
          onClick={onSave}
          disabled={disabled}
          loading={loading}
        >
          SAVE
        </LoadingButton>
        <Button
          variant="contained"
          style={{ marginRight: "10px" }}
          onClick={onDone}
        >
          DONE
        </Button>
      </Grid>
    );
  };

  return (
    <>
      {/* Sites Tab */}
      <Grid container>
        <Grid item xs={1}></Grid>
        <Grid item xs={10} style={{ marginTop: "10px" }}>
          <SelectedUnselected
            selected={selectedSites}
            unselected={unselectedSites}
            loadingUnselected={loadingSites}
            setSelected={setSelectedSites}
            setUnselected={setUnselectedSites}
            updateState={() => {}}
            idKey={"id"}
            label={"Sites"}
            renderDisplay={renderDisplayText}
            searchItem={(item, searchTerm) => {
              const name = item.ownerSiteProject
                ? item.ownerSiteProject.site.name
                : item.site.name;

              return name.toLowerCase().includes(searchTerm.toLowerCase());
            }}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      {renderButtons()}
    </>
  );
}
