import {
  filteredProjectsFunction,
  filteredContractorsFunction,
  filteredCrewmembersFunction,
  filteredOSPFunction,
  filteredTemplateFunction,
  filteredHATemplatesFunction,
} from "../filteringFunctions";
import { createAuditVar, editAuditVar } from "./audit";
import { createHAVar, editHAVar } from "./ha";
import { createObservationVar, editObservationVar } from "./observation";
import { trackedQueriesVar } from "./trackedQueries";
import {
  authVar,
  snackbarVar,
  loadingVar,
  onlineVar,
  permissionsVar,
} from "./user";

export const cacheFields = {
  fields: {
    // FOR PAGINATION
    observations: {
      // Don't cache separate results based on
      // any of this field's arguments.
      keyArgs: false,

      // Concatenate the incoming list items with
      // the existing list items.
      merge(existing = [], incoming) {
        if (incoming.edges) {
          return [...existing, ...incoming.edges.map((e) => e.node)];
        }
        return existing;
      },
    },
    hazardAnalyses: {
      // Don't cache separate results based on
      // any of this field's arguments.
      keyArgs: false,

      // Concatenate the incoming list items with
      // the existing list items.
      merge(existing = [], incoming) {
        if (incoming.edges) {
          return [...existing, ...incoming.edges.map((e) => e.node)];
        }
        return existing;
      },
    },
    paginatedCrewMembers: {
      // Don't cache separate results based on
      // any of this field's arguments.
      keyArgs: false,

      // Concatenate the incoming list items with
      // the existing list items.
      merge(existing = [], incoming) {
        if (existing.length === incoming.totalCount) {
          // eslint-disable-next-line no-console
          console.log("Cache already set for crewMembers.");
          return existing;
        }
        if (incoming.edges) {
          return [...existing, ...incoming.edges.map((e) => e.node)];
        }
        return existing;
      },
    },
    paginatedOwnerSiteProjectTemplatesHa: {
      // Don't cache separate results based on
      // any of this field's arguments.
      keyArgs: false,

      // Concatenate the incoming list items with
      // the existing list items.
      merge(existing = [], incoming) {
        if (existing.length === incoming.totalCount) {
          // eslint-disable-next-line no-console
          console.log("Cache already set for OSPTemplateHA.");
          return existing;
        }
        if (incoming.edges) {
          return [...existing, ...incoming.edges.map((e) => e.node)];
        }
        return existing;
      },
    },
    // FOR FILTERS
    getHA(_, { args, toReference }) {
      return toReference({
        __typename: "HazardAnalysisType",
        id: args.id,
      });
    },
    getLocalSites(_, { readField }) {
      const sites = readField("sites");
      return sites;
    },
    filterProjects: {
      read(filteredProjects, { readField, args }) {
        return filteredProjectsFunction(args, readField);
      },
    },
    filterOSP: {
      read(filteredOSP, { readField, args, toReference }) {
        return filteredOSPFunction(args, readField, toReference);
      },
    },
    filterContractors: {
      read(filteredContractors, { readField, args, toReference }) {
        return filteredContractorsFunction(args, readField, toReference);
      },
    },
    filterCrewMembers: {
      read(_, { readField, args, toReference }) {
        return filteredCrewmembersFunction(args, readField, toReference);
      },
    },
    filterHATemplates: {
      read(_, { readField, args, toReference }) {
        return filteredHATemplatesFunction(args, readField, toReference);
      },
    },
    filterTemplate: {
      read(_, { readField, args, toReference }) {
        return filteredTemplateFunction(args, readField, toReference);
      },
    },
    getOSP(_, { args, toReference }) {
      return toReference({
        __typename: "OwnerSiteProjectType",
        id: args.projectId,
      });
    },
    trackedQueries: {
      read() {
        return trackedQueriesVar();
      },
    },
    auth: {
      read() {
        return authVar();
      },
    },
    userPermissions: {
      read() {
        return permissionsVar();
      },
    },
    online: {
      read() {
        return onlineVar();
      },
    },
    snackbar: {
      read() {
        return snackbarVar();
      },
    },
    loading: {
      read() {
        return loadingVar();
      },
    },
    editObservation: {
      read() {
        return createObservationVar();
      },
    },
    createObservation: {
      read() {
        return editObservationVar();
      },
    },
    editHAVar: {
      read() {
        return editHAVar();
      },
    },
    createHAVar: {
      read() {
        return createHAVar();
      },
    },
    editAuditVar: {
      read() {
        return editAuditVar();
      },
    },
    createAuditVar: {
      read() {
        return createAuditVar();
      },
    },
  },
};
