import React from "react";
import { Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#FCA800",
  },
});

export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "black",
    "&.Mui-selected": {
      color: "black",
      fontWeight: "bold",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "white",
    },
  })
);

export const StyledTabWhite = styled((props) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: "#fff",
  "&.Mui-selected": {
    color: "#fff",
    fontWeight: "bold",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#fff",
  },
}));
