import React from "react";

import { TextField, Grid, Switch, Typography } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import { useReactiveVar } from "@apollo/client";
import moment from "moment";

import {
  createIncidentVar,
  editIncidentVar,
} from "../../../../graphql/localVariables/investigation";

const deathDateQuestionName = "Date of Death";

export default function DateInputView({ question, type, updateState }) {
  // Global Variables
  const editObs = useReactiveVar(editIncidentVar);
  const createObs = useReactiveVar(createIncidentVar);
  const form = type === "EDIT" ? editObs : createObs;

  const [foundRes, setFoundRes] = React.useState({
    incidentQuestion: { id: question.id },
    date: question.name === deathDateQuestionName ? null : new Date(),
  });
  const [show, setShow] = React.useState(true);

  const handleChange = (date) => {
    updateState({
      incidentQuestion: { id: question.id },
      date: moment(date).format("YYYY-MM-DD"),
    });
    setFoundRes({
      incidentQuestion: { id: question.id },
      date: date,
    });
  };

  React.useEffect(() => {
    if (question.name === deathDateQuestionName) {
      setShow(false);
    }
  }, []);

  React.useEffect(() => {
    const doesExist = form.incidentQuestionResponses.find(
      (res) =>
        question.id === res.incidentQuestion?.id &&
        question.type === res.incidentQuestion?.type
    );
    if (doesExist) {
      setFoundRes(doesExist);
    } else {
      setFoundRes({
        incidentQuestion: { id: question.id },
        date: question.name === deathDateQuestionName ? null : new Date(),
      });
    }
  }, [form.incidentTopicResponses]);

  return (
    <Grid
      item
      xs={12}
      sx={{ padding: "10px 10px 50px 10px" }}
      container
      alignItems="center"
    >
      {question.name === deathDateQuestionName && (
        <>
          <Typography>{deathDateQuestionName}</Typography>
          <Switch onChange={(e) => setShow(e.target.checked)} />
        </>
      )}
      {show && (
        <MobileDatePicker
          label={question.name}
          inputFormat="MM/DD/YYYY"
          value={foundRes.date}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{
                width: "100%",
              }}
              variant="standard"
            />
          )}
        />
      )}
    </Grid>
  );
}
