import { browserName, isIOS, isSafari } from "react-device-detect";

export const checkInstall = async (prompt, setOpen) => {
  if (browserName === "Firefox") {
    alert(
      "Browser not supported - please use Chrome or Edge on Windows/Android/macOS and Safari on iOS."
    );
  } else if (isIOS && !isSafari) {
    alert("Browser not supported - please use Safari on iOS.");
  } else if (isIOS && isSafari) {
    setOpen(true);
  } else if (prompt !== null) {
    prompt.prompt();
    const { outcome } = await prompt.userChoice;
    if (outcome === "accepted") {
      prompt = null;
    }
  } else {
    alert(
      "Ensure you are not in incognito mode in order to install the web app."
    );
  }
};
