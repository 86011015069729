import React from "react";

import { useLazyQuery, useReactiveVar } from "@apollo/client";

import {
  createObservationVar,
  editObservationVar,
} from "../../../../graphql/localVariables/observation";
import { onlineVar } from "../../../../graphql/localVariables/user";
import { observationMutations } from "../../../../graphql/mutations";
import { GET_FILTERED_OSP, GET_OSP } from "../../../../graphql/queries";
import { GET_FILTERED_PROJECTS_HA } from "../../../../graphql/queries/ha";
import {
  GET_PROJECT_BY_OWNERSITE,
  GET_SITES,
} from "../../../../graphql/queries/index";
import General from "./General";

export default function GeneralContainer({ type, onEditSection }) {
  // Global Variables
  const editObs = useReactiveVar(editObservationVar);
  const createObs = useReactiveVar(createObservationVar);
  const form = type === "EDIT" ? editObs : createObs;
  const online = useReactiveVar(onlineVar);

  // Mutations
  const { updateKeyEditObservation, updateKeyCreateObservation } =
    observationMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditObservation : updateKeyCreateObservation;

  // Queries
  const [getLocation, { data: siteData }] = useLazyQuery(GET_SITES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const [getProjectsBySite, { data: projectData, loading: loadingProjects }] =
    useLazyQuery(GET_PROJECT_BY_OWNERSITE, {
      fetchPolicy: online ? "network-only" : "cache-only",
    });
  const [getProjectsBySiteOffline, { data: offlineProjects }] = useLazyQuery(
    GET_FILTERED_PROJECTS_HA
  );
  const [getOSPQuery] = useLazyQuery(GET_OSP, {
    onCompleted(data) {
      updateObsKeyFunction("ospId", data.ownerSiteProjects[0].id);
    },
  });
  const [getOSPOffline] = useLazyQuery(GET_FILTERED_OSP, {
    onCompleted(data) {
      console.log("Offline OSP", data);
      if (data.filterOSP.length > 0) {
        updateObsKeyFunction("ospId", data.filterOSP[0].id);
      }
    },
  });

  React.useEffect(() => {
    getLocation();
  }, []);

  React.useEffect(() => {
    if (form.site?.id) {
      if (online) {
        getProjectsBySite({
          variables: {
            siteId: Number(form.site.id),
            ownerId: Number(form.owner.id),
          },
        });
      } else {
        getProjectsBySiteOffline({
          variables: {
            siteId: form.site.id,
            ownerId: form.owner.id,
          },
        });
      }
    }
  }, [form.site]);

  React.useEffect(() => {
    if (form.site?.id && form.project?.id) {
      if (online) {
        getOSPQuery({
          variables: {
            ownerId: Number(form.owner.id),
            siteId: Number(form.site.id),
            projectId: Number(form.project.id),
          },
        });
      } else {
        getOSPOffline({
          variables: {
            ownerId: form.owner.id,
            siteId: form.site.id,
            projectId: form.project.id,
          },
        });
      }
    }
  }, [form.owner, form.site, form.project]);

  return (
    <General
      sites={siteData}
      form={form}
      type={type}
      projects={online ? projectData : offlineProjects}
      loadingProjects={loadingProjects}
      onEditSection={onEditSection}
    />
  );
}
