import React from "react";

import { Check, CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import { useMutation, useLazyQuery, useReactiveVar } from "@apollo/client";

import {
  onlineVar,
  permissionsVar,
} from "../../../../../graphql/localVariables/user";
import { CREATE_EDIT_PROJECT } from "../../../../../graphql/mutations/admin/planning-project";
import {
  GET_PROJECTS,
  GET_PROJECT_DATA,
  GET_SITES,
} from "../../../../../graphql/queries/admin/planning-project";
import { GET_SEARCH_TEMPLATES } from "../../../../../graphql/queries/ha";
import { states } from "../../../../../utils/states";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../../CustomComponents/OnErrorFunction";
import SearchField from "../../../../CustomComponents/SearchField";
import { CustomSwitch } from "../../../../CustomComponents/Switch";
import { CustomTextField } from "../../../../CustomStyles/LightTextField";
import { yellowButton } from "../../../../CustomStyles/buttons";
import { CustomDialog } from "../../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../../CustomStyles/pageTitle";
import { COMPANY_ADMIN_OSP } from "../../../Company/companyAdminQueries";
import PreviewImages from "../PreviewImages";
import UploadDialog from "../Upload";

export default function EditProject({ row, open, handleClose, company }) {
  const online = useReactiveVar(onlineVar);
  const permissions = useReactiveVar(permissionsVar);
  const [getProjectData, { data }] = useLazyQuery(GET_PROJECT_DATA);
  const [getQuery, { data: sites }] = useLazyQuery(GET_SITES);
  const [getTemplates, { data: templates, loading: loadingTemplates }] =
    useLazyQuery(GET_SEARCH_TEMPLATES);

  React.useEffect(() => {
    if (open) {
      getProjectData({
        variables: { projectId: Number(row.id) },
      });
      getQuery({
        fetchPolicy: online ? "network-only" : "cache-only",
      });
      getTemplates({
        fetchPolicy: online ? "network-only" : "cache-only",
      });
    }
  }, [open]);

  const [editProject, { loading: loadingEdit }] = useMutation(
    CREATE_EDIT_PROJECT,
    {
      onCompleted() {
        handleClose();
        onCompletedFunc("Project has been updated");
      },
      onError(error) {
        onErrorFunc(error);
      },
      refetchQueries: [
        { query: GET_PROJECTS },
        company && {
          query: COMPANY_ADMIN_OSP,
          variables: { id: Number(company.id) },
        },
      ],
    }
  );

  const [editDisabled, setEditDisabled] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [location, setLocation] = React.useState([]);
  const [name, setName] = React.useState("");
  const [addr1, setAddr1] = React.useState("");
  const [addr2, setAddr2] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [zip, setZip] = React.useState("");
  const [dollar, setDollar] = React.useState("");
  const [photos, setPhotos] = React.useState([]);
  const [removedPhotos, setRemovedPhotos] = React.useState([]);

  const [projTemplates, setProjTemplates] = React.useState([]);
  const [addedTemplates, setAddedTemplates] = React.useState([]);
  const [searchTemplates, setSearchTemplates] = React.useState("");

  const setInitialState = () => {
    setIsActive(data.project.isActive);
    setLocation(data.project.ownerSiteProjects);
    setName(data.project.name);
    setAddr1(data.project.addrLine1);
    setAddr2(data.project.addrLine2);
    setCity(data.project.addrCity);
    setState(data.project.addrState);
    setZip(data.project.addrZip);
    setDollar(data.project.dollarAmount);
    setPhotos(data.project.projectMediaFiles);
    let temp = data.project.ownerSiteProjects[0]
      ? data.project.ownerSiteProjects[0].ownerSiteProjectTemplate
      : [];

    setProjTemplates(temp.filter((t) => t.isActive));
  };

  React.useEffect(() => {
    if (!permissions.includes("EDIT_PROJECT")) {
      setEditDisabled(true);
    }
  }, []);

  React.useEffect(() => {
    if (data) {
      setInitialState();
    }
  }, [data]);

  const onEdit = () => {
    if (state && state !== "" && location.length > 0) {
      editProject({
        variables: {
          isActive,
          projectName: name,
          addrCity: city,
          addrLine1: addr1,
          addrLine2: addr2,
          addrState: state,
          addrZip: zip,
          projectId: row.id,
          OSPinfo: location.map((l) => {
            return {
              company: l.owner.id,
              site: l.site.id,
              associatedTemplate: [
                ...addedTemplates.map((t) => Number(t.id)),
                ...projTemplates.map((t) =>
                  t.template ? Number(t.template.id) : Number(t.id)
                ),
              ],
            };
          }),
          dollarAmount: Number(dollar),
          edit: true,
          media: photos.filter((p) => !p.mediaFile),
          removeMedia: removedPhotos,
          changeStatus: false,
        },
      });
      setRemovedPhotos([]);
    } else {
      onErrorFunc(
        "Make sure to select a site location and state for your project."
      );
    }
  };

  const onCancel = () => {
    handleClose();
    onClear();
  };

  const onClear = () => {
    setInitialState();
    setAddedTemplates([]);
  };

  const add = (p) => {
    setAddedTemplates((prev) => [...prev, p]);
  };

  const remove = (p) => {
    setAddedTemplates((prev) => prev.filter((prev) => prev.id !== p.id));
    if (
      p.template &&
      projTemplates.findIndex(
        (t) => Number(t.template.id) !== Number(p.template.id)
      ) >= 0
    ) {
      setProjTemplates((prev) =>
        prev.filter(
          (prev) => Number(prev.template.id) !== Number(p.template.id)
        )
      );
    }
  };

  return (
    <CustomDialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle
        style={{
          ...pageTitleStyles,
          fontSize: "2rem",
        }}
      >
        PROJECT: <span style={{ color: "#FFB700" }}> {row.name}</span>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ marginBottom: "20px" }}>
          <Grid item xs={12} container alignItems="center">
            <FormControlLabel
              style={{ color: "white" }}
              control={
                <CustomSwitch
                  checked={isActive}
                  onChange={(event) => setIsActive(event.target.checked)}
                  light
                  disabled={editDisabled}
                />
              }
              label={isActive ? "ACTIVE" : "INACTIVE"}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="select-site-owner"
              limitTags={3}
              options={
                sites
                  ? [
                      ...sites.ownerSiteProjects.filter((osp) =>
                        company
                          ? osp.owner && osp.owner.id === company.id && osp.site
                          : osp.owner && osp.site
                      ),
                    ].sort((a, b) => (a.site.name > b.site.name ? 1 : -1))
                  : []
              }
              value={location}
              onChange={(event, value) => setLocation(value)}
              getOptionLabel={(option) =>
                `${option.site.name} [${option.owner.name}]`
              }
              isOptionEqualToValue={(option, value) =>
                option.site.id === value.site.id &&
                option.owner.id === value.owner.id
              }
              disableClearable
              disabled={editDisabled}
              multiple
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.id}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {`${option.site.name} [${option.owner.name}]`}
                </li>
              )}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  style={{ color: "#fff" }}
                  variant="standard"
                  label={"Location:"}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Project Name:"
              variant="standard"
              style={{ width: "100%" }}
              value={name}
              onChange={(event) => setName(event.target.value)}
              disabled={editDisabled}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Address Line 1:"
              variant="standard"
              style={{ width: "100%" }}
              value={addr1}
              onChange={(event) => setAddr1(event.target.value)}
              disabled={editDisabled}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Address Line 2:"
              variant="standard"
              style={{ width: "100%" }}
              value={addr2}
              onChange={(event) => setAddr2(event.target.value)}
              disabled={editDisabled}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="City:"
              variant="standard"
              style={{ width: "100%" }}
              value={city}
              onChange={(event) => setCity(event.target.value)}
              disabled={editDisabled}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="select-site-state"
              options={states}
              value={state}
              onChange={(event, value) => setState(value)}
              disabled={editDisabled}
              disableClearable
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  style={{ color: "#fff" }}
                  variant="standard"
                  label={"State:"}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Zipcode:"
              variant="standard"
              style={{ width: "100%" }}
              value={zip}
              onChange={(event) => setZip(event.target.value)}
              disabled={editDisabled}
              type="number"
              inputProps={{ maxLength: 5 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Dollar Amount:"
              variant="standard"
              style={{ width: "100%" }}
              value={dollar}
              onChange={(event) => setDollar(event.target.value)}
              disabled={editDisabled}
              type="number"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <Typography style={{ color: "white" }}>ADDED TEMPLATES:</Typography>
            <Grid
              container
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                backgroundColor: "white",
                marginTop: "10px",
                borderRadius: 5,
              }}
            >
              {projTemplates.length === 0 && addedTemplates.length === 0 && (
                <Typography style={{ padding: "20px", color: "black" }}>
                  No added templates.
                </Typography>
              )}

              {[...projTemplates, ...addedTemplates]
                .sort((a, b) => {
                  const aName = a.template ? a.template.name : a.name;
                  const bName = b.template ? b.template.name : b.name;

                  return aName > bName ? 1 : -1;
                })
                .map((u) =>
                  u.template ? (
                    <Grid
                      item
                      xs={12}
                      key={u.id}
                      style={{
                        padding: "10px",
                        height: "fit-content",
                      }}
                      container
                      alignItems="center"
                    >
                      <Grid item xs={2}>
                        <IconButton
                          style={{ marginRight: "10px" }}
                          onClick={() => remove(u)}
                          disabled={editDisabled}
                        >
                          <Check />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10} container>
                        <Typography
                          style={{ marginRight: "15px", color: "black" }}
                        >
                          {u.template.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      key={u.id}
                      style={{
                        padding: "10px",
                        height: "fit-content",
                      }}
                      container
                      alignItems="center"
                    >
                      <Grid item xs={2}>
                        <IconButton
                          style={{ marginRight: "10px" }}
                          onClick={() => remove(u)}
                          disabled={editDisabled}
                        >
                          <Check />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10} container>
                        <Typography
                          style={{ marginRight: "15px", color: "black" }}
                        >
                          {u.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                )}
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Typography style={{ color: "white" }}>
              UNADDED TEMPLATES:
            </Typography>
            <SearchField
              value={searchTemplates}
              setValue={setSearchTemplates}
            />
            <Grid
              container
              style={{
                maxHeight: "200px",
                overflowY: "auto",
                backgroundColor: "white",
                width: "100%",
                marginTop: "10px",
                borderRadius: 5,
              }}
            >
              {loadingTemplates ? (
                <Grid container justifyContent="center" sx={{ p: 2 }}>
                  <CircularProgress color="secondary" />
                </Grid>
              ) : (
                templates &&
                templates.templates
                  .filter(
                    (template) =>
                      projTemplates.findIndex(
                        (t) => t.template.id === template.id
                      ) < 0 &&
                      addedTemplates.findIndex((t) => t.id === template.id) <
                        0 &&
                      template.name
                        .toLowerCase()
                        .includes(searchTemplates.toLowerCase())
                  )
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((t) => (
                    <Grid
                      item
                      xs={12}
                      key={t.id}
                      style={{
                        padding: "10px",
                        height: "fit-content",
                      }}
                      container
                      alignItems="center"
                    >
                      <Grid item xs={2}>
                        <IconButton
                          style={{ marginRight: "10px" }}
                          onClick={() => add(t)}
                          disabled={editDisabled}
                        >
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10} container>
                        <Typography
                          style={{ marginRight: "5px", color: "black" }}
                        >
                          {t.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))
              )}
            </Grid>
          </Grid>
        </Grid>
        <UploadDialog
          files={photos}
          handleFilesChange={(files) => setPhotos(files)}
          disabled={editDisabled}
        />
        <PreviewImages
          fileObjects={photos}
          handleFilesChange={(files) => setPhotos(files)}
          setRemovedPhotos={setRemovedPhotos}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          sx={{ marginRight: "10px", ...yellowButton }}
          onClick={onEdit}
          loading={loadingEdit}
          disabled={editDisabled}
        >
          SAVE
        </LoadingButton>
        <Button variant="contained" onClick={onCancel}>
          CANCEL
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}
