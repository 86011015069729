import { gql } from "@apollo/client";

export const MUTATE_INDUSTRY = gql`
  mutation mutateIndustry(
    $name: String!
    $id: ID
    $classifications: [ID]
    $code: String
    $isActive: Boolean
  ) {
    industryMutations(
      name: $name
      id: $id
      code: $code
      classifications: $classifications
      isActive: $isActive
    ) {
      industry {
        id
        name
      }
    }
  }
`;

export const MUTATE_CLASSIFICATION = gql`
  mutation mutateClassification(
    $name: String!
    $id: ID
    $code: String
    $isActive: Boolean
  ) {
    classificationMutations(
      name: $name
      id: $id
      code: $code
      isActive: $isActive
    ) {
      classification {
        id
        name
      }
    }
  }
`;
