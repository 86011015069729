import React from "react";
import { useReactiveVar } from "@apollo/client";
import { authVar } from "./graphql/localVariables/user";
import { Navigate, Outlet } from "react-router-dom";

const LoggedInRedirection = () => {
  /*
   * If the user is signed in then redirect the user to default page.
   * Else render the login page.
   */
  const isLoggedIn = useReactiveVar(authVar);

  return isLoggedIn.isAuthenticated ? <Navigate to="/" /> : <Outlet />;
};

export default LoggedInRedirection;
