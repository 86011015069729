import React from "react";

import {
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  FormControlLabel,
  Avatar,
} from "@mui/material";

import {
  useApolloClient,
  useMutation,
  useQuery,
} from "@apollo/client";
import axios from "axios";

import { apiUrl } from "../../config";
import {
  CHANGE_EMAIL,
  CHANGE_NAME,
  UPDATE_RECEIVE_OTHER_REPORTS,
} from "../../graphql/mutations/userSettings";
import { GET_CURRENT_USER } from "../../graphql/queries/auth";
import UploadProfilePic from "../Admin/Company/Dashboard/Carousel/Edit/UploadProfilePic";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../CustomComponents/OnErrorFunction";
import PasswordField from "../CustomComponents/PasswordField";
import { CustomSwitch } from "../CustomComponents/Switch";
import { blueButton } from "../CustomStyles/buttons";
import { pageTitleStyles } from "../CustomStyles/pageTitle";
import Layout from "../Layout";
import handleLogout from "../SideNav/handleLogout";
import { versionNum } from "../SideNav/releaseNotes";
import ChangePassword from "./ChangePassword";
import useStyles from "./styles";

export default function UserSettings() {
  const client = useApolloClient();

  const [mutateEmail] = useMutation(CHANGE_EMAIL, {
    onCompleted() {
      onCompletedFunc("Email updated");
      setPasswordEmail("");
    },
    onError(error) {
      onErrorFunc(error);
    },
  });
  const [mutateName] = useMutation(CHANGE_NAME, {
    onCompleted() {
      onCompletedFunc("Name updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
  });
  const [mutateReports] = useMutation(UPDATE_RECEIVE_OTHER_REPORTS, {
    onCompleted() {
      onCompletedFunc("Report setting updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
  });
  const { data } = useQuery(GET_CURRENT_USER);
  const classes = useStyles();

  const [type, setType] = React.useState("CHANGE PASSWORD");
  const [newEmail, setNewEmail] = React.useState("");
  const [passwordEmail, setPasswordEmail] = React.useState("");

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");

  const [reports, setReports] = React.useState(
    data?.currentUser.receiveOtherReports
  );

  React.useEffect(() => {
    if (data) {
      setFirstName(data.currentUser.firstName);
      setLastName(data.currentUser.lastName);
      setNewEmail(data.currentUser.email);
    }
  }, [data]);

  const changeName = () => {
    if (firstName !== "" && lastName !== "") {
      mutateName({
        variables: {
          userId: Number(data?.currentUser.id),
          firstName: firstName,
          lastName: lastName,
        },
      });
    }
  };

  const changeEmail = () => {
    if (newEmail !== "" && passwordEmail !== "") {
      mutateEmail({
        variables: {
          userId: Number(data?.currentUser.id),
          email: newEmail,
          password: passwordEmail,
        },
      });
    }
  };

  const changeReports = () => {
    mutateReports({
      variables: {
        id: Number(data?.currentUser.id),
        ror: reports,
      },
    });
  };

  const logout = () => {
    axios
      .post(`${apiUrl}auth/jwt/logout/`)
      .then(() => handleLogout(client))
      .catch(() => handleLogout(client));
  };

  return (
    <Layout>
      <Typography sx={pageTitleStyles}>USER SETTINGS.</Typography>
      <Grid
        container
        style={{
          borderRadius: "5px",
          backgroundColor: "#fff",
          padding: "2em",
          minWidth: "200px",
        }}
      >
        {data && (
          <Grid container direction="column">
            <Avatar
              alt={`${firstName} ${lastName}`}
              src={data.currentUser.image}
              sx={{
                width: "100px",
                margin: "auto",
                height: "100px",
                fontSize: "2.5rem",
              }}
            />
            <UploadProfilePic userId={data.currentUser.id} />
          </Grid>
        )}
        <FormControl
          variant="standard"
          fullWidth
          sx={{
            mb: 2,
          }}
        >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type}
            onChange={(event) => setType(event.target.value)}
            sx={{ fontSize: "20px", fontWeight: "bold" }}
          >
            <MenuItem value={"CHANGE PASSWORD"}>CHANGE PASSWORD</MenuItem>
            <MenuItem value={"CHANGE EMAIL"}>CHANGE EMAIL</MenuItem>
            <MenuItem value={"CHANGE NAME"}>CHANGE NAME</MenuItem>
            <MenuItem value={"REPORTS"}>REPORTS</MenuItem>
          </Select>
        </FormControl>

        {type === "CHANGE PASSWORD" && (
          <ChangePassword userId={data?.currentUser.id} />
        )}

        {type === "CHANGE EMAIL" && (
          <FormControl fullWidth>
            <TextField
              variant="standard"
              label="New Email Address:"
              type="email"
              value={newEmail}
              onChange={(event) => setNewEmail(event.target.value)}
            />
            <PasswordField
              label="Password:"
              password={passwordEmail}
              setPassword={setPasswordEmail}
            />
            <Button
              variant="contained"
              className={classes.blueButton}
              sx={{ mt: 1 }}
              onClick={changeEmail}
            >
              SAVE
            </Button>
          </FormControl>
        )}

        {type === "CHANGE NAME" && (
          <FormControl fullWidth>
            <TextField
              variant="standard"
              label="First Name:"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />
            <TextField
              variant="standard"
              label="Last Name:"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
            <Button
              variant="contained"
              className={classes.blueButton}
              sx={{ mt: 1 }}
              onClick={changeName}
            >
              SAVE
            </Button>
          </FormControl>
        )}

        {type === "REPORTS" && (
          <FormControl fullWidth>
            <Typography>Recieve reports from other users?</Typography>
            <Grid
              container
              alignItems="center"
              sx={{
                mt: 1,
              }}
            >
              <FormControlLabel
                style={{ color: "black" }}
                control={
                  <CustomSwitch
                    checked={reports}
                    onChange={(event) => setReports(event.target.checked)}
                  />
                }
                label={reports ? "YES" : "NO"}
              />
            </Grid>
            <Button
              variant="contained"
              className={classes.blueButton}
              sx={{ mt: 1 }}
              onClick={changeReports}
            >
              SAVE
            </Button>
          </FormControl>
        )}
      </Grid>
      <Grid container justifyContent="center">
        <Button style={{ ...blueButton, margin: "20px 0px" }} onClick={logout}>
          LOGOUT
        </Button>
      </Grid>
      <Typography
        style={{ color: "white", textAlign: "center", marginBottom: "15px" }}
      >
        Version {versionNum}
      </Typography>
    </Layout>
  );
}
