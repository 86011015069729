import * as React from "react";

import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Autocomplete,
  TextField,
  Grid,
  Button,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import { useQuery, useReactiveVar } from "@apollo/client";

import { onlineVar } from "../../../../../../../graphql/localVariables/user";
import {
  GET_OUTCOMES,
  GET_SITES,
} from "../../../../../../../graphql/queries/index";
import { GET_CONTRACTORS_AT_OWNER_SITE_PROJECT } from "../../../../../../../graphql/queries/offlineMode";
import {
  blueButton,
  blackButton,
} from "../../../../../../CustomStyles/buttons";

// PROPS
// searchTerm - string
// onSearchChange - function
// title - string
export default function ReusableTableToolbar(props) {
  const online = useReactiveVar(onlineVar);
  const { data: sites } = useQuery(GET_SITES, {
    fetchPolicy: online ? "cache-first" : "cache-only",
  });
  const { data: contractors } = useQuery(
    GET_CONTRACTORS_AT_OWNER_SITE_PROJECT,
    { fetchPolicy: online ? "network-only" : "cache-only" }
  );
  const { data: getOutcomes } = useQuery(GET_OUTCOMES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const {
    numSelected,
    title,
    onSiteChange,
    site,
    contractor,
    onContractorChange,
    onAfterDateChange,
    afterDate,
    onBeforeDateChange,
    beforeDate,
    onOutcomeChange,
    outcomes,
    onStatusChange,
    status,
    clearFilters,
  } = props;
  const [open, setOpen] = React.useState(false);

  const removeDuplicateContractors = (arr) => {
    const uniqueIds = [];
    const filtered = arr.filter((c) => {
      const isDuplicate = uniqueIds.includes(c.contractor.id);
      if (!isDuplicate) {
        uniqueIds.push(c.contractor.id);
        return true;
      }
      return false;
    });
    return filtered;
  };

  const getContractors = () => {
    if (site.owner.name !== "") {
      return contractors && contractors.ownerSiteProjectContractors
        ? contractors.ownerSiteProjectContractors.filter(
            (c) =>
              c.ownerSiteProject.owner?.id === site.owner.id &&
              c.ownerSiteProject.site?.id === site.site.id
          )
        : [];
    }
    return contractors && contractors.ownerSiteProjectContractors
      ? removeDuplicateContractors(contractors.ownerSiteProjectContractors)
      : [];
  };

  const filter = () => {
    setOpen(false);
  };

  return (
    <Toolbar
      sx={{
        pl: {
          sm: 2,
        },
        pr: {
          xs: 1,
          sm: 1,
        },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{
          flex: "1 1 100%",
        }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {title ? title : null}
      </Typography>
      <Tooltip title="Filter">
        <IconButton onClick={() => setOpen(true)}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <Grid container>
            <Grid item xs={12} md={4} style={{ padding: "5px" }}>
              <Autocomplete
                id="filter-site"
                options={
                  sites && sites.ownerSiteProjects
                    ? [...sites.ownerSiteProjects].sort((a, b) =>
                        a.site.name > b.site.name ? 1 : -1
                      )
                    : []
                }
                getOptionLabel={(option) =>
                  option.site
                    ? `${option.site?.name} [${option.owner?.name}]`
                    : option.owner?.name
                }
                value={site}
                onChange={onSiteChange}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.site
                        ? `${option.site?.name} [${option.owner?.name}]`
                        : option.owner?.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Filter by Site [Site Owner]"
                    value={params}
                    id={params.id}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} style={{ padding: "5px" }}>
              <Autocomplete
                id="filter-contractor"
                options={getContractors().sort((a, b) =>
                  a.contractor.name > b.contractor.name ? 1 : -1
                )}
                getOptionLabel={(option) => option.contractor?.name}
                value={contractor}
                onChange={onContractorChange}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.contractor.id}>
                      {option.contractor?.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Filter by Contractor"
                    value={params}
                    id={params.id}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} style={{ padding: "5px" }}>
              <Autocomplete
                id="filter-outcomes"
                options={
                  getOutcomes && getOutcomes.outcomes
                    ? [...getOutcomes.outcomes].sort((a, b) =>
                        a.name > b.name ? 1 : -1
                      )
                    : []
                }
                getOptionLabel={(option) => {
                  return option.name;
                }}
                value={outcomes}
                onChange={onOutcomeChange}
                multiple
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Filter by Outcome"
                    value={params}
                    id={params.id}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} style={{ padding: "5px" }}>
              <Autocomplete
                id="filter-planned"
                options={["Planned", "Unplanned"]}
                getOptionLabel={(option) => {
                  return option;
                }}
                value={status}
                onChange={onStatusChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Filter by Planned Status"
                    value={params}
                    id={params.id}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} style={{ padding: "5px" }} container>
              <DesktopDatePicker
                label="Filter After This Date"
                inputFormat="MMM DD, yyyy"
                value={afterDate}
                onChange={onAfterDateChange}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "45%", marginRight: "20px" }}
                    variant="standard"
                    {...params}
                  />
                )}
                disableMaskedInput
              />
              <DesktopDatePicker
                label="Filter Before This Date"
                inputFormat="MMM DD, yyyy"
                value={beforeDate}
                onChange={onBeforeDateChange}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "45%" }}
                    variant="standard"
                    {...params}
                  />
                )}
                disableMaskedInput
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={filter} style={{ ...blueButton }}>
            FILTER
          </Button>
          <Button onClick={clearFilters} style={{ ...blackButton }}>
            CLEAR
          </Button>
        </DialogActions>
      </Dialog>
    </Toolbar>
  );
}
