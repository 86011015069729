import React from "react";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {
  Grid,
  Typography,
  FormControlLabel,
  TextField,
  InputAdornment,
} from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import {
  createObservationVar,
  editObservationVar,
} from "../../../graphql/localVariables/observation";
import { observationMutations } from "../../../graphql/mutations";
import { CustomSwitch } from "../../CustomComponents/Switch";
import OutcomeInjuries from "./OutcomeInjuries";
import RootCauses from "./RootCauses";

export default function OutcomeForm({
  type,
  onEditSection,
  mitigators,
  loading,
}) {
  // Global Variables
  const editObs = useReactiveVar(editObservationVar);
  const createObs = useReactiveVar(createObservationVar);
  const form = type === "EDIT" ? editObs : createObs;

  // Mutations
  const { updateKeyEditObservation, updateKeyCreateObservation } =
    observationMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditObservation : updateKeyCreateObservation;

  const handleChangeUnplanned = (event) => {
    updateObsKeyFunction("outcomeUnplanned", event.target.checked);
  };

  const handleChangeExpected = (event) => {
    updateObsKeyFunction("outcomeExpected", event.target.checked);
  };

  const handleChangeInjury = (event) => {
    updateObsKeyFunction("outcomeInjury", event.target.checked);
  };

  const handleChangeNearMiss = (event) => {
    updateObsKeyFunction("outcomeNearMiss", event.target.checked);
  };

  const handleChangeDollarAmount = (event) => {
    updateObsKeyFunction("dollarAmount", event.target.value);
  };

  React.useEffect(() => {}, [
    form.outcomeUnplanned,
    form.outcomeExpected,
    form.outcomeInjury,
  ]);

  return (
    <Grid
      container
      testid="Outcomes"
      textAlign={"center"}
      sx={{ marginBottom: { xs: "25px", md: "0px" } }}
    >
      <Grid item xs={12}>
        <Typography sx={{ display: "inline-flex" }}>
          Did any unplanned work take place?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          style={{ color: "black" }}
          control={
            <CustomSwitch
              checked={form.outcomeUnplanned}
              onChange={handleChangeUnplanned}
              value={"yes"}
            />
          }
          label={form.outcomeUnplanned ? "YES" : "NO"}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>Did the event occur as expected?</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <CustomSwitch
              checked={form.outcomeExpected}
              onChange={handleChangeExpected}
              value={"yes"}
            />
          }
          label={form.outcomeExpected ? "YES" : "NO"}
        />
      </Grid>

      <Grid
        container
        style={
          !form.outcomeExpected
            ? null
            : {
                display: "none",
              }
        }
      >
        <Grid item xs={12}>
          <Typography>Was there injury or property damage?</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <CustomSwitch
                checked={form.outcomeInjury}
                onChange={handleChangeInjury}
                value={"yes"}
              />
            }
            label={form.outcomeInjury ? "YES" : "NO"}
          />
        </Grid>
      </Grid>

      <Grid
        container
        style={
          !form.outcomeExpected && !form.outcomeInjury
            ? null
            : {
                display: "none",
              }
        }
      >
        <Grid item xs={12}>
          <Typography>
            Did an event occur, where with a slight change in timing/position,
            there may have been an injury or property damage?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <CustomSwitch
                checked={form.outcomeNearMiss}
                onChange={handleChangeNearMiss}
                value={"yes"}
              />
            }
            label={form.outcomeNearMiss ? "YES" : "NO"}
          />
        </Grid>
      </Grid>

      <Grid
        container
        style={
          !form.outcomeExpected && form.outcomeInjury
            ? null
            : {
                display: "none",
              }
        }
      >
        <OutcomeInjuries type={type} />
      </Grid>
      <Grid
        item
        key={"loss-amount"}
        xs={12}
        style={
          form.outcomeInjury
            ? {
                width: "100%",
              }
            : {
                display: "none",
                width: "100%",
              }
        }
        container
        justifyContent="center"
      >
        <TextField
          value={form.dollarAmount || ""}
          onChange={handleChangeDollarAmount}
          variant="standard"
          label={"Estimated Loss Amount:"}
          multiline={true}
          type="number"
          style={{ width: "50%", minWidth: "200px", marginBottom: "20px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid
        item
        key={"root-causes"}
        xs={12}
        style={
          !form.outcomeExpected && form.outcomeInjury
            ? {
                width: "100%",
              }
            : {
                display: "none",
              }
        }
      >
        <RootCauses
          type={type}
          onEditSection={onEditSection}
          mitigators={mitigators}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
}
