import React from "react";

import { Grid, TextField } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import { useReactiveVar } from "@apollo/client";

import { createHAVar, editHAVar } from "../../../graphql/localVariables/ha";
import { haMutations } from "../../../graphql/mutations";
import { useStyles } from "./styles";

export default function DateInputView({ type }) {
  const editHA = useReactiveVar(editHAVar);
  const createHA = useReactiveVar(createHAVar);
  const updateHA = type === "EDIT" ? editHA : createHA;
  const { updateCreateHA, updateEditHA } = haMutations;
  const updateHAFunction = type === "EDIT" ? updateEditHA : updateCreateHA;
  const classes = useStyles()();

  const handleChange = (date) => {
    updateHAFunction("date", date);
  };

  return (
    <Grid item xs={12} sm={6} lg={3} className={classes.borderedBox}>
      <MobileDatePicker
        label="Date:"
        inputFormat="MM/DD/YYYY"
        value={updateHA.date}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            style={{
              width: "100%",
            }}
            variant="standard"
          />
        )}
      />
    </Grid>
  );
}
