import { makeStyles } from "@mui/styles";

const useStyles = () =>
  makeStyles(() => ({
    root: {
      height: "calc(100vh - 125px)",
      width: "100%",
      padding: "10px",
    },
    dashboardMonitorRoot: {
      height: "100%",
    },
    gaugeRoot: {
      height: "100%",
      margin: 0,
    },
  }));

export default useStyles;
