import React from "react";

import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import { useMutation, useLazyQuery, useReactiveVar } from "@apollo/client";
import moment from "moment";

import { onlineVar } from "../../../../graphql/localVariables/user";
import { TEMPLATE_CREATE } from "../../../../graphql/mutations/admin/planning-template";
import { GET_ACTIONS } from "../../../../graphql/queries";
import { GET_COMPANY } from "../../../../graphql/queries/admin/company";
import {
  GET_TEMPLATE_PAGE_OSPS,
  GET_TEMPLATES,
} from "../../../../graphql/queries/admin/planning-template";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../CustomComponents/OnErrorFunction";
import SelectedUnselected from "../../../CustomComponents/SelectedUnselected";
import { CustomTextField } from "../../../CustomStyles/LightTextField";
import { yellowButton } from "../../../CustomStyles/buttons";
import { CustomDialog } from "../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../CustomStyles/pageTitle";
import ListboxComponent from "./VirtualizedListBox";

export default function CreateTemplate({ open, handleClose, company }) {
  const online = useReactiveVar(onlineVar);
  const [getActions, { data: actions }] = useLazyQuery(GET_ACTIONS, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const [getOsps, { data: osps, loading: loadingOSP }] = useLazyQuery(
    GET_TEMPLATE_PAGE_OSPS,
    {
      fetchPolicy: online ? "network-only" : "cache-only",
    }
  );

  React.useEffect(() => {
    if (open) {
      getOsps();
      getActions();
    }
  }, [open]);

  const [createTemplate, { loading }] = useMutation(TEMPLATE_CREATE, {
    onCompleted() {
      handleClose();
      onClear();
      onCompletedFunc("Template has been created.");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [
      { query: GET_TEMPLATES },
      company && {
        query: GET_COMPANY,
        variables: { id: Number(company.id) },
      },
    ],
  });

  const [name, setName] = React.useState("");
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [osp, setOsp] = React.useState([]);

  const [selectedActions, setSelectedActions] = React.useState([]);
  const [unselectedActions, setUnselectedActions] = React.useState([]);

  React.useEffect(() => {
    if (actions) {
      setSelectedActions([]);
      setUnselectedActions(actions.actionRiskMitigators);
    }
  }, [actions]);

  const onSave = () => {
    if (osp.length === 0) {
      onErrorFunc(
        "Include at least one company, site or project for your template."
      );
    } else if (!startDate || !endDate) {
      onErrorFunc("Include start date and end date of template.");
    } else {
      createTemplate({
        variables: {
          name,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
          associatedOSP: osp.map((osp) => Number(osp.id)),
          unassociatedActions: [],
          associatedActions: selectedActions.map((a) => Number(a.id)),
        },
      });
    }
  };

  const updateState = () => {};

  const onCancel = () => {
    onClear();
    handleClose();
  };

  const onClear = () => {
    setName("");
    setStartDate(null);
    setEndDate(null);
    setOsp([]);
    setSelectedActions([]);
    if (actions) {
      setUnselectedActions(actions.actionRiskMitigators);
    }
  };

  const OSPSelect = () => {
    const ospList = osps ? osps.templatePageOsps : [];

    const getOptionLabelFunction = (option) => {
      if (!option.site && !option.project) {
        return `Company: ${option.owner?.name}`;
      } else if (option.site && !option.project) {
        return `Site: ${option.site?.name} [${option.owner?.name}]`;
      } else
        return `Project: ${option.site?.name} [${option.owner?.name}] - ${option.project?.name}`;
    };

    return (
      <>
        <Autocomplete
          id="select-osp"
          options={ospList.sort((a, b) => {
            if (!a.site && b.site) {
              return -1;
            }
            if (a.site && !b.site) {
              return 1;
            }
            if (!a.project && b.project) {
              return -1;
            }
            if (a.project && !b.project) {
              return 1;
            }
            if (!a.site && !a.project && !b.site && !b.project) {
              return a.owner.name > b.owner.name ? 1 : -1;
            }
            if (a.site && !a.project && b.site && !b.project) {
              return a.site.name > b.site.name ? 1 : -1;
            }
            if (a.project && b.project) {
              return a.project.name > b.project.name ? 1 : -1;
            }
            return 0;
          })}
          value={osp}
          ListboxComponent={ListboxComponent}
          onChange={(event, value) => setOsp(value)}
          getOptionLabel={getOptionLabelFunction}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disableClearable
          multiple
          loading={loadingOSP}
          renderOption={(props, option, state) => [props, option, state]}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              style={{ color: "#fff" }}
              variant="standard"
              label={"Company, Site or Project:"}
            />
          )}
        />
      </>
    );
  };

  return (
    <CustomDialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle
        style={{
          ...pageTitleStyles,
        }}
      >
        CREATE TEMPLATE.
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTextField
              label="Template Name:"
              variant="standard"
              value={name}
              onChange={(event) => setName(event.target.value)}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MobileDatePicker
              label="Start Date:"
              inputFormat="MM/DD/YYYY"
              value={startDate}
              onChange={(date) => setStartDate(date)}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  style={{
                    width: "100%",
                  }}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MobileDatePicker
              label="End Date:"
              inputFormat="MM/DD/YYYY"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  style={{
                    width: "100%",
                  }}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            {OSPSelect()}
          </Grid>

          <Grid item xs={12}>
            <SelectedUnselected
              selected={selectedActions}
              unselected={unselectedActions}
              setSelected={setSelectedActions}
              setUnselected={setUnselectedActions}
              updateState={updateState}
              idKey="a"
              focusList={[]}
              label="Actions"
              selectedLabel
              showSearch
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          style={{ marginRight: "10px", ...yellowButton }}
          onClick={onSave}
          loading={loading}
        >
          CREATE
        </LoadingButton>
        <Button variant="contained" onClick={onCancel}>
          CANCEL
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}
