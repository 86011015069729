import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  overlay: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgb(0 0 0 / 30%)",
    opacity: 0.8,
    overflow: "hidden",
    width: "100%",
    height: 40,
    transition: ".5s ease",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    "&:hover": {
      height: "45px",
      opacity: 1,
      "& $text": {
        color: "#FFB700",
        cursor: "pointer",
      },
    },
  },
  text: {
    color: "#8297A0",
    position: "absolute",
    top: "50%",
    left: "50%",
    "-webkit-transform": "translate(-50%, -50%)",
    "-ms-transform": "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
}));

export default useStyles;
