import React from "react";

import { Grid } from "@mui/material";

import Contractors from "./Contractors";
import DateView from "./Date";
import HA from "./HA";
import Location from "./Location";
import Projects from "./Projects";
import TimeView from "./Time";

export default function General({
  type,
  sites,
  projects,
  loadingProjects,
  onEditSection,
}) {
  const onEdit = () => {
    onEditSection(1);
  };

  return (
    <Grid container spacing={2} sx={{ padding: { xs: "10px", md: "20px" } }}>
      <Grid item xs={12} md={4}>
        <DateView type={type} />
      </Grid>
      <Grid item xs={12} md={4}>
        <TimeView type={type} />
      </Grid>
      <Grid item xs={12} md={4}>
        <HA type={type} onEdit={onEdit} />
      </Grid>
      <Grid item xs={12} md={4}>
        <Location type={type} data={sites} onEdit={onEdit} />
      </Grid>
      <Grid item xs={12} md={4}>
        <Projects
          type={type}
          data={projects}
          loading={loadingProjects}
          onEdit={onEdit}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Contractors type={type} onEdit={onEdit} />
      </Grid>
    </Grid>
  );
}
