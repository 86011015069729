import React from "react";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { CustomDialog } from "../../CustomStyles/dialog";
import PreviewImages from "./PreviewImages";

export default function ViewImages({ open, handleClose, images }) {
  const onCancel = () => {
    handleClose();
  };

  return (
    <CustomDialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle
        style={{
          fontFamily: "-apple-system,BlinkMacSystemFont,'Segoe UI'",
          fontWeight: "bolder",
          color: "white",
        }}
        sx={{ fontSize: { xs: "1.5rem", md: "2.75rem" } }}
      >
        VIEW OBSERVATION IMAGES.
      </DialogTitle>
      <DialogContent>
        <PreviewImages fileObjects={images} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onCancel}>
          DONE
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}
