import * as React from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";

const styles = () => ({
  root: {},
  imageContainer: {
    position: "relative",
    zIndex: 10,
    textAlign: "center",
    "&:hover $image": {
      opacity: 0.3,
    },
    "&:hover $removeButton": {
      opacity: 1,
    },
    padding: "20px",
  },
  image: {
    maxWidth: "100%",
    width: "auto",
    maxHeight: "100%",
    height: "100px",
  },
  removeButton: {
    transition: ".5s ease",
    position: "absolute",
    top: 0,
    right: 0,
    opacity: 0,
    width: 40,
    height: 40,
    "&:focus": {
      opacity: 1,
    },
  },
});

function PreviewList({ fileObjects, classes }) {
  const isImage = (file) => {
    if (file.type && file.type.split("/")[0] === "image") {
      return true;
    }
  };

  const getName = (file) => {
    const arm = file.observationArm.arm;

    if (arm.m) {
      if (file.observationArm.isCorrection) {
        return (
          <Typography style={{ fontSize: "12px", color: "#FFB700" }}>
            <b>Correction:</b>{" "}
            {`${[arm.m.name]} [${arm.r.name}]  [${arm.a.name}]`}
          </Typography>
        );
      }
      return (
        <Typography style={{ fontSize: "12px" }}>
          <b>Mitigator:</b> {`${[arm.m.name]} [${arm.r.name}]  [${arm.a.name}]`}
        </Typography>
      );
    } else if (arm.r) {
      return (
        <Typography style={{ fontSize: "12px" }}>
          <b>Risk:</b> {`${arm.r.name}  [${arm.a.name}]`}
        </Typography>
      );
    } else {
      return (
        <Typography style={{ fontSize: "12px" }}>
          <b>Action:</b> {`${arm.a.name}`}
        </Typography>
      );
    }
  };

  const getPreviewIcon = (fileObject, classes) => {
    if (
      fileObject.mediaFile &&
      (fileObject.mediaFile.file.includes("png") ||
        fileObject.mediaFile.file.includes("jpg") ||
        fileObject.mediaFile.file.includes("jpeg"))
    ) {
      return (
        <>
          <a
            href={fileObject.mediaFile?.file}
            target="_blank"
            rel="noreferrer"
            download
            style={{ color: "white" }}
          >
            <img
              className={classes.image}
              src={fileObject.mediaFile.file}
              alt="Observation image"
            />
          </a>{" "}
          {getName(fileObject)}
        </>
      );
    }

    if (isImage(fileObject)) {
      return (
        <img
          className={classes.image}
          src={URL.createObjectURL(fileObject)}
          alt="Observation image"
        />
      );
    }

    return (
      <>
        {fileObject.mediaFile ? (
          <a
            href={fileObject.mediaFile?.file}
            target="_blank"
            rel="noreferrer"
            download
            style={{ color: "white" }}
          >
            <AttachFileIcon className={classes.image} />
            <Typography style={{ fontSize: "12px" }}>
              {getName(fileObject)}
            </Typography>
          </a>
        ) : (
          <>
            <AttachFileIcon className={classes.image} />
            <Typography style={{ fontSize: "12px" }}>
              {getName(fileObject)}
            </Typography>
          </>
        )}
      </>
    );
  };

  return (
    <Grid container>
      {fileObjects &&
        fileObjects.map((fileObject, i) => {
          return (
            <Grid
              item
              xs={12}
              md={3}
              key={`${fileObject.file?.name ?? "file"}-${i}`}
              className={classes.imageContainer}
            >
              {getPreviewIcon(fileObject, classes)}
            </Grid>
          );
        })}
    </Grid>
  );
}

export default withStyles(styles, { name: "MuiDropzonePreviewList" })(
  PreviewList
);
