import React from "react";

import { Grid, Typography } from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import {
  createObservationVar,
  editObservationVar,
} from "../../../graphql/localVariables/observation";
import { observationMutations } from "../../../graphql/mutations";
import SelectedUnselected from "../../CustomComponents/SelectedUnselected";
import CreateARM from "./CreateARM";

export default function RisksForm({
  type,
  onEditSection,
  risks: data,
  loading,
  permissions,
}) {
  // Global Variables
  const editObs = useReactiveVar(editObservationVar);
  const createObs = useReactiveVar(createObservationVar);
  const form = type === "EDIT" ? editObs : createObs;

  // Mutations
  const { updateKeyEditObservation, updateKeyCreateObservation } =
    observationMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditObservation : updateKeyCreateObservation;

  // Local Variables
  const [open, setOpen] = React.useState(false);
  const [selectedRisks, setSelectedRisks] = React.useState([]);
  const [unselectedRisks, setUnselectedRisks] = React.useState([]);
  const [focusList, setFocusList] = React.useState([]);

  const updateState = (newSelected) => {
    onEditSection(3);
    updateObsKeyFunction("risks", newSelected);

    const currentRiskIds = newSelected.map((s) => s.r.id);

    // Remove images of potentially removed action
    const removedUploadedFiles = form.files.filter((f) =>
      f.associatedItem.r
        ? !currentRiskIds.includes(f.associatedItem.r.id) && f.originalFile.id
        : false
    );

    if (form.removeMediaFiles) {
      updateObsKeyFunction("removeMediaFiles", [
        ...form.removeMediaFiles,
        ...removedUploadedFiles.map((f) => f.originalFile.id),
      ]);
    } else {
      updateObsKeyFunction("removeMediaFiles", [
        ...removedUploadedFiles.map((f) => f.originalFile.id),
      ]);
    }
    updateObsKeyFunction(
      "files",
      form.files.filter((f) =>
        f.associatedItem.r && f.associatedItem.r.id
          ? currentRiskIds.includes(f.associatedItem.r.id)
          : true
      )
    );
  };

  React.useEffect(() => {
    if (data && form.actions) {
      const filteredUnselectedRisks = [];
      const filteredSelectedRisks = [];
      // Get ARMs with selected actions
      const selectedARMs = data.companyArms
        .map((carm) => carm.arm)
        .filter(
          (arm) =>
            form.actions.findIndex(
              (a) => Number(arm.a.id) === Number(a.a.id)
            ) >= 0
        );

      selectedARMs.forEach((arm) => {
        if (
          arm.isExpected ||
          (form.risks &&
            form.risks.findIndex(
              (r) =>
                Number(r.a.id) === Number(arm.a.id) &&
                Number(r.r.id) === Number(arm.r.id)
            ) >= 0)
        ) {
          filteredSelectedRisks.push(arm);
        } else {
          filteredUnselectedRisks.push(arm);
        }
      });

      setUnselectedRisks(filteredUnselectedRisks);
      setSelectedRisks(filteredSelectedRisks);
      updateObsKeyFunction("risks", filteredSelectedRisks);
    }
  }, [data, form.actions]);

  React.useEffect(() => {
    if (form.ha && form.ha.haArms) {
      setFocusList(form.ha.haArms.map((arm) => Number(arm.arm.id)));
    }

    if (form.ha?.name === "") {
      setFocusList([]);
    }
  }, [form.ha]);

  return (
    <Grid testid="Risks" sx={{ padding: { xs: "10px", md: "20px" } }}>
      <SelectedUnselected
        selected={selectedRisks}
        unselected={unselectedRisks}
        setSelected={setSelectedRisks}
        setUnselected={setUnselectedRisks}
        updateState={updateState}
        idKey="r"
        itemType="risk"
        focusList={focusList}
        label="Risks"
        loadingUnselected={loading}
        hideMobileSearch
      />
      {permissions.includes("ARM") ? (
        <>
          <Typography
            onClick={() => setOpen(true)}
            style={{ marginTop: "20px", cursor: "pointer" }}
          >
            If you don&apos;t see the risk you are looking for,&nbsp;
            <span
              style={{
                background: "#FFB700",
              }}
            >
              click here
            </span>
            &nbsp;to add it.
          </Typography>
          <CreateARM
            open={open}
            handleClose={() => setOpen(false)}
            tab="risk"
            type={type}
          />
        </>
      ) : null}
    </Grid>
  );
}
