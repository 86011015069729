import React from "react";

import { Autocomplete, TextField } from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import {
  createIncidentVar,
  editIncidentVar,
} from "../../../../graphql/localVariables/investigation";
import { investigationMutations } from "../../../../graphql/mutations";

export default function Location({ type, data }) {
  // Global Variables
  const editObs = useReactiveVar(editIncidentVar);
  const createObs = useReactiveVar(createIncidentVar);
  const form = type === "EDIT" ? editObs : createObs;

  // Mutations
  const { updateKeyEditIncident, updateKeyCreateIncident } =
    investigationMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditIncident : updateKeyCreateIncident;

  const handleChange = (event, newValue) => {
    if (newValue) {
      updateObsKeyFunction("site", { id: newValue.site.id });
      updateObsKeyFunction("owner", { id: newValue.owner.id });
      updateObsKeyFunction("location", newValue.location);
      updateObsKeyFunction("project", { name: "" });
      updateObsKeyFunction("contractor", { name: "" });
    } else {
      updateObsKeyFunction("site", null);
      updateObsKeyFunction("owner", null);
      updateObsKeyFunction("location", "");
      updateObsKeyFunction("project", { name: "" });
      updateObsKeyFunction("contractor", { name: "" });
    }
  };

  React.useEffect(() => {}, [form.location]);

  return (
    <Autocomplete
      id="select-location"
      options={
        data && data.ownerSiteProjects
          ? [...data.ownerSiteProjects].sort((a, b) =>
              a.location > b.location ? 1 : -1
            )
          : []
      }
      value={form.location ? form.location : { location: "" }}
      getOptionLabel={(option) => {
        return option.location === undefined ? option : option.location;
      }}
      onChange={handleChange}
      isOptionEqualToValue={(option, value) =>
        option.location === value.location || option.location === value
      }
      renderOption={(props, option) => (
        <li {...props} key={`${option.location}-${option.id}`}>
          {option.location}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Location:"
          value={params}
          id={params.id}
        />
      )}
    />
  );
}
