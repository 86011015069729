import * as React from "react";
import { alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Search,
  StyledInputBase,
  SearchIconWrapper,
} from "../../../../CustomStyles/searchBar";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Autocomplete,
  TextField,
  Grid,
  Button,
} from "@mui/material";
import { blueButton, blackButton } from "../../../../CustomStyles/buttons";

// PROPS
// searchTerm - string
// onSearchChange - function
// title - string
export default function ReusableTableToolbar(props) {
  const {
    numSelected,
    title,
    onSearchChange,
    searchTerm,
    onActiveChange,
    active,
    clearFilters,
  } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <Toolbar
      sx={{
        pl: {
          sm: 2,
        },
        pr: {
          xs: 1,
          sm: 1,
        },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{
          flex: "1 1 100%",
        }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {title ? title : null}
      </Typography>
      <Tooltip title="Filter">
        <IconButton onClick={() => setOpen(true)}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogContent style={{ minHeight: "100px" }}>
          <Grid container>
            <Grid item xs={12} md={4} style={{ padding: "5px" }}>
              <Autocomplete
                id="filter-active"
                options={["Active", "Inactive"]}
                value={active}
                onChange={onActiveChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Filter by Active:"
                    value={params}
                    id={params.id}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} style={{ ...blueButton }}>
            FILTER
          </Button>
          <Button onClick={clearFilters} style={{ ...blackButton }}>
            CLEAR
          </Button>
        </DialogActions>
      </Dialog>
      <Tooltip title="Search">
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{
              "aria-label": "search",
            }}
            value={searchTerm}
            onChange={onSearchChange}
          />
        </Search>
      </Tooltip>
    </Toolbar>
  );
}
