import React from "react";
import { useNavigate } from "react-router-dom";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  Badge,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/styles";

import { useQuery } from "@apollo/client";
import moment from "moment";

import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import useStyles from "./styles";

const ObsItem = ({ obs, currentUser }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const d = new Date(obs.date);
  const utc = d.getTime() + d.getTimezoneOffset() * 60000;
  const convertedDate = new Date(utc);

  var time = new Date();
  const timeArr = obs.time.split(":");
  time.setHours(timeArr[0], timeArr[1], timeArr[2], 0);

  return (
    <>
      <Grid
        style={{
          backgroundColor: "black",
          width: "100%",
          color: "white",
          textAlign: "center",
          borderRadius: 6,
          opacity: "0.75",
          margin: "10px 0px",
          cursor: "pointer",
        }}
        container
        onClick={handleClick}
      >
        <Grid item xs={12} container style={{ padding: "5px 20px" }}>
          <Grid item xs={12}>
            <Typography>
              {obs.observationOutcomes.length > 0
                ? obs.observationOutcomes.map((o) => o.outcome.name).join(", ")
                : "No Outcomes"}{" "}
              | {moment(convertedDate).format("MM/DD/YYYY")}{" "}
              {moment(time).format("hh:mm A")}
            </Typography>
            <Typography>
              <span style={{ fontSize: "0.8rem" }}>SUBMITTED BY:</span>{" "}
              {obs.submitter.username}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={() => navigate(`/view-obs/${obs.obsId}`)}>
          VIEW
        </MenuItem>
        {currentUser?.currentUser.id === obs.submitter.id && (
          <MenuItem onClick={() => navigate(`/edit-obs/${obs.obsId}`)}>
            EDIT
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default function RecentObs({ obs, refetch, loading }) {
  const todayDate = moment(new Date()).format("YYYY-MM-DD");
  const lastWeek = moment(new Date()).subtract(7, "days").format("YYYY-MM-DD");
  const yesterdayDate = moment(new Date())
    .subtract(1, "days")
    .format("YYYY-MM-DD");

  const { data: currentUser } = useQuery(GET_CURRENT_USER);
  const theme = useTheme();
  const classes = useStyles()();
  const [expanded, setExpanded] = React.useState("panel1");
  const [today, setToday] = React.useState([]);
  const [yesterday, setYesterday] = React.useState([]);
  const [week, setWeek] = React.useState([]);
  const [sort, setSort] = React.useState("desc");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : panel);
  };

  const todayDateFormatted = moment(new Date()).format("ddd MMM D, YYYY");
  const yesterdayDateFormatted = moment(new Date())
    .subtract(1, "days")
    .format("ddd MMM D, YYYY");

  React.useEffect(() => {
    if (obs && obs.observations?.edges) {
      const obsList = obs.observations.edges.map((e) => e.node);
      setToday(obsList.filter((o) => o.date === todayDate));
      setYesterday(obsList.filter((o) => o.date === yesterdayDate));
      setWeek(obsList.filter((o) => o.date >= lastWeek));
    }
  }, [obs]);

  const changeSort = () => {
    if (sort === "desc") {
      setSort("asc");
    } else setSort("desc");
  };

  const sortItems = (arr) => {
    return arr.sort((a, b) => {
      const bDate = new Date(b.date);
      const aDate = new Date(a.date);

      const bTimeArr = b.time.split(":");
      bDate.setHours(bTimeArr[0], bTimeArr[1], bTimeArr[2], 0);

      const aTimeArr = a.time.split(":");
      aDate.setHours(aTimeArr[0], aTimeArr[1], aTimeArr[2], 0);

      if (sort === "asc") {
        return bDate > aDate ? 1 : -1;
      } else {
        return aDate > bDate ? 1 : -1;
      }
    });
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      className={classes.root}
      style={{
        backgroundColor: "inherit",
        borderRadius: "6px",
        boxShadow: `0px -5px 30px ${theme.palette.grey3.main}`,
        position: "relative",
      }}
    >
      {loading && (
        <Grid
          container
          justifyContent="center"
          style={{ position: "absolute", top: "50%" }}
        >
          <CircularProgress color="secondary" />
        </Grid>
      )}
      <Grid item xs={12} container>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="space-between"
          style={{ marginBottom: "5px" }}
        >
          <Typography
            className={classes.cardTitle}
            sx={{
              fontFamily: "barlowextrabold",
              paddingLeft: "10px",
            }}
          >
            RECENT OBS
          </Typography>
          <Grid item>
            <Tooltip title="Refresh Observations">
              <IconButton onClick={() => refetch()}>
                <RefreshIcon style={{ color: theme.palette.grey2.main }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Sort by Outcomes">
              <IconButton onClick={changeSort}>
                {sort === "desc" ? (
                  <ArrowDownwardIcon
                    style={{ color: theme.palette.grey2.main }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    style={{ color: theme.palette.grey2.main }}
                  />
                )}
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            disableGutters
            style={{
              backgroundColor: "inherit",
              boxShadow:
                expanded === "panel1"
                  ? `0px -5px 30px ${theme.palette.grey3.main}`
                  : "",
              color: theme.palette.grey1.main,
            }}
          >
            <AccordionSummary
              style={{
                color:
                  expanded === "panel1"
                    ? theme.palette.yellow0.main
                    : theme.palette.grey1.main,
                fontFamily: "barlowextrabold",
              }}
            >
              <Badge badgeContent={today.length} showZero color="secondary">
                <div
                  style={{
                    width: "90px",
                    border: "1px solid #FFFFFF50",
                    borderRadius: 6,
                    textAlign: "center",
                  }}
                >
                  Today
                </div>
              </Badge>
              <Grid
                container
                alignContent="center"
                style={{ marginLeft: "20px", fontSize: "12px" }}
              >
                [{todayDateFormatted}]
              </Grid>
            </AccordionSummary>
            <AccordionDetails style={{ height: "30vh", overflowY: "auto" }}>
              {today.length === 0 ? (
                <Grid container justifyContent="center">
                  <Typography>No observations submitted</Typography>
                </Grid>
              ) : (
                sortItems(today).map((obs) => (
                  <ObsItem key={obs.id} obs={obs} currentUser={currentUser} />
                ))
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            disableGutters
            style={{
              backgroundColor: "inherit",
              boxShadow:
                expanded === "panel3"
                  ? `0px -5px 30px ${theme.palette.grey3.main}`
                  : "",
              color: theme.palette.grey1.main,
            }}
          >
            <AccordionSummary
              style={{
                color:
                  expanded === "panel3"
                    ? theme.palette.yellow0.main
                    : theme.palette.grey1.main,
                fontFamily: "barlowextrabold",
              }}
            >
              <Badge badgeContent={yesterday.length} showZero color="secondary">
                <div
                  style={{
                    width: "90px",
                    border: "1px solid #FFFFFF50",
                    borderRadius: 6,
                    textAlign: "center",
                  }}
                >
                  Yesterday
                </div>
              </Badge>
              <Grid
                container
                alignContent="center"
                style={{ marginLeft: "20px", fontSize: "12px" }}
              >
                [{yesterdayDateFormatted}]
              </Grid>
            </AccordionSummary>
            <AccordionDetails style={{ height: "30vh", overflowY: "auto" }}>
              {yesterday.length === 0 ? (
                <Grid container justifyContent="center">
                  <Typography>No observations submitted</Typography>
                </Grid>
              ) : (
                sortItems(yesterday).map((obs) => (
                  <ObsItem key={obs.id} obs={obs} currentUser={currentUser} />
                ))
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            disableGutters
            style={{
              backgroundColor: "inherit",
              boxShadow:
                expanded === "panel2"
                  ? `0px -5px 30px ${theme.palette.grey3.main}`
                  : "",
              color: theme.palette.grey1.main,
            }}
          >
            <AccordionSummary
              style={{
                color:
                  expanded === "panel2"
                    ? theme.palette.yellow0.main
                    : theme.palette.grey1.main,
                fontFamily: "barlowextrabold",
              }}
            >
              <Badge badgeContent={week.length} showZero color="secondary">
                <div
                  style={{
                    width: "90px",
                    border: "1px solid #FFFFFF50",
                    borderRadius: 6,
                    textAlign: "center",
                  }}
                >
                  Last 7 Days
                </div>
              </Badge>
            </AccordionSummary>
            <AccordionDetails style={{ height: "30vh", overflowY: "auto" }}>
              {week.length === 0 ? (
                <Grid container justifyContent="center">
                  <Typography>No observations submitted</Typography>
                </Grid>
              ) : (
                sortItems(week).map((obs) => (
                  <ObsItem key={obs.id} obs={obs} currentUser={currentUser} />
                ))
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Grid>
  );
}
