import * as React from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";

import { useQuery, useReactiveVar } from "@apollo/client";

import { onlineVar } from "../../../../graphql/localVariables/user";
import { GET_COMPANIES } from "../../../../graphql/queries";
import { GET_PERMISSION_GROUPS } from "../../../../graphql/queries/permissions";
import { blackButton, blueButton } from "../../../CustomStyles/buttons";
import {
  Search,
  StyledInputBase,
  SearchIconWrapper,
} from "../../../CustomStyles/searchBar";

// PROPS
// searchTerm - string
// onSearchChange - function
// title - string
export default function ReusableTableToolbar(props) {
  const online = useReactiveVar(onlineVar);
  const { data: groups } = useQuery(GET_PERMISSION_GROUPS, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const { data } = useQuery(GET_COMPANIES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  const {
    numSelected,
    title,
    onSearchChange,
    searchTerm,
    groupsFilter,
    onGroupChange,
    companyFilter,
    onCompanyChange,
    clearFilters,
  } = props;
  const [open, setOpen] = React.useState(false);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <Toolbar
      sx={{
        pl: {
          sm: 2,
        },
        pr: {
          xs: 1,
          sm: 1,
        },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{
          flex: "1 1 100%",
        }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {title ? title : null}
      </Typography>
      <Tooltip title="Filter">
        <IconButton onClick={() => setOpen(true)}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <Grid container>
            <Grid item xs={12} md={4} style={{ padding: "5px" }}>
              <Autocomplete
                id="filter-user-company"
                options={
                  data
                    ? [...data.companies].sort((a, b) =>
                        a.name > b.name ? 1 : -1
                      )
                    : []
                }
                value={companyFilter}
                getOptionLabel={(option) => option.name}
                onChange={onCompanyChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Filter by Company:"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} style={{ padding: "5px" }}>
              <Autocomplete
                id="select-groups"
                options={groups ? groups.PermissionGroups : []}
                getOptionLabel={(option) => {
                  return option.name;
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                value={groupsFilter}
                onChange={onGroupChange}
                multiple
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Filter by Permission Groups:"
                    value={params}
                    id={params.id}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} style={{ ...blueButton }}>
            FILTER
          </Button>
          <Button onClick={clearFilters} style={{ ...blackButton }}>
            CLEAR
          </Button>
        </DialogActions>
      </Dialog>
      <Tooltip title="Search">
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{
              "aria-label": "search",
            }}
            value={searchTerm}
            onChange={onSearchChange}
          />
        </Search>
      </Tooltip>
    </Toolbar>
  );
}
