import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Grid, Typography, Paper } from "@mui/material";

import axios from "axios";

import { authMutations } from "../../graphql/mutations";
import logo from "../../images/nixn-logo-square.png";
import PasswordField from "../CustomComponents/PasswordField";
import { blackButton } from "../CustomStyles/buttons";
import { pageTitleStyles } from "../CustomStyles/pageTitle";

export default function PasswordReset() {
  const { string: cryptostring } = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = React.useState("");
  const [passwordConfirm, setPasswordConfirm] = React.useState("");

  // Mutations
  const { updateSnackbar } = authMutations;

  const submitReset = () => {
    axios
      .post("/main/pw-reset/", {
        cryptostring,
        new_password1: password,
        new_password2: passwordConfirm,
      })
      .then((response) => {
        if (response.data.success) {
          updateSnackbar({
            open: true,
            message: "Password successfully reset!",
            severity: "success",
          });
          navigate("/login");
        } else {
          updateSnackbar({
            open: true,
            message: response.data.message,
            severity: "error",
          });
        }
      })
      .catch(() => {
        updateSnackbar({
          open: true,
          message: "There was an error when resetting your password.",
          severity: "error",
        });
      });
  };

  return (
    <Grid
      container
      alignItems="center"
      style={{ width: "100%" }}
      justifyContent="center"
    >
      <Grid
        item
        xs={12}
        container
        style={{ backgroundColor: "#151711", height: 60, width: "100%" }}
        justifyContent="center"
      >
        <img src={logo} alt="NIXN logo" style={{ height: "100%" }} />
      </Grid>
      <Grid item style={{ marginTop: "30px" }} xs={10} md={6}>
        <Typography style={pageTitleStyles}>PASSWORD RESET.</Typography>
        <Paper style={{ width: "100%" }}>
          <Grid container direction="column" alignItems="center">
            <PasswordField
              password={password}
              setPassword={setPassword}
              label="New Password:"
              style={{ width: "80%" }}
            />
            <PasswordField
              password={passwordConfirm}
              setPassword={setPasswordConfirm}
              label="Confirm New Password:"
              style={{ width: "80%" }}
            />
            <Button
              onClick={submitReset}
              style={{ ...blackButton, margin: "20px" }}
            >
              SUBMIT
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
