export const CompanyTypeFunction = () => {
  return {
    fields: {
      isContractor: {
        read(value, { readField }) {
          const contractorArray = readField("ownerSiteProjectContractor");
          if (contractorArray.length !== 0) {
            return true;
          }
          return false;
        },
      },
    },
  };
};

export const HazardAnalysisTypeFunction = () => {
  return {
    fields: {
      ospId: {
        read(value, { readField }) {
          let osp = null;
          if (readField("ownerSiteProject")) {
            osp = readField("id", readField("ownerSiteProject"));
          }
          return osp;
        },
      },
    },
  };
};

export const OwnerSiteProjectTypeFunction = () => {
  return {
    fields: {
      projectId: {
        read(value, { readField }) {
          const projectId = readField("id", readField("project"));
          if (projectId) {
            return projectId;
          }
          return null;
        },
      },
      location: {
        read(value, { readField }) {
          const site = readField("site");
          const owner = readField("owner");
          return `${readField("name", site)} [${readField("name", owner)}]`;
        },
      },
    },
  };
};

export const SiteTypeFunction = () => {
  return {
    fields: {
      location: {
        read(value, { readField }) {
          const ownerArray = readField("ownerSiteProjects");
          const siteName = readField("name");
          if (ownerArray && ownerArray.length !== 0) {
            return `${siteName} [${readField("name", ownerArray[0].owner)}]`;
          }
          return siteName;
        },
      },
      ownerId: {
        read(value, { readField }) {
          const ownerArray = readField("ownerSiteProjects");
          if (ownerArray.length !== 0) {
            return readField("id", ownerArray[0].owner);
          }
          return null;
        },
      },
    },
  };
};
