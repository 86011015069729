import { authMutations } from "../../graphql/mutations";
import { createUpdatePermissions } from "../../graphql/mutations/auth";

const { updateAuth } = authMutations;

export const handleLogout = (client) => {
  localStorage.clear();
  client.clearStore();
  // eslint-disable-next-line no-console
  console.log("CLEARING CACHE.");
  updateAuth(false);
  createUpdatePermissions([]);
};

export default handleLogout;
