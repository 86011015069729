import React from "react";

import CheckIcon from "@mui/icons-material/Check";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import { useMutation, useQuery, useReactiveVar } from "@apollo/client";

import { permissionsVar } from "../../../../graphql/localVariables/user";
import {
  MUTATE_CATEGORY,
  MUTATE_CATEGORY_ACTION,
} from "../../../../graphql/mutations/admin/arm";
import {
  GET_ACTIVE_ACTIONS_ADMIN,
  GET_CATEGORIES,
  GET_CATEGORY_ACTIONS,
} from "../../../../graphql/queries/admin/arm";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../CustomComponents/OnErrorFunction";
import { CustomSwitch } from "../../../CustomComponents/Switch";
import { CustomTextField } from "../../../CustomStyles/LightTextField";
import { yellowButton } from "../../../CustomStyles/buttons";
import { pageTitleStyles } from "../../../CustomStyles/pageTitle";

function AssociatedAction({ action, remove, disabled }) {
  return (
    <Grid
      key={action.id}
      container
      style={{
        padding: "10px",
        height: "fit-content",
      }}
      alignItems="center"
      justifyContent="flex-start"
    >
      <Grid item xs={2}>
        <IconButton onClick={() => remove(action)} disabled={disabled}>
          <CheckIcon />
        </IconButton>
      </Grid>
      <Grid item xs={10}>
        <Typography style={{ color: "black" }}>
          {action.a ? action.a.name : action.name}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default function CategoryPage({ id, handleClose: handleParentClose }) {
  const permissions = useReactiveVar(permissionsVar);

  const { data: categories } = useQuery(GET_CATEGORIES, {
    fetchPolicy: "network-only",
  });
  const { data: categoryActions, loading } = useQuery(GET_CATEGORY_ACTIONS, {
    variables: { categoryId: Number(id) },
    fetchPolicy: "network-only",
  });

  const { data: actions } = useQuery(GET_ACTIVE_ACTIONS_ADMIN, {
    fetchPolicy: "network-only",
  });

  const [mutateCategoryBatchActions] = useMutation(MUTATE_CATEGORY_ACTION, {
    onCompleted() {
      onCompletedFunc("Category has been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [{ query: GET_CATEGORIES }],
  });
  const [mutateCategory] = useMutation(MUTATE_CATEGORY, {
    onCompleted() {
      setOpen(false);
      onCompletedFunc("Category has been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [{ query: GET_CATEGORIES }],
  });

  // CATEGORY STATE
  const [category, setCategory] = React.useState({ name: "" });
  const [isActive, setIsActive] = React.useState(false);
  const [categoryName, setCategoryName] = React.useState("");
  const [associatedActions, setAssociatedActions] = React.useState([]);
  const [addedActions, setAddedActions] = React.useState([]);
  const [updateAssociatedAction, setUpdateAssociatedAction] = React.useState(
    []
  );
  const [actionSearch, setActionSearch] = React.useState("");

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (categories && id) {
      setCategory(categories.categories.find((c) => c.id === id));
    }
  }, [categories, id]);

  React.useEffect(() => {
    if (category.name !== "") {
      setIsActive(category.isActive);
      setCategoryName(category.name);
    }
  }, [category]);

  React.useEffect(() => {
    if (categoryActions) {
      setAssociatedActions(categoryActions.categoryActions);
    }
  }, [categoryActions]);

  React.useEffect(() => {
    setUpdateAssociatedAction([...associatedActions, ...addedActions]);
  }, [associatedActions, addedActions]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearchChange = (event) => {
    setActionSearch(event.target.value);
  };

  const add = (a) => {
    setAddedActions((prev) => [...prev, a]);
  };

  const remove = (a) => {
    setAssociatedActions((prev) => prev.filter((p) => p.action.id !== a.id));
    setAddedActions((prev) => prev.filter((p) => p.id !== a.id));
  };

  const onSave = () => {
    const assoArmInfo = associatedActions.map((cat) => Number(cat.action.id));
    const addedArmInfo = addedActions.map((a) => Number(a.a.id));
    const unassociated =
      actions &&
      actions.actionRiskMitigators
        .filter(
          (a) =>
            associatedActions.findIndex((cat) => cat.action.id === a.a.id) <
              0 && addedActions.findIndex((action) => action.id === a.a.id) < 0
        )
        .map((a) => Number(a.a.id));

    mutateCategoryBatchActions({
      variables: {
        category: Number(category.id),
        associatedActions: [...assoArmInfo, ...addedArmInfo],
        unassociatedActions: unassociated,
      },
    });
    handleParentClose();
  };

  const onSaveEdit = () => {
    mutateCategory({
      variables: { input: { id: category.id, name: categoryName, isActive } },
    });
  };

  const onCancel = () => {
    if (categoryActions) {
      setAssociatedActions(categoryActions.categoryActions);
    }
    setAddedActions([]);
    handleParentClose();
  };

  const canEdit =
    permissions.includes("EDIT_CATEGORY") || permissions.includes("CATEGORY");

  return (
    <>
      <DialogContent>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={10}>
            <Typography
              style={{
                ...pageTitleStyles,
              }}
            >
              CATEGORY: {category.name}.
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} container justifyContent="flex-end">
            {canEdit && (
              <Grid item>
                <Button
                  variant="contained"
                  style={{ ...yellowButton, zIndex: 1000 }}
                  endIcon={<EditIcon />}
                  onClick={handleOpen}
                >
                  EDIT
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid item xs={7}>
            <Typography style={{ color: "white" }}>
              ASSOCIATED ACTIONS:
            </Typography>
            {loading ? (
              <Grid container style={{ width: "100%" }} justifyContent="center">
                <CircularProgress color="secondary" />
              </Grid>
            ) : (
              <Grid
                container
                style={{
                  maxHeight: "300px",
                  overflowY: "auto",
                  backgroundColor: "white",
                  width: "90%",
                  marginTop: "10px",
                  borderRadius: 5,
                }}
              >
                {updateAssociatedAction.length === 0 ? (
                  <Typography style={{ padding: "20px" }}>
                    No associated actions.
                  </Typography>
                ) : (
                  updateAssociatedAction
                    .sort((a, b) => (a.action?.name > b.action?.name ? 1 : -1))
                    .map((action) => (
                      <AssociatedAction
                        key={action.id}
                        action={action.action ? action.action : action}
                        remove={remove}
                        disabled={!canEdit}
                      />
                    ))
                )}
              </Grid>
            )}
          </Grid>
          <Grid item xs={5}>
            <Typography style={{ color: "white" }}>
              UNASSOCIATED ACTIONS:
            </Typography>
            <CustomTextField
              label="SEARCH:"
              variant="standard"
              style={{ margin: "10px" }}
              value={actionSearch}
              onChange={handleSearchChange}
            />
            <Grid
              container
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                backgroundColor: "white",
                width: "90%",
                marginTop: "10px",
                borderRadius: 5,
              }}
            >
              {actions &&
                actions.actionRiskMitigators
                  .filter(
                    (a) =>
                      associatedActions.findIndex(
                        (cat) => cat.action.id === a.a.id
                      ) < 0 &&
                      addedActions.findIndex(
                        (action) => action.a.id === a.a.id
                      ) < 0 &&
                      a.a.name
                        .toLowerCase()
                        .includes(actionSearch.toLowerCase())
                  )
                  .sort((a, b) => (a.a.name > b.a.name ? 1 : -1))
                  .map((a) => (
                    <Grid
                      item
                      xs={12}
                      key={a.id}
                      style={{
                        padding: "10px",
                        height: "fit-content",
                        color: "black",
                      }}
                      container
                      alignItems="center"
                    >
                      <Grid item xs={2}>
                        <IconButton
                          style={{ marginRight: "10px" }}
                          onClick={() => add(a)}
                          disabled={!canEdit}
                        >
                          <CheckBoxOutlineBlankIcon />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography>{a.a.name}</Typography>
                      </Grid>
                    </Grid>
                  ))}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          style={{ marginRight: "10px", ...yellowButton }}
          onClick={onSave}
          disabled={!canEdit}
        >
          SAVE
        </Button>
        <Button variant="contained" onClick={onCancel}>
          CANCEL
        </Button>
      </DialogActions>
      <Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose}>
        <DialogTitle style={{ ...pageTitleStyles, color: "black" }}>
          EDIT CATEGORY.
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems="center">
            <Grid item xs={12} container alignItems="center">
              <FormControlLabel
                style={{ color: "black" }}
                control={
                  <CustomSwitch
                    checked={isActive}
                    onChange={(event) => setIsActive(event.target.checked)}
                  />
                }
                label={isActive ? "ACTIVE" : "INACTIVE"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                value={categoryName}
                label="Category Name:"
                onChange={(event) => setCategoryName(event.target.value)}
                style={{ width: "100%" }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ marginRight: "10px", ...yellowButton }}
            onClick={onSaveEdit}
          >
            SAVE
          </Button>
          <Button
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={handleClose}
          >
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
