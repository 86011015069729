import React from "react";

import { Grid, Typography } from "@mui/material";

import { useStyles } from "./styles";

export default function ActionTableHeaderView() {
  const classes = useStyles()();
  return (
    <Grid
      container
      style={{
        width: "100%",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <Grid item xs={3} className={classes.headerBorderedBox}>
        <Typography style={{ fontWeight: "bold", color: "#333" }}>
          Job Step Name:
        </Typography>
      </Grid>
      <Grid item xs={3.75} sm={4.5} className={classes.headerBorderedBox}>
        <Typography style={{ fontWeight: "bold", color: "#333" }}>
          Risks/Additional Risks:
        </Typography>
      </Grid>
      <Grid item xs={5.25} sm={4.5} className={classes.headerBorderedBox}>
        <Typography style={{ fontWeight: "bold", color: "#333" }}>
          Mitigators:
        </Typography>
      </Grid>
    </Grid>
  );
}
