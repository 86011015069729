import * as React from "react";

import { useReactiveVar } from "@apollo/client";

import { viewIncidentFiltersVar } from "../../../../graphql/localVariables/investigation";
import { investigationMutations } from "../../../../graphql/mutations";
import TableMain from "./ReusableTableLayout";
import ReusableTableToolbar from "./ReusableTableToolbar";
import TableBodyTemplate from "./TableBodyTemplate";
import TableHeadTemplate from "./TableHeadTemplate";
import { filterData } from "./searchFilterFunction";

// Props
// hasPagination - boolean
// data - array of objects
// 3 children component
//    If using the ReusableTableToolbar
//    - title - string - displayed at the top
//    - onDeleteClick - when trash can on toolbar is clicked
//    - onSearchChange - when input in search bar
//    - searchTerm - string
export default function Table({ data }) {
  const { updateAllViewIncidentFilters } = investigationMutations;
  const filters = useReactiveVar(viewIncidentFiltersVar);

  const clearFilters = () => {
    updateAllViewIncidentFilters({
      site: { owner: { name: "" } },
      afterDate: null,
      beforeDate: null,
      username: { username: "" },
      type: "",
    });
  };

  const [filteredData, setFilteredData] = React.useState(data);

  // useEffect for filter update
  React.useEffect(() => {
    setFilteredData(
      filterData(
        data,
        filters.searchterm ? filters.searchterm : "",
        filters.site ? filters.site : { owner: { name: "" } },
        filters.afterDate ? filters.afterDate : null,
        filters.beforeDate ? filters.beforeDate : null,
        filters.username ? filters.username : { username: "" },
        filters.type ? filters.type : ""
      )
    );
  }, [
    filters.searchterm,
    filters.site,
    filters.afterDate,
    filters.beforeDate,
    filters.username,
    filters.type,
    data,
  ]);

  return (
    <TableMain
      CustomTableHeader={<TableHeadTemplate />}
      CustomTableBody={<TableBodyTemplate />}
      CustomTableToolBar={
        <ReusableTableToolbar
          clearFilters={clearFilters}
          data={data}
          title={"Incident Investigations"}
        />
      }
      hasPagination={true}
      data={filteredData}
    />
  );
}
