import * as React from "react";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import ReusableTableHeadCell from "./ReusableTableHeadCell";

// Props
// order, orderBy, numSelected, onRequestSort, onSelectAllClick, rowCount - from ReusableMainTable
export default function TableHeadTemplate(props) {
  const { order, orderBy, onRequestSort } = props;

  // if filtering is available
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <ReusableTableHeadCell
              order={order}
              orderBy={orderBy}
              headCell={headCell}
              createSortHandler={createSortHandler}
              key={headCell.uid}
            />
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const headCells = [
  {
    uid: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    uid: "timeCreated",
    numeric: false,
    disablePadding: false,
    label: "Date Created",
  },
  {
    uid: "lastUpdated",
    numeric: false,
    disablePadding: false,
    label: "Last Updated",
  },
  {
    uid: "editor",
    numeric: false,
    disablePadding: false,
    label: "Last Editor",
  },
  {
    uid: "revision",
    numeric: false,
    disablePadding: false,
    label: "Revision #",
  },
  {
    uid: "associatedTo",
    numeric: false,
    disablePadding: false,
    label: "Associated To",
  },
  {
    uid: "active",
    numeric: false,
    disablePadding: false,
    label: "Active",
  },
  {
    uid: "",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];
