import { gql } from "@apollo/client";

export const GET_COMPANY_ARMS_ADMIN = gql`
  query companyArmAdmin($companyId: Int, $rNull: Boolean, $mNull: Boolean) {
    companyArms(
      company_Id: $companyId
      arm_M_Isnull: $mNull
      arm_R_Isnull: $rNull
    ) {
      isActive
      arm {
        isActive
        riskScore
        id
        m {
          id
          name
          isActive
        }
        a {
          id
          name
          isActive
        }
        r {
          id
          name
          isActive
        }
      }
    }
  }
`;

export const GET_COMPANY_ARMS_PAGINATED_ADMIN = gql`
  query companyArmPaginated(
    $companyId: Int
    $first: Int
    $last: Int
    $fName: String
  ) {
    companyArmsPaginated(
      first: $first
      last: $last
      isApproved: false
      isActive: true
      fName: $fName
      company_Id: $companyId
      arm_M_Isnull: false
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          carmId
          isApproved
          isActive
          user {
            id
            username
          }
          company {
            id
            name
          }
          arm {
            id
            a {
              id
              name
            }
            r {
              id
              name
            }
            m {
              id
              name
            }
          }
        }
        cursor
      }
    }
  }
`;

export const GET_RISKS_BY_COMPANY_ACTION_ADMIN = gql`
  query risksByCompanyActionAdmin($companyId: Int, $actionId: Int) {
    companyArms(
      company_Id: $companyId
      arm_A_Id: $actionId
      isActive: true
      arm_M_Isnull: true
      arm_R_Isnull: false
      arm_R_IsActive: true
    ) {
      riskLevelRange
      arm {
        isActive
        isExpected
        riskScore
        id
        m {
          id
          name
          isActive
        }
        a {
          id
          name
          isActive
        }
        r {
          id
          name
          isActive
        }
      }
    }
  }
`;

export const GET_MIT_BY_COMPANY_RISK_ADMIN = gql`
  query mitigatorsByCompanyRiskAdmin(
    $companyId: Int
    $actionId: Int
    $riskId: Int
  ) {
    companyArms(
      company_Id: $companyId
      arm_A_Id: $actionId
      arm_R_Id: $riskId
      isActive: true
      arm_M_Isnull: false
      arm_R_Isnull: false
      arm_R_IsActive: true
      arm_M_IsActive: true
    ) {
      riskLevelRange
      arm {
        isActive
        riskScore
        id
        m {
          id
          name
          isActive
        }
        a {
          id
          name
          isActive
        }
        r {
          id
          name
          isActive
        }
      }
    }
  }
`;

export const GET_UNSCORED_ARMS = gql`
  query unscoredARMs {
    actions: actionRiskMitigators(
      m_Isnull: true
      r_Isnull: true
      riskScore_Isnull: true
      isActive: true
    ) {
      isExpected
      riskScore
      riskLevelRange
      isActive
      id
      m {
        id
        name
        isActive
      }
      a {
        id
        name
        isActive
      }
      r {
        id
        name
        isActive
      }
    }

    risks: actionRiskMitigators(
      m_Isnull: true
      r_Isnull: false
      riskScore_Isnull: true
      isActive: true
    ) {
      isExpected
      riskScore
      riskLevelRange
      isActive
      id
      m {
        id
        name
        isActive
      }
      a {
        id
        name
        isActive
      }
      r {
        id
        name
        isActive
      }
    }

    mitigators: actionRiskMitigators(
      m_Isnull: false
      r_Isnull: false
      riskScore_Isnull: true
      isActive: true
    ) {
      isExpected
      riskScore
      riskLevelRange
      isActive
      id
      m {
        id
        name
        isActive
      }
      a {
        id
        name
        isActive
      }
      r {
        id
        name
        isActive
      }
    }
  }
`;

export const GET_CATEGORIES = gql`
  query getCategories {
    categories(manage: true) {
      id
      isActive
      name
    }
  }
`;

export const GET_CATEGORY_ACTIONS = gql`
  query categoryActions($categoryId: Int) {
    categoryActions(
      category_Id: $categoryId
      action_IsActive: true
      isActive: true
    ) {
      id
      action {
        id
        name
      }
    }
  }
`;

export const GET_ACTIVE_ACTIONS_ADMIN = gql`
  query activeActions {
    actionRiskMitigators(
      r_Isnull: true
      m_Isnull: true
      a_IsActive: true
      isActive: true
    ) {
      isActive
      id
      a {
        id
        name
        isActive
      }
    }
  }
`;

export const GET_ACTIVE_ARMS_ADMIN = gql`
  query activeARMs {
    risks: actionRiskMitigators(
      r_Isnull: false
      m_Isnull: true
      r_IsActive: true
      isActive: true
    ) {
      isActive
      id
      m {
        id
        name
        isActive
      }
      a {
        id
        name
        isActive
      }
      r {
        id
        name
        isActive
      }
    }

    mitigators: actionRiskMitigators(
      m_Isnull: false
      m_IsActive: true
      isActive: true
    ) {
      isActive
      id
      riskScore
      riskLevelRange
      m {
        id
        name
        isActive
      }
      a {
        id
        name
        isActive
      }
      r {
        id
        name
        isActive
      }
    }
  }
`;

export const GET_COMPANY_ARMS = gql`
  query companyArms(
    $company_Id: Int!
    $isApproved: Boolean!
    $isActive: Boolean!
  ) {
    companyArms(
      manage: true
      company_Id: $company_Id
      isApproved: $isApproved
      isActive: $isActive
      arm_IsActive: true
    ) {
      id
      isApproved
      isActive
      user {
        id
        username
      }
      company {
        id
        name
      }
      arm {
        id
        a {
          id
          name
        }
        r {
          id
          name
        }
        m {
          id
          name
        }
      }
    }
  }
`;

export const GET_APPROVALS_MAC = gql`
  query approvalsMAC {
    companyArms(isApproved: false, isActive: true) {
      id
      isApproved
      isActive
      user {
        id
        username
      }
      company {
        id
        name
      }
      arm {
        id
        a {
          id
          name
        }
        r {
          id
          name
        }
        m {
          id
          name
        }
      }
    }
  }
`;

export const GET_GLOBAL_RISKS = gql`
  query getGlobalRisks {
    risks {
      id
      isActive
      name
    }
  }
`;

export const GET_GLOBAL_MIT = gql`
  query getGlobalMits {
    mitigators {
      id
      isActive
      name
    }
  }
`;

export const ARM_SHARE_TASK = gql`
  query armShareTask($taskId: String) {
    armShareTask(taskId: $taskId) {
      status
      result
      taskArgs
      taskKwargs
      dateCreated
      dateDone
    }
  }
`;

export const MISSING_SHARED_ARMS = gql`
  query missingSharedArms($categoryId: Int) {
    missingSharedArms(categoryId: $categoryId) {
      id
      a {
        id
        name
      }
      r {
        id
        name
      }
      m {
        id
        name
      }
      companyArm {
        company {
          name
        }
      }
    }
  }
`;
