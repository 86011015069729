import React from "react";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { CustomDialog } from "../../../../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../../../../CustomStyles/pageTitle";
import CrewMemberVirtualizedTable from "./CrewmemberTable";

const headCells = [
  {
    uid: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    uid: "phoneNumber",
    numeric: true,
    disablePadding: false,
    label: "Phone Number",
  },
  {
    uid: "jobTitle",
    numeric: true,
    disablePadding: false,
    label: "Job Title",
  },
  {
    uid: "craftType",
    numeric: true,
    disablePadding: false,
    label: "Craft Type",
  },
  {
    uid: "button",
    numeric: true,
    disablePadding: false,
    label: "",
  },
];

export default function ViewCrewmembers({
  open,
  setOpen,
  title,
  data,
  company,
}) {
  const onDone = () => {
    setOpen(false);
  };

  return (
    <>
      <CustomDialog
        open={open}
        fullWidth
        maxWidth="md"
        sx={{ color: "#333" }}
        onClose={() => setOpen(false)}
      >
        <DialogTitle style={{ ...pageTitleStyles }}>
          VIEW {title.toUpperCase()}.
        </DialogTitle>
        <DialogContent>
          <CrewMemberVirtualizedTable
            data={data}
            columns={headCells}
            company={company}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={onDone}
          >
            DONE
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
}
