import React from "react";
import { useNavigate } from "react-router-dom";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpIcon from "@mui/icons-material/Help";
import {
  List,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useTheme } from "@mui/styles";

import Company from "./Company";
import Planning from "./Planning";

export default function AdminItems({
  iconSelectedColor,
  textSelectedColor,
  endBarSelectedColor,
  classes,
  permissions,
  keyHook,
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const expandIconColor = open ? endBarSelectedColor : theme.palette.grey1.main;

  let userItem;
  if (
    permissions.includes("EDIT_USER") ||
    permissions.includes("CREATE_USER")
  ) {
    userItem = (
      <ListItemButton onClick={() => navigate("/admin/user")}>
        <ListItemText primary="Users" className={classes.listText} />
      </ListItemButton>
    );
  }

  let carmItem;
  if (permissions.includes("VIEW_CARM_ADMINISTRATION")) {
    carmItem = (
      <ListItemButton onClick={() => navigate("/admin/arm")}>
        <ListItemText primary="ARM" className={classes.listText} />
      </ListItemButton>
    );
  }

  let planningItem;
  if (permissions.includes("VIEW_PLANNING_ADMINISTRATION")) {
    planningItem = (
      <ListItemButton sx={{ padding: 0 }}>
        <Planning
          iconSelectedColor={iconSelectedColor}
          textSelectedColor={textSelectedColor}
          endBarSelectedColor={endBarSelectedColor}
          classes={classes}
          permissions={permissions}
        />
      </ListItemButton>
    );
  }

  let companyItem;
  if (permissions.includes("VIEW_COMPANY_ADMINISTRATION")) {
    companyItem = (
      <ListItemButton sx={{ padding: 0 }}>
        <Company
          iconSelectedColor={iconSelectedColor}
          textSelectedColor={textSelectedColor}
          endBarSelectedColor={endBarSelectedColor}
          classes={classes}
        />
      </ListItemButton>
    );
  }

  let permissionItem;
  if (permissions.includes("VIEW_PERMISSION_ADMINISTRATION")) {
    permissionItem = (
      <ListItemButton onClick={() => navigate("/admin/permission")}>
        <ListItemText primary="Permissions" className={classes.listText} />
      </ListItemButton>
    );
  }

  let reportsItem;
  if (permissions.includes("SUPERVISION")) {
    reportsItem = (
      <ListItemButton onClick={() => navigate("/admin/supervisors")}>
        <ListItemText primary="Supervisors" className={classes.listText} />
      </ListItemButton>
    );
  }

  let auditsItem;
  if (permissions.includes("AUDIT_MANAGE")) {
    auditsItem = (
      <ListItemButton onClick={() => navigate("/admin/audits")}>
        <ListItemText primary="Audit Management" className={classes.listText} />
      </ListItemButton>
    );
  }

  return (
    <Accordion disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: expandIconColor }} />}
        aria-controls="admin-content"
        id="admin-header"
        style={{
          backgroundColor: theme.palette.black0.main,
          color: theme.palette.grey1.main,
        }}
        onClick={handleClick}
      >
        <HelpIcon
          className={classes.listIcons}
          style={{
            color: open || keyHook === "admin" ? iconSelectedColor : undefined,
          }}
        />
        <Typography
          className={classes.listText}
          style={{
            color: open || keyHook === "admin" ? textSelectedColor : undefined,
          }}
        >
          Administration
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{
          backgroundColor: theme.palette.black0.main,
        }}
      >
        <List>
          {userItem}
          {reportsItem}
          {permissionItem}
          {carmItem}
          {planningItem}
          {companyItem}
          {auditsItem}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}
