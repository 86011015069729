import { gql } from "@apollo/client";

export function updateCreateAudit(createAuditVar) {
  return (key, value) => {
    const copy = { ...createAuditVar() };
    copy[key] = value;

    createAuditVar(copy);
  };
}

export function updateEditAudit(editAuditVar) {
  return (key, value) => {
    const copy = { ...editAuditVar() };
    copy[key] = value;
    editAuditVar(copy);
  };
}

export function updateCreateAuditImages(createAuditImagesVar) {
  return (value) => {
    createAuditImagesVar(value);
  };
}

export function updateEditAuditImages(editAuditImagesVar) {
  return (value) => {
    editAuditImagesVar(value);
  };
}

export function updateEditResponsesAudit(editAuditVar) {
  return (value, id, key) => {
    const copy = { ...editAuditVar() };
    const found = copy.auditResponses.find((res) => {
      return res.auditTopicQuestion === id;
    });
    if (found) {
      found[key] = value;
    } else if (key !== "auditTopicQuestion") {
      copy.auditResponses = [
        ...copy.auditResponses,
        { auditTopicQuestion: id, [key]: value },
      ];
    }
    editAuditVar(copy);
  };
}
export function updateCreateResponsesAudit(createAuditVar) {
  return (value, id, key) => {
    const copy = { ...createAuditVar() };
    const found = copy.auditResponses.find((res) => {
      return res.auditTopicQuestion === id;
    });
    if (found) {
      found[key] = value;
    } else if (key !== "auditTopicQuestion") {
      copy.auditResponses = [
        ...copy.auditResponses,
        { auditTopicQuestion: id, [key]: value },
      ];
    }
    createAuditVar(copy);
  };
}

export function updateAllCreateAudit(createAuditVar) {
  return (value) => {
    createAuditVar(value);
  };
}

export function updateAllEditAudit(editAuditVar) {
  return (value) => {
    editAuditVar(value);
  };
}

export function createUpdateKeyViewAuditFilter(viewAuditFilterVar) {
  return (key, value) => {
    const obsCopy = { ...viewAuditFilterVar() };
    obsCopy[key] = value;

    viewAuditFilterVar(obsCopy);
  };
}

export function createUpdateAllViewAuditFilter(viewAuditFilterVar) {
  return (value) => {
    viewAuditFilterVar(value);
  };
}

export const AUDIT_EDIT = gql`
  mutation auditEdit($id: ID!, $input: UpdateAuditSubmissionInput!) {
    auditEdit(id: $id, input: $input) {
      auditSubmission {
        id
      }
      message {
        success
        errors
        info
      }
    }
  }
`;

export const AUDIT_CREATE = gql`
  mutation auditCreate($input: CreateAuditSubmissionInput!) {
    auditCreate(input: $input) {
      auditSubmission {
        id
      }
      message {
        success
        errors
        info
      }
    }
  }
`;

// TODO - FIX
export const UPLOAD_MUTATION = gql`
  mutation ($file: Upload!) {
    uploadFile(file: $file) {
      success
    }
  }
`;

export const EDIT_AUDIT = gql`
  mutation auditEdit($id: ID!, $input: UpdateAuditSubmissionInput!) {
    auditEdit(id: $id, input: $input) {
      message {
        success
        errors
        info
      }
    }
  }
`;

export const EMAIL_AUDIT = gql`
  mutation emailAudit($id: ID, $file: Upload!) {
    auditEmail(id: $id, file: $file) {
      message {
        success
        errors
        info
      }
    }
  }
`;

export const UPLOAD_AUDIT_CSV = gql`
  mutation auditUpload($file: Upload!) {
    uploadAuditMutation(file: $file) {
      message {
        success
        errors
        info
      }
    }
  }
`;

export const EDIT_AUDIT_CSV = gql`
  mutation auditUpload($file: Upload!, $edit: Boolean, $id: ID!) {
    uploadAuditMutation(file: $file, edit: $edit, id: $id) {
      message {
        success
        errors
        info
      }
    }
  }
`;
