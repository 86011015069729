import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";

import { useMutation } from "@apollo/client";

import { UPDATE_COMPANY } from "../../../graphql/mutations/admin/company";
import { GET_COMPANY } from "../../../graphql/queries/admin/company";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import { CustomSwitch } from "../../CustomComponents/Switch";
import { yellowButton } from "../../CustomStyles/buttons";

export default function DeactivateModal({ company, permissions }) {
  const [updateIsActive] = useMutation(UPDATE_COMPANY, {
    onCompleted() {
      onCompletedFunc("Company status has been updated");
    },
    onError() {
      onErrorFunc("There was an error. Please try again.");
    },
    refetchQueries: [
      { query: GET_COMPANY, variables: { id: Number(company.id) } },
    ],
  });
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  const onSave = () => {
    if (company) {
      const input = {
        id: Number(company.id),
        isActive: !company.isActive,
      };
      updateIsActive({
        variables: { ...input },
      });
    }

    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid style={{ marginLeft: "10px" }}>
      <Tooltip title="Activate/Deactivate Company">
        <FormControlLabel
          style={{ color: "white" }}
          control={
            <CustomSwitch
              checked={company ? company.isActive : false}
              onChange={() => setOpen(true)}
              value={company.isActive}
              disabled={!permissions.includes("EDIT_COMPANY")}
              light
            />
          }
          label={company && company.isActive ? "ACTIVE" : "INACTIVE"}
        />
      </Tooltip>
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"}>
        <DialogContent
          style={{ backgroundColor: theme.palette.black1.main, color: "white" }}
        >
          <Typography>
            Are you sure you want to{" "}
            {company?.isActive ? "deactivate" : "activate"} this company?
          </Typography>
        </DialogContent>
        <DialogActions style={{ backgroundColor: theme.palette.black1.main }}>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Button
              variant="contained"
              onClick={onSave}
              style={{ ...yellowButton, marginRight: "10px" }}
            >
              YES
            </Button>
            <Button variant="contained" onClick={handleClose}>
              NO
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
