import React from "react";
import { TextField } from "@mui/material";
import { CustomTextField } from "../CustomStyles/LightTextField";

export default function PasswordField({ label, password, setPassword, white }) {
  if (white) {
    return (
      <CustomTextField
        variant="standard"
        label={label}
        type="password"
        value={password}
        onChange={(event) => setPassword(event.target.value)}
      />
    );
  }
  return (
    <TextField
      variant="standard"
      label={label}
      type="password"
      value={password}
      onChange={(event) => setPassword(event.target.value)}
    />
  );
}
