export const filterData = (arr, searchTerm, groupsFilter, inactive) => {
  let filteredArr = searchFilter(arr, searchTerm);
  if (groupsFilter.length > 0) {
    filteredArr = groupFilter(arr, groupsFilter);
  }
  if (!inactive) {
    filteredArr = filteredArr.filter((arr) => arr.isActive);
  }
  return filteredArr;
};

// searchTerm - string
// arr - array of objects
const searchFilter = (arr, searchTerm) => {
  searchTerm = String(searchTerm).toLowerCase();
  return arr.filter(
    (o) =>
      `${o.firstName} ${o.lastName}`.toLowerCase().includes(searchTerm) ||
      o.username.toLowerCase().includes(searchTerm)
  );
};

const groupFilter = (arr, groups) => {
  const gIds = groups.map((g) => g.id);
  return arr.filter(
    (a) =>
      a.userPermissionGroups.findIndex((upg) =>
        gIds.includes(upg.permissionGroup.id)
      ) >= 0
  );
};
