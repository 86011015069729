import axios from "axios";

import { authMutations } from "../../graphql/mutations";

export const fourFunc = () => {
  const { updateAuth } = authMutations;
  axios
    .post("/auth/jwt/refresh/")
    .then(() => {
      updateAuth(true);
    })
    .catch(() => {
      updateAuth(false);
    });
};
