import { gql } from "@apollo/client";

export function updateCreateHA(createHAVar) {
  return (key, value) => {
    const copy = { ...createHAVar() };
    copy[key] = value;

    createHAVar(copy);
  };
}

export function updateEditHA(editHAVar) {
  return (key, value) => {
    const copy = { ...editHAVar() };
    copy[key] = value;

    editHAVar(copy);
  };
}

export function updateAllCreateHA(createHAVar) {
  return (value) => {
    createHAVar(value);
  };
}

export function updateAllEditHA(editHAVar) {
  return (value) => {
    editHAVar(value);
  };
}

export function createUpdateKeyViewHAFilter(viewHAFilterVar) {
  return (key, value) => {
    const obsCopy = { ...viewHAFilterVar() };
    obsCopy[key] = value;

    viewHAFilterVar(obsCopy);
  };
}

export function createUpdateAllViewHAFilter(viewHAFilterVar) {
  return (value) => {
    viewHAFilterVar(value);
  };
}

export const UPDATE_CREWMEMBER = gql`
  mutation UpdateCrewmemberSignature($input: CrewMemberMutationInput!) {
    crewMember(input: $input) {
      crewMember {
        id
      }
      errors {
        messages
      }
    }
  }
`;

/*
input: {   
    name: "Test",
    ownerSiteProject: 1,       // id of ownerSiteProject
    date: "2022-12-22",        // string of format "YYYY-MM-DD"
    timeCompleted: "23:59:59", // string of format "HH:MM:SS"
    submitter: 1,
    comment: "Comment",
    isCompleted: false,
    hasInjury: false,
    injuryText: "",
    raScore: 80,
    raScoreMax: 98,
    raScoreMin: 75,
    arms: [
      {id: 1},                      // id of actionRiskMitigator
      {id: 3, actionOrdering: 1}    // id of actionRiskMitigator, the action with ordering
    ],
    templates: [],
    crewMembers: [],
    files: []
  }
*/
export const CREATE_HA = gql`
  mutation haCreate($input: CreateHazardAnalysisInput!) {
    haCreate(input: $input) {
      hazardAnalysis {
        id
        haId
      }
      message {
        success
        errors
        info
      }
    }
  }
`;

export const EDIT_HA = gql`
  mutation haEdit($id: ID!, $input: UpdateHazardAnalysisInput!) {
    haEdit(id: $id, input: $input) {
      hazardAnalysis {
        id
        haId
      }
      message {
        success
        errors
        info
      }
    }
  }
`;

export const HA_EMAIL = gql`
  mutation emailHA($id: ID, $file: Upload!) {
    haEmail(id: $id, file: $file) {
      message {
        success
        errors
        info
      }
    }
  }
`;
