import React from "react";
import { Virtuoso } from "react-virtuoso";

import { CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

import CreateDialog from "./Create/CreateDialog";
import EditDialog from "./Edit/EditDialog";
import ViewDialog from "./View/ViewDialog";
import useStyles from "./styles";

function CardContent({ title, data, company, loading, extraData }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const getRow = (d) => {
    const getItem = () => {
      if (title === "Owned Sites") {
        return (
          <EditDialog
            key={`${d.id}-ownedsites`}
            title={title}
            company={company}
            id={d.id}
            d={d}
            extraData={extraData}
          />
        );
      }
      if (title === "Observed Sites") {
        return (
          <EditDialog
            key={`${d.id}-observedsite`}
            title={title}
            company={company}
            id={d.id}
            d={d}
          />
        );
      }
      if (title === "Projects") {
        return (
          <EditDialog
            key={`${d.id}-projects`}
            title={title}
            company={company}
            id={d.id}
            d={d}
          />
        );
      }
      if (title === "Templates") {
        return (
          <EditDialog
            key={`${d.id}-templates`}
            title={title}
            company={company}
            id={d.id}
            d={d}
          />
        );
      }
      if (title === "Contractors") {
        return (
          <EditDialog
            key={`${d.id}-contractor`}
            title={title}
            company={company}
            id={d.id}
            d={d}
            extraData={extraData}
          />
        );
      }
      if (title === "CrewMembers") {
        return (
          <EditDialog
            key={`${d.id}-crewmembers`}
            title={title}
            company={company}
            id={d.id}
            d={d}
          />
        );
      }
      if (title === "Users") {
        return (
          <EditDialog
            key={`${d.id}-users`}
            title={title}
            company={company}
            id={d.id}
            d={d}
          />
        );
      }
      if (title === "Supervisors") {
        return (
          <EditDialog
            key={`${d.id}-supervisor`}
            title={title}
            company={company}
            id={d.id}
            d={d}
          />
        );
      }
      return null;
    };

    return <div>{getItem()}</div>;
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.contentRoot}
      style={{ paddingBottom: "40px" }}
    >
      <Grid sx={{ padding: "10px", width: "100%" }}>
        <CreateDialog
          title={title}
          data={data}
          company={company}
          extraData={extraData}
        />
        <ViewDialog
          title={title}
          data={data}
          company={company}
          open={open}
          setOpen={setOpen}
          extraData={extraData}
        />
        <Grid item xs={12} style={{ width: "100%" }}>
          <Typography
            className={classes.cardTitle}
            sx={{
              fontFamily: "barlowextrabold",
            }}
          >
            {title}
          </Typography>
        </Grid>
        {loading ? (
          <Grid
            container
            sx={{ height: 400 }}
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <Virtuoso
            style={{ height: 400 }}
            data={data}
            itemContent={(index, d) => getRow(d)}
          />
        )}
      </Grid>
      <div className={classes.overlay} onClick={() => setOpen(true)}>
        <Typography className={classes.text}>View All</Typography>
      </div>
    </Grid>
  );
}

export default function CarouselCard({
  title,
  onClick,
  data,
  company,
  loading,
  extraData,
}) {
  const theme = useTheme();

  return (
    <Paper
      children={
        <CardContent
          title={title}
          onClick={onClick}
          data={data}
          company={company}
          loading={loading}
          extraData={extraData}
        />
      }
      elevation={5}
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "inherit",
        borderRadius: "10px",
        boxShadow: `10px -10px 30px ${theme.palette.grey3.main}`,
      }}
    />
  );
}
