import * as React from "react";

import TableMain from "./ReusableTableLayout";
import ReusableTableToolbar from "./ReusableTableToolbar";
import TableBodyTemplate from "./TableBodyTemplate";
import TableHeadTemplate from "./TableHeadTemplate";
import { filterData } from "./searchFilterFunction";

// Props
// hasPagination - boolean
// data - array of objects
// 3 children component
//    If using the ReusableTableToolbar
//    - title - string - displayed at the top
//    - onDeleteClick - when trash can on toolbar is clicked
//    - onSearchChange - when input in search bar
//    - searchTerm - string
export default function Table({ data }) {
  const onSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const onSiteChange = (event, newValue) => {
    setSiteFilter(newValue || { owner: { name: "" } });
  };

  const onContractorChange = (event, newValue) => {
    setContractorFilter(newValue || { contractor: { name: "" } });
  };

  const onAfterDateChange = (event) => {
    setAfterDate(event);
  };

  const onBeforeDateChange = (event) => {
    setBeforeDate(event);
  };

  const onOutcomeChange = (event, newValue) => {
    setOutcomeFilter(newValue || []);
  };

  const onStatusChange = (event, newValue) => {
    setPlannedStatusFilter(newValue || "");
  };

  const clearFilters = () => {
    setSiteFilter({ owner: { name: "" } });
    setContractorFilter({ contractor: { name: "" } });
    setAfterDate(null);
    setBeforeDate(null);
    setOutcomeFilter([]);
    setPlannedStatusFilter("");
  };

  const [searchTerm, setSearchTerm] = React.useState("");
  const [siteFilter, setSiteFilter] = React.useState({ owner: { name: "" } });
  const [contractorFilter, setContractorFilter] = React.useState({
    contractor: { name: "" },
  });
  const [afterDate, setAfterDate] = React.useState(null);
  const [beforeDate, setBeforeDate] = React.useState(null);
  const [outcomeFilter, setOutcomeFilter] = React.useState([]);
  const [plannedStatusFilter, setPlannedStatusFilter] = React.useState("");

  const [filteredData, setFilteredData] = React.useState(data);

  // useEffect for filter update
  React.useEffect(() => {
    setFilteredData(
      filterData(
        data,
        searchTerm,
        siteFilter,
        afterDate,
        beforeDate,
        outcomeFilter,
        plannedStatusFilter,
        contractorFilter
      )
    );
  }, [
    searchTerm,
    siteFilter,
    afterDate,
    beforeDate,
    outcomeFilter,
    plannedStatusFilter,
    contractorFilter,
  ]);

  return (
    <>
      <TableMain
        CustomTableHeader={<TableHeadTemplate />}
        CustomTableBody={<TableBodyTemplate />}
        CustomTableToolBar={
          <ReusableTableToolbar
            searchTerm={searchTerm}
            onSearchChange={onSearchChange}
            site={siteFilter}
            onSiteChange={onSiteChange}
            contractor={contractorFilter}
            onContractorChange={onContractorChange}
            onAfterDateChange={onAfterDateChange}
            afterDate={afterDate}
            onBeforeDateChange={onBeforeDateChange}
            beforeDate={beforeDate}
            onOutcomeChange={onOutcomeChange}
            outcomes={outcomeFilter}
            onStatusChange={onStatusChange}
            status={plannedStatusFilter}
            clearFilters={clearFilters}
            title={"Observations"}
          />
        }
        hasPagination={true}
        data={filteredData}
        loading={false}
      />
    </>
  );
}
