import { InMemoryCache } from "@apollo/client";
import { persistCache, LocalStorageWrapper } from "apollo3-cache-persist";

import { cacheFields } from "./localVariables/cacheFields";
import {
  CompanyTypeFunction,
  SiteTypeFunction,
  HazardAnalysisTypeFunction,
  OwnerSiteProjectTypeFunction,
} from "./typeFunctions";

// broke this is into separate files so it would not be over whelming
export const cache = new InMemoryCache({
  typePolicies: {
    // All local variables
    Query: cacheFields,
    // These are variables added to modal types stored in cache (ex isContractor on Company, location on site )
    CompanyType: CompanyTypeFunction(),
    SiteType: SiteTypeFunction(),
    HazardAnalysisType: HazardAnalysisTypeFunction(),
    OwnerSiteProjectType: OwnerSiteProjectTypeFunction(),
  },
});

export const executePersistCache = async () => {
  await persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
  });
};
