import React from "react";

import { TextField } from "@mui/material";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";

import { useReactiveVar } from "@apollo/client";

import {
  createIncidentVar,
  editIncidentVar,
} from "../../../../graphql/localVariables/investigation";
import { investigationMutations } from "../../../../graphql/mutations";

export default function TimeInputView({ type }) {
  // Global Variables
  const edit = useReactiveVar(editIncidentVar);
  const create = useReactiveVar(createIncidentVar);
  const form = type === "EDIT" ? edit : create;

  // Mutations
  const { updateKeyEditIncident, updateKeyCreateIncident } =
    investigationMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditIncident : updateKeyCreateIncident;

  const handleChange = (time) => {
    updateObsKeyFunction("time", time);
  };

  return (
    <MobileTimePicker
      label="Time:"
      inputFormat="hh:mm A"
      value={form.time}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          style={{
            width: "100%",
          }}
          variant="standard"
        />
      )}
    />
  );
}
