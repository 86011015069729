import * as React from "react";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import ReusableTableHeadCell from "./ReusableTableHeadCell";

// Props
// order, orderBy, numSelected, onRequestSort, onSelectAllClick, rowCount - from ReusableMainTable
export default function TableHeadTemplate(props) {
  const { order, orderBy, onRequestSort } = props;

  // if filtering is available
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <ReusableTableHeadCell
              order={order}
              orderBy={orderBy}
              headCell={headCell}
              createSortHandler={createSortHandler}
              key={headCell.uid}
            />
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const headCells = [
  {
    uid: "name",
    numeric: false,
    disablePadding: false,
    label: "HA Name",
    sort: true,
  },
  {
    uid: "date",
    numeric: true,
    disablePadding: false,
    label: "Date",
    sort: true,
  },
  {
    uid: "Submitter Username",
    numeric: true,
    disablePadding: false,
    label: "Submitter Username",
    sort: true,
  },
  {
    uid: "company",
    numeric: true,
    disablePadding: false,
    label: "Company",
    sort: true,
  },
  {
    uid: "Location",
    numeric: true,
    disablePadding: false,
    label: "Location",
    sort: false,
  },
  {
    uid: "projects",
    numeric: true,
    disablePadding: false,
    label: "Project(s)",
    sort: false,
  },
  {
    uid: "templates",
    numeric: true,
    disablePadding: false,
    label: "Template(s)",
    sort: false,
  },
  {
    uid: "status",
    numeric: true,
    disablePadding: false,
    label: "HA Status",
    sort: true,
  },
  {
    uid: "revisionNumber",
    numeric: true,
    disablePadding: false,
    label: "Revisions",
    sort: true,
  },
  {
    uid: "incident",
    numeric: true,
    disablePadding: false,
    label: "With or Without Incident",
    sort: true,
  },
  {
    uid: "crewmembers",
    numeric: true,
    disablePadding: false,
    label: "# of Crew Members",
    sort: true,
  },
  {
    uid: "raScore",
    numeric: true,
    disablePadding: false,
    label: "Risk Score",
    sort: true,
  },
];
