import { Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    padding: theme.spacing(2),
    backgroundColor: "#333",
    color: "#fff",
  },
}));
