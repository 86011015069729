/* istanbul ignore file */
const httpProtocol =
  process.env.REACT_APP_USE_SSL === "false" ? "http" : "https";
const wsProtocol = process.env.REACT_APP_USE_SSL === "false" ? "ws" : "wss";

const host =
  window.location.host === "localhost:3000"
    ? "localhost:8000"
    : window.location.host;

export const apiUrl = `${httpProtocol}://${host}/api/`;
export const wsUrl = `${wsProtocol}://${host}/ws/`;

export const environment =
  window.location.host === "localhost:3000"
    ? "dev"
    : window.location.host === "qa.nixn.app"
    ? "qa"
    : "production";
