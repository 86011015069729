import React from "react";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";

import { CustomTextField } from "../../../../../../CustomStyles/LightTextField";
import { CustomDialog } from "../../../../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../../../../CustomStyles/pageTitle";

export default function ViewObservedSite({ open, setOpen, data }) {
  const onDone = () => {
    setOpen(false);
  };

  return (
    <>
      <CustomDialog
        open={open}
        fullWidth
        maxWidth="md"
        sx={{ color: "#333" }}
        onClose={() => setOpen(false)}
      >
        <DialogTitle style={{ ...pageTitleStyles }}>
          OBSERVED SITE:{" "}
          <span style={{ color: "#FFB700" }}>
            {" "}
            {data.ownerSiteProject.site?.name}
          </span>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ marginBottom: "20px" }}>
            <Grid item xs={12} md={6}>
              <CustomTextField
                variant="standard"
                style={{ width: "100%" }}
                value={data.ownerSiteProject.owner.name}
                label={"Site Owner:"}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTextField
                label="Site Name:"
                variant="standard"
                style={{ width: "100%" }}
                value={data.ownerSiteProject.site.name}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTextField
                label="Address Line 1:"
                variant="standard"
                style={{ width: "100%" }}
                value={data.ownerSiteProject.site.addrLine1}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTextField
                label="Address Line 2:"
                variant="standard"
                style={{ width: "100%" }}
                value={data.ownerSiteProject.site.addrLine2}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTextField
                label="City:"
                variant="standard"
                style={{ width: "100%" }}
                value={data.ownerSiteProject.site.addrCity}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTextField
                label="State:"
                variant="standard"
                style={{ width: "100%" }}
                value={data.ownerSiteProject.site.addrState}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTextField
                label="Zipcode:"
                variant="standard"
                style={{ width: "100%" }}
                value={data.ownerSiteProject.site.addrZip}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTextField
                label="Note:"
                variant="standard"
                style={{ width: "100%" }}
                value={data.ownerSiteProject.site.note}
                disabled
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={onDone}
          >
            DONE
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
}
