import { gql } from "@apollo/client";

export const GET_SITES_ADMIN = gql`
  query sitesAdmin {
    ownerSiteProjects(
      site_Isnull: false
      project_Isnull: true
      owner_IsActive: true
      isActive: true
      manage: true
    ) {
      id
      owner {
        id
        name
      }
      site {
        id
        name
        timeCreated
        isActive
      }
    }
  }
`;

export const GET_SITE_INFO = gql`
  query site($siteId: Int) {
    site(id: $siteId) {
      id
      timeCreated
      isActive
      name
      addrLine1
      addrLine2
      addrCity
      addrState
      addrZip
      location @client
      note
    }

    ownerSiteProjects(
      site_Id: $siteId
      project_Isnull: true
      isActive: true
      isCompanyDashboard: true
    ) {
      id
      location @client
      owner {
        id
        name
      }
      ownerSiteProjectTemplate {
        id
        isActive
        template {
          id
          name
        }
      }
      ownerSiteProjectContractor {
        id
        isActive
        contractor {
          id
          name
        }
      }
      ownerSiteProjectCrewMember {
        id
        isActive
        crewMember {
          id
          firstName
          lastName
          company {
            name
          }
        }
      }
      ownerSiteProjectUser {
        id
        isActive
        user {
          id
          username
          firstName
          lastName
          company {
            name
          }
        }
      }
      supervision {
        id
        isActive
        supervisor {
          id
          username
        }
        supervisee {
          id
          username
        }
      }
    }
  }
`;

export const GET_OWNERS = gql`
  query ownerAdmin {
    ownerSiteProjects(
      isActive: true
      site_Isnull: true
      project_Isnull: true
      owner_IsActive: true
    ) {
      id
      owner {
        id
        name
      }
      site {
        id
        name
      }
      project {
        id
        name
      }
    }
  }
`;

export const GET_OWNER_SITES = gql`
  query ownerAdmin($ownerId: Int) {
    ownerSiteProjects(
      isActive: true
      owner_Id: $ownerId
      site_Isnull: false
      project_Isnull: true
    ) {
      id
      owner {
        id
        name
      }
      site {
        id
        name
      }
    }
  }
`;

export const GET_CREWMEMBERS = gql`
  query adminCrewmembers {
    crewMembers(isActive: true) {
      id
      firstName
      lastName
      company {
        name
      }
    }
  }
`;
