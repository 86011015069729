import React from "react";

import { Typography, FormControl, Button } from "@mui/material";

import { useMutation } from "@apollo/client";

import { authMutations } from "../../graphql/mutations";
import { CHANGE_PASSWORD } from "../../graphql/mutations/userSettings";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../CustomComponents/OnErrorFunction";
import PasswordField from "../CustomComponents/PasswordField";
import useStyles from "./styles";

export default function ChangePassword({ userId }) {
  const { updateSnackbar } = authMutations;
  const [newPassword] = useMutation(CHANGE_PASSWORD, {
    onCompleted() {
      onCompletedFunc("Password changed");
      setOldPassword("");
      setPassword("");
      setPasswordConfirm("");
    },
    onError(error) {
      onErrorFunc(error);
    },
  });
  const classes = useStyles();
  const [oldPassword, setOldPassword] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConfirm, setPasswordConfirm] = React.useState("");

  const changePassword = () => {
    // TODO: make sure password is good format
    if (oldPassword === "") {
      updateSnackbar({
        open: true,
        message: "Please enter your current password.",
        severity: "error",
      });
    } else if (password === "") {
      updateSnackbar({
        open: true,
        message: "Please enter your new password.",
        severity: "error",
      });
    } else if (passwordConfirm === "") {
      updateSnackbar({
        open: true,
        message: "Please confirm your new password.",
        severity: "error",
      });
    } else if (password !== passwordConfirm) {
      updateSnackbar({
        open: true,
        message:
          "Please make sure to enter your password correctly both times.",
        severity: "error",
      });
    } else {
      newPassword({
        variables: {
          userId: Number(userId),
          oldPassword: oldPassword,
          password,
        },
      });
    }
  };

  return (
    <FormControl fullWidth>
      <PasswordField
        label="Old Password:"
        password={oldPassword}
        setPassword={setOldPassword}
      />
      <PasswordField
        label="New Password:"
        password={password}
        setPassword={setPassword}
      />
      <Typography>
        Your password can&apos;t be too similar to your other personal
        information.
      </Typography>
      <Typography>Your password must contain at least 8 characters.</Typography>
      <Typography>
        Your password can&apos;t be a commonly used password.
      </Typography>
      <Typography>Your password can&apos;t be entirely numeric.</Typography>
      <PasswordField
        label="New Password Confirmation:"
        password={passwordConfirm}
        setPassword={setPasswordConfirm}
      />
      <Button
        variant="contained"
        className={classes.blueButton}
        sx={{ mt: 1 }}
        onClick={changePassword}
      >
        SAVE
      </Button>
    </FormControl>
  );
}
