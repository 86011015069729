import React from "react";

import {
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  Autocomplete,
  Typography,
  DialogActions,
  Paper,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import moment from "moment";

import {
  onlineVar,
  permissionsVar,
} from "../../../../../../graphql/localVariables/user";
import {
  UPDATE_SINGLE_CREWMEMBER_GENERAL,
  UPDATE_SINGLE_CREWMEMBER_JOB,
} from "../../../../../../graphql/mutations/crewmembers";
import {
  COMPANY_DASHBOARD,
  CREW_MEMBER_INFO,
  GET_CREWMEMBER_DATA_COMPANY,
} from "../../../../../../graphql/queries/admin/company";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../../../CustomComponents/OnErrorFunction";
import TabPanel from "../../../../../CustomComponents/TabPanels";
import {
  StyledTabs,
  StyledTabWhite,
} from "../../../../../CustomComponents/Tabs";
import {
  CustomDateField,
  CustomTextField,
} from "../../../../../CustomStyles/LightTextField";
import { yellowButton } from "../../../../../CustomStyles/buttons";
import { CustomDialog } from "../../../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../../../CustomStyles/pageTitle";
import EditCrewSites from "./EditCrewSites";
import HATable from "./HATable";
import useStyles from "./styles";

export default function EditCrewDialog({ open, setOpen, d, company }) {
  // Queries
  const permissions = useReactiveVar(permissionsVar);
  const classes = useStyles();
  const theme = useTheme();
  const online = useReactiveVar(onlineVar);

  const [getCompanies, { data: companies, loading: loadingCompanies }] =
    useLazyQuery(COMPANY_DASHBOARD, {
      fetchPolicy: online ? "network-only" : "cache-only",
    });
  const [getCrewData, { data, loading: loadingData }] = useLazyQuery(
    GET_CREWMEMBER_DATA_COMPANY
  );
  const [getInfo, { data: info }] = useLazyQuery(CREW_MEMBER_INFO);

  const [editDisabled, setEditDisabled] = React.useState(false);

  React.useEffect(() => {
    if (!permissions.includes("EDIT_SITE")) {
      setEditDisabled(true);
    }
  }, []);

  React.useEffect(() => {
    if (open) {
      getCompanies();
      getInfo();
      getCrewData({
        variables: { crewId: Number(d.id) },
      });
    }
  }, [open]);

  // General Info Mutation
  const [mutateCrewMemberProfile] = useMutation(
    UPDATE_SINGLE_CREWMEMBER_GENERAL,
    {
      onCompleted() {
        onCompletedFunc("CrewMember has been updated");
        reset();
      },
      onError(error) {
        onErrorFunc(error);
        reset();
      },
    }
  );

  // Job Info Mutation
  const [mutateCrewMemberJob] = useMutation(UPDATE_SINGLE_CREWMEMBER_JOB, {
    onCompleted() {
      onCompletedFunc("CrewMember has been updated");
      reset();
    },
    onError(error) {
      onErrorFunc(error);
      reset();
    },
  });

  // Local State
  // Tab State
  const [value, setValue] = React.useState(0);

  // General Info State
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [contactName, setContactName] = React.useState("");
  const [contactPhone, setContactPhone] = React.useState("");
  const [crewCompany, setCrewCompany] = React.useState({ name: "" });

  // Job Info
  const [hireDate, setHireDate] = React.useState("");
  const [expirationDate, setExpirationDate] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");
  const [craft, setCraft] = React.useState("");
  const [years, setYears] = React.useState("");
  const [other, setOther] = React.useState("");

  const setInitialState = () => {
    if (data) {
      setFirstName(data.crewMember.firstName);
      setLastName(data.crewMember.lastName);
      setPhone(data.crewMember.phoneNumber);
      setEmail(data.crewMember.email);
      setContactName(
        data.crewMember.crewMemberContacts.length !== 0
          ? data.crewMember.crewMemberContacts[0].contact.name
          : " "
      );
      setContactPhone(
        data.crewMember.crewMemberContacts.length !== 0
          ? data.crewMember.crewMemberContacts[0].contact.phoneNumber
          : " "
      );
      setCrewCompany(data.crewMember.company);
      setHireDate(data.crewMember.hireDate);
      setExpirationDate(data.crewMember.drugTestExpiry);
      setJobTitle(data.crewMember.jobTitle);
      setCraft(data.crewMember.craftType);
      setYears(data.crewMember.yrsOfExp);
      setOther(data.crewMember.other);
    }
  };

  React.useEffect(() => {
    setInitialState();
  }, [data]);

  // Reports
  const [openHA, setOpenHA] = React.useState(false);

  // On Done: closes modal and resets
  const onDone = () => {
    setOpen(false);
    // reset();
  };

  // On Save: chooses mutation based on tab index
  const onSave = () => {
    switch (value) {
      case 0:
        mutateCrewMemberProfile({
          variables: {
            id: Number(d.id),
            firstName,
            lastName,
            email,
            contactName,
            contactPhone,
            phoneNumber: phone,
            company: Number(crewCompany.id ? crewCompany.id : company.id),
          },
        });
      case 1:
        mutateCrewMemberJob({
          variables: {
            id: Number(d.id),
            jobTitle: Number(jobTitle.id),
            craftType: Number(craft.id),
            yrsOfExp: Number(years.id),
            hireDate: hireDate ? moment(hireDate).format("YYYY-MM-DD") : null,
            expirationDate: expirationDate
              ? moment(expirationDate).format("YYYY-MM-DD")
              : null,
            other,
            company: Number(company.id),
            phoneNumber: phone,
          },
        });
      default:
        return null;
    }
  };

  // Resets General Info
  const reset = () => {
    setInitialState();
  };

  // Tabs Function: sets index
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderButtons = () => {
    return (
      <Grid sx={{ textAlignLast: "end", paddingTop: "50px" }}>
        <Button
          variant="contained"
          style={{ marginRight: "10px", ...yellowButton }}
          onClick={onSave}
          disabled={editDisabled}
        >
          SAVE
        </Button>
        <Button
          variant="contained"
          style={{ marginRight: "10px" }}
          onClick={onDone}
        >
          DONE
        </Button>
      </Grid>
    );
  };

  return (
    <>
      <CustomDialog
        open={open}
        fullWidth
        maxWidth="md"
        sx={{ color: "#333", minWidth: "900px" }}
        onClose={() => setOpen(false)}
      >
        <DialogTitle style={{ ...pageTitleStyles }}>
          CREW MEMBER:
          <span style={{ color: "#FFB700" }}>
            {" "}
            {d?.firstName !== "" && d?.lastName !== ""
              ? `${d?.firstName} ${d?.lastName}`
              : d?.username}
          </span>
        </DialogTitle>
        <DialogContent>
          {loadingData ? (
            <Grid container justifyContent="center">
              <CircularProgress color="secondary" />
            </Grid>
          ) : (
            <Grid container>
              {/* Tab Panel */}

              <Grid item xs={12}>
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  style={{ color: "white", paddingBottom: "20px" }}
                >
                  <StyledTabWhite label="GENERAL INFO" />
                  <StyledTabWhite label="JOB INFO" />
                  <StyledTabWhite label="SITES" />
                  <StyledTabWhite label="REPORTS" />
                </StyledTabs>

                {/* General Info Tab */}

                <TabPanel value={value} index={0}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <CustomTextField
                        variant="standard"
                        label="First Name:"
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                        style={{ width: "100%" }}
                        disabled={editDisabled}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomTextField
                        variant="standard"
                        label="Last Name:"
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                        style={{ width: "100%" }}
                        disabled={editDisabled}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomDateField
                        defaultCountry={"us"}
                        value={phone}
                        onChange={(val) => setPhone(val)}
                        label="Phone Number:"
                        style={{ width: "100%" }}
                        disabled={editDisabled}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomTextField
                        variant="standard"
                        label="Email:"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        type="email"
                        style={{ width: "100%" }}
                        disabled={editDisabled}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomTextField
                        variant="standard"
                        label="Emergency Contact Name:"
                        value={contactName}
                        onChange={(event) => setContactName(event.target.value)}
                        style={{ width: "100%" }}
                        disabled={editDisabled}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomDateField
                        defaultCountry={"us"}
                        value={contactPhone}
                        onChange={(val) => setContactPhone(val)}
                        label="Emergency Contact Phone Number:"
                        style={{ width: "100%" }}
                        disabled={editDisabled}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        id="select-company"
                        options={
                          companies
                            ? companies.companies.sort((a, b) =>
                                a.name > b.name ? 1 : -1
                              )
                            : []
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        value={crewCompany}
                        onChange={(event, value) => setCrewCompany(value)}
                        getOptionLabel={(option) => option.name}
                        disableClearable
                        disabled={editDisabled}
                        loading={loadingCompanies}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            variant="standard"
                            label={"Company:"}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  {renderButtons()}
                </TabPanel>

                {/* Job Info Tab */}

                <TabPanel value={value} index={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Autocomplete
                        id="select-craft-type"
                        options={info ? info.craftTypes : []}
                        value={craft}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newVal) => setCraft(newVal)}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        disableClearable
                        disabled={editDisabled}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            variant="standard"
                            label="Craft Type:"
                            value={params}
                            id={params.id}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        id="select-job-title"
                        options={info ? info.jobTitles : []}
                        value={jobTitle}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newVal) => setJobTitle(newVal)}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        disableClearable
                        disabled={editDisabled}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            variant="standard"
                            label="Job Title:"
                            value={params}
                            id={params.id}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        id="select-years"
                        options={info ? info.yearsOfExps : []}
                        value={years}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newVal) => setYears(newVal)}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        disableClearable
                        disabled={editDisabled}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            variant="standard"
                            label="Years of Exp:"
                            value={params}
                            id={params.id}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MobileDatePicker
                        label="Hire Date:"
                        inputFormat="MM/DD/YYYY"
                        value={hireDate}
                        onChange={(date) => setHireDate(date)}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            style={{
                              width: "100%",
                            }}
                            variant="standard"
                          />
                        )}
                        disabled={editDisabled}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MobileDatePicker
                        label="Expiration Date:"
                        inputFormat="MM/DD/YYYY"
                        value={expirationDate}
                        onChange={(date) => setExpirationDate(date)}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            style={{
                              width: "100%",
                            }}
                            variant="standard"
                          />
                        )}
                        disabled={editDisabled}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomTextField
                        variant="standard"
                        label="Other:"
                        value={other}
                        onChange={(event) => setOther(event.target.value)}
                        style={{ width: "100%" }}
                        disabled={editDisabled}
                      />
                    </Grid>
                  </Grid>
                  {renderButtons()}
                </TabPanel>

                {/* Sites Tab */}

                <TabPanel value={value} index={2}>
                  <EditCrewSites
                    d={data}
                    open={open}
                    setOpen={setOpen}
                    company={company}
                    disabled={editDisabled}
                  />
                </TabPanel>

                {/* Reports Tab */}

                <TabPanel value={value} index={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Paper
                        children={
                          <Grid
                            container
                            style={{ position: "relative", height: "100%" }}
                          >
                            <Typography
                              style={{ padding: "10px", fontWeight: "bold" }}
                            >
                              Signed Hazard Analysis (
                              {data && data.crewMember.haCrewMember.length})
                            </Typography>
                            <div
                              className={classes.overlay}
                              onClick={() => setOpenHA(true)}
                            >
                              <Typography className={classes.text}>
                                View All
                              </Typography>
                            </div>
                            <CustomDialog
                              fullWidth
                              maxWidth="lg"
                              open={openHA}
                              onClose={() => setOpenHA(false)}
                            >
                              <DialogContent>
                                <HATable
                                  data={
                                    data
                                      ? data.crewMember.haCrewMember.map(
                                          (h) => h.ha
                                        )
                                      : []
                                  }
                                />
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  style={yellowButton}
                                  onClick={() => setOpenHA(false)}
                                >
                                  DONE
                                </Button>
                              </DialogActions>
                            </CustomDialog>
                          </Grid>
                        }
                        elevation={5}
                        style={{
                          width: "100%",
                          height: "80px",
                          backgroundColor: "inherit",
                          borderRadius: "10px",
                          boxShadow: `10px -10px 30px ${theme.palette.grey3.main}`,
                          color: "white",
                        }}
                      />
                    </Grid>
                  </Grid>
                  {renderButtons()}
                </TabPanel>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </CustomDialog>
    </>
  );
}
