import React from "react";
import { useNavigate } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";

import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";

import { useMutation, useQuery } from "@apollo/client";

import { BULK_EDIT_RISK_SCORES } from "../../../graphql/mutations/admin/arm";
import { GET_UNSCORED_ARMS } from "../../../graphql/queries/admin/arm";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import SearchField from "../../CustomComponents/SearchField";
import TabPanel from "../../CustomComponents/TabPanels";
import { StyledTab, StyledTabs } from "../../CustomComponents/Tabs";
import { yellowButton } from "../../CustomStyles/buttons";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import RiskScoreInput from "./RiskScoreInput";

function UnscoredItem({ type, item, onEdit, editedItems }) {
  const edit = editedItems.find((i) => i.id === item.id);
  const [button, setButton] = React.useState(edit ? edit.riskScore : null);

  const handleChange = (newVal) => {
    setButton(newVal);
    onEdit(item, newVal);
  };

  return (
    <Grid
      item
      xs={12}
      container
      alignItems="center"
      style={{ margin: "10px 0px", borderBottom: "1px solid grey" }}
    >
      {type === "action" && (
        <Grid item xs={9}>
          <Typography>{item.a.name}</Typography>
        </Grid>
      )}
      {type === "risk" && (
        <Grid item xs={4.5}>
          <Typography>{item.a.name}</Typography>
        </Grid>
      )}
      {type === "risk" && (
        <Grid item xs={4.5}>
          <Typography>{item.r?.name}</Typography>
        </Grid>
      )}
      {type === "mitigator" && (
        <Grid item xs={3}>
          <Typography>{item.a.name}</Typography>
        </Grid>
      )}
      {type === "mitigator" && (
        <Grid item xs={3}>
          <Typography>{item.r.name}</Typography>
        </Grid>
      )}
      {type === "mitigator" && (
        <Grid item xs={3}>
          <Typography>{item.m.name}</Typography>
        </Grid>
      )}
      <Grid item xs={3}>
        <RiskScoreInput
          value={button}
          onChange={handleChange}
          range={item.riskLevelRange}
        />
      </Grid>
    </Grid>
  );
}

export default function UnscoredARMs() {
  const navigate = useNavigate();

  // Query for unscored ARMs
  const { data: unscored, loading: unscoreLoading } = useQuery(
    GET_UNSCORED_ARMS,
    {
      fetchPolicy: "network-only",
    }
  );

  const [mutateARMScores, { loading }] = useMutation(BULK_EDIT_RISK_SCORES, {
    onCompleted() {
      onCompletedFunc("ARM risk scores have been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [
      {
        query: GET_UNSCORED_ARMS,
        fetchPolicy: "network-only",
      },
    ],
  });
  const [value, setValue] = React.useState(0);
  const [actions, setActions] = React.useState([]);
  const [risks, setRisks] = React.useState([]);
  const [mitigators, setMitigators] = React.useState([]);
  const [search, setSearch] = React.useState("");

  const [editedItems, setEditedItems] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (unscored) {
      setActions(unscored.actions.filter((c) => !c.riskScore));
      setRisks(unscored.risks.filter((c) => !c.riskScore));
      setMitigators(unscored.mitigators.filter((c) => !c.riskScore));
    }
  }, [unscored]);

  const onEdit = (item, value) => {
    if (editedItems.findIndex((i) => i.id === item.id) >= 0) {
      setEditedItems((prev) =>
        prev.map((p) => {
          if (p.id === item.id) {
            p.riskScore = value;
          }
          return p;
        })
      );
    } else {
      setEditedItems((prev) => [...prev, { id: item.id, riskScore: value }]);
    }
  };

  const onSave = () => {
    mutateARMScores({
      variables: {
        armInputs: editedItems.map((i) => ({
          id: Number(i.id),
          riskScore: Number(i.riskScore),
        })),
      },
    });
  };

  const getRow = (item, type) => {
    return (
      <UnscoredItem
        key={item.id}
        type={type}
        item={item}
        onEdit={onEdit}
        editedItems={editedItems}
      />
    );
  };

  const [filteredA, setFilteredA] = React.useState([]);
  const [filteredR, setFilteredR] = React.useState([]);
  const [filteredM, setFilteredM] = React.useState([]);

  React.useEffect(() => {
    if (actions) setFilteredA(actions);
    if (risks) setFilteredR(risks);
    if (mitigators) setFilteredM(mitigators);
  }, [actions, risks, mitigators]);

  React.useEffect(() => {
    setFilteredA(
      actions.filter((a) =>
        `${a.a.name}`.toLowerCase().includes(search.toLowerCase())
      )
    );
    setFilteredR(
      risks.filter((a) =>
        `${a.a.name} ${a.r.name}`.toLowerCase().includes(search.toLowerCase())
      )
    );
    setFilteredM(
      mitigators.filter((a) =>
        `${a.a.name} ${a.r.name} ${a.m.name}`
          .toLowerCase()
          .includes(search.toLowerCase())
      )
    );
  }, [search]);

  return (
    <div>
      <DialogTitle style={{ ...pageTitleStyles }}>UNSCORED ARMS.</DialogTitle>

      {unscoreLoading ? (
        <Grid container justifyContent="center">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <DialogContent>
          <Grid item xs={12}>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                marginBottom: "10px",
                color: "white",
              }}
            >
              Total Unscored ARMs:{" "}
              {actions.length + risks.length + mitigators.length}
            </Typography>
          </Grid>
          <div style={{ margin: "20px 0px" }}>
            <SearchField value={search} setValue={setSearch} />
          </div>
          <Paper style={{ color: "black", padding: "10px" }}>
            <StyledTabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
            >
              <StyledTab label="ACTIONS" />
              <StyledTab label="RISKS" />
              <StyledTab label="MITIGATORS" />
            </StyledTabs>
            <TabPanel value={value} index={0}>
              <Grid container style={{ minWidth: "800px" }}>
                <Grid item xs={9}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.1rem",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    Action
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.1rem",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    New Risk Score
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Virtuoso
                    style={{ height: 400 }}
                    data={filteredA}
                    itemContent={(index, item) => getRow(item, "action")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                    }}
                  >
                    Total Unscored Actions: {filteredA.length}
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid container style={{ minWidth: "800px" }}>
                <Grid item xs={4.5}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.1rem",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    Action
                  </Typography>
                </Grid>
                <Grid item xs={4.5}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.1rem",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    Risk
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.1rem",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    New Risk Score
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Virtuoso
                    style={{ height: 400 }}
                    data={filteredR}
                    itemContent={(index, item) => getRow(item, "risk")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{ marginTop: "10px", fontSize: "14px" }}>
                    Total Unscored Risks: {filteredR.length}
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Grid container style={{ minWidth: "800px" }}>
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.1rem",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    Action
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.1rem",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    Risk
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.1rem",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    Mitigator
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.1rem",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    New Risk Score
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Virtuoso
                    style={{ height: 400 }}
                    data={filteredM}
                    itemContent={(index, item) => getRow(item, "mitigator")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                    }}
                  >
                    Total Unscored Mitigators: {filteredM.length}
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
          </Paper>
        </DialogContent>
      )}
      <DialogActions>
        <LoadingButton
          variant="contained"
          sx={{ marginRight: "10px", ...yellowButton }}
          onClick={onSave}
          loading={loading}
        >
          SAVE
        </LoadingButton>
        <Button
          variant="contained"
          style={{ marginRight: "10px" }}
          onClick={() => navigate(-1)}
        >
          CANCEL
        </Button>
      </DialogActions>
    </div>
  );
}
