import { gql } from "@apollo/client";

export const GET_PROJECTS = gql`
  query projectAdmin {
    projects {
      id
      timeCreated
      isActive
      name
      dollarAmount
      ownerSiteProjects(
        isActive: true
        site_Isnull: false
        owner_Isnull: false
        project_Isnull: false
        owner_IsActive: true
        site_IsActive: true
      ) {
        id
        owner {
          id
          name
        }
        site {
          id
          name
        }
      }
    }
  }
`;

export const GET_PROJECT_DATA = gql`
  query projectData($projectId: Int) {
    project(id: $projectId) {
      id
      name
      timeCreated
      isActive
      name
      addrLine1
      addrLine2
      addrCity
      addrState
      addrZip
      dollarAmount
      projectMediaFiles(isActive: true, mediaFile_IsActive: true) {
        id
        mediaFile {
          id
          file
        }
      }
      ownerSiteProjects(site_Isnull: false, isActive: true) {
        id
        location @client
        owner {
          id
          name
        }
        site {
          id
          name
        }
        project {
          id
          name
        }
        ownerSiteProjectTemplate {
          id
          isActive
          template {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_SITES = gql`
  query sites {
    ownerSiteProjects(
      site_Isnull: false
      project_Isnull: true
      site_IsActive: true
      owner_IsActive: true
      isActive: true
    ) {
      id
      owner {
        id
        name
      }
      site {
        id
        name
      }
    }
  }
`;
