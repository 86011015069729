import React from "react";
import { BrowserView, MobileView } from "react-device-detect";

import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Autocomplete,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import {
  Document,
  Page,
  PDFDownloadLink,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";

import {
  onlineVar,
  permissionsVar,
} from "../../../../../graphql/localVariables/user";
import { TEMPLATE_EDIT } from "../../../../../graphql/mutations/admin/planning-template";
import {
  GET_ACTIONS,
  GET_MITIGATORS,
  GET_RISKS,
} from "../../../../../graphql/queries";
import { GET_COMPANY } from "../../../../../graphql/queries/admin/company";
import {
  GET_TEMPLATE_PAGE_OSPS,
  GET_TEMPLATES,
  GET_TEMPLATE_DATA,
} from "../../../../../graphql/queries/admin/planning-template";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../../CustomComponents/OnErrorFunction";
import SelectedUnselected from "../../../../CustomComponents/SelectedUnselected";
import { CustomSwitch } from "../../../../CustomComponents/Switch";
import { CustomTextField } from "../../../../CustomStyles/LightTextField";
import { blackButton, yellowButton } from "../../../../CustomStyles/buttons";
import { CustomDialog } from "../../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../../CustomStyles/pageTitle";
import ListboxComponent from "../VirtualizedListBox";

const styles = StyleSheet.create({
  page: {
    width: "95%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    border: "3px solid black",
    justifyContent: "center",
    margin: "0px auto",
  },
  formSection: { width: "25%", border: "1px solid black", padding: "10px" },
  armSectionHeader: { width: "50%", borderBottom: "1px solid black" },
  armSectionHeaderMit: { width: "33.3%", borderBottom: "1px solid black" },
  armSection: {
    width: "100%",
    borderBottom: "1px solid black",
    borderLeft: "1px solid black",
  },
  column: { display: "flex", flexDirection: "column" },
  row: { width: "100%", display: "flex", flexDirection: "row" },
  text: { padding: "20px", fontSize: "10px" },
  sectionLabel: { paddingBottom: "10px", fontSize: "12px" },
  labelText: { padding: "10px", fontSize: "12px", color: "grey" },
  mainText: {
    padding: "10px",
    fontSize: "14px",
    width: "90%",
    borderBottom: "1px solid grey",
  },
});

export default function EditTemplate({ row, open, handleClose, company }) {
  const online = useReactiveVar(onlineVar);
  const permissions = useReactiveVar(permissionsVar);

  const [getTemplate, { data }] = useLazyQuery(GET_TEMPLATE_DATA);
  const [getActions, { data: actions }] = useLazyQuery(GET_ACTIONS, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const [getRisks, { data: risks }] = useLazyQuery(GET_RISKS, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const [getMit, { data: mit }] = useLazyQuery(GET_MITIGATORS, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const [getOSP, { data: osps, loading: loadingOSP }] = useLazyQuery(
    GET_TEMPLATE_PAGE_OSPS,
    {
      fetchPolicy: online ? "network-only" : "cache-only",
    }
  );

  React.useEffect(() => {
    if (open) {
      getTemplate({ variables: { id: Number(row.id) } });
      getActions();
      getRisks();
      getMit();
      getOSP();
    }
  }, [open]);

  const [editTemplate, { loading }] = useMutation(TEMPLATE_EDIT, {
    onCompleted() {
      setEdit(false);
      onCompletedFunc("Template has been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [
      { query: GET_TEMPLATES },
      company && {
        query: GET_COMPANY,
        variables: { id: Number(company.id) },
      },
      { query: GET_TEMPLATE_DATA, variables: { id: Number(row.id) } },
    ],
  });
  const [edit, setEdit] = React.useState(false);

  const [isActive, setIsActive] = React.useState(row.isActive);
  const [name, setName] = React.useState(row.name);
  const [startDate, setStartDate] = React.useState(new Date(row.startDate));
  const [endDate, setEndDate] = React.useState(new Date(row.endDate));

  const [osp, setOsp] = React.useState([]);

  const [selectedActions, setSelectedActions] = React.useState([]);
  const [unselectedActions, setUnselectedActions] = React.useState([]);
  const [viewMit, setViewMit] = React.useState(false);

  const setInitialState = () => {
    if (data) {
      const originalOSP = data.ownerSiteProjectTemplates.map((ospt) => ({
        id: ospt.ownerSiteProject.id,
        owner: ospt.ownerSiteProject.owner,
        site: ospt.ownerSiteProject.site,
        project: ospt.ownerSiteProject.project,
      }));
      const originalActions =
        data.templateArms.length > 0
          ? data.templateArms.map((tArm) => tArm.arm)
          : [];

      setIsActive(data.template.isActive);
      setName(data.template.name);
      setStartDate(new Date(data.template.startDate));
      setEndDate(new Date(data.template.endDate));
      setOsp(originalOSP);
      setSelectedActions(originalActions);
    }
  };

  React.useEffect(() => {
    if (actions && data) {
      setInitialState();
      if (data.templateArms.length > 0) {
        setUnselectedActions(
          actions.actionRiskMitigators.filter(
            (arm) =>
              data.templateArms.findIndex((a) => a.arm.a.id === arm.a?.id) < 0
          )
        );
      } else {
        setUnselectedActions(actions.actionRiskMitigators);
      }
    }
  }, [actions, data]);

  const onSave = () => {
    if (osp.length === 0) {
      onErrorFunc(
        "Select a company, site or project to associate to this template."
      );
    } else {
      const originalOSP = data.ownerSiteProjectTemplates.map((ospt) => ({
        id: ospt.ownerSiteProject.id,
        owner: ospt.ownerSiteProject.owner,
        site: ospt.ownerSiteProject.site,
        project: ospt.ownerSiteProject.project,
      }));
      const originalActions =
        data.templateArms.length > 0
          ? data.templateArms.map((tArm) => tArm.arm)
          : [];

      let unassociatedOSP = originalOSP
        .filter((oOSP) => osp.findIndex((o) => o.id === oOSP.id) < 0)
        .map((o) => Number(o.id));
      let unassociatedActions = originalActions
        .filter(
          (oAction) => selectedActions.findIndex((a) => a.id === oAction.id) < 0
        )
        .map((a) => Number(a.id));

      editTemplate({
        variables: {
          id: Number(row.id),
          name,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
          associatedOSP: osp.map((osp) => Number(osp.id)),
          unassociatedOSP,
          unassociatedActions,
          associatedActions: selectedActions.map((a) => Number(a.id)),
        },
      });
    }
  };

  const updateState = () => {};

  const onCancel = () => {
    if (edit) {
      setEdit(false);
      onClear();
    } else {
      handleClose();
    }
  };

  const onClear = () => {
    setInitialState();
  };

  const findRisks = (action) => {
    return risks
      ? risks.actionRiskMitigators.filter(
          (r) => r.a.id === action.id && r.isExpected
        )
      : [];
  };

  const findMit = (action, risk) => {
    return mit
      ? mit.actionRiskMitigators.filter(
          (m) => m.a.id === action.id && m.r.id === risk.id
        )
      : [];
  };

  const PDF = () => {
    const template = data.template;
    const arms = data.templateArms;

    return (
      <Document title="Template" author="NIXN">
        <Page size="A4" wrap>
          <View
            style={{
              padding: "10px",
              borderTop: "3px solid grey",
              margin: "20px auto",
              width: "95%",
            }}
          >
            <Text>TEMPLATE</Text>
          </View>
          <View style={styles.page}>
            <View
              style={[
                styles.formSection,
                { borderTop: "none", borderLeft: "none" },
              ]}
            >
              <Text style={styles.sectionLabel}>Template Name</Text>
              <Text style={{ fontSize: "12px" }}>{template.name}</Text>
            </View>
            <View
              style={[
                styles.formSection,
                { borderTop: "none", borderLeft: "none" },
              ]}
            >
              <Text style={styles.sectionLabel}>Date Created</Text>
              <Text style={{ fontSize: "12px" }}>
                {moment(new Date(template.timeCreated)).format(
                  "MM/DD/YYYY hh:mm A"
                )}
              </Text>
            </View>
            <View
              style={[
                styles.formSection,
                { borderTop: "none", borderLeft: "none" },
              ]}
            >
              <Text style={styles.sectionLabel}>Date Last Updated</Text>
              <Text style={{ fontSize: "12px" }}>
                {moment(new Date(template.lastUpdated)).format(
                  "MM/DD/YYYY hh:mm A"
                )}
              </Text>
            </View>
            <View
              style={[
                styles.formSection,
                { borderTop: "none", borderLeft: "none" },
              ]}
            >
              <Text style={styles.sectionLabel}>Last Edited By</Text>
              <Text style={{ fontSize: "12px" }}>
                {template.lastEditor
                  ? template.lastEditor?.firstName !== "" &&
                    template.lastEditor?.lastName !== ""
                    ? `${template.lastEditor?.firstName} ${template.lastEditor?.lastName}`
                    : template.lastEditor.username
                  : "N/A"}
              </Text>
            </View>
            <View
              style={[
                styles.formSection,
                { borderTop: "none", borderLeft: "none" },
              ]}
            >
              <Text style={styles.sectionLabel}>Start Date</Text>
              <Text style={{ fontSize: "12px" }}>
                {moment(template.startDate).format("MM/DD/YYYY")}
              </Text>
            </View>
            <View
              style={[
                styles.formSection,
                { borderTop: "none", borderLeft: "none" },
              ]}
            >
              <Text style={styles.sectionLabel}>End Date</Text>
              <Text style={{ fontSize: "12px" }}>
                {moment(template.endDate).format("MM/DD/YYYY")}
              </Text>
            </View>
            <View
              style={[
                styles.formSection,
                { borderTop: "none", borderLeft: "none" },
              ]}
            ></View>
            <View
              style={[
                styles.formSection,
                { borderTop: "none", borderLeft: "none" },
              ]}
            ></View>

            <View
              style={{
                width: "100%",
                height: "20px",
                backgroundColor: "black",
              }}
            ></View>
            <View
              style={[
                viewMit ? styles.armSectionHeaderMit : styles.armSectionHeader,
                { borderRight: "1px solid black" },
              ]}
            >
              <Text
                style={{
                  padding: "20px",
                  fontSize: "12px",
                  borderTop: "5px solid #8297A0",
                }}
              >
                Job Step
              </Text>
            </View>
            <View
              style={[
                viewMit ? styles.armSectionHeaderMit : styles.armSectionHeader,
                { borderRight: "1px solid black" },
              ]}
            >
              <Text
                style={{
                  padding: "20px",
                  fontSize: "12px",
                  borderTop: "5px solid #8297A0",
                }}
              >
                Expected Risks
              </Text>
            </View>
            {viewMit && (
              <View
                style={[
                  styles.armSectionHeaderMit,
                  { borderRight: "1px solid black" },
                ]}
              >
                <Text
                  style={{
                    padding: "20px",
                    fontSize: "12px",
                    borderTop: "5px solid #8297A0",
                  }}
                >
                  Mitigators
                </Text>
              </View>
            )}
            <View style={[styles.column, { width: "100%" }]}>
              {arms.map((action) => {
                return (
                  <View key={action.arm.a.name} style={styles.row}>
                    <View
                      style={[
                        viewMit
                          ? styles.armSectionHeaderMit
                          : styles.armSectionHeader,
                      ]}
                    >
                      <Text
                        style={[styles.text, { textAlign: "center" }]}
                        wrap={false}
                      >
                        {action.arm.a.name}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.column,
                        {
                          width: viewMit ? "66.6%" : "50%",
                        },
                      ]}
                    >
                      {findRisks(action.arm.a).length === 0 && (
                        <View style={[styles.row]}>
                          <View style={[styles.armSection]}>
                            <Text
                              style={[
                                styles.text,
                                {
                                  textAlign: "center",
                                },
                              ]}
                              wrap={false}
                            >
                              {" "}
                            </Text>
                          </View>
                        </View>
                      )}
                      {findRisks(action.arm.a).map((risk) => {
                        return (
                          <View key={risk.r.name} style={[styles.row]}>
                            <View style={[styles.armSection]}>
                              <Text
                                style={[
                                  styles.text,
                                  {
                                    textAlign: "center",
                                  },
                                ]}
                                wrap={false}
                              >
                                {risk.r.name}
                              </Text>
                            </View>
                            {viewMit && (
                              <View style={[styles.armSection]}>
                                {findMit(action.arm.a, risk.r).map((mit) => {
                                  return (
                                    <Text
                                      key={mit.id}
                                      style={[
                                        styles.text,
                                        {
                                          textAlign: "center",
                                        },
                                      ]}
                                    >
                                      {mit.m.name}
                                    </Text>
                                  );
                                })}
                              </View>
                            )}
                          </View>
                        );
                      })}
                    </View>
                  </View>
                );
              })}
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  const mobileView = (
    <Paper
      style={{
        width: "100%",
        height: "300px",
        marginTop: "20px",
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "300px" }}
      >
        <Typography>
          Cannot view PDF on mobile device. Download PDF in order to view.
        </Typography>
      </Grid>
    </Paper>
  );

  const OSPSelect = () => {
    const ospList = osps ? osps.templatePageOsps : [];

    const getOptionLabelFunction = (option) => {
      if (!option.site && !option.project) {
        return `Company: ${option.owner?.name}`;
      } else if (option.site && !option.project) {
        return `Site: ${option.site?.name} [${option.owner?.name}]`;
      } else
        return `Project: ${option.site?.name} [${option.owner?.name}] - ${option.project?.name}`;
    };

    return (
      <>
        <Autocomplete
          id="select-osp"
          options={ospList.sort((a, b) => {
            if (!a.site && b.site) {
              return -1;
            }
            if (a.site && !b.site) {
              return 1;
            }
            if (!a.project && b.project) {
              return -1;
            }
            if (a.project && !b.project) {
              return 1;
            }
            if (!a.site && !a.project && !b.site && !b.project) {
              return a.owner.name > b.owner.name ? 1 : -1;
            }
            if (a.site && !a.project && b.site && !b.project) {
              return a.site.name > b.site.name ? 1 : -1;
            }
            if (a.project && b.project) {
              return a.project.name > b.project.name ? 1 : -1;
            }
            return 0;
          })}
          value={osp}
          ListboxComponent={ListboxComponent}
          onChange={(event, value) => setOsp(value)}
          getOptionLabel={getOptionLabelFunction}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disableClearable
          multiple
          loading={loadingOSP}
          renderOption={(props, option, state) => [props, option, state]}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              style={{ color: "#fff" }}
              variant="standard"
              label={"Company, Site or Project:"}
            />
          )}
        />
      </>
    );
  };

  return (
    <CustomDialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle
        style={{
          ...pageTitleStyles,
          fontSize: "2rem",
        }}
      >
        TEMPLATE: <span style={{ color: "#FFB700" }}> {row.name}</span>
      </DialogTitle>
      <DialogContent>
        {edit ? (
          <Grid container spacing={2}>
            <Grid item xs={12} container alignItems="center">
              <FormControlLabel
                style={{ color: "white" }}
                control={
                  <CustomSwitch
                    checked={isActive}
                    onChange={(event) => setIsActive(event.target.checked)}
                    light
                  />
                }
                label={isActive ? "ACTIVE" : "INACTIVE"}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                label="Template Name:"
                variant="standard"
                value={name}
                onChange={(event) => setName(event.target.value)}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MobileDatePicker
                label="Start Date:"
                inputFormat="MM/DD/YYYY"
                value={startDate}
                onChange={(date) => setStartDate(date)}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    style={{
                      width: "100%",
                    }}
                    variant="standard"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MobileDatePicker
                label="End Date:"
                inputFormat="MM/DD/YYYY"
                value={endDate}
                onChange={(date) => setEndDate(date)}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    style={{
                      width: "100%",
                    }}
                    variant="standard"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              {OSPSelect()}
            </Grid>
            <Grid item xs={12}>
              <SelectedUnselected
                selected={selectedActions}
                unselected={unselectedActions}
                setSelected={setSelectedActions}
                setUnselected={setUnselectedActions}
                updateState={updateState}
                idKey="a"
                focusList={[]}
                label="Actions"
                selectedLabel
                showSearch
              />
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ marginBottom: "20px" }}
            >
              {permissions.includes("EDIT_TEMPLATE") && (
                <Button
                  style={{ ...yellowButton, height: "37px" }}
                  onClick={() => setEdit(true)}
                >
                  EDIT
                </Button>
              )}
              <Grid item xs={2} container direction="column">
                <Typography>View mitigators?</Typography>
                <FormControlLabel
                  style={{ color: "white" }}
                  control={
                    <CustomSwitch
                      checked={viewMit}
                      onChange={(event) => setViewMit(event.target.checked)}
                      light
                    />
                  }
                  label={viewMit ? "YES" : "NO"}
                />
              </Grid>
              {data && (
                <PDFDownloadLink
                  document={PDF()}
                  fileName={`TEMPLATE_${data.template.name.replace(
                    /[:<>*?".|\/\s]/g,
                    ""
                  )}`}
                  style={{ textDecoration: "none" }}
                >
                  {({ loading }) =>
                    loading ? (
                      "Loading document..."
                    ) : (
                      <div>
                        <Button
                          style={{
                            color: "white",
                            ...blackButton,
                            marginRight: "20px",
                          }}
                        >
                          Download PDF
                        </Button>
                      </div>
                    )
                  }
                </PDFDownloadLink>
              )}
            </Grid>
            {data ? (
              <>
                <BrowserView>
                  <PDFViewer width="100%" height="800px">
                    {PDF()}
                  </PDFViewer>
                </BrowserView>
                <MobileView>{mobileView}</MobileView>
              </>
            ) : (
              <Grid container justifyContent="center" style={{ width: "100%" }}>
                <CircularProgress color="secondary" />
              </Grid>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {edit && (
          <LoadingButton
            variant="contained"
            style={{ marginRight: "10px", ...yellowButton }}
            onClick={onSave}
            loading={loading}
          >
            SAVE
          </LoadingButton>
        )}
        <Button variant="contained" onClick={onCancel}>
          {edit ? "CANCEL" : "DONE"}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}
