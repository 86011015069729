import React from "react";

import { Grid } from "@mui/material";

import { useReactiveVar } from "@apollo/client";
import MuiPhoneNumber from "material-ui-phone-number";

import {
  createIncidentVar,
  editIncidentVar,
} from "../../../../graphql/localVariables/investigation";

export default function DateInputView({ type, question, updateState }) {
  // Global Variables
  const editObs = useReactiveVar(editIncidentVar);
  const createObs = useReactiveVar(createIncidentVar);
  const form = type === "EDIT" ? editObs : createObs;

  const [foundRes, setFoundRes] = React.useState({
    incidentQuestion: { id: question.id },
    textInput: "",
  });

  const handleChange = (value) => {
    // update State

    updateState({
      incidentQuestion: { id: question.id },
      textInput: value,
    });
    setFoundRes({
      incidentQuestion: { id: question.id },
      textInput: value,
    });
  };

  React.useEffect(() => {
    const doesExist = form.incidentQuestionResponses.find(
      (res) =>
        question.id === res.incidentQuestion?.id &&
        question.type === res.incidentQuestion?.type
    );
    if (doesExist) {
      setFoundRes(doesExist);
    } else {
      setFoundRes({
        incidentQuestion: { id: question.id },
        textInput: "",
      });
    }
  }, [form.incidentTopicResponses]);

  return (
    <Grid item xs={12} md={4} sx={{ padding: "10px 10px 50px 10px" }}>
      <MuiPhoneNumber
        defaultCountry={"us"}
        value={foundRes.textInput}
        onChange={handleChange}
        label={question.name}
        style={{ width: "100%" }}
      />
    </Grid>
  );
}
