import React from "react";

import CheckIcon from "@mui/icons-material/Check";
import {
  Grid,
  Autocomplete,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import {
  createObservationVar,
  editObservationVar,
} from "../../../graphql/localVariables/observation";
import { observationMutations } from "../../../graphql/mutations";
import SelectedUnselected from "../../CustomComponents/SelectedUnselected";
import CreateARM from "./CreateARM";

export default function Actions({
  type,
  onEditSection,
  actions: data,
  loading,
  permissions,
}) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  // Global Variables
  const editObs = useReactiveVar(editObservationVar);
  const createObs = useReactiveVar(createObservationVar);
  const form = type === "EDIT" ? editObs : createObs;

  // Mutations
  const { updateKeyEditObservation, updateKeyCreateObservation } =
    observationMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditObservation : updateKeyCreateObservation;

  // Local Variables
  const [open, setOpen] = React.useState(false);
  const [searchAction, setSearchAction] = React.useState({ a: { name: "" } });
  const [selectedActions, setSelectedActions] = React.useState([]);
  const [unselectedActions, setUnselectedActions] = React.useState([]);
  const [focusList, setFocusList] = React.useState([]);

  // Select/Unselect state update
  const updateState = (newSelected) => {
    onEditSection(2);
    updateObsKeyFunction("actions", newSelected);

    const currentActionsIds = newSelected.map((s) => s.a.id);

    // Remove images of potentially removed action
    const removedUploadedFiles = form.files.filter(
      (f) =>
        !currentActionsIds.includes(f.associatedItem.a.id) && f.originalFile.id
    );

    if (form.removeMediaFiles) {
      updateObsKeyFunction("removeMediaFiles", [
        ...form.removeMediaFiles,
        ...removedUploadedFiles.map((f) => f.originalFile.id),
      ]);
    } else {
      updateObsKeyFunction("removeMediaFiles", [
        ...removedUploadedFiles.map((f) => f.originalFile.id),
      ]);
    }
    updateObsKeyFunction(
      "files",
      form.files.filter((f) =>
        currentActionsIds.includes(f.associatedItem.a.id)
      )
    );
  };

  React.useEffect(() => {
    if (data) {
      if (form.actions && form.actions.length != 0) {
        const actionIds = form.actions.map((a) => Number(a.id));
        setSelectedActions(form.actions);
        setUnselectedActions(
          data.companyArms
            .filter((carm) => !actionIds.includes(Number(carm.arm.id)))
            .map((carm) => carm.arm)
        );
      } else {
        setSelectedActions([]);
        setUnselectedActions(data.companyArms.map((carm) => carm.arm));
      }
    }
  }, [data]);

  // Add Action when selected in search autocomplete
  const addActionBySearch = (value) => {
    if (
      value &&
      selectedActions.indexOf((act) => act.a.id === value.a.id) === -1
    ) {
      setSelectedActions((prevState) => {
        // Add value to previous state
        const newArr = [...prevState, value];
        // Update global state
        updateState(newArr);
        // Set local state to newArr
        return newArr;
      });
      setUnselectedActions((prevState) => {
        // Remove item from unselected
        const newState = prevState.filter(
          (prevItems) => prevItems.a.id !== value.a.id
        );
        return [...newState];
      });
      setSearchAction({ a: { name: "" } });
    }
  };

  React.useEffect(() => {
    if (form.ha && form.ha.haArms) {
      setFocusList(form.ha.haArms.map((arm) => Number(arm.arm.id)));
    }

    if (form.ha?.name === "") {
      setFocusList([]);
    }
  }, [form.ha]);

  const unselect = (item) => {
    const stateUnselected = [...unselectedActions, item];
    const oldStateSelected = [...selectedActions];
    const newState = oldStateSelected.filter(
      (prevItems) => prevItems.id !== item.id
    );
    updateState(newState);
    setSelectedActions(newState);
    setUnselectedActions(stateUnselected);
  };

  return (
    <Grid container spacing={2} sx={{ padding: { xs: "10px", md: "20px" } }}>
      <Grid item xs={12} md={4}>
        <Autocomplete
          disablePortal
          id="obs-search-actions"
          options={
            unselectedActions
              ? [...unselectedActions].sort((a, b) =>
                  a.a.name > b.a.name ? 1 : -1
                )
              : []
          }
          value={searchAction}
          onChange={(event, value) => addActionBySearch(value)}
          getOptionLabel={(option) => option.a.name}
          renderOption={(props, option) => (
            <li {...props} key={option.a.id}>
              {option.a.name}
            </li>
          )}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Search for Action:"
            />
          )}
        />
      </Grid>
      {mobile ? (
        <div style={{ marginTop: "10px", width: "100%", paddingLeft: "16px" }}>
          <Typography style={{ fontSize: "0.8rem" }}>
            Selected Actions:
          </Typography>
          <Grid
            container
            style={{
              border: "1px solid #8297a0",
              borderRadius: "4px",
              maxHeight: "200px",
              overflowY: "auto",
              width: "100%",
              backgroundColor: "white",
              color: "black",
            }}
          >
            {[...selectedActions].reverse().map((a) => (
              <Grid
                item
                container
                key={a.a.id}
                style={{ padding: "10px" }}
                onClick={() => unselect(a)}
              >
                <Grid item xs={10}>
                  <Typography style={{ maxWidth: "90%" }}>
                    {a.a.name}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <CheckIcon
                    style={{
                      color: "green",
                      fontSize: "24px",
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </div>
      ) : null}
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <SelectedUnselected
          selected={selectedActions}
          unselected={unselectedActions}
          setSelected={setSelectedActions}
          setUnselected={setUnselectedActions}
          updateState={updateState}
          idKey="a"
          focusList={focusList}
          label="Actions"
          loadingUnselected={loading}
          hideMobileSearch
        />
        {permissions.includes("ARM") ? (
          <>
            <Typography
              onClick={() => setOpen(true)}
              style={{ marginTop: "20px", cursor: "pointer" }}
            >
              If you don&apos;t see the action you are looking for,&nbsp;
              <span
                style={{
                  background: "#FFB700",
                }}
              >
                click here
              </span>
              &nbsp;to add it.
            </Typography>
            <CreateARM
              open={open}
              handleClose={() => setOpen(false)}
              tab="action"
              type={type}
            />{" "}
          </>
        ) : null}
      </Grid>
    </Grid>
  );
}
