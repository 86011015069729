import React from "react";

import { Grid, TextField } from "@mui/material";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";

import { useReactiveVar } from "@apollo/client";

import { createHAVar, editHAVar } from "../../../graphql/localVariables/ha";
import { haMutations } from "../../../graphql/mutations";
import { useStyles } from "./styles";

export default function TimeInputView({ type }) {
  const editHA = useReactiveVar(editHAVar);
  const createHA = useReactiveVar(createHAVar);
  const updateHA = type === "EDIT" ? editHA : createHA;
  const { updateCreateHA, updateEditHA } = haMutations;
  const updateHAFunction = type === "EDIT" ? updateEditHA : updateCreateHA;
  const classes = useStyles()();

  const handleChange = (time) => {
    updateHAFunction("startTime", time);
  };

  return (
    <Grid item xs={12} sm={6} lg={3} className={classes.borderedBox}>
      <MobileTimePicker
        label="Start Time:"
        inputFormat="hh:mm A"
        value={updateHA.startTime}
        onChange={handleChange}
        disabled={type === "EDIT"}
        renderInput={(params) => (
          <TextField
            {...params}
            style={{
              width: "100%",
            }}
            variant="standard"
          />
        )}
      />
    </Grid>
  );
}
