import { gql } from "@apollo/client";

export const MUTATE_AUDIT = gql`
  mutation mutateAudit($input: AuditMutationInput!) {
    audit(input: $input) {
      audit {
        id
        name
      }
    }
  }
`;
