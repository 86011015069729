import React from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Button,
  Tooltip,
  Autocomplete,
  Checkbox,
  Typography,
  Chip,
} from "@mui/material";

import { useReactiveVar, useQuery } from "@apollo/client";

import {
  createAuditVar,
  editAuditVar,
} from "../../../graphql/localVariables/audit";
import { onlineVar } from "../../../graphql/localVariables/user";
import { auditMutations } from "../../../graphql/mutations";
import { GET_USERNAMES } from "../../../graphql/queries";

export default function AuthorModal({ type }) {
  // Apollo queries, variables, mutations
  const editAudit = useReactiveVar(editAuditVar);
  const createAudit = useReactiveVar(createAuditVar);
  const updateAudit = type === "EDIT" ? editAudit : createAudit;
  const { updateCreateAudit, updateEditAudit } = auditMutations;
  const updateAuditFunction =
    type === "EDIT" ? updateEditAudit : updateCreateAudit;
  // Query for users
  const online = useReactiveVar(onlineVar);
  const { data } = useQuery(GET_USERNAMES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event, newValue) => {
    updateAuditFunction("contributors", newValue);
  };

  const filterData = () => {
    if (
      data &&
      data.users &&
      data.users.length !== 0 &&
      updateAudit &&
      updateAudit.creator &&
      updateAudit.creator.id
    ) {
      return data.users.filter((u) => {
        return u.id !== updateAudit.creator.id;
      });
    } else {
      return [];
    }
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <div style={{ display: "contents" }}>
      <Tooltip title="Add new contributors">
        <IconButton
          onClick={handleOpen}
          sx={{
            color: "#FFB700",
          }}
        >
          <GroupAddIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent sx={{ width: "500px", minHeight: "350px" }}>
          {online ? (
            <Autocomplete
              id="audit-add-contributor"
              options={data && data.users ? filterData() : []}
              value={updateAudit.contributors || []}
              multiple
              onChange={handleChange}
              getOptionLabel={(option) =>
                option.firstName !== "" && option.lastName !== ""
                  ? `${option.firstName} ${option.lastName}`
                  : option.username
              }
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option.id}
                    label={
                      <Typography>
                        {option.firstName !== "" && option.lastName !== ""
                          ? `${option.firstName} ${option.lastName}`
                          : option.username}
                      </Typography>
                    }
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.id}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.firstName !== "" && option.lastName !== ""
                    ? `${option.firstName} ${option.lastName}`
                    : option.username}
                </li>
              )}
              disableClearable
              isOptionEqualToValue={(option, value) => value.id === option.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={"Contributors:"}
                />
              )}
            />
          ) : (
            <Typography>Contributors not available in offline mode.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>CLOSE</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
