import React from "react";

import { Delete } from "@mui/icons-material";
import {
  TextField,
  Grid,
  Button,
  Typography,
  IconButton,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import { useReactiveVar } from "@apollo/client";
import moment from "moment";

import {
  createIncidentVar,
  editIncidentVar,
} from "../../../../graphql/localVariables/investigation";
import { blueButton, yellowButton } from "../../../CustomStyles/buttons";

export default function MultiDateRange({ question, type, updateState }) {
  const [ranges, setRanges] = React.useState([]);
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [allDays, setAllDays] = React.useState(0);
  const [nonweekendDays, setNonWeekendDays] = React.useState(0);
  const [showDates, setShowDates] = React.useState(false);

  // Global Variables
  const editIncident = useReactiveVar(editIncidentVar);
  const createIncident = useReactiveVar(createIncidentVar);
  const form = type === "EDIT" ? editIncident : createIncident;

  React.useEffect(() => {
    const doesExist = form.incidentQuestionResponses.find(
      (res) =>
        question.id === res.incidentQuestion?.id &&
        question.type === res.incidentQuestion?.type
    );
    if (doesExist) {
      setRanges(doesExist.dateRanges);
      setShowDates(true);
    }
  }, [form.incidentTopicResponses]);

  const countDays = (startDate, endDate) => {
    var d1 = moment(startDate);
    var d2 = moment(endDate);
    var countAll = 0;
    var countNoWeekend = 0;

    while (d1 < d2) {
      var day = d1.day();
      var isWeekend = day === 6 || day === 0;
      if (isWeekend) {
        countAll++;
      } else {
        countAll++;
        countNoWeekend++;
      }
      d1.add(1, "days");
    }

    return { countAll, countNoWeekend };
  };

  React.useEffect(() => {
    let countAll = 0;
    let countNoWeekend = 0;

    ranges.forEach((r) => {
      const { countAll: all, countNoWeekend: noWeekend } = countDays(
        r.startDate,
        r.endDate
      );
      countAll = countAll + all;
      countNoWeekend = countNoWeekend + noWeekend;
    });

    setAllDays(countAll);
    setNonWeekendDays(countNoWeekend);
  }, [ranges]);

  const addRange = () => {
    setRanges((prev) => [
      ...prev,
      {
        id: prev.length,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
      },
    ]);
    updateState({
      incidentQuestion: { id: question.id },
      dateRanges: [
        ...ranges,
        {
          id: ranges.length,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
        },
      ],
    });
  };

  const removeRange = (id) => {
    setRanges((prev) => {
      const newRanges = prev.filter((p) => p.id !== id);
      updateState({
        incidentQuestion: { id: question.id },
        dateRanges: newRanges,
      });
      return newRanges;
    });
  };

  return (
    <Grid
      item
      xs={12}
      sx={{ padding: "10px 10px 50px 10px" }}
      container
      spacing={2}
    >
      <Grid item xs={4}>
        <Typography>{question.name}</Typography>
      </Grid>

      {!showDates ? (
        <Grid item xs={8} container spacing={2}>
          <FormControlLabel
            control={
              <Switch
                checked={showDates}
                onChange={() => setShowDates((prev) => !prev)}
              />
            }
            label={showDates ? "YES" : "NO"}
          />
        </Grid>
      ) : (
        <Grid item xs={8} container spacing={2}>
          <FormControlLabel
            control={
              <Switch
                checked={showDates}
                onChange={() => setShowDates((prev) => !prev)}
              />
            }
            label={showDates ? "YES" : "NO"}
          />

          <Grid item xs={12}>
            {ranges.map((r) => (
              <Grid container key={r.id}>
                <p>
                  {moment(r.startDate).format("MM/DD/YYYY")} to{" "}
                  {moment(r.endDate).format("MM/DD/YYYY")}
                </p>
                <IconButton onClick={() => removeRange(r.id)}>
                  <Delete />
                </IconButton>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={6}>
            <MobileDatePicker
              label="Start Date:"
              inputFormat="MM/DD/YYYY"
              value={startDate}
              onChange={(date) => setStartDate(date)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{
                    width: "100%",
                  }}
                  variant="standard"
                />
              )}
              maxDate={endDate}
            />
          </Grid>

          <Grid item xs={6}>
            <MobileDatePicker
              label="End Date:"
              inputFormat="MM/DD/YYYY"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{
                    width: "100%",
                  }}
                  variant="standard"
                />
              )}
              minDate={startDate}
            />
          </Grid>

          <Grid item xs={6}>
            <Button onClick={addRange} style={yellowButton}>
              Add Time Period
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                setStartDate(new Date());
                setEndDate(new Date());
              }}
              style={blueButton}
            >
              Clear Time Period
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Typography>
              Number of days including weekends: {allDays}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              Number of days excluding weekends: {nonweekendDays}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
