export const filterData = (
  arr,
  searchTerm,
  contractor,
  nixnuser,
  active
) => {
  let filteredArr = searchFilter(arr, searchTerm);
  if(contractor != "") {
    filteredArr = contractorFilter(filteredArr, contractor);
  }
  if(nixnuser != "") {
    filteredArr = nixnUserFilter(filteredArr, nixnuser);
  }
  if(active != "") {
    filteredArr = activeFilter(filteredArr, active);
  }
  return filteredArr;
};

// searchTerm - string
// arr - array of objects
const searchFilter = (arr, searchTerm) => {
  searchTerm = String(searchTerm).toLowerCase();
  return arr.filter((o) => o.name.toLowerCase().includes(searchTerm));
};

const contractorFilter = (arr, contractor) => {
  return arr.filter((o) => contractor === "Yes" ? o.isContractor : !o.isContractor);
}

const nixnUserFilter = (arr, nixnuser) => {
  // every company is currently a nixnuser
  return nixnuser === "Yes" ? arr : [];
  // return arr.filter((o) => o.nixnuser === nixnuser);
}

const activeFilter = (arr, active) => {
  return arr.filter((o) => active === "Active" ? o.isActive : !o.isActive);
}