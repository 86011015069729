export const filterData = (arr, searchTerm, active, siteOwner) => {
  let filteredArr = searchFilter(arr, searchTerm);

  filteredArr = activeFilter(filteredArr, active);

  if (siteOwner.name !== "") {
    filteredArr = siteOwnerFilter(filteredArr, siteOwner);
  }

  return filteredArr;
};

// searchTerm - string
// arr - array of objects
const searchFilter = (arr, searchTerm) => {
  searchTerm = String(searchTerm).toLowerCase();
  return arr.filter((o) => o.site.name.toLowerCase().includes(searchTerm));
};

const activeFilter = (arr, active) => {
  return arr.filter((o) => (active ? true : o.site.isActive));
};

const siteOwnerFilter = (arr, siteOwner) => {
  return arr.filter((o) => o.owner.id === siteOwner.id);
};
