import React from "react";

import { Grid, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useReactiveVar } from "@apollo/client";

import { permissionsVar } from "../../../graphql/localVariables/user";

const StyledToggleButton = styled(ToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "#8297A0",
  },
});

export default function RiskScoreInput({ value, onChange, range }) {
  const permissions = useReactiveVar(permissionsVar);
  const handleChange = (event, newVal) => {
    if (newVal !== null) {
      onChange(newVal);
    }
  };

  return (
    <Grid
      item
      style={{
        margin: "5px 0px",
        backgroundColor: "white",
        borderRadius: "6px",
      }}
    >
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={handleChange}
        aria-label="risk score"
        disabled={!permissions.includes("EDIT_ARM_RISK_SCORES")}
      >
        <StyledToggleButton
          value={0}
          aria-label="0"
          disabled={range && !range.includes(0)}
        >
          <Tooltip title="Eliminated">
            <span>E</span>
          </Tooltip>
        </StyledToggleButton>

        <StyledToggleButton
          value={0.1}
          aria-label="0.1"
          disabled={range && !range.includes(1)}
        >
          <Tooltip title="Slight">
            <span>SL</span>
          </Tooltip>
        </StyledToggleButton>

        <StyledToggleButton
          value={0.3}
          aria-label="0.3"
          disabled={range && !range.includes(2)}
        >
          <Tooltip title="Possible">
            <span>P</span>
          </Tooltip>
        </StyledToggleButton>

        <StyledToggleButton
          value={0.5}
          aria-label="0.5"
          disabled={range && !range.includes(3)}
        >
          <Tooltip title="Substantial">
            <span>SB</span>
          </Tooltip>
        </StyledToggleButton>

        <StyledToggleButton
          value={0.7}
          aria-label="0.7"
          disabled={range && !range.includes(4)}
        >
          <Tooltip title="High">
            <span>H</span>
          </Tooltip>
        </StyledToggleButton>

        <StyledToggleButton
          value={0.9}
          aria-label="0.9"
          disabled={range && !range.includes(5)}
        >
          <Tooltip title="Very High">
            <span>VH</span>
          </Tooltip>
        </StyledToggleButton>
      </ToggleButtonGroup>
    </Grid>
  );
}
