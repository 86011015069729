import React from "react";

import { Search } from "@mui/icons-material";
import { Grid, IconButton, TextField } from "@mui/material";

import { CustomTextField } from "../CustomStyles/LightTextField";

export default function SearchField({ value, setValue, dark }) {
  const [text, setText] = React.useState(value);

  React.useEffect(() => {
    setText(value);
  }, [value]);

  const search = () => {
    setValue(text);
  };

  return (
    <Grid container alignItems="center">
      {dark ? (
        <TextField
          value={text}
          label="Search:"
          variant="standard"
          onChange={(e) => setText(e.target.value)}
          sx={{ width: "calc(100% - 50px)" }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setValue(text);
            }
          }}
        />
      ) : (
        <CustomTextField
          value={text}
          label="Search:"
          variant="standard"
          onChange={(e) => setText(e.target.value)}
          sx={{ width: "calc(100% - 50px)" }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setValue(text);
            }
          }}
        />
      )}
      <IconButton
        onClick={search}
        style={{
          backgroundColor: "black",
          borderRadius: "3px",
          marginTop: "15px",
          height: "32px",
          width: "50px",
        }}
      >
        <Search style={{ color: "white" }} />
      </IconButton>
    </Grid>
  );
}
