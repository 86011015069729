import * as React from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ReportIcon from "@mui/icons-material/Report";
import WarningIcon from "@mui/icons-material/Warning";
import { Chip, MenuItem, Typography, Menu } from "@mui/material";
import Fade from "@mui/material/Fade";

import { useReactiveVar } from "@apollo/client";

import {
  createAuditVar,
  editAuditVar,
} from "../../../graphql/localVariables/audit";
import { auditMutations } from "../../../graphql/mutations";
import { getAuditScores } from "../../../utils/raScore";

export default function CustomizedMenus({ questions, type }) {
  // Apollo variables, mutations
  const editAudit = useReactiveVar(editAuditVar);
  const createAudit = useReactiveVar(createAuditVar);
  const updateAudit = type === "EDIT" ? editAudit : createAudit;
  const {
    updateCreateResponsesAudit,
    updateEditResponsesAudit,
    updateEditAudit,
    updateCreateAudit,
  } = auditMutations;
  const updateAuditFunction =
    type === "EDIT" ? updateEditResponsesAudit : updateCreateResponsesAudit;
  const updateKeyAuditFunction =
    type === "EDIT" ? updateEditAudit : updateCreateAudit;

  // Risk Chips
  const renderChip = (color, risk) => {
    return (
      <Chip
        label={`${risk} Severity`}
        sx={{
          backgroundColor: color,
          marginLeft: "10px",
          height: "20px",
          "&:hover": { backgroundColor: color, opacity: 0.8 },
        }}
        deleteIcon={<KeyboardArrowDownIcon />}
        onDelete={handleClick}
        onClick={handleClick}
        clickable
      />
    );
  };
  // State for Risk Level dropdown
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (value) => {
    onRiskChange(value);
    handleClose();
  };

  const onRiskChange = (risk) => {
    setAnchorEl(null);

    updateAudit.auditResponses.forEach((r) => {
      const index = questions.findIndex((q) => q.id === r.auditTopicQuestion);
      if (index !== -1) {
        updateAuditFunction(
          questions[index].score + risk,
          r.auditTopicQuestion,
          "score"
        );
      }
    });

    const scores = getAuditScores(
      updateAudit.auditTopics,
      updateAudit.auditResponses
    );
    updateKeyAuditFunction("raScore", scores.raScore.toFixed(2) * 100);
  };

  // total the Risk Level average
  const getRiskAverage = () => {
    let total = 0;
    updateAudit.auditResponses.forEach((r) => {
      const find = questions.find((q) => q.id === r.auditTopicQuestion);
      if (find) {
        if (r.score) {
          total += r.score - find.score;
        } else {
          total += 0;
        }
      }
    });
    if (total > 0) {
      return renderChip("#d32f2f", "High");
    } else if (total < 0) {
      return renderChip("#2e7d32", "Low");
    } else {
      return renderChip("#d9d9d9", "Default");
    }
  };

  React.useEffect(() => {}, [updateAudit.auditResponses]);

  return (
    <>
      {getRiskAverage()}
      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id="risk-dropdown-menu"
        MenuListProps={{
          "aria-labelledby": "risk-dropdown-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem disableRipple onClick={() => handleSelect(-0.1)}>
          <CheckCircleIcon sx={{ color: "#2e7d32", marginRight: "2px" }} />
          <Typography sx={{ fontSize: "0.8125rem" }}>Low Severity</Typography>
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={() => handleSelect(0)}
          sx={{
            borderTop: "solid #d9d9d9 .25px",
            borderBottom: "solid #d9d9d9  .25px",
          }}
        >
          <WarningIcon sx={{ color: "#d9d9d9", marginRight: "2px" }} />
          <Typography sx={{ fontSize: "0.8125rem" }}>
            Default Severity
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => handleSelect(0.1)} disableRipple>
          <ReportIcon sx={{ color: "#d32f2f", marginRight: "2px" }} />
          <Typography sx={{ fontSize: "0.8125rem" }}>High Severity</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
