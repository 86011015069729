import React from "react";
import { useNavigate } from "react-router-dom";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Visibility from "@mui/icons-material/Visibility";
import {
  List,
  ListItemText,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItemButton,
} from "@mui/material";
import { useTheme } from "@mui/styles";

export default function ViewItems({
  iconSelectedColor,
  textSelectedColor,
  endBarSelectedColor,
  classes,
  permissions,
  keyHook,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const expandIconColor = open ? endBarSelectedColor : theme.palette.grey1.main;

  let viewObsItem;
  if (permissions.includes("OBSERVATION")) {
    viewObsItem = (
      <ListItemButton onClick={() => navigate("/view-obs")}>
        <ListItemText primary="Observations" className={classes.listText} />
      </ListItemButton>
    );
  }

  let viewHAItem;
  if (permissions.includes("HA")) {
    viewHAItem = (
      <ListItemButton onClick={() => navigate("/view-ha")}>
        <ListItemText primary="HAs" className={classes.listText} />
      </ListItemButton>
    );
  }

  let viewAuditItem;
  if (permissions.includes("AUDIT")) {
    viewAuditItem = (
      <ListItemButton onClick={() => navigate("/view-audit")}>
        <ListItemText primary="Audits" className={classes.listText} />
      </ListItemButton>
    );
  }

  let viewInvestigationItem;
  if (permissions.includes("VIEW_INCIDENT_REPORT")) {
    viewInvestigationItem = (
      <ListItemButton onClick={() => navigate("/view-incident")}>
        <ListItemText
          primary="Incident Investigation"
          className={classes.listText}
        />
      </ListItemButton>
    );
  }

  return (
    <Accordion disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: expandIconColor }} />}
        aria-controls="view-content"
        id="view-header"
        style={{
          backgroundColor: theme.palette.black0.main,
          color: theme.palette.grey1.main,
        }}
        onClick={handleClick}
      >
        <Visibility
          className={classes.listIcons}
          style={{
            color: open || keyHook === "view" ? iconSelectedColor : undefined,
          }}
        />
        <Typography
          className={classes.listText}
          style={{
            color: open || keyHook === "view" ? textSelectedColor : undefined,
          }}
        >
          View
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{
          backgroundColor: theme.palette.black0.main,
          paddingBottom: "0px",
        }}
      >
        <List>
          {viewObsItem}
          {viewHAItem}
          {viewAuditItem}
          {viewInvestigationItem}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}
