import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import shareIcon from "../../images/icons8-share-rounded-30.png";
import roundLogo from "../../images/logo192.png";

export function PWADialog({ open, setOpen }) {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={11} container alignItems="center">
            <img
              src={roundLogo}
              style={{ width: "50px", marginRight: "10px" }}
              alt="NIXN logo"
            />
            <Typography>Install NIXN</Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography style={{ marginBottom: "10px" }}>
          Install this app on your device to access it from your homescreen.
        </Typography>
        <Grid container alignItems="center">
          <Typography>1. Tap on </Typography>
          <img
            src={shareIcon}
            style={{ margin: "0px 5px", width: "25px" }}
            alt="iOS Share icon"
          />
          <Typography>in the tab bar</Typography>
        </Grid>
        <Typography>2. Scroll and select Add to Home Screen</Typography>
        <Typography>3. Look for the NIXN logo on your home screen</Typography>
      </DialogContent>
    </Dialog>
  );
}
