import React from "react";
import { useParams } from "react-router-dom";

import { Grid, Typography } from "@mui/material";

import { useQuery, useReactiveVar } from "@apollo/client";

import { onlineVar } from "../../../graphql/localVariables/user";
import { VIEW_HA } from "../../../graphql/queries/ha";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import Layout from "../../Layout";
import HAForm from "../Form";

export default function EditHAForm() {
  const { id } = useParams();
  const online = useReactiveVar(onlineVar);
  const { data } = useQuery(VIEW_HA, {
    variables: { id: Number(id) },
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  return (
    <Layout>
      <Grid
        style={{
          height: "100%",
        }}
      >
        <Typography
          sx={{ ...pageTitleStyles, fontSize: { xs: "2rem", md: "2.75rem" } }}
        >
          EDIT HAZARD ANALYSIS.
        </Typography>
        <HAForm ha={data?.hazardAnalysis} type="EDIT" />
      </Grid>
    </Layout>
  );
}
