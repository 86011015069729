import React from "react";

import Layout from "../../Layout";
import InvestigationForm from "../Form";

export default function CreateInvestigation() {
  return (
    <Layout>
      <InvestigationForm type={"CREATE"} />
    </Layout>
  );
}
