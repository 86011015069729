import { gql } from "@apollo/client";

export const CREATE_COMPANY = gql`
  mutation createCompany(
    $parent: ID
    $industry: ID
    $name: String!
    $rt: Float
    $ao: Boolean
    $isActive: Boolean
    $logo: String
    $supervisors: [ID]
    $childCompanies: [ID]
    $associatedUsers: [ID]
    $ownerSiteProject: [ID]
    $isContractor: Boolean
    $associatedTemplate: [ID]
  ) {
    companyCreateOrEditMutation(
      name: $name
      parent: $parent
      industry: $industry
      riskThreshold: $rt
      isActive: $isActive
      administrativeOrganization: $ao
      logoBase64: $logo
      childCompanies: $childCompanies
      isContractor: $isContractor
      ownerSiteProject: $ownerSiteProject
      supervisors: $supervisors
      associatedUsers: $associatedUsers
      associatedTemplate: $associatedTemplate
    ) {
      company {
        id
        name
      }
      errors {
        messages
        field
      }
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany(
    $id: ID!
    $parent: ID
    $industry: ID
    $name: String
    $rt: Float
    $ao: Boolean
    $logo: String
    $isActive: Boolean
    $supervisors: [ID]
    $associatedUsers: [ID]
    $unassociatedUsers: [ID]
    $childCompanies: [ID]
    $ownerSiteProject: [ID]
    $isContractor: Boolean
    $associatedTemplate: [ID]
    $unassociatedTemplate: [ID]
  ) {
    companyCreateOrEditMutation(
      id: $id
      name: $name
      parent: $parent
      industry: $industry
      riskThreshold: $rt
      isActive: $isActive
      administrativeOrganization: $ao
      logoBase64: $logo
      childCompanies: $childCompanies
      isContractor: $isContractor
      ownerSiteProject: $ownerSiteProject
      supervisors: $supervisors
      associatedUsers: $associatedUsers
      unassociatedUsers: $unassociatedUsers
      associatedTemplate: $associatedTemplate
      unassociatedTemplate: $unassociatedTemplate
    ) {
      company {
        id
        name
      }
      errors {
        messages
        field
      }
    }
  }
`;

export const UPDATE_COMPANY_OBSERVED_SITES = gql`
  mutation updateCompany(
    $id: ID!
    $ownerSiteProject: [ID]
    $isContractor: Boolean
  ) {
    companyCreateOrEditMutation(
      id: $id
      isContractor: $isContractor
      ownerSiteProject: $ownerSiteProject
    ) {
      company {
        id
        name
      }
      errors {
        messages
        field
      }
    }
  }
`;

export const TOGGLE_COMPANY_STATUS = gql`
  mutation COMPANY_TOGGLE($activeCompanies: [ID]!, $inactiveCompanies: [ID]!) {
    toggleCompanyStatus(
      InactiveCompanies: $inactiveCompanies
      activeCompanies: $activeCompanies
    ) {
      ActiveCompanies {
        id
        name
      }
      InactiveCompanies {
        id
        name
      }
    }
  }
`;

export const COMPANY_ACTION = gql`
  mutation COMPANY_ACTION($company: ID!, $ua: [ID]!, $aa: [ID]!) {
    companyActionMutations(
      company: $company
      unassociatedArms: $ua
      associatedArms: $aa
    ) {
      associatedArms {
        company {
          id
          name
        }
        arm {
          a {
            id
            name
          }
        }
      }
    }
  }
`;

export const COMPANY_INDUSTRY = gql`
  mutation COMPANY_INDUSTRY($company: ID!, $industry: ID!) {
    companyIndustryMutation(company: $company, industry: $industry) {
      company {
        id
        name
        industry {
          id
          name
          isActive
        }
      }
    }
  }
`;

export const COMPANY_USER = gql`
  mutation COMPANY_USER($user: ID!, $company: ID!) {
    updateUser(userId: $user, company: $company) {
      user {
        id
        username
        firstName
        lastName
        company {
          id
          name
        }
      }
    }
  }
`;

export const ADD_USER_TO_COMPANY = gql`
  mutation ADD_USER_TO_COMPANY(
    $company: ID!
    $supervisors: [ID]
    $inactive_users: [ID]
    $active_users: [ID]
  ) {
    addUserToCompany(
      company: $company
      supervisors: $supervisors
      unassociatedUsers: $inactive_users
      associatedUsers: $active_users
    ) {
      activeUsers {
        id
        ownerSiteProject {
          owner {
            id
            name
          }
        }
        user {
          id
          username
        }
      }
    }
  }
`;
