import React from "react";
import { useNavigate } from "react-router-dom";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Paper,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";

import { useReactiveVar, useMutation, useQuery } from "@apollo/client";
import moment from "moment";

import {
  createAuditImagesVar,
  createAuditVar,
  editAuditImagesVar,
  editAuditVar,
  initialState,
} from "../../../graphql/localVariables/audit";
import { onlineVar } from "../../../graphql/localVariables/user";
import { auditMutations, authMutations } from "../../../graphql/mutations";
import { GET_AUDITS } from "../../../graphql/queries/audits";
import { onErrorFunc } from "../../CustomComponents/OnErrorFunction";
import { yellowButton, blackButton } from "../../CustomStyles/buttons";
import Authors from "./Authors";
import RiskDropdown from "./RiskDropdown";
import TopicQuestions from "./TopicQuestions";

export default function AuditForm({ type, setStartAudit }) {
  const navigate = useNavigate();

  // Apollo queries, variables, mutations
  const editAudit = useReactiveVar(editAuditVar);
  const createAudit = useReactiveVar(createAuditVar);
  const editAuditImages = useReactiveVar(editAuditImagesVar);
  const createAuditImages = useReactiveVar(createAuditImagesVar);
  const online = useReactiveVar(onlineVar);
  const updateAudit = type === "EDIT" ? editAudit : createAudit;
  const updateAuditImages =
    type === "EDIT" ? editAuditImages : createAuditImages;
  const {
    updateCreateAudit,
    updateEditAudit,
    updateAllCreateAudit,
    updateAllEditAudit,
    updateCreateAuditImages,
    updateEditAuditImages,
    AUDIT_EDIT,
    AUDIT_CREATE,
  } = auditMutations;
  const updateAuditFunction =
    type === "EDIT" ? updateEditAudit : updateCreateAudit;
  const updateAuditImagesFunction =
    type === "EDIT" ? updateEditAuditImages : updateCreateAuditImages;
  const updateAllAuditFunction =
    type === "EDIT" ? updateAllEditAudit : updateAllCreateAudit;
  const { updateSnackbar, updateLoading } = authMutations;

  // mutation to create audit
  const [createAuditMutation, { loading: loadingCreate }] = useMutation(
    AUDIT_CREATE,
    {
      onCompleted(data) {
        if (data.auditCreate.auditSubmission) {
          updateSnackbar({
            open: true,
            message: "Audit was sent successfully!",
            severity: "success",
          });
          navigate(`/view-audit/${data.auditCreate.auditSubmission.id}`);
          updateAllAuditFunction(initialState);
        } else {
          onErrorFunc("There was an error when creating your audit.");
        }
      },
      onError(error) {
        onErrorFunc(error);
      },
    }
  );

  // Mutation to edit audit
  const [editAuditMutation, { loading: loadingEdit }] = useMutation(
    AUDIT_EDIT,
    {
      onCompleted(data) {
        if (data.auditEdit.auditSubmission) {
          updateSnackbar({
            open: true,
            message: "Audit was edited successfully!",
            severity: "success",
          });
          navigate(`/view-audit/${data.auditEdit.auditSubmission.id}`);
          updateAllAuditFunction({ ...initialState, id: null });
        } else {
          onErrorFunc("There was an error when editing your audit.");
        }
      },
      onError(error) {
        onErrorFunc(error);
      },
    }
  );

  const { data: allAudits } = useQuery(GET_AUDITS, {
    fetchPolicy: "cache-only",
  });

  // Accordion state
  const [expanded, setExpanded] = React.useState(false);
  // state to disable state button
  const [checked, setChecked] = React.useState(true);

  // Totals on accordion
  const getNumAnswers = (ques) => {
    const auditResponses = updateAudit.auditResponses;
    let countYes = 0;
    let countNo = 0;
    let countNA = 0;
    let countMissing = 0;
    const total = ques.length;
    ques.forEach((q) => {
      const question = auditResponses.find(
        (r) => parseInt(r.auditTopicQuestion) === parseInt(q.id)
      );
      // && question.response comes back false if === 0
      if (question) {
        if (parseInt(question.response) === 1) {
          countYes++;
        }
        if (parseInt(question.response) === -1) {
          countNo++;
        }
        if (parseInt(question.response) === 0) {
          countNA++;
        }
        if (question.response === undefined) {
          countMissing++;
        }
      } else {
        countMissing++;
      }
    });
    return { countYes, countNo, countNA, countMissing, total };
  };

  // Open accordion
  const handleChange = (panel) => (event, isExpanded) => {
    // Maybe not the best solution but fix the issue where clicking the risk opened accordion
    if (
      event.target.outerText !== "Low Severity" &&
      event.target.outerText !== "Default Severity" &&
      event.target.outerText !== "High Severity"
    ) {
      setExpanded(isExpanded ? panel : false);
    }
  };

  // when audit is chosen, call to get topics and questions
  React.useEffect(() => {
    if (updateAudit.audit && updateAudit.audit.id && allAudits) {
      const found = allAudits.audits.find(
        (a) => Number(a.id) === Number(updateAudit.audit.id)
      );
      if (found) updateAuditFunction("auditTopics", found?.topics);
    }
  }, [allAudits, updateAudit.audit]);

  React.useEffect(() => {
    updateLoading(loadingEdit);
  }, [loadingEdit]);

  React.useEffect(() => {
    updateLoading(loadingCreate);
  }, [loadingCreate]);

  const totalPenaltyCalc = () => {
    let totalPenalty = 0;
    let answerTotal = 0;
    if (updateAudit.auditResponses && updateAudit.auditResponses.length !== 0) {
      updateAudit.auditResponses.forEach((r) => {
        if (
          Number(r.response) === -1 ||
          Number(r.response) === 1 ||
          Number(r.response) === 0
        ) {
          answerTotal++;
        }
        if (Number(r.response) === -1 && r.penalty) {
          totalPenalty = Number(totalPenalty) + Number(r.penalty);
        }
      });
    }
    return { totalPenalty, answerTotal };
  };

  // add penalty total
  const renderPenalty = () => {
    const { totalPenalty, answerTotal } = totalPenaltyCalc();
    if (totalPenalty !== 0) {
      return (
        <>
          <Typography sx={{ color: "#FFB700" }}>PENALTY</Typography>
          <Typography>
            ${parseFloat(totalPenalty.toFixed(2)).toLocaleString()}
          </Typography>
        </>
      );
    } else {
      const questionTotal =
        updateAudit.auditTopics.length !== 0
          ? updateAudit.auditTopics.reduce((acc, currentValue, index) => {
              if (index === 1) {
                return acc.questions.length + currentValue.questions.length;
              } else {
                return acc + currentValue.questions.length;
              }
            })
          : 0;
      return (
        <>
          <Typography sx={{ color: "#FFB700" }}>PERCENT COMPLETED</Typography>
          <Typography>
            {questionTotal !== 0
              ? parseFloat((answerTotal / questionTotal) * 100).toFixed(0)
              : 0}
            %
          </Typography>
        </>
      );
    }
  };

  function groupBy(objectArray, property) {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
    }, {});
  }

  const formatFiles = (files) => {
    let newArray = [];
    const groupedFiles = groupBy(files, "questionId");
    Object.entries(groupedFiles).forEach(([key, value]) => {
      const questionId = key;
      value.forEach((file, index) => {
        if (file.image.name) {
          const newName = file.image.name.split(".").pop();
          const newFile = new File(
            [file.image],
            `image_${questionId}_${index}.${newName}`,
            {
              type: file.image.type,
            }
          );
          newArray.push(newFile);
        }
      });
    });

    return newArray;
  };

  const formatResponses = (res) => {
    const response = res.map((r) => {
      return {
        auditTopicQuestion: Number(r.auditTopicQuestion),
        response: Number(r.response),
        comment: r.comment,
        score: r.score,
      };
    });
    return response;
  };

  const checkCompleted = () => {
    let flag = true;
    updateAudit.auditTopics.forEach((t) => {
      const totals = getNumAnswers(t.questions);
      if (totals.countMissing > 0) {
        flag = false;
      }
    });
    return flag;
  };

  // Submit the audit and send mutation
  const submitForm = () => {
    if (checkCompleted()) {
      setChecked(false);
      if (type === "EDIT") {
        const input = {
          audit: Number(updateAudit.audit.id),
          ownerSiteProject: Number(updateAudit.ospId),
          removeImages: updateAudit.removeImages,
          responses: formatResponses(updateAudit.auditResponses),
          files: formatFiles(updateAuditImages),
          penalty: totalPenaltyCalc().totalPenalty,
          raScore: updateAudit.raScore,
          users: updateAudit.contributors.map((user) => {
            return {
              id: Number(user.id),
            };
          }),
        };
        editAuditMutation({
          variables: { id: Number(updateAudit.id), input },
        });

        if (!online) {
          updateSnackbar({
            open: true,
            message:
              "Your device is offline. Your audit will be edited when back online.",
            severity: "info",
          });
        }
      } else {
        const input = {
          audit: Number(updateAudit.audit.id),
          ownerSiteProject: Number(updateAudit.ospId),
          responses: formatResponses(updateAudit.auditResponses),
          files: formatFiles(updateAuditImages),
          penalty: totalPenaltyCalc().totalPenalty,
          raScore: updateAudit.raScore,
          date: moment(updateAudit.date).format("YYYY-MM-DD"),
          time: moment(updateAudit.time).format("HH:mm:ss"),
          users: updateAudit.contributors.map((user) => {
            return {
              id: Number(user.id),
            };
          }),
        };
        createAuditMutation({
          variables: { input },
        });

        if (!online) {
          updateSnackbar({
            open: true,
            message:
              "Your device is offline. Your audit will be created when back online.",
            severity: "info",
          });
        }
      }
    } else {
      updateSnackbar({
        open: true,
        message: "Please answer all questions.",
        severity: "error",
      });
    }
  };

  // Clears form
  const clearForm = () => {
    if (type === "EDIT") {
      navigate(`/view-audit/${updateAudit.id}`);
    } else {
      setStartAudit(false);
      updateAllAuditFunction(initialState);
      updateAuditImagesFunction([]);
    }
  };

  const getSortedTopics = () => {
    const copy = [...updateAudit.auditTopics];
    return copy.sort((a, b) => a.ordering - b.ordering);
  };

  return (
    <Grid sx={{ marginBottom: { xs: "75px", md: "50px" } }}>
      <Paper
        elevation={0}
        style={{ padding: "40px 20px 10px 10px", backgroundColor: "#333" }}
      >
        <Grid container justifyContent="space-between">
          <Grid
            item
            xs={5.5}
            md={2.5}
            style={{
              border: "1px solid #FFB700",
              padding: "20px",
              textAlign: "center",
              backgroundColor: "#333",
              color: "#fff",
              width: "100%",
              height: "100%",
              backgroundColor: "inherit",
              borderRadius: "10px",
              boxShadow: "10px -10px 20px rgb(0, 0, 0, .5)",
              overflow: "hidden",
              height: "auto",
            }}
          >
            <Typography sx={{ color: "#FFB700" }}>LOCATION</Typography>
            <Typography>{updateAudit.location}</Typography>
          </Grid>
          <Grid
            item
            xs={5.5}
            md={2.5}
            style={{
              border: "1px solid #FFB700",
              padding: "20px",
              textAlign: "center",
              backgroundColor: "#333",
              color: "#fff",
              width: "100%",
              height: "100%",
              backgroundColor: "inherit",
              borderRadius: "10px",
              boxShadow: "10px -10px 20px rgb(0, 0, 0, .5)",
              overflow: "hidden",
              height: "auto",
            }}
          >
            <Typography sx={{ color: "#FFB700" }}>Date</Typography>
            {type === "EDIT" ? (
              <Typography>{`${moment(
                new Date(`${updateAudit.date} ${updateAudit.time}`)
              ).format("MM/DD/YYYY hh:mm A")}`}</Typography>
            ) : (
              <Typography>{`${moment(new Date(updateAudit.date)).format(
                "MM/DD/YYYY"
              )} ${moment(new Date(updateAudit.time)).format(
                "hh:mm A"
              )}`}</Typography>
            )}
          </Grid>
          <Grid
            item
            xs={5.5}
            md={2.5}
            style={{
              border: "1px solid #FFB700",
              padding: "20px",
              textAlign: "center",
              backgroundColor: "#333",
              color: "#fff",
              width: "100%",
              minheight: "100%",
              backgroundColor: "inherit",
              borderRadius: "10px",
              boxShadow: "10px -10px 20px rgb(0, 0, 0, .5)",
              overflow: "hidden",
              height: "auto",
            }}
          >
            <Typography sx={{ color: "#FFB700" }}>
              RISK EXPOSURE SCORE
            </Typography>
            <Typography>
              {updateAudit.raScore ? `${updateAudit.raScore}%` : ""}
            </Typography>
          </Grid>
          <Grid
            item
            xs={5.5}
            md={2.5}
            style={{
              border: "1px solid #FFB700",
              padding: "20px",
              textAlign: "center",
              backgroundColor: "#333",
              color: "#fff",
              width: "100%",
              minheight: "100%",
              backgroundColor: "inherit",
              borderRadius: "10px",
              boxShadow: "10px -10px 20px rgb(0, 0, 0, .5)",
              overflow: "hidden",
              height: "auto",
            }}
          >
            {renderPenalty()}
          </Grid>
        </Grid>
        <Authors type={type} />
      </Paper>
      <Typography
        style={{
          color: "white",
          marginBottom: "5px",
          marginLeft: "16px",
          fontSize: "1.2rem",
        }}
      >
        {updateAudit.audit ? updateAudit.audit.name : ""}
      </Typography>
      {updateAudit.auditTopics ? (
        getSortedTopics().map((t, i) => {
          const totals = getNumAnswers(t.questions);
          return (
            <Accordion
              key={t.id}
              expanded={expanded === t.id}
              onChange={handleChange(t.id)}
              disableGutters
              sx={
                i === 0
                  ? {
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                    }
                  : i === updateAudit.auditTopics.length - 1
                  ? {
                      borderBottomRightRadius: "10px",
                      borderBottomLeftRadius: "10px",
                    }
                  : {}
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${t.name}-content`}
                id={`${t.name}-content`}
              >
                <Grid
                  item
                  xs={6}
                  sm={3}
                  sx={{ width: "100%" }}
                  justifyContent="center"
                >
                  <Typography
                    sx={{
                      flexShrink: 0,
                      padding: "0px 20px",
                      display: "inline",
                    }}
                  >
                    {t.name}
                  </Typography>
                  <RiskDropdown questions={t.questions} type={type} />
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  sm={9}
                  alignItems="center"
                  sx={{ width: "100%" }}
                >
                  <Grid item xs={12} sm={2.5}>
                    <Typography sx={{ color: "text.secondary" }}>
                      Yes: {totals.countYes}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2.5}>
                    <Typography sx={{ color: "text.secondary" }}>
                      No: {totals.countNo}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2.5}>
                    <Typography sx={{ color: "text.secondary" }}>
                      N/A: {totals.countNA}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4.5}>
                    <Typography sx={{ color: "text.secondary" }}>
                      Missing: {totals.countMissing}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <TopicQuestions
                  topic={t}
                  type={type}
                  topics={updateAudit.auditTopics}
                />
              </AccordionDetails>
            </Accordion>
          );
        })
      ) : (
        <Typography>Loading...</Typography>
      )}

      <Grid
        container
        justifyContent="space-between"
        style={{
          position: "fixed",
        }}
        sx={{
          width: { xs: "calc(100% - 40px)", md: "calc(100% - 70px)" },
          bottom: { xs: 90, md: 20 },
        }}
      >
        <Button variant="contained" sx={blackButton} onClick={clearForm}>
          {type === "EDIT" ? "CANCEL" : "CLEAR"}
        </Button>
        <Grid item>
          <Button
            variant="contained"
            sx={yellowButton}
            onClick={submitForm}
            disabled={!checked}
          >
            {type === "EDIT" ? "SAVE" : "SUBMIT"}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
