import * as React from "react";

import { useLazyQuery, useReactiveVar } from "@apollo/client";
import moment from "moment";

import { viewObservationFiltersVar } from "../../../../graphql/localVariables/observation";
import { onlineVar } from "../../../../graphql/localVariables/user";
import { observationMutations } from "../../../../graphql/mutations";
import { GET_OBSERVATIONS } from "../../../../graphql/queries/observations";
import TableMain from "./ReusableTableLayout";
import ReusableTableToolbar from "./ReusableTableToolbar";
import TableBodyTemplate from "./TableBodyTemplate";
import TableHeadTemplate from "./TableHeadTemplate";

// Props
// hasPagination - boolean
// data - array of objects
// 3 children component
//    If using the ReusableTableToolbar
//    - title - string - displayed at the top
//    - onDeleteClick - when trash can on toolbar is clicked
//    - onSearchChange - when input in search bar
//    - searchTerm - string
export default function Table() {
  const online = useReactiveVar(onlineVar);
  const [getObs, { data, loading, refetch }] = useLazyQuery(GET_OBSERVATIONS, {
    fetchPolicy: online ? "network-only" : "cache-only",
    notifyOnNetworkStatusChange: true,
  });
  const { updateAllViewObservationFilter } = observationMutations;
  const filters = useReactiveVar(viewObservationFiltersVar);
  const [initialLoad, setInitialLoad] = React.useState(false);

  const clearFilters = () => {
    updateAllViewObservationFilter({
      site: { owner: { name: "" } },
      contractor: { contractor: { name: "" } },
      afterDate: null,
      beforeDate: null,
      outcomes: [],
      status: "",
      usernames: [],
    });
  };

  const getInput = () => {
    return {
      name: filters.searchterm ? filters.searchterm : "",
      owner:
        filters.site && filters.site.owner && filters.site.owner.id
          ? Number(filters.site.owner.id)
          : null,
      site:
        filters.site && filters.site.site ? Number(filters.site.site.id) : null,
      afterDate: filters.afterDate
        ? moment(filters.afterDate).format("YYYY-MM-DD")
        : null,
      beforeDate: filters.beforeDate
        ? moment(filters.beforeDate).format("YYYY-MM-DD")
        : null,
      contractor:
        filters.contractor &&
        filters.contractor.contractor &&
        filters.contractor.contractor.id
          ? Number(filters.contractor.contractor.id)
          : null,
      submitter:
        filters.usernames && filters.usernames.length > 0
          ? filters.usernames.map((u) => Number(u.id))
          : null,
      outcome:
        filters.outcomes && filters.outcomes.length > 0
          ? filters.outcomes.map((o) => Number(o.id))
          : null,
      planned:
        !filters.status || filters.status === ""
          ? null
          : filters.status === "Planned",
    };
  };

  const onFilter = () => {
    const input = {
      first: 50,
      ...getInput(),
    };

    refetch(input);
  };

  const fetchMore = (obj) => {
    const input = {
      after: obj.after,
      first: obj.first,
      ...getInput(),
    };

    refetch(input);
  };

  React.useEffect(() => {
    getObs({
      variables: { first: 50, ...getInput() },
      onCompleted() {
        setInitialLoad(true);
      },
    });
  }, []);

  React.useEffect(() => {
    if (filters.searchterm && initialLoad) {
      onFilter();
    }
  }, [filters.searchterm]);

  return (
    <>
      <TableMain
        CustomTableHeader={<TableHeadTemplate />}
        CustomTableBody={<TableBodyTemplate />}
        CustomTableToolBar={
          <ReusableTableToolbar
            clearFilters={clearFilters}
            onFilter={onFilter}
            title={"Observations"}
          />
        }
        hasPagination={true}
        allData={data}
        data={
          data && data.observations && data.observations.edges
            ? data.observations.edges.map((e) => e.node)
            : []
        }
        fetchMore={fetchMore}
        loading={loading}
      />
    </>
  );
}
