const grey = "#8297a0";
const greyHover = "#8297a050";
const yellow = "#FFB700";

export const blueButton = {
  color: "white",
  backgroundColor: grey,
  width: "fit-content",
  "&:hover": {
    backgroundColor: greyHover,
  },
};

export const blackButton = {
  backgroundColor: "#171717",
  color: "white",
  width: "fit-content",
  fontSize: "0.875rem",
  boxShadow:
    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
  "&:hover": {
    backgroundColor: "#00000050",
  },
};

export const yellowButton = {
  backgroundColor: yellow,
  color: "black",
  width: "fit-content",
  "&:hover": {
    backgroundColor: yellow,
  },
};
