import React from "react";
import { useNavigate } from "react-router-dom";

import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import moment from "moment";

import { initialState } from "../../../graphql/localVariables/observation";
import { createObservationVar } from "../../../graphql/localVariables/observation";
import { onlineVar } from "../../../graphql/localVariables/user";
import {
  authMutations,
  observationMutations,
} from "../../../graphql/mutations";
import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import { onErrorFunc } from "../../CustomComponents/OnErrorFunction";
import Layout from "../../Layout";
import ObservationForm from "../Form";

export default function CreateObservation() {
  const navigate = useNavigate();
  const online = useReactiveVar(onlineVar);

  const { updateKeyCreateObservation } = observationMutations;

  // Queries
  const { data: userData } = useQuery(GET_CURRENT_USER);

  // Mutations Callbacks
  const observationFail = (error) => {
    updateKeyCreateObservation("disabled", false);
    onErrorFunc(error);
  };

  const observationEditSuccess = (success) => {
    if (!success.observationCreate.observation) {
      observationFail("There was an error when creating your observation.");
      updateKeyCreateObservation("disabled", false);
    } else {
      resetForm();
      updateSnackbar({
        open: true,
        message: "Observation was saved successfully!",
        severity: "success",
      });
      navigate(`/view-obs/${success.observationCreate.observation.obsId}`);
    }
  };

  // Mutations
  const { updateSnackbar, updateLoading } = authMutations;
  const { OBSERVATION_CREATE, updateAllCreateObservation } =
    observationMutations;
  const [submitCreateObservation, { loading }] = useMutation(
    OBSERVATION_CREATE,
    {
      context: {
        serializationKey: "MUTATION",
      },
      onCompleted: observationEditSuccess,
      onError: observationFail,
    }
  );

  // Local Variables
  const [expanded, setExpanded] = React.useState(1);
  const [currentSection, setCurrentSection] = React.useState(1);

  // Clear Form
  const resetForm = () => {
    setCurrentSection(1);
    setExpanded(1);
    updateAllCreateObservation({
      ...initialState,
      date: new Date(),
      time: new Date(),
    });
  };

  const getFileType = (file) => {
    if (file.name) {
      return file.name.split(".").pop();
    } else if (file.type) {
      return file.type.split("/").pop();
    } else return "";
  };

  const formatFiles = (files) => {
    let newArray = [];
    files.forEach((f, index) => {
      if (f.originalFile && !f.originalFile.id) {
        const fileType = getFileType(f.originalFile);
        const newFile = new File(
          [f.originalFile],
          fileType !== ""
            ? `image_${f.associatedItem.id}_${index}.${fileType}`
            : `image_${f.associatedItem.id}_${index}`,
          {
            type: f.originalFile.type,
            size: f.originalFile.size,
          }
        );
        newArray.push(newFile);
      }
    });

    return newArray;
  };

  // Submit Mutation
  const executeSubmit = () => {
    const form = createObservationVar();
    if (userData?.currentUser) {
      const input = {
        submitter: Number(userData.currentUser.id),
        ownerSiteProject: Number(form.ospId),
        date: moment(form.date).format("YYYY-MM-DD"),
        time: moment(form.time).format("HH:mm:ss"),
        files: formatFiles(form.files),
        dollarAmount: Number(form.dollarAmount),
        correctionsGiven: !![
          ...form.actions,
          ...form.risks,
          ...form.mitigators,
          ...form.unselectedMitigators.filter(
            (m) => m.isCorrection || m.isRootCause
          ),
        ].find((arm) => arm.isCorrection === true),
        raScore: form.raScore,
        raScoreMax: form.raScoreMax,
        raScoreCorr: form.raScoreCorr,
        raScoreMin: form.raScoreMin,
        manualCheck: form.outcomeUnplanned,
        users: form.foreman.map((user) => {
          return {
            id: Number(user.id),
          };
        }),
        crewMembers: form.crewMembers.map((user) => {
          return {
            id: Number(user.crewMember.id),
          };
        }),
        comment: form.correctionComments,
        outcomeExpected: form.outcomeExpected,
        outcomeInjury: form.outcomeInjury,
        outcomeNearMiss: form.outcomeNearMiss,
        contractor: Number(form.contractor.id),
        arms: [
          ...form.actions,
          ...form.risks,
          ...form.mitigators,
          ...form.unselectedMitigators.filter(
            (m) => m.isCorrection || m.isRootCause
          ),
        ].map((arm) => {
          let obj = { id: Number(arm.id), isActive: arm.isActive };
          if (arm.isRootCause) {
            obj = {
              ...obj,
              isRootCause: arm.isRootCause,
            };
          }
          if (arm.isCorrection) {
            obj = {
              ...obj,
              isCorrection: arm.isCorrection,
            };
          }
          if (arm.comment) {
            obj = { ...obj, comment: arm.comment };
          }
          return obj;
        }),
        outcomes: form.outcomes.map((outcome) => {
          return { id: Number(outcome.id) };
        }),
        ...(form.ha?.haId && { ha: Number(form.ha.haId) }),
      };

      submitCreateObservation({
        variables: {
          input,
        },
      });

      if (!online) {
        updateSnackbar({
          open: true,
          message:
            "Your device is offline. Your observation will be created when back online.",
          severity: "info",
        });
      }
    } else onErrorFunc("Something went wrong, please refresh.");
  };

  // Show Loading wheel
  React.useEffect(() => {
    if (online) {
      updateLoading(loading);
    }
  }, [loading]);

  return (
    <Layout>
      <ObservationForm
        type={"CREATE"}
        executeSubmit={executeSubmit}
        resetForm={resetForm}
        expanded={expanded}
        setExpanded={setExpanded}
        setCurrentSection={setCurrentSection}
        currentSection={currentSection}
        riskThreshold={userData?.currentUser.company?.riskThreshold}
      />
    </Layout>
  );
}
