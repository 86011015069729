/* eslint-disable no-console */

/* eslint-disable quotes */
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import {
  Alert,
  CircularProgress,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";

import { useApolloClient, useLazyQuery, useReactiveVar } from "@apollo/client";
import axios from "axios";

import AuthorizationProtectedRoute from "./AuthorizationProtectedRoute";
import LoggedInRedirection from "./LoggedInRedirection";
import ARM from "./components/Admin/ARM";
import ActionPage from "./components/Admin/ARM/Action/Action";
import Approvals from "./components/Admin/ARM/Approvals";
import CategoryPage from "./components/Admin/ARM/Category/Category";
import Mitigator from "./components/Admin/ARM/Mitigator/Mitigator";
import Risk from "./components/Admin/ARM/Risk/Risk";
import Unscored from "./components/Admin/ARM/Unscored";
import Audits from "./components/Admin/Audits";
import Company from "./components/Admin/Company";
import ViewCompanies from "./components/Admin/Company/View";
import Industry from "./components/Admin/Industry/Classification";
import Permissions from "./components/Admin/Permissions";
import Project from "./components/Admin/Planning/Project";
import Site from "./components/Admin/Planning/Site";
import Template from "./components/Admin/Planning/Template";
import Supervisors from "./components/Admin/Supervisors";
import UserAdmin from "./components/Admin/User";
import CreateAudit from "./components/Audit/Create/CreateAudit";
import EditAudit from "./components/Audit/Edit/EditAudit";
import ViewAuditPDF from "./components/Audit/View/PDF";
import ViewAudit from "./components/Audit/View/ViewAudit";
import CrewMemberSignUp from "./components/CrewMemberSignUp";
import CustomSnackbar from "./components/CustomComponents/Snackbar";
import Dashboard from "./components/Dashboard";
import ForgotPassword from "./components/ForgotPassword";
import CreateHAForm from "./components/HA/Create";
import EditHAForm from "./components/HA/Edit";
import ViewHA from "./components/HA/View";
import ViewHAPDF from "./components/HA/View/PDF";
import CreateInvestigation from "./components/Investigation/Create";
import EditInvestigation from "./components/Investigation/Edit";
import ViewInvestigation from "./components/Investigation/View";
import ViewSingleInvestigation from "./components/Investigation/View/ViewSingleInvestigation";
import Login from "./components/Login";
import CreateObservation from "./components/Observations/Create";
import EditObservation from "./components/Observations/Edit";
import ViewObservation from "./components/Observations/View";
import ViewSingleObservation from "./components/Observations/View/ViewSingleObservation";
import PasswordReset from "./components/PasswordReset";
import CustomReporting from "./components/Reporting/Custom/CustomReporting";
import UserSettings from "./components/UserSettings/UserSettings";
import { apiUrl } from "./config";
import { authVar, loadingVar, onlineVar } from "./graphql/localVariables/user";
import { authMutations } from "./graphql/mutations";
import { GET_CURRENT_USER } from "./graphql/queries/auth";
import {
  GET_COMPANY_ARMS,
  GET_AUDITS,
  GET_CONTRACTORS_AT_OWNER_SITE_PROJECT,
  GET_CREW_PAGINATED,
  GET_OSP,
  GET_OUTCOMES,
  GET_PROJECTS,
  GET_SITES,
  GET_HA_TEMPLATES_PAGINATED,
} from "./graphql/queries/offlineMode";

axios.defaults.baseURL = apiUrl;
axios.defaults.withCredentials = true;
axios.defaults.headers.get["Content-Type"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";

function useInterval(callback, delay) {
  const savedCallback = React.useRef();

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default function App() {
  const client = useApolloClient();
  const { updateAuth, updateOnline } = authMutations;
  const loading = useReactiveVar(loadingVar);
  const online = useReactiveVar(onlineVar);
  const auth = useReactiveVar(authVar);

  const { updatePermissions } = authMutations;
  const [progression, setProgression] = React.useState(false);

  const [getCurrentUser, { data: user }] = useLazyQuery(GET_CURRENT_USER, {
    onCompleted(data) {
      let permissions = [];
      data.currentUser.userPermissionGroups.forEach((upg) => {
        upg.permissionGroup.permissionGroupPermissions.forEach((pgp) =>
          permissions.push(pgp.permission.name)
        );
      });
      updatePermissions([...new Set(permissions)]);
      console.log(
        "Updated user permissions",
        [...new Set(permissions)].sort((a, b) => (a > b ? 1 : -1))
      );
    },
  });

  const [getActions, { loading: loadingActions }] =
    useLazyQuery(GET_COMPANY_ARMS);
  const [getRisks, { loading: loadingRisks }] = useLazyQuery(GET_COMPANY_ARMS);
  const [getMitigators, { loading: loadingMit }] =
    useLazyQuery(GET_COMPANY_ARMS);
  const [getAudits, { loading: loadingAudit }] = useLazyQuery(GET_AUDITS);
  const [getContractors, { loading: loadingContractors }] = useLazyQuery(
    GET_CONTRACTORS_AT_OWNER_SITE_PROJECT
  );
  const [getCrewmembers, { loading: loadingCrew }] = useLazyQuery(
    GET_CREW_PAGINATED,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted(data) {
        if (data.paginatedCrewMembers?.pageInfo?.hasNextPage) {
          console.log("Getting next page of crewmembers...");
          getCrewmembers({
            variables: {
              after: data.paginatedCrewMembers?.pageInfo?.endCursor,
            },
          });
        }
      },
    }
  );
  const [getOSP, { loading: loadingOSP }] = useLazyQuery(GET_OSP);
  const [getObsOutcomes, { loading: loadingOutcomes }] =
    useLazyQuery(GET_OUTCOMES);
  const [getProjects, { loading: loadingProjects }] =
    useLazyQuery(GET_PROJECTS);
  const [getSites, { loading: loadingSites }] = useLazyQuery(GET_SITES);
  const [getHATemplates, { loading: loadingHATemplates }] = useLazyQuery(
    GET_HA_TEMPLATES_PAGINATED,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted(data) {
        if (data.paginatedOwnerSiteProjectTemplatesHa?.pageInfo?.hasNextPage) {
          console.log("Getting next page of HA templates...");
          getHATemplates({
            variables: {
              ospIdList: null,
              after:
                data.paginatedOwnerSiteProjectTemplatesHa?.pageInfo?.endCursor,
            },
          });
        }
      },
    }
  );

  const [rootQuery, setRootQuery] = React.useState(null);

  const loadingOfflineMode =
    loadingActions ||
    loadingRisks ||
    loadingMit ||
    loadingAudit ||
    loadingContractors ||
    loadingCrew ||
    loadingOSP ||
    loadingOutcomes ||
    loadingProjects ||
    loadingSites ||
    loadingHATemplates;

  // Add new paths here
  const routePaths = [
    { path: "/", component: <Dashboard /> },
    { path: "/create-obs", component: <CreateObservation /> },
    { path: "/view-obs", component: <ViewObservation /> },
    { path: "/view-obs/:id", component: <ViewSingleObservation /> },
    { path: "/edit-obs/:id", component: <EditObservation /> },

    { path: "/create-ha", component: <CreateHAForm /> },
    { path: "/view-ha", component: <ViewHA /> },
    { path: "/view-ha/:id", component: <ViewHAPDF /> },
    { path: "/edit-ha/:id", component: <EditHAForm /> },

    { path: "/create-audit", component: <CreateAudit /> },
    { path: "/view-audit", component: <ViewAudit /> },
    { path: "/view-audit/:id", component: <ViewAuditPDF /> },
    { path: "/edit-audit/:id", component: <EditAudit /> },

    { path: "/create-incident", component: <CreateInvestigation /> },
    { path: "/view-incident", component: <ViewInvestigation /> },
    { path: "/view-incident/:id", component: <ViewSingleInvestigation /> },
    { path: "/edit-incident/:id", component: <EditInvestigation /> },

    { path: "/admin/user", component: <UserAdmin /> },
    { path: "/admin/arm", component: <ARM /> },
    { path: "/admin/arm/category/:id", component: <CategoryPage /> },
    { path: "/admin/arm/action/:id", component: <ActionPage /> },
    { path: "/admin/arm/risk/:id", component: <Risk /> },
    { path: "/admin/arm/mitigator/:id", component: <Mitigator /> },
    { path: "/admin/arm/approvals", component: <Approvals /> },
    { path: "/admin/arm/:companyId/approvals", component: <Approvals /> },
    { path: "/admin/arm/unscored", component: <Unscored /> },
    { path: "/admin/arm/:companyId", component: <ARM /> },
    { path: "/admin/arm/:companyId/category/:id", component: <CategoryPage /> },
    { path: "/admin/arm/:companyId/action/:id", component: <ActionPage /> },
    { path: "/admin/arm/:companyId/risk/:id", component: <Risk /> },
    { path: "/admin/arm/:companyId/mitigator/:id", component: <Mitigator /> },
    { path: "/admin/permission", component: <Permissions /> },
    { path: "/admin/company", component: <Company /> },
    { path: "/admin/company/:id", component: <Company /> },
    { path: "/admin/company/view", component: <ViewCompanies /> },
    { path: "/admin/industry", component: <Industry /> },
    { path: "/admin/planning/project", component: <Project /> },
    { path: "/admin/planning/site", component: <Site /> },
    { path: "/admin/planning/template", component: <Template /> },
    { path: "/admin/audits", component: <Audits /> },
    { path: "/admin/supervisors", component: <Supervisors /> },

    { path: "/user-settings", component: <UserSettings /> },

    { path: "/reporting/custom", component: <CustomReporting /> },
  ];

  const verify = (online) => {
    const path = window.location.pathname;
    if (
      !(
        path.includes("forgot-password") ||
        path.includes("pw-reset") ||
        path.includes("login")
      )
    ) {
      axios
        .post("/auth/jwt/refresh/")
        .then(() => {
          // updateAuth(true);
        })
        .catch(() => {
          if (online) {
            updateAuth(false);
          }
        });
    }
  };

  useInterval(() => verify(online), 1 * 60 * 1000); // every 1 minute

  useInterval(() => {
    fetch("https://dummyjson.com/products/1")
      .then(() => {
        if (!online) {
          setOnline();
        }
      })
      .catch(() => {
        if (online) {
          setOffline();
        }
      });
  }, 0.5 * 60 * 1000); // every 0.5 minutes

  React.useEffect(() => {
    setProgression(loading);
  }, [loading]);

  React.useEffect(() => {
    if (auth.isAuthenticated) {
      console.log("CALLING CURRENT USER.");
      getCurrentUser();
    }
  }, [auth.isAuthenticated]);

  React.useEffect(() => {
    const serializedState = client.cache.extract();
    setRootQuery(serializedState.ROOT_QUERY);
  }, [client.cache.extract()]);

  React.useEffect(() => {
    if (online && user && auth.isAuthenticated && rootQuery) {
      // Check if cache is empty
      if (
        !rootQuery['outcomes({"isActive":true})'] &&
        !rootQuery[
          'companyArms({"arm_IsActive":true,"arm_M_Isnull":false,"arm_R_Isnull":false,"company_Id":436,"isActive":true})'
        ] &&
        !rootQuery.paginatedCrewMembers
      ) {
        console.log("CALLING CACHE QUERIES.");
        getCrewmembers();
        const companyId = Number(user.currentUser.company.id);
        getActions({
          variables: { companyId, rNull: true, mNull: true },
        });
        getRisks({
          variables: { companyId, rNull: false, mNull: true },
        });
        getMitigators({
          variables: { companyId, rNull: false, mNull: false },
        });
        getAudits();
        getContractors();
        getOSP();
        getObsOutcomes();
        getProjects();
        getSites();
        getHATemplates();
      } else console.log("Cache has already been filled.");
    }
  }, [online, user, auth.isAuthenticated, rootQuery]);

  const setOnline = () => {
    console.log("We are online!");
    updateOnline(true);

    // refresh token now online
    verify(online);
  };
  const setOffline = () => {
    console.log("We are offline!");
    updateOnline(false);
  };

  // Register the event listeners
  React.useEffect(() => {
    window.addEventListener("offline", setOffline);
    window.addEventListener("online", setOnline);

    // cleanup if we unmount
    return () => {
      window.removeEventListener("offline", setOffline);
      window.removeEventListener("online", setOnline);
    };
  }, []);

  return (
    <div className="App">
      <CustomSnackbar />
      <Snackbar
        open={loadingOfflineMode}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="info" sx={{ width: "75%" }}>
          Loading data for offline mode. Please stay within Internet range until
          this is complete. This may take a few minutes.
        </Alert>
      </Snackbar>
      {progression && (
        <div
          style={{
            position: "absolute",
            width: "50%",
            height: "40px",
            top: "30%",
            left: "25%",
            textAlign: "center",
            zIndex: 10,
          }}
        >
          <CircularProgress
            sx={{ color: "rgb(255, 183, 0)" }}
            size={100}
            thickness={15}
          />
        </div>
      )}
      <Router>
        <Routes>
          <Route
            path="*"
            element={
              <Grid
                container
                sx={{ height: "100vh" }}
                justifyContent="center"
                alignItems="center"
              >
                <Typography style={{ fontSize: "2rem", color: "white" }}>
                  The URL you have requested is not found.
                </Typography>
              </Grid>
            }
          />
          {routePaths.map((r) => {
            return (
              <Route
                key={r.path}
                exact
                path={r.path}
                element={<AuthorizationProtectedRoute />}
              >
                <Route exact path={r.path} element={r.component} />
              </Route>
            );
          })}
          <Route exact path="/login" element={<LoggedInRedirection />}>
            <Route exact path="/login" element={<Login />} />
          </Route>
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/pw-reset/:string" element={<PasswordReset />} />
          <Route
            exact
            path="/crewmembers/:ospId/:siteId/:site/:owner"
            element={<CrewMemberSignUp />}
          />
        </Routes>
      </Router>
    </div>
  );
}
