import * as React from "react";

import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";

import {
  blackButton,
  blueButton,
} from "../../../../../../../CustomStyles/buttons";
import {
  Search,
  StyledInputBase,
  SearchIconWrapper,
} from "../../../../../../../CustomStyles/searchBar";

// PROPS
// searchTerm - string
// onSearchChange - function
// title - string
export default function ReusableTableToolbar(props) {
  const {
    numSelected,
    title,
    onSearchChange,
    searchTerm,
    clearFilters,
  } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <Toolbar
      sx={{
        pl: {
          sm: 2,
        },
        pr: {
          xs: 1,
          sm: 1,
        },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{
          flex: "1 1 100%",
        }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {title ? title : null}
      </Typography>
      <Tooltip title="Filter">
        <IconButton onClick={() => setOpen(true)}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogContent style={{ minHeight: "100px" }}></DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} style={{ ...blueButton }}>
            FILTER
          </Button>
          <Button onClick={clearFilters} style={{ ...blackButton }}>
            CLEAR
          </Button>
        </DialogActions>
      </Dialog>
      <Tooltip title="Search">
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{
              "aria-label": "search",
            }}
            value={searchTerm}
            onChange={onSearchChange}
          />
        </Search>
      </Tooltip>
    </Toolbar>
  );
}
