import React from "react";

import AttachFile from "@mui/icons-material/AttachFile";
import { Grid, TextField, InputAdornment } from "@mui/material";

import { useReactiveVar } from "@apollo/client";
import { DropzoneDialog } from "mui-file-dropzone";

import {
  createIncidentVar,
  editIncidentVar,
  editIncidentImagesVar,
  createIncidentImagesVar,
} from "../../../../graphql/localVariables/investigation";
import { investigationMutations } from "../../../../graphql/mutations";
import PreviewImages from "./PreviewImages";

//  Multiline
export default function Notes({ topic, type }) {
  // Global Variables
  const editIncident = useReactiveVar(editIncidentVar);
  const createIncident = useReactiveVar(createIncidentVar);
  const editIncidentImages = useReactiveVar(editIncidentImagesVar);
  const createIncidentImages = useReactiveVar(createIncidentImagesVar);
  const form = type === "EDIT" ? editIncident : createIncident;
  const updateIncidentImages =
    type === "EDIT" ? editIncidentImages : createIncidentImages;
  const [foundRes, setFoundRes] = React.useState({
    incidentTopic: { id: topic.id },
    note: "",
  });
  const [images, setImages] = React.useState([]);

  // Mutations
  const {
    updateKeyEditIncident,
    updateKeyCreateIncident,
    updateCreateIncidentImages,
    updateEditIncidentImages,
  } = investigationMutations;
  const updateKeyFunction =
    type === "EDIT" ? updateKeyEditIncident : updateKeyCreateIncident;
  const updateIncidentImagesFunction =
    type === "EDIT" ? updateEditIncidentImages : updateCreateIncidentImages;
  const [openUpload, setOpenUpload] = React.useState(false);

  const handleNoteUpdate = (event) => {
    setFoundRes((prev) => ({ ...prev, note: event.target.value }));
  };

  const handleBlur = () => {
    updateState("note", foundRes.note);
  };

  const updateState = (type, value) => {
    const filterArray = form.incidentTopicResponses.filter(
      (res) => topic.id !== res.incidentTopic?.id
    );
    updateKeyFunction("incidentTopicResponses", [
      ...filterArray,
      {
        ...foundRes,
        [type]: value,
      },
    ]);
  };

  React.useEffect(() => {
    setImages(
      updateIncidentImages.filter(
        (i) => Number(i.topicId) === Number(foundRes.incidentTopic?.id)
      )
    );
  }, [foundRes, updateIncidentImages]);

  React.useEffect(() => {
    const doesExist = form.incidentTopicResponses.find(
      (res) => topic.id == res.incidentTopic?.id
    );
    if (doesExist) {
      setFoundRes(doesExist);
    } else {
      setFoundRes({
        incidentTopic: { id: topic.id },
        note: "",
      });
    }
  }, [form.incidentTopicResponses]);

  const handleFilesChange = (value) => {
    updateIncidentImagesFunction([
      ...updateIncidentImages.filter(
        (i) => Number(i.topicId) !== Number(foundRes.incidentTopic?.id)
      ),
      ...value,
    ]);
  };

  const removeImage = (value) => {
    updateKeyFunction("removeFiles", [...form.removeFiles, Number(value)]);
  };

  const handleSave = (files) => {
    setOpenUpload(false);
    // updating question state
    const arr = images ? images : [];
    const filesArr = files.map((f) => ({
      file: f,
      topicId: foundRes.incidentTopic?.id,
    }));

    handleFilesChange([...arr, ...filesArr]);
  };

  return (
    <Grid item xs={12} sx={{ padding: "10px", width: "100%" }}>
      <TextField
        id={`${topic.id}-notes`}
        value={foundRes.note}
        variant="standard"
        label={"Additional notes and files"}
        onChange={handleNoteUpdate}
        onBlur={handleBlur}
        multiline={true}
        style={{
          width: "100%",
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ cursor: "pointer" }}>
              <AttachFile
                fontSize="medium"
                onClick={() => setOpenUpload(true)}
              />
            </InputAdornment>
          ),
        }}
      />
      <DropzoneDialog
        open={openUpload}
        onSave={handleSave}
        showPreviews={true}
        onClose={() => setOpenUpload(false)}
        maxFileSize={1000000000} // 1000MB
      />
      {images && (
        <PreviewImages
          fileObjects={images}
          type={type}
          handleFilesChange={handleFilesChange}
          removeImage={removeImage}
        />
      )}
    </Grid>
  );
}
