import React from "react";
import { useNavigate } from "react-router-dom";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  List,
  ListItemText,
  Typography,
  ListItemButton,
  ListItemAvatar,
  Grid,
  Menu,
} from "@mui/material";

import { useApolloClient } from "@apollo/client";
import axios from "axios";

import { apiUrl } from "../../config";
import handleLogout from "./handleLogout";

export default function AccountItems({
  iconSelectedColor,
  classes,
  // isSuperuser,
  user,
}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const client = useApolloClient();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    axios
      .post(`${apiUrl}auth/jwt/logout/`)
      .then(() => handleLogout(client))
      .catch(() => handleLogout(client));
  };

  // let superAdminControlItem;
  // if (isSuperuser) {
  //   superAdminControlItem = (
  //     <ListItemButton onClick={() => navigate("/super-admin-controls")}>
  //       <ListItemText
  //         primary="Super Admin Controls"
  //         className={classes.listText}
  //       />
  //     </ListItemButton>
  //   );
  // }

  // let onboardingCreateItem;
  // if (isSuperuser) {
  //   onboardingCreateItem = (
  //     <ListItemButton onClick={() => navigate("/safety-audit")}>
  //       <ListItemText
  //         primary="Onboarding Create"
  //         className={classes.listText}
  //       />
  //     </ListItemButton>
  //   );
  // }

  // let onboardingReviewItem;
  // if (isSuperuser) {
  //   onboardingReviewItem = (
  //     <ListItemButton onClick={() => navigate("/review-safety-audit")}>
  //       <ListItemText
  //         primary="Onboarding Review"
  //         className={classes.listText}
  //       />
  //     </ListItemButton>
  //   );
  // }

  return (
    <div>
      <List>
        <ListItemButton onClick={handleClick}>
          <Grid container alignItems="center" direction="row">
            <Grid item xs={3}>
              <ListItemAvatar>
                <AccountCircleIcon
                  style={{
                    color: iconSelectedColor,
                    fontSize: "42px",
                  }}
                />
              </ListItemAvatar>
            </Grid>
            <Grid item xs={8} container direction="column">
              <Typography style={{ color: "white" }}>
                {`${user?.currentUser.firstName} ${user?.currentUser.lastName}`}
              </Typography>
              <Typography style={{ color: "white", fontSize: "14px" }}>
                {user?.currentUser.username}
              </Typography>
            </Grid>
            <Grid item xs={1} container direction="column">
              <KeyboardArrowUpIcon style={{ color: "white" }} />
              <KeyboardArrowDownIcon style={{ color: "white" }} />
            </Grid>
          </Grid>
        </ListItemButton>
      </List>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List sx={{ padding: 0 }}>
          <ListItemButton onClick={() => navigate("/user-settings")}>
            <ListItemText
              primary="User Settings"
              className={classes.listText}
            />
          </ListItemButton>
          <ListItemButton onClick={logout}>
            <ListItemText primary="Logout" className={classes.listText} />
          </ListItemButton>
          {/* {superAdminControlItem}
          {onboardingCreateItem}
          {onboardingReviewItem} */}
        </List>
      </Menu>
    </div>
  );
}
