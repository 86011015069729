export default function removeDuplicates() {
  const arr = arguments[0];

  const uniqueIds = [];
  const filtered = arr.filter((item) => {
    let itemId = item;
    let i = 1;

    while (i < arguments.length) {
      itemId = itemId[arguments[i]];
      i++;
    }

    const isDuplicate = uniqueIds.includes(itemId);
    if (!isDuplicate) {
      uniqueIds.push(itemId);
      return true;
    }
    return false;
  });
  return filtered;
}
