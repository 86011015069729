import * as React from "react";

import TableCell from "@mui/material/TableCell";;

// Props
// order, orderBy, headCell (data), createSortHandler
export default function ReusableTableHeadCell(props) {
  const { order, orderBy, headCell } = props;

  return (
    <TableCell
      key={headCell.uid}
      padding={headCell.disablePadding ? "none" : "normal"}
      sortDirection={orderBy === headCell.uid ? order : false}
    >
      {headCell.label}
    </TableCell>
  );
}
