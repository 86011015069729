import React from "react";

import {
  Grid,
  TextField,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";

import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";

import { createHAVar, editHAVar } from "../../../graphql/localVariables/ha";
import { onlineVar } from "../../../graphql/localVariables/user";
import { haMutations } from "../../../graphql/mutations";
import { GET_SITES } from "../../../graphql/queries";
import { GET_OFFLINE_OSP, GET_OWNER_OSP_ID } from "../../../graphql/queries/ha";
import { useStyles } from "./styles";

export default function LocationInputView({ type }) {
  const editHA = useReactiveVar(editHAVar);
  const createHA = useReactiveVar(createHAVar);
  const updateHA = type === "EDIT" ? editHA : createHA;
  const online = useReactiveVar(onlineVar);
  const { updateCreateHA, updateEditHA } = haMutations;
  const updateHAFunction = type === "EDIT" ? updateEditHA : updateCreateHA;

  const { data, loading: loadingSites } = useQuery(GET_SITES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const [getOSP] = useLazyQuery(GET_OWNER_OSP_ID, {
    onCompleted(data) {
      updateHAFunction("ownerOSPId", Number(data.ownerSiteProjects[0].id));
    },
  });
  const [getOfflineOSP] = useLazyQuery(GET_OFFLINE_OSP, {
    onCompleted(data) {
      console.log("Owner Offline OSP", data);
      if (data.filterOSP.length > 0) {
        updateHAFunction("ownerOSPId", Number(data.filterOSP[0].id));
      } else console.log("No offline OSP!");
    },
  });
  const classes = useStyles()();

  const handleChange = (event, newValue) => {
    if (newValue) {
      updateHAFunction("siteId", newValue.site.id);
      updateHAFunction("ownerId", newValue.owner.id);
      updateHAFunction("location", newValue.location);
      updateHAFunction("siteOSPId", newValue.id);
      if (online) {
        getOSP({ variables: { ownerId: Number(newValue.owner.id) } });
      } else {
        getOfflineOSP({ variables: { ownerId: Number(newValue.owner.id) } });
      }
    } else {
      updateHAFunction("siteId", null);
      updateHAFunction("ownerId", null);
      updateHAFunction("location", "");
      updateHAFunction("siteOSPId", null);
      updateHAFunction("ownerOSPId", null);
    }

    updateHAFunction("projects", []);
  };

  const filterOptions = createFilterOptions({
    stringify: (option) => option.location,
  });

  return (
    <Grid
      item
      key={"location"}
      xs={12}
      sm={6}
      lg={3}
      className={classes.borderedBox}
    >
      <Autocomplete
        disablePortal
        filterOptions={filterOptions}
        id="ha-select-location"
        options={
          data && data.ownerSiteProjects
            ? [...data.ownerSiteProjects].sort((a, b) =>
                a.location > b.location ? 1 : -1
              )
            : []
        }
        value={updateHA.location ? updateHA.location : { location: "" }}
        getOptionLabel={(option) => {
          return option.location === undefined ? option : option.location;
        }}
        renderOption={(props, option) => (
          <li {...props} key={`${option.location}-${option.id}`}>
            {option.location}
          </li>
        )}
        loading={loadingSites}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) =>
          value.location === ""
            ? true
            : option.location === value.location || option.location === value
        }
        renderInput={(params) => (
          <TextField {...params} variant="standard" label={"Location:"} />
        )}
      />
    </Grid>
  );
}
