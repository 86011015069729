import React from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Typography } from "@mui/material";

import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import moment from "moment";

import {
  editObservationVar,
  initialState,
} from "../../../graphql/localVariables/observation";
import { onlineVar } from "../../../graphql/localVariables/user";
import {
  authMutations,
  observationMutations,
} from "../../../graphql/mutations";
import { GET_SINGLE_OBSERVATION } from "../../../graphql/queries/observations";
import { onErrorFunc } from "../../CustomComponents/OnErrorFunction";
import Layout from "../../Layout";
import ObservationForm from "../Form";

export default function EditObservation() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { updateKeyEditObservation } = observationMutations;

  // Queries
  const online = useReactiveVar(onlineVar);
  const {
    data: observation,
    refetch,
    loading: obFetch,
  } = useQuery(GET_SINGLE_OBSERVATION, {
    variables: { id: Number(id) },
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  // Mutations Callbacks
  const observationFail = (error) => {
    updateKeyEditObservation("disabled", false);
    onErrorFunc(error);
  };

  const observationEditSuccess = (success) => {
    if (!success.observationEdit.observation) {
      observationFail("There was an error when editing your observation.");
      updateKeyEditObservation("disabled", false);
    } else {
      resetForm();
      updateSnackbar({
        open: true,
        message: "Observation was saved successfully!",
        severity: "success",
      });
    }
  };

  // Mutations
  const { updateSnackbar, updateLoading } = authMutations;
  const { OBSERVATION_EDIT, updateAllEditObservation } = observationMutations;
  const [submitEditObservation, { loading }] = useMutation(OBSERVATION_EDIT, {
    context: {
      serializationKey: "MUTATION",
    },
    onCompleted: observationEditSuccess,
    onError: observationFail,
  });

  // Local Variables
  const [expanded, setExpanded] = React.useState(1);
  const [currentSection, setCurrentSection] = React.useState(6);

  // Clear Form
  const resetForm = () => {
    setCurrentSection(1);
    setExpanded(false);
    updateAllEditObservation({
      id: null,
      ...initialState,
    });
    navigate(`/view-obs/${id}`);
  };

  const formatFiles = (files) => {
    let newArray = [];

    files.forEach((f, index) => {
      if (!f.originalFile.id) {
        const newName = f.originalFile.name.split(".").pop();
        const newFile = new File(
          [f.originalFile],
          `image_${f.associatedItem.id}_${index}.${newName}`,
          {
            type: f.originalFile.type,
          }
        );
        newArray.push(newFile);
      }
    });

    return newArray;
  };

  const getEditObsInput = (form) => {
    const input = {
      submitter: Number(form.submitter.id),
      ownerSiteProject: Number(form.ospId),
      date: moment(form.date).format("YYYY-MM-DD"),
      time: moment(form.time).format("HH:mm:ss"),
      correctionsGiven: [
        ...form.actions,
        ...form.risks,
        ...form.mitigators,
        ...form.unselectedMitigators,
      ].find((arm) => arm.isCorrection === true)
        ? true
        : false,
      files: formatFiles(form.files),
      removeArmMediaFiles: form.removeMediaFiles
        ? form.removeMediaFiles.map((i) => Number(i))
        : [],
      removeMediaFiles: [],
      raScore: form.raScore,
      raScoreMax: form.raScoreMax,
      raScoreCorr: form.raScoreCorr,
      raScoreMin: form.raScoreMin,
      dollarAmount: Number(form.dollarAmount),
      manualCheck: form.outcomeUnplanned,
      contractor: Number(form.contractor.id),
      users: form.foreman.map((user) => {
        return {
          id: Number(user.id),
        };
      }),
      crewMembers: form.crewMembers.map((user) => {
        return {
          id: Number(user.crewMember.id),
        };
      }),
      comment: form.correctionComments,
      outcomeExpected: form.outcomeExpected,
      outcomeInjury: form.outcomeInjury,
      outcomeNearMiss: form.outcomeNearMiss,
      arms: [
        ...form.actions,
        ...form.risks,
        ...form.mitigators,
        ...form.unselectedMitigators,
      ].map((arm) => {
        return {
          id: Number(arm.id),
          isActive: arm.isActive,
          isCorrection: arm.isCorrection,
          isRootCause: arm.isRootCause,
        };
      }),
      outcomes: form.outcomes
        .filter(
          (o) =>
            ![
              "Non-Issue - Requiring Correction",
              "Safe",
              "Near Miss",
              "Unsafe Work Condition",
            ].includes(o.name)
        )
        .map((outcome) => {
          return { id: Number(outcome.id) };
        }),
      ...(form.ha && form.ha.haId && { ha: Number(form.ha.haId) }),
    };

    return input;
  };

  // Submit Mutation
  const executeSubmit = () => {
    const form = editObservationVar();
    const input = getEditObsInput(form);

    submitEditObservation({
      variables: {
        id: Number(id),
        input,
      },
    });

    if (!online) {
      updateSnackbar({
        open: true,
        message:
          "Your device is offline. Your observation will be edited when back online.",
        severity: "info",
      });
    }
  };

  const findSection = (arm) => {
    if (arm.arm.a && arm.arm.r === null) {
      return 2;
    } else if (arm.arm.r && arm.arm.m === null) {
      return 3;
    } else if (arm.arm.m && arm.isActive) {
      return 4;
    } else if (arm.isCorrection) {
      return 5;
    }
  };

  // If id in url updates, refetch query
  React.useEffect(() => {
    refetch({ id: Number(id) });
  }, [id]);

  React.useEffect(() => {
    const uniqueActions = (arr) =>
      arr
        ? arr.reduce((results, item) => {
            if (!results.some((i) => i.a?.id === item.a?.id)) {
              results.push(item);
            }
            return results;
          }, [])
        : [];

    const uniqueRisks = (arr) =>
      arr
        ? arr.reduce((results, item) => {
            if (!results.some((i) => i.r?.id === item.r?.id)) {
              results.push(item);
            }
            return results;
          }, [])
        : [];

    // Update the global var to update with obs data
    if (observation?.observation) {
      const o = observation.observation;
      let armMediaArray = [];

      const formattedArms = o.observationArms.map((arm) => {
        if (arm.observationArmMediaFiles.length !== 0) {
          arm.observationArmMediaFiles.map((armFile) => {
            armMediaArray.push({
              associatedItem: {
                id: armFile.observationArm.arm.id,
                isExpected: armFile.observationArm.arm.isExpected,
                isCorrection: armFile.observationArm.isCorrection,
                isActive: armFile.observationArm.isActive,
                isRootCause: armFile.observationArm.isRootCause,
                a: armFile.observationArm.arm.a,
                r: armFile.observationArm.arm.r,
                m: armFile.observationArm.arm.m,
              },
              originalFile: armFile.mediaFile,
              section: findSection(armFile.observationArm),
            });
          });
        }

        return {
          id: arm.arm.id,
          isExpected: arm.arm.isExpected,
          isCorrection: arm.isCorrection,
          isActive: arm.isActive,
          isRootCause: arm.isRootCause,
          a: arm.arm.a,
          r: arm.arm.r,
          m: arm.arm.m,
        };
      });

      updateAllEditObservation({
        id,
        submitter: o.submitter,
        owner: o.ownerSiteProject.owner,
        site: o.ownerSiteProject.site,
        project: o.ownerSiteProject.project,
        ospId: o.ownerSiteProject.id,
        location: `${o?.ownerSiteProject.site.name} [${o?.ownerSiteProject.owner.name}]`,
        contractor: o.contractor,
        correctionComments: o.comment,
        crewMembers: o.observationCrewMembers,
        foreman: o.observationUsers.map((u) => u.user),
        date: new Date(`${o.date} ${o.time}`),
        time: new Date(`${o.date} ${o.time}`),
        ha: o.ha,
        actions: uniqueActions(formattedArms),
        risks: uniqueRisks(formattedArms).filter((arm) => arm.r),
        mitigators: formattedArms.filter((arm) => arm.m && arm.isActive),
        unselectedMitigators: formattedArms.filter(
          (arm) => arm.m && !arm.isActive
        ),
        corrections: formattedArms.filter((c) => c.isCorrection),
        outcomeUnplanned: o.automaticCheck,
        outcomeExpected: o.outcomeExpected,
        dollarAmount: o.dollarAmount,
        outcomeInjury: o.outcomeInjury,
        outcomes: o.observationOutcomes.map((o) => ({
          id: o.outcome.id,
          name: o.outcome.name,
          checked: true,
        })),
        outcomeNearMiss: o.outcomeNearMiss,
        files: armMediaArray,
        raScore: o.raScore,
        raScoreMax: o.raScoreMax,
        raScoreCorr: o.raScoreCorr,
        raScoreMin: o.raScoreMin,
      });
    }
  }, [observation]);

  // Show Loading Wheel
  React.useEffect(() => {
    if (online) {
      updateLoading(loading);
    }
  }, [loading]);

  // Show Loading Wheel
  React.useEffect(() => {
    updateLoading(obFetch);
  }, [obFetch]);

  if (observation?.observation === null) {
    return (
      <Layout>
        <Typography sx={{ color: "#fff" }}>
          No observation could be found.
        </Typography>
      </Layout>
    );
  }
  return (
    <Layout>
      <ObservationForm
        type={"EDIT"}
        executeSubmit={executeSubmit}
        resetForm={resetForm}
        expanded={expanded}
        setExpanded={setExpanded}
        setCurrentSection={setCurrentSection}
        currentSection={currentSection}
      />
    </Layout>
  );
}
