export function addTrackedQuery(trackedQueryVar) {
  return (value) => {
    const qCopy = [...trackedQueryVar()];
    qCopy.push(value);

    trackedQueryVar(qCopy);
  };
}

export function removeTrackedQuery(trackedQueryVar) {
  return (value) => {
    const qCopy = [...trackedQueryVar()];

    trackedQueryVar(qCopy.filter((q) => q.id === value));
  };
}
