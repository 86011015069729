import { makeStyles } from "@mui/styles";

const useStyles = () =>
  makeStyles((theme) => ({
    root: {
      height: "100%",
      width: "100%",
      padding: "10px",
      position: "relative",
    },
    title: {
      fontSize: "1.5em",
      color: theme.palette.grey3.main,
      fontFamily: "barlowextrabold",
    },
    titleDot: {
      fontSize: "1.5em",
      color: theme.palette.yellow0.main,
      fontFamily: "barlowextrabold",
    },
    cardTitle: {
      fontSize: "1.3em",
      color: theme.palette.grey2.main,
      fontFamily: "barlowextrabold",
    },
    overlay: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "rgb(0 0 0 / 30%)",
      opacity: 0.8,
      overflow: "hidden",
      width: "100%",
      height: 40,
      transition: ".5s ease",
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
      "&:hover": {
        height: "60px",
        opacity: 1,
        "& $text": {
          color: "#FFB700",
          cursor: "pointer",
        },
      },
    },
    text: {
      color: "#8297A0",
      position: "absolute",
      top: "50%",
      left: "50%",
      "-webkit-transform": "translate(-50%, -50%)",
      "-ms-transform": "translate(-50%, -50%)",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
    },
    cardAddIcon: {
      position: "absolute",
      color: theme.palette.yellow0.main,
      top: "-20px",
      right: "-10px",
      fontSize: "2.5em",
      transition: "transform .5s ease",
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.5)",
      },
    },
  }));

export default useStyles;
