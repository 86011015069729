import React from "react";
import { useNavigate } from "react-router-dom";

import { Button, Grid, Typography } from "@mui/material";

import { useQuery, useReactiveVar } from "@apollo/client";

import { permissionsVar } from "../../../../graphql/localVariables/user";
import { GET_COMPANIES } from "../../../../graphql/queries";
import {
  COMPANY_DASHBOARD,
  GET_USERS,
} from "../../../../graphql/queries/admin/company";
import { GET_CREWMEMBERS } from "../../../../graphql/queries/admin/planning-site";
import { GET_SEARCH_TEMPLATES } from "../../../../graphql/queries/ha";
import { yellowButton } from "../../../CustomStyles/buttons";
import { pageTitleStyles } from "../../../CustomStyles/pageTitle";
import Layout from "../../../Layout";
import CreateSite from "./CreateSite";
import Table from "./Table";

export default function SiteAdmin() {
  const navigate = useNavigate();
  const permissions = useReactiveVar(permissionsVar);
  const [open, setOpen] = React.useState(false);

  // DATA FOR CREATE/EDIT
  const { data: owners, loading: loadingOwners } = useQuery(COMPANY_DASHBOARD, {
    fetchPolicy: "network-only",
  });
  const { data: users, loading: loadingUsers } = useQuery(GET_USERS, {
    fetchPolicy: "network-only",
  });
  const { data: companies, loading: loadingCompanies } = useQuery(
    GET_COMPANIES,
    { fetchPolicy: "network-only" }
  );
  const { data: crew, loading: loadingCrew } = useQuery(GET_CREWMEMBERS, {
    fetchPolicy: "network-only",
  });
  const { data: templates, loading: loadingTemplates } = useQuery(
    GET_SEARCH_TEMPLATES,
    { fetchPolicy: "network-only" }
  );

  React.useEffect(() => {
    if (
      permissions.length === 0 ||
      (permissions.length > 0 &&
        !permissions.includes("VIEW_PLANNING_ADMINISTRATION"))
    ) {
      navigate("/");
    }
  }, []);

  return (
    <Layout>
      <Grid
        style={{
          height: "100%",
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography sx={pageTitleStyles}>PLANNING ADMIN - SITE.</Typography>
          {permissions.includes("CREATE_SITE") && (
            <Button
              variant="contained"
              style={{
                marginRight: "10px",
                ...yellowButton,
                height: "fit-content",
              }}
              onClick={() => setOpen(true)}
            >
              CREATE SITE
            </Button>
          )}
        </Grid>
        <Table
          data={{
            owners: owners ? owners : { companies: [] },
            loadingOwners,
            templates: templates ? templates : { templates: [] },
            loadingTemplates,
            crew: crew ? crew : { crewMembers: [] },
            loadingCrew,
            users: users ? users : { users: [] },
            loadingUsers,
            companies: companies ? companies : { companies: [] },
            loadingCompanies,
          }}
        />
        <CreateSite
          open={open}
          handleClose={() => setOpen(false)}
          data={{
            owners: owners ? owners : { companies: [] },
            loadingOwners,
            templates: templates ? templates : { templates: [] },
            loadingTemplates,
            crew: crew ? crew : { crewMembers: [] },
            loadingCrew,
            users: users ? users : { users: [] },
            loadingUsers,
            companies: companies ? companies : { companies: [] },
            loadingCompanies,
          }}
        />
      </Grid>
    </Layout>
  );
}
