import React from "react";

import {
  Grid,
  Typography,
  FormControlLabel,
  Autocomplete,
  TextField,
} from "@mui/material";

import { useQuery } from "@apollo/client";

import { COMPANY_DASHBOARD } from "../../../graphql/queries/admin/company";
import { CustomSwitch } from "../../CustomComponents/Switch";

// Type - 'action', 'risk', or 'mitigator'
export default function CompanyAssign({
  type,
  assignedCompany,
  setAssignedCompany,
  // childCompanies,
  // setChildCompanies,
  currentUser: userData,
}) {
  const { data: getCompanies } = useQuery(COMPANY_DASHBOARD, {
    fetchPolicy: "network-only",
  });
  // Local state
  const [assignedToYourCompany, setAssigned] = React.useState(true);

  React.useEffect(() => {
    if (userData && userData.currentUser) {
      setAssignedCompany(userData.currentUser.company);
    }
  }, [userData]);

  return (
    <Grid
      container
      textAlign={"center"}
      sx={{ marginBottom: { xs: "25px", md: "0px" } }}
    >
      <Grid item xs={12}>
        <Typography sx={{ display: "inline-flex" }}>
          Would you like the owner of this {type} to be assigned to{" "}
          {userData && userData.currentUser
            ? userData.currentUser.company.name
            : "your company"}
          ?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          style={{ color: "black" }}
          control={
            <CustomSwitch
              checked={assignedToYourCompany}
              onChange={() => setAssigned((prev) => !prev)}
              value={true}
            />
          }
          label={assignedToYourCompany ? "YES" : "NO"}
        />
      </Grid>

      {!assignedToYourCompany ? (
        <Grid
          container
          item
          xs={12}
          alignContent={"center"}
          justifyContent="center"
        >
          <Autocomplete
            id="select-company"
            options={
              getCompanies && getCompanies.companies
                ? getCompanies.companies
                : []
            }
            value={assignedCompany}
            onChange={(event, value) => setAssignedCompany(value)}
            getOptionLabel={(option) => option.name}
            disableClearable
            style={{ width: "75%", marginBottom: "20px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={"Company to assign to:"}
              />
            )}
          />
        </Grid>
      ) : null}
      {/* <Grid
        container
        style={
          assignedCompany?.id
            ? null
            : {
                display: "none",
              }
        }
      >
        <Grid item xs={12}>
          <Typography>
            Should child companies and observed companies of{" "}
            {assignedCompany && assignedCompany.name
              ? assignedCompany.name
              : "your company"}{" "}
            see this {type}?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <CustomSwitch
                checked={childCompanies}
                onChange={() => setChildCompanies((prev) => !prev)}
                value={true}
              />
            }
            label={childCompanies ? "YES" : "NO"}
          />
        </Grid>
      </Grid> */}
    </Grid>
  );
}
