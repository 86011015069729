import { gql } from "@apollo/client";

export function createUpdateAuth(authVar) {
  return (isAuthenticated) => {
    localStorage.setItem("isLoggedIn", JSON.stringify(isAuthenticated));
    authVar({ isAuthenticated });
  };
}

export function createUpdatePermissions(permissionsVar) {
  return (value) => {
    localStorage.setItem("permissions", JSON.stringify(value));
    permissionsVar(value);
  };
}

export function createUpdateSnackbar(snackbarVar) {
  return (value) => {
    snackbarVar(value);
  };
}

export function createUpdateLoading(loadingVar) {
  return (value) => {
    loadingVar(value);
  };
}

export function createUpdateOnline(onlineVar) {
  return (value) => {
    onlineVar(value);
  };
}

export function createUpdateCache(cacheVar) {
  return (value) => {
    cacheVar(value);
  };
}

export const LOGIN_MUTATION = gql`
  mutation Login($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      token
    }
  }
`;

export const REGISTER_MUTATION = gql`
  mutation Register($username: String!, $password: String!) {
    createUser(username: $username, password: $password) {
      user {
        id
        username
      }
    }
  }
`;
