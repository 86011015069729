import { gql } from "@apollo/client";

export const MUTATE_PERMISSION = gql`
  mutation mutatePermission($input: PermissionMutationInput!) {
    permissionMutation(input: $input) {
      permission {
        id
      }
      errors {
        messages
      }
    }
  }
`;

export const CREATE_PERMISSION_GROUP = gql`
  mutation createPermissionGroup(
    $company: ID!
    $name: String!
    $permissions: [ID]!
  ) {
    permissionGroupCreate(
      company: $company
      name: $name
      permissions: $permissions
    ) {
      permissionGroupPermissions {
        permissionGroup {
          id
          name
          company {
            id
            name
          }
        }
        permission {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_PERMISSION_GROUP = gql`
  mutation updatePermissionGroup(
    $isActive: Boolean
    $name: String
    $id: ID!
    $ap: [ID]!
    $dp: [ID]!
  ) {
    permissionGroupUpdate(
      name: $name
      isActive: $isActive
      permissionGroup: $id
      activePermissions: $ap
      deactivatePermissions: $dp
    ) {
      PermissionGroupPermission {
        permissionGroup {
          id
          name
          company {
            id
            name
          }
        }
        permission {
          id
          name
        }
      }
      permissionGroup {
        id
        name
        isActive
      }
    }
  }
`;

export const TOGGLE_PG_STATUS = gql`
  mutation togglePermissionGroupStatus($permission_group: ID!) {
    permissionGroupStatusToggle(permissionGroup: $permission_group) {
      permissionGroup {
        id
        name
        isActive
      }
    }
  }
`;

export const USER_PERMISSION_GROUPS = gql`
  mutation updateUserPermissionGroups($id: ID!, $apg: [ID]!, $dpg: [ID]!) {
    userPermissionGroup(
      user: $id
      activatePermissionGroups: $apg
      deactivatePermissionGroups: $dpg
    ) {
      permissionGroupUser {
        user {
          id
          firstName
          lastName
          username
          image
          email
          userPermissionGroups(isActive: true) {
            id
            permissionGroup {
              id
              name
              company {
                id
                name
              }
              permissionGroupPermission {
                permission {
                  id
                  name
                  description
                }
              }
            }
          }
        }
      }
    }
  }
`;
