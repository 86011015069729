export const filterData = (arr, searchTerm, active) => {
  let filteredArr = searchFilter(arr, searchTerm);
  if (active != "") {
    filteredArr = activeFilter(filteredArr, active);
  }
  return filteredArr;
};

// searchTerm - string
// arr - array of objects
const searchFilter = (arr, searchTerm) => {
  searchTerm = String(searchTerm).toLowerCase();
  return arr.filter((o) => o.name.toLowerCase().includes(searchTerm));
};

const activeFilter = (arr, active) => {
  return arr.filter((o) => (active === "Active" ? o.isActive : !o.isActive));
};
