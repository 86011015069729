import React from "react";

import { Grid } from "@mui/material";

import Employee from "./Employee";
import EmployeeDOB from "./EmployeeDOB";
import EmployeeJobTitle from "./EmployeeJobTitle";
import EmployeePhoneNumber from "./EmployeePhone";
import InjuryDate from "./InjuryDate";
import InjuryTime from "./InjuryTime";
import Weather from "./Weather";

export default function General({ type }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} sx={{ padding: "10px 10px 50px 10px" }}>
        <Employee type={type} />
      </Grid>
      <Grid item xs={12} md={4} sx={{ padding: "10px 10px 50px 10px" }}>
        <EmployeeJobTitle type={type} />
      </Grid>
      <Grid item xs={12} md={4} sx={{ padding: "10px 10px 50px 10px" }}>
        <EmployeePhoneNumber type={type} />
      </Grid>
      <Grid item xs={12} md={4} sx={{ padding: "10px 10px 50px 10px" }}>
        <EmployeeDOB type={type} />
      </Grid>
      <Grid item xs={12} md={4} sx={{ padding: "10px 10px 50px 10px" }}>
        <InjuryDate type={type} />
      </Grid>
      <Grid item xs={12} md={4} sx={{ padding: "10px 10px 50px 10px" }}>
        <InjuryTime type={type} />
      </Grid>
      <Grid item xs={12} sx={{ padding: "10px 10px 50px 10px" }}>
        <Weather type={type} />
      </Grid>
    </Grid>
  );
}
