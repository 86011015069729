import React from "react";

import { Grid } from "@mui/material";

import { useStyles } from "../styles";
import JobStepName from "./JobStepName";
import RisksView from "./Risks";

export default function ActionTableView({
  type,
  action,
  risks,
  mit,
  actions,
  loadingRisks,
  loadingMit,
}) {
  const classes = useStyles()();

  return (
    <Grid
      container
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Grid
        item
        xs={3}
        container
        alignContent="center"
        justifyContent="center"
        className={classes.actionBorderedBox}
      >
        <Grid item>
          <JobStepName
            type={type}
            action={action}
            mits={mit}
            risks={risks}
            actions={actions}
          />
        </Grid>
      </Grid>
      <Grid item container xs={9} align="center">
        <RisksView
          type={type}
          action={action}
          mit={mit}
          data={risks}
          loadingRisks={loadingRisks}
          loadingMit={loadingMit}
        />
      </Grid>
    </Grid>
  );
}
