import * as React from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";

import Pagination from "./TablePagination";
import { TableCell, TableRow } from "@mui/material";

// Props
// 3 children component, Toolbar, Header, TableBody
// hasPagination is boolean
// data - array of objects for body
export default function ReusableTableLayout({
  CustomTableHeader,
  CustomTableBody,
  CustomTableToolBar,
  hasPagination,
  data,
}) {
  // Must have for filtering
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState([]);

  // Must have for pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Filtering with header
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  // if checkbox on rows to select all and delete
  const onSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.uid);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  // when there are checkboxes out front of rows
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // Which rows are selected
  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <Box
      sx={{
        width: "100%",
        boxShadow: "0px 0px 8px 2px #00000050",
        mt: 2,
      }}
    >
      <Paper
        sx={{
          width: "100%",
        }}
      >
        {React.cloneElement(CustomTableToolBar, {
          numSelected: selected.length,
        })}
        <TableContainer>
          <Table
            sx={{
              minWidth: 750,
            }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            {React.cloneElement(CustomTableHeader, {
              numSelected: selected.length,
              order,
              orderBy,
              onRequestSort: handleRequestSort,
              onSelectAllClick: onSelectAllClick,
              rowCount: data.length,
            })}
            <TableBody>
              {React.cloneElement(CustomTableBody, {
                order,
                orderBy,
                rowsPerPage,
                page,
                handleClick,
                isSelected,
                stableSort: stableSort,
                getComparator: getComparator,
                data: data,
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 71 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {hasPagination ? (
          <Pagination
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            dataLength={data.length}
          />
        ) : null}
      </Paper>
    </Box>
  );
}
