import { gql } from "@apollo/client";

export const MUTATE_SITE = gql`
  mutation mutateSite($input: SiteMutationInput!) {
    site(input: $input) {
      site {
        id
        isActive
      }
      errors {
        messages
      }
    }
  }
`;

export const CREATE_SITE = gql`
  mutation siteMutation(
    $isActive: Boolean
    $addrCity: String
    $addrLine1: String
    $addrLine2: String
    $addrState: String
    $addrZip: String
    $company: ID
    $name: String
    $note: String
    $associatedUsers: [ID]
    $supervisors: [ID]
    $associatedCrewMembers: [ID]
    $associatedTemplates: [ID]
    $associatedContractors: [ID]
  ) {
    createEditSiteMutation(
      isActive: $isActive
      addrCity: $addrCity
      addrLine1: $addrLine1
      addrLine2: $addrLine2
      addrState: $addrState
      addrZip: $addrZip
      company: $company
      name: $name
      note: $note
      associatedUsers: $associatedUsers
      supervisors: $supervisors
      associatedCrewMembers: $associatedCrewMembers
      associatedTemplates: $associatedTemplates
      associatedContractors: $associatedContractors
    ) {
      site {
        id
      }
    }
  }
`;

export const EDIT_SITE = gql`
  mutation siteMutation(
    $isActive: Boolean
    $addrCity: String
    $addrLine1: String
    $addrLine2: String
    $addrState: String
    $addrZip: String
    $company: ID
    $id: ID
    $name: String
    $note: String
    $associatedUsers: [ID]
    $unassociatedUsers: [ID]
    $supervisors: [ID]
    $associatedCrewMembers: [ID]
    $unassociatedCrewMembers: [ID]
    $unassociatedTemplates: [ID]
    $associatedTemplates: [ID]
    $associatedContractors: [ID]
    $unassociatedContractors: [ID]
  ) {
    createEditSiteMutation(
      isActive: $isActive
      addrCity: $addrCity
      addrLine1: $addrLine1
      addrLine2: $addrLine2
      addrState: $addrState
      addrZip: $addrZip
      company: $company
      id: $id
      name: $name
      note: $note
      associatedUsers: $associatedUsers
      unassociatedUsers: $unassociatedUsers
      supervisors: $supervisors
      associatedCrewMembers: $associatedCrewMembers
      unassociatedCrewMembers: $unassociatedCrewMembers
      associatedTemplates: $associatedTemplates
      unassociatedTemplates: $unassociatedTemplates
    ) {
      site {
        id
      }
    }

    siteContractor(
      associatedContractors: $associatedContractors
      company: $company
      site: $id
      unassociatedContractors: $unassociatedContractors
    ) {
      activeContractors {
        id
      }
    }
  }
`;

export const MUTATE_OWNER_SITES = gql`
  mutation mutateOwnerSites(
    $associatedSites: [ID]
    $company: ID
    $unassociatedSites: [ID]
  ) {
    siteOwner(
      associatedSites: $associatedSites
      company: $company
      unassociatedSites: $unassociatedSites
    ) {
      associatedSites {
        id
      }
      unassociatedSites {
        id
      }
    }
  }
`;
