import { makeStyles } from "@mui/styles";

const useStyles = () =>
  makeStyles((theme) => ({
    greetingText: {
      color: theme.palette.white.main,
      fontSize: "2em",
      fontFamily: "barlowextrabold",
      // padding: "0px 20px",
      textAlign: "center",
    },
    name: {
      color: theme.palette.yellow0.main,
      fontSize: "2em",
      fontFamily: "barlowextrabold",
      // padding: "0px 20px",
      textAlign: "center",
    },
    dateArrowIcon: {
      color: theme.palette.white.main,
      fontSize: "1.3em",
      marginRight: 10,
    },
    dateText: {
      fontSize: "1.3em !important",
      fontWeight: "bold !important",
      color: theme.palette.grey1.main,
    },
    divider: {
      color: theme.palette.black0.main,
    },
  }));

export default useStyles;
