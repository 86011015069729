import React from "react";
import { useParams } from "react-router-dom";

import { useLazyQuery, useReactiveVar } from "@apollo/client";

import { onlineVar } from "../../../graphql/localVariables/user";
import { investigationMutations } from "../../../graphql/mutations";
import { GET_INVESTIGATION_RESPONSES } from "../../../graphql/queries/incidentInvestigations";
import Layout from "../../Layout";
import IncidentForm from "../Form";

export default function EditIncident() {
  // Incident Report ID from url
  const { id } = useParams();
  const online = useReactiveVar(onlineVar);

  // Data
  const [getResponses, { data }] = useLazyQuery(GET_INVESTIGATION_RESPONSES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  // Mutations
  const { updateAllEditIncident, updateEditIncidentImages } =
    investigationMutations;

  // When Id from url updates, fetch submission
  React.useEffect(() => {
    if (id) {
      getResponses({
        variables: { id: Number(id) },
        fetchPolicy: "network-only",
      });
    }
  }, [id]);

  React.useEffect(() => {
    if (data && data.incidentReport) {
      const report = data.incidentReport;
      const osp =
        report.ownerSiteProject && report.ownerSiteProject
          ? report.ownerSiteProject
          : null;
      updateAllEditIncident({
        id: id,
        owner: osp ? osp.owner : null,
        site: osp ? osp.site : null,
        project: osp ? osp.project : null,
        ospId: osp ? osp.id : null,
        date: report.date,
        time: new Date(`${report.date} ${report.time}`),
        ha: report.ha,
        action: null,
        location: osp ? `${osp.site.name} [${osp.owner.name}]` : null,
        crewMembers: [],
        employeeName: report.employeeName ? report.employeeName : "",
        employeeJobTitle: report.employeeJobTitle
          ? report.employeeJobTitle
          : "",
        employeePhoneNumber: report.employeePhoneNumber
          ? report.employeePhoneNumber
          : "",
        employeeDOB: report.employeeDob ? report.employeeDob : null,
        type: report.type,
        injuryDate: report.injuryDate ? report.injuryDate : new Date(),
        injuryTime: report.injuryTime
          ? new Date(`${report.injuryDate} ${report.injuryTime}`)
          : new Date(),
        haARM: [],
        incidentReportArms: report.incidentReportArms,
        incidentTopicResponses: report.incidentTopicResponses,
        incidentQuestionResponses: report.incidentQuestionResponses,
        isCompleted: report.isComplete,
        weather: report.weather,
        removeFiles: [],
      });
      const imageArray = [];
      data.incidentReport.incidentTopicResponses.map((topic) => {
        topic.files.map((f) => {
          imageArray.push({
            file: f.file,
            topicId: topic.incidentTopic.id,
            id: f.id,
          });
        });
      });

      updateEditIncidentImages(imageArray);
    }
  }, [data]);

  return (
    <Layout>
      <IncidentForm type={"EDIT"} />
    </Layout>
  );
}
