import { gql } from "@apollo/client";

export const GET_PERMISSION_GROUPS = gql`
  query getPermissionGroups {
    PermissionGroups {
      id
      name
      isActive
      company {
        id
        name
      }
      permissionGroupPermissions(isActive: true, permission_IsActive: true) {
        id
        permission {
          id
          name
          description
        }
      }
    }
  }
`;

export const GET_ACTIVE_PERMISSIONS = gql`
  query getActivePermissions {
    permissions {
      id
      name
      description
      isActive
    }
  }
`;
