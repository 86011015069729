import React from "react";

import { Grid, Typography } from "@mui/material";

import { useQuery, useReactiveVar } from "@apollo/client";

import { onlineVar } from "../../../graphql/localVariables/user";
import { authMutations } from "../../../graphql/mutations";
import { GET_AUDIT_SUBMISSIONS } from "../../../graphql/queries/audits";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import Layout from "../../Layout";
import Table from "./Table";

export default function ViewAudit() {
  const online = useReactiveVar(onlineVar);
  const { data, loading } = useQuery(GET_AUDIT_SUBMISSIONS, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const { updateLoading } = authMutations;

  // show loading
  React.useEffect(() => {
    updateLoading(loading);
  }, [loading]);

  return (
    <Layout>
      <Grid
        style={{
          height: "100%",
        }}
      >
        <Typography sx={pageTitleStyles}>VIEW AUDIT.</Typography>
        {data && <Table data={data.auditSubmissions} />}
      </Grid>
    </Layout>
  );
}
