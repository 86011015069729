import { gql } from "@apollo/client";

export const GET_CLASSIFICATIONS = gql`
  query getClassifications {
    classifications {
      id
      isActive
      name
      code
      industrialClassifications {
        industry {
          id
          name
          isActive
          code
        }
      }
    }
  }
`;
