import * as React from "react";

import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";

// Props
// order, orderBy, headCell (data), createSortHandler
export default function ReusableTableHeadCell(props) {
  const { order, orderBy, headCell, createSortHandler } = props;

  return (
    <TableCell
      key={headCell.uid}
      padding={headCell.disablePadding ? "none" : "normal"}
      sortDirection={orderBy === headCell.uid ? order : false}
    >
      {headCell.sort ? (
        <TableSortLabel
          active={orderBy === headCell.uid}
          direction={orderBy === headCell.uid ? order : "asc"}
          onClick={createSortHandler(headCell.uid)}
        >
          {headCell.label}
          {orderBy === headCell.uid ? (
            <Box component="span" sx={visuallyHidden}>
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </Box>
          ) : null}
        </TableSortLabel>
      ) : (
        headCell.label
      )}
    </TableCell>
  );
}
