import React from "react";

import { Grid } from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import {
  createObservationVar,
  editObservationVar,
} from "../../../graphql/localVariables/observation";
import { observationMutations } from "../../../graphql/mutations";
import SelectedUnselected from "../../CustomComponents/SelectedUnselected";

export default function RootCauses({ type, mitigators: data }) {
  // Global Variables
  const editObs = useReactiveVar(editObservationVar);
  const createObs = useReactiveVar(createObservationVar);
  const form = type === "EDIT" ? editObs : createObs;

  // Mutations
  const { updateKeyEditObservation, updateKeyCreateObservation } =
    observationMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditObservation : updateKeyCreateObservation;

  // Local Variables
  const [selectedCauses, setSelectedCauses] = React.useState([]);
  const [unselectedCauses, setUnselectedCauses] = React.useState([]);

  const rcFiles = form.files.filter((f) => f.section === 6);

  const updateState = (newSelected) => {
    const selected = form.mitigators.map((m) => {
      if (newSelected.findIndex((s) => s.id === m.id) >= 0) {
        return { ...m, isRootCause: true };
      } else {
        if (m.isRootCause) {
          const copy = { ...m };
          delete copy.isRootCause;
          return copy;
        } else return m;
      }
    });
    const unselected = form.unselectedMitigators.map((m) => {
      if (newSelected.findIndex((s) => s.id === m.id) >= 0) {
        return { ...m, isRootCause: true, isActive: false };
      } else {
        if (m.isRootCause) {
          const copy = { ...m };
          delete copy.isRootCause;
          delete copy.isActive;
          return copy;
        } else return m;
      }
    });
    updateObsKeyFunction("mitigators", selected);
    updateObsKeyFunction("unselectedMitigators", unselected);

    const currentRCIds = newSelected.map((s) => s.id);

    // Remove images of potentially removed action
    const removedUploadedFiles = rcFiles.filter(
      (f) => !currentRCIds.includes(f.associatedItem.id) && f.originalFile.id
    );

    if (form.removeMediaFiles) {
      updateObsKeyFunction("removeMediaFiles", [
        ...form.removeMediaFiles,
        ...removedUploadedFiles.map((f) => f.originalFile.id),
      ]);
    } else {
      updateObsKeyFunction("removeMediaFiles", [
        ...removedUploadedFiles.map((f) => f.originalFile.id),
      ]);
    }

    updateObsKeyFunction("files", [
      ...form.files.filter((f) => f.section != 6),
      ...rcFiles.filter((f) => currentRCIds.includes(f.associatedItem.id)),
    ]);
  };

  React.useEffect(() => {
    if (data && form.mitigators && form.unselectedMitigators && form.risks) {
      // get all selected rootcauses
      const selected = [
        ...form.mitigators.filter((m) => m.isRootCause),
        ...form.unselectedMitigators.filter((m) => m.isRootCause),
      ];

      // filtered selected risks vs mitigators
      const unselected = [];

      const selectedIds = selected.map((s) => s.id);
      data.companyArms
        .map((carm) => carm.arm)
        .forEach((mitigatorArm) => {
          form.risks.forEach((riskArm) => {
            if (
              riskArm.a.id === mitigatorArm.a.id &&
              riskArm.r.id === mitigatorArm.r.id &&
              (!riskArm.m || !riskArm.m.id) &&
              !selectedIds.includes(mitigatorArm.id)
            ) {
              unselected.push(mitigatorArm);
            }
          });
        });

      setSelectedCauses(selected);
      setUnselectedCauses(unselected);
    }
  }, [data, form.risks, form.mitigators, form.unselectedMitigators]);

  return (
    <Grid testid="Root Causes">
      <SelectedUnselected
        selected={selectedCauses}
        unselected={unselectedCauses}
        setSelected={setSelectedCauses}
        setUnselected={setSelectedCauses}
        updateState={updateState}
        idKey="m"
        itemType="mitigator"
        focusList={[]}
        label="Root Causes"
      />
    </Grid>
  );
}
