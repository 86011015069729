import React from "react";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { CustomDialog } from "../../CustomStyles/dialog";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import PreviewImages from "./PreviewImages";

export default function ViewImages({ open, handleClose, images }) {
  const onCancel = () => {
    handleClose();
  };

  return (
    <CustomDialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle
        style={{
          ...pageTitleStyles,
        }}
      >
        VIEW INCIDENT FILES.
      </DialogTitle>
      <DialogContent>
        <PreviewImages fileObjects={images} handleFilesChange={() => {}} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onCancel}>
          DONE
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}
