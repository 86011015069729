import React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";

import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import { CustomDialog } from "../../CustomStyles/dialog";
import { CustomTextField } from "../../CustomStyles/LightTextField";
import { useMutation } from "@apollo/client";
import { MUTATE_PERMISSION } from "../../../graphql/mutations/admin/permissions";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import { yellowButton } from "../../CustomStyles/buttons";
import { GET_ACTIVE_PERMISSIONS } from "../../../graphql/queries/permissions";

export default function CreatePermission({ open, handleClose }) {
  const [createPermission] = useMutation(MUTATE_PERMISSION, {
    onCompleted() {
      onCompletedFunc("Permission created");
      handleClose();
      onClear();
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [{ query: GET_ACTIVE_PERMISSIONS }],
  });
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");

  const onCreate = () => {
    createPermission({
      variables: { input: { name, description, isActive: true } },
    });
  };

  const onDone = () => {
    handleClose();
    onClear();
  };

  const onClear = () => {
    setName("");
    setDescription("");
  };

  return (
    <CustomDialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle
        style={{
          ...pageTitleStyles,
        }}
      >
        CREATE PERMISSION.
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <CustomTextField
            label="Name:"
            variant="standard"
            style={{ margin: "10px 0px" }}
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          <CustomTextField
            label="Description:"
            variant="standard"
            style={{ margin: "10px 0px" }}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          style={{ marginRight: "10px", ...yellowButton }}
          onClick={onCreate}
        >
          CREATE
        </Button>
        <Button
          variant="contained"
          style={{ marginRight: "10px" }}
          onClick={onDone}
        >
          DONE
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}
