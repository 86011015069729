import { makeStyles } from "@mui/styles";

const useStyles = () =>
  makeStyles((theme) => ({
    borderedBox: {
      padding: "2em",
      border: "black solid 1px",
    },
    onlyBorderedBox: {
      padding: "1em",
      borderLeft: "black solid 1px",
      borderRight: "black solid 1px",
      overflowWrap: "break-word",
      height: "100%",
    },
    bottomBorderedBox: {
      padding: "1em",
      borderLeft: "black solid 1px",
      borderRight: "black solid 1px",
      borderTop: "black solid 1px",
      overflowWrap: "break-word",
    },
    topBorderedBox: {
      padding: "1em",
      borderLeft: "black solid 1px",
      borderRight: "black solid 1px",
      borderBottom: "black solid 1px",
      overflowWrap: "break-word",
    },
    riskBox: {
      border: "black solid 1px",
    },
    headerBorderedBox: {
      padding: ".5em",
      border: "black solid 1px",
      overflowWrap: "break-word",
      fontWeight: "bold",
    },
    actionBorderedBox: {
      border: "black solid 1px",
      overflowWrap: "break-word",
    },
    crewMemberButtons: {
      minWidth: "90px",
      fontSize: "x-small",
    },
    iconButtonPrimary: {
      backgroundColor: theme.palette.primary.main,
      color: "#FFF",
      "&:focus-visible": {
        backgroundColor: theme.palette.primary.main,
        color: "#FFF",
      },
    },
    iconButtonSecondary: {
      backgroundColor: theme.palette.secondary.main,
      color: "#FFF",
    },
    sigPad: {
      width: "100%",
      height: 200,
      border: "1px solid black",
    },
    disabledCheckbox: {
      "&.Mui-disabled": {
        pointerEvents: "auto",
        "&:hover": {
          backgroundColor: "transparent",
        },
        "& .MuiSvgIcon-root": {
          color: "#4D4D4D",
        },
      },
    },
    disabledLabel: {
      "&.Mui-disabled": {
        pointerEvents: "auto",
        "&:hover": {
          backgroundColor: "transparent",
        },
        "& .MuiTypography-root": {
          color: "#4D4D4D",
        },
      },
    },
  }));

export { useStyles };
