import React from "react";
import { useNavigate } from "react-router-dom";

// import AccountTreeIcon from "@mui/icons-material/AccountTree";
import HomeIcon from "@mui/icons-material/Home";
import Info from "@mui/icons-material/Info";
import {
  Grid,
  List,
  ListItemText,
  Divider,
  ListItemButton,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ListItem,
} from "@mui/material";
import { useTheme } from "@mui/styles";

import nixnLogo from "../../images/NIXN_Logo.svg";
import { yellowButton } from "../CustomStyles/buttons";
import { CustomDialog } from "../CustomStyles/dialog";
import AccountItems from "./AccountItems";
import AdminItems from "./AdminItem";
import CreateItems from "./CreateItems";
import ReportingItems from "./ReportingItems";
import ViewItems from "./ViewItems";
import { versionNum, releaseNotes } from "./releaseNotes";
import useStyles from "./styles";

export default function Drawer({
  drawerWidth,
  permissions,
  isSuperuser,
  isStaff,
  user,
}) {
  const classes = useStyles(drawerWidth)();
  const navigate = useNavigate();
  const theme = useTheme();

  const iconSelectedColor = theme.palette.yellow0.main;
  const textSelectedColor = theme.palette.white.main;
  const endBarSelectedColor = theme.palette.yellow0.main;

  const [keyHook, setKey] = React.useState("Home");
  const [open, setOpen] = React.useState(false);

  // SET KEY BASED ON URL
  React.useEffect(() => {
    const path = window.location.pathname;
    if (path.includes("create")) {
      setKey("create");
    } else if (
      (path.includes("view") || path.includes("edit")) &&
      !path.includes("admin")
    ) {
      setKey("view");
    } else if (path.includes("user-settings")) {
      setKey("account");
    } else if (path.includes("reporting")) {
      setKey("reporting");
    } else if (path.includes("admin")) {
      setKey("admin");
    }
  }, []);

  // let riskLabItem;
  // if (permissions.includes("VIEW_RISK_LAB")) {
  //   riskLabItem = (
  //     <ListItemButton
  //       key="Risk Lab"
  //       onClick={() => {
  //         setKey("RiskLab");
  //         navigate("/risk-lab");
  //       }}
  //     >
  //       <AccountTreeIcon
  //         className={classes.listIcons}
  //         style={{
  //           color: keyHook === "RiskLab" ? iconSelectedColor : undefined,
  //         }}
  //       />
  //       <ListItemText
  //         primary={"Risk Lab"}
  //         className={classes.listText}
  //         style={{
  //           color: keyHook === "RiskLab" ? textSelectedColor : undefined,
  //         }}
  //       />
  //       <div
  //         className={classes.listEndBar}
  //         style={{
  //           backgroundColor:
  //             keyHook === "RiskLab" ? endBarSelectedColor : undefined,
  //         }}
  //       ></div>
  //     </ListItemButton>
  //   );
  // }

  let reportingItem;
  if (permissions.includes("CUSTOM_REPORTING")) {
    reportingItem = (
      <ReportingItems
        iconSelectedColor={iconSelectedColor}
        textSelectedColor={textSelectedColor}
        endBarSelectedColor={endBarSelectedColor}
        classes={classes}
        permissions={permissions}
        isStaff={isStaff}
        keyHook={keyHook}
      />
    );
  }

  let adminItems;
  if (
    permissions.includes("VIEW_CARM_ADMINISTRATION") ||
    permissions.includes("VIEW_PLANNING_ADMINISTRATION") ||
    permissions.includes("VIEW_PERMISSION_ADMINISTRATION") ||
    permissions.includes("VIEW_COMPANY_ADMINISTRATION") ||
    permissions.includes("SUPERVISION") ||
    permissions.includes("AUDIT_MANAGE") ||
    permissions.includes("CREATE_USER") ||
    permissions.includes("EDIT_USER")
  ) {
    adminItems = (
      <AdminItems
        iconSelectedColor={iconSelectedColor}
        textSelectedColor={textSelectedColor}
        endBarSelectedColor={endBarSelectedColor}
        classes={classes}
        permissions={permissions}
        keyHook={keyHook}
      />
    );
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      style={{ minHeight: "100%", flexWrap: "nowrap" }}
    >
      <Grid item>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-end"
          className={classes.toolbar}
        >
          <Grid
            item
            xs={12}
            style={{
              textAlign: "center",
            }}
          >
            <img
              src={nixnLogo}
              width="150px"
              height="100%"
              style={{
                verticalAlign: "middle",
                cursor: "pointer",
              }}
              onClick={() => {
                setKey("Home");
                navigate("/");
              }}
              alt="NIXN logo"
            />
          </Grid>
        </Grid>

        <Divider />
        <List>
          <ListItemButton
            key={"Home"}
            onClick={() => {
              setKey("Home");
              navigate("/");
            }}
          >
            <HomeIcon
              className={classes.listIcons}
              style={{
                color: keyHook === "Home" ? iconSelectedColor : undefined,
              }}
            />
            <ListItemText
              primary={"Home"}
              className={classes.listText}
              style={{
                color: keyHook === "Home" ? textSelectedColor : undefined,
              }}
            />
            <div
              className={classes.listEndBar}
              style={{
                backgroundColor:
                  keyHook === "Home" ? endBarSelectedColor : undefined,
              }}
            ></div>
          </ListItemButton>
          <CreateItems
            iconSelectedColor={iconSelectedColor}
            textSelectedColor={textSelectedColor}
            endBarSelectedColor={endBarSelectedColor}
            permissions={permissions}
            classes={classes}
            keyHook={keyHook}
          />
          <ViewItems
            iconSelectedColor={iconSelectedColor}
            textSelectedColor={textSelectedColor}
            endBarSelectedColor={endBarSelectedColor}
            permissions={permissions}
            classes={classes}
            keyHook={keyHook}
          />
          {/* {riskLabItem}*/}
          {reportingItem}
          {adminItems}
        </List>

        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.toolbar}
        ></Grid>
      </Grid>
      <Grid item>
        <AccountItems
          iconSelectedColor={iconSelectedColor}
          textSelectedColor={textSelectedColor}
          endBarSelectedColor={endBarSelectedColor}
          isSuperuser={isSuperuser}
          classes={classes}
          keyHook={keyHook}
          user={user}
        />
        <Grid container alignItems="center" justifyContent="center">
          <Typography
            style={{ color: "white", fontSize: "12px", fontWeight: "bold" }}
          >
            VERSION {versionNum}
          </Typography>
          <IconButton onClick={() => setOpen(true)}>
            <Info
              style={{ color: "white", fontSize: "18px", marginLeft: "20px" }}
            />
          </IconButton>
        </Grid>
      </Grid>
      <CustomDialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          VERSION {releaseNotes[0].versionNum} ({releaseNotes[0].date}) RELEASE
          NOTES
        </DialogTitle>
        <DialogContent style={{ height: "90vh" }}>
          <Grid style={{ height: "45%", overflow: "auto" }}>
            <List sx={{ listStyleType: "disc", pl: 4 }}>
              {releaseNotes[0].notes.map((n) => (
                <ListItem key={n} sx={{ display: "list-item" }}>
                  <ListItemText>{n}</ListItemText>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Typography style={{ fontWeight: "bold", marginTop: "20px" }}>
            PAST RELEASE NOTES:
          </Typography>
          <Grid style={{ height: "45%", overflow: "auto" }}>
            {releaseNotes.map((note, index) => (
              <div key={note.versionNum}>
                {index !== 0 && (
                  <Grid>
                    <Typography style={{ marginTop: "20px" }}>
                      {note.versionNum} ({note.date})
                    </Typography>
                    <List sx={{ listStyleType: "disc", pl: 4 }}>
                      {note.notes.map((n) => (
                        <ListItem key={n} sx={{ display: "list-item" }}>
                          <ListItemText>{n}</ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                )}
              </div>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button style={yellowButton} onClick={() => setOpen(false)}>
            CLOSE
          </Button>
        </DialogActions>
      </CustomDialog>
    </Grid>
  );
}
