import * as React from "react";

import TablePagination from "@mui/material/TablePagination";

// Props
// const [page, setPage] = React.useState(0);
// const [rowsPerPage, setRowsPerPage] = React.useState(5);
// dataLength = the data [] being mapped in table's length.

export default function Pagination(props) {
  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    dataLength,
    fetchMore,
    allData,
  } = props;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    if (
      allData.hazardAnalyses &&
      allData.hazardAnalyses.edges &&
      (page + 1) * rowsPerPage + 1 > allData.hazardAnalyses.edges.length &&
      allData.hazardAnalyses.pageInfo.hasNextPage
    ) {
      fetchMore({
        after: allData.hazardAnalyses.pageInfo.endCursor,
        first: dataLength + 50,
      });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={
        allData && allData.hazardAnalyses.totalCount
          ? allData.hazardAnalyses.totalCount
          : 0
      }
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      showFirstButton
    />
  );
}
