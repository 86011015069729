export const filterData = (arr, searchTerm, active, siteOwner) => {
  let filteredArr = searchFilter(arr, searchTerm);

  filteredArr = activeFilter(filteredArr, active);

  if (siteOwner.name !== "") {
    filteredArr = siteOwnerFilter(filteredArr, siteOwner);
  }

  return filteredArr;
};

// searchTerm - string
// arr - array of objects
const searchFilter = (arr, searchTerm) => {
  searchTerm = String(searchTerm).toLowerCase();
  return arr.filter((o) => o.name.toLowerCase().includes(searchTerm));
};

const activeFilter = (arr, active) => {
  return arr.filter((o) => (active ? true : o.isActive));
};

const siteOwnerFilter = (arr, siteOwner) => {
  return arr.filter((o) => {
    const ownerIds = o.ownerSiteProjects.map((o) => o.owner.id);
    return ownerIds.includes(siteOwner.id);
  });
};
