import * as React from "react";

import { CircularProgress, Grid } from "@mui/material";

import { useQuery, useReactiveVar } from "@apollo/client";

import { onlineVar } from "../../../../graphql/localVariables/user";
import { GET_USERS_ADMIN } from "../../../../graphql/queries/admin/user";
import TableMain from "./ReusableTableLayout";
import ReusableTableToolbar from "./ReusableTableToolbar";
import TableBodyTemplate from "./TableBodyTemplate";
import TableHeadTemplate from "./TableHeadTemplate";
import { filterData } from "./searchFilterFunction";

// Props
// hasPagination - boolean
// data - array of objects
// 3 children component
//    If using the ReusableTableToolbar
//    - title - string - displayed at the top
//    - onDeleteClick - when trash can on toolbar is clicked
//    - onSearchChange - when input in search bar
//    - searchTerm - string
export default function Table() {
  const online = useReactiveVar(onlineVar);
  const { data, loading } = useQuery(GET_USERS_ADMIN, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  const onSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const onGroupChange = (event, newValue) => {
    setGroupsFilter(newValue || []);
  };

  const onCompanyChange = (event, newValue) => {
    setCompanyFilter(newValue || { name: "" });
  };

  const clearFilters = () => {
    setGroupsFilter([]);
    setCompanyFilter({ name: "" });
  };

  const [searchTerm, setSearchTerm] = React.useState("");
  const [groupsFilter, setGroupsFilter] = React.useState([]);
  const [companyFilter, setCompanyFilter] = React.useState({ name: "" });

  const [filteredData, setFilteredData] = React.useState([]);

  // useEffect for filter update
  React.useEffect(() => {
    setFilteredData(
      filterData(
        data ? data.users : [],
        searchTerm,
        groupsFilter,
        companyFilter
      )
    );
  }, [data, searchTerm, groupsFilter, companyFilter]);

  return (
    <>
      {loading ? (
        <Grid container justifyContent="center">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <TableMain
          CustomTableHeader={<TableHeadTemplate />}
          CustomTableBody={<TableBodyTemplate />}
          CustomTableToolBar={
            <ReusableTableToolbar
              searchTerm={searchTerm}
              onSearchChange={onSearchChange}
              groupsFilter={groupsFilter}
              onGroupChange={onGroupChange}
              companyFilter={companyFilter}
              onCompanyChange={onCompanyChange}
              clearFilters={clearFilters}
              title={"Users"}
            />
          }
          hasPagination={true}
          data={filteredData}
        />
      )}
    </>
  );
}
