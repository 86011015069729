export const filteredProjectsFunction = (args, readField) => {
  const { ownerId, siteId } = args;
  const projects = readField({
    fieldName: "ownerSiteProjects",
    args: { isActive: true, project_Isnull: false },
  });
  const filteredArray = projects
    ? projects.filter((projectRef) => {
        const projectSiteId = readField("id", readField("site", projectRef));
        const projectOwnerId = readField("id", readField("owner", projectRef));
        return (
          Number(projectSiteId) === Number(siteId) &&
          Number(projectOwnerId) === Number(ownerId)
        );
      })
    : [];
  return filteredArray;
};

export const filteredOSPFunction = (args, readField, toReference) => {
  const { siteId, ownerId, projectId, projectIds } = args;
  const osp = readField({
    fieldName: "ownerSiteProjects",
    args: { isActive: true, isCompanyDashboard: true },
  });

  const filteredArray = [];
  if (osp) {
    osp.forEach((ospItem) => {
      const ownerIdRef = readField("id", readField("owner", ospItem));
      const siteIdRef = readField("id", readField("site", ospItem));
      const projectIdRef = readField("id", readField("project", ospItem));

      const ospIdRef = readField("id", ospItem);

      // optional args
      // owner
      if (ownerId == ownerIdRef && !siteId && !siteIdRef) {
        filteredArray.push(
          toReference({
            __typename: "OwnerSiteProjectType",
            id: ospIdRef,
          })
        );
      }

      // owner-site
      if (
        ownerId &&
        ownerId == ownerIdRef &&
        siteId &&
        siteId == siteIdRef &&
        !projectId &&
        !projectIds
      ) {
        filteredArray.push(
          toReference({
            __typename: "OwnerSiteProjectType",
            id: ospIdRef,
          })
        );
      }

      // owner-site-project
      if (
        ownerId &&
        siteId &&
        ownerId == ownerIdRef &&
        siteId == siteIdRef &&
        ((projectId && projectId == projectIdRef) ||
          (projectIds && projectIds.includes(Number(projectIdRef))))
      ) {
        filteredArray.push(
          toReference({
            __typename: "OwnerSiteProjectType",
            id: ospIdRef,
          })
        );
      }
    });
  }
  return filteredArray;
};

export const filteredContractorsFunction = (args, readField, toReference) => {
  const { siteId, ownerId } = args;
  const sopContractors = readField({
    fieldName: "ownerSiteProjectContractors",
    args: { isActive: true },
  });
  const filteredArray = [];

  if (sopContractors) {
    sopContractors.forEach((sopc) => {
      if (sopc.ownerSiteProject.owner && sopc.ownerSiteProject.site) {
        const ownerIdRef = readField(
          "id",
          readField("owner", sopc.ownerSiteProject)
        );
        const siteIdRef = readField(
          "id",
          readField("site", sopc.ownerSiteProject)
        );

        const contractorIdRef = readField("id", sopc.contractor);
        if (ownerId == ownerIdRef && siteId == siteIdRef) {
          filteredArray.push(
            toReference({
              __typename: "CompanyType",
              id: contractorIdRef,
            })
          );
        }
      }
    });
  }
  return filteredArray;
};

export const filteredCrewmembersFunction = (args, readField, toReference) => {
  const { ospId } = args;
  const crewMembers = readField({
    fieldName: "paginatedCrewMembers",
    args: { isActive: true },
  });

  const filteredArray = [];
  if (crewMembers) {
    crewMembers.forEach((member) => {
      const ownerSiteProjectCrewMemberIds = readField({
        fieldName: "ownerSiteProjectCrewMemberIds",
        args: { isActive: true, ownerSiteProject_IsActive: true },
        from: member,
      });
      if (
        ownerSiteProjectCrewMemberIds &&
        ownerSiteProjectCrewMemberIds.length > 0
      ) {
        if (ownerSiteProjectCrewMemberIds.includes(`${ospId}`)) {
          filteredArray.push({
            crewMember: toReference({
              __typename: "CrewMemberType",
              id: readField("id", member),
            }),
          });
        }
      }
    });
  }
  return filteredArray;
};

export const filteredHATemplatesFunction = (args, readField) => {
  const { ospIdList } = args;
  const ospTemplates = readField({
    fieldName: "paginatedOwnerSiteProjectTemplatesHa",
    args: { isActive: true, template_IsActive: true, first: 200 },
  });

  const filteredArray = [];
  if (ospTemplates) {
    if (!ospIdList || ospIdList.length === 0) {
      ospTemplates.forEach((osptItem) => {
        if (osptItem.isParentTemplate) {
          filteredArray.push(osptItem);
        }
      });
    } else {
      ospTemplates.forEach((osptItem) => {
        const ospIdRef = readField("id", osptItem.ownerSiteProject);
        if (
          osptItem.isParentTemplate ||
          ospIdList.findIndex((id) => Number(id) === Number(ospIdRef)) >= 0
        ) {
          filteredArray.push(osptItem);
        }
      });
    }
  }
  // eslint-disable-next-line no-console
  console.log("Sending these HA templates from filter:", filteredArray);
  return filteredArray;
};

export const filteredTemplateFunction = (args, readField, toReference) => {
  const { idList } = args;
  const temp = readField({
    fieldName: "paginatedTemplates",
    args: { isActive: true },
  });
  const filteredArray = [];
  if (temp) {
    temp.forEach((t) => {
      const tempIdRef = readField("id", t);
      if (idList.findIndex((id) => Number(id) === Number(tempIdRef)) >= 0) {
        filteredArray.push(
          toReference({
            __typename: "TemplateType",
            id: readField("id", t),
          })
        );
      }
    });
  }
  return filteredArray;
};
