import React from "react";
import { useNavigate } from "react-router-dom";

import { Grid, Typography } from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import { permissionsVar } from "../../../../graphql/localVariables/user";
import { pageTitleStyles } from "../../../CustomStyles/pageTitle";
import Layout from "../../../Layout";
import CreateCompany from "./CreateCompany";
import Table from "./Table";

export default function ViewCompanies() {
  const navigate = useNavigate();
  const permissions = useReactiveVar(permissionsVar);

  React.useEffect(() => {
    if (
      permissions.length === 0 ||
      (permissions.length > 0 &&
        !permissions.includes("VIEW_COMPANY_ADMINISTRATION"))
    ) {
      navigate("/");
    }
  }, []);

  return (
    <Layout>
      <Grid
        container
        style={{
          width: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            sx={{ ...pageTitleStyles, fontSize: { xs: "2rem", md: "3rem" } }}
          >
            VIEW COMPANIES
          </Typography>
          <CreateCompany />
        </Grid>
        <Table />
      </Grid>
    </Layout>
  );
}
