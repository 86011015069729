import { gql } from "@apollo/client";

export const MUTATE_SUPERVISION = gql`
  mutation updateSupervision(
    $supervisor: ID!
    $supervisees: [ID]!
    $associatedOSP: [ID]
    $unassociatedOSP: [ID]
  ) {
    supervisorToUser(supervisor: $supervisor, supervisees: $supervisees) {
      supervision {
        supervisor {
          id
          supervise {
            id
            supervisee {
              id
              firstName
              lastName
              username
            }
            ownerSiteProject {
              id
              owner {
                name
                id
              }
              site {
                name
                id
              }
            }
          }
        }
      }
    }

    supervisorToOsp(
      supervisor: $supervisor
      AssociatedOSP: $associatedOSP
      UnassociatedOSP: $unassociatedOSP
    ) {
      supervisors {
        supervisor {
          id
          supervise {
            id
            supervisee {
              id
              firstName
              lastName
              username
            }
            ownerSiteProject {
              id
              owner {
                name
                id
              }
              site {
                name
                id
              }
            }
          }
        }
      }
    }
  }
`;
