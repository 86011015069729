import React from "react";

import EditTemplate from "../../../../Planning/Template/Table/EditTemplate";

export default function EditTemplateDialog({ data, open, setOpen }) {
  return (
    <EditTemplate
      open={open}
      handleClose={() => setOpen(false)}
      row={data.template ? data.template : data}
    />
  );
}
