import React from "react";

import BooleanQuestionType from "./Boolean";
import CheckBoxQuestion from "./Checkboxes";
import Date from "./Date";
import MultiDateRange from "./MultiDateRange";
import MultipleChoice from "./MultipleChoice";
import PhoneNumber from "./Phone";
import TextBoxQuestion from "./TextBox";
import TextInputQuestion from "./TextInput";

export default function QuestionTypes({ type, question, updateState }) {
  // render different things based on type of question
  switch (question.type) {
    case "TextInput":
      return (
        <TextInputQuestion
          question={question}
          type={type}
          updateState={updateState}
        />
      );
    case "TextBox":
      return (
        <TextBoxQuestion
          question={question}
          type={type}
          updateState={updateState}
        />
      );
    case "MultipleChoice":
      return (
        <MultipleChoice
          question={question}
          type={type}
          updateState={updateState}
        />
      );
    case "Boolean":
      return (
        <BooleanQuestionType
          question={question}
          type={type}
          updateState={updateState}
        />
      );
    case "Checkbox":
      return (
        <CheckBoxQuestion
          question={question}
          type={type}
          updateState={updateState}
        />
      );
    case "PhoneNumber":
      return (
        <PhoneNumber
          question={question}
          type={type}
          updateState={updateState}
        />
      );
    case "Date":
      return <Date question={question} type={type} />;
    case "MultiDateRange":
      return (
        <MultiDateRange
          question={question}
          type={type}
          updateState={updateState}
        />
      );
    default:
      return null;
  }
}
