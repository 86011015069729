import * as React from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";

const styles = () => ({
  root: {},
  imageContainer: {
    width: "fit-content",
    position: "relative",
    zIndex: 10,
    textAlign: "center",
    "&:hover $image": {
      opacity: 0.3,
    },
    "&:hover $removeButton": {
      opacity: 1,
    },
    padding: "20px",
  },
  image: {
    maxWidth: "100%",
    width: "auto",
    maxHeight: "100%",
    height: "100px",
  },
  removeButton: {
    transition: ".5s ease",
    position: "absolute",
    top: 0,
    right: 0,
    opacity: 0,
    width: 40,
    height: 40,
    "&:focus": {
      opacity: 1,
    },
  },
});

function PreviewList({ fileObjects, classes, handleFilesChange, removeImage }) {
  const isImage = (file) => {
    if (file.type && file.type.split("/")[0] === "image") {
      return true;
    }
  };

  const getPreviewIcon = (fileObject, classes) => {
    if (fileObject.id) {
      return (
        <img
          className={classes.image}
          src={fileObject.file}
          alt="Investigation image"
        />
        // TODO determine name in this case
      );
    } else {
      if (fileObject.file && isImage(fileObject.file)) {
        return (
          <>
            <img
              className={classes.image}
              src={URL.createObjectURL(fileObject.file)}
              alt="Investigation image"
            />
            <p>{fileObject.file.name}</p>
          </>
        );
      } else if (fileObject.type && isImage(fileObject)) {
        return (
          <>
            <img
              className={classes.image}
              src={URL.createObjectURL(fileObject)}
              alt="Investigation image"
            />
            <p>{fileObject.name}</p>
          </>
        );
      }
      return (
        <>
          <AttachFileIcon style={{ width: "100%" }} />
          <p>{fileObject.file ? fileObject.file.name : fileObject.name}</p>
        </>
      );
    }
  };

  const handleRemove = (fileIndex) => (event) => {
    event.stopPropagation();
    const arr = [...fileObjects];
    const image = fileObjects[fileIndex];
    if (image.id) {
      removeImage(image.id);
    }
    arr.splice(fileIndex, 1);
    handleFilesChange(arr);
  };

  return (
    <Grid container>
      {fileObjects &&
        fileObjects.map((fileObject, i) => {
          return (
            <Grid
              item={true}
              key={`${fileObject.file?.name ?? "file"}-${i}`}
              className={classes.imageContainer}
            >
              {getPreviewIcon(fileObject, classes)}

              <Fab
                onClick={handleRemove(i)}
                aria-label="Delete"
                className={classes.removeButton}
              >
                <DeleteIcon />
              </Fab>
            </Grid>
          );
        })}
    </Grid>
  );
}

export default withStyles(styles, { name: "MuiDropzonePreviewList" })(
  PreviewList
);
