import React from "react";

import {
  Grid,
  TextField,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";

import { useQuery, useReactiveVar } from "@apollo/client";

import { createAuditVar } from "../../../graphql/localVariables/audit";
import { auditMutations } from "../../../graphql/mutations";
import { GET_SITES } from "../../../graphql/queries";

export default function LocationInputView({ setStartAudit }) {
  const updateAudit = useReactiveVar(createAuditVar);
  const { updateCreateAudit } = auditMutations;
  const { data } = useQuery(GET_SITES);

  const handleChange = (event, newValue) => {
    if (newValue) {
      updateCreateAudit("ospId", newValue.id);
      updateCreateAudit("location", newValue.location);
      if (updateAudit.audit && updateAudit.audit.name) {
        setStartAudit(true);
      }
    } else {
      updateCreateAudit("ospId", null);
      updateCreateAudit("location", null);
    }
  };

  const filterOptions = createFilterOptions({
    stringify: (option) => option.location,
  });

  return (
    <Grid item key={"location"} xs={12}>
      <Autocomplete
        disablePortal
        id="audit-select-location"
        filterOptions={filterOptions}
        options={
          data && data.ownerSiteProjects
            ? [...data.ownerSiteProjects].sort((a, b) =>
                a.location > b.location ? 1 : -1
              )
            : []
        }
        value={updateAudit.location ? updateAudit.location : { location: "" }}
        getOptionLabel={(option) => {
          return option.location === undefined ? option : option.location;
        }}
        renderOption={(props, option) => (
          <li {...props} key={`${option.location}-${option.id}`}>
            {option.location}
          </li>
        )}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) =>
          option.location === value.location || option.location === value
        }
        renderInput={(params) => (
          <TextField {...params} variant="standard" label={"Location:"} />
        )}
      />
    </Grid>
  );
}
