import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

import MuiPhoneNumber from "material-ui-phone-number";

export const CustomTextField = styled(TextField)(() => ({
  "& .MuiFormLabel-root": {
    color: "#fff",
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: "#fff",
  },
  "& .MuiFormLabel-root.Mui-disabled": {
    color: "#bdbdbd",
  },
  "& .MuiFormLabel-root.Mui-disabled": {
    color: "#bdbdbd",
  },
  "& .MuiInputBase-input": {
    color: "#fff",
  },
  "& .MuiInputBase-input.MuiInput-input.Mui-disabled": {
    "-webkit-text-fill-color": "#bdbdbd",
  },
  "& .MuiButtonBase-root": {
    color: "#fff",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "#fff8", // Semi-transparent underline
  },
  "& .MuiInput-underline:hover:before": {
    borderBottomColor: "#fff", // Solid underline on hover
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#fff", // Solid underline on focus
  },
  "& .MuiAutocomplete-tag": {
    color: "#fff",
  },
}));

export const CustomDateField = styled(MuiPhoneNumber)(() => ({
  "& .MuiFormLabel-root": {
    color: "#fff",
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: "#fff",
  },

  "& .MuiInputBase-input": {
    color: "#fff",
  },
  "& .MuiButtonBase-root": {
    color: "#fff",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "#fff8", // Semi-transparent underline
  },
  "& .MuiInput-underline:hover:before": {
    borderBottomColor: "#fff", // Solid underline on hover
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#fff", // Solid underline on focus
  },
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#fff",
  },
}));
