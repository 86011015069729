import React from "react";

import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Tooltip,
  Button,
  Fab,
  Badge,
} from "@mui/material";

import { useLazyQuery, useReactiveVar } from "@apollo/client";

import {
  createObservationVar,
  editObservationVar,
} from "../../../graphql/localVariables/observation";
import { onlineVar } from "../../../graphql/localVariables/user";
import { permissionsVar } from "../../../graphql/localVariables/user";
import {
  authMutations,
  observationMutations,
} from "../../../graphql/mutations";
import { GET_COMPANY_ARMS } from "../../../graphql/queries";
import { getRaScores } from "../../../utils/raScore";
import { blackButton, yellowButton } from "../../CustomStyles/buttons";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import Actions from "./Actions";
import Corrections from "./Corrections";
import General from "./General";
import Mitigators from "./Mitigators";
import Outcomes from "./Outcome";
import PhotoMenu from "./PhotoMenu";
import Risks from "./Risks";

export default function ObservationForm({
  type,
  submitForm,
  resetForm,
  checkNextSection,
  setExpanded,
  expanded,
  currentSection,
  onEditSection,
  user,
}) {
  // Global Variables
  const editObs = useReactiveVar(editObservationVar);
  const createObs = useReactiveVar(createObservationVar);
  const form = type === "EDIT" ? editObs : createObs;
  const online = useReactiveVar(onlineVar);
  const permissions = useReactiveVar(permissionsVar);

  // Mutations
  const { updateKeyEditObservation, updateKeyCreateObservation } =
    observationMutations;
  const { updateSnackbar } = authMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditObservation : updateKeyCreateObservation;

  const [getCompanyActions, { data: actions, loading: loadingActions }] =
    useLazyQuery(GET_COMPANY_ARMS, {
      fetchPolicy: online ? "network-only" : "cache-only",
    });
  const [getCompanyRisks, { data: risks, loading: loadingRisks }] =
    useLazyQuery(GET_COMPANY_ARMS, {
      fetchPolicy: online ? "network-only" : "cache-only",
    });
  const [getCompanyMit, { data: mit, loading: loadingMit }] = useLazyQuery(
    GET_COMPANY_ARMS,
    {
      fetchPolicy: online ? "network-only" : "cache-only",
    }
  );

  React.useEffect(() => {
    if (user && type !== "EDIT") {
      const companyId = Number(user.currentUser.company.id);
      getCompanyActions({ variables: { companyId, rNull: true, mNull: true } });
      getCompanyRisks({ variables: { companyId, rNull: false, mNull: true } });
      getCompanyMit({ variables: { companyId, rNull: false, mNull: false } });
    }
  }, [user]);

  React.useEffect(() => {
    if (form && form.submitter && type === "EDIT") {
      const companyId = Number(form.submitter.company.id);
      getCompanyActions({ variables: { companyId, rNull: true, mNull: true } });
      getCompanyRisks({ variables: { companyId, rNull: false, mNull: true } });
      getCompanyMit({ variables: { companyId, rNull: false, mNull: false } });
    }
  }, [form.submitter]);

  // for accordion to open
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [unselectedMitigators, setUnselectedMitigators] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClick = () => {
    if (expanded && expanded !== 1) {
      setOpen((prevOpen) => !prevOpen);
    } else {
      if (!expanded) {
        updateSnackbar({
          open: true,
          message: "Open an Observation section to add photos.",
          severity: "error",
        });
      }
      if (expanded === 1) {
        updateSnackbar({
          open: true,
          message: "Cannot add photos to General section.",
          severity: "error",
        });
      }
    }
  };

  const handleClose = (e) => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      return;
    }

    setOpen(false);
  };

  // different sections of accordion
  const accordionObject = [
    {
      id: 1,
      name: "General",
      component: <General type={type} onEditSection={onEditSection} />,
      description: "Involving or applicable to",
    },
    {
      id: 2,
      name: "Actions",
      component: (
        <Actions
          type={type}
          onEditSection={onEditSection}
          actions={actions}
          loading={loadingActions}
          permissions={permissions}
        />
      ),
      description:
        "In this section you will select which actions are being performed. \
         The easiest way to find the actions you want is to search a keyword like \
         “ladder” and select which ladder you’re observing from the list. If the \
         Action you want to observe is not present contact your NIXN administrator.",
    },
    {
      id: 3,
      name: "Risks",
      component: (
        <Risks
          type={type}
          onEditSection={onEditSection}
          risks={risks}
          loading={loadingRisks}
          permissions={permissions}
        />
      ),
      description:
        "In this section you want to add the specific risks which are present. \
        Expected Risks are already applied, but other risks which may or may not \
        be applicable will need to be added to the observation. If the Risk you \
        are observing is not present contact your NIXN administrator.",
    },
    {
      id: 4,
      name: "Mitigators",
      component: (
        <Mitigators
          type={type}
          onEditSection={onEditSection}
          unselectedMitigators={unselectedMitigators}
          setUnselectedMitigators={setUnselectedMitigators}
          mitigators={mit}
          loading={loadingMit}
          permissions={permissions}
        />
      ),
      description:
        "In this section you only want to select the Mitigators which are in place. \
        If the employees are not using a mitigator or the mitigator is something \
        you corrected, leave it unselected. If the mitigator is not present on the \
        list contact your NIXN administrator.",
    },
    {
      id: 5,
      name: "Corrections",
      component: (
        <Corrections
          type={type}
          onEditSection={onEditSection}
          unselectedMitigators={unselectedMitigators}
        />
      ),
      description:
        "In this section you will select what was corrected. Any unselected \
        mitigators from the previous page will populate here so you can select \
        which specific mitigator was corrected during the observation.",
    },
    {
      id: 6,
      name: "Outcomes",
      component: (
        <Outcomes
          type={type}
          onEditSection={onEditSection}
          mitigators={mit}
          loading={loadingMit}
        />
      ),
      description:
        "Unplanned work - Unplanned work is any work not listed on the Hazard \
        Analysis. If a Hazard Analysis was linked to the observation it will \
        automatically classify as planned or unplanned, but the toggle can also \
        be manually switched. Event occur as expected - If the event went as \
        planned and no work was stopped, no injuries occurred, and no \
        corrections were made then the work went as expected. Injury or property\
        damage - If an injury or property damage occurred then toggle this to \
        yes and select the injury outcome. You will also need to select the \
        root cause and associate a dollar amount to the injury or property \
        damage. Did event occur where…… - If the observation could’ve result \
        in injury or property damage with a slight change in timing or \
        positioning then toggle this to yes.",
    },
  ];

  const accordionSection = (item) => {
    return (
      <Accordion
        key={item.id}
        expanded={expanded === item.id}
        onChange={handleChange(item.id)}
        disabled={currentSection < item.id}
        disableGutters
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${item.name}-content`}
          id={`${item.name}-header`}
        >
          <Typography>{item.name}</Typography>
          <Tooltip title={`${item.description}`}>
            <InfoOutlinedIcon
              color="primary"
              sx={{
                fontSize: "small",
                alignSelf: "center",
                marginLeft: "3px",
              }}
            />
          </Tooltip>
        </AccordionSummary>
        <AccordionDetails>
          {expanded === item.id ? item.component : null}
        </AccordionDetails>
      </Accordion>
    );
  };

  const handleFilesChange = (files) => {
    updateObsKeyFunction("files", files);
  };

  const hitSubmit = (e) => {
    e.preventDefault();
    updateObsKeyFunction("disabled", true);
    submitForm();
  };

  const handleClear = (e) => {
    e.preventDefault();
    updateObsKeyFunction("disabled", false);
    resetForm();
  };

  React.useEffect(() => {
    if (actions && risks && mit) {
      const allArms = [
        ...actions.companyArms,
        ...risks.companyArms,
        ...mit.companyArms,
      ]
        .map((carm) => carm.arm)
        .map((arm) => {
          if (arm.r === null || arm.r.id === null)
            return { ...arm, r: null, m: null };
          if (arm.m === null || arm.m.id === null) return { ...arm, m: null };
          return arm;
        });

      const formattedInputs = [
        ...form.actions,
        ...form.risks,
        ...form.mitigators,
      ].map((arm) => {
        return {
          id: Number(arm.id),
          a: { id: Number(arm.a.id) },
          r: arm.r && arm.r.id ? { id: Number(arm.r.id) } : null,
          m: arm.m && arm.m.id ? { id: Number(arm.m.id) } : null,
        };
      });
      const formattedInputsWithUnselectedMit = [
        ...form.actions,
        ...form.risks,
        ...form.mitigators,
        ...form.unselectedMitigators.filter((m) => m.isCorrection),
      ].map((arm) => {
        return {
          id: Number(arm.id),
          a: { id: Number(arm.a.id) },
          r: arm.r && arm.r.id ? { id: Number(arm.r.id) } : null,
          m: arm.m && arm.m.id ? { id: Number(arm.m.id) } : null,
        };
      });

      const max = getRaScores(allArms, formattedInputs, "preMit");
      const min = getRaScores(allArms, formattedInputs, "postMit");
      const raScore = getRaScores(allArms, formattedInputs);
      const corr = getRaScores(allArms, formattedInputsWithUnselectedMit);

      updateObsKeyFunction("raScore", raScore);
      updateObsKeyFunction("raScoreMax", max);
      updateObsKeyFunction("raScoreMin", min);
      updateObsKeyFunction("raScoreCorr", corr);
    }
  }, [
    actions,
    risks,
    mit,
    form.actions,
    form.risks,
    form.mitigators,
    form.unselectedMitigators,
  ]);

  let scoreColor = form.raScore
    ? form.raScore > 90
      ? "red"
      : form.raScore > 70
      ? "gold"
      : "#FDBC02"
    : "white";

  return (
    <Grid
      container
      justifyContent="center"
      style={{
        height: "100%",
      }}
      sx={{ marginBottom: { xs: "85px", md: 0 } }}
    >
      <Grid container style={{ maxWidth: "1000px" }}>
        <Grid item sm={9}>
          <Typography
            sx={{ ...pageTitleStyles, fontSize: { xs: "2rem", md: "3rem" } }}
          >
            OBSERVATION FORM.
          </Typography>
        </Grid>
        <Grid
          item
          sm={3}
          alignItems="center"
          sx={{ width: "100%", textAlign: "center" }}
        >
          <Typography
            sx={{
              textShadow:
                "1px 1px #000, -1px 1px #000, 1px -1px #000, -1px -1px #000",
              color: scoreColor,
              fontSize: { xs: "2rem", md: "3rem" },
              fontWeight: "bolder",
            }}
          >
            {form.raScore ? form.raScore.toFixed(2) : ""}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            overflowY: "auto",
            borderRadius: "5px",
            backgroundColor: "white",
          }}
        >
          {accordionObject.map((item) => accordionSection(item))}
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="flex-start"
        style={{
          position: "fixed",
          width: "fit-content",
        }}
        sx={{
          left: { xs: 20, md: 40 },
          bottom: { xs: 125, md: 40 },
        }}
      >
        <Grid item sx={{ paddingBottom: "30px" }}>
          <Badge
            badgeContent={
              form.files.filter((f) => f.section === expanded).length
            }
            color="secondary"
          >
            <Tooltip title="Add Images">
              <Fab
                ref={anchorRef}
                style={{
                  backgroundColor:
                    form.files.filter((f) => f.section === expanded).length > 0
                      ? "#00000095"
                      : "#8297A095",
                }}
                onClick={handleClick}
              >
                <CameraAltIcon style={{ color: "white" }} />
              </Fab>
            </Tooltip>
          </Badge>
        </Grid>

        <PhotoMenu
          currentSection={expanded}
          anchorRef={anchorRef}
          open={open}
          handleClose={handleClose}
          fileObjects={form.files}
          handleFilesChange={handleFilesChange}
          updateObsKeyFunction={updateObsKeyFunction}
          form={form}
        />
      </Grid>
      <Button
        variant="contained"
        sx={{
          ...blackButton,
          position: "fixed",
          height: "fit-content",
          left: { xs: 20, md: 40 },
          bottom: { xs: 100, md: 20 },
        }}
        onClick={handleClear}
      >
        {type === "EDIT" ? "CLOSE" : "CLEAR"}
      </Button>
      {currentSection === 6 ? (
        <Button
          variant="contained"
          sx={{
            ...yellowButton,
            marginRight: "10px",
            height: "fit-content",
            position: "fixed",
            right: { xs: 20, md: 40 },
            bottom: { xs: 100, md: 20 },
          }}
          onClick={hitSubmit}
          disabled={form.disabled}
        >
          SUBMIT
        </Button>
      ) : (
        <Button
          variant="contained"
          sx={{
            ...yellowButton,
            marginRight: "10px",
            height: "fit-content",
            position: "fixed",
            right: { xs: 20, md: 40 },
            bottom: { xs: 100, md: 20 },
          }}
          onClick={checkNextSection}
        >
          NEXT
        </Button>
      )}
    </Grid>
  );
}
