import { createTheme } from "@mui/material/styles";

const mainTheme = createTheme({
  palette: {
    primary: {
      main: "#171717",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#8297A0",
    },
    grey0: {
      main: "#666",
    },
    grey1: {
      main: "#748087",
    },
    grey2: {
      main: "#8297A0",
    },
    grey3: {
      main: "#4D4D4D",
    },
    grey4: {
      main: "#34383D",
    },
    yellow0: {
      main: "#FFB700",
    },
    yellow1: {
      main: "#FCA800",
    },
    black0: {
      main: "#171717",
    },
    black1: {
      main: "#333",
    },
    white: {
      main: "#FFF",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  shape: {
    borderRadius: 5,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#333",
          minWidth: "300px",
          minHeight: "500px",
          margin: 0,
          fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
          'sans-serif'`,
          webkitFontSmoothing: "antialiased",
          mozOsxFontSmoothing: "grayscale",
        },
      },
    },
  },
});

export default mainTheme;
