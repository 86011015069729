import React from "react";

import {
  Autocomplete,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import _ from "lodash";

import {
  createObservationVar,
  editObservationVar,
} from "../../../graphql/localVariables/observation";
import { observationMutations } from "../../../graphql/mutations";
import {
  CREATE_ACTION,
  CREATE_MITIGATOR,
  CREATE_RISK,
} from "../../../graphql/mutations/admin/arm";
import {
  GET_ACTIONS,
  GET_MITIGATORS,
  GET_RISKS,
} from "../../../graphql/queries";
import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import TabPanel from "../../CustomComponents/TabPanels";
import { StyledTab, StyledTabs } from "../../CustomComponents/Tabs";
import { yellowButton } from "../../CustomStyles/buttons";
import { CustomDialog } from "../../CustomStyles/dialog";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";

export default function CreateARM({ open, handleClose, tab, type }) {
  const editObs = useReactiveVar(editObservationVar);
  const createObs = useReactiveVar(createObservationVar);
  const { updateKeyEditObservation, updateKeyCreateObservation } =
    observationMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditObservation : updateKeyCreateObservation;
  const form = type === "EDIT" ? editObs : createObs;

  const { data: userData } = useQuery(GET_CURRENT_USER);

  const [createAction] = useMutation(CREATE_ACTION, {
    onCompleted(data) {
      onCompletedFunc("Action has been sent for approval.");
      setCreateActionName("");
      const newArm = data.actionMutations?.arm;
      updateObsKeyFunction("actions", [...form.actions, newArm]);
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [{ query: GET_ACTIONS }],
  });
  const [createRisk] = useMutation(CREATE_RISK, {
    onCompleted(data) {
      onCompletedFunc("Risk has been sent for approval.");
      setCreateRiskAction({ a: { name: "" } });
      setCreateRiskName("");
      const newArm = data.riskMutations?.arm;
      updateObsKeyFunction("risks", [...form.risks, newArm]);
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [{ query: GET_RISKS }],
  });
  const [createMitigator] = useMutation(CREATE_MITIGATOR, {
    onCompleted(data) {
      onCompletedFunc("Mitigator has been sent for approval.");
      setCreateMitigatorName("");
      setCreateMitigatorAction({ a: { name: "" } });
      setCreateMitigatorRisk({ r: { name: "" } });
      const newArm = data.mitigatorMutations?.arm;
      updateObsKeyFunction("mitigators", [...form.mitigators, newArm]);
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [{ query: GET_MITIGATORS }],
  });

  let val = 0;
  switch (tab) {
    case "action":
      val = 0;
      break;
    case "risk":
      val = 1;
      break;
    case "mitigator":
      val = 2;
      break;
  }
  const [value, setValue] = React.useState(val);

  const [createActionName, setCreateActionName] = React.useState("");

  const [createRiskAction, setCreateRiskAction] = React.useState({
    a: { name: "" },
  });
  const [createRiskName, setCreateRiskName] = React.useState("");
  const [riskExpected, setRiskExpected] = React.useState(false);

  const [createMitigatorAction, setCreateMitigatorAction] = React.useState({
    a: { name: "" },
  });
  const [createMitigatorRisk, setCreateMitigatorRisk] = React.useState({
    r: { name: "" },
  });
  const [createMitigatorName, setCreateMitigatorName] = React.useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function titleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1);
    });
  }

  const onCreateAction = () => {
    if (createActionName.replace(/\s/g, "") !== "") {
      createAction({
        variables: {
          company: Number(userData?.currentUser?.company?.id),
          companyArm_IsApproved: false,
          companyArm_Exclusive: true,
          name: titleCase(createActionName),
          isActive: true,
          user: Number(userData.currentUser.id),
        },
      });
    } else onErrorFunc("Please provide a valid name for this action.");
  };

  const onCreateRisk = () => {
    if (createRiskName.replace(/\s/g, "") !== "") {
      createRisk({
        variables: {
          action: Number(createRiskAction.a.id),
          isExpected: riskExpected,
          company: Number(userData?.currentUser?.company?.id),
          companyArm_IsApproved: false,
          companyArm_Exclusive: true,
          name: titleCase(createRiskName),
          user: Number(userData.currentUser.id),
        },
      });
    } else onErrorFunc("Please provide a valid name for this risk.");
  };

  const onCreateMitigator = () => {
    if (createMitigatorName.replace(/\s/g, "") !== "") {
      createMitigator({
        variables: {
          action: Number(createMitigatorAction.a.id),
          risk: Number(createMitigatorRisk.r.id),
          company: Number(userData?.currentUser?.company?.id),
          companyArm_IsApproved: false,
          companyArm_Exclusive: true,
          name: titleCase(createMitigatorName),
          user: Number(userData.currentUser.id),
        },
      });
    } else onErrorFunc("Please provide a valid name for this mitigator.");
  };

  return (
    <CustomDialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle style={{ ...pageTitleStyles }}>CREATE ARM.</DialogTitle>
      <DialogContent>
        <Paper>
          <StyledTabs value={value} onChange={handleChange} variant="fullWidth">
            <StyledTab label="ACTION" />
            <StyledTab label="RISK" />
            <StyledTab label="MITIGATOR" />
          </StyledTabs>
          <TabPanel value={value} index={0}>
            <Grid container direction="column" alignItems="center">
              <TextField
                label="New Action Name:"
                variant="standard"
                style={{ margin: "10px 0px", width: "75%" }}
                value={createActionName}
                onChange={(event) => setCreateActionName(event.target.value)}
                inputProps={{ maxLength: 100 }}
              />
              <Button
                variant="contained"
                style={{
                  marginRight: "10px",
                  ...yellowButton,
                  width: "fit-content",
                }}
                onClick={onCreateAction}
              >
                CREATE
              </Button>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container direction="column" alignItems="center">
              <Autocomplete
                disablePortal
                id="select-action-create-risk"
                options={form.actions}
                value={createRiskAction}
                onChange={(event, value) => setCreateRiskAction(value)}
                getOptionLabel={(option) => option.a?.name}
                disableClearable
                style={{ width: "75%" }}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" label={"Action:"} />
                )}
              />
              <TextField
                label="New Risk Name:"
                variant="standard"
                style={{ margin: "10px 0px", width: "75%" }}
                value={createRiskName}
                onChange={(event) => setCreateRiskName(event.target.value)}
                disabled={createRiskAction.name === ""}
                inputProps={{ maxLength: 100 }}
              />
              <Grid item container alignItems="center" justifyContent="center">
                <Checkbox
                  checked={riskExpected}
                  onChange={(event) => setRiskExpected(event.target.checked)}
                />
                <Typography>Is Expected</Typography>
              </Grid>
              <Button
                variant="contained"
                style={{
                  marginRight: "10px",
                  ...yellowButton,
                  width: "fit-content",
                }}
                onClick={onCreateRisk}
              >
                CREATE
              </Button>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Grid container direction="column" alignItems="center">
              <Autocomplete
                disablePortal
                id="select-action-create-mitigator"
                options={form.actions}
                value={createMitigatorAction}
                onChange={(event, value) => setCreateMitigatorAction(value)}
                getOptionLabel={(option) => option.a.name}
                disableClearable
                style={{ width: "75%" }}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" label={"Action:"} />
                )}
              />
              <Autocomplete
                disablePortal
                id="select-risk-create-mitigator"
                options={form.risks.filter(
                  (arm) => arm.a.id === createMitigatorAction.a.id
                )}
                value={createMitigatorRisk}
                onChange={(event, value) => setCreateMitigatorRisk(value)}
                getOptionLabel={(option) => option.r.name}
                disableClearable
                disabled={createMitigatorAction.a.name === ""}
                style={{ width: "75%" }}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" label={"Risk:"} />
                )}
              />
              <TextField
                label="New Mitigator Name:"
                variant="standard"
                style={{ margin: "10px 0px", width: "75%" }}
                value={createMitigatorName}
                onChange={(event) => setCreateMitigatorName(event.target.value)}
                inputProps={{ maxLength: 200 }}
              />
              <Button
                variant="contained"
                style={{
                  marginRight: "10px",
                  ...yellowButton,
                  width: "fit-content",
                }}
                onClick={onCreateMitigator}
              >
                CREATE
              </Button>
            </Grid>
          </TabPanel>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          style={{ marginRight: "10px" }}
          onClick={handleClose}
        >
          CLOSE
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}
