import { makeStyles, withStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";

const useStyles = () =>
  makeStyles((theme) => ({
    root: {
      height: "100%",
      width: "100%",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    greetingText: {
      color: theme.palette.white.main,
      fontSize: "3em",
      fontFamily: "barlowextrabold",
    },
    name: {
      color: theme.palette.yellow0.main,
    },
    dateArrowIcon: {
      color: theme.palette.white.main,
      fontSize: "1.3em",
      marginRight: 10,
    },
    dateText: {
      fontSize: "1.3em !important",
      fontWeight: "bold !important",
      color: theme.palette.grey1.main,
    },
    divider: {
      color: theme.palette.black0.main,
    },
  }));

const textfieldStyles = (theme) => ({
  root: {
    "& .MuiInputBase-input": {
      color: theme.palette.white.main,
      zIndex: theme.zIndex.drawer + 1,
    },
    "& label.Mui-focused": {
      color: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "inherit",
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.light,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.light,
      },
    },
  },
});

const CustomTextField = withStyles(textfieldStyles)(TextField);

export { useStyles, CustomTextField };
