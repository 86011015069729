import * as React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ReusableTableHeadCell from "./ReusableTableHeadCell";

// Props
// order, orderBy, numSelected, onRequestSort, onSelectAllClick, rowCount - from ReusableMainTable
export default function TableHeadTemplate(props) {
  const { order, orderBy, onRequestSort } = props;

  // if filtering is available
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <ReusableTableHeadCell
              order={order}
              orderBy={orderBy}
              headCell={headCell}
              createSortHandler={createSortHandler}
              key={headCell.uid}
            />
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const headCells = [
  {
    uid: "name",
    numeric: false,
    disablePadding: false,
    label: "Location",
  },
  {
    uid: "timeCreated",
    numeric: true,
    disablePadding: false,
    label: "Date Created",
  },
  {
    uid: "active",
    numeric: false,
    disablePadding: false,
    label: "Active",
  },
];
