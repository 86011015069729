import { makeStyles } from "@mui/styles";

const useStyles = (drawerWidth) =>
  makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: theme.palette.black0.main,
      overflowX: "hidden",
    },
    content: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      backgroundColor: theme.palette.black1.main,
    },
    listIcons: {
      color: theme.palette.grey1.main,
      marginRight: 20,
    },
    listText: {
      color: theme.palette.grey1.main,
    },
    listEndBar: {
      backgroundColor: theme.palette.black0.main,
      width: "5px",
      height: "30px",
    },
  }));

export default useStyles;
