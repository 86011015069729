import * as React from "react";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { useMutation } from "@apollo/client";
import moment from "moment";
import { DropzoneDialog } from "mui-file-dropzone";

import { MUTATE_AUDIT } from "../../../../graphql/mutations/admin/audit";
import { EDIT_AUDIT_CSV } from "../../../../graphql/mutations/audits";
import { GET_AUDITS } from "../../../../graphql/queries/admin/audits";
import {
  onErrorFunc,
  onCompletedFunc,
} from "../../../CustomComponents/OnErrorFunction";
import { CustomSwitch } from "../../../CustomComponents/Switch";
import { yellowButton } from "../../../CustomStyles/buttons";
import { CustomDialog } from "../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../CustomStyles/pageTitle";
import ViewDialog from "./ViewDialog";

function Row({ row }) {
  const [open, setOpen] = React.useState(false);
  const [openEdit, setEditOpen] = React.useState(false);
  const [editAudit] = useMutation(MUTATE_AUDIT, {
    onCompleted() {},
    refetchQueries: [{ query: GET_AUDITS }],
  });

  const [uploadAuditCSV] = useMutation(EDIT_AUDIT_CSV, {
    onCompleted(data) {
      if (data.uploadAuditMutation) {
        onCompletedFunc("Audit has been edited");
      } else {
        onErrorFunc("There was an error in the CSV format");
      }
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [
      {
        query: GET_AUDITS,
      },
    ],
  });

  const handleSave = (file) => {
    uploadAuditCSV({
      variables: { id: Number(row.id), file: file[0], edit: true },
    });
    setEditOpen(false);
  };

  const onCancel = () => {
    setOpen(false);
  };

  const handleIsActiveChange = (event) => {
    editAudit({
      variables: {
        input: { id: Number(row.id), isActive: event.target.checked },
      },
    });
  };
  return (
    <>
      <TableRow hover key={row.id}>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell component="th" scope="row">
          {moment(row.timeCreated).format("MM/DD/YYYY hh:mm A")}
        </TableCell>
        <TableCell component="th" scope="row">
          <CustomSwitch
            checked={row.isActive}
            name={row.id}
            onChange={(event) => handleIsActiveChange(event)}
          />
        </TableCell>
        <TableCell>
          <Button style={{ ...yellowButton }} onClick={() => setOpen(true)}>
            VIEW
          </Button>
        </TableCell>
        <TableCell>
          <Button style={{ ...yellowButton }}>
            <a
              href={row.csv}
              target="_blank"
              type="text/csv"
              download={`${row.name}.csv`}
              style={{ color: "black", textDecoration: "none" }}
              rel="noreferrer"
            >
              Download CSV
            </a>
          </Button>
        </TableCell>
        <TableCell>
          <Button style={{ ...yellowButton }} onClick={() => setEditOpen(true)}>
            Edit
          </Button>
        </TableCell>
      </TableRow>
      <DropzoneDialog
        open={openEdit}
        onSave={handleSave}
        acceptedFiles={[
          ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
        ]}
        showPreviews={true}
        filesLimit={1}
        onClose={() => setEditOpen(false)}
        maxFileSize={10000000} // 10MB
      />
      <CustomDialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={() => setOpen(false)}
      >
        <DialogTitle
          style={{
            ...pageTitleStyles,
          }}
        >
          VIEW AUDIT TYPE.
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column">
            <Grid item container style={{ marginTop: "20px" }}>
              <Typography
                style={{
                  color: "white",
                  marginBottom: "5px",
                  fontSize: "1.2rem",
                }}
              >
                {row.name}
              </Typography>
              <ViewDialog audit={row} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onCancel}>
            CANCEL
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
}

export default function TableBodyTemplate(props) {
  // these props are available in table body
  const { order, orderBy, page, rowsPerPage, getComparator, stableSort, data } =
    props;

  if (
    stableSort(data, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    ).length === 0
  ) {
    return (
      <TableRow>
        <TableCell style={{ borderBottom: "transparent" }} colSpan={5}>
          No Results Found
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {stableSort(data, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => (
          <Row key={row.id} row={row} />
        ))}
    </>
  );
}
