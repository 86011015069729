import React from "react";

import { Autocomplete, TextField } from "@mui/material";

import { useLazyQuery, useReactiveVar } from "@apollo/client";

import {
  createObservationVar,
  editObservationVar,
} from "../../../../graphql/localVariables/observation";
import { onlineVar } from "../../../../graphql/localVariables/user";
import { observationMutations } from "../../../../graphql/mutations";
import { GET_LOCAL_CONTRACTORS_AT_OWNER_SITE_PROJECT } from "../../../../graphql/queries";
import { GET_FILTERED_CONTRACTORS_OBS } from "../../../../graphql/queries/observations";

export default function Contractors({ type, onEdit }) {
  // Global Variables
  const editObs = useReactiveVar(editObservationVar);
  const createObs = useReactiveVar(createObservationVar);
  const online = useReactiveVar(onlineVar);
  const form = type === "EDIT" ? editObs : createObs;

  // Queries
  const [getContractor, { data }] = useLazyQuery(
    GET_LOCAL_CONTRACTORS_AT_OWNER_SITE_PROJECT
  );
  const [getOfflineContractor, { data: offlineContractors }] = useLazyQuery(
    GET_FILTERED_CONTRACTORS_OBS
  );

  // Mutations
  const { updateKeyEditObservation, updateKeyCreateObservation } =
    observationMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditObservation : updateKeyCreateObservation;

  React.useEffect(() => {
    if (form.site && form.owner) {
      if (online) {
        getContractor({
          variables: {
            siteId: Number(form.site?.id),
            ownerId: Number(form.owner?.id),
          },
        });
      } else {
        getOfflineContractor({
          variables: {
            siteId: Number(form.site?.id),
            ownerId: Number(form.owner?.id),
          },
        });
      }
    }
  }, [form.site, form.owner]);

  const handleChange = (event, newValue) => {
    if (newValue) {
      updateObsKeyFunction(
        "contractor",
        newValue.contractor ? newValue.contractor : newValue
      );
    } else {
      updateObsKeyFunction("contractor", null);
    }
    onEdit();
  };

  const options = online
    ? data
      ? [...data.ownerSiteProjectContractors].sort((a, b) =>
          a.contractor.name > b.contractor.name ? 1 : -1
        )
      : []
    : offlineContractors
    ? [...offlineContractors.filterContractors].sort((a, b) =>
        a.name > b.name ? 1 : -1
      )
    : [];

  return (
    <Autocomplete
      id="select-contractor"
      value={form.contractor || { name: "" }}
      options={options}
      getOptionLabel={(option) => {
        return option.contractor ? option.contractor.name : option.name;
      }}
      onChange={handleChange}
      disabled={form.project ? !form.project.id : true}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Who did you observe?"
        />
      )}
    />
  );
}
