import * as React from "react";

import { useQuery, useReactiveVar } from "@apollo/client";

import { onlineVar } from "../../../../../graphql/localVariables/user";
import { authMutations } from "../../../../../graphql/mutations";
import { GET_TEMPLATES } from "../../../../../graphql/queries/admin/planning-template";
import TableMain from "./ReusableTableLayout";
import ReusableTableToolbar from "./ReusableTableToolbar";
import TableBodyTemplate from "./TableBodyTemplate";
import TableHeadTemplate from "./TableHeadTemplate";
import { filterData } from "./searchFilterFunction";

// Props
// hasPagination - boolean
// data - array of objects
// 3 children component
//    If using the ReusableTableToolbar
//    - title - string - displayed at the top
//    - onDeleteClick - when trash can on toolbar is clicked
//    - onSearchChange - when input in search bar
//    - searchTerm - string
export default function Table() {
  const online = useReactiveVar(onlineVar);
  const { data, loading } = useQuery(GET_TEMPLATES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const { updateLoading } = authMutations;

  const onSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const onActiveChange = (event, newValue) => {
    setActiveFilter(newValue || "");
  };

  const clearFilters = () => {
    setActiveFilter("");
  };

  const [searchTerm, setSearchTerm] = React.useState("");
  const [active, setActiveFilter] = React.useState("");

  const [filteredData, setFilteredData] = React.useState([]);

  // useEffect for filter update
  React.useEffect(() => {
    setFilteredData(
      filterData(
        data && data.templates ? data.templates : [],
        searchTerm,
        active
      )
    );
  }, [searchTerm, active, data]);

  // show loading
  React.useEffect(() => {
    updateLoading(loading);
  }, [loading]);

  return (
    <TableMain
      CustomTableHeader={<TableHeadTemplate />}
      CustomTableBody={<TableBodyTemplate />}
      CustomTableToolBar={
        <ReusableTableToolbar
          searchTerm={searchTerm}
          onSearchChange={onSearchChange}
          active={active}
          onActiveChange={onActiveChange}
          clearFilters={clearFilters}
          title={"Templates"}
        />
      }
      hasPagination={true}
      data={filteredData}
    />
  );
}
