import React from "react";
import { BrowserView, isTablet, MobileView } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";

import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import {
  Document,
  Image,
  Link,
  Page,
  pdf,
  PDFDownloadLink,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import ExifReader from "exifreader";
import moment from "moment";

import {
  onlineVar,
  permissionsVar,
} from "../../../graphql/localVariables/user";
import {
  INCIDENT_EDIT,
  INCIDENT_EMAIL,
} from "../../../graphql/mutations/investigation";
import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import { GET_INVESTIGATION_RESPONSES } from "../../../graphql/queries/incidentInvestigations";
import logo from "../../../images/nixn-logo-white.png";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import {
  blackButton,
  blueButton,
  yellowButton,
} from "../../CustomStyles/buttons";
import { CustomDialog } from "../../CustomStyles/dialog";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import Layout from "../../Layout";
import ViewImages from "./ViewImages";

const styles = StyleSheet.create({
  page: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Helvetica",
  },
  header: { fontSize: "12px", fontFamily: "Helvetica-Bold" },
  formSection: { width: "147.3px", border: "1px solid black" },
  armSectionHeader: { width: "33.3%" },
  column: { display: "flex", flexDirection: "column" },
  row: { display: "flex", flexDirection: "row" },
  text: { padding: "20px", fontSize: "10px" },
});

export default function ViewSingleInvestigation() {
  const navigate = useNavigate();
  const { id } = useParams();
  const online = useReactiveVar(onlineVar);
  const permissions = useReactiveVar(permissionsVar);

  const [open, setOpen] = React.useState(false);
  const [openClose, setOpenClose] = React.useState(false);

  const { data, loading } = useQuery(GET_INVESTIGATION_RESPONSES, {
    variables: { id: Number(id) },
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const { data: currentUser } = useQuery(GET_CURRENT_USER);
  const [editIncidentMutation] = useMutation(INCIDENT_EDIT, {
    onCompleted(data) {
      if (data.incidentEdit.incidentReport) {
        onCompletedFunc("Incident investigation was closed successfully!");
      } else {
        onErrorFunc(
          "There was an error when editing your incident investigation."
        );
      }
    },
    onError(error) {
      onErrorFunc(error);
    },
  });
  const [emailMutation] = useMutation(INCIDENT_EMAIL, {
    onCompleted() {
      onCompletedFunc("Email will be sent!");
    },
    onError(error) {
      onErrorFunc(error);
    },
  });

  const navigateToEdit = () => {
    navigate(`/edit-incident/${id}`);
  };

  const getNumDays = (startDate, endDate) => {
    var d1 = moment(startDate);
    var d2 = moment(endDate);
    var countAll = 0;
    var countNoWeekend = 0;

    while (d1 < d2) {
      var day = d1.day();
      var isWeekend = day === 6 || day === 0;
      if (isWeekend) {
        countAll++;
      } else {
        countAll++;
        countNoWeekend++;
      }
      d1.add(1, "days");
    }
    return { countAll, countNoWeekend };
  };

  const renderQuestion = (question) => {
    const questionStyle = {};
    const textStyle = {
      fontSize: "14px",
      margin: "20px 0px",
    };

    switch (question.incidentQuestion.type) {
      case "TextInput":
        return (
          <View style={questionStyle}>
            <Text style={textStyle}>{question.textInput}</Text>
          </View>
        );
      case "TextBox":
        return (
          <View style={questionStyle}>
            <Text style={textStyle}>{question.textBox}</Text>
          </View>
        );
      case "MultipleChoice":
        return (
          <View style={questionStyle}>
            <Text style={textStyle}>
              {question.options.length > 0
                ? question.options[0].option.name
                : ""}
            </Text>
          </View>
        );
      case "Boolean":
        return (
          <View style={questionStyle}>
            <Text style={textStyle}>{question.boolean ? "Yes" : "No"}</Text>
          </View>
        );
      case "Checkbox":
        return (
          <View style={questionStyle}>
            <Text style={textStyle}>
              {question.options.map((o) => o.option.name).join(", ")}
            </Text>
          </View>
        );
      case "PhoneNumber":
        return (
          <View style={questionStyle}>
            <Text style={textStyle}>{question.textInput}</Text>
          </View>
        );
      case "DateField":
        return (
          <View style={questionStyle}>
            <Text style={textStyle}>{question.date}</Text>
          </View>
        );
      case "MultiDateRange":
        let countAll = 0;
        let countNoWeekend = 0;

        question.dateRanges.forEach((r) => {
          const { countAll: all, countNoWeekend: noWeekend } = getNumDays(
            r.startDate,
            r.endDate
          );
          countAll = countAll + all;
          countNoWeekend = countNoWeekend + noWeekend;
        });

        return (
          <View style={questionStyle}>
            {question.dateRanges.map((dr, index) => {
              return (
                <View
                  key={`${dr.startDate} to ${dr.endDate}`}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                  }}
                >
                  {index > 0 ? <Text>{"\n"}</Text> : null}
                  <Text style={[textStyle, { width: "100%" }]}>
                    {`${moment(dr.startDate).format("MM/DD/YYYY")} to ${moment(
                      dr.endDate
                    ).format("MM/DD/YYYY")}`}
                    {"\n"}
                  </Text>
                </View>
              );
            })}
            <Text>{"\n"}</Text>
            <Text style={textStyle}>
              Number of days excluding weekends: {countNoWeekend} {"\n"}
            </Text>
            <Text style={textStyle}>
              Number of days including weekends: {countAll} {"\n"}
            </Text>
          </View>
        );
      default:
        return (
          <View>
            <Text> </Text>
          </View>
        );
    }
  };

  const getTopic = (topic, i) => {
    if (
      topic.name === "Incident Classification - US (OSHA)" &&
      i.type !== "Injury"
    )
      return (
        <View>
          <Text> </Text>
        </View>
      );
    return (
      <View key={topic.id} style={{ width: "100%" }}>
        {topic.name !== "Incident Details - US (OSHA)" &&
          topic.name !== "General" && (
            <Text
              style={[
                styles.header,
                {
                  borderTop: "3px solid grey",
                  paddingTop: "10px",
                  fontSize: "16px",
                },
              ]}
            >
              {topic.name.toUpperCase()}
            </Text>
          )}
        {topic.name === "Incident Details - US (OSHA)" ? (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {[...topic.incidentQuestions]
              .sort((a, b) => a.ordering - b.ordering)
              .map((q) => (
                <View
                  key={q.id}
                  style={[
                    styles.armSectionHeader,
                    { width: "100%", margin: "20px 0px" },
                  ]}
                >
                  <Text
                    style={[
                      styles.header,
                      { marginBottom: "10px", paddingRight: "10px" },
                    ]}
                  >
                    {q.name}
                  </Text>
                  <View>
                    {i.incidentQuestionResponses.find(
                      (resp) => resp.incidentQuestion.id === q.id
                    ) ? (
                      renderQuestion(
                        i.incidentQuestionResponses.find(
                          (resp) => resp.incidentQuestion.id === q.id
                        )
                      )
                    ) : (
                      <View>
                        <Text> </Text>
                      </View>
                    )}
                  </View>
                </View>
              ))}
          </View>
        ) : (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {[...topic.incidentQuestions]
              .sort((a, b) => a.ordering - b.ordering)
              .map((q) => (
                <View
                  key={q.id}
                  style={[
                    styles.armSectionHeader,
                    {
                      width: q.name === "What Happened?" ? "100%" : "48%",
                      margin: "10px 0px",
                      marginRight: "5px",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.header,
                      { marginBottom: "10px", paddingRight: "10px" },
                    ]}
                  >
                    {q.name}
                  </Text>
                  <View>
                    {i.incidentQuestionResponses.find(
                      (resp) => resp.incidentQuestion.id === q.id
                    ) ? (
                      renderQuestion(
                        i.incidentQuestionResponses.find(
                          (resp) => resp.incidentQuestion.id === q.id
                        )
                      )
                    ) : (
                      <View>
                        <Text> </Text>
                      </View>
                    )}
                  </View>
                </View>
              ))}
          </View>
        )}
        <View>{getResponses(topic.id, i)}</View>
      </View>
    );
  };

  const width = "150px";

  const IncidentImage = ({ img }) => {
    const [exif, setExif] = React.useState({});

    React.useEffect(() => {
      async function fetchData() {
        const res = await fetch(img);
        const blob = await res.blob();
        const arrBuff = await blob.arrayBuffer();
        const tags = ExifReader.load(arrBuff);
        setExif(tags);
      }
      fetchData();
    }, []);

    let style;
    const rotVal = exif.Orientation?.value;
    if (rotVal == 1 || rotVal == undefined) {
      style = { width };
    } else if (rotVal == 3) {
      style = { width, transform: "rotate(180deg)" };
    } else if (rotVal == 6) {
      style = { width, transform: "rotate(90deg)" };
    } else if (rotVal == 8) {
      style = { width, transform: "rotate(270deg)" };
    }

    return <Image style={{ ...style, marginTop: "10px" }} src={img} />;
  };

  const getResponses = (id, i) => {
    const responses = i.incidentTopicResponses.find(
      (resp) => resp.incidentTopic.id === id
    );

    const getPreviewIcon = (fileObject) => {
      if (
        fileObject.file &&
        (fileObject.file.includes("png") ||
          fileObject.file.includes("jpg") ||
          fileObject.file.includes("jpeg"))
      ) {
        return (
          <Link
            key={fileObject.id}
            src={fileObject.file}
            style={{
              color: "white",
              fontSize: "14px",
              margin: "5px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width,
              minHeight: "150px",
            }}
          >
            <IncidentImage key={fileObject.id} img={fileObject.file} />
          </Link>
        );
      }

      return (
        <>
          {fileObject.file && (
            <Link
              key={fileObject.id}
              src={fileObject.file}
              style={{ color: "white", fontSize: "14px", margin: "10px" }}
            >
              <Text>
                {
                  fileObject.file.split("/")[
                    fileObject.file.split("/").length - 1
                  ]
                }
              </Text>
            </Link>
          )}
        </>
      );
    };

    return (
      <View>
        {responses && (responses.note !== "" || responses.files.length > 0) ? (
          <View
            style={{
              marginBottom: "20px",
              backgroundColor: "#8297A0",
              color: "white",
              padding: "10px",
            }}
          >
            <Text style={{ fontSize: "14px", fontFamily: "Helvetica-Bold" }}>
              Note:
            </Text>
            <Text style={{ fontSize: "14px", margin: "10px 0px" }}>
              {responses.note}
            </Text>
            {responses.files.length > 0 && (
              <View style={{ margin: "20px 0px" }}>
                <Text
                  style={{
                    fontSize: "14px",
                    fontFamily: "Helvetica-Bold",
                    margin: "10px 0px",
                  }}
                  wrap={false}
                >
                  Files:
                </Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {responses.files.map((f) => getPreviewIcon(f))}
                </View>
              </View>
            )}
          </View>
        ) : (
          <View>
            <Text> </Text>
          </View>
        )}
      </View>
    );
  };

  const PDFDocument = () => {
    const i = data.incidentReport;

    const startTime = moment();
    const timeArr = i.time.split(":");
    startTime.hours(Number(timeArr[0])).minutes(Number(timeArr[1]));

    const injuryTime = moment();
    const injuryTimeArr = i.injuryTime.split(":");
    injuryTime
      .hours(Number(injuryTimeArr[0]))
      .minutes(Number(injuryTimeArr[1]));

    const dob = new Date(i.employeeDob);
    let utc = dob.getTime() + dob.getTimezoneOffset() * 60000;
    const convertedDob = new Date(utc);

    const incidentDate = new Date(i.injuryDate);
    utc = incidentDate.getTime() + incidentDate.getTimezoneOffset() * 60000;
    const convertedIncidentDate = new Date(utc);

    return (
      <Document
        pageMode="fullScreen"
        title="Incident Investigation"
        author="NIXN"
      >
        <Page size="A4" style={{ padding: 20 }}>
          <View style={styles.page}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "7%",
                marginBottom: "3%",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "90px",
                }}
              >
                <Text style={{ fontFamily: "Helvetica-Bold" }}>INCIDENT</Text>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>REPORT</Text>
              </View>
              {i.type.toUpperCase() === "PROPERTY DAMAGE" ? (
                <Text
                  style={{
                    width: "85px",
                    fontSize: 14,
                  }}
                >
                  {i.type.toUpperCase()}
                </Text>
              ) : (
                <Text
                  style={{
                    width: "75px",
                  }}
                >
                  {i.type.toUpperCase()}
                </Text>
              )}
              <View
                style={{ width: "40%", borderBottom: "3px solid #FCA800" }}
              ></View>
              {i &&
              i.submitter.company &&
              i.submitter.company.logoBase64 !== "" ? (
                <Image
                  src={`data:image;base64,${i.submitter.company.logoBase64}`}
                  style={{ maxWidth: "100px" }}
                />
              ) : (
                <Image src={logo} style={{ maxWidth: "100px" }} />
              )}
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                margin: "0px auto",
                width: "95%",
                height: "90%",
              }}
            >
              <View style={{ width: "100%" }}>
                <Text
                  style={[
                    styles.header,
                    {
                      fontSize: "16px",
                      paddingBottom: "20px",
                      borderTop: "3px solid grey",
                      paddingTop: "10px",
                    },
                  ]}
                >
                  GENERAL INFORMATION
                </Text>
              </View>
              <View style={styles.armSectionHeader}>
                <Text style={styles.header}>Type</Text>
                <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                  {i.type}
                </Text>
              </View>
              <View style={styles.armSectionHeader}>
                <Text style={styles.header}>Date</Text>
                <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                  {moment(new Date(convertedDate)).format("MM/DD/YYYY")}
                </Text>
              </View>
              <View style={styles.armSectionHeader}>
                <Text style={styles.header}>Time</Text>
                <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                  {startTime.format("hh:mm A")}
                </Text>
              </View>
              <View style={styles.armSectionHeader}>
                <Text style={styles.header}>Owner</Text>
                <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                  {i.ownerSiteProject.owner?.name}
                </Text>
              </View>
              <View style={styles.armSectionHeader}>
                <Text style={styles.header}>Site</Text>
                <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                  {i.ownerSiteProject.site?.name}
                </Text>
              </View>
              <View style={styles.armSectionHeader}>
                <Text style={styles.header}>Project</Text>
                {i.ownerSiteProject.project && (
                  <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                    {i.ownerSiteProject.project?.name}
                  </Text>
                )}
              </View>
              <View style={styles.armSectionHeader}>
                <Text style={styles.header}>HA</Text>
                <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                  {i.ha?.name}
                </Text>
              </View>
              <View style={styles.armSectionHeader}>
                <Text style={styles.header}>Weather</Text>
                <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                  {i.weather}
                </Text>
              </View>
              <View style={styles.armSectionHeader}>
                <Text style={styles.header}>Submitted By</Text>
                <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                  {i.submitter.username}
                </Text>
              </View>

              {getTopic(
                [...i.incident.incidentTopics].sort(
                  (a, b) => a.ordering - b.ordering
                )[0],
                i
              )}
              <View style={{ width: "100%" }}>
                <Text
                  style={[
                    styles.header,
                    {
                      fontSize: "16px",
                      paddingBottom: "20px",
                      borderTop: "3px solid grey",
                      paddingTop: "10px",
                    },
                  ]}
                >
                  INCIDENT DETAILS - US (OSHA)
                </Text>
              </View>
              <View style={styles.armSectionHeader}>
                <Text style={styles.header}>Employee Name</Text>
                <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                  {i.employeeName}
                </Text>
              </View>
              <View style={styles.armSectionHeader}>
                <Text style={styles.header}>Employee Job Title</Text>
                <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                  {i.employeeJobTitle}
                </Text>
              </View>
              <View style={styles.armSectionHeader}>
                <Text style={styles.header}>Employee Phone Number</Text>
                <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                  {i.employeePhoneNumber}
                </Text>
              </View>
              <View style={styles.armSectionHeader}>
                <Text style={styles.header}>Employee Date of Birth</Text>
                <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                  {moment(convertedDob).format("MM/DD/YYYY")}
                </Text>
              </View>
              <View style={styles.armSectionHeader}>
                <Text style={styles.header}>Incident Date/Time</Text>
                <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                  {moment(convertedIncidentDate).format("MM/DD/YYYY")}{" "}
                  {injuryTime.format("hh:mm A")}
                </Text>
              </View>
              {/* INCIDENT DETAILS is 2nd */}
              {getTopic(
                [...i.incident.incidentTopics].sort(
                  (a, b) => a.ordering - b.ordering
                )[1],
                i
              )}
            </View>
          </View>
        </Page>

        <Page size="A4" style={{ padding: 20 }}>
          <View style={styles.page}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {[...i.incident.incidentTopics]
                .sort((a, b) => a.ordering - b.ordering)
                .slice(2)
                .map((topic) => getTopic(topic, i))}
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  const mobileView = (
    <Paper
      style={{
        width: "100%",
        height: "300px",
        marginTop: "20px",
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "300px" }}
      >
        <Typography>
          Cannot view PDF on mobile device. Download PDF in order to view.
        </Typography>
      </Grid>
    </Paper>
  );

  const formatQuestionResponses = (arr) => {
    return arr.map((r) => {
      if (r.multipleChoice) {
        return {
          incidentQuestion: Number(r.incidentQuestion.id),
          options: [Number(r.multipleChoice)],
        };
      } else if (r.checkbox) {
        return {
          incidentQuestion: Number(r.incidentQuestion.id),
          options: r.options ? r.options.map((o) => Number(o.option?.id)) : [],
        };
      } else if (r.boolean) {
        return {
          incidentQuestion: Number(r.incidentQuestion.id),
          boolean: r.boolean === "true" || r.boolean === true ? true : false,
        };
      } else {
        return {
          date: r.date,
          dateRanges: [],
          textBox: r.textBox,
          textInput: r.textInput,
          time: r.time,
          incidentQuestion: Number(r.incidentQuestion.id),
          ...(r.options
            ? {
                options: r.options.map((o) =>
                  Number(o.option ? o.option.id : o)
                ),
              }
            : null),
        };
      }
    });
  };

  const getInput = (report) => {
    return {
      incident: report.incident.id,
      arms: [],
      ha: report.ha ? Number(report.ha.id) : null,
      ownerSiteProject: Number(report.ownerSiteProject.id),
      date: report.date,
      time: report.time,
      employeePhoneNumber: report.employeePhoneNumber
        ? report.employeePhoneNumber
        : "",
      employeeName: report.employeeName ? report.employeeName : "",
      employeeJobTitle: report.employeeJobTitle ? report.employeeJobTitle : "",
      employeeDob: report.employeeDob ? report.employeeDob : null,
      injuryDate: report.injuryDate ? report.injuryDate : null,
      injuryTime: report.injuryTime ? report.injuryTime : null,
      topicResponses: report.incidentTopicResponses.map((r) => ({
        incidentTopic: Number(r.incidentTopic.id),
        note: r.note,
      })),
      questionResponses: formatQuestionResponses(
        report.incidentQuestionResponses
      ),
      removeTopicFiles: [],
      removeQuestionFiles: [],
      isCompleted: true,
    };
  };

  const sendEmail = async () => {
    let blobPdf = await pdf(PDFDocument()).toBlob();
    let file = new File([blobPdf], "pdf_incident_report", {
      type: "application/pdf",
    });
    const report = data.incidentReport;
    emailMutation({
      variables: { id: Number(report.id), file },
    });
  };

  const closeIncident = () => {
    setOpenClose(false);
    const report = data.incidentReport;
    const input = getInput(report);
    input.isCompleted = true;
    editIncidentMutation({
      variables: { id: Number(report.id), input },
    });
  };

  const isOwner =
    data?.incidentReport.submitter.username ===
    currentUser?.currentUser.username;

  const closed = data?.incidentReport.isCompleted;

  const d = new Date(data?.incidentReport.date);
  const utc = d.getTime() + d.getTimezoneOffset() * 60000;
  const convertedDate = new Date(utc);

  return (
    <Layout>
      <Grid container justifyContent="space-between" alignContent="center">
        <Typography
          sx={{ ...pageTitleStyles, fontSize: { xs: "2rem", md: "3rem" } }}
        >
          VIEW INCIDENT INVESTIGATION.
        </Typography>
        <Button
          onClick={() => navigate("/view-incident")}
          style={{ color: "white" }}
        >
          GO BACK
        </Button>

        {loading ? (
          <Grid container justifyContent="center">
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <Grid container>
            <Grid
              item
              xs={12}
              container
              direction="row"
              alignItems="center"
              style={{ marginBottom: "20px" }}
            >
              {!closed &&
                (isOwner ||
                  permissions.includes("EDIT_OTHER_INCIDENT_REPORT")) && (
                  <Button
                    style={{ width: "fit-content", ...yellowButton }}
                    variant="contained"
                    onClick={navigateToEdit}
                  >
                    Edit
                  </Button>
                )}
              {!closed && permissions.includes("CLOSE_INCIDENT_REPORT") && (
                <Button
                  style={{
                    width: "fit-content",
                    backgroundColor: "red",
                    marginLeft: "20px",
                  }}
                  variant="contained"
                  onClick={() => setOpenClose(true)}
                >
                  Close
                </Button>
              )}
              <CustomDialog
                open={openClose}
                onClose={() => setOpenClose(false)}
              >
                <DialogTitle>Close Incident Investigation</DialogTitle>
                <DialogContent>
                  <Typography>
                    Are you sure you want to close this investigation? Once
                    closed, it cannot be edited or reopened.
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{
                      width: "fit-content",
                      backgroundColor: "red",
                      color: "white",
                    }}
                    onClick={closeIncident}
                  >
                    CLOSE
                  </Button>
                  <Button
                    style={blueButton}
                    onClick={() => setOpenClose(false)}
                  >
                    CANCEL
                  </Button>
                </DialogActions>
              </CustomDialog>
              <Button
                style={{
                  width: "fit-content",
                  marginLeft: "20px",
                  ...blueButton,
                }}
                variant="contained"
                onClick={sendEmail}
              >
                Send to Email
              </Button>
              {data && (
                <PDFDownloadLink
                  document={PDFDocument()}
                  fileName={`INCIDENT_REPORT_${moment(convertedDate).format(
                    "MMDDYYYY"
                  )}`.replace(/[:<>*?".|\/\s]/g, "")}
                  style={{ textDecoration: "none" }}
                >
                  {({ loading }) =>
                    loading ? (
                      "Loading document..."
                    ) : (
                      <div>
                        <Button
                          style={{
                            color: "white",
                            ...blackButton,
                            marginLeft: "20px",
                          }}
                        >
                          Download PDF
                        </Button>
                      </div>
                    )
                  }
                </PDFDownloadLink>
              )}
              {data && (
                <>
                  <Button
                    style={{ width: "fit-content", marginLeft: "20px" }}
                    variant="contained"
                    onClick={() => setOpen(true)}
                  >
                    View Files
                  </Button>
                  <ViewImages
                    open={open}
                    handleClose={() => setOpen(false)}
                    images={data.incidentReport.incidentTopicResponses}
                  />
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              {data && (
                <Grid>
                  {isTablet ? (
                    <>{mobileView}</>
                  ) : (
                    <>
                      <BrowserView>
                        {data?.incidentReport && (
                          <PDFViewer width="100%" height="800px">
                            {PDFDocument()}
                          </PDFViewer>
                        )}
                      </BrowserView>
                      <MobileView>{mobileView}</MobileView>
                    </>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Layout>
  );
}
