import * as React from "react";
import TextField from "@mui/material/TextField";
import { useReactiveVar } from "@apollo/client";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { createAuditVar } from "../../../graphql/localVariables/audit";
import { auditMutations } from "../../../graphql/mutations";

export default function BasicDateTimePicker() {
  const updateAudit = useReactiveVar(createAuditVar);
  const { updateCreateAudit } = auditMutations;

  const handleChange = (date) => {
    updateCreateAudit("time", date);
  };

  return (
    <MobileTimePicker
      label="Time:"
      inputFormat="hh:mm"
      value={updateAudit.time}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          style={{
            width: "100%",
          }}
          variant="standard"
        />
      )}
    />
  );
}
