/* eslint-disable quotes */
export const pageTitleStyles = {
  fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI"',
  fontWeight: "bolder",
  fontSize: "2.75rem",
  color: "#FFF",
};

export const loginTitleStyles = {
  fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI"',
  fontWeight: "bolder",
  fontSize: "2.5rem",
  color: "black",
};

export const headerStyles = {
  fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI"',
  fontWeight: "bolder",
  fontSize: "1.5rem",
  color: "black",
};

export const phonePageTitleStyles = {
  fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI"',
  fontWeight: "bolder",
  fontSize: "2rem",
  color: "#FFF",
};
