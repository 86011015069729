import React from "react";
import { Grid, Typography, Checkbox } from "@mui/material";
import Question from "./Question";

export default function TopicQuestion({ topic, type, topics }) {
  const [setAll, setSetAll] = React.useState(null);

  const getSortedQuestions = () => {
    const copy = [...topic.questions];
    return copy.sort((a, b) => a.ordering - b.ordering);
  };

  return (
    <Grid container>
      <Grid
        item
        xs={6}
        sm={7}
        style={{
          border: "1px solid black",
          padding: "20px",
          backgroundColor: "#74808760",
        }}
        container
        alignItems="center"
        justifyContent="center"
      >
        <Typography>Select All For Section</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={5}
        container
        style={{
          border: "1px solid black",
          padding: "20px",
          textAlign: "center",
          backgroundColor: "#74808760",
        }}
        direction="row"
        justifyContent="space-between"
      >
        <Grid item xs={3} container alignItems="center">
          <Checkbox checked={setAll === 1} onChange={() => setSetAll(1)} />
          <Typography>Yes</Typography>
        </Grid>
        <Grid item xs={3} container alignItems="center">
          <Checkbox checked={setAll === -1} onChange={() => setSetAll(-1)} />
          <Typography>No</Typography>
        </Grid>
        <Grid item xs={3} container alignItems="center">
          <Checkbox checked={setAll === 0} onChange={() => setSetAll(0)} />
          <Typography>N/A</Typography>
        </Grid>
      </Grid>
      {getSortedQuestions().map((q) => {
        return (
          <Question
            key={q.id}
            question={q}
            type={type}
            setAll={setAll}
            topics={topics}
          />
        );
      })}
    </Grid>
  );
}
