import * as React from "react";

import { useQuery } from "@apollo/client";

import { authMutations } from "../../../../graphql/mutations";
import { GET_AUDITS } from "../../../../graphql/queries/admin/audits";
import TableMain from "./ReusableTableLayout";
import ReusableTableToolbar from "./ReusableTableToolbar";
import TableBodyTemplate from "./TableBodyTemplate";
import TableHeadTemplate from "./TableHeadTemplate";
import { filterData } from "./searchFilterFunction";

// Props
// hasPagination - boolean
// data - array of objects
// 3 children component
//    If using the ReusableTableToolbar
//    - title - string - displayed at the top
//    - onDeleteClick - when trash can on toolbar is clicked
//    - onSearchChange - when input in search bar
//    - searchTerm - string
export default function Table() {
  const { data, loading } = useQuery(GET_AUDITS, {
    fetchPolicy: "network-only",
  });
  const { updateLoading } = authMutations;

  const onSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const clearFilters = () => {};

  const [searchTerm, setSearchTerm] = React.useState("");

  const [filteredData, setFilteredData] = React.useState([]);

  // useEffect for filter update
  React.useEffect(() => {
    setFilteredData(filterData(data ? data.audits : [], searchTerm));
  }, [data, searchTerm]);

  // show loading
  React.useEffect(() => {
    updateLoading(loading);
  }, [loading]);

  return (
    <TableMain
      CustomTableHeader={<TableHeadTemplate />}
      CustomTableBody={<TableBodyTemplate />}
      CustomTableToolBar={
        <ReusableTableToolbar
          searchTerm={searchTerm}
          onSearchChange={onSearchChange}
          clearFilters={clearFilters}
          title={"Audit Types"}
        />
      }
      hasPagination={true}
      data={filteredData}
    />
  );
}
