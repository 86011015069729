import React from "react";

import { Check, InfoOutlined } from "@mui/icons-material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import { useMutation } from "@apollo/client";

import { UPDATE_PERMISSION_GROUP } from "../../../graphql/mutations/admin/permissions";
import { GET_PERMISSION_GROUPS } from "../../../graphql/queries/permissions";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import { CustomSwitch } from "../../CustomComponents/Switch";
import { CustomTextField } from "../../CustomStyles/LightTextField";
import { yellowButton } from "../../CustomStyles/buttons";

export default function PermissionGroup({ permissionGroup, allPermissions }) {
  const theme = useTheme();
  const [updateGroup] = useMutation(UPDATE_PERMISSION_GROUP, {
    onCompleted() {
      onCompletedFunc("Permission group has been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [{ query: GET_PERMISSION_GROUPS }],
  });
  const [groupName, setGroupName] = React.useState(permissionGroup.name);
  const [isActive, setIsActive] = React.useState(permissionGroup.isActive);
  const [permissions, setPermissions] = React.useState(
    permissionGroup.permissionGroupPermissions
  );
  const [addedPermissions, setAddedPermissions] = React.useState([]);

  const onSave = () => {
    updateGroup({
      variables: {
        name: groupName,
        isActive,
        id: Number(permissionGroup.id),
        ap: [...addedPermissions, ...permissions.map((p) => p.permission)].map(
          (p) => Number(p.id)
        ),
        dp: allPermissions.permissions
          .filter(
            (p) =>
              [
                ...addedPermissions,
                ...permissions.map((p) => p.permission),
              ].findIndex((ap) => ap.id === p.id) < 0
          )
          .map((p) => Number(p.id)),
      },
    });
  };

  const onCancel = () => {
    setPermissions(permissionGroup.permissionGroupPermissions);
    setAddedPermissions([]);
  };

  const add = (p) => {
    setAddedPermissions((prev) => [...prev, p]);
  };

  const remove = (p) => {
    setAddedPermissions((prev) => prev.filter((pr) => pr.id !== p.id));
    setPermissions((prev) =>
      prev.filter((pr) => pr.permission.id !== p.permission?.id)
    );
  };

  React.useEffect(() => {}, [allPermissions]);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
        aria-controls="permissiongroup-content"
        id="permissiongroup-header"
        style={{
          backgroundColor: isActive
            ? theme.palette.grey1.main
            : theme.palette.grey0.main,
          color: theme.palette.white.main,
        }}
      >
        {permissionGroup.name}
      </AccordionSummary>
      <AccordionDetails
        style={{
          backgroundColor: isActive
            ? theme.palette.grey1.main
            : theme.palette.grey0.main,
        }}
      >
        <CustomTextField
          variant="standard"
          label="Group Name:"
          value={groupName}
          onChange={(event) => setGroupName(event.target.value)}
        />
        <Grid container alignItems="center" style={{ marginTop: "20px" }}>
          <FormControlLabel
            style={{ color: "white" }}
            control={
              <CustomSwitch
                checked={isActive}
                onChange={(event) => setIsActive(event.target.checked)}
              />
            }
            label={isActive ? "ACTIVE" : "INACTIVE"}
          />
        </Grid>
        <Grid container style={{ marginTop: "20px" }}>
          <Grid item xs={7}>
            <Typography style={{ color: "white" }}>
              INCLUDED PERMISSIONS:
            </Typography>
            <Grid
              container
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                backgroundColor: "white",
                width: "80%",
                marginTop: "10px",
                borderRadius: 5,
              }}
            >
              {permissions.length === 0 && addedPermissions.length === 0 && (
                <Typography style={{ padding: "20px" }}>
                  No added permissions.
                </Typography>
              )}
              {[...permissions, ...addedPermissions]
                .sort((a, b) => {
                  const aName = a.permission ? a.permission.name : a.name;
                  const bName = b.permission ? b.permission.name : b.name;
                  return aName > bName ? 1 : -1;
                })
                .map((p) =>
                  p.permission ? (
                    <Grid
                      item
                      xs={12}
                      key={p.permission.id}
                      style={{
                        padding: "10px",
                        height: "fit-content",
                      }}
                      container
                      alignItems="center"
                    >
                      <Grid item xs={1}>
                        <IconButton
                          style={{ marginRight: "10px" }}
                          onClick={() => remove(p)}
                        >
                          <Check />
                        </IconButton>
                      </Grid>
                      <Grid item xs={11} container>
                        <Typography style={{ marginRight: "15px" }}>
                          {p.permission.name}
                        </Typography>
                        <Tooltip title={p.permission.description}>
                          <InfoOutlined />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      key={p.id}
                      style={{
                        padding: "10px",
                        height: "fit-content",
                      }}
                      container
                      alignItems="center"
                    >
                      <Grid item xs={1}>
                        <IconButton
                          style={{ marginRight: "10px" }}
                          onClick={() => remove(p)}
                        >
                          <Check />
                        </IconButton>
                      </Grid>
                      <Grid item xs={11} container>
                        <Typography style={{ marginRight: "15px" }}>
                          {p.name}
                        </Typography>
                        <Tooltip title={p.description}>
                          <InfoOutlined />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )
                )}
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Typography style={{ color: "white" }}>
              UNINCLUDED PERMISSIONS:
            </Typography>
            <Grid
              container
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                backgroundColor: "white",
                width: "80%",
                marginTop: "10px",
                borderRadius: 5,
              }}
            >
              {allPermissions &&
                allPermissions.permissions
                  .filter(
                    (p) =>
                      p.isActive &&
                      permissions.findIndex(
                        (perm) => perm.permission.id === p.id
                      ) < 0 &&
                      addedPermissions.findIndex((perm) => perm.id === p.id) < 0
                  )
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((p) => (
                    <Grid
                      item
                      xs={12}
                      key={p.id}
                      style={{
                        padding: "10px",
                        height: "fit-content",
                      }}
                      container
                      alignItems="center"
                    >
                      <Grid item xs={1.5}>
                        <IconButton
                          style={{ marginRight: "10px" }}
                          onClick={() => add(p)}
                        >
                          <CheckBoxOutlineBlankIcon />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10.5} container>
                        <Typography style={{ marginRight: "15px" }}>
                          {p.name}
                        </Typography>
                        <Tooltip title={p.description}>
                          <InfoOutlined />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ))}
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ margin: "20px 0px" }}>
            <Button
              variant="contained"
              style={{ marginRight: "10px", ...yellowButton }}
              onClick={onSave}
            >
              SAVE
            </Button>
            <Button variant="contained" onClick={onCancel}>
              CANCEL
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
