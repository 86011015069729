import * as React from "react";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Stack, Chip, Typography, Grid, Avatar, Tooltip } from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import {
  createAuditVar,
  editAuditVar,
} from "../../../graphql/localVariables/audit";
import { auditMutations } from "../../../graphql/mutations";
import AuthorsModal from "./AuthorModal";

export default function DeletableChips({ type }) {
  // Apollo queries, variables, mutations
  const editAudit = useReactiveVar(editAuditVar);
  const createAudit = useReactiveVar(createAuditVar);
  const updateAudit = type === "EDIT" ? editAudit : createAudit;
  const { updateCreateAudit, updateEditAudit } = auditMutations;
  const updateAuditFunction =
    type === "EDIT" ? updateEditAudit : updateCreateAudit;

  const handleDelete = (id) => {
    if (
      updateAudit &&
      updateAudit.contributors &&
      updateAudit.contributors.length !== 0
    ) {
      const newArray = updateAudit.contributors.filter((c) => {
        return c.id !== id;
      });
      updateAuditFunction("contributors", newArray);
    }
  };

  const renderContributorChip = (p) => {
    return (
      <Chip
        label={p.firstName !== "" ? `${p.firstName} ${p.lastName}` : p.username}
        variant="outlined"
        value={p}
        key={`${p.firstName} ${p.lastName}`}
        color="secondary"
        deleteIcon={<HighlightOffIcon sx={{ color: "#FFB700" }} />}
        avatar={<Avatar alt={`${p.firstName} ${p.lastName}`} src={p.image} />}
        onDelete={() => handleDelete(p.id)}
        sx={{ color: "#FFB700", width: "fit-content" }}
      />
    );
  };

  return (
    <Grid
      container
      spacing={1}
      style={{
        color: "white",
        marginTop: "30px",
        marginBottom: "5px",
        marginLeft: "16px",
        fontSize: "1.2rem",
      }}
    >
      <Grid item xs={12} md={4}>
        {updateAudit && updateAudit.creator ? (
          <>
            <Typography sx={{ display: "inline-block", marginRight: "10px" }}>
              Created By:
            </Typography>
            {updateAudit.creator.firstName !== "" ? (
              <Chip
                label={`${updateAudit.creator.firstName} ${updateAudit.creator.lastName}`}
                variant="outlined"
                color="secondary"
                avatar={
                  <Avatar
                    alt={`${updateAudit.creator.firstName} ${updateAudit.creator.lastName}`}
                    src={updateAudit.creator.image}
                  />
                }
                sx={{ color: "#FFB700", width: "fit-content" }}
              />
            ) : (
              <Chip
                label={`${updateAudit.creator.username}`}
                variant="outlined"
                color="secondary"
                avatar={
                  <Avatar
                    alt={`${updateAudit.creator.username}`}
                    src={updateAudit.creator.image}
                  />
                }
                sx={{ color: "#FFB700", width: "fit-content" }}
              />
            )}
          </>
        ) : null}
      </Grid>

      <Grid item xs={12} md={8}>
        <Typography sx={{ display: "inline-block", marginRight: "10px" }}>
          Contributed By:
        </Typography>
        {updateAudit &&
        updateAudit.contributors &&
        updateAudit.contributors.length !== 0
          ? updateAudit.contributors.map((c) => renderContributorChip(c))
          : null}
        <AuthorsModal type={type} />
      </Grid>
    </Grid>
  );
}
