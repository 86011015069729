import * as React from "react";

import { Button } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { useMutation } from "@apollo/client";
import moment from "moment";

import { MUTATE_PROJECT } from "../../../../../graphql/mutations/admin/planning-project";
import { GET_PROJECTS } from "../../../../../graphql/queries/admin/planning-project";
import { CustomSwitch } from "../../../../CustomComponents/Switch";
import { yellowButton } from "../../../../CustomStyles/buttons";
import EditProject from "./EditProject";

function Row({ row }) {
  const [editProject] = useMutation(MUTATE_PROJECT, {
    refetchQueries: [{ query: GET_PROJECTS }],
  });
  const [active, setActive] = React.useState(row.isActive);
  const [open, setOpen] = React.useState(false);

  const handleIsActiveChange = (event) => {
    setActive(event.target.checked);
    editProject({
      variables: {
        input: { id: Number(row.id), isActive: event.target.checked },
      },
    });
  };
  if (row.ownerSiteProjects[0] && row.ownerSiteProjects[0].owner.name !== "") {
    return (
      <>
        <TableRow hover key={row.id}>
          <TableCell component="th" scope="row">
            {row.name}{" "}
            {row.ownerSiteProjects.length > 0
              ? `[${row.ownerSiteProjects[0].site.name} - ${row.ownerSiteProjects[0].owner.name}]`
              : ""}
          </TableCell>
          <TableCell>
            {moment(new Date(row.timeCreated)).format("MM/DD/YYYY hh:mm A")}
          </TableCell>
          <TableCell>{row.ownerSiteProjects?.length}</TableCell>
          <TableCell>${row.dollarAmount}</TableCell>
          <TableCell>
            <CustomSwitch
              checked={active}
              name={row.id}
              onChange={(event) => handleIsActiveChange(event)}
            />
          </TableCell>
          <TableCell>
            <Button style={{ ...yellowButton }} onClick={() => setOpen(true)}>
              VIEW
            </Button>
          </TableCell>
        </TableRow>
        <EditProject open={open} handleClose={() => setOpen(false)} row={row} />
      </>
    );
  } else {
    return null;
  }
}

export default function TableBodyTemplate(props) {
  // these props are available in table body
  const { order, orderBy, page, rowsPerPage, getComparator, stableSort, data } =
    props;

  if (
    stableSort(data, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    ).length === 0
  ) {
    return (
      <TableRow>
        <TableCell style={{ borderBottom: "transparent" }} colSpan={5}>
          No Results Found
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {stableSort(data, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => {
          return <Row key={row.id} row={row} />;
        })}
    </>
  );
}
