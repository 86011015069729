import React from "react";

import { Check, CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import { useMutation, useLazyQuery, useReactiveVar } from "@apollo/client";

import { onlineVar } from "../../../../graphql/localVariables/user";
import { CREATE_EDIT_PROJECT } from "../../../../graphql/mutations/admin/planning-project";
import {
  GET_PROJECTS,
  GET_SITES,
} from "../../../../graphql/queries/admin/planning-project";
import { GET_SEARCH_TEMPLATES } from "../../../../graphql/queries/ha";
import { states } from "../../../../utils/states";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../CustomComponents/OnErrorFunction";
import { CustomTextField } from "../../../CustomStyles/LightTextField";
import { yellowButton } from "../../../CustomStyles/buttons";
import { CustomDialog } from "../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../CustomStyles/pageTitle";
import { COMPANY_ADMIN_OSP } from "../../Company/companyAdminQueries";
import PreviewImages from "./PreviewImages";
import UploadDialog from "./Upload";

export default function CreateProject({ open, handleClose, company }) {
  const online = useReactiveVar(onlineVar);
  const [getQuery, { data: sites, loading: loadingSites }] = useLazyQuery(
    GET_SITES,
    {
      fetchPolicy: online ? "network-only" : "cache-only",
    }
  );
  const [getTemplates, { data: templates, loading: loadingTemplates }] =
    useLazyQuery(GET_SEARCH_TEMPLATES, {
      fetchPolicy: online ? "network-only" : "cache-only",
    });

  React.useEffect(() => {
    if (open) {
      getQuery({
        fetchPolicy: online ? "network-only" : "cache-only",
      });
      getTemplates({
        fetchPolicy: online ? "network-only" : "cache-only",
      });
    }
  }, [open]);

  const [createProject, { loading: loadingCreate }] = useMutation(
    CREATE_EDIT_PROJECT,
    {
      onCompleted() {
        handleClose();
        onClear();
        onCompletedFunc("Project has been created");
      },
      onError(error) {
        onErrorFunc(error);
      },
      refetchQueries: [
        { query: GET_PROJECTS },
        company && {
          query: COMPANY_ADMIN_OSP,
          variables: { id: Number(company.id) },
        },
      ],
    }
  );

  const [location, setLocation] = React.useState([]);
  const [name, setName] = React.useState("");
  const [addr1, setAddr1] = React.useState("");
  const [addr2, setAddr2] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [zip, setZip] = React.useState("");
  const [dollar, setDollar] = React.useState(0.0);
  const [photos, setPhotos] = React.useState([]);

  const [addedTemplates, setAddedTemplates] = React.useState([]);
  const [searchTemplates, setSearchTemplates] = React.useState("");

  const onCreate = () => {
    if (state !== "" && location.length > 0) {
      createProject({
        variables: {
          addrCity: city,
          addrLine1: addr1,
          addrLine2: addr2,
          addrState: state,
          addrZip: zip,
          dollarAmount: Number(dollar),
          media: photos,
          projectName: name,
          changeStatus: false,
          OSPinfo: location.map((l) => {
            return {
              company: Number(l.owner.id),
              site: Number(l.site.id),
              associatedTemplate: addedTemplates.map((t) => t.id),
            };
          }),
        },
      });
    } else {
      onErrorFunc(
        "Make sure to select a site location and state for your project"
      );
    }
  };

  const onCancel = () => {
    handleClose();
    onClear();
  };

  const onClear = () => {
    setLocation([]);
    setName("");
    setAddedTemplates([]);
    setSearchTemplates("");
  };

  const add = (p) => {
    setAddedTemplates((prev) => [...prev, p]);
  };

  const remove = (p) => {
    setAddedTemplates((prev) => prev.filter((prev) => prev.id !== p.id));
  };

  return (
    <CustomDialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle
        style={{
          ...pageTitleStyles,
        }}
      >
        CREATE PROJECT.
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ marginBottom: "20px" }}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="select-site-owner"
              limitTags={3}
              options={
                sites
                  ? [
                      ...sites.ownerSiteProjects.filter((osp) =>
                        company
                          ? osp.owner && osp.owner.id === company.id && osp.site
                          : osp.owner && osp.site
                      ),
                    ].sort((a, b) => (a.site.name < b.site.name ? -1 : 1))
                  : []
              }
              value={location}
              onChange={(event, value) => setLocation(value)}
              getOptionLabel={(option) =>
                `${option.site?.name} [${option.owner?.name}]`
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disableClearable
              multiple
              loading={loadingSites}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {`${option.site.name} [${option.owner.name}]`}
                </li>
              )}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  style={{ color: "#fff" }}
                  variant="standard"
                  label={"Location:"}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Project Name:"
              variant="standard"
              style={{ width: "100%" }}
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Address Line 1:"
              variant="standard"
              style={{ width: "100%" }}
              value={addr1}
              onChange={(event) => setAddr1(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Address Line 2:"
              variant="standard"
              style={{ width: "100%" }}
              value={addr2}
              onChange={(event) => setAddr2(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="City:"
              variant="standard"
              style={{ width: "100%" }}
              value={city}
              onChange={(event) => setCity(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="select-site-state"
              options={states}
              value={state}
              onChange={(event, value) => setState(value)}
              disableClearable
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  style={{ color: "#fff" }}
                  variant="standard"
                  label={"State:"}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Zipcode:"
              variant="standard"
              style={{ width: "100%" }}
              value={zip}
              onChange={(event) => setZip(event.target.value)}
              type="number"
              inputProps={{ maxLength: 5 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Dollar Amount:"
              variant="standard"
              style={{ width: "100%" }}
              value={dollar}
              onChange={(event) => setDollar(event.target.value)}
              type="number"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <Typography style={{ color: "white" }}>ADDED TEMPLATES:</Typography>
            <Grid
              container
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                backgroundColor: "white",
                marginTop: "10px",
                borderRadius: 5,
              }}
            >
              {addedTemplates.length === 0 && (
                <Typography style={{ padding: "20px", color: "black" }}>
                  No added templates.
                </Typography>
              )}

              {addedTemplates
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((u) => (
                  <Grid
                    item
                    xs={12}
                    key={u.id}
                    style={{
                      padding: "10px",
                      height: "fit-content",
                    }}
                    container
                    alignItems="center"
                  >
                    <Grid item xs={2}>
                      <IconButton
                        style={{ marginRight: "10px" }}
                        onClick={() => remove(u)}
                      >
                        <Check />
                      </IconButton>
                    </Grid>
                    <Grid item xs={10} container>
                      <Typography
                        style={{ marginRight: "15px", color: "black" }}
                      >
                        {u.name}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Typography style={{ color: "white" }}>
              UNADDED TEMPLATES:
            </Typography>
            <CustomTextField
              label="Search:"
              variant="standard"
              style={{ width: "100%" }}
              value={searchTemplates}
              onChange={(event) => setSearchTemplates(event.target.value)}
            />
            <Grid
              container
              style={{
                maxHeight: "200px",
                overflowY: "auto",
                backgroundColor: "white",
                width: "100%",
                marginTop: "10px",
                borderRadius: 5,
              }}
            >
              {loadingTemplates ? (
                <Grid container justifyContent="center" sx={{ p: 1 }}>
                  <CircularProgress color="secondary" />
                </Grid>
              ) : (
                templates &&
                templates.templates
                  .filter(
                    (template) =>
                      addedTemplates.findIndex((t) => t.id === template.id) <
                        0 &&
                      template.name
                        .toLowerCase()
                        .includes(searchTemplates.toLowerCase())
                  )
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((t) => (
                    <Grid
                      item
                      xs={12}
                      key={t.id}
                      style={{
                        padding: "10px",
                        height: "fit-content",
                      }}
                      container
                      alignItems="center"
                    >
                      <Grid item xs={2}>
                        <IconButton
                          style={{ marginRight: "10px" }}
                          onClick={() => add(t)}
                        >
                          <CheckBoxOutlineBlank />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10} container>
                        <Typography
                          style={{ marginRight: "5px", color: "black" }}
                        >
                          {t.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))
              )}
            </Grid>
          </Grid>
        </Grid>
        <UploadDialog
          files={photos}
          handleFilesChange={(files) => setPhotos(files)}
        />
        <PreviewImages
          fileObjects={photos}
          handleFilesChange={(files) => setPhotos(files)}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          sx={{ marginRight: "10px", ...yellowButton }}
          onClick={onCreate}
          loading={loadingCreate}
        >
          CREATE
        </LoadingButton>
        <Button variant="contained" onClick={onCancel}>
          CANCEL
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}
