import React from "react";

import { Grid, Paper } from "@mui/material";
import { useTheme } from "@mui/styles";

import DashboardMonitor from "./DashboardMonitor";
import NotificationsRoot from "./Notifications/NotificationsRoot";
import useStyles from "./styles";

export default function DashboardMonitorGauge({
  // riskMitigationScore,
  // lastUpdate,
  // monthlyRiskMitigation,
  riskMonitor,
  setRiskMonitor,
  auditScore,
  observationScore,
  jsaScore,
  permissions,
}) {
  const classes = useStyles()();
  const theme = useTheme();
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={12} md={8}>
        <DashboardMonitor
          riskMonitor={riskMonitor}
          setRiskMonitor={setRiskMonitor}
          auditScore={auditScore}
          observationScore={observationScore}
          jsaScore={jsaScore}
          permissions={permissions}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        sx={{ height: { xs: "fit-content", md: "100%" } }}
      >
        <Paper
          children={<NotificationsRoot />}
          elevation={5}
          style={{
            height: "100%",
            backgroundColor: "inherit",
            borderRadius: "20px",
            boxShadow: `10px -10px 30px ${theme.palette.grey3.main}`,
          }}
        />
      </Grid>
    </Grid>
  );
}
