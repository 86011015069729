import React from "react";

import { Typography } from "@mui/material";

import { useMutation } from "@apollo/client";
import { DropzoneDialog } from "mui-file-dropzone";

import { CHANGE_PROFILE_PIC } from "../../../../../../graphql/mutations/userSettings";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../../../CustomComponents/OnErrorFunction";

export default function UploadDialog({ userId, disabled }) {
  const [open, setOpen] = React.useState(false);
  const [mutateProfilePic] = useMutation(CHANGE_PROFILE_PIC, {
    onCompleted() {
      onCompletedFunc("Image has been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (files) => {
    setOpen(false);
    mutateProfilePic({
      variables: {
        userId: userId,
        image: files[0],
      },
    });
  };

  const handleOpen = () => {
    if (!disabled) {
      setOpen(true);
    }
  };

  return (
    <div>
      <Typography
        onClick={handleOpen}
        sx={{
          fontSize: ".75rem",
          textAlign: "center",
          cursor: "pointer",
          margin: "10px 0px",
        }}
      >
        CHANGE PHOTO
      </Typography>
      <DropzoneDialog
        open={open}
        onSave={handleSave}
        acceptedFiles={["image/jpeg", "image/png"]}
        showPreviews={true}
        filesLimit={1}
        onClose={handleClose}
        maxFileSize={10000000} // 10MB
      />
    </div>
  );
}
