import React from "react";
import { BrowserView, isTablet, MobileView } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";

import {
  Typography,
  Grid,
  Button,
  Paper,
  FormControlLabel,
  DialogContent,
  DialogActions,
} from "@mui/material";

import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import {
  PDFViewer,
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
  PDFDownloadLink,
  pdf,
} from "@react-pdf/renderer";
import moment from "moment";

import {
  onlineVar,
  permissionsVar,
} from "../../../graphql/localVariables/user";
import { authMutations } from "../../../graphql/mutations";
import { EDIT_HA, HA_EMAIL } from "../../../graphql/mutations/ha";
import { GET_COMPANY_ARMS_NO_RBAC } from "../../../graphql/queries";
import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import { VIEW_HA } from "../../../graphql/queries/ha";
import logo from "../../../images/nixn-logo-white.png";
import { getGradeLetter } from "../../../utils/raScore";
import removeDuplicates from "../../../utils/removeDuplicates";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import { CustomSwitch } from "../../CustomComponents/Switch";
import { CustomTextField } from "../../CustomStyles/LightTextField";
import {
  blackButton,
  blueButton,
  yellowButton,
} from "../../CustomStyles/buttons";
import { CustomDialog } from "../../CustomStyles/dialog";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import Layout from "../../Layout";

const styles = StyleSheet.create({
  page: {
    width: "95%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    border: "3px solid black",
    justifyContent: "center",
    margin: "0px auto",
  },
  formSection: { width: "25%", border: "1px solid black", padding: "10px" },
  armSectionHeader: { width: "33.3%", borderBottom: "1px solid black" },
  armSection: {
    width: "100%",
    borderBottom: "1px solid black",
    borderLeft: "1px solid black",
  },
  column: { display: "flex", flexDirection: "column" },
  row: { width: "100%", display: "flex", flexDirection: "row" },
  text: { padding: "20px", fontSize: "10px" },
  sectionLabel: { paddingBottom: "10px", fontSize: "12px" },
  labelText: { padding: "10px", fontSize: "12px", color: "grey" },
  mainText: {
    padding: "10px",
    fontSize: "14px",
    width: "90%",
    borderBottom: "1px solid grey",
  },
});

export default function ViewHAPDF() {
  const navigate = useNavigate();
  const { id } = useParams();
  const online = useReactiveVar(onlineVar);
  const permissions = useReactiveVar(permissionsVar);
  const { updateLoading } = authMutations;

  // QUERIES
  const { data, loading: loadingHA } = useQuery(VIEW_HA, {
    variables: { id: Number(id) },
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const { data: currentUser } = useQuery(GET_CURRENT_USER);
  const [getCompanyMit, { data: arms, loading: loadingMit }] = useLazyQuery(
    GET_COMPANY_ARMS_NO_RBAC,
    {
      fetchPolicy: online ? "network-only" : "cache-only",
    }
  );

  // MUTATIONS
  const [emailHAMutation] = useMutation(HA_EMAIL, {
    onCompleted() {
      onCompletedFunc("Email will be sent!");
    },
    onError(error) {
      onErrorFunc(error);
    },
  });
  const [editHAMutation] = useMutation(EDIT_HA, {
    onCompleted() {
      onCompletedFunc("Ha will be closed");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [{ query: VIEW_HA, variables: { id: Number(id) } }],
  });

  const [actions, setActions] = React.useState([]);
  const [missedMitigators, setMissedMitigators] = React.useState([]);
  const [scores, setScores] = React.useState(true);
  const [riskMitigated, setRiskMitigated] = React.useState(0);
  const [gradeLetter, setGradeLetter] = React.useState("");

  const [openClose, setOpenClose] = React.useState(false);

  React.useEffect(() => {
    if (
      data &&
      data.hazardAnalysis &&
      data.hazardAnalysis.submitter &&
      data.hazardAnalysis.submitter.company
    ) {
      const companyId = Number(data.hazardAnalysis.submitter.company.id);
      getCompanyMit({ variables: { companyId, rNull: false, mNull: false } });
    }
  }, [data]);

  React.useEffect(() => {
    const unique = (arr) =>
      arr.reduce((results, item) => {
        if (
          !results.some(
            (i) =>
              i.arm.a.name === item.arm.a.name && i.arm.a.id === item.arm.a.id
          )
        ) {
          results.push(item);
        }
        return results;
      }, []);

    if (data) {
      setActions(
        unique(
          data.hazardAnalysis.haArms.filter(
            (haArm) => !haArm.arm.r && !haArm.arm.m
          )
        )
      );

      const { grade, mitigatedRisk } = getGradeLetter(
        data.hazardAnalysis.raScore,
        data.hazardAnalysis.raScoreMax,
        data.hazardAnalysis.raScoreMin
      );
      setRiskMitigated(mitigatedRisk);
      setGradeLetter(grade);
    }
  }, [data]);

  const getUniqueRisks = (arr) =>
    arr.reduce((results, item) => {
      if (
        item.arm.r &&
        !results.some(
          (i) =>
            i.arm.r?.name === item.arm.r.name && i.arm.r?.id === item.arm.r.id
        )
      ) {
        results.push(item);
      }
      return results;
    }, []);

  React.useEffect(() => {
    if (data && arms) {
      const haArms = data.hazardAnalysis.haArms;
      const allRisks = haArms.filter((arm) => arm.arm.r && !arm.arm.m);
      const allMit = arms.companyArmsView.map((carm) => carm.arm);

      // Get all mitigators that match action-risks in the HA arm
      const potentialMit = removeDuplicates(
        allMit.filter(
          (arm) =>
            allRisks.findIndex(
              (r) =>
                Number(r.arm.a.id) === Number(arm.a.id) &&
                Number(r.arm.r.id) === Number(arm.r.id)
            ) >= 0
        ),
        "id"
      );

      const haMitigators = haArms
        .filter((haArm) => haArm.arm.m)
        .map((m) => Number(m.arm.id));

      // Get mitigators not included in the HA arm
      const missingMit = potentialMit.filter(
        (m) => !haMitigators.includes(Number(m.id))
      );

      setMissedMitigators(missingMit);
    }
  }, [data, arms]);

  const findRisks = (a) => {
    const allRisks = data?.hazardAnalysis.haArms.filter(
      (oa) => oa.arm.a.name === a.name && oa.arm.a.id === a.id
    );

    return getUniqueRisks(allRisks);
  };

  const findMitigators = (ar) => {
    const allMitigators = data?.hazardAnalysis.haArms.filter(
      (oa) =>
        oa.arm.r?.name === ar.r?.name &&
        oa.arm.r?.id === ar.r?.id &&
        oa.arm.a.name === ar.a.name &&
        oa.arm.a.id === ar.a.id
    );
    const unique = (arr) =>
      arr.reduce((results, item) => {
        if (item.arm.m !== null) {
          results.push(item);
        }
        return results;
      }, []);
    return unique(allMitigators);
  };

  const closeHA = () => {
    setOpenClose(true);
  };

  const [hasInjury, setHasInjury] = React.useState(false);
  const [injuryText, setInjuryText] = React.useState("");

  React.useEffect(() => {
    updateLoading(loadingHA || loadingMit);
  }, [loadingHA, loadingMit]);

  React.useEffect(() => {
    if (data) {
      setHasInjury(data.hazardAnalysis.hasInjury);
      setInjuryText(data.hazardAnalysis.injuryText);
    }
  }, [data]);

  const handleChange = (event) => {
    setHasInjury(event.target.checked);
  };

  const onChangeText = (event) => {
    setInjuryText(event.target.value);
  };

  const closeHAConfirm = () => {
    setOpenClose(false);
    editHAMutation({
      variables: {
        id: Number(data.hazardAnalysis.haId),
        input: {
          isCompleted: true,
          timeCompleted: new Date(),
          name: data.hazardAnalysis.name,
          comment: data.hazardAnalysis.comment,
          hasInjury: hasInjury,
          injuryText: injuryText,
          date: data.hazardAnalysis.date,
          ownerSiteProjects: data.hazardAnalysis.haOwnerSiteProjects.map(
            (osp) => ({
              id: Number(osp.ownerSiteProject.id),
            })
          ),
          arms: data.hazardAnalysis.haArms.map((arm) => {
            let copy = { id: Number(arm.arm.id) };
            if (arm.actionOrdering) {
              copy = { ...copy, actionOrdering: arm.actionOrdering };
            }
            return copy;
          }),
          templates: data.hazardAnalysis.haTemplate.map((t) => ({
            id: Number(t.template.id),
          })),
          crewMembers: data.hazardAnalysis.haCrewMembers.map((crew) => {
            let copy = { id: Number(crew.crewMember.id) };
            return copy;
          }),
        },
      },
    });
  };

  function toDecimalPlace(num, fixed) {
    var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
    return num.toString().match(re)[0];
  }

  const PDFDocument = () => {
    const d = new Date(data?.hazardAnalysis.date);
    const utc = d.getTime() + d.getTimezoneOffset() * 60000;
    const convertedDate = new Date(utc);

    let startTime = moment();
    if (data?.hazardAnalysis.time) {
      const timeArr = data?.hazardAnalysis.time.split(":");
      startTime.hours(Number(timeArr[0])).minutes(Number(timeArr[1]));
    } else {
      startTime = moment(new Date(data?.hazardAnalysis.timeCompleted));
    }

    return (
      <Document
        pageMode="fullScreen"
        title="Hazard Analysis Report"
        author="NIXN"
      >
        <Page size="A4" orientation="landscape">
          <View
            style={{
              padding: "20px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text style={{ fontFamily: "Helvetica-Bold" }}>HAZARD</Text>
              <Text style={{ fontFamily: "Helvetica-Bold" }}>ANALYSIS</Text>
              <Text style={{ fontFamily: "Helvetica-Bold" }}>REPORT</Text>
            </View>
            <View
              style={{ width: "73%", borderBottom: "3px solid #FCA800" }}
            ></View>
            {data && data.hazardAnalysis.submitter.company.logoBase64 !== "" ? (
              <Image
                src={`data:image;base64,${data.hazardAnalysis.submitter.company.logoBase64}`}
                style={{ width: "100px" }}
              />
            ) : (
              <Image src={logo} style={{ width: "100px" }} />
            )}
          </View>
          <View
            style={{
              padding: "20px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {scores && (
              <View style={{ width: "48%" }}>
                <View
                  style={{
                    border: "3px solid #8297A0",
                    borderRadius: 10,
                    padding: "10px",
                  }}
                >
                  <Text>
                    <Text style={{ color: "#8297A0" }}>OVERALL</Text> SCORE:{" "}
                    <Text style={{ color: "grey" }}>{gradeLetter}</Text>
                  </Text>
                  <View
                    style={{
                      padding: "20px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <View style={{ width: "50%" }}>
                      <Text style={{ fontSize: "12px", color: "#8297A0" }}>
                        TOTAL RISK SCORE:
                      </Text>
                      <Text style={{ fontSize: "12px", padding: "25px 0px" }}>
                        {data?.hazardAnalysis.raScore.toFixed(2)}
                      </Text>
                    </View>
                    <View style={{ width: "50%" }}>
                      <Text
                        style={{
                          fontSize: "12px",
                          color: "#8297A0",
                          width: "75%",
                        }}
                      >
                        PERCENT OF RISK MITIGATED:
                      </Text>
                      <Text style={{ fontSize: "12px", padding: "20px 0px" }}>
                        {toDecimalPlace(riskMitigated, 2)}%
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            )}
            <View
              style={{
                width: "48%",
                padding: "10px",
                border: "3px solid #8297A0",
                borderRadius: 10,
              }}
            >
              <Text>SITE SPECIFIC</Text>
              <Text style={{ padding: "10px 0px", fontSize: "14px" }}>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  Project Location:
                </Text>{" "}
                {data?.hazardAnalysis.haOwnerSiteProjects.length > 0
                  ? data?.hazardAnalysis.haOwnerSiteProjects[0].ownerSiteProject
                      .site.name
                  : ""}{" "}
                [
                {data?.hazardAnalysis.haOwnerSiteProjects.length > 0
                  ? data?.hazardAnalysis.haOwnerSiteProjects[0].ownerSiteProject
                      .owner.name
                  : ""}
                ]
              </Text>
              <Text style={{ padding: "10px 0px", fontSize: "14px" }}>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  Project Name:
                </Text>{" "}
                {data?.hazardAnalysis.haOwnerSiteProjects.length > 0
                  ? data?.hazardAnalysis.haOwnerSiteProjects
                      .map((ha) =>
                        ha.ownerSiteProject.project
                          ? ha.ownerSiteProject.project.name
                          : ""
                      )
                      .join(", ")
                  : ""}
              </Text>
              {/* <Text style={{ padding: "10px 0px", fontSize: "14px" }}>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  Emergency Response Info:
                </Text>
              </Text> */}
              <Text style={{ padding: "10px 0px", fontSize: "14px" }}>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>Date:</Text>{" "}
                {moment(convertedDate).format("MM/DD/YYYY")}
              </Text>
              <Text style={{ padding: "10px 0px", fontSize: "14px" }}>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>Time:</Text>{" "}
                {moment(startTime).format("hh:mm A")}
              </Text>
              <Text style={{ padding: "10px 0px", fontSize: "14px" }}>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  Weather Conditions:
                </Text>{" "}
                {data?.hazardAnalysis.weather}
              </Text>
              <Text style={{ padding: "10px 0px", fontSize: "14px" }}>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>HA ID:</Text>{" "}
                {data?.hazardAnalysis.haId}
              </Text>
              <Text style={{ padding: "10px 0px", fontSize: "14px" }}>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  Revision Number:
                </Text>{" "}
                {data?.hazardAnalysis.revisionNumber}
              </Text>
              <Text style={{ padding: "10px 0px", fontSize: "14px" }}>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  Date Created:
                </Text>{" "}
                {moment(new Date(data?.hazardAnalysis.timeCreated)).format(
                  "MM/DD/YYYY hh:mm A"
                )}
              </Text>
              <Text style={{ padding: "10px 0px", fontSize: "14px" }}>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  Date Last Updated:
                </Text>{" "}
                {moment(new Date(data?.hazardAnalysis.lastUpdated)).format(
                  "MM/DD/YYYY hh:mm A"
                )}
              </Text>
            </View>
          </View>
        </Page>
        <Page size="A4" orientation="landscape" wrap>
          <View
            style={{
              padding: "10px",
              borderTop: "3px solid grey",
              margin: "20px auto",
              width: "95%",
            }}
          ></View>
          <View style={styles.page}>
            <View
              style={[
                styles.formSection,
                { borderTop: "none", borderLeft: "none" },
              ]}
            >
              <Text style={styles.sectionLabel}>Hazard Analysis Name</Text>
              <Text style={{ fontSize: "12px" }}>
                {data?.hazardAnalysis.name}
              </Text>
            </View>
            <View
              style={[
                styles.formSection,
                { borderTop: "none", borderLeft: "none" },
              ]}
            >
              <Text style={styles.sectionLabel}>Date</Text>
              <Text style={{ fontSize: "12px" }}>
                {moment(convertedDate).format("MM/DD/YYYY")}
              </Text>
            </View>
            <View
              style={[
                styles.formSection,
                { borderTop: "none", borderLeft: "none" },
              ]}
            >
              <Text style={styles.sectionLabel}>Created By</Text>
              <Text style={{ fontSize: "12px" }}>
                {data?.hazardAnalysis.submitter.username}
              </Text>
            </View>
            <View
              style={[
                styles.formSection,
                {
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                },
              ]}
            >
              <Text style={styles.sectionLabel}>User Company</Text>
              <Text style={{ fontSize: "12px" }}>
                {data?.hazardAnalysis.submitter.company?.name}
              </Text>
            </View>
            <View
              style={[
                styles.formSection,
                { borderTop: "none", borderLeft: "none" },
              ]}
            >
              <Text style={styles.sectionLabel}>Start Time</Text>
              <Text style={{ fontSize: "12px" }}>
                {moment(startTime).format("hh:mm A")}
              </Text>
            </View>
            <View
              style={[
                styles.formSection,
                { borderTop: "none", borderLeft: "none" },
              ]}
            >
              <Text style={styles.sectionLabel}>Location</Text>
              <Text style={{ fontSize: "12px" }}>
                {data?.hazardAnalysis.haOwnerSiteProjects.length > 0
                  ? data?.hazardAnalysis.haOwnerSiteProjects[0].ownerSiteProject
                      .site.name
                  : ""}{" "}
                [
                {data?.hazardAnalysis.haOwnerSiteProjects.length > 0
                  ? data?.hazardAnalysis.haOwnerSiteProjects[0].ownerSiteProject
                      .owner.name
                  : ""}
                ]
              </Text>
            </View>
            <View
              style={[
                styles.formSection,
                { borderTop: "none", borderLeft: "none" },
              ]}
            >
              <Text style={styles.sectionLabel}>Project</Text>
              <Text style={{ fontSize: "12px" }}>
                {data?.hazardAnalysis.haOwnerSiteProjects.length > 0
                  ? data?.hazardAnalysis.haOwnerSiteProjects
                      .map((ha) =>
                        ha.ownerSiteProject.project
                          ? ha.ownerSiteProject.project.name
                          : ""
                      )
                      .join(", ")
                  : ""}
              </Text>
            </View>
            <View
              style={[
                styles.formSection,
                {
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                },
              ]}
            >
              <Text style={styles.sectionLabel}>Template</Text>
              {data?.hazardAnalysis.haTemplate.length > 0 ? (
                <Text style={{ fontSize: "12px" }}>
                  {data?.hazardAnalysis.haTemplate
                    .map((ha) => ha.template.name)
                    .join(", ")}
                </Text>
              ) : (
                <Text style={{ fontSize: "12px" }}>---</Text>
              )}
            </View>
            <View
              style={{
                width: "100%",
                height: "20px",
                backgroundColor: "black",
              }}
            ></View>
            <View
              style={[
                styles.armSectionHeader,
                { borderRight: "1px solid black" },
              ]}
            >
              <Text
                style={{
                  padding: "20px",
                  fontSize: "12px",
                  borderTop: "5px solid #8297A0",
                }}
              >
                Job Step
              </Text>
            </View>
            <View
              style={[
                styles.armSectionHeader,
                { borderRight: "1px solid black" },
              ]}
            >
              <Text
                style={{
                  padding: "20px",
                  fontSize: "12px",
                  borderTop: "5px solid #8297A0",
                }}
              >
                Potential Risks
              </Text>
            </View>
            <View style={styles.armSectionHeader}>
              <Text
                style={{
                  padding: "20px",
                  fontSize: "12px",
                  borderTop: "5px solid #8297A0",
                }}
              >
                Preventative Controls
              </Text>
            </View>
            <View style={[styles.column, { width: "100%" }]}>
              {actions
                .sort((a, b) => (a.actionOrdering > b.actionOrdering ? 1 : -1))
                .map((action) => {
                  return (
                    <View key={action.arm.id} style={styles.row}>
                      <View style={styles.armSectionHeader}>
                        <Text
                          style={[styles.text, { textAlign: "center" }]}
                          wrap={false}
                        >
                          {action.arm.a.name}
                        </Text>
                      </View>
                      <View style={[styles.column, { width: "66.6%" }]}>
                        {findRisks(action.arm.a).length === 0 ? (
                          <View style={styles.armSection}>
                            <Text style={styles.text}> </Text>
                            <Text style={styles.text}> </Text>
                          </View>
                        ) : (
                          findRisks(action.arm.a)
                            .sort((a, b) => {
                              const aName = a.arm.r.name;
                              const bName = b.arm.r.name;

                              const aExpected = a.arm.isExpected;
                              const bExpected = b.arm.isExpected;

                              if (
                                (aExpected && bExpected) ||
                                (!aExpected && !bExpected)
                              ) {
                                return aName > bName ? 1 : -1;
                              }
                              if (aExpected && !bExpected) return -1;
                              if (!aExpected && bExpected) return 1;
                            })
                            .map((risk, index) => {
                              return (
                                <View
                                  key={risk.arm.r?.name}
                                  style={[styles.row]}
                                >
                                  <View
                                    style={[
                                      styles.armSection,
                                      { width: "50%" },
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        styles.text,
                                        {
                                          textAlign: "center",
                                        },
                                      ]}
                                      wrap={false}
                                    >
                                      {risk.arm.r?.name}
                                    </Text>
                                    {index ===
                                      findRisks(action.arm.a).length - 1 && (
                                      <Text style={styles.text}> </Text>
                                    )}
                                  </View>
                                  <View
                                    style={[styles.column, { width: "50%" }]}
                                  >
                                    {findMitigators(risk.arm).length === 0 ? (
                                      <View style={styles.armSection}>
                                        <Text style={styles.text}> </Text>
                                        {index ===
                                          findRisks(action.arm.a).length -
                                            1 && (
                                          <Text style={styles.text}> </Text>
                                        )}
                                      </View>
                                    ) : (
                                      findMitigators(risk.arm)
                                        .sort((a, b) =>
                                          a.arm.m.name > b.arm.m.name ? 1 : -1
                                        )
                                        .map((mit) => {
                                          return (
                                            <View
                                              key={mit.arm.m.name}
                                              style={styles.armSection}
                                            >
                                              <Text
                                                style={styles.text}
                                                wrap={false}
                                              >
                                                {mit.arm.m.name}
                                              </Text>
                                              {findMitigators(risk.arm)
                                                .length === 1 &&
                                                index ===
                                                  findRisks(action.arm.a)
                                                    .length -
                                                    1 && (
                                                  <Text style={styles.text}>
                                                    {" "}
                                                  </Text>
                                                )}
                                            </View>
                                          );
                                        })
                                    )}
                                  </View>
                                </View>
                              );
                            })
                        )}
                      </View>
                    </View>
                  );
                })}
            </View>
          </View>
        </Page>
        <Page size="A4" orientation="landscape">
          <View
            style={{
              border: "3px solid black",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Text style={{ fontFamily: "Helvetica-Bold", fontSize: "14px" }}>
              Missed Mitigators:
            </Text>
            {missedMitigators
              .sort((a, b) => (a.r.name > b.r.name ? 1 : -1))
              .map((m) => (
                <Text
                  key={m.id}
                  style={{ fontSize: "12px", padding: "5px 0px" }}
                >
                  {m.m.name} [{m.r.name}]
                </Text>
              ))}
          </View>
          <View
            style={{ display: "flex", margin: "20px", flexDirection: "row" }}
            wrap={false}
          >
            <View
              style={{
                width: "50%",
                padding: "20px",
                border: "3px solid black",
              }}
            >
              <View style={{ marginBottom: "10px" }}>
                <Text style={styles.sectionLabel}>Comments:</Text>
                {data?.hazardAnalysis.comment ? (
                  <Text
                    style={{
                      fontSize: "12px",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    {data.hazardAnalysis.comment}
                  </Text>
                ) : null}
              </View>
            </View>
            <View
              style={{
                width: "50%",
                padding: "20px",
                border: "3px solid black",
                borderLeft: "none",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                }}
              >
                <Text style={styles.sectionLabel}>Did an incident occur?</Text>
                {data?.hazardAnalysis.hasInjury ? (
                  <Text
                    style={{
                      fontSize: "12px",
                      marginLeft: "10px",
                    }}
                  >
                    YES
                  </Text>
                ) : (
                  <Text
                    style={{
                      fontSize: "12px",
                      marginLeft: "10px",
                    }}
                  >
                    NO
                  </Text>
                )}
              </View>
              <View style={{ width: "100%", marginTop: "10px" }}>
                {data?.hazardAnalysis.injuryText &&
                data?.hazardAnalysis.injuryText !== "" ? (
                  <Text
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Incident Details: {data.hazardAnalysis.injuryText}
                  </Text>
                ) : null}
              </View>
            </View>
          </View>
        </Page>
        <Page size="A4" orientation="landscape">
          <View
            style={{
              borderTop: "3px solid black",
              margin: "30px 20px 20px 20px",
            }}
          >
            <Text style={{ marginTop: "20px" }}>
              Hazard Analysis Approval/Review
            </Text>
            <View
              style={{
                marginTop: "30px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <View style={{ width: "50%" }}>
                <Text style={styles.labelText}>Supervisor:</Text>
                <Text style={styles.mainText}>
                  {data.hazardAnalysis.submitter.company
                    ? data.hazardAnalysis.submitter.company.name
                    : " "}
                </Text>
              </View>
              <View style={{ width: "50%" }}>
                <Text style={styles.labelText}>Who Completed HA:</Text>
                <Text style={styles.mainText}>
                  {data.hazardAnalysis.submitter.firstName}{" "}
                  {data.hazardAnalysis.submitter.lastName}{" "}
                  {`(${data.hazardAnalysis.submitter.username})`}
                </Text>
              </View>
              <View style={{ width: "50%" }}>
                <Text style={styles.labelText}>Date:</Text>
                <Text style={styles.mainText}>
                  {moment(new Date(data.hazardAnalysis.timeCreated)).format(
                    "MM/DD/YYYY"
                  )}
                </Text>
              </View>
              <View style={{ width: "50%" }}>
                <Text style={styles.labelText}>Time:</Text>
                <Text style={styles.mainText}>
                  {moment(new Date(data.hazardAnalysis.timeCreated)).format(
                    "hh:mm A"
                  )}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              padding: "20px",
              borderRight: "1px solid black",
            }}
          >
            <Text style={styles.labelText}>Crewmembers:</Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {data?.hazardAnalysis.haCrewMembers.length > 0 ? (
                data?.hazardAnalysis.haCrewMembers.map((cm) => {
                  return (
                    <View
                      wrap={false}
                      key={cm.crewMember.id}
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        width: "50%",
                      }}
                    >
                      <Text style={{ fontSize: "12px", marginRight: "20px" }}>
                        {cm.crewMember.firstName} {cm.crewMember.lastName}
                      </Text>
                      <Text style={{ fontSize: "12px", marginRight: "20px" }}>
                        {moment(cm.crewMember.lastUpdated).format("MM/DD/YYYY")}
                      </Text>
                      {cm.crewMember.signatureBase64 !== "" && (
                        <Image
                          src={cm.crewMember.signatureBase64}
                          style={{ width: "200px" }}
                        />
                      )}
                    </View>
                  );
                })
              ) : (
                <Text style={{ fontSize: "12px" }}>No crewmembers added.</Text>
              )}
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  const mobileView = (
    <Paper
      style={{
        width: "100%",
        height: "300px",
        marginTop: "20px",
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "300px" }}
      >
        <Typography>
          Cannot view PDF on mobile device. Download PDF in order to view.
        </Typography>
      </Grid>
    </Paper>
  );

  const isOwner =
    data?.hazardAnalysis.submitter.username ===
    currentUser?.currentUser.username;

  const sendEmail = async () => {
    if (data && arms) {
      let blobPdf = await pdf(PDFDocument()).toBlob();
      let file = new File([blobPdf], "file_name", { type: "application/pdf" });
      emailHAMutation({
        variables: {
          id: Number(data.hazardAnalysis.haId),
          file,
        },
      });
    } else onErrorFunc("Please try again momentarily after HA data loads.");
  };

  const getPDFButton = () => {
    const d = new Date(data.hazardAnalysis.date);
    const utc = d.getTime() + d.getTimezoneOffset() * 60000;
    const convertedDate = new Date(utc);

    return (
      <PDFDownloadLink
        document={PDFDocument()}
        fileName={`HA_${data.hazardAnalysis.name}_${moment(
          convertedDate
        ).format("MMDDYYYY")}`.replace(/[:<>*?".|\/\s]/g, "")}
        style={{ textDecoration: "none" }}
      >
        {({ loading }) =>
          loading ? (
            <p style={{ color: "white" }}>Loading PDF...</p>
          ) : (
            <div>
              <Button
                style={{
                  color: "white",
                  ...blackButton,
                  marginLeft: "10px",
                }}
              >
                Download PDF
              </Button>
            </div>
          )
        }
      </PDFDownloadLink>
    );
  };

  return (
    <Layout>
      <Grid container justifyContent="space-between">
        <Typography sx={pageTitleStyles}>VIEW HAZARD ANALYSIS.</Typography>
        <Button onClick={() => navigate("/view-ha")} style={{ color: "white" }}>
          GO BACK
        </Button>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          container
          direction="row"
          alignItems="center"
          style={{ marginBottom: "20px" }}
        >
          {(isOwner || permissions.includes("EDIT_OTHER_HA")) && (
            <>
              {data && !data.hazardAnalysis.isCompleted && (
                <Button
                  style={{ width: "fit-content", ...yellowButton }}
                  onClick={() =>
                    navigate(`/edit-ha/${data?.hazardAnalysis.haId}`)
                  }
                  variant="contained"
                >
                  Edit
                </Button>
              )}
              {data &&
                data.hazardAnalysis &&
                !data.hazardAnalysis.isCompleted && (
                  <Button
                    style={{
                      width: "fit-content",
                      backgroundColor: "red",
                      color: "white",
                      margin: "0px 20px",
                    }}
                    onClick={closeHA}
                    variant="contained"
                  >
                    Close
                  </Button>
                )}
              {data &&
                data.hazardAnalysis &&
                data.hazardAnalysis.isCompleted &&
                data.hazardAnalysis.timeCompleted && (
                  <div style={{ marginRight: "20px" }}>
                    <Typography style={{ color: "white" }}>
                      COMPLETED AT:{" "}
                    </Typography>
                    <Typography style={{ color: "white" }}>
                      {moment(
                        new Date(data.hazardAnalysis.timeCompleted)
                      ).format("MM/DD/YYYY hh:mm A")}
                    </Typography>
                  </div>
                )}
            </>
          )}
          <Button
            style={{ width: "fit-content", ...blueButton }}
            variant="contained"
            onClick={sendEmail}
          >
            Send to Email
          </Button>

          <Grid
            sx={{
              ml: 4,
            }}
          >
            <Typography style={{ color: "white" }}>Include Scores?</Typography>
            <FormControlLabel
              style={{ color: "white" }}
              control={
                <CustomSwitch
                  checked={scores}
                  onChange={(event) => setScores(event.target.checked)}
                  light
                />
              }
              label={scores ? "YES" : "NO"}
            />
          </Grid>
          {data && data.hazardAnalysis && arms && getPDFButton()}
        </Grid>
        <Grid item xs={12}>
          {data && arms && (
            <Grid>
              {isTablet ? (
                <>{mobileView}</>
              ) : (
                <>
                  <BrowserView>
                    {data.hazardAnalysis && (
                      <PDFViewer width="100%" height="800px">
                        {PDFDocument()}
                      </PDFViewer>
                    )}
                  </BrowserView>
                  <MobileView>{mobileView}</MobileView>
                </>
              )}
            </Grid>
          )}
        </Grid>
        <CustomDialog open={openClose} onClose={() => setOpenClose(false)}>
          <DialogContent>
            <Grid container>
              <Grid item xs={6}>
                <Typography>
                  Were there any incidents associated with this HA?
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  style={{ color: "white" }}
                  control={
                    <CustomSwitch
                      checked={hasInjury}
                      onChange={handleChange}
                      light
                    />
                  }
                  label={hasInjury || false ? "YES" : "NO"}
                />
              </Grid>

              <Grid item key={"incident"} xs={12} style={{ marginTop: "10px" }}>
                <CustomTextField
                  value={injuryText}
                  variant="standard"
                  label={"Please describe the incident:"}
                  multiline={true}
                  onChange={onChangeText}
                  style={
                    hasInjury
                      ? {
                          width: "100%",
                        }
                      : {
                          display: "none",
                          width: "100%",
                        }
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                width: "fit-content",
                backgroundColor: "red",
                color: "white",
              }}
              onClick={closeHAConfirm}
            >
              CLOSE HA
            </Button>
            <Button style={blueButton} onClick={() => setOpenClose(false)}>
              CANCEL
            </Button>
          </DialogActions>
        </CustomDialog>
      </Grid>
    </Layout>
  );
}
