import React from "react";

import { useQuery, useReactiveVar } from "@apollo/client";

import {
  editObservationVar,
  createObservationVar,
} from "../../../graphql/localVariables/observation";
import {
  authMutations,
  observationMutations,
} from "../../../graphql/mutations";
import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import ObservationForm from "./ObservationForm";
import RiskNotify from "./RiskNotify";

// All logic and functions for observation form
export default function ObservationFormContainer({
  type,
  executeSubmit,
  expanded,
  setExpanded,
  setCurrentSection,
  currentSection,
  resetForm,
  riskThreshold,
}) {
  // Global Variables
  const editObs = useReactiveVar(editObservationVar);
  const createObs = useReactiveVar(createObservationVar);
  const form = type === "EDIT" ? editObs : createObs;

  const { data: currentUser } = useQuery(GET_CURRENT_USER);

  // Mutations
  const { updateSnackbar } = authMutations;
  const { updateKeyEditObservation, updateKeyCreateObservation } =
    observationMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditObservation : updateKeyCreateObservation;

  const [overThreshold, setOverThreshold] = React.useState(false);

  // Used when select/unselect state is updatedState is called
  const onEditSection = (sectionNum) => {
    setCurrentSection(sectionNum);
  };

  const checkGeneralSection = () => {
    const keys = {
      "Owner Site Project": form.ospId,
      Date: form.date,
      Time: form.time,
      Contractor: form.contractor,
    };
    let nullFlag = true;
    Object.entries(keys).forEach(([key, value]) => {
      if (
        (key === "Contractor" && value && value.name === "") ||
        value === null
      ) {
        nullFlag = false;
        updateSnackbar({
          open: true,
          message: `Input a value for ${key}`,
          severity: "error",
        });
        return false;
      }
      return true;
    });
    return nullFlag;
  };

  const checkActionSection = () => {
    if (form.actions.length === 0) {
      updateSnackbar({
        open: true,
        message: "Select at least one action.",
        severity: "error",
      });
      return false;
    } else return true;
  };

  const onCorrectionsCheck = () => {
    // TODO: check for incident match
    if (Number(form.raScore) > riskThreshold) {
      setOverThreshold(true);
      // TODO: backend call for risk notify
    }
  };

  // Called by next button
  const checkNextSection = () => {
    const next = currentSection + 1;
    if (next <= 6) {
      if (currentSection === 1) {
        if (checkGeneralSection()) {
          setCurrentSection(next);
          setExpanded(next);
        }
      } else if (currentSection === 2) {
        if (checkActionSection()) {
          setCurrentSection(next);
          setExpanded(next);
        }
      } else if (currentSection === 4) {
        // skip corrections if no mitigators unselected
        if (form.unselectedMitigators.length === 0) {
          setCurrentSection(next + 1);
          setExpanded(next + 1);

          onCorrectionsCheck();
        } else {
          setCurrentSection(next);
          setExpanded(next);
        }
      } else if (currentSection === 5) {
        setCurrentSection(next);
        setExpanded(next);

        onCorrectionsCheck();
      } else {
        setCurrentSection(next);
        setExpanded(next);
      }
    }
  };

  const checkComplete = () => {
    return checkGeneralSection() && checkActionSection();
  };

  const submitForm = () => {
    if (checkComplete()) {
      executeSubmit();
    }
  };

  // Update create time when you go to the page
  React.useEffect(() => {
    if (type === "CREATE") {
      updateObsKeyFunction("date", new Date());
      updateObsKeyFunction("time", new Date());
    }
  }, []);

  return (
    <>
      <ObservationForm
        submitForm={submitForm}
        resetForm={resetForm}
        checkNextSection={checkNextSection}
        onEditSection={onEditSection}
        type={type}
        setExpanded={setExpanded}
        expanded={expanded}
        setCurrentSection={setCurrentSection}
        currentSection={currentSection}
        user={currentUser}
      />
      <RiskNotify
        open={overThreshold}
        handleClose={() => setOverThreshold(false)}
      />
    </>
  );
}
