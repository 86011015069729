import { makeStyles } from "@mui/styles";

import backgoundImage from "../../images/login.png";

const useStyles = makeStyles(() => ({
  loginBox: {
    height: "100vh",
    backgroundImage: `url(${backgoundImage})`,
    backgroundSize: "cover",
  },
}));

export default useStyles;
