import { makeStyles } from "@mui/styles";

const useStyles = () =>
  makeStyles((theme) => ({
    root: {
      height: "100%",
      width: "100%",
      position: "relative",
    },
    contentRoot: {
      height: "100%",
      width: "100%",
      position: "relative",
      overflow: "auto",
      borderRadius: "5px",
      "&::-webkit-scrollbar": {
        width: "5px",
        height: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#D9D9D9",
        "border-radius": "10px",
      },
    },
    cardAddIcon: {
      position: "absolute",
      backgroundColor: theme.palette.yellow0.main,
      color: "inherit",
      top: "-1em",
      right: "-1em",
      height: "2em",
      width: "2em",
      "border-radius": "50%",
      zIndex: 100,
      transition: "transform .5s ease",
      "text-align-last": "center",
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.5)",
      },
    },
    icon: {
      paddingTop: ".25em",
    },
    selectUnselectTtem: {
      padding: 10,
      flexDirection: "row",
      justifyContent: "space-between",
    },
  }));

export default useStyles;
