import React from "react";

import {
  Grid,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Typography,
} from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import { createHAVar, editHAVar } from "../../../../graphql/localVariables/ha";
import { haMutations } from "../../../../graphql/mutations";
import { useStyles } from "../styles";
import MitigatorsView from "./Mitigators";

export default function RiskView({
  type,
  action,
  mit,
  data,
  loadingRisks,
  loadingMit,
}) {
  const editHA = useReactiveVar(editHAVar);
  const createHA = useReactiveVar(createHAVar);
  const updateHA = type === "EDIT" ? editHA : createHA;
  const { updateCreateHA, updateEditHA } = haMutations;
  const updateHAFunction = type === "EDIT" ? updateEditHA : updateCreateHA;

  const classes = useStyles()();
  const [risks, setRisks] = React.useState([]);

  React.useEffect(() => {
    setRisks(findRisks(action.a));
  }, [data, action]);

  React.useEffect(() => {
    const addRisks = [];
    risks.forEach((r) => {
      if (
        r.isExpected &&
        updateHA.risks.findIndex(
          (haRisk) => haRisk.a.id == r.a.id && haRisk.r.id == r.r.id
        ) < 0
      ) {
        addRisks.push(r);
      }
    });
    updateHAFunction("risks", [...updateHA.risks, ...addRisks]);
  }, [risks]);

  const findRisks = (a) => {
    const allRisks = data?.companyArms
      .map((carm) => carm.arm)
      .filter((haArm) => haArm.a?.id == a?.id);

    const unique = (arr) =>
      arr
        ? arr.reduce((results, item) => {
            if (
              !results.some(
                (i) => i.r.name === item.r.name && i.r.id === item.r.id
              )
            ) {
              results.push(item);
            }
            return results;
          }, [])
        : [];
    return unique(allRisks);
  };

  const findChecked = (risk) => {
    return updateHA.risks
      ? updateHA.risks.findIndex(
          (haRisk) => haRisk.a.id == risk.a.id && haRisk.r.id == risk.r.id
        ) >= 0
      : false;
  };

  const handleChange = (event, risk) => {
    if (event.target.checked) {
      const prev = updateHA.risks ? updateHA.risks : [];
      updateHAFunction("risks", [...prev, risk]);
    } else {
      updateHAFunction(
        "risks",
        updateHA.risks.filter((r) => Number(r.r.id) !== Number(risk.r.id))
      );
      updateHAFunction(
        "mitigators",
        updateHA.mitigators.filter((r) => Number(r.r.id) !== Number(risk.r.id))
      );
    }
  };

  const displayRisk = (risk) => {
    return (
      <Grid
        item
        xs={12}
        key={risk.r.id}
        className={classes.riskBox}
        container
        style={{ backgroundColor: findChecked(risk) ? "#8297A050" : "white" }}
      >
        <Grid item xs={5} sm={6} container justifyContent="center">
          <FormControlLabel
            sx={{
              wordBreak: "break-word",
              marginRight: "0px",
              padding: "20px",
            }}
            control={
              <Checkbox
                checked={findChecked(risk)}
                onChange={(event) => handleChange(event, risk)}
                disabled={findChecked(risk) && risk.isExpected}
                sx={{ paddingLeft: "0px" }}
                className={classes.disabledCheckbox}
              />
            }
            label={risk.r.name}
            className={classes.disabledLabel}
          />
        </Grid>
        <Grid item xs={7} sm={6}>
          {findChecked(risk) ? (
            <MitigatorsView
              type={type}
              risk={risk}
              disabled={false}
              data={mit}
              loadingMit={loadingMit}
            />
          ) : (
            <div
              style={{
                borderLeft: "1px solid black",
                width: "100%",
                height: "100%",
              }}
            ></div>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {loadingRisks ? (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress color="secondary" />
        </Grid>
      ) : risks.length === 0 ? (
        <Grid container alignItems="center" sx={{ p: 2 }}>
          <Typography>
            You may not have access to these arms or there may be no associated
            risks with this action. Please contact an administrator if you need
            more help.
          </Typography>
        </Grid>
      ) : (
        risks
          .sort((a, b) => {
            const aName = a.r.name;
            const bName = b.r.name;

            if (
              (a.isExpected && b.isExpected) ||
              (!a.isExpected && !b.isExpected)
            ) {
              return aName > bName ? 1 : -1;
            }
            if (a.isExpected && !b.isExpected) return -1;
            if (!a.isExpected && b.isExpected) return 1;
          })
          .map((r) => displayRisk(r))
      )}
    </>
  );
}
