/**
 * Takes a ARM table containing risk scoring information and a single arm
 *and returns the relevant riskLevel from the ARM table.
 *
 * @param actionRiskMitigators ARM table containing risk scoring information.
 *  example: [
 *    {id: 1, a: {id: 1}, r: null, m: null, riskScore: 0.5},
 *    {id: 1, a: {id: 1}, r: {id: 11), m: null, riskScore: 0.9},
 *    {id: 1, a: {id: 1}, r: {id: 12), m: {id: 121}, riskScore: 0.3},
 *    {id: 1, a: {id: 1}, r: {id: 12), m: {id: 122}, riskScore: null},
 *  ]
 * @param arm The inputArm object.
 *  example: {id: 1, a: {id: 1}, r: {id: 12), m: {id: 111}}
 *
 * @returns The risk score base on the given arm ids.
 */
let getRiskLevel = (actionRiskMitigators, inputArm) => {
  // Set default score value to be used when no scores exists.
  const defaultScore = 0.5;
  let aRow = [];
  let arRow = [];
  let armRow = [];
  let score = defaultScore;

  if (inputArm.a !== null) {
    // Get the A rows includes only the action (if it exists).
    aRow = actionRiskMitigators.filter(
      (arm) =>
        arm.a !== null &&
        arm.a.id === inputArm.a.id &&
        arm.r === null &&
        arm.m === null
    );
  }

  if (inputArm.r !== null) {
    // Get the AR rows includes only the action and risk (if it exists).
    arRow = actionRiskMitigators.filter(
      (arm) =>
        arm.a !== null &&
        arm.r !== null &&
        arm.a.id === inputArm.a.id &&
        arm.r.id === inputArm.r.id &&
        arm.m === null
    );
  }

  if (inputArm.m !== null) {
    // Get the ARM rows includes action, risk and mitigator (if it exists).
    armRow = actionRiskMitigators.filter(
      (arm) =>
        arm.a !== null &&
        arm.r !== null &&
        arm.m !== null &&
        arm.a.id === inputArm.a.id &&
        arm.r.id === inputArm.r.id &&
        arm.m.id === inputArm.m.id
    );
  }

  // If we received a A, set the score to be returned here.
  if (inputArm.a !== null && inputArm.r === null && inputArm.m === null) {
    // If the A row doesn't exist, use the default score.
    if (aRow.length === 0) {
      score = defaultScore;
    }
    // Otherwise, use the A score.
    else {
      score = aRow[0].riskScore;
    }
  }
  // If we received an AR, set the score to be returned here.
  else if (inputArm.a !== null && inputArm.r !== null && inputArm.m === null) {
    // If the AR row doesn't exist but the A row does, use the A score.
    if (arRow.length === 0 && aRow.length > 0) {
      score = aRow[0].riskScore;
    }
    // Otherwise, if neither A nor AR row exists, use the default score.
    else if (arRow.length === 0 && aRow.length === 0) {
      score = defaultScore;
    }
    // Otherwise, use the AR score.
    else {
      score = arRow[0].riskScore;
    }
  }
  // If we received an ARM, set the score to be returned here.
  else if (inputArm.a !== null && inputArm.r !== null && inputArm.m !== null) {
    // If the ARM row doesn't exist but the AR row does, use the AR score.
    if (armRow.length === 0 && arRow.length > 0) {
      score = arRow[0].riskScore;
    }
    // Otherwise, if neither AR nor ARM row exists, but the A row does, use the A score.
    else if (armRow.length === 0 && arRow.length === 0 && aRow.length > 0) {
      score = aRow[0].riskScore;
    }
    // Otherwise, if all A, AR and ARM row not exist, use the default score.
    else if (armRow.length === 0 && arRow.length === 0 && aRow.length === 0) {
      score = defaultScore;
    }
    // Otherwise, use the ARM score.
    else {
      score = armRow[0].riskScore;
    }
  }

  // for unscored arm, return the default score
  if (score === null || score === undefined) {
    score = defaultScore;
  }

  return score;
};

/**
 * Takes a list of individual incident probabilities, as floats between
 * 0 and 1, and returns the total probability of an incident occurring.
 *
 * @param probs Iterable containing individual incident probabilities.
 *
 * @returns The total probability as a float number.
 */
let getDemorganProb = (probs) => {
  const minusProbs = probs.map((prob) => 1 - prob);
  return (
    1 -
    minusProbs.reduce((x, y) => {
      return x * y;
    })
  );
};

/**
 * Takes a list of individual incident probabilities, as floats between
 * 0 and 1, and returns the combined probability of an incident occurring.
 *
 * @param probs Iterable containing individual incident probabilities.
 *
 * @returns The combined probability as a float number.
 */
let getCombinedProb = (probs) => {
  // if there's no valid score, return 0
  if (probs.length <= 0) {
    return 0;
  }

  // Get upper and lower bounds on the combined probability. Using the uni-modal
  // bounds theorem, we take the lower bound to be the highest individual
  // probability that we're combining, and the upper bound to be the combined
  // probability calculated using DeMorgan's rule.
  const lowerBound = Math.max(...probs);
  const upperBound = getDemorganProb(probs);

  // For now, we are simply returning the mean of the lower and upper bounds.
  // In the future, we may do something more here, such as passing a
  // probability distribution or a confidence interval.
  const combinedProb = (lowerBound + upperBound) / 2;

  return combinedProb;
};

/**
 * Takes an array of arms with riskScore data, returns the probability of
 * an incident after all mitigators have been applied.
 *
 * @param actionRiskMitigators ARM table containing risk scoring information.
 *  example: [
 *    {id: 1, a: {id: 1}, r: null, m: null, riskScore: 0.5},
 *    {id: 1, a: {id: 1}, r: {id: 11), m: null, riskScore: 0.9},
 *    {id: 1, a: {id: 1}, r: {id: 12), m: {id: 121}, riskScore: 0.3},
 *    {id: 1, a: {id: 1}, r: {id: 12), m: {id: 122}, riskScore: null},
 *  ]
 * @param armTree ARM table containing risk scoring information.
 *  example: [
 *    {id: 1, a: {id: 1}, r: {id: 12), m: {id: 111}, riskScore: 0.3},
 *    {id: 1, a: {id: 1}, r: {id: 12), m: {id: 112}, riskScore: 0.4},
 *  ]
 *
 * @returns The risk score base on the given arm tree.
 */
let calcMitigator = (actionRiskMitigators, armTree) => {
  const defaultScore = 0.5;
  // Get the action-risk level risk score as a starting point.
  let armRisk = armTree.find(
    (arm) => arm.a !== null && arm.r !== null && arm.m === null
  );
  let arScore = defaultScore;
  // if no risk arm exist, return defaultScore.
  if (armRisk === undefined) {
    arScore = defaultScore;
  } else {
    arScore = getRiskLevel(actionRiskMitigators, armRisk);
  }

  // Compile a list of the ARM risks for every mitigator.
  let armTreeMitigator = armTree.filter(
    (arm) => arm.a !== null && arm.r !== null && arm.m !== null
  );
  // Calculate the probabilities for each mitigator present.
  const mitigatorScores = armTreeMitigator.map((arm) =>
    getRiskLevel(actionRiskMitigators, arm)
  );

  // Calculate a final risk using each of the ARM risks in concert with the
  // AR risk. The logic here is that each ARM risk represents a certain
  // percentage decrease in risk from the AR risk, so we apply those
  // percentages sequentially to the initial (AR) risk, and use the result as
  // our fully mitigated risk value.
  const reductions = mitigatorScores.map(
    (mitigatorScore) => mitigatorScore / arScore
  );

  // We then look at the lowest individual mitigated score and set that as our
  // floor value.
  const scoreFloor = Math.min(...mitigatorScores);

  // Lastly we apply the percentage-wise reductions sequentially towards our
  // floor value.
  let combinedProb;
  if (reductions.length === 0) {
    combinedProb = arScore;
  } else {
    combinedProb =
      scoreFloor +
      (arScore - scoreFloor) *
        reductions.reduce((x, y) => {
          return x * y;
        });
  }

  return combinedProb;
};

/**
 * Takes a single action/risk combination's armTree with riskScore data,
 * returns the total risk score for that action/risk combination.
 *
 * @param actionRiskMitigators ARM table containing risk scoring information.
 *  example: [
 *    {id: 1, a: {id: 1}, r: null, m: null, riskScore: 0.5},
 *    {id: 1, a: {id: 1}, r: {id: 11), m: null, riskScore: 0.9},
 *    {id: 1, a: {id: 1}, r: {id: 12), m: {id: 111}, riskScore: 0.3},
 *  ]
 * @param armTree ARM table containing risk scoring information.
 *  example: [
 *    {id: 1, a: {id: 1}, r: {id: 11), m: null, riskScore: 0.9},
 *    {id: 1, a: {id: 1}, r: {id: 12), m: {id: 111}, riskScore: 0.3},
 *    {id: 1, a: {id: 1}, r: {id: 12), m: {id: 112}, riskScore: 0.4},
 *  ]
 *
 * @returns total risk score for that action/risk combination.
 */
let calcRisk = (actionRiskMitigators, armTree) => {
  const defaultScore = 0.5;
  // find the risk arm in the array
  let armRisk = armTree.find(
    (arm) => arm.a !== null && arm.r !== null && arm.m === null
  );

  let armTreeMitigator = [];
  // if no risk arm exist, return defaultScore.
  if (armRisk === undefined) {
    return defaultScore;
  } else {
    armTreeMitigator = armTree.filter(
      (arm) => arm.a !== null && arm.r !== null && arm.m !== null
    );
  }

  // Check if mitigator array is empty, and if so return the AR risk from risk arm.
  if (armTreeMitigator.length <= 0) {
    return getRiskLevel(actionRiskMitigators, armRisk);
  } else {
    // Otherwise, calculate the combined probability for all risks and return.
    return calcMitigator(actionRiskMitigators, armTree);
  }
};

/**
 * Takes a single action's armTree with riskScore data, returns the total risk score.
 *
 * @param actionRiskMitigators ARM table containing risk scoring information.
 *  example: [
 *    {id: 1, a: {id: 1}, r: null, m: null, riskScore: 0.5},
 *    {id: 1, a: {id: 1}, r: {id: 11), m: null, riskScore: 0.9},
 *    {id: 1, a: {id: 1}, r: {id: 12), m: {id: 121}, riskScore: 0.3},
 *    {id: 1, a: {id: 1}, r: {id: 12), m: {id: 122}, riskScore: null},
 *  ]
 * @param armTree ARM table containing risk scoring information.
 *  example: [
 *    {id: 1, a: {id: 1}, r: null, m: null, riskScore: 0.5},
 *    {id: 1, a: {id: 1}, r: {id: 11), m: null, riskScore: 0.9},
 *    {id: 1, a: {id: 1}, r: {id: 12), m: {id: 111}, riskScore: 0.3},
 *  ]
 *
 * @returns total risk score for that action.
 */
let calcAction = (actionRiskMitigators, armTree) => {
  const defaultScore = 0.5;
  // find the action arm in the array
  let armAction = armTree.find(
    (arm) => arm.a !== null && arm.r === null && arm.m === null
  );
  // if no action arm exist, return defaultScore.
  if (armAction === undefined) {
    return defaultScore;
  }

  // group arm tree by risks
  let armTreeGroupByRisk = armTree
    .filter((arm) => arm.r !== null)
    .reduce(function (groupObj, arm) {
      groupObj[arm.r.id] = groupObj[arm.r.id] || [];
      groupObj[arm.r.id].push(arm);
      return groupObj;
    }, {});

  // Calculate the probabilities for each risk present.
  let riskScores = [];
  for (const armTreeRisk of Object.values(armTreeGroupByRisk)) {
    riskScores.push(calcRisk(actionRiskMitigators, armTreeRisk));
  }

  // if there's no valid risk score, return the A risk from the action arm.
  if (riskScores.length <= 0) {
    return getRiskLevel(actionRiskMitigators, armAction);
  }

  return getCombinedProb(riskScores);
};

/**
 * Takes a armTree with riskScore data, returns the total risk score.
 *
 * @param actionRiskMitigators ARM table containing risk scoring information.
 *  example: [
 *    {id: 1, a: {id: 1}, r: null, m: null, riskScore: 0.5},
 *    {id: 1, a: {id: 1}, r: {id: 11), m: null, riskScore: 0.9},
 *    {id: 1, a: {id: 1}, r: {id: 12), m: {id: 121}, riskScore: 0.3},
 *    {id: 1, a: {id: 1}, r: {id: 12), m: {id: 122}, riskScore: null},
 *  ]
 * @param armTree ARM table containing risk scoring information.
 *  example: [
 *    {id: 1, a: {id: 1}, r: null, m: null, riskScore: 0.5},
 *    {id: 1, a: {id: 1}, r: {id: 11), m: null, riskScore: 0.9},
 *    {id: 1, a: {id: 1}, r: {id: 12), m: {id: 111}, riskScore: 0.3},
 *  ]
 *
 * @returns total risk score for the ARM tree.
 */
let calcARM = (actionRiskMitigators, armTree) => {
  const defaultScore = 0.5;
  // group arm tree by actions
  let armTreeGroupByAction = armTree.reduce(function (groupObj, arm) {
    groupObj[arm.a.id] = groupObj[arm.a.id] || [];
    groupObj[arm.a.id].push(arm);
    return groupObj;
  }, {});

  // Calculate the probabilities for each action present.
  let actionScores = [];
  for (const armTreeAction of Object.values(armTreeGroupByAction)) {
    actionScores.push(calcAction(actionRiskMitigators, armTreeAction));
  }

  // if there's no valid action score, return defaultScore
  if (actionScores.length === 0) {
    return defaultScore;
  }

  return getCombinedProb(actionScores);
};

/**
 * This function handles correcting the ra-score. if the calculated ra-score
 * not in the rang of [0, 100], replace it with 0
 *
 * @param score the single ra-score.
 *
 * @return float number either the input itself or 0.
 */
let correctScore = (score) => {
  if (isNaN(score)) {
    // if the input is not a number, return 0
    return 0;
  } else if (score >= 0 && score <= 100) {
    // if the input is a number, and in the correct range, return itself
    return score;
  } else {
    // otherwise, return 0
    return 0;
  }
};

/**
 * Using the given armTree , retrieves the scores for both pre and post mitigation.
 *
 * @param actionRiskMitigators ARM table containing risk scoring information.
 *  example: [
 *    {id: 1, a: {id: 1}, r: null, m: null, riskScore: 0.5},
 *    {id: 1, a: {id: 1}, r: {id: 11), m: null, riskScore: 0.9},
 *    {id: 1, a: {id: 1}, r: {id: 12), m: {id: 121}, riskScore: 0.3},
 *    {id: 1, a: {id: 1}, r: {id: 12), m: {id: 122}, riskScore: null},
 *  ]
 * @param selectedArmTree The arm tree array with objects of ids.
 *  example: [
 *    {id: 1, a: {id: 1}, r: null, m: null},
 *    {id: 1, a: {id: 1}, r: {id: 11), m: null},
 *    {id: 1, a: {id: 1}, r: {id: 12), m: {id: 111}},
 *  ]
 * @param type "primary"--all selected arms, the real raScore, default value.
 *   or "preMit"--no mitigator arms, the max raScore.
 *   or "postMit"--includes all mitigators from source data, the min raScore.
 *
 * @returns armTreeToCalculate, armTree with all riskScores for calculation
 */
let getArmTreeToCalculate = (actionRiskMitigators, selectedArmTree, type) => {
  let armTreeToCalculate = [];
  if (type === "primary") {
    // find all arm from data with score
    for (let selectedArm of selectedArmTree) {
      //  loop through all selected arm, find all arms from data
      if (selectedArm.a !== null) {
        // selected arm has to have valid action
        if (selectedArm.r === null && selectedArm.m === null) {
          // find the action arm from data
          armTreeToCalculate = armTreeToCalculate.concat(
            actionRiskMitigators.filter(
              (arm) =>
                arm.a !== null &&
                arm.a.id === selectedArm.a.id &&
                arm.r === null &&
                arm.m === null
            )
          );
        } else if (selectedArm.r !== null && selectedArm.m === null) {
          // find the action-risk arm from data
          armTreeToCalculate = armTreeToCalculate.concat(
            actionRiskMitigators.filter(
              (arm) =>
                arm.a !== null &&
                arm.r !== null &&
                arm.a.id === selectedArm.a.id &&
                arm.r.id === selectedArm.r.id &&
                arm.m === null
            )
          );
        } else if (selectedArm.r !== null && selectedArm.m !== null) {
          // find the action-risk-mitigator arm from data
          armTreeToCalculate = armTreeToCalculate.concat(
            actionRiskMitigators.filter(
              (arm) =>
                arm.a !== null &&
                arm.r !== null &&
                arm.m !== null &&
                arm.a.id === selectedArm.a.id &&
                arm.r.id === selectedArm.r.id &&
                arm.m.id === selectedArm.m.id
            )
          );
        }
      }
    }
  } else if (type === "preMit") {
    // find all arm from data with score, no mitigator needed
    for (let selectedArm of selectedArmTree) {
      //  loop through all selected arm, find all arms from data
      if (selectedArm.a !== null) {
        // selected arm has to have valid action
        if (selectedArm.r === null && selectedArm.m === null) {
          // find the action arm from data
          armTreeToCalculate = armTreeToCalculate.concat(
            actionRiskMitigators.filter(
              (arm) =>
                arm.a !== null &&
                arm.a.id === selectedArm.a.id &&
                arm.r === null &&
                arm.m === null
            )
          );
        } else if (selectedArm.r !== null && selectedArm.m === null) {
          // find the action-risk arm from data
          armTreeToCalculate = armTreeToCalculate.concat(
            actionRiskMitigators.filter(
              (arm) =>
                arm.a !== null &&
                arm.r !== null &&
                arm.a.id === selectedArm.a.id &&
                arm.r.id === selectedArm.r.id &&
                arm.m === null
            )
          );
        }
      }
    }
  } else if (type === "postMit") {
    // find all arm from data with score, all mitigator needed
    for (let selectedArm of selectedArmTree) {
      //  loop through all selected arm, find all arms from data
      if (selectedArm.a !== null) {
        // selected arm has to have valid action
        if (selectedArm.r === null && selectedArm.m === null) {
          // find the action arm from data
          armTreeToCalculate = armTreeToCalculate.concat(
            actionRiskMitigators.filter(
              (arm) =>
                arm.a !== null &&
                arm.a.id === selectedArm.a.id &&
                arm.r === null &&
                arm.m === null
            )
          );
        } else if (selectedArm.r !== null && selectedArm.m === null) {
          // find the action-risk and action-risk-mitigator arms from data
          armTreeToCalculate = armTreeToCalculate.concat(
            actionRiskMitigators.filter(
              (arm) =>
                arm.a !== null &&
                arm.r !== null &&
                arm.a.id === selectedArm.a.id &&
                arm.r.id === selectedArm.r.id
            )
          );
        }
      }
    }
  }
  return armTreeToCalculate;
};

/**
 * Using the given armTree, retrieves the raScore base on type.
 *
 * @param actionRiskMitigators ARM table containing risk scoring information.
 *  example: [
 *    {id: 1, a: {id: 1}, r: null, m: null, riskScore: 0.5},
 *    {id: 1, a: {id: 1}, r: {id: 11}, m: null, riskScore: 0.9},
 *    {id: 1, a: {id: 1}, r: {id: 12}, m: {id: 121}, riskScore: 0.3},
 *    {id: 1, a: {id: 1}, r: {id: 12}, m: {id: 122}, riskScore: null},
 *  ]
 * @param armTree The arm tree array with objects of ids.
 *  example: [
 *    {id: 1, a: {id: 1}, r: null, m: null},
 *    {id: 1, a: {id: 1}, r: {id: 11}, m: null},
 *    {id: 1, a: {id: 1}, r: {id: 12}, m: {id: 111}},
 *  ]
 * @param type "primary"--all selected arms, the real raScore, default value.
 *   or "preMit"--no mitigator arms, the max raScore.
 *   or "postMit"--includes all mitigators from source data, the min raScore.
 *
 * @param roundScore The final score as integer or not.
 *
 * @returns raScore, float or integer number depending on roundScore
 */
let getRaScores = (
  actionRiskMitigators,
  armTree,
  type = "primary",
  roundScore = false
) => {
  let armTreeCalculate = getArmTreeToCalculate(
    actionRiskMitigators,
    armTree,
    type
  );

  let raScore = 0;
  if (armTreeCalculate.length > 0) {
    raScore = correctScore(
      // remove duplicates from armTreeCalculate
      parseFloat(calcARM(actionRiskMitigators, [...new Set(armTreeCalculate)]))
    );
  }

  if (roundScore) {
    return parseInt(Math.round(raScore * 100));
  } else {
    return raScore * 100;
  }
};

/**
 * Using the given ra-scores, gives a risked mitigated, corrected and absorbed scores.
 *
 * @param raScore the risk score with mitigators performed.
 * @param maxScore the risk score with no mitigator performed. preMit score
 * @param minScore the risk score with available mitigators performed, postMit score.
 * @param corrScore the risk score with corrections performed, corr score.
 *
 * @returns object with both key and value for mitigated, corrected and absorbed
 */
let getRiskedScore = (raScore, maxScore, minScore, corrScore) => {
  let mitigated = 100;
  let corrected = 100;
  if (minScore === maxScore) {
    mitigated = 100;
    corrected = 100;
  } else {
    mitigated = ((maxScore - raScore) / (maxScore - minScore)) * 100;
    corrected = ((raScore - corrScore) / (maxScore - minScore)) * 100;
  }
  let absorbed = 100 - (mitigated + corrected);

  return { mitigated, corrected, absorbed };
};

/**
 * Using the given percent mitigated, gives a grade.
 *
 * @param percentMitigated a percentage number
 *
 * @returns grade letter
 */
let gradeMap = (percentMitigated) => {
  if (percentMitigated < 60) {
    return "F";
  } else if (percentMitigated < 70) {
    return "D";
  } else if (percentMitigated < 80) {
    return "C";
  } else if (percentMitigated < 90) {
    return "B";
  } else {
    return "A";
  }
};

/**
 * Using the given ra-scores, gives a grade letter and mitigated risk percentage.
 *
 * @param raScore the risk score with mitigators performed.
 * @param maxScore the risk score with no mitigator performed. preMit score
 * @param minScore the risk score with available mitigators performed, postMit score.
 *
 * @returns object with both key and value for grade letter and percentage of mitigated risk
 */
let getGradeLetter = (raScore, maxScore, minScore) => {
  let mitigatedRisk = 100;
  if (minScore === maxScore) {
    mitigatedRisk = 100;
  } else if (minScore === raScore) {
    mitigatedRisk = 100;
  } else if (maxScore === raScore) {
    mitigatedRisk = 0;
  } else {
    mitigatedRisk = ((maxScore - raScore) / (maxScore - minScore)) * 100;
  }
  let grade = gradeMap(mitigatedRisk);
  return { grade, mitigatedRisk };
};

/**
 * Takes a list of numeric individual audit risk scores and returns
 * the combined risk score.
 *
 * @param topicQuestions A list of numeric individual scores.
 *  example: [0.5, 0.2]
 *
 * @returns total risk score for that Topic.
 */
let calcTopic = (topicQuestions) => {
  const defaultScore = 0.5;
  // Check if risk_scores is empty, and if so return defaultScore.
  if (topicQuestions.length === 0) {
    return defaultScore;
  }

  return getCombinedProb(topicQuestions);
};

/**
 * Takes a list of numeric individual topic of questions with scores and returns
 * the combined risk score.
 *
 * @param auditTopics A list of numeric individual topic of questions with scores.
 *  example: [
 *    {questions: [
 *      {score: 0.5, response{score: 0.6}},
 *      {score: 0.2, response{score: null}}
 *    ]},
 *    {questions: [{score: 0.4, response{score: null}}},
 *  ]
 *
 * @returns total risk score for that Audit.
 */
let calAudit = (auditTopics) => {
  const defaultScore = 0.5;

  // Calculate the probabilities for each Topic
  let topicScores = [];
  for (let topic of auditTopics) {
    if (topic.questions.length > 0) {
      topicScores.push(
        calcTopic(
          topic.questions.map((question) => {
            // try to get altered score from response
            let score = question.response.score;
            if (score === null || score === undefined) {
              score = question.score;
            }
            // if the question unscored, return default score
            if (score === null || score === undefined) {
              score = defaultScore;
            }
            return score;
          })
        )
      );
    }
  }

  // if there's no valid topic score, return 0
  if (topicScores.length === 0) {
    return defaultScore;
  }

  return getCombinedProb(topicScores);
};

/**
 * Using the given auditTopicQuestions and responses, retrieves the scores and penalties
 *
 * @param auditTopics The audit topic question tree that includes scores
 *  example: [
 *    {id: '1', questions: [
 *      {id: '1', penalty: 800, score: 0.5}
 *      {id: '2', penalty: 200, score: 0.2}
 *    ]},
 *    {id: '2', questions: [
 *      {id: '3', penalty: 0, score: 0.4}
 *      {id: '4', penalty: 0, score: 0.8}
 *    ]}
 *  ]
 * @param responses The responses array base on auditTopicQuestion id.
 *  example: [
 *    {auditTopicQuestion: '1', penalty: 800, response: '-1', score: 0.6}
 *    {auditTopicQuestion: '2', penalty: 200, response: '1', score: 0.6}
 *    {auditTopicQuestion: '3', penalty: 0, response: '0', score: null}
 *    {auditTopicQuestion: '4', penalty: 0, score: 0.6}
 *  ]
 *
 * @returns object with both key and value for raScore and penalty
 */
let getAuditScores = (auditTopics, responses) => {
  // only keep the response with "No" answer
  let validResponses = responses.filter(
    (response) => parseInt(response.response) === -1
  );
  // collect all question ids from audit for all valid responses
  let validQuestionIds = validResponses.map(
    (response) => response.auditTopicQuestion
  );
  // collect all questions from audit for easier accessing score and penalty
  let questions = [];
  auditTopics.map((topic) => (questions = questions.concat(topic.questions)));
  // only keep the questions that the response with "No" answer
  let validQuestions = questions.filter((question) =>
    validQuestionIds.includes(question.id)
  );
  // only keep the questions that the response with "No" answer in auditTopics
  let validAuditTopics = [];
  for (let topic of auditTopics) {
    let questions = topic.questions.filter((question) =>
      validQuestionIds.includes(question.id)
    );

    let questionResponses = [];
    // add response to questions
    for (let question of questions) {
      questionResponses.push({
        ...question,
        response: validResponses.find(
          (response) => response.auditTopicQuestion === question.id
        ),
      });
    }

    validAuditTopics.push({ questions: questionResponses });
  }

  // sum up all penalty, filter out those penalty with a number value.
  let penalty = validQuestions
    .filter((response) => !isNaN(response.penalty))
    .reduce(
      (a, b) => ({
        penalty: a.penalty + b.penalty,
      }),
      { penalty: 0 }
    ).penalty;

  // calculate the ra score
  let raScore;
  if (validResponses.length > 0) {
    raScore = calAudit(validAuditTopics);
  } else {
    raScore = 0;
  }

  return { raScore, penalty };
};

export {
  getAuditScores,
  getCombinedProb,
  getGradeLetter,
  getRaScores,
  getRiskedScore,
  getRiskLevel,
};
