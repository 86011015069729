import React from "react";

import CreateSite from "../../../../Planning/Site/CreateSite";

export default function CreateOwnedSite({ open, setOpen, company, extraData }) {
  return (
    <CreateSite
      open={open}
      handleClose={() => setOpen(false)}
      company={company}
      data={extraData}
    />
  );
}
