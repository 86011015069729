import React from "react";
import { useNavigate } from "react-router-dom";

import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Checkbox,
  Tooltip,
} from "@mui/material";

import {
  useMutation,
  useQuery,
  useLazyQuery,
  useReactiveVar,
} from "@apollo/client";
import * as Sentry from "@sentry/react";
import moment from "moment";

import {
  createHAVar,
  editHAVar,
  initialState,
} from "../../../graphql/localVariables/ha";

import { onlineVar } from "../../../graphql/localVariables/user";
import { authMutations, haMutations } from "../../../graphql/mutations";
import { CREATE_HA, EDIT_HA } from "../../../graphql/mutations/ha";
import { GET_COMPANY_ARMS } from "../../../graphql/queries";
import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import { getGradeLetter, getRaScores } from "../../../utils/raScore";
import { onErrorFunc } from "../../CustomComponents/OnErrorFunction";
import { yellowButton, blackButton } from "../../CustomStyles/buttons";
import ActionTableHeaderView from "./ActionTableHeaders";
import ActionTableView from "./ActionTableView";
import AddActionInputView from "./AddActionsInputView";
import CrewMemberView from "./CrewMemberView";
import DateInputView from "./DateInputView";
import InjuryView from "./InjuryView";
import LocationInputView from "./LocationInputView";
import ProjectInputView from "./ProjectInputView";
import TemplateInputView from "./TemplateInputView";
import TextInputView from "./TextInputView";
import TimeInputView from "./TimeInputView";

export default function HAForm({ ha, type }) {
  const navigate = useNavigate();
  const online = useReactiveVar(onlineVar);
  const editHA = useReactiveVar(editHAVar);
  const createHA = useReactiveVar(createHAVar);
  const updateHA = type === "EDIT" ? editHA : createHA;
  const { updateCreateHA, updateEditHA, updateAllCreateHA, updateAllEditHA } =
    haMutations;
  const { updateSnackbar, updateLoading } = authMutations;
  const updateHAFunction = type === "EDIT" ? updateEditHA : updateCreateHA;
  const updateAllHAFunction =
    type === "EDIT" ? updateAllEditHA : updateAllCreateHA;

  const { data } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const [getActions, { data: actions, loading: loadingActions }] =
    useLazyQuery(GET_COMPANY_ARMS);
  const [getRisks, { data: risks, loading: loadingRisks }] =
    useLazyQuery(GET_COMPANY_ARMS);
  const [getMits, { data: mit, loading: loadingMit }] =
    useLazyQuery(GET_COMPANY_ARMS);

  React.useEffect(() => {
    if (data && type !== "EDIT") {
      const companyId = Number(data.currentUser.company.id);
      getActions({
        variables: { companyId, rNull: true, mNull: true },
        fetchPolicy: online ? "network-only" : "cache-only",
      });
      getRisks({
        variables: { companyId, rNull: false, mNull: true },
        fetchPolicy: online ? "network-only" : "cache-only",
      });
      getMits({
        variables: { companyId, rNull: false, mNull: false },
        fetchPolicy: online ? "network-only" : "cache-only",
      });
    }
  }, [data]);

  React.useEffect(() => {
    if (ha?.submitter && type === "EDIT") {
      const companyId = Number(ha.submitter.company.id);
      getActions({
        variables: { companyId, rNull: true, mNull: true },
        fetchPolicy: online ? "network-only" : "cache-only",
      });
      getRisks({
        variables: { companyId, rNull: false, mNull: true },
        fetchPolicy: online ? "network-only" : "cache-only",
      });
      getMits({
        variables: { companyId, rNull: false, mNull: false },
        fetchPolicy: online ? "network-only" : "cache-only",
      });
    }
  }, [ha?.submitter]);

  const [createHAMutation, { loading: loadingCreate }] = useMutation(
    CREATE_HA,
    {
      onCompleted(data) {
        if (data.haCreate.hazardAnalysis) {
          updateSnackbar({
            open: true,
            message: "HA was sent successfully!",
            severity: "success",
          });
          navigate(`/view-ha/${data.haCreate.hazardAnalysis.haId}`);
          updateAllHAFunction(initialState);
        } else {
          onErrorFunc("There was an error when creating your HA.");
        }
      },
      onError(error) {
        onErrorFunc(error);
      },
    }
  );
  const [editHAMutation, { loading: loadingEdit }] = useMutation(EDIT_HA, {
    onCompleted(data) {
      if (data.haEdit.hazardAnalysis) {
        updateSnackbar({
          open: true,
          message: "HA was edited successfully!",
          severity: "success",
        });
        navigate(`/view-ha/${data.haEdit.hazardAnalysis.haId}`);
        updateAllHAFunction(initialState);
      } else {
        onErrorFunc("There was an error when editing your HA.");
      }
    },
    onError(error) {
      onErrorFunc(error);
    },
  });

  const [openConfirmClear, setOpenConfirmClear] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  // Handle close of Acknowledge Crewmember dialog
  const handleClose = () => {
    setOpen(false);
    setDisabled(false);
  };

  const confirmSubmit = () => {
    if (updateHA.name === "") {
      updateSnackbar({
        open: true,
        message: "Make sure to include a name.",
        severity: "error",
      });
    } else if (!updateHA.date) {
      updateSnackbar({
        open: true,
        message: "Make sure to include a date.",
        severity: "error",
      });
    } else if (!updateHA.location) {
      updateSnackbar({
        open: true,
        message: "Make sure to include a site and site owner.",
        severity: "error",
      });
    } else if (updateHA.projects.length === 0) {
      updateSnackbar({
        open: true,
        message: "Make sure to include at least one project.",
        severity: "error",
      });
    } else if (updateHA.ospIds.length === 0) {
      updateSnackbar({
        open: true,
        message: "Please reselect the site and project.",
        severity: "error",
      });
    } else if (updateHA.actions.length === 0) {
      updateSnackbar({
        open: true,
        message: "Make sure to select at least one action.",
        severity: "error",
      });
    } else if (updateHA.crewMembers.length > 0) {
      setOpen(true);
    } else {
      submitForm();
    }
  };

  const submitForm = () => {
    setDisabled(true);
    let arms = [];
    if (actions && risks && mit) {
      arms = [...actions.companyArms, ...risks.companyArms, ...mit.companyArms]
        .map((carm) => carm.arm)
        .map((arm) => {
          if (arm.r === null || arm.r.id === null)
            return { ...arm, r: null, m: null };
          if (arm.m === null || arm.m.id === null) return { ...arm, m: null };
          return arm;
        });
    }
    if (arms.length === 0) {
      if (online) {
        let companyId = null;
        if (type === "EDIT") {
          companyId = Number(updateHA.submitter.company.id);
        } else if (data?.currentUser?.company) {
          companyId = Number(data?.currentUser?.company?.id);
        } else {
          Sentry.captureMessage("Creating HA & currentUser is undefined.");
        }

        if (companyId) {
          getActions({
            variables: { companyId, rNull: true, mNull: true },
            fetchPolicy: "network-only",
          });
          getRisks({
            variables: { companyId, rNull: false, mNull: true },
            fetchPolicy: "network-only",
          });
          getMits({
            variables: { companyId, rNull: false, mNull: false },
            fetchPolicy: "network-only",
          });
          updateSnackbar({
            open: true,
            message:
              "Please wait a moment before submitting, your company ARMs are loading.",
            severity: "info",
          });
        } else {
          updateSnackbar({
            open: true,
            message:
              "Please refresh the page and then try again to submit after everything loads.",
            severity: "info",
          });
        }
      } else {
        updateSnackbar({
          open: true,
          message:
            "Your device is offline and does not have your company ARMs saved. Please wait until online to resubmit.",
          severity: "info",
        });
      }
    } else {
      const formattedInputs = [
        ...updateHA.actions,
        ...updateHA.risks,
        ...updateHA.mitigators,
      ].map((arm) => {
        return {
          id: Number(arm.id),
          a: { id: Number(arm.a.id) },
          r: arm.r?.id ? { id: Number(arm.r.id) } : null,
          m: arm.m?.id ? { id: Number(arm.m.id) } : null,
        };
      });

      console.log(arms, formattedInputs);

      const score = getRaScores(arms, formattedInputs);
      const pre = getRaScores(arms, formattedInputs, "preMit");
      const post = getRaScores(arms, formattedInputs, "postMit");
      const { grade } = getGradeLetter(score, pre, post);

      // prevent date from changing timezones when editing
      const d = new Date(updateHA.date);
      const utc = d.getTime() + d.getTimezoneOffset() * 60000;
      const convertedDate = new Date(utc);

      const input = {
        name: updateHA.name,
        ownerSiteProjects: updateHA.ospIds.map((id) => ({
          id: Number(id),
        })),
        date: moment(convertedDate).format("YYYY-MM-DD"),
        time: moment(new Date(updateHA.startTime)).format("HH:mm:ss"),
        comment: updateHA.comment,
        hasInjury: updateHA.hasInjury,
        injuryText: updateHA.injuryText,
        raScore: score,
        raScoreMin: post,
        raScoreMax: pre,
        grade: grade,
        arms: [
          ...updateHA.actions,
          ...updateHA.risks,
          ...updateHA.mitigators,
        ].map((arm) => {
          let copy = { id: Number(arm.id) };
          if (arm.actionOrdering !== undefined) {
            copy = { ...copy, actionOrdering: arm.actionOrdering };
          }
          return copy;
        }),
        templates: updateHA.templates.map((t) => ({ id: Number(t.id) })),
        crewMembers: updateHA.crewMembers.map((crew) => {
          let copy = { id: Number(crew.id) };
          if (crew.replace) {
            copy = {
              ...copy,
              replace: crew.replace,
              signatureBase64: crew.signatureBase64,
            };
          }
          return copy;
        }),
      };

      if (type === "EDIT") {
        editHAMutation({
          variables: { id: Number(updateHA.haId), input },
        });

        if (!online) {
          updateSnackbar({
            open: true,
            message:
              "Your device is offline. Your HA will be edited when back online.",
            severity: "info",
          });
          navigate("/");
        }
      } else {
        createHAMutation({
          variables: {
            input,
          },
        });

        if (!online) {
          updateSnackbar({
            open: true,
            message:
              "Your device is offline. Your HA will be created when back online.",
            severity: "info",
          });
          navigate("/");
        }
      }
    }
  };

  // Show Loading wheel
  React.useEffect(() => {
    if (online) {
      updateLoading(loadingCreate || loadingEdit);
    }
  }, [loadingCreate, loadingEdit]);

  const confirmClear = () => {
    setOpenConfirmClear(true);
  };

  const clearForm = () => {
    setOpenConfirmClear(false);
    updateAllHAFunction(initialState);
    if (type === "CREATE") {
      updateHAFunction("startTime", new Date());
    }
  };

  const cancelForm = () => {
    navigate("/view-ha");
  };

  React.useEffect(() => {
    if (type === "EDIT" && ha) {
      updateHAFunction("id", ha.id);
      updateHAFunction("haId", ha.haId);
      updateHAFunction("name", ha.name);
      updateHAFunction("date", ha.date);
      let startTime = new Date();
      const startArr = ha.time.split(":");
      startTime.setHours(startArr[0], startArr[1], startArr[2], 0);
      updateHAFunction("startTime", startTime);
      updateHAFunction("submitter", ha.submitter);
      if (ha.haOwnerSiteProjects.length > 0) {
        updateHAFunction(
          "location",
          `${ha?.haOwnerSiteProjects[0].ownerSiteProject.site.name} [${ha?.haOwnerSiteProjects[0].ownerSiteProject.owner.name}]`
        );

        updateHAFunction(
          "siteId",
          ha?.haOwnerSiteProjects[0].ownerSiteProject.site.id
        );
        updateHAFunction(
          "ownerId",
          ha?.haOwnerSiteProjects[0].ownerSiteProject.owner.id
        );
      } else {
        updateHAFunction("location", "");
      }
      updateHAFunction(
        "templatesFromPDF",
        ha?.haTemplate && ha?.haTemplate.length > 0
          ? ha?.haTemplate?.map((haT) => haT.template)
          : []
      );
      updateHAFunction(
        "projects",
        ha?.haOwnerSiteProjects.map((p) => p.ownerSiteProject.project)
      );
      updateHAFunction(
        "templates",
        ha?.haTemplate && ha?.haTemplate.length > 0
          ? ha?.haTemplate?.map((haT) => haT.template)
          : []
      );
      const formattedArms = ha?.haArms.map((arm) => ({
        id: arm.arm.id,
        riskScore: arm.arm.riskScore,
        actionOrdering: arm.actionOrdering,
        a: arm.arm.a,
        r: arm.arm.r,
        m: arm.arm.m,
      }));
      updateHAFunction(
        "actions",
        formattedArms.filter((arm) => arm.a && !arm.r && !arm.m)
      );
      updateHAFunction(
        "risks",
        formattedArms.filter((arm) => arm.r && !arm.m)
      );
      updateHAFunction(
        "mitigators",
        formattedArms.filter((arm) => arm.m)
      );
      updateHAFunction(
        "crewMembers",
        ha?.haCrewMembers.map((haCrew) => haCrew.crewMember)
      );
      updateHAFunction("hasInjury", ha?.hasInjury);
      updateHAFunction("injuryText", ha?.injuryText);
      updateHAFunction("comment", ha?.comment);
    }
  }, [ha]);

  React.useEffect(() => {
    if (type === "CREATE") {
      updateHAFunction("date", new Date());
      updateHAFunction("startTime", new Date());
    }
  }, []);

  return (
    <Grid
      container
      style={{
        borderRadius: "5px",
        backgroundColor: "#fff",
        padding: "1em",
        minWidth: "200px",
      }}
      sx={{ marginBottom: { xs: "50px", sm: 0 } }}
    >
      <TextInputView
        label={"Hazard Analysis Name:"}
        value={updateHA.name}
        updateFunction={(val) => updateHAFunction("name", val)}
        questionKey={"name"}
        xs={12}
        md={6}
        lg={3}
      />
      <DateInputView type={type} />
      <TextInputView
        label={"Created By:"}
        value={
          type === "EDIT"
            ? updateHA.submitter?.username
            : data?.currentUser.username
        }
        updateFunction={() => {}}
        disabled={true}
        questionKey={"author"}
        xs={12}
        md={6}
        lg={3}
      />
      <TimeInputView type={type} />
      <LocationInputView type={type} />
      <ProjectInputView type={type} />
      <TemplateInputView type={type} actions={actions} risks={risks} />

      <AddActionInputView
        type={type}
        actions={actions}
        loading={loadingActions}
      />

      <Grid
        item
        xs={12}
        style={{
          height: "2em",
          width: "100%",
          backgroundColor: "#000",
        }}
      />
      <ActionTableHeaderView />
      <ActionTableView
        type={type}
        actions={actions}
        loadingActions={loadingActions}
        risks={risks}
        loadingRisks={loadingRisks}
        mitigators={mit}
        loadingMitigators={loadingMit}
      />
      <CrewMemberView type={type} />
      <Grid container item sm={12} md={6}>
        <TextInputView
          label={"Additional Comments:"}
          multiline={true}
          questionKey={"comment"}
          value={updateHA.comment}
          updateFunction={(val) => updateHAFunction("comment", val)}
          xs={12}
          md={12}
          lg={12}
        />
        {type === "EDIT" ? <InjuryView type={type} /> : <Grid />}
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        sx={{
          position: "fixed",
          width: {
            xs: "calc(100% - 70px)",
            md: "calc(100% - 100px)",
          },
          bottom: { xs: 100, md: 20 },
        }}
      >
        {type === "EDIT" ? (
          <Button variant="contained" sx={blackButton} onClick={cancelForm}>
            CANCEL
          </Button>
        ) : (
          <Button variant="contained" sx={blackButton} onClick={confirmClear}>
            CLEAR HA
          </Button>
        )}
        <Tooltip
          title={
            loadingActions || loadingRisks || loadingMit
              ? "Loading ARM data, please wait..."
              : ""
          }
        >
          <span>
            <Button
              variant="contained"
              sx={{ ...yellowButton }}
              onClick={confirmSubmit}
              disabled={
                disabled || loadingActions || loadingRisks || loadingMit
              }
            >
              {type === "EDIT" ? "SAVE" : "SUBMIT"}
            </Button>
          </span>
        </Tooltip>
      </Grid>
      <Dialog
        open={openConfirmClear}
        onClose={() => setOpenConfirmClear(false)}
      >
        <DialogContent sx={{ textAlign: "center" }}>
          <Typography variant="h5">
            Are you sure you want to clear this HA?
          </Typography>
          <Typography>
            All information in this form will be cleared and you will have to
            restart.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={clearForm}
            style={{ backgroundColor: "red", color: "white" }}
          >
            CLEAR
          </Button>
          <Button onClick={() => setOpenConfirmClear(false)}>CANCEL</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography>
            Upon initiating and submitting this document, I confirm to the best
            of my knowledge that the information listed was reviewed with all
            crew members who then signed this document and that all work was
            performed according to this document. Upon closure of this document,
            whether manually or automatically, I confirm no incidents or
            injuries were reported by the crew members or myself that have not
            been recorded on this document.
          </Typography>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: "20px" }}
          >
            <Checkbox
              checked={checked}
              onChange={(event) => setChecked(event.target.checked)}
              style={{ marginRight: "10px" }}
            />
            <Typography>I confirm.</Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            onClick={submitForm}
            loading={loadingCreate || loadingEdit}
            disabled={!checked && !disabled}
          >
            SUBMIT
          </LoadingButton>
          <Button onClick={handleClose}>CANCEL</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
