import React from "react";

import {
  Autocomplete,
  Button,
  ClickAwayListener,
  Grid,
  Grow,
  Paper,
  Popper,
  TextField,
  Typography,
} from "@mui/material";

import { DropzoneDialog } from "mui-file-dropzone";

import PreviewImages from "./PreviewImages";

const findName = (id) => {
  switch (id) {
    case 2:
      return "Actions";
    case 3:
      return "Risks";
    case 4:
      return "Mitigators";
    case 5:
      return "Corrections";
    case 6:
      return "Outcomes";
    default:
      return "";
  }
};

const findInitialState = (id) => {
  switch (id) {
    case 2:
      return { a: { name: "" } };
    case 3:
      return { r: { name: "" }, a: { name: "" } };
    case 4:
    case 5:
    case 6:
      return { m: { name: "" }, r: { name: "" }, a: { name: "" } };
    default:
      return "";
  }
};

export default function PhotoMenu({
  handleFilesChange,
  fileObjects,
  currentSection,
  anchorRef,
  open,
  handleClose,
  updateObsKeyFunction,
  form,
}) {
  const [openDropzone, setOpenDropzone] = React.useState(false);
  const [selected, setSelected] = React.useState(
    findInitialState(currentSection)
  );
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleSave = (files) => {
    setOpenDropzone(false);
    const mapFiles = [...files];
    handleFilesChange([
      ...fileObjects,
      ...mapFiles.map((f) => ({
        originalFile: f,
        section: currentSection,
        associatedItem: selected,
      })),
    ]);
    setSelected(findInitialState(currentSection));
  };

  const handleCloseDropzone = () => {
    setOpenDropzone(false);
  };

  const handleChange = (event, newValue) => {
    setSelected(newValue);
  };

  const getOptions = () => {
    switch (currentSection) {
      case 2:
        return form.actions.filter(
          (a) =>
            fileObjects.findIndex(
              (fo) => fo.associatedItem.id === a.id && fo.section === 2
            ) < 0
        );
      case 3:
        return form.risks.filter(
          (r) =>
            fileObjects.findIndex(
              (fo) => fo.associatedItem.id === r.id && fo.section === 3
            ) < 0
        );
      case 4:
        return form.mitigators.filter(
          (m) =>
            fileObjects.findIndex(
              (fo) => fo.associatedItem.id === m.id && fo.section === 4
            ) < 0
        );
      case 5:
        return form.unselectedMitigators
          .filter((m) => m.isCorrection && !m.isActive)
          .filter(
            (m) =>
              fileObjects.findIndex(
                (fo) => fo.associatedItem.id === m.id && fo.section === 5
              ) < 0
          );
      case 6:
        return [
          ...form.mitigators.filter((m) => m.isRootCause),
          ...form.unselectedMitigators.filter((m) => m.isRootCause),
        ].filter(
          (m) =>
            fileObjects.findIndex(
              (fo) => fo.associatedItem.id === m.id && fo.section === 6
            ) < 0
        );
      default:
        return "";
    }
  };

  const getSelected = () => {
    if (selected.m && selected.m.name !== "") {
      return true;
    } else if (selected.r && selected.r.name !== "") {
      return true;
    } else if (selected.a && selected.a.name !== "") {
      return true;
    } else return false;
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      placement="top-start"
      transition
      disablePortal
      style={{ paddingBottom: "5px" }}
      sx={{ width: { xs: "75vw", md: "35vw" } }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "left top" : "left bottom",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <Grid container style={{ padding: "20px", minHeight: 200 }}>
                <Grid item xs={12}>
                  <Typography>
                    Add Photos to {findName(currentSection)}
                  </Typography>
                </Grid>
                <PreviewImages
                  fileObjects={fileObjects.filter(
                    (f) => f.section === currentSection
                  )}
                  handleFilesChange={handleFilesChange}
                  updateObsKeyFunction={updateObsKeyFunction}
                  form={form}
                />
                <Grid item container alignItems="center">
                  <Grid item xs={8}>
                    <Autocomplete
                      id="photo-selection"
                      value={selected}
                      options={getOptions()}
                      getOptionLabel={(option) => {
                        switch (currentSection) {
                          case 2:
                            return option.a ? option.a.name : "";
                          case 3:
                            return option.r
                              ? option.r.name === ""
                                ? ""
                                : `${option.r.name} [${option.a.name}]`
                              : "";
                          case 4:
                          case 5:
                          case 6:
                            return !option.m || option.m?.name === ""
                              ? ""
                              : `${option.m.name} [${option.r.name}] [${option.a.name}]`;
                          default:
                            return "";
                        }
                      }}
                      onChange={handleChange}
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label={`Observed ${findName(currentSection)}:`}
                        />
                      )}
                      style={{ zIndex: 20 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      onClick={() => setOpenDropzone(true)}
                      style={{ marginLeft: "10px" }}
                      disabled={!getSelected()}
                    >
                      Upload Photo
                    </Button>
                  </Grid>
                </Grid>
                <DropzoneDialog
                  open={openDropzone}
                  onSave={handleSave}
                  acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                  showPreviews={true}
                  onClose={handleCloseDropzone}
                  maxFileSize={10000000} // 10MB
                />
              </Grid>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
