import * as React from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import EditUserDialog from "../../../Edit/EditUserDialog";

function Row({ row }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow
        hover
        onClick={() => setOpen(true)}
        key={row.id}
        style={{ cursor: "pointer" }}
      >
        <TableCell component="th" scope="row">
          {`${row.firstName} ${row.lastName}`}
        </TableCell>
        <TableCell>{row.username}</TableCell>
        <TableCell>
          {row.userPermissionGroups
            .map((pg) => pg.permissionGroup.name)
            .join(", ")}
        </TableCell>
        <TableCell>{row.ha.length}</TableCell>
        <TableCell>{row.observation.length}</TableCell>
        <TableCell>{row.auditSubmission.length}</TableCell>
      </TableRow>
      <EditUserDialog open={open} setOpen={setOpen} d={row} />
    </>
  );
}

export default function TableBodyTemplate(props) {
  // these props are available in table body
  const { order, orderBy, page, rowsPerPage, getComparator, stableSort, data } =
    props;

  if (
    stableSort(data, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    ).length === 0
  ) {
    return (
      <TableRow>
        <TableCell style={{ borderBottom: "transparent" }} colSpan={5}>
          No Results Found
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {stableSort(data, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => (
          <Row key={row.id} row={row} />
        ))}
    </>
  );
}
