import * as React from "react";

import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Autocomplete,
  TextField,
  Grid,
  Button,
  FormControlLabel,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";

import { useQuery, useReactiveVar } from "@apollo/client";

import { onlineVar } from "../../../../../graphql/localVariables/user";
import { GET_COMPANIES } from "../../../../../graphql/queries";
import { CustomSwitch } from "../../../../CustomComponents/Switch";
import { blueButton, blackButton } from "../../../../CustomStyles/buttons";
import {
  Search,
  StyledInputBase,
  SearchIconWrapper,
} from "../../../../CustomStyles/searchBar";

// PROPS
// searchTerm - string
// onSearchChange - function
// title - string
export default function ReusableTableToolbar(props) {
  const {
    numSelected,
    title,
    onSearchChange,
    searchTerm,
    onActiveChange,
    active,
    onSiteOwnerChange,
    siteOwner,
    clearFilters,
  } = props;
  const [open, setOpen] = React.useState(false);

  const online = useReactiveVar(onlineVar);
  const { data } = useQuery(GET_COMPANIES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  return (
    <Toolbar
      sx={{
        pl: {
          sm: 2,
        },
        pr: {
          xs: 1,
          sm: 1,
        },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{
          flex: "1 1 100%",
        }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {title ? title : null}
      </Typography>
      <Tooltip title="Filter">
        <IconButton onClick={() => setOpen(true)}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogContent style={{ minHeight: "100px" }}>
          <Grid container>
            <Grid item xs={12} md={4} style={{ padding: "5px" }}>
              <Typography>Show Inactive?</Typography>
              <FormControlLabel
                style={{ color: "black" }}
                control={
                  <CustomSwitch
                    checked={active}
                    onChange={onActiveChange}
                    value={true}
                  />
                }
                label={active ? "YES" : "NO"}
              />
            </Grid>
            <Grid item xs={12} md={4} style={{ padding: "5px" }}>
              <Autocomplete
                id="site-filter-siteowner"
                options={
                  data
                    ? [...data.companies].sort((a, b) =>
                        a.name > b.name ? 1 : -1
                      )
                    : []
                }
                getOptionLabel={(option) => option.name}
                value={siteOwner}
                onChange={onSiteOwnerChange}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Filter by Site Owner"
                    value={params}
                    id={params.id}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} style={{ ...blueButton }}>
            FILTER
          </Button>
          <Button onClick={clearFilters} style={{ ...blackButton }}>
            CLEAR
          </Button>
        </DialogActions>
      </Dialog>
      <Tooltip title="Search">
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{
              "aria-label": "search",
            }}
            value={searchTerm}
            onChange={onSearchChange}
          />
        </Search>
      </Tooltip>
    </Toolbar>
  );
}
