import * as React from "react";
import { useNavigate } from "react-router-dom";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import moment from "moment";

export default function TableBodyTemplate(props) {
  // these props are available in table body
  const { order, orderBy, page, rowsPerPage, getComparator, stableSort, data } =
    props;
  const navigate = useNavigate();

  if (
    stableSort(data, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    ).length === 0
  ) {
    return (
      <TableRow>
        <TableCell style={{ borderBottom: "transparent" }} colSpan={5}>
          No Results Found
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {stableSort(data, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => {
          const d = new Date(row.date);
          const utc = d.getTime() + d.getTimezoneOffset() * 60000;
          const convertedDate = new Date(utc);

          return (
            <TableRow
              hover
              onClick={() => navigate(`/view-incident/${row.id}`)}
              key={row.id}
              style={{ cursor: "pointer" }}
            >
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell>{`${moment(convertedDate).format(
                "MM/DD/YYYY"
              )}`}</TableCell>
              <TableCell>{row.type}</TableCell>
              <TableCell>{row.submitter?.username}</TableCell>
              <TableCell>{row.isCompleted ? "Closed" : "Open"}</TableCell>
              <TableCell>
                {row.ownerSiteProject?.site?.name} [
                {row.ownerSiteProject?.owner?.name}]
              </TableCell>
              <TableCell>
                {row.ha ? `${row.ha.name} [${row.ha.date}]` : ""}
              </TableCell>
            </TableRow>
          );
        })}
    </>
  );
}
