import * as React from "react";

import { useLazyQuery, useReactiveVar } from "@apollo/client";
import moment from "moment";

import { viewHAFiltersVar } from "../../../../graphql/localVariables/ha";
import { onlineVar } from "../../../../graphql/localVariables/user";
import { haMutations } from "../../../../graphql/mutations";
import { GET_HAS } from "../../../../graphql/queries/ha";
import TableMain from "./ReusableTableLayout";
import ReusableTableToolbar from "./ReusableTableToolbar";
import TableBodyTemplate from "./TableBodyTemplate";
import TableHeadTemplate from "./TableHeadTemplate";

// Props
// hasPagination - boolean
// data - array of objects
// 3 children component
//    If using the ReusableTableToolbar
//    - title - string - displayed at the top
//    - onDeleteClick - when trash can on toolbar is clicked
//    - onSearchChange - when input in search bar
//    - searchTerm - string
export default function Table() {
  const online = useReactiveVar(onlineVar);
  const [getHAs, { data, loading, refetch }] = useLazyQuery(GET_HAS, {
    variables: {
      first: 50,
      name: "",
      owner: null,
      site: null,
      afterDate: null,
      beforeDate: null,
      submitterCompany: null,
      user: null,
      projects: null,
      templates: null,
      status: null,
      incident: null,
    },
    fetchPolicy: online ? "network-only" : "cache-only",
    notifyOnNetworkStatusChange: true,
  });
  const { updateAllViewHAFilters } = haMutations;
  const filters = useReactiveVar(viewHAFiltersVar);
  const [initialLoad, setInitialLoad] = React.useState(false);
  const [displayedData, setDisplayedData] = React.useState([]);

  const clearFilters = () => {
    updateAllViewHAFilters({
      site: { owner: { name: "" } },
      projects: [],
      templates: [],
      afterDate: null,
      beforeDate: null,
      status: "",
      incident: "",
      usernames: [],
      company: { name: "" },
    });
  };

  const getInput = () => {
    return {
      name: filters.searchterm ? filters.searchterm : "",
      owner: filters.site.owner?.id ? Number(filters.site.owner.id) : null,
      site: filters.site.site ? Number(filters.site.site.id) : null,
      afterDate: filters.afterDate
        ? moment(filters.afterDate).format("YYYY-MM-DD")
        : null,
      beforeDate: filters.beforeDate
        ? moment(filters.beforeDate).format("YYYY-MM-DD")
        : null,
      submitterCompany:
        filters.company && filters.company.name !== ""
          ? filters.company.id
          : null,
      user:
        filters.usernames && filters.usernames.length > 0
          ? filters.usernames.map((u) => Number(u.id))
          : null,
      projects:
        filters.projects && filters.projects.length > 0
          ? filters.projects.map((p) => Number(p.id))
          : null,
      templates:
        filters.templates && filters.templates.length > 0
          ? filters.templates.map((t) => Number(t.id))
          : null,
      status:
        !filters.status || filters.status === ""
          ? null
          : filters.status === "Closed",
      incident:
        !filters.incident || filters.incident === ""
          ? null
          : filters.incident === "With",
    };
  };

  const onFilter = () => {
    const input = {
      first: 50,
      ...getInput(),
    };

    refetch(input);
  };

  const fetchMore = (obj) => {
    const input = {
      after: obj.after,
      first: obj.first,
      ...getInput(),
    };

    refetch(input);
  };

  React.useEffect(() => {
    getHAs({
      variables: { first: 50, ...getInput() },
      onCompleted() {
        setInitialLoad(true);
      },
    });
  }, []);

  React.useEffect(() => {
    if (filters.searchterm && initialLoad) {
      onFilter();
    }
  }, [filters.searchterm]);

  /*
    Added 10/2/24
    Once data has been received from GQL, we are filtering out
    inactive Ha-OSPs
  */
  React.useEffect(() => {
    if (data === undefined || data === null) return;
    const nodes = data.hazardAnalyses.edges.map((edge) => edge.node);
    const updatedNodes = nodes.map((node) => {
      return {
        ...node,
        haOwnerSiteProject: node.haOwnerSiteProject.filter(
          (haOSP) => haOSP.isActive
        ),
      };
    });

    setDisplayedData(updatedNodes);
  }, [data]);

  return (
    <TableMain
      CustomTableHeader={<TableHeadTemplate />}
      CustomTableBody={<TableBodyTemplate />}
      CustomTableToolBar={
        <ReusableTableToolbar
          clearFilters={clearFilters}
          onFilter={onFilter}
          title={"Hazard Analyses"}
          data={data}
        />
      }
      hasPagination={true}
      allData={data}
      data={displayedData}
      fetchMore={fetchMore}
      loading={loading}
    />
  );
}
