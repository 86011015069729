import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, Tooltip, Grid, Typography } from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import { createHAVar, editHAVar } from "../../../../graphql/localVariables/ha";
import { haMutations } from "../../../../graphql/mutations";
import { getRaScores } from "../../../../utils/raScore";

export default function JobStepView({ type, action, actions, risks, mits }) {
  const editHA = useReactiveVar(editHAVar);
  const createHA = useReactiveVar(createHAVar);
  const updateHA = type === "EDIT" ? editHA : createHA;
  const { updateCreateHA, updateEditHA } = haMutations;
  const updateHAFunction = type === "EDIT" ? updateEditHA : updateCreateHA;

  const moveOrderUp = (action) => {
    if (updateHA.actions.length > 1 && action.actionOrdering > 0) {
      const newActions = updateHA.actions.map((a) => {
        if (a.actionOrdering === action.actionOrdering - 1) {
          return { ...a, actionOrdering: a.actionOrdering + 1 };
        }

        if (a.actionOrdering === action.actionOrdering) {
          return { ...a, actionOrdering: a.actionOrdering - 1 };
        }

        return a;
      });
      updateHAFunction("actions", newActions);
    }
  };

  const moveOrderDown = (action) => {
    if (
      updateHA.actions.length > 1 &&
      action.actionOrdering < updateHA.actions.length - 1
    ) {
      const newActions = updateHA.actions.map((a) => {
        if (a.actionOrdering === action.actionOrdering + 1) {
          return { ...a, actionOrdering: a.actionOrdering - 1 };
        }

        if (a.actionOrdering === action.actionOrdering) {
          return { ...a, actionOrdering: a.actionOrdering + 1 };
        }

        return a;
      });
      updateHAFunction("actions", newActions);
    }
  };

  const deleteAction = (action) => {
    updateHAFunction(
      "actions",
      updateHA.actions.filter((a) => Number(a.a.id) !== Number(action.a.id))
    );
    updateHAFunction(
      "risks",
      updateHA.risks.filter((r) => Number(r.a.id) !== Number(action.a.id))
    );
    updateHAFunction(
      "mitigators",
      updateHA.mitigators.filter((m) => Number(m.a.id) !== Number(action.a.id))
    );
  };

  const [actionScore, setScore] = React.useState(0);

  React.useEffect(() => {
    if (actions && risks && mits) {
      const allArms = [
        ...actions.companyArms,
        ...risks.companyArms,
        ...mits.companyArms,
      ]
        .map((carm) => carm.arm)
        .map((arm) => {
          if (arm.r === null || arm.r.id === null)
            return { ...arm, r: null, m: null };
          if (arm.m === null || arm.m.id === null) return { ...arm, m: null };
          return arm;
        });

      const actionId = Number(action.a.id);
      const formattedInputs = [
        ...updateHA.actions.filter((a) => Number(a.a.id) === actionId),
        ...updateHA.risks.filter((r) => Number(r.a.id) === actionId),
        ...updateHA.mitigators.filter((m) => Number(m.a.id) === actionId),
      ].map((arm) => {
        return {
          id: Number(arm.id),
          a: { id: Number(arm.a.id) },
          r: arm.r && arm.r.id ? { id: Number(arm.r.id) } : null,
          m: arm.m && arm.m.id ? { id: Number(arm.m.id) } : null,
        };
      });

      const score = getRaScores(allArms, formattedInputs);
      setScore(score.toFixed(2));
    }
  }, [
    updateHA.actions,
    updateHA.risks,
    updateHA.mitigators,
    actions,
    risks,
    mits,
  ]);

  return (
    <>
      <Grid item xs={12}>
        <Tooltip title="Up">
          <Button
            sx={{
              width: "100%",
              padding: "0px",
              minWidth: "100%",
            }}
            onClick={() => moveOrderUp(action)}
          >
            <KeyboardArrowUpIcon
              sx={{
                width: "100%",
                fontSize: "100px",
              }}
              color="secondary"
            />
          </Button>
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ textAlign: "center" }}>
          {action.a?.name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ textAlign: "center" }}>{actionScore}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Tooltip title="Delete">
          <Button
            sx={{
              width: "100%",
              padding: "0px",
              minWidth: "100%",
            }}
            onClick={() => deleteAction(action)}
          >
            <CloseIcon
              sx={{
                width: "100%",
                fontSize: "35px",
              }}
            />
          </Button>
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <Tooltip title="Down">
          <Button
            sx={{
              width: "100%",
              padding: "0px",
              minWidth: "100%",
            }}
            onClick={() => moveOrderDown(action)}
          >
            <KeyboardArrowDownIcon
              sx={{
                width: "100%",
                fontSize: "100px",
              }}
              color="secondary"
            />
          </Button>
        </Tooltip>
      </Grid>
    </>
  );
}
