import React from "react";

import { Grid } from "@mui/material";

import Notifications from "./Notifications";
import useStyles from "./styles";

export default function NotificationsRoot() {
  const classes = useStyles()();

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      className={classes.root}
      style={{ height: "100%" }}
    >
      <Grid item xs={12}>
        <Notifications />
      </Grid>
    </Grid>
  );
}
