import { gql } from "@apollo/client";

export const MUTATE_TEMPLATE = gql`
  mutation mutateTemplate($input: TemplateMutationInput!) {
    template(input: $input) {
      template {
        id
      }
      errors {
        messages
      }
    }
  }
`;

export const TEMPLATE_CREATE = gql`
  mutation TEMPLATE_CREATE(
    $name: String!
    $startDate: Date!
    $endDate: Date!
    $unassociatedOSP: [ID]
    $associatedOSP: [ID]
    $unassociatedActions: [ID]
    $associatedActions: [ID]
  ) {
    templateMutation(
      name: $name
      startDate: $startDate
      endDate: $endDate
      unassociatedOSP: $unassociatedOSP
      associatedOSP: $associatedOSP
      unassociatedActions: $unassociatedActions
      associatedActions: $associatedActions
    ) {
      template {
        id
        name
      }
    }
  }
`;

export const TEMPLATE_EDIT = gql`
  mutation TEMPLATE_EDIT(
    $name: String
    $id: ID!
    $isActive: Boolean
    $startDate: Date
    $endDate: Date
    $unassociatedOSP: [ID]
    $associatedOSP: [ID]
    $unassociatedActions: [ID]
    $associatedActions: [ID]
  ) {
    templateMutation(
      name: $name
      Id: $id
      isActive: $isActive
      startDate: $startDate
      endDate: $endDate
      unassociatedOSP: $unassociatedOSP
      associatedOSP: $associatedOSP
      unassociatedActions: $unassociatedActions
      associatedActions: $associatedActions
    ) {
      template {
        id
        name
      }
    }
  }
`;
