import React from "react";

import { Avatar, Grid, Typography } from "@mui/material";

import { defaultAvatar } from "../../../../../CustomStyles/avatar";
import ViewContractor from "../View/Contractors/ViewContractor";
import ViewObservedSite from "../View/ObservedSites/ViewSite";
import EditCrewDialog from "./EditCrewDialog";
import EditProjectDialog from "./EditProjectDialog";
import EditSite from "./EditSiteDialog";
import EditTemplateDialog from "./EditTemplateDialog";
import EditUserDialog from "./EditUserDialog";

function TemplateItem({ t }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Grid
        container
        key={t.id}
        style={{
          padding: "10px",
          borderBottom: "1px solid black",
          cursor: "pointer",
        }}
        onClick={() => setOpen(true)}
      >
        <Typography
          style={{ color: "white" }}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "1",
            WebkitBoxOrient: "vertical",
          }}
        >
          {t.template.name}
        </Typography>
      </Grid>
      <EditTemplateDialog open={open} setOpen={setOpen} data={t} />
    </>
  );
}

export default function EditDialog({ title, id, company, d, extraData }) {
  const [open, setOpen] = React.useState(false);

  const renderChild = () => {
    switch (title) {
      case "Users":
        return (
          <>
            <Grid
              container
              key={d.id}
              direction="row"
              style={{
                padding: "10px",
                borderBottom: "1px solid black",
                cursor: "pointer",
              }}
              onClick={() => setOpen(true)}
            >
              <Grid item xs={1}>
                <Avatar
                  alt={d.username}
                  src={d.image}
                  sx={{ ...defaultAvatar, width: 30, height: 30 }}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  style={{
                    color: "white",
                    paddingLeft: "20px",
                    alignSelf: "center",
                  }}
                >
                  {d.firstName !== "" && d.lastName !== ""
                    ? `${d.firstName} ${d.lastName}`
                    : d.username}
                </Typography>
              </Grid>
            </Grid>
            <EditUserDialog
              open={open}
              setOpen={setOpen}
              title={title}
              id={id}
              company={company}
              d={d}
            />
          </>
        );
      case "Supervisors":
        return (
          <>
            <Grid
              container
              key={d.id}
              direction="row"
              style={{
                padding: "10px",
                borderBottom: "1px solid black",
                cursor: "pointer",
              }}
              onClick={() => setOpen(true)}
            >
              <Grid item xs={1}>
                <Avatar
                  alt={d.username}
                  src={d.image}
                  sx={{ ...defaultAvatar, width: 30, height: 30 }}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  style={{
                    color: "white",
                    paddingLeft: "20px",
                    alignSelf: "center",
                  }}
                >
                  {d.firstName !== "" && d.lastName !== ""
                    ? `${d.firstName} ${d.lastName}`
                    : d.username}
                </Typography>
              </Grid>
            </Grid>
            <EditUserDialog
              open={open}
              setOpen={setOpen}
              title={title}
              id={id}
              company={company}
              d={d}
            />
          </>
        );
      case "Owned Sites":
        return (
          <>
            <Grid
              container
              key={d.id}
              style={{
                padding: "10px",
                borderBottom: "1px solid black",
                cursor: "pointer",
              }}
              onClick={() => setOpen(true)}
            >
              <Typography style={{ color: "white" }}>{d.site.name}</Typography>
            </Grid>
            <EditSite
              open={open}
              setOpen={setOpen}
              title={title}
              id={id}
              company={company}
              data={d}
              siteData={extraData}
            />
          </>
        );
      case "Observed Sites":
        return (
          <>
            <Grid
              container
              key={d.id}
              style={{
                padding: "10px",
                borderBottom: "1px solid black",
                cursor: "pointer",
              }}
              onClick={() => setOpen(true)}
            >
              <Typography style={{ color: "white" }}>
                {d.ownerSiteProject.site?.name} [
                {d.ownerSiteProject.owner?.name}]
              </Typography>
            </Grid>
            <ViewObservedSite
              open={open}
              setOpen={setOpen}
              title={title}
              id={id}
              company={company}
              data={d}
            />
          </>
        );
      case "Contractors":
        return (
          <>
            <Grid
              container
              key={d.id}
              style={{
                padding: "10px",
                borderBottom: "1px solid black",
                cursor: "pointer",
              }}
              onClick={() => setOpen(true)}
            >
              <Typography style={{ color: "white" }}>
                {d.contractor.name}
              </Typography>
            </Grid>
            <ViewContractor
              open={open}
              setOpen={setOpen}
              title={title}
              id={id}
              company={company}
              data={d}
              extraData={extraData}
            />
          </>
        );
      case "Projects":
        return (
          <>
            <Grid
              container
              key={d.id}
              style={{
                padding: "10px",
                borderBottom: "1px solid black",
                cursor: "pointer",
              }}
              onClick={() => setOpen(true)}
            >
              <Typography style={{ color: "white" }}>
                {d.project.name}
              </Typography>
            </Grid>
            <EditProjectDialog
              open={open}
              setOpen={setOpen}
              title={title}
              id={id}
              company={company}
              data={d}
            />
          </>
        );
      case "Templates":
        return (
          <TemplateItem
            key={id}
            t={d}
            id={id}
            title={title}
            company={company}
          />
        );
      case "CrewMembers":
        return (
          <>
            <Grid
              container
              key={d.id}
              style={{
                padding: "10px",
                borderBottom: "1px solid black",
                cursor: "pointer",
              }}
              onClick={() => setOpen(true)}
            >
              <Typography
                style={{ color: "white" }}
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {d.firstName} {d.lastName}
              </Typography>
            </Grid>
            <EditCrewDialog
              open={open}
              setOpen={setOpen}
              id={id}
              company={company}
              d={d}
            />
          </>
        );
      default:
        return null;
    }
  };

  return <>{company && renderChild()}</>;
}
