import * as React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { useReactiveVar } from "@apollo/client";

import {
  createIncidentVar,
  editIncidentVar,
} from "../../../../graphql/localVariables/investigation";
import { investigationMutations } from "../../../../graphql/mutations";

export default function Employee({ type }) {
  // Global Variables
  const editObs = useReactiveVar(editIncidentVar);
  const createObs = useReactiveVar(createIncidentVar);
  const form = type === "EDIT" ? editObs : createObs;

  // Mutations
  const { updateKeyEditIncident, updateKeyCreateIncident } =
    investigationMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditIncident : updateKeyCreateIncident;

  const handleAutocomplete = (event, value) => {
    updateState(value);
  };

  const handleTextInput = (event) => {
    updateState(event.target.value);
  };

  const updateState = (value) => {
    updateObsKeyFunction(
      "employeeName",
      value.firstName ? `${value.firstName} ${value.lastName}` : value
    );
    updateObsKeyFunction(
      "employeeJobTitle",
      value.jobTitle ? value.jobTitle : ""
    );
    updateObsKeyFunction(
      "employeePhoneNumber",
      value.phoneNumber ? value.phoneNumber : ""
    );
  };

  return (
    <Autocomplete
      id="select-employee"
      value={form.employeeName}
      freeSolo
      fullWidth
      onChange={handleAutocomplete}
      isOptionEqualToValue={(option, value) =>
        `${option.firstName} ${option.lastName}` === value
      }
      options={form.crewMembers}
      getOptionLabel={(option) =>
        option.firstName ? `${option.firstName} ${option.lastName}` : option
      }
      renderOption={(props, option) => {
        return (
          <li {...props} key={`${option.firstName}-${option.id}`}>
            {option.firstName} {option.lastName}
          </li>
        );
      }}
      disableClearable
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Involved Employee"
          onChange={handleTextInput}
        />
      )}
    />
  );
}
