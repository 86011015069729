import React from "react";

import CheckIcon from "@mui/icons-material/Check";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";

import { onErrorFunc } from "./OnErrorFunction";
import SearchField from "./SearchField";
import useStyles from "./styles";

const disabledMessage = () => {
  onErrorFunc(
    "You do not have permissions to perform this action. Talk to your administrator."
  );
};

function UnselectedItem({ item, idKey, select, renderDisplay, disabled }) {
  const classes = useStyles();

  return (
    <Grid
      onClick={() => {
        if (!disabled) select(item);
        else disabledMessage();
      }}
      key={`${idKey}-${item.id}`}
    >
      <Grid
        container
        justifyContent="space-between"
        className={classes.selectUnselectItem}
        style={{ padding: "10px" }}
      >
        <Grid item xs={2}>
          <CheckBoxOutlineBlankIcon
            style={{
              fontSize: "24px",
              cursor: "pointer",
            }}
            color="secondary"
          />
        </Grid>
        <Grid item xs={10}>
          <Typography>{renderDisplay(item, idKey)}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

function SelectedItem({ item, idKey, unselect, renderDisplay, disabled }) {
  return (
    <Grid
      onClick={() => {
        if (!disabled) unselect(item);
        else disabledMessage();
      }}
      key={`${idKey}-${item.id}`}
    >
      <Grid
        container
        justifyContent="space-between"
        style={{ padding: "10px" }}
      >
        <Grid item xs={2}>
          <CheckIcon
            style={{
              color: "#FDBC02",
              fontSize: "24px",
              cursor: "pointer",
            }}
          />
        </Grid>
        <Grid item xs={10}>
          <Typography>{renderDisplay(item, idKey)}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default function SelectedUnselected({
  selected,
  unselected,
  loadingUnselected,
  setSelected,
  setUnselected,
  updateState,
  idKey,
  label,
  renderDisplay,
  searchItem,
  getUniqueId,
  disabled,
  sortFunction,
}) {
  const classes = useStyles();
  const [search, setSearch] = React.useState("");

  const sortedSelected = sortFunction ? sortFunction(selected) : selected;

  const mobileSelected = sortedSelected.map((u) => ({
    ...u,
    selected: true,
  }));

  const sortedUnselected = sortFunction ? sortFunction(unselected) : unselected;

  const mobileUnSelected = sortedUnselected.map((u) => ({
    ...u,
    selected: false,
  }));

  React.useEffect(() => {
    setSearch("");
  }, [label]);

  React.useEffect(() => {}, [selected, unselected]);

  const select = (item) => {
    // setUnselected where not updating fast enough for function
    const stateSelected = [...selected, item];
    const oldStateUnselected = [...unselected];
    const newState = oldStateUnselected.filter((prevItems) => {
      if (getUniqueId) {
        return getUniqueId(prevItems) !== getUniqueId(item);
      } else {
        return prevItems.id !== item.id;
      }
    });
    updateState(stateSelected, newState);
    setSelected(stateSelected);
    setUnselected(newState);
  };

  const unselect = (item) => {
    // setUnselected where not updating fast enough for function
    const stateUnselected = [...unselected, item];
    const oldStateSelected = [...selected];
    const newState = oldStateSelected.filter((prevItems) => {
      if (getUniqueId) {
        return getUniqueId(prevItems) !== getUniqueId(item);
      } else {
        return prevItems.id !== item.id;
      }
    });

    updateState(newState, stateUnselected);
    setSelected(newState);
    setUnselected(stateUnselected);
  };

  return (
    <div>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography style={{ fontSize: "0.8rem" }}>
              Unassociated {label}:
            </Typography>
            <div style={{ margin: "10px 0px" }}>
              <SearchField value={search} setValue={setSearch} />
            </div>
            <div
              style={{
                border: "1px solid #8297a0",
                borderRadius: "4px",
                maxHeight: "200px",
                overflowY: "auto",
                backgroundColor: "white",
                color: "black",
              }}
            >
              {unselected.length === 0 && !loadingUnselected ? (
                <Grid
                  container
                  justifyContent="space-between"
                  className={classes.selectUnselectItem}
                  style={{ padding: "10px" }}
                >
                  <Grid item xs={10}>
                    <Typography style={{ maxWidth: "90%" }}>
                      None unselected.
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
              {loadingUnselected ? (
                <Grid container>
                  <CircularProgress color="secondary" />
                </Grid>
              ) : null}
              {sortedUnselected
                .filter((u) => searchItem(u, search))
                .map((item) => {
                  return (
                    <Grid
                      onClick={() => {
                        if (!disabled) select(item);
                        else disabledMessage();
                      }}
                      key={
                        getUniqueId
                          ? `${idKey}-${getUniqueId(item)}`
                          : `${idKey}-${item.id}`
                      }
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        alignContent="center"
                        className={classes.selectUnselectItem}
                        style={{ padding: "10px" }}
                      >
                        <Grid item xs={2}>
                          <CheckBoxOutlineBlankIcon
                            style={{
                              fontSize: "24px",
                              cursor: "pointer",
                            }}
                            color="secondary"
                          />
                        </Grid>
                        <Grid item xs={10}>
                          {renderDisplay(item, idKey)}
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
            </div>
          </Grid>
          <Grid item xs={6}>
            <Typography style={{ fontSize: "0.8rem" }}>
              Associated {label}:
            </Typography>
            <div
              style={{
                border: "1px solid #8297a0",
                borderRadius: "4px",
                maxHeight: "200px",
                overflowY: "auto",
                backgroundColor: "white",
                color: "black",
              }}
            >
              {selected.length === 0 && (
                <Grid
                  container
                  justifyContent="space-between"
                  className={classes.selectUnselectItem}
                  style={{ padding: "10px" }}
                >
                  <Grid item xs={10}>
                    <Typography style={{ maxWidth: "90%" }}>
                      None selected.
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {sortedSelected.map((item) => {
                return (
                  <Grid
                    onClick={() => {
                      if (!disabled) unselect(item);
                      else disabledMessage();
                    }}
                    key={
                      getUniqueId
                        ? `${idKey}-${getUniqueId(item)}`
                        : `${idKey}-${item.id}`
                    }
                  >
                    <Grid
                      container
                      alignContent="center"
                      justifyContent="space-between"
                      style={{ padding: "10px" }}
                    >
                      <Grid item xs={2}>
                        <CheckIcon
                          style={{
                            color: "#FDBC02",
                            fontSize: "24px",
                            cursor: "pointer",
                          }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        {renderDisplay(item, idKey)}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </div>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography style={{ fontSize: "0.8rem" }}>{label}:</Typography>
            <div
              style={{
                border: "1px solid #8297a0",
                borderRadius: "4px",
                maxHeight: "200px",
                overflowY: "auto",
                backgroundColor: "white",
                color: "black",
              }}
            >
              {[...mobileSelected, ...mobileUnSelected]
                // .sort((a, b) => (a[idKey] > b[idKey] ? 1 : -1))
                .map((item) => {
                  if (!item.selected) {
                    return (
                      <UnselectedItem
                        key={
                          getUniqueId
                            ? `${idKey}-${getUniqueId(item)}`
                            : `${idKey}-${item.id}`
                        }
                        item={item}
                        idKey={idKey}
                        select={select}
                        renderDisplay={renderDisplay}
                        disabled={disabled}
                      />
                    );
                  } else
                    return (
                      <SelectedItem
                        key={
                          getUniqueId
                            ? `${idKey}-${getUniqueId(item)}`
                            : `${idKey}-${item.id}`
                        }
                        item={item}
                        idKey={idKey}
                        unselect={unselect}
                        renderDisplay={renderDisplay}
                        disabled={disabled}
                      />
                    );
                })}
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
