import React from "react";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import { CustomDialog } from "../../CustomStyles/dialog";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";

export default function RiskNotify({ open, handleClose }) {
  return (
    <CustomDialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle style={{ ...pageTitleStyles }}>ATTENTION!</DialogTitle>
      <DialogContent>
        <Typography>
          The observation you are working on exceeds the risk threshold set by
          your company.
        </Typography>
        <Typography>
          Please PAUSE what you are doing and wait to hear from your supervisor
          before taking any further action. They will be contacting you shortly.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          style={{ marginRight: "10px" }}
          onClick={handleClose}
        >
          CLOSE
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}
