import React from "react";

import { Grid } from "@mui/material";

import { useQuery, useReactiveVar } from "@apollo/client";
import moment from "moment";

import { onlineVar } from "../../../../../graphql/localVariables/user";
import {
  GET_RECENT_HAS,
  GET_RECENT_OBSERVATIONS,
} from "../../../../../graphql/queries";
import { GET_AUDIT_SUBMISSIONS_DASHBOARD } from "../../../../../graphql/queries/audits";
import RecentHAWrapper from "../../../RecentHA";
import RecentObsWrapper from "../../../RecentObs";
import Dashboard from "./Dashboard";
import useStyles from "./styles";

export default function DashboardMonitor({ permissions }) {
  const dashboardHeight = 30;
  const classes = useStyles(dashboardHeight)();
  const [obsScore, setObsScore] = React.useState(0);
  const [auditScore, setAuditScore] = React.useState(0);
  const [haScore, setHaScore] = React.useState(0);

  const lastWeek = moment(new Date()).subtract(7, "days").format("YYYY-MM-DD");
  const yesterdayDate = moment(new Date())
    .subtract(1, "days")
    .format("YYYY-MM-DD");

  const online = useReactiveVar(onlineVar);
  const { data: obs } = useQuery(GET_RECENT_OBSERVATIONS, {
    variables: { after_date: lastWeek, first: 100 },
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const { data: ha } = useQuery(GET_RECENT_HAS, {
    variables: { after_date: yesterdayDate },
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const { data: audits } = useQuery(GET_AUDIT_SUBMISSIONS_DASHBOARD, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  React.useEffect(() => {
    if (obs && obs.observations.edges && obs.observations.edges.length > 0) {
      setObsScore(obs.observations.edges[0].node.raScoreMax);
    }
  }, [obs]);

  React.useEffect(() => {
    if (ha && ha.hazardAnalyses.edges && ha.hazardAnalyses.edges.length > 0) {
      setHaScore(ha.hazardAnalyses.edges[0].node.raScore);
    }
  }, [ha]);

  React.useEffect(() => {
    if (audits && audits.auditSubmissions.length > 0) {
      setAuditScore([...audits.auditSubmissions][0].penalty);
    }
  }, [audits]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      className={classes.root}
      style={{ height: "100%" }}
    >
      <Grid item xs={12}>
        <Dashboard
          auditScore={auditScore}
          observationScore={obsScore}
          jsaScore={haScore}
          permissions={permissions}
        />
      </Grid>
      <Grid item xs={6}>
        <RecentHAWrapper />
      </Grid>
      <Grid item xs={6} style={{ padding: "0px 10px" }}>
        <RecentObsWrapper />
      </Grid>
    </Grid>
  );
}
