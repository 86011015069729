import * as React from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { useQuery } from "@apollo/client";
import moment from "moment";

import { GET_PROJECT_DATA_COMPANY } from "../../../../../../../../graphql/queries/admin/company";
import EditProjectDialog from "../../../Edit/EditProjectDialog";

function Row({ row }) {
  const { data } = useQuery(GET_PROJECT_DATA_COMPANY, {
    variables: { projectId: Number(row.project.id) },
  });
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow
        hover
        onClick={() => setOpen(true)}
        key={row.id}
        style={{ cursor: "pointer" }}
      >
        <TableCell component="th" scope="row">
          {row.project.name}
        </TableCell>
        <TableCell>
          {data &&
            moment(new Date(data.project.timeCreated)).format(
              "MM/DD/YYYY HH:mm"
            )}
        </TableCell>
        <TableCell>{data && data.project.ownerSiteProjects.length}</TableCell>
        <TableCell>${data && data.project.dollarAmount}</TableCell>
      </TableRow>
      <EditProjectDialog open={open} setOpen={setOpen} data={row} />
    </>
  );
}

export default function TableBodyTemplate(props) {
  // these props are available in table body
  const { order, orderBy, page, rowsPerPage, getComparator, stableSort, data } =
    props;

  if (
    stableSort(data, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    ).length === 0
  ) {
    return (
      <TableRow>
        <TableCell style={{ borderBottom: "transparent" }} colSpan={5}>
          No Results Found
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {stableSort(data, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => (
          <Row key={row.id} row={row} />
        ))}
    </>
  );
}
