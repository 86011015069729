import React from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, TextField } from "@mui/material";

import { useQuery } from "@apollo/client";

import { GET_USERS } from "../../../../graphql/queries/admin/company";

export default function Observer({
  user,
  setUser,
  company,
  disabled,
  clearOSP,
}) {
  // Queries
  const { data, loading } = useQuery(GET_USERS);

  const handleChange = (event, newValue) => {
    clearOSP();
    setUser(newValue || []);
  };

  const getOptions = () => {
    if (data) {
      if (company) {
        return [
          ...data.users.filter((u) =>
            u.company ? u.company.id == company.id : false
          ),
        ].sort((a, b) => (a.username > b.username ? 1 : -1));
      }
      return [...data.users].sort((a, b) => (a.username > b.username ? 1 : -1));
    } else return [];
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <Autocomplete
      id="select-observer"
      value={user || []}
      options={getOptions()}
      multiple
      disabled={disabled}
      getOptionLabel={(option) =>
        option.username === ""
          ? ""
          : `${option.username} [${option.company?.name}]`
      }
      onChange={handleChange}
      loading={loading}
      disableCloseOnSelect
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={`${option.id}`}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {`${option.username} [${option.company?.name}]`}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label="Observer(s):" />
      )}
    />
  );
}
