import * as React from "react";
import { Virtuoso } from "react-virtuoso";

import { Check, Clear } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";

import { CustomDialog } from "../../../CustomStyles/dialog";
import ActionPage from "../Action/Action";
import CategoryPage from "../Category/Category";
import Mitigator from "../Mitigator/Mitigator";
import Risk from "../Risk/Risk";
import ReusableTableToolbar from "./ReusableTableToolbar";
import { filterData } from "./searchFilterFunction";

function RowItem({ row, type, activeARMs, loadingActiveARMs, company }) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const getName = () => {
    switch (type) {
      case "Categories":
        return row.name;
      case "Actions":
        return row.arm.a.name;
      case "Risks":
      case "Mitigators":
        return row.name;
    }
  };

  const getIsActive = () => {
    switch (type) {
      case "Categories":
        return row.isActive;
      case "Actions":
        return row.isActive;
      case "Risks":
      case "Mitigators":
        return row.isActive;
    }
  };

  const getDialogContent = () => {
    switch (type) {
      case "Categories":
        return (
          <CategoryPage id={row.id} open={open} handleClose={handleClose} />
        );
      case "Actions":
        return (
          <ActionPage
            action={row}
            open={open}
            handleClose={handleClose}
            loadingActiveARMs={loadingActiveARMs}
            risks={activeARMs && activeARMs.risks ? activeARMs.risks : []}
            mitigators={
              activeARMs && activeARMs.mitigators ? activeARMs.mitigators : []
            }
            company={company}
          />
        );
      case "Risks":
        return <Risk risk={row} open={open} handleClose={handleClose} />;
      case "Mitigators":
        return (
          <Mitigator mitigator={row} open={open} handleClose={handleClose} />
        );
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Grid
        container
        alignContent="center"
        onClick={() => setOpen(true)}
        key={row.id}
        style={{
          cursor: "pointer",
          borderBottom: "1px solid lightgrey",
          padding: 10,
        }}
      >
        <Grid item xs={11}>
          {getName()}
        </Grid>
        <Grid item xs={1}>
          {getIsActive() ? <Check color="yellow1" /> : <Clear color="grey1" />}
        </Grid>
      </Grid>
      <CustomDialog open={open} fullScreen onClose={handleClose}>
        {getDialogContent()}
      </CustomDialog>
    </div>
  );
}

export default function Table({
  data,
  type,
  activeARMs,
  loadingActiveARMs,
  company,
}) {
  const onSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const onInactiveChange = (newValue) => {
    setInactiveFilter(newValue);
  };

  const clearFilters = () => {
    setInactiveFilter(true);
  };

  const [searchTerm, setSearchTerm] = React.useState("");
  const [inactive, setInactiveFilter] = React.useState(true);

  const [filteredData, setFilteredData] = React.useState([]);

  // useEffect for filter update
  React.useEffect(() => {
    setFilteredData(
      filterData(
        [...data].sort((a, b) => {
          let aItem, bItem;

          switch (type) {
            case "Categories":
              aItem = a.name;
              bItem = b.name;
              break;
            case "Actions":
              aItem = a.arm.a.name;
              bItem = b.arm.a.name;
              break;
            // GLOBAL
            case "Risks":
            case "Mitigators":
              aItem = a.name;
              bItem = b.name;
              break;
          }

          return aItem > bItem ? 1 : -1;
        }),
        type,
        searchTerm,
        inactive
      )
    );
  }, [data, searchTerm, inactive]);

  const getRow = (item) => (
    <RowItem
      row={item}
      type={type}
      activeARMs={activeARMs}
      loadingActiveARMs={loadingActiveARMs}
      company={company}
    />
  );

  return (
    <div
      style={{
        width: "100%",
        border: "1px solid black",
        borderRadius: "10px",
        padding: "20px",
      }}
    >
      <ReusableTableToolbar
        searchTerm={searchTerm}
        onSearchChange={onSearchChange}
        inactive={inactive}
        onInactiveChange={onInactiveChange}
        clearFilters={clearFilters}
        title={type}
      />
      <Grid
        container
        style={{ borderBottom: "1px solid lightgrey", padding: 10 }}
      >
        <Grid item xs={11}>
          <Typography>Name</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography>Is Active?</Typography>
        </Grid>
      </Grid>
      <Virtuoso
        style={{ height: 450 }}
        data={filteredData}
        itemContent={(index, item) => getRow(item)}
      />
      <Typography style={{ marginTop: "10px" }}>
        Total {type}: {filteredData.length}
      </Typography>
    </div>
  );
}
