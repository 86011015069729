import React from "react";

import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { IconButton, useTheme } from "@mui/material";

import { DropzoneDialog } from "mui-file-dropzone";

export default function UploadDialog({
  handleFilesChange,
  fileObjects,
  questionId,
}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (files) => {
    setOpen(false);
    // updating question state
    const arr = fileObjects ? fileObjects : [];
    const filesArr = files.map((f) => ({ image: f, questionId }));
    handleFilesChange([...arr, ...filesArr]);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div style={{ display: "contents" }}>
      <IconButton onClick={handleOpen}>
        <CameraAltIcon
          style={{
            color:
              fileObjects && fileObjects.length > 0
                ? theme.palette.yellow0.main
                : "inherit",
          }}
        />
      </IconButton>
      <DropzoneDialog
        open={open}
        onSave={handleSave}
        acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
        showPreviews={true}
        onClose={handleClose}
        maxFileSize={10000000} // 10MB
      />
    </div>
  );
}
