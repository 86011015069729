import React from "react";
import { useNavigate } from "react-router-dom";

import { BarChart, GppGood, RemoveRedEye, Settings } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar,
  Toolbar,
  Drawer,
  IconButton,
  Box,
  Grid,
  Popover,
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Menu,
  MenuItem,
} from "@mui/material";
import { withTheme } from "@mui/styles";

import { useLazyQuery, useReactiveVar } from "@apollo/client";

import { onlineVar } from "../../graphql/localVariables/user";
import { GET_OBSERVATION_USERS } from "../../graphql/queries";
import logo from "../../images/NIXN_Logo.svg";
import Notifications from "../Dashboard/DesktopDashboard/DashboardMonitorGauge/Notifications/Notifications";
import DrawerItems from "./DrawerItems";

function MobileNav({
  theme,
  permissions,
  isSuperuser,
  isStaff,
  isMobile,
  user,
}) {
  const navigate = useNavigate();
  const online = useReactiveVar(onlineVar);
  const [getUsers, { data }] = useLazyQuery(GET_OBSERVATION_USERS, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openNotifications = Boolean(anchorEl);

  const [notifications, setNotifications] = React.useState([]);
  const [value, setValue] = React.useState(null);

  React.useEffect(() => {
    // Get initial value based on URL
    // if (window.location.pathname === "") {
    //   setValue(0);
    // }
    if (window.location.pathname.includes("obs")) {
      setValue(0);
    }
    if (window.location.pathname.includes("audit")) {
      setValue(1);
    }
    if (window.location.pathname.includes("ha")) {
      setValue(2);
    }
    if (window.location.pathname.includes("incident")) {
      setValue(3);
    }
    if (window.location.pathname.includes("user-settings")) {
      setValue(4);
    }
  }, []);

  React.useEffect(() => {
    if (user && isMobile) {
      getUsers({ variables: { id: Number(user.currentUser.id) } });
    }
  }, [user]);

  React.useEffect(() => {
    if (data) {
      setNotifications(
        data.observationUsers.filter(
          (oUser) => oUser.notification && !oUser.notified
        )
      );
    }
  }, [data]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  const [anchorElObs, setAnchorElObs] = React.useState(null);
  const openObs = Boolean(anchorElObs);

  const handleClickObs = (event) => {
    setAnchorElObs(event.currentTarget);
  };

  const [anchorElAudit, setAnchorElAudit] = React.useState(null);
  const openAudit = Boolean(anchorElAudit);

  const handleClickAudit = (event) => {
    setAnchorElAudit(event.currentTarget);
  };

  const [anchorElHA, setAnchorElHA] = React.useState(null);
  const openHA = Boolean(anchorElHA);

  const handleClickHA = (event) => {
    setAnchorElHA(event.currentTarget);
  };

  const [anchorElIncident, setAnchorElIncident] = React.useState(null);
  const openIncident = Boolean(anchorElIncident);

  const handleClickIncident = (event) => {
    setAnchorElIncident(event.currentTarget);
  };

  return (
    <>
      <AppBar position="fixed" style={{ backgroundColor: "black" }}>
        <Toolbar>
          <Grid container alignItems="center" style={{ height: "56px" }}>
            <Grid item xs={11} container justifyContent="center">
              <img
                src={logo}
                style={{ height: 50, cursor: "pointer" }}
                onClick={() => {
                  navigate("/");
                }}
                alt="NIXN logo"
              />
            </Grid>
            <Grid
              item
              xs={1}
              container
              alignItems="center"
              style={{ height: "100%" }}
            >
              <IconButton
                edge="start"
                color="inherit"
                sx={{ display: { xs: "block", md: "none" } }}
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                <Badge
                  badgeContent={notifications.length}
                  color="secondary"
                  max={100}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <NotificationsIcon
                    style={{
                      color:
                        notifications.length > 0
                          ? theme.palette.yellow0.main
                          : "white",
                    }}
                  />
                </Badge>
              </IconButton>
            </Grid>
          </Grid>

          <Drawer
            anchor="left"
            variant="temporary"
            open={open}
            onClose={toggleDrawer(false)}
            PaperProps={{
              sx: {
                width: "75%",
                backgroundColor: theme.palette.black0.main,
              },
            }}
          >
            <Box
              sx={{
                p: 2,
                height: 1,
                backgroundColor: "white",
              }}
            >
              <DrawerItems
                isSuperuser={isSuperuser}
                isStaff={isStaff}
                permissions={permissions}
                user={user}
              />
            </Box>
          </Drawer>
        </Toolbar>
        <Popover
          open={openNotifications}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Grid
            container
            style={{
              width: "80vw",
              maxHeight: "50vh",
              overflowY: "auto",
              backgroundColor: theme.palette.black1.main,
            }}
          >
            <IconButton onClick={() => setAnchorEl(null)}>
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
            <Notifications mobile />
          </Grid>
        </Popover>
      </AppBar>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 100,
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            switch (newValue) {
              // case 0:
              //   navigate("/");
              //   break;
              case 4:
                navigate("/user-settings");
                break;
              default:
                return;
            }
          }}
          sx={{ backgroundColor: "black", height: "70px" }}
        >
          {/* <BottomNavigationAction
            style={{ color: "white" }}
            label="Home"
            icon={<Home />}
            sx={{ padding: "5px" }}
          /> */}
          {permissions.includes("OBSERVATION") && (
            <BottomNavigationAction
              style={{ color: "white" }}
              label="Obs"
              icon={<RemoveRedEye />}
              onClick={handleClickObs}
              sx={{ padding: "5px 4px 5px 10px" }}
            />
          )}
          {permissions.includes("AUDIT") && (
            <BottomNavigationAction
              style={{ color: "white" }}
              label="Audits"
              icon={<GppGood />}
              onClick={handleClickAudit}
              sx={{ padding: "5px 4px" }}
            />
          )}
          {permissions.includes("HA") && (
            <BottomNavigationAction
              style={{ color: "white" }}
              label="HAs"
              icon={<BarChart />}
              onClick={handleClickHA}
              sx={{ padding: "5px 4px" }}
            />
          )}
          {permissions.includes("INCIDENT_REPORT") && (
            <BottomNavigationAction
              style={{ color: "white" }}
              label="Incident"
              icon={<SearchIcon />}
              onClick={handleClickIncident}
              sx={{ padding: "5px 4px" }}
            />
          )}
          <BottomNavigationAction
            style={{ color: "white" }}
            label="Settings"
            icon={<Settings />}
            sx={{ padding: "5px 10px 5px 4px" }}
          />
        </BottomNavigation>
        <Menu
          anchorEl={anchorElObs}
          open={openObs}
          onClose={() => setAnchorElObs(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={() => navigate("/create-obs")}>CREATE</MenuItem>
          <MenuItem onClick={() => navigate("/view-obs")}>VIEW</MenuItem>
        </Menu>
        <Menu
          anchorEl={anchorElAudit}
          open={openAudit}
          onClose={() => setAnchorElAudit(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={() => navigate("/create-audit")}>CREATE</MenuItem>
          <MenuItem onClick={() => navigate("/view-audit")}>VIEW</MenuItem>
        </Menu>{" "}
        <Menu
          anchorEl={anchorElHA}
          open={openHA}
          onClose={() => setAnchorElHA(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={() => navigate("/create-ha")}>CREATE</MenuItem>
          <MenuItem onClick={() => navigate("/view-ha")}>VIEW</MenuItem>
        </Menu>
        <Menu
          anchorEl={anchorElIncident}
          open={openIncident}
          onClose={() => setAnchorElIncident(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={() => navigate("/create-incident")}>
            CREATE
          </MenuItem>
          <MenuItem onClick={() => navigate("/view-incident")}>VIEW</MenuItem>
        </Menu>
      </Paper>
    </>
  );
}

export default withTheme(MobileNav);
