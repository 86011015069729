import React from "react";

import ViewContractors from "./Contractors/ViewContractors";
import ViewCrewmembers from "./Crewmembers/ViewCrewmembers";
import ViewObservedSites from "./ObservedSites/ViewObservedSites";
import ViewOwnedSites from "./OwnedSites/ViewOwnedSites";
import ViewProjects from "./Projects/ViewProjects";
import ViewSupervisors from "./Supervisors/ViewSupervisors";
import ViewTemplates from "./Templates/ViewTemplates";
import ViewUsers from "./Users/ViewUsers";

export default function ViewDialog({
  title,
  data,
  company,
  open,
  setOpen,
  extraData,
}) {
  React.useEffect(() => {}, [company]);

  const renderChild = () => {
    switch (title) {
      case "Users":
        return (
          <ViewUsers
            open={open}
            setOpen={setOpen}
            title={title}
            data={data}
            company={company}
            extraData={extraData}
          />
        );
      case "Owned Sites":
        return (
          <ViewOwnedSites
            open={open}
            setOpen={setOpen}
            title={title}
            data={data}
            company={company}
            extraData={extraData}
          />
        );
      case "Observed Sites":
        return (
          <ViewObservedSites
            open={open}
            setOpen={setOpen}
            title={title}
            data={data}
            company={company}
            extraData={extraData}
          />
        );
      case "Projects":
        return (
          <ViewProjects
            open={open}
            setOpen={setOpen}
            title={title}
            data={data}
            company={company}
            extraData={extraData}
          />
        );
      case "Contractors":
        return (
          <ViewContractors
            open={open}
            setOpen={setOpen}
            title={title}
            data={data}
            company={company}
            extraData={extraData}
          />
        );
      case "CrewMembers":
        return (
          <ViewCrewmembers
            open={open}
            setOpen={setOpen}
            title={title}
            data={data}
            company={company}
            extraData={extraData}
          />
        );
      case "Templates":
        return (
          <ViewTemplates
            open={open}
            setOpen={setOpen}
            title={title}
            data={data}
            company={company}
            extraData={extraData}
          />
        );
      case "Supervisors":
        return (
          <ViewSupervisors
            open={open}
            setOpen={setOpen}
            title={title}
            data={data}
            company={company}
            extraData={extraData}
          />
        );
      default:
        return null;
    }
  };

  return <>{company && renderChild()}</>;
}
