import * as React from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Autocomplete,
  TextField,
  Grid,
  Button,
  Checkbox,
  Tooltip,
  IconButton,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import { useQuery, useReactiveVar } from "@apollo/client";

import { onlineVar } from "../../../../../../../graphql/localVariables/user";
import { GET_SITES } from "../../../../../../../graphql/queries";
import {
  GET_ALL_PROJECTS,
  GET_SEARCH_TEMPLATES,
} from "../../../../../../../graphql/queries/ha";
import {
  blueButton,
  blackButton,
} from "../../../../../../CustomStyles/buttons";

// PROPS
// searchTerm - string
// onSearchChange - function
// title - string
export default function ReusableTableToolbar(props) {
  const online = useReactiveVar(onlineVar);
  const { data: sites } = useQuery(GET_SITES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const { data: projects } = useQuery(GET_ALL_PROJECTS, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const { data: templates } = useQuery(GET_SEARCH_TEMPLATES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const {
    numSelected,
    title,
    onSiteChange,
    site,
    onAfterDateChange,
    afterDate,
    onBeforeDateChange,
    beforeDate,
    projectFilter,
    onProjectChange,
    templateFilter,
    onTemplateChange,
    status,
    onStatusChange,
    incident,
    onIncidentChange,
    clearFilters,
  } = props;
  const [open, setOpen] = React.useState(false);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const filterProjects = () => {
    if (site.owner.name !== "") {
      return projects && projects.projects
        ? projects &&
            projects.projects.filter(
              (p) =>
                p.ownerSiteProject.findIndex(
                  (osp) => osp.owner.id === site.ownerId
                ) >= 0 &&
                p.ownerSiteProject.findIndex(
                  (osp) => osp.site.id === site.id
                ) >= 0
            )
        : [];
    }
    return projects && projects.projects ? projects.projects : [];
  };

  return (
    <Toolbar
      sx={{
        pl: {
          sm: 2,
        },
        pr: {
          xs: 1,
          sm: 1,
        },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{
          flex: "1 1 100%",
        }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {title ? title : null}
      </Typography>
      <Tooltip title="Filter">
        <IconButton onClick={() => setOpen(true)}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <Grid container>
            <Grid item xs={12} md={4} style={{ padding: "5px" }}>
              <Autocomplete
                id="ha-filter-site"
                options={
                  sites && sites.ownerSiteProjects
                    ? [...sites.ownerSiteProjects].sort((a, b) =>
                        a.site.name > b.site.name ? 1 : -1
                      )
                    : []
                }
                getOptionLabel={(option) =>
                  option.site
                    ? `${option.site?.name} [${option.owner?.name}]`
                    : option.owner?.name
                }
                value={site}
                onChange={onSiteChange}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.site
                        ? `${option.site?.name} [${option.owner?.name}]`
                        : option.owner?.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Filter by Site [Site Owner]"
                    value={params}
                    id={params.id}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} style={{ padding: "5px" }}>
              <Autocomplete
                id="filter-projects"
                options={filterProjects().sort((a, b) =>
                  a.name > b.name ? 1 : -1
                )}
                value={projectFilter}
                getOptionLabel={(option) => {
                  return option.name;
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.id}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                multiple
                onChange={onProjectChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={"Filter by Projects"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} style={{ padding: "5px" }}>
              <Autocomplete
                id="filter-templates"
                options={
                  templates
                    ? [...templates.templates].sort((a, b) =>
                        a.name > b.name ? 1 : -1
                      )
                    : []
                }
                value={templateFilter}
                getOptionLabel={(option) => {
                  return option.name;
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                multiple
                onChange={onTemplateChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={"Filter by Templates"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "5px" }}>
              <Autocomplete
                id="filter-status"
                options={["Open", "Closed"]}
                value={status}
                onChange={onStatusChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Filter by Status"
                    value={params}
                    id={params.id}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "5px" }}>
              <Autocomplete
                id="filter-incident"
                options={["With", "Without"]}
                value={incident}
                onChange={onIncidentChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Filter by Incident"
                    value={params}
                    id={params.id}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} style={{ padding: "5px" }} container>
              <DesktopDatePicker
                label="Filter After This Date"
                inputFormat="MMM DD, yyyy"
                value={afterDate}
                onChange={onAfterDateChange}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "47%", marginRight: "3%" }}
                    variant="standard"
                    {...params}
                  />
                )}
                disableMaskedInput
              />
              <DesktopDatePicker
                label="Filter Before This Date"
                inputFormat="MMM DD, yyyy"
                value={beforeDate}
                onChange={onBeforeDateChange}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "50%" }}
                    variant="standard"
                    {...params}
                  />
                )}
                disableMaskedInput
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} style={{ ...blueButton }}>
            FILTER
          </Button>
          <Button onClick={clearFilters} style={{ ...blackButton }}>
            CLEAR
          </Button>
        </DialogActions>
      </Dialog>
    </Toolbar>
  );
}
