/* istanbul ignore file */
import React from "react";
import ReactDOM from "react-dom";

import { CircularProgress } from "@mui/material";
// MUI
import { ThemeProvider } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// APOLLO
import { ApolloProvider } from "@apollo/client";
// SENTRY
import * as Sentry from "@sentry/react";

import App from "./App";
import theme from "./Theme";
import { environment } from "./config";
import { queueLink, useInitializeClient } from "./graphql/useInitializeClient";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorker";

// import trackerLink from "./utils/trackerLink";

function AppWrapper() {
  const client = useInitializeClient();
  const setOnline = () => {
    // Wait for refresh token
    setTimeout(() => queueLink.open(), 1000);
  };
  const setOffline = () => {
    queueLink.close();
  };

  // Register the event listeners
  React.useEffect(() => {
    window.addEventListener("offline", setOffline);
    window.addEventListener("online", setOnline);

    // cleanup if we unmount
    return () => {
      window.removeEventListener("offline", setOffline);
      window.removeEventListener("online", setOnline);
    };
  }, []);

  if (!client) return <CircularProgress />;

  return (
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  );
}

Sentry.init({
  dsn: "https://8917e3900233b0d67855b9e5705ebf49@o4505749947351040.ingest.sentry.io/4505749948923904",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https:nixn.app/api/"],
    }),
    new Sentry.Replay(),
  ],
  environment: environment,
  // Performance Monitoring
  tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <AppWrapper />
      </ThemeProvider>
    </LocalizationProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
const onServiceWorkerUpdate = (registration) => {
  window.location.reload();
  if (registration && registration.waiting) {
    registration.waiting.postMessage({ type: "SKIP_WAITING" });
  }
};

serviceWorkerRegistration.register({ onUpdate: onServiceWorkerUpdate });
