import React from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";

import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import moment from "moment";

import { onlineVar } from "../../../../../graphql/localVariables/user";
import { MARK_OBSERVATION_USER_NOTIFIED } from "../../../../../graphql/mutations/observations";
import {
  GET_NOTIFICATION,
  GET_OBSERVATION_USERS,
} from "../../../../../graphql/queries";
import { GET_CURRENT_USER } from "../../../../../graphql/queries/auth";

const NotificationCard = ({ notification }) => {
  const theme = useTheme();
  const [getNotification, { data }] = useLazyQuery(GET_NOTIFICATION);
  const [readNotification] = useMutation(MARK_OBSERVATION_USER_NOTIFIED);

  const [open, setOpen] = React.useState(false);
  const [photosAttached, setPhotosAttached] = React.useState([]);
  const [corrections, setCorrections] = React.useState([]);

  React.useEffect(() => {
    if (open) {
      getNotification({ variables: { id: Number(notification.id) } });
    }
  }, [open]);

  React.useEffect(() => {
    if (data) {
      setCorrections(
        data.observationUser.observation.observationArms
          .filter((oa) => oa.isCorrection)
          .map((oa) => `${oa.arm.m.name} [${oa.arm.r.name}] [${oa.arm.a.name}]`)
      );

      data.observationUser.observation.observationArms.forEach((oa) =>
        setPhotosAttached((prev) => [...prev, ...oa.observationArmMediaFiles])
      );
    }
  }, [data]);

  const markRead = () => {
    readNotification({
      variables: { input: { id: Number(notification.id), notified: true } },
    });
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid
        item
        style={{
          backgroundColor: "black",
          color: "white",
          borderRadius: 6,
          width: "100%",
          padding: "10px",
          cursor: "pointer",
          marginBottom: "10px",
        }}
        onClick={() => setOpen(true)}
      >
        <Grid container alignItems="center">
          <Grid item xs={1}>
            {!notification.notified ? (
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  backgroundColor: theme.palette.yellow0.main,
                  borderRadius: 10,
                }}
              />
            ) : (
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  backgroundColor: theme.palette.grey0.main,
                  borderRadius: 10,
                }}
              />
            )}
          </Grid>
          <Grid item xs={11}>
            <Typography>
              You are being notified of corrections on Observation{" "}
              {notification.observation.name}.
            </Typography>
            <Typography style={{ fontSize: "0.8rem" }}>
              {moment(notification.timeCreated).format("MM/DD/YYYY hh:mm A")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"}>
        <DialogContent
          style={{ backgroundColor: theme.palette.black1.main, color: "white" }}
        >
          {data ? (
            <>
              <Typography style={{ textAlign: "right" }}>
                {moment(notification.timeCreated).format("MM/DD/YYYY hh:mm A")}
              </Typography>
              <Typography style={{ margin: "20px 0px" }}>
                You are being notified of corrections on Observation{" "}
                {notification.observation.name} submitted on{" "}
                {moment(data.observationUser.observation.timeCreated).format(
                  "MM/DD/YYYY hh:mm A"
                )}{" "}
                by {data.observationUser.observation.submitter?.username}.
              </Typography>
              <Typography
                style={{
                  marginTop: "20px",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                Corrections/Unsafe Actions:
              </Typography>
              {corrections.map((c) => (
                <Typography key={c}>{c}</Typography>
              ))}

              <Typography
                style={{
                  marginTop: "20px",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                Comment from Submitter:
              </Typography>
              <Typography>
                {data.observationUser.observation.comment !== "" ? (
                  data.observationUser.observation.comment
                ) : (
                  <i>No comment given.</i>
                )}
              </Typography>

              <Typography
                style={{
                  marginTop: "20px",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                Crewmembers Observed:
              </Typography>
              <Typography>
                {data.observationUser.observation.observationCrewMembers
                  .length > 0 ? (
                  data.observationUser.observation.observationCrewMembers
                    .map(
                      (oCM) =>
                        `${oCM.crewMember.firstName} ${oCM.crewMember.lastName}`
                    )
                    .join(", ")
                ) : (
                  <i>No crewmembers added.</i>
                )}
              </Typography>

              <Typography
                style={{
                  marginTop: "20px",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                Photos Attached?
              </Typography>
              <Typography style={{ marginBottom: "20px" }}>
                {photosAttached.length > 0 ? "Yes" : "No"}
              </Typography>

              <Link
                href={`/view-obs/${notification.observation.obsId}`}
                style={{
                  color: theme.palette.yellow0.main,
                  textDecoration: "underline",
                }}
              >
                View Observation
              </Link>
              <Grid
                container
                justifyContent="flex-end"
                style={{ marginTop: "30px" }}
              >
                {!notification.notified ? (
                  <Button variant="contained" onClick={markRead}>
                    I CONFIRM I READ THIS NOTIFICATION
                  </Button>
                ) : (
                  <Button variant="contained" onClick={handleClose}>
                    CLOSE
                  </Button>
                )}
              </Grid>
            </>
          ) : (
            <Grid>
              <CircularProgress />
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default function Notifications({ mobile }) {
  const online = useReactiveVar(onlineVar);
  const { data: current } = useQuery(GET_CURRENT_USER);
  const [getUsers, { data }] = useLazyQuery(GET_OBSERVATION_USERS, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  const theme = useTheme();

  const [notifications, setNotifications] = React.useState([]);

  React.useEffect(() => {
    if (current) {
      getUsers({ variables: { id: Number(current.currentUser.id) } });
    }
  }, [current]);

  React.useEffect(() => {
    if (data) {
      setNotifications(
        data.observationUsers.filter((oUser) => oUser.notification)
      );
    }
  }, [data]);

  return (
    <Grid
      container
      justifyContent="center"
      style={
        mobile
          ? {
              width: "100%",
              padding: "0px 20px 20px 20px",
              backgroundColor: theme.palette.black1.main,
            }
          : {
              width: "100%",
              maxHeight: 300,
            }
      }
    >
      <Typography
        sx={{
          fontFamily: "barlowextrabold",
          fontSize: "1.3rem",
          color: theme.palette.grey2.main,
          paddingBottom: "10px",
        }}
      >
        NOTIFICATIONS
      </Typography>
      <Grid
        style={
          mobile
            ? { height: "fit-content" }
            : { height: "100%", overflowY: "auto" }
        }
        item
        xs={12}
        container
      >
        {mobile && notifications.length === 0 && (
          <Typography style={{ color: "white" }}>
            You have no notifications.
          </Typography>
        )}
        {notifications
          .sort((a, b) => {
            if (!a.notified && b.notified) {
              return -1;
            } else if (a.notified && !b.notified) {
              return 1;
            } else {
              return new Date(b.timeCreated) - new Date(a.timeCreated);
            }
          })
          .map((n) => (
            <NotificationCard key={n.id} notification={n} />
          ))}
      </Grid>
    </Grid>
  );
}
