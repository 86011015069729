import { gql } from "@apollo/client";

export const GET_USERS_ADMIN = gql`
  query adminUsers {
    users(isAdminPage: true, isActive: true) {
      username
      firstName
      lastName
      id
      isActive
      lastLogin
      email
      image
      company {
        id
        name
      }
      userPermissionGroups(isActive: true, permissionGroup_IsActive: true) {
        permissionGroup {
          id
          name
        }
      }
    }
  }
`;
