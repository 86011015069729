import { gql } from "@apollo/client";

export const MUTATE_PROJECT = gql`
  mutation mutateProject($input: ProjectMutationInput!) {
    project(input: $input) {
      project {
        id
      }
    }
  }
`;

export const CREATE_EDIT_PROJECT = gql`
  mutation projectMutation(
    $OSPinfo: [OSPProjectInputType]
    $addrCity: String
    $addrLine1: String
    $addrLine2: String
    $addrState: String
    $addrZip: String
    $media: Upload
    $projectName: String
    $projectId: ID
    $removeMedia: [ID]
    $dollarAmount: Float
    $changeStatus: Boolean
    $statusActiveProjects: [ID]
    $statusInactiveProjects: [ID]
    $statusCompany: ID
    $statusSite: ID
    $isActive: Boolean
  ) {
    createOrEditProjectAdmin(
      ospInfo: $OSPinfo
      addrCity: $addrCity
      addrLine1: $addrLine1
      addrLine2: $addrLine2
      addrState: $addrState
      addrZip: $addrZip
      media: $media
      projectName: $projectName
      projectId: $projectId
      removeMedia: $removeMedia
      dollarAmount: $dollarAmount
      changeStatus: $changeStatus
      statusActiveProjects: $statusActiveProjects
      statusInactiveProjects: $statusInactiveProjects
      statusCompany: $statusCompany
      statusSite: $statusSite
      isActive: $isActive
    ) {
      project {
        id
      }
    }
  }
`;

export const GET_SITES = gql`
  query sites {
    sites {
      id
      name
      isActive
      timeCreated
      ownerSiteProjects {
        owner {
          id
          name
        }
        project {
          id
        }
      }
      location @client
      ownerId @client
    }
  }
`;
