import React from "react";
import { useNavigate } from "react-router-dom";

import { Grid, Paper, Typography } from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import { permissionsVar } from "../../../graphql/localVariables/user";
import TabPanel from "../../CustomComponents/TabPanels";
import { StyledTab, StyledTabs } from "../../CustomComponents/Tabs";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import Layout from "../../Layout";
import BatchUserCreation from "./BatchUserCreation";
import CreateNewUser from "./CreateNewUser";
import Table from "./Table";

export default function UserAdmin() {
  const navigate = useNavigate();
  const permissions = useReactiveVar(permissionsVar);

  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if (
      permissions.length === 0 ||
      (permissions.length > 0 &&
        !permissions.includes("CREATE_USER") &&
        !permissions.includes("EDIT_USER"))
    ) {
      navigate("/");
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Layout>
      <Grid
        style={{
          height: "100%",
        }}
      >
        <Typography sx={pageTitleStyles}>USER ADMINISTRATION.</Typography>
        <Paper style={{ padding: "20px" }}>
          <StyledTabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            style={{ color: "white" }}
          >
            <StyledTab label="USERS" />
            <StyledTab label="CREATE NEW USER" />
            <StyledTab label="BATCH USER CREATION" />
          </StyledTabs>
          <TabPanel value={value} index={0}>
            <Table />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CreateNewUser />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <BatchUserCreation />
          </TabPanel>
        </Paper>
      </Grid>
    </Layout>
  );
}
