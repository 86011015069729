import React from "react";

import { CssBaseline, Grid } from "@mui/material";

import { useQuery, useReactiveVar } from "@apollo/client";
import moment from "moment";

import { permissionsVar } from "../../../graphql/localVariables/user";
import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import Sidenav from "../../SideNav";
import useStyles from "../styles";
import DashboardMonitorGauge from "./DashboardMonitorGauge";
import TopBar from "./TopBar";

export default function DesktopDashboard() {
  const drawerWidth = 240;

  const classes = useStyles(drawerWidth)();

  const permissions = useReactiveVar(permissionsVar);
  const { data } = useQuery(GET_CURRENT_USER);
  const isSuperuser =
    data && data.currentUser ? data.currentUser.isSuperuser : false;
  const isStaff = data && data.currentUser ? data.currentUser.isStaff : false;

  const riskMitigationScore = parseInt("0");
  const lastUpdate = moment(new Date()).format("MM/DD/YYYY");
  const auditScore = parseInt("0");
  const observationScore = parseInt("0");
  const monthlyRiskMitigation = [];
  const haScore = 0;

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
      }}
    >
      <CssBaseline />
      <Sidenav
        drawerWidth={drawerWidth}
        permissions={permissions}
        isSuperuser={isSuperuser}
        isStaff={isStaff}
      />
      <Grid
        container
        className={classes.content}
        style={{
          width: `calc(100% - ${drawerWidth}px)`,
          height: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          style={{ height: "fit-content", padding: "10px 0px" }}
        >
          <TopBar
            name={
              data && data.currentUser.username
                ? `${data.currentUser.username}`
                : ""
            }
          />
        </Grid>

        <Grid item xs={12}>
          <DashboardMonitorGauge
            riskMitigationScore={riskMitigationScore}
            lastUpdate={lastUpdate}
            monthlyRiskMitigation={monthlyRiskMitigation}
            // riskMonitor={riskMonitor}
            // setRiskMonitor={setRiskMonitor}
            auditScore={auditScore}
            observationScore={observationScore}
            jsaScore={haScore}
            permissions={permissions}
          />
        </Grid>
      </Grid>
    </div>
  );
}
