import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CircularProgress, Grid } from "@mui/material";

import { useLazyQuery, useReactiveVar } from "@apollo/client";

import { permissionsVar } from "../../../graphql/localVariables/user";
import { GET_COMPANY } from "../../../graphql/queries/admin/company";
import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import Layout from "../../Layout";
import CompanySelect from "./CompanySelect";
import Dashboard from "./Dashboard";
import DeactivateModal from "./DeactivateModal";
import EditCompany from "./EditCompany";

export default function CompanyDashboard() {
  const navigate = useNavigate();
  const permissions = useReactiveVar(permissionsVar);
  const { id } = useParams();
  const [getCurrentUser, { data: userData }] = useLazyQuery(GET_CURRENT_USER);
  const [getCompany, { data: company, loading }] = useLazyQuery(GET_COMPANY, {
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    if (userData) {
      getCompany({
        variables: {
          id: id ? Number(id) : Number(userData.currentUser.company?.id),
        },
      });
    }
  }, [userData, id]);

  React.useEffect(() => {
    if (
      permissions.length === 0 ||
      (permissions.length > 0 &&
        !permissions.includes("VIEW_COMPANY_ADMINISTRATION"))
    ) {
      navigate("/");
    }
    getCurrentUser();
  }, []);

  return (
    <Layout>
      <Grid
        container
        style={{
          height: "calc(100vh - 200px)",
          width: "100%",
        }}
        direction="row"
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          style={{ marginBottom: "15px" }}
        >
          <CompanySelect company={company} />
          {company && (
            <DeactivateModal
              company={company.company}
              permissions={permissions}
            />
          )}
          {company && permissions.includes("EDIT_COMPANY") && (
            <EditCompany company={company.company} />
          )}
        </Grid>
        {loading ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: "600px" }}
          >
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <Dashboard company={company?.company} />
        )}
      </Grid>
    </Layout>
  );
}
