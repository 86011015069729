export const filterData = (arr, searchTerm, groupsFilter, companyFilter) => {
  let filteredArr = searchFilter(arr, searchTerm);
  if (groupsFilter.length > 0) {
    filteredArr = groupFilter(arr, groupsFilter);
  }
  if (companyFilter.name !== "") {
    filteredArr = companyFilterFn(arr, companyFilter);
  }
  return filteredArr;
};

// searchTerm - string
// arr - array of objects
const searchFilter = (arr, searchTerm) => {
  searchTerm = String(searchTerm).toLowerCase();
  return arr.filter((o) =>
    `${o.firstName} ${o.lastName} ${o.username}`
      .toLowerCase()
      .includes(searchTerm)
  );
};

const groupFilter = (arr, groups) => {
  const gIds = groups.map((g) => g.id);
  return arr.filter(
    (a) =>
      a.userPermissionGroups.findIndex((upg) =>
        gIds.includes(upg.permissionGroup.id)
      ) >= 0
  );
};

const companyFilterFn = (arr, company) => {
  return arr.filter((a) => a.company?.id === company.id);
};
