import React from "react";

import { CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

import Carousel from "./Carousel";
import OrgChartRoot from "./OrgChart/OrgChartRoot";
import useStyles from "./styles";

export default function Dashboard({ company }) {
  const classes = useStyles()();
  const theme = useTheme();

  if (company) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.root}
        sx={{ maxHeight: { md: "600px" } }}
      >
        <Grid item xs={12} md={5} sx={{ height: { xs: "40%", md: "100%" } }}>
          <Paper
            children={<OrgChartRoot company={company} />}
            elevation={5}
            style={{
              height: "100%",
              backgroundColor: "inherit",
              borderRadius: "20px",
              boxShadow: `10px -10px 30px ${theme.palette.grey3.main}`,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          container
          direction="column"
          sx={{ marginTop: { xs: "50px", md: "0px" } }}
        >
          <Grid item xs={12}>
            <Carousel company={company} />
          </Grid>
          <Grid item xs={12} container style={{ marginTop: "20px" }}>
            <Grid item xs={1} />
            <Grid item xs={10}>
              <Paper
                children={
                  <Grid>
                    <Typography
                      sx={{
                        fontSize: "1.3em",
                        color: theme.palette.grey2.main,
                        fontFamily: "barlowextrabold",
                      }}
                    >
                      Details
                    </Typography>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography
                          style={{ color: "white", marginBottom: "10px" }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontSize: "0.9rem" }}
                          >
                            INDUSTRY:
                          </span>{" "}
                          {company.industry?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography style={{ color: "white" }}>
                          <span
                            style={{ fontWeight: "bold", fontSize: "0.9rem" }}
                          >
                            RISK THRESHOLD:
                          </span>{" "}
                          {company.riskThreshold}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                }
                elevation={5}
                style={{
                  width: "95%",
                  height: "100%",
                  backgroundColor: "inherit",
                  padding: "10px",
                  borderRadius: "10px",
                  boxShadow: `10px -10px 30px ${theme.palette.grey3.main}`,
                }}
              />
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Grid>
      </Grid>
    );
  } else
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "600px" }}
      >
        <CircularProgress color="secondary" />
      </Grid>
    );
}
