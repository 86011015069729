import React from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Checkbox,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { useQuery } from "@apollo/client";

import { GET_PROJECTS } from "../../../../graphql/queries/offlineMode";

export default function Projects({
  project,
  setProject,
  site,
  owner,
  allProjects,
  setAllProjects,
  allSites,
}) {
  // Queries
  const { data, loading } = useQuery(GET_PROJECTS);

  const handleChange = (event, newValue) => {
    setProject(newValue);
  };

  const getOptions = () => {
    if (data) {
      let projects = [...data.ownerSiteProjects];
      if (owner && owner.length > 0) {
        const ownerIds = owner.map((o) => Number(o.owner.id));
        projects = projects.filter((osp) =>
          ownerIds.includes(Number(osp.owner.id))
        );
      }
      if (site && site.length > 0) {
        const siteIds = site.map((s) => Number(s.site.id));
        projects = projects.filter((osp) =>
          siteIds.includes(Number(osp.site.id))
        );
      }
      return projects.sort((a, b) =>
        a.project.name > b.project.name ? 1 : -1
      );
    } else return [];
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <>
      <Autocomplete
        id="select-projects"
        options={getOptions()}
        value={project}
        getOptionLabel={(option) =>
          `${option.project.name} [${option.site.name}]`
        }
        multiple
        onChange={handleChange}
        disabled={allProjects || (site.length === 0 && !allSites)}
        loading={loading}
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) =>
          `${option.project.id}-${option.site.id}-${option.owner.id}` ===
          `${value.project.id}-${value.site.id}-${value.owner.id}`
        }
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            key={`${option.project.id}-${option.site.id}-${option.owner.id}`}
          >
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {`${option.project.name} [${option.site.name}]`}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            value={params}
            variant="standard"
            label="Project(s):"
          />
        )}
      />
      <Grid container alignItems="center">
        <Checkbox
          checked={allProjects}
          onChange={(e) => setAllProjects(e.target.checked)}
          disabled={site.length === 0 && !allSites}
        />
        <Typography>Select All Projects</Typography>
      </Grid>
    </>
  );
}
