import React from "react";

import { useQuery, useReactiveVar } from "@apollo/client";
import moment from "moment";

import { onlineVar } from "../../../graphql/localVariables/user";
import { GET_RECENT_OBSERVATIONS } from "../../../graphql/queries";
import RecentObs from "./RecentObs";

export default function RecentObsWrapper() {
  const lastWeek = moment(new Date()).subtract(7, "days").format("YYYY-MM-DD");

  const online = useReactiveVar(onlineVar);
  const {
    data: obs,
    refetch: refetchObs,
    loading,
  } = useQuery(GET_RECENT_OBSERVATIONS, {
    variables: { after_date: lastWeek, first: 1000 },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  return <RecentObs obs={obs} refetch={refetchObs} loading={loading} />;
}
