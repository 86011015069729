import React from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import { useNavigate } from "react-router-dom";

import {
  Grid,
  Typography,
  Avatar,
  Tooltip,
  CircularProgress,
} from "@mui/material";

import { useQuery } from "@apollo/client";

import { GET_CURRENT_USER } from "../../../../../graphql/queries/auth";
import { defaultAvatar } from "../../../../CustomStyles/avatar";
import {
  COMPANY_ADMIN_OSP,
  COMPANY_ADMIN_USERS,
} from "../../companyAdminQueries";

export default function OrgChart({ company, viewAll }) {
  const navigate = useNavigate();
  const currCompany = company.name;

  const { data: user } = useQuery(GET_CURRENT_USER);
  const { data: osp } = useQuery(COMPANY_ADMIN_OSP, {
    variables: { id: Number(company.id) },
  });
  const { data: users } = useQuery(COMPANY_ADMIN_USERS, {
    variables: { id: Number(company.id) },
  });

  const renderStats = (amountOfChildren) => {
    if (amountOfChildren < 2) {
      return (
        <Grid container item xs={12} sx={{ paddingTop: "10px" }}>
          <Grid item xs={3}>
            <Typography sx={{ fontSize: "1.25rem", fontWeight: "bold" }}>
              {company.child.length}
            </Typography>
            <Tooltip title={"Companies"}>
              <Typography sx={{ fontSize: ".75rem", lineHeight: 0.9 }} noWrap>
                Companies
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            {osp ? (
              <Typography sx={{ fontSize: "1.25rem", fontWeight: "bold" }}>
                {
                  osp.ownerSiteProjects.filter(
                    (osp) => osp.site && !osp.project
                  ).length
                }
              </Typography>
            ) : (
              <Grid>
                <CircularProgress size="small" />
              </Grid>
            )}
            <Typography sx={{ fontSize: ".75rem", lineHeight: 0.9 }} noWrap>
              Sites
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {osp ? (
              <Typography sx={{ fontSize: "1.25rem", fontWeight: "bold" }}>
                {osp.ownerSiteProjects.filter((osp) => osp.project).length}
              </Typography>
            ) : (
              <Grid>
                <CircularProgress size="small" />
              </Grid>
            )}
            <Tooltip title={"Projects"}>
              <Typography sx={{ fontSize: ".75rem", lineHeight: 0.9 }} noWrap>
                Projects
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            {users ? (
              <Typography sx={{ fontSize: "1.25rem", fontWeight: "bold" }}>
                {users.users.length}
              </Typography>
            ) : (
              <Grid>
                <CircularProgress size="small" />
              </Grid>
            )}
            <Typography sx={{ fontSize: ".75rem", lineHeight: 0.9 }} noWrap>
              Users
            </Typography>
          </Grid>
        </Grid>
      );
    }
  };

  const renderCurrentCompanyDetails = (amountOfChildren = 0) => {
    return (
      <Grid container>
        <Grid item xs={12} alignItems="center" justifyContent="center">
          <Avatar
            alt={company.name}
            src={`data:image/jpg;base64,${company.logoBase64}`}
            sx={{
              ...defaultAvatar,
              width: amountOfChildren > 2 ? "50px" : "80px",
              margin: "auto",
              height: amountOfChildren > 2 ? "50px" : "80px",
              fontSize: amountOfChildren > 2 ? "1.5rem" : "2.5rem",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: amountOfChildren > 2 ? "1rem" : "1.25rem",
              textAlign: "center",
            }}
          >
            {company.name}
          </Typography>
          <Typography
            sx={{ fontSize: ".75rem", lineHeight: 0.9, textAlign: "center" }}
          >
            {company.industry?.name}
          </Typography>
        </Grid>
        {renderStats(amountOfChildren)}
      </Grid>
    );
  };

  const renderNode = (data, amountOfChildren = 0) => {
    return (
      <TreeNode
        key={data.id}
        style={{ placeContent: "center" }}
        label={
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{
              padding: "20px",
              border:
                data.name === currCompany
                  ? "solid 1px #FFB700"
                  : "solid 1px #eee",
              color: "white",
              cursor: "pointer",
              fontSize: "0.75rem",
            }}
            onClick={() => navigate(`/admin/company/${data.id}`)}
          >
            {data.name === currCompany
              ? renderCurrentCompanyDetails(amountOfChildren)
              : data.name}
          </Grid>
        }
      >
        {data.child &&
          data.child.map((p) => {
            return renderNode(p, data.child.length);
          })}
      </TreeNode>
    );
  };

  const getChildren = () => {
    if (viewAll) {
      return company.child;
    } else return company.child.slice(0, 50);
  };

  if (!company.parent && company.child.length === 0) {
    return (
      <div
        style={{
          padding: "20px",
          border: "solid 1px #FFB700",
          color: "white",
        }}
      >
        {renderCurrentCompanyDetails()}
      </div>
    );
  }

  if (company.parent) {
    return (
      <Tree
        lineColor="white"
        label={
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{
              padding: "20px",
              border: "solid 1px #eee",
              color: "white",
              cursor: "pointer",
            }}
            onClick={() => {
              // Most child companies should not be able to see parent company page
              if (user.currentUser.company.name !== currCompany)
                navigate(`/admin/company/${company.parent.id}`);
            }}
          >
            {company.parent.name}
          </Grid>
        }
      >
        {renderNode(company, getChildren().length)}
      </Tree>
    );
  } else {
    return (
      <Tree
        lineColor="white"
        label={
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{
              padding: "20px",
              border: "solid 1px #FFB700",
              color: "white",
            }}
          >
            {renderCurrentCompanyDetails()}
          </Grid>
        }
      >
        {getChildren().map((c) => renderNode(c, getChildren.length))}
      </Tree>
    );
  }
}
