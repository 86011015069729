import React from "react";

import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

import { useReactiveVar } from "@apollo/client";

import { permissionsVar } from "../../../../../graphql/localVariables/user";
import CreateCompanyDialog from "../../View/CreateCompanyDialog";
import useStyles from "./styles";

export default function CreateCompany() {
  const classes = useStyles()();
  const permissions = useReactiveVar(permissionsVar);
  const [open, setOpen] = React.useState(false);

  return (
    <>
      {permissions.includes("CREATE_COMPANY") && (
        <AddCircleRoundedIcon
          className={classes.cardAddIcon}
          onClick={() => setOpen(true)}
        />
      )}
      <CreateCompanyDialog open={open} setOpen={setOpen} />
    </>
  );
}
