import React from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Checkbox,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { useLazyQuery, useQuery } from "@apollo/client";

import {
  GET_COMPANIES,
  GET_CONTRACTORS_AT_OSPS,
} from "../../../../graphql/queries";
import removeDuplicates from "../../../../utils/removeDuplicates";

export default function Contractors({
  contractors,
  setContractors,
  allContractors,
  setAllContractors,
  owner,
  site,
}) {
  // Queries
  const { data: companies } = useQuery(GET_COMPANIES);
  const [getContractors, { data, loading }] = useLazyQuery(
    GET_CONTRACTORS_AT_OSPS
  );

  React.useEffect(() => {
    if (owner.length > 0) {
      getContractors({
        variables: {
          ownerId: owner.map((o) => Number(o.owner.id)),
          siteId: site.length > 0 ? site.map((s) => Number(s.site.id)) : null,
        },
      });
    }
  }, [owner, site]);

  const handleChange = (event, newValue) => {
    setContractors(newValue || []);
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const getOptions = () => {
    if (owner.length === 0) {
      return companies
        ? [...companies.companies].sort((a, b) => (a.name > b.name ? 1 : -1))
        : [];
    } else {
      return data
        ? removeDuplicates(
            [...data.contractors]
              .map((c) => c.contractor)
              .sort((a, b) => (a.name > b.name ? 1 : -1)),
            "id"
          )
        : [];
    }
  };

  return (
    <>
      <Autocomplete
        id="select-contractors"
        value={contractors}
        multiple
        options={getOptions()}
        getOptionLabel={(option) => option.name}
        loading={loading}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        disabled={allContractors}
        renderInput={(params) => (
          <TextField {...params} variant="standard" label="Contractors:" />
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={`${option.id}`}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </li>
        )}
      />
      <Grid container alignItems="center">
        <Checkbox
          checked={allContractors}
          onChange={(e) => setAllContractors(e.target.checked)}
        />
        <Typography>Select All Contractors</Typography>
      </Grid>
    </>
  );
}
