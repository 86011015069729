import React from "react";
import { useNavigate } from "react-router-dom";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  List,
  ListItemText,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItemButton,
} from "@mui/material";
import { useTheme } from "@mui/styles";

export default function Company({
  textSelectedColor,
  endBarSelectedColor,
  classes,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const expandIconColor = open ? endBarSelectedColor : theme.palette.grey1.main;

  return (
    <Accordion disableGutters style={{ width: "100%" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: expandIconColor }} />}
        aria-controls="company-content"
        id="company-header"
        style={{
          backgroundColor: theme.palette.black0.main,
          color: theme.palette.grey1.main,
          padding: 0,
        }}
        onClick={handleClick}
      >
        <Typography
          className={classes.listText}
          style={{
            color: open ? textSelectedColor : undefined,
            paddingLeft: "16px",
          }}
        >
          Company
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{
          backgroundColor: theme.palette.black0.main,
        }}
      >
        <List>
          <ListItemButton onClick={() => navigate("/admin/company")}>
            <ListItemText primary="Company" className={classes.listText} />
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/admin/industry")}>
            <ListItemText primary="Industry" className={classes.listText} />
          </ListItemButton>
        </List>
      </AccordionDetails>
    </Accordion>
  );
}
