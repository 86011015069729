import React from "react";

import {
  Grid,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import { createHAVar, editHAVar } from "../../../../graphql/localVariables/ha";
import { haMutations } from "../../../../graphql/mutations";
import { useStyles } from "../styles";

export default function MitigatorsView({
  type,
  risk,
  disabled,
  data,
  loadingMit,
}) {
  const editHA = useReactiveVar(editHAVar);
  const createHA = useReactiveVar(createHAVar);
  const updateHA = type === "EDIT" ? editHA : createHA;
  const { updateCreateHA, updateEditHA } = haMutations;
  const updateHAFunction = type === "EDIT" ? updateEditHA : updateCreateHA;

  const classes = useStyles()();

  const findMitigators = (risk) => {
    const allMitigators = data?.companyArms
      .map((carm) => carm.arm)
      .filter(
        (haArm) =>
          Number(haArm.r?.id) === Number(risk.r?.id) &&
          Number(haArm.a.id) === Number(risk.a.id)
      );
    const unique = (arr) =>
      arr.reduce((results, item) => {
        if (item.m !== null) {
          results.push(item);
        }
        return results;
      }, []);
    return unique(allMitigators ? allMitigators : []);
  };

  const handleChange = (event, m) => {
    if (event.target.checked) {
      const prev = updateHA.mitigators ? updateHA.mitigators : [];
      updateHAFunction("mitigators", [...prev, m]);
    } else {
      updateHAFunction(
        "mitigators",
        updateHA.mitigators.filter((r) => Number(r.m.id) !== Number(m.m.id))
      );
    }
  };

  const findClass = (index) => {
    const length = findMitigators(risk).length;
    if (length === 1) {
      return classes.onlyBorderedBox;
    } else if (index === 0) {
      return classes.topBorderedBox;
    } else if (index === length - 1) {
      return classes.bottomBorderedBox;
    } else return classes.borderedBox;
  };

  const displayMitigator = (m, index) => {
    return (
      <Grid
        item
        xs={12}
        key={m.m.id}
        className={findClass(index)}
        style={{ backgroundColor: findChecked(m) ? "#74808760" : "white" }}
      >
        <FormControlLabel
          sx={{ marginRight: 0, wordBreak: "break-word" }}
          control={
            <Checkbox
              checked={findChecked(m)}
              disabled={disabled}
              onChange={(event) => handleChange(event, m)}
            />
          }
          label={m.m.name}
        />
      </Grid>
    );
  };

  const findChecked = (mit) => {
    return (
      updateHA.mitigators &&
      updateHA.mitigators.findIndex(
        (haRisk) =>
          Number(haRisk.a.id) === Number(mit.a.id) &&
          Number(haRisk.r?.id) === Number(mit.r?.id) &&
          Number(haRisk.m?.id) === Number(mit.m?.id)
      ) >= 0
    );
  };

  return (
    <>
      {loadingMit ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "100%" }}
        >
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        findMitigators(risk)
          .sort((a, b) => (a.m.name > b.m.name ? 1 : -1))
          .map((m, index) => displayMitigator(m, index))
      )}
    </>
  );
}
