import React from "react";

import { useLazyQuery, useReactiveVar } from "@apollo/client";

import {
  createIncidentVar,
  editIncidentVar,
} from "../../../../graphql/localVariables/investigation";
import { investigationMutations } from "../../../../graphql/mutations";
import { GET_OSP } from "../../../../graphql/queries";
import {
  GET_PROJECT_BY_OWNERSITE,
  GET_SITES,
} from "../../../../graphql/queries/index";
import General from "./General";

export default function GeneralContainer({ type }) {
  // Global Variables
  const editInc = useReactiveVar(editIncidentVar);
  const createInc = useReactiveVar(createIncidentVar);
  const form = type === "EDIT" ? editInc : createInc;
  // Mutations
  const { updateKeyEditIncident, updateKeyCreateIncident } =
    investigationMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditIncident : updateKeyCreateIncident;

  // Queries
  const [getLocation, { data: siteData }] = useLazyQuery(GET_SITES);
  const [getProjectsBySite, { data: projectData }] = useLazyQuery(
    GET_PROJECT_BY_OWNERSITE
  );
  const [getOSPQuery] = useLazyQuery(GET_OSP, {
    onCompleted(data) {
      updateObsKeyFunction("ospId", data.ownerSiteProjects[0].id);
    },
  });

  React.useEffect(() => {
    getLocation();
  }, []);

  React.useEffect(() => {
    if (form.site?.id) {
      getProjectsBySite({
        variables: {
          siteId: Number(form.site.id),
          ownerId: Number(form.owner.id),
        },
      });
    }
  }, [form.site]);

  React.useEffect(() => {
    if (form.site?.id && form.project?.id) {
      getOSPQuery({
        variables: {
          ownerId: Number(form.owner.id),
          siteId: Number(form.site.id),
          projectId: Number(form.project.id),
        },
      });
    }
  }, [form.owner, form.site, form.project]);

  return (
    <General sites={siteData} form={form} type={type} projects={projectData} />
  );
}
