import {
  createAuditImagesVar,
  createAuditVar,
  editAuditImagesVar,
  editAuditVar,
  viewAuditFiltersVar,
} from "../localVariables/audit";
import { createHAVar, editHAVar, viewHAFiltersVar } from "../localVariables/ha";
import {
  createIncidentVar,
  editIncidentVar,
  createIncidentImagesVar,
  editIncidentImagesVar,
  viewIncidentFiltersVar,
} from "../localVariables/investigation";
import {
  createObservationVar,
  editObservationVar,
  viewObservationFiltersVar,
} from "../localVariables/observation";
import { trackedQueriesVar } from "../localVariables/trackedQueries";
import {
  authVar,
  snackbarVar,
  loadingVar,
  onlineVar,
  permissionsVar,
  cacheVar,
} from "../localVariables/user";
import {
  updateCreateAudit,
  updateEditAudit,
  updateAllCreateAudit,
  updateAllEditAudit,
  updateEditResponsesAudit,
  updateCreateResponsesAudit,
  AUDIT_EDIT,
  AUDIT_CREATE,
  updateCreateAuditImages,
  updateEditAuditImages,
  createUpdateKeyViewAuditFilter,
  createUpdateAllViewAuditFilter,
} from "./audits";
import {
  createUpdateAuth,
  createUpdateSnackbar,
  createUpdateLoading,
  createUpdateOnline,
  LOGIN_MUTATION,
  REGISTER_MUTATION,
  createUpdatePermissions,
  createUpdateCache,
} from "./auth";
import {
  updateCreateHA,
  updateEditHA,
  updateAllCreateHA,
  updateAllEditHA,
  createUpdateKeyViewHAFilter,
  createUpdateAllViewHAFilter,
} from "./ha";
import {
  createUpdateKeyCreateIncident,
  createUpdateKeyEditIncident,
  createUpdateAllCreateIncident,
  createUpdateAllEditIncident,
  updateCreateIncidentImages,
  updateEditIncidentImages,
  INCIDENT_CREATE,
  INCIDENT_EDIT,
  updateKeyViewIncidentFilter,
  updateAllViewIncidentFilter,
} from "./investigation";
import {
  createUpdateKeyCreateObservation,
  createUpdateKeyEditObservation,
  createUpdateAllCreateObservation,
  createUpdateAllEditObservation,
  OBSERVATION_CREATE,
  OBSERVATION_EDIT,
  createUpdateKeyViewObservationFilter,
  createUpdateAllViewObservationFilter,
} from "./observations";
import { addTrackedQuery, removeTrackedQuery } from "./trackedQueries";

export const authMutations = {
  updateAuth: createUpdateAuth(authVar),
  updatePermissions: createUpdatePermissions(permissionsVar),
  updateSnackbar: createUpdateSnackbar(snackbarVar),
  updateLoading: createUpdateLoading(loadingVar),
  updateOnline: createUpdateOnline(onlineVar),
  updateCache: createUpdateCache(cacheVar),
  LOGIN_MUTATION,
  REGISTER_MUTATION,
};

export const trackedQueriesMutations = {
  addTrackedQuery: addTrackedQuery(trackedQueriesVar),
  removeTrackedQuery: removeTrackedQuery(trackedQueriesVar),
};

export const observationMutations = {
  updateKeyCreateObservation:
    createUpdateKeyCreateObservation(createObservationVar),
  updateKeyEditObservation: createUpdateKeyEditObservation(editObservationVar),
  updateAllCreateObservation:
    createUpdateAllCreateObservation(createObservationVar),
  updateAllEditObservation: createUpdateAllEditObservation(editObservationVar),
  updateKeyViewObservationFilter: createUpdateKeyViewObservationFilter(
    viewObservationFiltersVar
  ),
  updateAllViewObservationFilter: createUpdateAllViewObservationFilter(
    viewObservationFiltersVar
  ),
  OBSERVATION_CREATE,
  OBSERVATION_EDIT,
};

export const investigationMutations = {
  updateKeyCreateIncident: createUpdateKeyCreateIncident(createIncidentVar),
  updateKeyEditIncident: createUpdateKeyEditIncident(editIncidentVar),
  updateAllCreateIncident: createUpdateAllCreateIncident(createIncidentVar),
  updateAllEditIncident: createUpdateAllEditIncident(editIncidentVar),
  updateCreateIncidentImages: updateCreateIncidentImages(
    createIncidentImagesVar
  ),
  updateEditIncidentImages: updateEditIncidentImages(editIncidentImagesVar),
  updateAllViewIncidentFilters: updateAllViewIncidentFilter(
    viewIncidentFiltersVar
  ),
  updateKeyViewIncidentFilters: updateKeyViewIncidentFilter(
    viewIncidentFiltersVar
  ),
  INCIDENT_CREATE,
  INCIDENT_EDIT,
};

export const auditMutations = {
  updateCreateAudit: updateCreateAudit(createAuditVar),
  updateEditAudit: updateEditAudit(editAuditVar),
  updateCreateAudit: updateCreateAudit(createAuditVar),
  updateEditAudit: updateEditAudit(editAuditVar),
  updateAllCreateAudit: updateAllCreateAudit(createAuditVar),
  updateAllEditAudit: updateAllEditAudit(editAuditVar),
  updateEditResponsesAudit: updateEditResponsesAudit(editAuditVar),
  updateCreateResponsesAudit: updateCreateResponsesAudit(createAuditVar),
  updateCreateAuditImages: updateCreateAuditImages(createAuditImagesVar),
  updateEditAuditImages: updateEditAuditImages(editAuditImagesVar),
  updateAllViewAuditFilters:
    createUpdateAllViewAuditFilter(viewAuditFiltersVar),
  updateKeyViewAuditFilters:
    createUpdateKeyViewAuditFilter(viewAuditFiltersVar),
  AUDIT_EDIT,
  AUDIT_CREATE,
};

export const haMutations = {
  updateCreateHA: updateCreateHA(createHAVar),
  updateEditHA: updateEditHA(editHAVar),
  updateAllCreateHA: updateAllCreateHA(createHAVar),
  updateAllEditHA: updateAllEditHA(editHAVar),
  updateKeyViewHAFilters: createUpdateKeyViewHAFilter(viewHAFiltersVar),
  updateAllViewHAFilters: createUpdateAllViewHAFilter(viewHAFiltersVar),
};
