export const filterData = (arr, searchTerm) => {
  let filteredArr = searchFilter(arr, searchTerm);
  return filteredArr;
};

// searchTerm - string
// arr - array of objects
const searchFilter = (arr, searchTerm) => {
  searchTerm = String(searchTerm).toLowerCase();
  return arr.filter((o) => `${o.name}`.toLowerCase().includes(searchTerm));
};
