import * as React from "react";

import { Button } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { useMutation } from "@apollo/client";
import moment from "moment";

import { MUTATE_SITE } from "../../../../../graphql/mutations/admin/planning-site";
import { GET_SITES_ADMIN } from "../../../../../graphql/queries/admin/planning-site";
import { CustomSwitch } from "../../../../CustomComponents/Switch";
import { yellowButton } from "../../../../CustomStyles/buttons";
import EditSite from "./EditSite";

function Row({ row, siteData }) {
  const [editSite] = useMutation(MUTATE_SITE, {
    refetchQueries: [{ query: GET_SITES_ADMIN }],
  });
  const [open, setOpen] = React.useState(false);

  const handleIsActiveChange = (event) => {
    editSite({
      variables: {
        input: { id: Number(row.site.id), isActive: event.target.checked },
      },
    });
  };

  return (
    <>
      <TableRow hover key={row.id}>
        <TableCell component="th" scope="row">
          {row.site.name} [{row.owner.name}]
        </TableCell>
        <TableCell>
          {moment(new Date(row.site.timeCreated)).format("MM/DD/YYYY hh:mm A")}
        </TableCell>
        <TableCell>
          <CustomSwitch
            checked={row.site.isActive}
            name={row.id}
            onChange={(event) => handleIsActiveChange(event)}
          />
        </TableCell>
        <TableCell>
          <Button style={{ ...yellowButton }} onClick={() => setOpen(true)}>
            VIEW
          </Button>
        </TableCell>
      </TableRow>
      <EditSite
        open={open}
        handleClose={() => setOpen(false)}
        row={row.site}
        company={row.owner}
        siteData={siteData}
      />
    </>
  );
}

export default function TableBodyTemplate(props) {
  // these props are available in table body
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    getComparator,
    stableSort,
    data,
    siteData,
  } = props;

  if (data.length === 0) {
    return (
      <TableRow>
        <TableCell style={{ borderBottom: "transparent" }} colSpan={5}>
          No Results Found
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {stableSort(data, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => {
          return <Row key={row.id} row={row} siteData={siteData} />;
        })}
    </>
  );
}
