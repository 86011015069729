import { makeStyles } from "@mui/styles";

const useStyles = () =>
  makeStyles((theme) => ({
    root: {
      height: "100%",
      width: "100%",
    },
    labelRoot: {
      backgroundColor: theme.palette.black0.main,
      textAlign: "center",
      borderRadius: "5px",
      verticalAlign: "middle",
    },
    labelY: {
      fontSize: "0.5em",
      color: theme.palette.yellow0.main,
      fontFamily: "barlowextrabold",
    },
    labelTick: {
      fontSize: "0.5em",
      color: theme.palette.white.main,
      fontFamily: "barlowextrabold",
    },
    title: {
      fontSize: "1.5em",
      color: theme.palette.grey3.main,
      fontFamily: "barlowextrabold",
    },
    titleDot: {
      fontSize: "1.5em",
      color: theme.palette.yellow0.main,
      fontFamily: "barlowextrabold",
    },
    buttonText: {
      fontSize: "1em",
      color: theme.palette.yellow0.main,
      fontFamily: "barlowextrabold",
    },
    buttonIcon: {
      color: theme.palette.white.main,
      fontFamily: "barlowextrabold",
    },
    menuItem: {
      backgroundColor: "inherit",
      color: theme.palette.yellow0.main,
    },
    cardTitle: {
      fontSize: "1.3em",
      color: theme.palette.grey2.main,
      fontFamily: "barlowextrabold",
    },
  }));

export default useStyles;
