import { gql } from "@apollo/client";

export const GET_AUDITS = gql`
  query GetAudits {
    audits {
      id
      csv
      name
      ordering
      timeCreated
      isActive
    }
  }
`;

export const GET_SINGLE_AUDIT_TOPICS = gql`
  query singleAuditTopics($id: Int!) {
    audit(id: $id) {
      id
      topics(isActive: true) {
        id
        name
        questions(isActive: true) {
          id
          ordering
          name
          standardNumber
          score
          penalty
        }
        ordering
      }
    }
  }
`;
