import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";

import { Info } from "@mui/icons-material";
import {
  Typography,
  Grid,
  Button,
  Paper,
  Tooltip,
  CircularProgress,
} from "@mui/material";

import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import {
  PDFViewer,
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
  pdf,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import moment from "moment";

import {
  onlineVar,
  permissionsVar,
} from "../../../graphql/localVariables/user";
import { EMAIL_AUDIT } from "../../../graphql/mutations/audits";
import {
  GET_AUDIT_SUBMISSION,
  GET_SINGLE_AUDIT_TOPICS,
} from "../../../graphql/queries/audits";
import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import logo from "../../../images/nixn-logo-white.png";
import { getAuditScores } from "../../../utils/raScore";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import { CustomSwitch } from "../../CustomComponents/Switch";
import {
  blackButton,
  blueButton,
  yellowButton,
} from "../../CustomStyles/buttons";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import Layout from "../../Layout";
import ViewImages from "./ViewImages";

const styles = StyleSheet.create({
  page: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    padding: "20px",
  },
  formSection: { width: "147.3px", border: "1px solid black", padding: "10px" },
  armSectionHeader: { width: "196.4px", border: "1px solid black" },
  column: { display: "flex", flexDirection: "column" },
  row: { display: "flex", flexDirection: "row" },
  text: { padding: "10px", fontSize: "10px" },
  labelText: { padding: "10px", fontSize: "12px", color: "grey" },
  mainText: {
    padding: "10px",
    fontSize: "14px",
    width: "90%",
    borderBottom: "1px solid grey",
  },
  sectionLabel: { paddingBottom: "10px", fontSize: "10px" },
  tableSection: { width: "120px" },
  smallerTableSection: { width: "90px" },
  largerTableSection: { width: "165px" },
});

export default function PDF() {
  const navigate = useNavigate();
  const { id } = useParams();
  const online = useReactiveVar(onlineVar);
  const permissions = useReactiveVar(permissionsVar);

  const { data } = useQuery(GET_AUDIT_SUBMISSION, {
    variables: { id: Number(id) },
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const [getAuditTopics, { data: auditTopics }] = useLazyQuery(
    GET_SINGLE_AUDIT_TOPICS
  );
  const { data: currentUser } = useQuery(GET_CURRENT_USER);
  const [sendEmailMutation] = useMutation(EMAIL_AUDIT, {
    onCompleted() {
      onCompletedFunc("Email will be sent!");
    },
    onError(error) {
      onErrorFunc(error);
    },
  });

  const [open, setOpen] = React.useState(false);
  const [images, setImages] = React.useState([]);
  const [showAll, setShowAll] = React.useState(false);

  React.useEffect(() => {
    if (data) {
      getAuditTopics({
        variables: { id: Number(data.auditSubmission.audit?.id) },
      });

      const img = [];
      data.auditSubmission.responses.forEach((res) => {
        res.images.forEach((i) => {
          img.push(i);
        });
      });
      setImages(img);
    }
  }, [data]);

  const PDFDocument = () => {
    const topicOrdering = auditTopics
      ? [...auditTopics.audit.topics].sort((a, b) => a.ordering - b.ordering)
      : [];
    const topicQuestions = data.auditSubmission?.responses.map((r) => {
      return {
        comment: r.comment,
        response: r.response,
        auditTopicId: r.auditTopicQuestion.auditTopic.id,
        auditTopicName: r.auditTopicQuestion.auditTopic.name,
        auditTopicQuestion: r.auditTopicQuestion.id,
        auditQuestion: r.auditTopicQuestion.name,
        auditQuestionOrder: r.auditTopicQuestion.ordering,
        auditQuestionPenalty: r.auditTopicQuestion.penalty,
        auditQuestionScore: r.auditTopicQuestion.score,
        auditQuestionStandardNumber: r.auditTopicQuestion.standardNumber,
        auditQuestionImages: r.images,
      };
    });

    const d = new Date(data.auditSubmission.date);
    const utc = d.getTime() + d.getTimezoneOffset() * 60000;
    const convertedDate = new Date(utc);

    let startTime = moment();
    if (data.auditSubmission.time) {
      const timeArr = data.auditSubmission.time.split(":");
      startTime.hours(Number(timeArr[0])).minutes(Number(timeArr[1]));
    }

    const tableTextStyle = {
      fontSize: "8px",
    };

    const tableCellStyle = {
      borderLeft: "1px solid black",
      borderBottom: "1px solid black",
      padding: "3px 5px",
    };

    const positiveSections = [];
    const negativeSections = [];
    topicOrdering.forEach((topic) => {
      const allQuestions = topicQuestions.filter(
        (q) => Number(q.auditTopicId) === Number(topic.id)
      );
      const positiveQuestions = topicQuestions.filter(
        (q) => Number(q.auditTopicId) === Number(topic.id) && q.response === 1
      );
      const negativeQuestions = topicQuestions.filter(
        (q) => Number(q.auditTopicId) === Number(topic.id) && q.response === -1
      );

      const { raScore, penalty } = getAuditScores([topic], allQuestions);

      if (positiveQuestions.length > 0) {
        positiveSections.push({
          name: topic.name,
          number: topic.ordering + 1,
          score: raScore,
          positiveResponses: positiveQuestions.length,
        });
      }

      if (negativeQuestions.length > 0) {
        negativeSections.push({
          name: topic.name,
          number: topic.ordering + 1,
          score: raScore,
          negativeResponses: negativeQuestions.length,
          penalty,
        });
      }
    });

    return (
      <Document pageMode="fullScreen" title="Audit Report" author="NIXN">
        <Page size="A4">
          <View style={styles.page}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "280px",
                  textAlign: "center",
                }}
              >
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  {data.auditSubmission.audit.name.toUpperCase()}
                </Text>
              </View>
              <View
                style={{ width: "25%", borderBottom: "3px solid #FCA800" }}
              ></View>
              {data &&
              data.auditSubmission.submitter.company &&
              data.auditSubmission.submitter.company.logoBase64 !== "" ? (
                <Image
                  src={`data:image;base64,${data.auditSubmission.submitter.company.logoBase64}`}
                  style={{ width: "100px" }}
                />
              ) : (
                <Image src={logo} style={{ width: "100px" }} />
              )}
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                marginTop: "25px",
              }}
            >
              <View
                style={{
                  border: "3px solid #8297A0",
                  borderRadius: 5,
                  width: "150px",
                  textAlign: "center",
                }}
              >
                <Text style={styles.text}>LOCATION:</Text>
                <Text style={styles.text}>
                  {data.auditSubmission.ownerSiteProject.site.name}
                </Text>
                <Text style={[styles.text, { paddingTop: 0 }]}>
                  {`[${data.auditSubmission.ownerSiteProject.owner.name}]`}
                </Text>
              </View>
              <View
                style={{
                  border: "3px solid #8297A0",
                  borderRadius: 5,
                  width: "150px",
                  textAlign: "center",
                }}
              >
                <Text style={styles.text}>RISK EXPOSURE SCORE:</Text>
                <Text style={styles.text}>{data.auditSubmission.raScore}%</Text>
              </View>
              <View
                style={{
                  border: "3px solid #8297A0",
                  borderRadius: 5,
                  width: "150px",
                  textAlign: "center",
                }}
              >
                <Text style={styles.text}>POTENTIAL CITATIONS:</Text>
                <Text style={styles.text}>
                  $
                  {parseFloat(
                    data.auditSubmission.penalty.toFixed(2)
                  ).toLocaleString()}
                </Text>
              </View>
            </View>

            {/* POSITIVE AUDIT */}
            <View
              style={{
                border: "1px solid black",
                width: "100%",
                marginTop: "20px",
                backgroundColor: "#8297a0",
                textAlign: "center",
                padding: "3px 5px",
              }}
            >
              <Text style={{ color: "green", fontSize: "10px" }}>
                Positive Audit Findings
              </Text>
            </View>
            <View
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <View style={{ width: "50%", ...tableCellStyle }}>
                <Text style={tableTextStyle}>Audit Item #</Text>
              </View>
              <View style={{ width: "25%", ...tableCellStyle }}>
                <Text style={tableTextStyle}>Risk Exposure Score</Text>
              </View>
              <View
                style={{
                  width: "25%",
                  ...tableCellStyle,
                  borderRight: "1px solid black",
                }}
              >
                <Text style={tableTextStyle}>Positive Responses</Text>
              </View>
            </View>
            {positiveSections.map((section) => (
              <View
                key={section.number}
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
              >
                <View style={{ width: "50%", ...tableCellStyle }}>
                  <Text style={tableTextStyle}>
                    {section.number} {section.name}
                  </Text>
                </View>
                <View style={{ width: "25%", ...tableCellStyle }}>
                  <Text style={tableTextStyle}>
                    {section.score.toFixed(2) * 100}
                  </Text>
                </View>
                <View
                  style={{
                    width: "25%",
                    ...tableCellStyle,
                    borderRight: "1px solid black",
                  }}
                >
                  <Text style={tableTextStyle}>
                    {section.positiveResponses}
                  </Text>
                </View>
              </View>
            ))}

            {/* NEGATIVE AUDIT */}
            <View
              style={{
                border: "1px solid black",
                width: "100%",
                marginTop: "20px",
                backgroundColor: "#8297a0",
                textAlign: "center",
                padding: "3px 5px",
              }}
            >
              <Text style={{ color: "red", fontSize: "10px" }}>
                Audit Findings Which Could Result in OSHA Citation
              </Text>
            </View>
            <View
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <View style={{ width: "50%", ...tableCellStyle }}>
                <Text style={tableTextStyle}>Audit Item #</Text>
              </View>
              <View style={{ width: "25%", ...tableCellStyle }}>
                <Text style={tableTextStyle}>Risk Exposure Score</Text>
              </View>
              <View
                style={{
                  width: "25%",
                  ...tableCellStyle,
                  borderRight: "1px solid black",
                }}
              >
                <Text style={tableTextStyle}>
                  OSHA Estimated Score (Penalty)
                </Text>
              </View>
            </View>
            {negativeSections.map((section) => (
              <View
                key={section.number}
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
              >
                <View style={{ width: "50%", ...tableCellStyle }}>
                  <Text style={tableTextStyle}>
                    {section.number} {section.name}
                  </Text>
                </View>
                <View style={{ width: "25%", ...tableCellStyle }}>
                  <Text style={tableTextStyle}>
                    {section.score.toFixed(2) * 100}
                  </Text>
                </View>
                <View
                  style={{
                    width: "25%",
                    ...tableCellStyle,
                    borderRight: "1px solid black",
                  }}
                >
                  <Text style={tableTextStyle}>
                    {section.negativeResponses} (${section.penalty})
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </Page>

        {/* Page 2 */}
        <Page size="A4">
          <View style={{ border: "3px solid black", margin: "20px" }}>
            <View
              style={{
                borderTop: "3px solid #8297A0",
                borderBottom: "3px solid black",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View style={styles.smallerTableSection}>
                <Text style={styles.text}>Audit Topic</Text>
              </View>
              <View style={styles.largerTableSection}>
                <Text style={styles.text}>Audit Findings</Text>
              </View>
              <View style={styles.largerTableSection}>
                <Text style={styles.text}>Comments</Text>
              </View>
              <View style={styles.tableSection}>
                <Text style={styles.text}>OSHA Estimated Penalties</Text>
              </View>
            </View>
            {topicOrdering.map((topic, index) => {
              const questions = showAll
                ? topicQuestions.filter((q) => q.auditTopicId === topic.id)
                : topicQuestions.filter(
                    (q) =>
                      q.auditTopicId === topic.id &&
                      (q.response === -1 ||
                        q.comment !== "" ||
                        q.auditQuestionImages.length > 0)
                  );

              if (questions.length > 0) {
                return (
                  <View
                    key={topic.id}
                    style={{
                      borderBottom:
                        index !== topicOrdering.length - 1
                          ? "3px solid black"
                          : undefined,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <View style={styles.smallerTableSection}>
                      <Text wrap={false} style={styles.text}>
                        {topic.name}
                      </Text>
                    </View>
                    <View>
                      {questions
                        .sort(
                          (a, b) => a.auditQuestionOrder - b.auditQuestionOrder
                        )
                        .map((q, index) => (
                          <View
                            wrap={false}
                            key={`${q.auditQuestion}-${q.auditTopicId}`}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              borderBottom:
                                index !==
                                topicQuestions.filter(
                                  (q) => q.auditTopicId === topic.id
                                ).length -
                                  1
                                  ? "2px solid #8297A0"
                                  : undefined,
                              flexWrap: "wrap",
                            }}
                          >
                            <View style={styles.largerTableSection}>
                              <Text
                                style={[
                                  styles.text,
                                  {
                                    color:
                                      q.response === 1
                                        ? "green"
                                        : q.response === -1
                                        ? "red"
                                        : "black", // N/A
                                  },
                                ]}
                              >
                                {q.auditQuestion}
                              </Text>
                            </View>
                            <View style={styles.largerTableSection}>
                              <Text style={styles.text}>{q.comment}</Text>
                            </View>
                            <View style={styles.tableSection}>
                              <Text style={styles.text}>
                                {q.response === 1 &&
                                  "No Violation Identified - See comments or photos for details"}
                                {q.response === -1
                                  ? q.auditQuestionStandardNumber
                                    ? `${q.auditQuestionStandardNumber} - $${q.auditQuestionPenalty}`
                                    : `$${q.auditQuestionPenalty}`
                                  : null}
                              </Text>
                            </View>
                            <View style={{ width: "100%" }}>
                              {q.auditQuestionImages.map((p) => {
                                return (
                                  <Image
                                    key={p.id}
                                    src={p.image}
                                    style={{
                                      width: "25%",
                                      height: "auto",
                                      margin: "5px",
                                      border: "1px solid black",
                                    }}
                                  />
                                );
                              })}
                            </View>
                          </View>
                        ))}
                    </View>
                  </View>
                );
              } else return null;
            })}
          </View>
        </Page>

        {/* Page 3 */}
        <Page size="A4">
          <View
            style={{
              borderTop: "3px solid black",
              margin: "30px 20px 20px 20px",
            }}
          >
            <Text style={{ marginTop: "20px" }}>Audit Approval/Review</Text>
            <View
              style={{
                marginTop: "30px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <View style={{ width: "50%" }}>
                <Text style={styles.labelText}>Supervisor:</Text>
                <Text style={styles.mainText}>
                  {data.auditSubmission.submitter.company
                    ? data.auditSubmission.submitter.company.name
                    : " "}
                </Text>
              </View>
              <View style={{ width: "50%" }}>
                <Text style={styles.labelText}>Who Completed Audit:</Text>
                <Text style={styles.mainText}>
                  {data.auditSubmission.submitter.firstName}{" "}
                  {data.auditSubmission.submitter.lastName}{" "}
                  {`(${data.auditSubmission.submitter.username})`}
                </Text>
              </View>
              <View style={{ width: "50%" }}>
                <Text style={styles.labelText}>Date:</Text>
                <Text style={styles.mainText}>
                  {data.auditSubmission.date
                    ? moment(convertedDate).format("MM/DD/YYYY")
                    : ""}
                </Text>
              </View>
              <View style={{ width: "50%" }}>
                <Text style={styles.labelText}>Time:</Text>
                <Text style={styles.mainText}>
                  {data.auditSubmission.time
                    ? moment(new Date(startTime)).format("hh:mm A")
                    : ""}
                </Text>
              </View>
              {data.auditSubmission.auditSubmissionUsers.length > 0 && (
                <View style={{ width: "100%" }}>
                  <Text style={[styles.labelText, { marginTop: "20px" }]}>
                    Other Audit Contributors:
                  </Text>
                  <Text style={{ fontSize: "10px", padding: "10px" }}>
                    {data.auditSubmission.auditSubmissionUsers
                      .map(
                        (user) =>
                          `${user.user.firstName} ${user.user.lastName} (${user.user.username})`
                      )
                      .join(", ")}
                  </Text>
                </View>
              )}
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  const mobileView = (
    <Paper
      style={{
        width: "100%",
        height: "300px",
        marginTop: "20px",
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "300px" }}
      >
        <Typography>
          Cannot view PDF on mobile device. Download PDF in order to view.
        </Typography>
      </Grid>
    </Paper>
  );

  const sendEmail = async () => {
    let blobPdf = await pdf(PDFDocument()).toBlob();
    let file = new File([blobPdf], "file_name", { type: "application/pdf" });
    sendEmailMutation({
      variables: {
        id: Number(id),
        file,
      },
    });
  };

  const isOwner =
    data?.auditSubmission.submitter.username ===
    currentUser?.currentUser.username;

  const getPDFButton = () => {
    const d = new Date(data.auditSubmission.timeCreated);
    const utc = d.getTime() + d.getTimezoneOffset() * 60000;
    const convertedDate = new Date(utc);

    return (
      <PDFDownloadLink
        document={PDFDocument()}
        fileName={`AUDIT_${data.auditSubmission.ownerSiteProject.site.name}_[${
          data.auditSubmission.ownerSiteProject.owner.name
        }]_${moment(new Date(convertedDate)).format("MMDDYYYY")}`.replace(
          /[:<>*?".|\/\s]/g,
          ""
        )}
        style={{ textDecoration: "none" }}
      >
        {({ loading }) =>
          loading ? (
            <p style={{ color: "white", padding: "0px 10px" }}>Loading...</p>
          ) : (
            <div>
              <Button
                style={{
                  color: "white",
                  ...blackButton,
                  marginRight: "20px",
                }}
              >
                Download PDF
              </Button>
            </div>
          )
        }
      </PDFDownloadLink>
    );
  };

  return (
    <Layout>
      <Grid container justifyContent="space-between">
        <Typography sx={pageTitleStyles}>VIEW AUDIT.</Typography>
        <Button
          onClick={() => navigate("/view-audit")}
          style={{ color: "white" }}
        >
          GO BACK
        </Button>
      </Grid>
      {data ? (
        <Grid container>
          <Grid
            item
            xs={12}
            container
            direction="row"
            style={{ marginBottom: "20px" }}
          >
            {(isOwner || permissions.includes("EDIT_OTHER_AUDIT")) && (
              <Button
                style={{
                  width: "fit-content",
                  ...yellowButton,
                  marginRight: "20px",
                }}
                onClick={() =>
                  navigate(`/edit-audit/${data.auditSubmission.id}`)
                }
                variant="contained"
              >
                Edit
              </Button>
            )}
            <Button
              style={{
                width: "fit-content",
                ...blueButton,
                marginRight: "20px",
                height: "36.5px",
              }}
              variant="contained"
              onClick={sendEmail}
            >
              Send to Email
            </Button>
            {getPDFButton()}
            <Button
              style={{ width: "fit-content", height: "36.5px", ...blueButton }}
              variant="contained"
              onClick={() => setOpen(true)}
            >
              View Images
            </Button>
            <ViewImages
              open={open}
              handleClose={() => setOpen(false)}
              images={images}
            />
            <Grid
              item
              xs={12}
              md={6}
              container
              alignItems="center"
              sx={{ pl: 2 }}
            >
              <Grid container item sx={{ flexGrow: 1 }} alignItems="center">
                <Typography style={{ color: "white", textAlign: "center" }}>
                  Show filtered responses
                </Typography>
                <Tooltip title="Show only responses with No answers or responses with images or comments.">
                  <Info style={{ color: "white", marginLeft: "10px" }} />
                </Tooltip>

                <CustomSwitch
                  checked={showAll}
                  onChange={() => setShowAll((prev) => !prev)}
                  value={true}
                />
                <Typography style={{ color: "white" }}>
                  Show all responses
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <BrowserView>
              {data.auditSubmission && (
                <PDFViewer width="100%" height="800px">
                  {PDFDocument()}
                </PDFViewer>
              )}
            </BrowserView>
            <MobileView>{mobileView}</MobileView>
          </Grid>
        </Grid>
      ) : (
        <Grid container justifyContent="center">
          <CircularProgress color="secondary" />
        </Grid>
      )}
    </Layout>
  );
}
