import React from "react";

import { FileUpload } from "@mui/icons-material";
import { Button, Grid, useTheme } from "@mui/material";

import { DropzoneDialog } from "mui-file-dropzone";

export default function UploadDialog({ handleFilesChange, files, disabled }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (addedFiles) => {
    setOpen(false);
    handleFilesChange([...files, ...addedFiles]);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Grid container>
      <Grid item xs={12} container justifyContent="center">
        <Button
          variant="contained"
          startIcon={
            <FileUpload
              style={{
                color:
                  files && files.length > 0
                    ? theme.palette.yellow0.main
                    : theme.palette.white.main,
                fontSize: "36px",
              }}
            />
          }
          onClick={handleOpen}
          disabled={disabled}
          sx={{ mt: 2 }}
        >
          UPLOAD FILES
        </Button>
      </Grid>
      <DropzoneDialog
        open={open}
        onSave={handleSave}
        showPreviews={true}
        onClose={handleClose}
        maxFileSize={10000000} // 10MB
      />
    </Grid>
  );
}
