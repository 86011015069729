import { gql } from "@apollo/client";

export const GET_AUDITS = gql`
  query audits {
    audits(isActive: true) {
      id
      name
      topics(isActive: true) {
        id
        name
        questions(isActive: true) {
          id
          ordering
          name
          standardNumber
          score
          penalty
        }
        ordering
      }
    }
  }
`;

export const GET_AUDIT_SUBMISSIONS = gql`
  query auditSubmissions {
    auditSubmissions(isActive: true) {
      id
      timeCreated
      date
      time
      raScore
      penalty
      audit {
        id
        name
      }
      submitter {
        id
        username
        firstName
        lastName
      }
      ownerSiteProject {
        id
        owner {
          id
          name
        }
        site {
          id
          name
        }
        project {
          id
          name
        }
      }
    }
  }
`;

export const GET_AUDIT_SUBMISSIONS_DASHBOARD = gql`
  query auditSubmissions {
    auditSubmissions(isActive: true, first: 1) {
      id
      timeCreated
      date
      time
      raScore
      penalty
    }
  }
`;

export const GET_AUDIT_SUBMISSION = gql`
  query auditSubmission($id: Int!) {
    auditSubmission(id: $id) {
      id
      timeCreated
      raScore
      date
      time
      penalty
      audit {
        id
        name
      }
      auditSubmissionUsers(isActive: true) {
        user {
          firstName
          lastName
          username
          id
          image
        }
      }
      submitter {
        id
        username
        firstName
        lastName
        image
        company {
          name
          logoBase64
        }
      }
      ownerSiteProject {
        id
        location @client
        owner {
          id
          name
        }
        site {
          id
          name
        }
      }
      responses {
        auditTopicQuestion {
          id
          auditTopic {
            id
            name
            ordering
          }
          ordering
          score
          penalty
          standardNumber
          name
        }
        response
        score
        comment
        images(isActive: true) {
          id
          image
        }
      }
      auditTopicQuestionResponse {
        id
        comment
        response
        auditTopicQuestion {
          id
          penalty
        }
        auditTopicQuestionResponseImage {
          image
          id
          thumbnail
        }
      }
    }
  }
`;

export const GET_SINGLE_AUDIT_TOPICS = gql`
  query singleAuditTopics($id: Int!) {
    audit(id: $id) {
      id
      topics(isActive: true) {
        id
        name
        questions(isActive: true) {
          id
          ordering
          name
          standardNumber
          score
          penalty
        }
        ordering
      }
    }
  }
`;
