import React from "react";
import { useReactiveVar } from "@apollo/client";
import { TextField } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import {
  createObservationVar,
  editObservationVar,
} from "../../../../graphql/localVariables/observation";
import { observationMutations } from "../../../../graphql/mutations";

export default function DateInputView({ type }) {
  // Global Variables
  const editObs = useReactiveVar(editObservationVar);
  const createObs = useReactiveVar(createObservationVar);
  const form = type === "EDIT" ? editObs : createObs;

  // Mutations
  const { updateKeyEditObservation, updateKeyCreateObservation } =
    observationMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditObservation : updateKeyCreateObservation;

  const handleChange = (date) => {
    updateObsKeyFunction("date", date);
  };

  return (
    <MobileDatePicker
      label="Date:"
      inputFormat="MM/DD/YYYY"
      value={form.date}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          style={{
            width: "100%",
          }}
          variant="standard"
        />
      )}
    />
  );
}
