import React from "react";

import EditSite from "../../../../Planning/Site/Table/EditSite";

export default function EditSiteDialog({
  open,
  setOpen,
  data,
  company,
  siteData,
}) {
  return (
    <EditSite
      open={open}
      handleClose={() => setOpen(false)}
      row={data}
      company={company}
      siteData={siteData}
    />
  );
}
