import React from "react";
import { useParams } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";

import { InfoOutlined } from "@mui/icons-material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import EditIcon from "@mui/icons-material/Edit";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";

import { permissionsVar } from "../../../../graphql/localVariables/user";
import {
  MUTATE_ACTION,
  MUTATE_ACTION_MUTATION,
  MUTATE_ACTION_RISKS,
  CUSTOM_COMP_ARM_TOGGLE,
} from "../../../../graphql/mutations/admin/arm";
import {
  GET_COMPANY_ARMS_ADMIN,
  GET_RISKS_BY_COMPANY_ACTION_ADMIN,
} from "../../../../graphql/queries/admin/arm";
import { GET_CURRENT_USER } from "../../../../graphql/queries/auth";
import removeDuplicates from "../../../../utils/removeDuplicates";
import {
  onErrorFunc,
  onCompletedFunc,
} from "../../../CustomComponents/OnErrorFunction";
import SearchField from "../../../CustomComponents/SearchField";
import { CustomSwitch } from "../../../CustomComponents/Switch";
import { yellowButton } from "../../../CustomStyles/buttons";
import { pageTitleStyles } from "../../../CustomStyles/pageTitle";
import RiskScoreInput from "../RiskScoreInput";
import AssociatedRisk from "./AssociatedRisk";

export const mapScoreToString = (score) => {
  switch (score) {
    case 0:
      return "E";
    case 0.1:
      return "SL";
    case 0.3:
      return "P";
    case 0.5:
      return "SB";
    case 0.7:
      return "H";
    case 0.9:
      return "VH";
    default:
      return "";
  }
};

export default function ActionPage({
  action: companyArmAction,
  handleClose: handleParentClose,
  loadingActiveARMs,
  risks,
  mitigators,
  company,
}) {
  const permissions = useReactiveVar(permissionsVar);
  const { companyId } = useParams();

  const { data: user } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: "network-only",
  });

  const [getRisksByAction, { data, loading }] = useLazyQuery(
    GET_RISKS_BY_COMPANY_ACTION_ADMIN,
    {
      fetchPolicy: "network-only",
    }
  );

  const [open, setOpen] = React.useState(false);
  // ACTION STATE
  const [action, setAction] = React.useState({ a: { name: "" } });
  const [armIsActive, setArmIsActive] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [score, setScore] = React.useState("");
  const [actionName, setActionName] = React.useState("");
  const [associatedRisks, setAssociatedRisks] = React.useState([]);
  const [addedRisks, setAddedRisks] = React.useState([]);
  const [riskSearch, setRiskSearch] = React.useState("");

  // For global action
  const [mutateAction] = useMutation(MUTATE_ACTION, {
    onCompleted() {
      onCompletedFunc("Action has been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
  });

  // For base ARM
  const [mutateActionARM] = useMutation(MUTATE_ACTION_MUTATION, {
    onCompleted() {
      onCompletedFunc("Action has been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [
      {
        query: GET_COMPANY_ARMS_ADMIN,
        fetchPolicy: "network-only",
        variables: {
          companyId: Number(company.company.id),
          rNull: true,
          mNull: true,
        },
      },
      {
        query: GET_RISKS_BY_COMPANY_ACTION_ADMIN,
        fetchPolicy: "network-only",
        variables: {
          companyId: companyId
            ? Number(companyId)
            : user && user.currentUser
            ? Number(user.currentUser.company.id)
            : null,
          actionId: Number(companyArmAction?.arm?.a.id),
        },
      },
    ],
  });

  // For company ARM
  const [mutateActionCARM] = useMutation(CUSTOM_COMP_ARM_TOGGLE, {
    onCompleted() {
      console.log("COMP ARM TOGGLE completed.");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [
      {
        query: GET_COMPANY_ARMS_ADMIN,
        variables: {
          companyId: Number(company.company.id),
          fetchPolicy: "network-only",
          rNull: true,
          mNull: true,
        },
      },
    ],
  });

  const [mutateActionRisks, { loading: loadingMutateActionRisks }] =
    useMutation(MUTATE_ACTION_RISKS, {
      onCompleted() {
        onCompletedFunc("Action and its risks have been updated.");
        setAddedRisks([]);
      },
      onError(error) {
        onErrorFunc(error);
      },
      refetchQueries: [
        {
          query: GET_RISKS_BY_COMPANY_ACTION_ADMIN,
          fetchPolicy: "network-only",
          variables: {
            companyId: companyId
              ? Number(companyId)
              : user && user.currentUser
              ? Number(user.currentUser.company.id)
              : null,
            actionId: Number(companyArmAction?.arm?.a.id),
          },
        },
      ],
    });

  React.useEffect(() => {
    if (companyArmAction) {
      setArmIsActive(companyArmAction.isActive);
      setAction(companyArmAction.arm);
    }
  }, [companyArmAction]);

  React.useEffect(() => {
    if (action.a.id && user) {
      getRisksByAction({
        variables: {
          companyId: companyId
            ? Number(companyId)
            : Number(user.currentUser.company.id),
          actionId: Number(action.a.id),
        },
      });
    }
  }, [action.a.id, user]);

  React.useEffect(() => {
    if (data) {
      setAssociatedRisks(
        data.companyArms.map((carm) => ({
          ...carm.arm,
          riskLevelRange: carm.riskLevelRange,
        }))
      );
    }
  }, [data]);

  React.useEffect(() => {
    if (action.a.id) {
      setIsActive(action.a.isActive);
      setScore(action.riskScore);
      setActionName(action.a.name);
    }
  }, [action]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const add = (r) => {
    setAddedRisks((prev) => [
      ...prev,
      { isExpected: false, riskScore: null, r: r.r, added: true },
    ]);
  };

  const changeExistingValue = (id, key, val) => {
    setAssociatedRisks((prev) =>
      prev.map((p) => {
        if (p.r.id === id) {
          const copy = { ...p };
          copy[key] = val;
          return copy;
        } else return p;
      })
    );
  };

  const changeAddedValue = (id, key, val) => {
    setAddedRisks((prev) =>
      prev.map((p) => {
        if (p.r.id === id) {
          const copy = { ...p };
          copy[key] = val;
          return copy;
        } else return p;
      })
    );
  };

  const remove = (r) => {
    setAddedRisks((prev) => prev.filter((p) => p.r.id !== r.id));
    setAssociatedRisks((prev) => prev.filter((p) => p.r.id !== r.id));
  };

  const onSaveAction = (newScore) => {
    const editingCompany = companyId
      ? Number(companyId)
      : user && user.currentUser
      ? Number(user.currentUser.company.id)
      : null;
    mutateActionARM({
      variables: {
        company: editingCompany,
        id: Number(action.id),
        isActive: armIsActive,
        riskScore: Number(newScore),
      },
    });
  };

  const onSave = () => {
    const assoArmInfo = associatedRisks.map((r) => {
      return {
        riskId: Number(r.r.id),
        isExpected: r.isExpected,
        riskScore: r.riskScore !== null ? Number(r.riskScore) : null,
      };
    });
    const addedArmInfo = addedRisks.map((r) => {
      return {
        riskId: Number(r.r.id),
        isExpected: r.isExpected,
        riskScore: r.riskScore !== null ? Number(r.riskScore) : null,
      };
    });
    const unassociated =
      data && data.companyArms
        ? data.companyArms
            .filter(
              (r) =>
                associatedRisks.findIndex((arm) => arm.r.id === r.arm.r.id) <
                  0 &&
                addedRisks.findIndex((arm) => arm.r.id === r.arm.r.id) < 0
            )
            .map((r) => Number(r.id))
        : [];

    const editingCompany = companyId
      ? Number(companyId)
      : user && user.currentUser
      ? Number(user.currentUser.company.id)
      : null;

    const allRisks = [...assoArmInfo, ...addedArmInfo];

    if (
      permissions.includes("EDIT_ARM_RISK_SCORES") &&
      allRisks.find((r) => r.riskScore === null)
    ) {
      onErrorFunc("Score all risks before you can save your changes.");
    } else if (
      permissions.includes("EDIT_ARM_RISK_SCORES") &&
      allRisks.find((r) => (r.riskScore !== null ? r.riskScore < score : false))
    ) {
      onErrorFunc(
        `Scores for the risks must be greater than or equal to the action's score of ${mapScoreToString(
          score
        )}. Fix this before you can save your changes.`
      );
    } else {
      // Update risks associated to this action for specific company
      mutateActionRisks({
        variables: {
          action: Number(action.a.id),
          armInfo: allRisks,
          unassociatedRisks: unassociated,
          company: editingCompany,
        },
      });
      if (armIsActive !== companyArmAction.isActive) {
        // Mutating action-level company ARM
        mutateActionCARM({
          variables: {
            company: editingCompany,
            action: Number(action.a.id),
            isActive: armIsActive,
          },
        });
      }
    }
  };

  const saveEdit = () => {
    // Update the global action
    mutateAction({
      variables: {
        id: Number(action.a.id),
        name: actionName,
        isActive,
      },
    });
    handleClose();
  };

  const onCancel = () => {
    handleParentClose();
  };

  const unassociatedRisks = () => {
    const array = risks
      ? removeDuplicates(
          risks.filter(
            (r) =>
              associatedRisks.findIndex(
                (arm) => Number(arm.r.id) === Number(r.r.id)
              ) < 0 &&
              addedRisks.findIndex(
                (arm) => Number(arm.r.id) === Number(r.r.id)
              ) < 0 &&
              r.r.name.toLowerCase().includes(riskSearch.toLowerCase())
          ),
          "r",
          "id"
        ).sort((a, b) => (a.r.name > b.r.name ? 1 : -1))
      : [];

    const getRow = (item) => {
      return (
        <Grid
          item
          xs={12}
          key={item.id}
          style={{
            padding: "10px",
            height: "fit-content",
          }}
          container
          alignItems="center"
        >
          <Grid item xs={2}>
            <IconButton
              style={{ marginRight: "10px" }}
              onClick={() => add(item)}
            >
              <CheckBoxOutlineBlankIcon />
            </IconButton>
          </Grid>
          <Grid item xs={10}>
            <Typography>{item.r.name}</Typography>
          </Grid>
        </Grid>
      );
    };

    if (loadingActiveARMs)
      return (
        <Grid container justifyContent="center" sx={{ p: 2 }}>
          <CircularProgress color="secondary" />
        </Grid>
      );

    if (array.length === 0)
      return <Typography sx={{ p: 1 }}>No risks found.</Typography>;

    return (
      <Virtuoso
        style={{ height: "300px", width: "100%" }}
        data={array}
        itemContent={(index, item) => getRow(item)}
      />
    );
  };

  return (
    <>
      <DialogContent>
        <Grid
          sx={{
            backgroundColor: "#8297A0",
            textAlign: "center",
            borderRadius: "6px",
            padding: "10px",
          }}
        >
          <Typography sx={{ fontWeight: "500" }}>
            Currently editing ARMs for {company?.company.name}.
          </Typography>
        </Grid>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={10}>
            <Typography
              style={{
                ...pageTitleStyles,
              }}
            >
              EDIT ACTION: <span>{action.a.name}</span>.
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 2 }}
          >
            {user && user.currentUser.isStaff && (
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  style={{
                    zIndex: 1000,
                    ...yellowButton,
                    width: "fit-content",
                  }}
                  endIcon={<EditIcon />}
                  onClick={handleOpen}
                >
                  EDIT GLOBAL ACTION
                </Button>
              </Grid>
            )}
            <Grid item xs={8} container>
              {permissions.includes("ARM") && (
                <Grid item xs={4} container alignItems="center">
                  <FormControlLabel
                    style={{ color: "white", mr: 3 }}
                    control={
                      <CustomSwitch
                        checked={armIsActive}
                        onChange={(event) =>
                          setArmIsActive(event.target.checked)
                        }
                      />
                    }
                    label={armIsActive ? "ACTIVE" : "INACTIVE"}
                  />
                  <Tooltip
                    title="This toggle edits the status of the ARMs associated to this
                    action for this specific company. MAC Safety users - check the global action settings
                     if you need to change the name or status of the action itself."
                  >
                    <InfoOutlined />
                  </Tooltip>
                  <Typography variant="body"></Typography>
                </Grid>
              )}
              <Grid item xs={4} container alignItems="center">
                <RiskScoreInput
                  value={score}
                  onChange={(newVal) => {
                    setScore(newVal);
                    onSaveAction(newVal);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8} sx={{ pr: 4 }}>
            {permissions.includes("EDIT_ARM_RISK_SCORES") && (
              <Alert severity="info" style={{ marginBottom: "20px" }}>
                NOTE: If you update a risk&apos;s score, and one of its
                mitigator&apos;s scores was originally higher than this new
                score, the mitigator&apos;s score will be updated to equal this
                risk&apos;s new score. Make sure to update these scores if that
                is not desired.
              </Alert>
            )}
            <Typography style={{ color: "white" }}>
              ASSOCIATED RISKS:
            </Typography>
            {loading ? (
              <Grid container style={{ width: "100%" }} justifyContent="center">
                <CircularProgress color="secondary" />
              </Grid>
            ) : (
              <Grid
                container
                style={{
                  maxHeight: "300px",
                  overflowY: "auto",
                  backgroundColor: "white",
                  color: "black",
                  width: "100%",
                  marginTop: "10px",
                  borderRadius: 5,
                }}
              >
                {associatedRisks.length === 0 && addedRisks.length === 0 && (
                  <Typography style={{ padding: "20px" }}>
                    No associated risks.
                  </Typography>
                )}
                {[
                  ...associatedRisks,
                  ...addedRisks.map((r) => ({ ...r, added: true })),
                ]
                  .sort((a, b) => (a.r.name > b.r.name ? 1 : -1))
                  .map(
                    (risk) =>
                      risk.r && (
                        <AssociatedRisk
                          key={risk.r.id}
                          risk={risk}
                          remove={remove}
                          changeValue={
                            risk.added ? changeAddedValue : changeExistingValue
                          }
                          action={action}
                          mitigators={mitigators}
                          user={user}
                        />
                      )
                  )}
              </Grid>
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography style={{ color: "white" }}>
              UNASSOCIATED RISKS:
            </Typography>
            <SearchField value={riskSearch} setValue={setRiskSearch} />
            <Grid
              container
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                backgroundColor: "white",
                color: "black",
                width: "90%",
                marginTop: "10px",
                borderRadius: 5,
              }}
            >
              {unassociatedRisks()}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          style={{ marginRight: "10px", ...yellowButton }}
          onClick={onSave}
          loading={loadingMutateActionRisks}
        >
          SAVE
        </LoadingButton>
        <Button variant="contained" onClick={onCancel}>
          CANCEL
        </Button>
      </DialogActions>
      <Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose}>
        <DialogTitle style={{ ...pageTitleStyles, color: "black" }}>
          EDIT ACTION.
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems="center">
            {permissions.includes("ARM") && (
              <Grid item container alignItems="center">
                <FormControlLabel
                  style={{ color: "black" }}
                  control={
                    <CustomSwitch
                      checked={isActive}
                      onChange={(event) => setIsActive(event.target.checked)}
                    />
                  }
                  label={isActive ? "ACTIVE" : "INACTIVE"}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                variant="standard"
                value={actionName}
                label="Action Name:"
                onChange={(event) => setActionName(event.target.value)}
                style={{ width: "100%" }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ marginRight: "10px", ...yellowButton }}
            onClick={saveEdit}
          >
            SAVE
          </Button>
          <Button
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={handleClose}
          >
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
