import React from "react";

import { TextField } from "@mui/material";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";

import { useReactiveVar } from "@apollo/client";

import {
  createObservationVar,
  editObservationVar,
} from "../../../../graphql/localVariables/observation";
import { observationMutations } from "../../../../graphql/mutations";

export default function DateInputView({ type }) {
  // Global Variables
  const editObs = useReactiveVar(editObservationVar);
  const createObs = useReactiveVar(createObservationVar);
  const form = type === "EDIT" ? editObs : createObs;

  // Mutations
  const { updateKeyEditObservation, updateKeyCreateObservation } =
    observationMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditObservation : updateKeyCreateObservation;

  const handleChange = (time) => {
    updateObsKeyFunction("time", time);
  };

  return (
    <MobileTimePicker
      label="Time:"
      inputFormat="hh:mm A"
      value={form.time}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          style={{
            width: "100%",
          }}
          variant="standard"
        />
      )}
    />
  );
}
