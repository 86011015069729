import React from "react";

import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { useLazyQuery, useMutation } from "@apollo/client";

import { UPDATE_COMPANY_OBSERVED_SITES } from "../../../../../../graphql/mutations/admin/company";
import { GET_COMPANY } from "../../../../../../graphql/queries/admin/company";
import { GET_SITES } from "../../../../../../graphql/queries/admin/planning-project";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../../../CustomComponents/OnErrorFunction";
import { CustomTextField } from "../../../../../CustomStyles/LightTextField";
import { yellowButton } from "../../../../../CustomStyles/buttons";
import { CustomDialog } from "../../../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../../../CustomStyles/pageTitle";
import { COMPANY_ADMIN_CONTRACTED_SITES } from "../../../companyAdminQueries";

export default function CreateObservedSiteDialog({
  open,
  handleClose,
  company,
  data,
}) {
  const [location, setLocation] = React.useState({
    owner: { name: "" },
    site: { name: "" },
  });
  const [getSites, { data: sites }] = useLazyQuery(GET_SITES);
  const [editCompanyObservedSites] = useMutation(
    UPDATE_COMPANY_OBSERVED_SITES,
    {
      onCompleted() {
        setLocation({
          owner: { name: "" },
          site: { name: "" },
        });
        handleClose();
        onCompletedFunc("Observed sites have been updated");
      },
      onError(error) {
        onErrorFunc(error);
      },
      refetchQueries: [
        {
          query: GET_COMPANY,
          variables: {
            id: Number(company.id),
          },
        },
        {
          query: COMPANY_ADMIN_CONTRACTED_SITES,
          variables: { id: Number(company.id) },
        },
      ],
    }
  );

  const onDone = () => {
    handleClose();
    setLocation({
      owner: { name: "" },
      site: { name: "" },
    });
  };

  const onSave = () => {
    editCompanyObservedSites({
      variables: {
        id: Number(company.id),
        isContractor: true,
        ownerSiteProject: [
          ...data.map((d) => Number(d.ownerSiteProject.id)),
          Number(location.id),
        ],
      },
    });
  };

  React.useEffect(() => {
    if (open) {
      getSites();
    }
  }, [open]);

  const ospIds = data.map((d) => d.ownerSiteProject.id);

  return (
    <>
      <CustomDialog
        open={open}
        fullWidth
        maxWidth="md"
        sx={{ color: "#333" }}
        onClose={handleClose}
      >
        <DialogTitle style={{ ...pageTitleStyles }}>
          ADD OBSERVED SITES.
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            id="select-site-owner"
            limitTags={3}
            options={
              sites
                ? sites.ownerSiteProjects
                    .filter(
                      (osp) => osp.site && osp.owner && !ospIds.includes(osp.id)
                    )
                    .sort((a, b) => (a.site.name > b.site.name ? 1 : -1))
                : []
            }
            value={location}
            onChange={(event, value) => setLocation(value)}
            getOptionLabel={(option) =>
              option.owner.name !== ""
                ? `${option.site.name} [${option.owner.name}]`
                : ""
            }
            isOptionEqualToValue={(option, value) =>
              option.site.id === value.site.id &&
              option.owner.id === value.owner.id
            }
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {`${option.site.name} [${option.owner.name}]`}
              </li>
            )}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                style={{ color: "#fff" }}
                variant="standard"
                label={"Location:"}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ marginRight: "10px", ...yellowButton }}
            onClick={onSave}
          >
            SAVE
          </Button>
          <Button
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={onDone}
          >
            CANCEL
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
}
