import React from "react";

import { Grid } from "@mui/material";

import DateView from "./Date";
import HA from "./HA";
import IncidentType from "./IncidentType";
import Location from "./Location";
import Projects from "./Projects";
import TimeView from "./Time";

export default function General({ type, sites, projects }) {
  const padding = { padding: "10px 10px 50px 10px" };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} sx={padding}>
        <IncidentType type={type} />
      </Grid>
      <Grid item xs={12} md={4} sx={padding}>
        <DateView type={type} />
      </Grid>
      <Grid item xs={12} md={4} sx={padding}>
        <TimeView type={type} />
      </Grid>
      <Grid item xs={12} md={4} sx={padding}>
        <HA type={type} />
      </Grid>
      <Grid item xs={12} md={4} sx={padding}>
        <Location type={type} data={sites} />
      </Grid>
      <Grid item xs={12} md={4} sx={padding}>
        <Projects type={type} data={projects} />
      </Grid>
    </Grid>
  );
}
