import * as React from "react";

import { useQuery, useReactiveVar } from "@apollo/client";

import { onlineVar } from "../../../../../graphql/localVariables/user";
import { authMutations } from "../../../../../graphql/mutations";
import { GET_SITES_ADMIN } from "../../../../../graphql/queries/admin/planning-site";
import TableMain from "./ReusableTableLayout";
import ReusableTableToolbar from "./ReusableTableToolbar";
import TableBodyTemplate from "./TableBodyTemplate";
import TableHeadTemplate from "./TableHeadTemplate";
import { filterData } from "./searchFilterFunction";

// Props
// hasPagination - boolean
// data - array of objects
// 3 children component
//    If using the ReusableTableToolbar
//    - title - string - displayed at the top
//    - onDeleteClick - when trash can on toolbar is clicked
//    - onSearchChange - when input in search bar
//    - searchTerm - string
export default function Table({ data }) {
  const online = useReactiveVar(onlineVar);
  const { data: sites, loading } = useQuery(GET_SITES_ADMIN, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const { updateLoading } = authMutations;

  const onSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const onActiveChange = (event) => {
    setActiveFilter(event.target.checked);
  };

  const onSiteOwnerChange = (event, newValue) => {
    setSiteOwnerFilter(newValue || { name: "" });
  };

  const clearFilters = () => {
    setActiveFilter(true);
    setSiteOwnerFilter({ name: "" });
  };

  const [searchTerm, setSearchTerm] = React.useState("");
  const [active, setActiveFilter] = React.useState(true);
  const [siteOwner, setSiteOwnerFilter] = React.useState({ name: "" });

  const [filteredData, setFilteredData] = React.useState([]);

  // useEffect for filter update
  React.useEffect(() => {
    setFilteredData(
      filterData(
        sites ? sites.ownerSiteProjects : [],
        searchTerm,
        active,
        siteOwner
      )
    );
  }, [searchTerm, active, sites, siteOwner]);

  // show loading
  React.useEffect(() => {
    updateLoading(loading);
  }, [loading]);

  return (
    <TableMain
      CustomTableHeader={<TableHeadTemplate />}
      CustomTableBody={<TableBodyTemplate />}
      CustomTableToolBar={
        <ReusableTableToolbar
          searchTerm={searchTerm}
          onSearchChange={onSearchChange}
          active={active}
          onActiveChange={onActiveChange}
          siteOwner={siteOwner}
          onSiteOwnerChange={onSiteOwnerChange}
          clearFilters={clearFilters}
          title={"Sites"}
        />
      }
      hasPagination={true}
      data={filteredData}
      siteData={data}
    />
  );
}
