import React from "react";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Grid, IconButton, useTheme } from "@mui/material";

import DashboardCard from "./DashboardCard";
import useStyles from "./styles";

function getUnitForAudit(auditScore) {
  let unit;
  if (auditScore <= 999 || Number.isNaN(auditScore)) {
    unit = "";
  } else {
    auditScore = (auditScore / 1000).toFixed(1);
    unit = "K";
    if (auditScore > 999) {
      auditScore = (auditScore / 1000).toFixed(1);
      unit = "M";
    }
  }
  auditScore = Math.round(auditScore);

  return { unit, auditScore };
}

const MainCard = ({ card, index, activeIndex }) => {
  if (index >= activeIndex && index < activeIndex + 2) {
    return card;
  }
  return null;
};

export default function Dashboard({ auditScore, observationScore, jsaScore }) {
  const classes = useStyles()();
  const theme = useTheme();
  const [activeIndex, changeActive] = React.useState(0);

  const { unit, auditScore: newAuditScore } = getUnitForAudit(auditScore);
  const auditCard = (
    <Grid item xs={12} md={5} style={{ paddingTop: "0px" }}>
      <DashboardCard
        title="AUDIT"
        subtitle="Most Recent Fine"
        score={newAuditScore}
        unit={unit}
        link="/create-audit"
      />
    </Grid>
  );

  const observationCard = (
    <Grid item xs={12} md={5} style={{ paddingTop: "0px" }}>
      <DashboardCard
        title="OBSERVATION"
        subtitle="Recent Risk Mitigated"
        score={observationScore}
        link="/create-obs"
      />
    </Grid>
  );

  const jsaCard = (
    <Grid item xs={12} md={5} style={{ paddingTop: "0px" }}>
      <DashboardCard
        title="HAZARD ANALYSIS"
        subtitle="Recent HA Score"
        score={jsaScore}
        link="/create-ha"
      />
    </Grid>
  );

  const cardArray = [
    auditCard,
    observationCard,
    jsaCard,
    <Grid
      key="placeholder"
      item
      xs={12}
      md={5}
      style={{ paddingTop: "0px" }}
    ></Grid>,
  ];

  const handleRightClick = () => {
    if (activeIndex + 2 < cardArray.length) {
      changeActive(() => activeIndex + 2);
    } else {
      changeActive(0);
    }
  };

  const handleLeftClick = () => {
    if (activeIndex - 2 >= 0) {
      changeActive(() => activeIndex - 2);
    } else {
      let remainder = cardArray.length % 2;
      if (remainder === 0) {
        remainder = 2;
      }
      changeActive(cardArray.length - remainder);
    }
  };

  const renderDots = () => {
    let numDots = cardArray.length / 2;
    if (cardArray.length === 0) {
      numDots = 0;
    } else if (numDots < 1) {
      numDots = 1;
    } else {
      numDots = Math.ceil(numDots);
    }
    const arr = new Array(numDots).fill("");
    const currentPage = Math.floor(activeIndex / 2);
    return arr.map((dot, index) => {
      if (index === currentPage) {
        return (
          <div
            key="current"
            style={{
              backgroundColor: "#F3CA3E",
              borderRadius: 10,
              width: "10px",
              height: "10px",
              margin: "10px 5px",
            }}
          />
        );
      } else {
        return (
          <div
            key="other"
            style={{
              backgroundColor: "#CDCDCD",
              borderRadius: 10,
              width: "10px",
              height: "10px",
              margin: "10px 5px",
            }}
          />
        );
      }
    });
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.root}
      spacing={3}
    >
      <Grid item xs={1}>
        <IconButton onClick={handleLeftClick}>
          <ArrowBackIosNewIcon style={{ color: theme.palette.grey2.main }} />
        </IconButton>
      </Grid>
      {cardArray.map((card, index) => (
        <MainCard
          key={`card-${index}`}
          card={card}
          index={index}
          activeIndex={activeIndex}
        />
      ))}
      <Grid item xs={1} style={{ paddingLeft: "18px" }}>
        <IconButton onClick={handleRightClick}>
          <ArrowForwardIosIcon style={{ color: theme.palette.grey2.main }} />
        </IconButton>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: "0px" }}>
        <Grid container justifyContent="center">
          {renderDots()}
        </Grid>
      </Grid>
    </Grid>
  );
}
