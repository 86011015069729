import React from "react";

import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Drawer,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { withTheme } from "@mui/styles";

import { useQuery } from "@apollo/client";

import { GET_CURRENT_USER } from "../../graphql/queries/auth";
import DrawerItems from "./DrawerItems";
import MobileNav from "./MobileNav";
import useStyles from "./styles";

function SideNav({
  theme,
  drawerWidth,
  permissions,
  isSuperuser,
  isStaff,
  dismissable,
}) {
  const classes = useStyles(drawerWidth)();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { data } = useQuery(GET_CURRENT_USER, { fetchPolicy: "cache-first" });
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  return (
    <nav className={classes.drawer} aria-label="nav">
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        {dismissable ? (
          <>
            <AppBar position="fixed" open={open}>
              <Toolbar sx={{ backgroundColor: "black" }}>
                <Grid
                  container
                  alignItems="center"
                  style={{ paddingLeft: open ? "240px" : 0 }}
                >
                  <IconButton onClick={() => setOpen((prev) => !prev)}>
                    <MenuIcon style={{ color: "white" }} />
                  </IconButton>
                  <Typography variant="h6" noWrap component="div">
                    NIXN
                  </Typography>
                </Grid>
              </Toolbar>
            </AppBar>
            <Drawer
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                  boxSizing: "border-box",
                },
              }}
              PaperProps={{
                sx: {
                  backgroundColor: theme.palette.black0.main,
                },
              }}
              variant="temporary"
              anchor="left"
              open={open}
              onClose={toggleDrawer(false)}
            >
              <DrawerItems
                drawerWidth={drawerWidth}
                permissions={permissions}
                isSuperuser={isSuperuser}
                isStaff={isStaff}
                user={data}
              />
            </Drawer>
          </>
        ) : (
          <Drawer
            variant="permanent"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            classes={{
              paper: classes.drawerPaper,
            }}
            PaperProps={{
              sx: {
                backgroundColor: theme.palette.black0.main,
              },
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <DrawerItems
              drawerWidth={drawerWidth}
              permissions={permissions}
              isSuperuser={isSuperuser}
              isStaff={isStaff}
              user={data}
            />
          </Drawer>
        )}
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <MobileNav
          permissions={permissions}
          isSuperuser={isSuperuser}
          isStaff={isStaff}
          isMobile={mobile}
          user={data}
        />
      </Box>
    </nav>
  );
}

export default withTheme(SideNav);
