import * as React from "react";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import ReusableTableHeadCell from "./ReusableTableHeadCell";

// Props
// order, orderBy, numSelected, onRequestSort, onSelectAllClick, rowCount - from ReusableMainTable
export default function TableHeadTemplate(props) {
  const { order, orderBy, onRequestSort } = props;

  // if filtering is available
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <ReusableTableHeadCell
              order={order}
              orderBy={orderBy}
              headCell={headCell}
              createSortHandler={createSortHandler}
              key={headCell.uid}
            />
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const headCells = [
  {
    uid: "name",
    numeric: false,
    disablePadding: false,
    label: "OBS ID",
    sort: true,
  },
  {
    uid: "date",
    numeric: true,
    disablePadding: false,
    label: "Date",
    sort: true,
  },
  {
    uid: "outcome",
    numeric: true,
    disablePadding: false,
    label: "Outcome",
    sort: false,
  },
  {
    uid: "Submitter Username",
    numeric: true,
    disablePadding: false,
    label: "Submitter Username",
    sort: true,
  },
  {
    uid: "Contractor Observed",
    numeric: true,
    disablePadding: false,
    label: "Contractor Observed",
    sort: true,
  },
  {
    uid: "Planned Status",
    numeric: true,
    disablePadding: false,
    label: "Planned Status",
    sort: false,
  },
  {
    uid: "Location",
    numeric: true,
    disablePadding: false,
    label: "Location",
    sort: false,
  },
  {
    uid: "HA Name",
    numeric: true,
    disablePadding: false,
    label: "HA Name",
    sort: true,
  },
];
