import React from "react";

import { Grid } from "@mui/material";

import { useReactiveVar } from "@apollo/client";

import { editHAVar, createHAVar } from "../../../graphql/localVariables/ha";
import ActionView from "./ActionView";

export default function ActionTableView({
  type,
  actions: allActions,
  risks,
  mitigators: mit,
  loadingActions,
  loadingRisks,
  loadingMit,
}) {
  const editHA = useReactiveVar(editHAVar);
  const createHA = useReactiveVar(createHAVar);
  const updateHA = type === "EDIT" ? editHA : createHA;

  const [actions, setActions] = React.useState(updateHA.actions);

  React.useEffect(() => {
    setActions(updateHA.actions);
  }, [updateHA.actions]);

  React.useEffect(() => {
    const unique = (arr) =>
      arr.reduce((results, item) => {
        if (
          !results.some((i) => i.a.name === item.a.name && i.a.id === item.a.id)
        ) {
          results.push(item);
        }
        return results;
      }, []);
    setActions(unique(actions));
  }, [mit]);

  return (
    <Grid
      container
      style={{
        width: "100%",
        height: "100%",
        overflowY: "auto", // option for smaller screens
      }}
    >
      {actions
        .sort((a, b) => (a.actionOrdering > b.actionOrdering ? 1 : -1))
        .map((a) => {
          return (
            <ActionView
              type={type}
              key={a.id}
              action={a}
              mit={mit}
              risks={risks}
              actions={allActions}
              loadingRisks={loadingRisks}
              loadingMit={loadingMit}
            />
          );
        })}
    </Grid>
  );
}
