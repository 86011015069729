import { gql } from "@apollo/client";

export const GET_AUTH = gql`
  query GetAuth {
    auth @client {
      isAuthenticated
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query currentUser {
    currentUser {
      id
      username
      firstName
      lastName
      email
      isSuperuser
      isStaff
      image
      receiveOtherReports
      company {
        id
        name
        riskThreshold
        industry {
          name
          industrialClassification {
            classification {
              name
            }
          }
        }
      }
      userPermissionGroups(isActive: true) {
        permissionGroup {
          permissionGroupPermissions(isActive: true) {
            permission {
              name
            }
          }
        }
      }
    }
  }
`;
