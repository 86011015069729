import * as React from "react";
import { useNavigate } from "react-router-dom";

import { CircularProgress, Grid } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import moment from "moment";

export default function TableBodyTemplate(props) {
  // these props are available in table body
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    getComparator,
    stableSort,
    data,
    loading,
  } = props;
  const navigate = useNavigate();

  if (loading) {
    return (
      <TableRow>
        <TableCell style={{ borderBottom: "transparent" }} colSpan={13}>
          <Grid container justifyContent="center" alignItems="center">
            <CircularProgress color="secondary" />
          </Grid>
        </TableCell>
      </TableRow>
    );
  }

  if (
    stableSort(data, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    ).length === 0
  ) {
    return (
      <TableRow>
        <TableCell style={{ borderBottom: "transparent" }} colSpan={13}>
          No Results Found
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {stableSort(data, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => {
          const d = new Date(row.date);
          const utc = d.getTime() + d.getTimezoneOffset() * 60000;
          const convertedDate = new Date(utc);

          return (
            <TableRow
              hover
              onClick={() => navigate(`/view-ha/${row.haId}`)}
              key={row.id}
              style={{ cursor: "pointer" }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell>{`${moment(convertedDate).format(
                "MM/DD/YYYY"
              )}`}</TableCell>
              <TableCell>{row.submitter?.username}</TableCell>
              <TableCell>{row.submitter?.company?.name}</TableCell>
              <TableCell>
                {row.haOwnerSiteProject && row.haOwnerSiteProject.length > 0
                  ? `${row.haOwnerSiteProject[0].ownerSiteProject.site.name} 
                  [${row.haOwnerSiteProject[0].ownerSiteProject.owner.name}]`
                  : ""}
              </TableCell>
              <TableCell>
                {row.haOwnerSiteProject
                  .map((o) => o.ownerSiteProject.project.name)
                  .join(", ")}
              </TableCell>
              <TableCell>
                {row.haTemplate?.map((o) => o.template.name).join(", ")}
              </TableCell>
              <TableCell>{row.isCompleted ? "Closed" : "Open"}</TableCell>
              <TableCell>{row.revisionNumber}</TableCell>
              <TableCell>{row.hasInjury ? "With" : "Without"}</TableCell>
              <TableCell>{row.haCrewMembers?.length}</TableCell>
              <TableCell>{+row.raScore.toFixed(2)}</TableCell>
            </TableRow>
          );
        })}
    </>
  );
}
