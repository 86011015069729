import * as React from "react";

import TableMain from "./ReusableTableLayout";
import ReusableTableToolbar from "./ReusableTableToolbar";
import TableBodyTemplate from "./TableBodyTemplate";
import TableHeadTemplate from "./TableHeadTemplate";
import { filterData } from "./searchFilterFunction";

// Props
// hasPagination - boolean
// data - array of objects
// 3 children component
//    If using the ReusableTableToolbar
//    - title - string - displayed at the top
//    - onDeleteClick - when trash can on toolbar is clicked
//    - onSearchChange - when input in search bar
//    - searchTerm - string
export default function Table({ data }) {
  const onSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const onSiteChange = (event, newValue) => {
    setSiteFilter(newValue || { owner: { name: "" } });
  };

  const onAfterDateChange = (event) => {
    setAfterDate(event);
  };

  const onBeforeDateChange = (event) => {
    setBeforeDate(event);
  };

  const onProjectChange = (event, newValue) => {
    setProjectFilter(newValue || []);
  };

  const onTemplateChange = (event, newValue) => {
    setTemplateFilter(newValue || []);
  };

  const onStatusChange = (event, newValue) => {
    setStatusFilter(newValue || "");
  };

  const onIncidentChange = (event, newValue) => {
    setIncidentFilter(newValue || undefined);
  };

  const clearFilters = () => {
    setSiteFilter({ owner: { name: "" } });
    setAfterDate(null);
    setBeforeDate(null);
    setProjectFilter([]);
    setTemplateFilter([]);
    setStatusFilter("");
    setIncidentFilter("");
  };

  const [searchTerm, setSearchTerm] = React.useState("");
  const [siteFilter, setSiteFilter] = React.useState({ owner: { name: "" } });
  const [afterDate, setAfterDate] = React.useState(null);
  const [beforeDate, setBeforeDate] = React.useState(null);

  const [projectFilter, setProjectFilter] = React.useState([]);
  const [templateFilter, setTemplateFilter] = React.useState([]);
  const [status, setStatusFilter] = React.useState("");
  const [incident, setIncidentFilter] = React.useState("");

  const [filteredData, setFilteredData] = React.useState(data);

  // useEffect for filter update
  React.useEffect(() => {
    setFilteredData(
      filterData(
        data,
        searchTerm,
        siteFilter,
        afterDate,
        beforeDate,
        projectFilter,
        templateFilter,
        status,
        incident
      )
    );
  }, [
    searchTerm,
    siteFilter,
    afterDate,
    beforeDate,
    projectFilter,
    templateFilter,
    status,
    incident,
    data,
  ]);

  return (
    <TableMain
      CustomTableHeader={<TableHeadTemplate />}
      CustomTableBody={<TableBodyTemplate />}
      CustomTableToolBar={
        <ReusableTableToolbar
          searchTerm={searchTerm}
          onSearchChange={onSearchChange}
          site={siteFilter}
          onSiteChange={onSiteChange}
          onAfterDateChange={onAfterDateChange}
          afterDate={afterDate}
          onBeforeDateChange={onBeforeDateChange}
          beforeDate={beforeDate}
          projectFilter={projectFilter}
          onProjectChange={onProjectChange}
          templateFilter={templateFilter}
          onTemplateChange={onTemplateChange}
          status={status}
          onStatusChange={onStatusChange}
          incident={incident}
          onIncidentChange={onIncidentChange}
          clearFilters={clearFilters}
          title={"Hazard Analyses"}
        />
      }
      hasPagination={true}
      data={filteredData}
    />
  );
}
