import { gql } from "@apollo/client";

export function createUpdateKeyCreateObservation(createObservationVar) {
  return (key, value) => {
    const obsCopy = { ...createObservationVar() };
    obsCopy[key] = value;

    createObservationVar(obsCopy);
  };
}

export function createUpdateAllCreateObservation(createObservationVar) {
  return (value) => {
    createObservationVar(value);
  };
}

export function createUpdateKeyEditObservation(editObservationVar) {
  return (key, value) => {
    const obsCopy = { ...editObservationVar() };
    obsCopy[key] = value;

    editObservationVar(obsCopy);
  };
}

export function createUpdateAllEditObservation(editObservationVar) {
  return (value) => {
    editObservationVar(value);
  };
}

export function createUpdateKeyViewObservationFilter(viewObsFilterVar) {
  return (key, value) => {
    const obsCopy = { ...viewObsFilterVar() };
    obsCopy[key] = value;

    viewObsFilterVar(obsCopy);
  };
}

export function createUpdateAllViewObservationFilter(viewObsFilterVar) {
  return (value) => {
    viewObsFilterVar(value);
  };
}

/**
 * Observation create mutation format
 * Example: 
    mutation {
      observationCreate (input:{
        ownerSiteProject: 1,      // id of ownerSiteProject
        contractor: 1,            // id of company
        ha: 1,                    // id of hazardAnalysis
        date: "2022-12-22",       // // string of format "YYYY-MM-DD"
        time: "23:59:59",         // // string of format "HH:MM:SS"
        correctionsGiven: true,
        raScore: 80,
        raScoreMax: 98,
        raScoreCorr: 90,
        raScoreMin: 75,
        manualCheck: true,
        outcomeExpected: false,
        outcomeInjury: true,
        outcomeNearMiss: false,
        arms: [
          {id: 1, isRootCause: true},                      // id of actionRiskMitigator
          {id: 3, actionOrdering: 1, isCorrection: true}    // id of actionRiskMitigator, the action with ordering
        ],
        outcomes: [
          {id: 1},                      // id of outcome
        ]
      }) {
        observation{id}, message{success, errors, info}
      }
    }
 */
export const OBSERVATION_CREATE = gql`
  mutation CreateObservation($input: CreateObservationInput!) {
    observationCreate(input: $input) {
      observation {
        id
        obsId
      }
      message {
        success
        errors
        info
      }
    }
  }
`;

export const OBSERVATION_EDIT = gql`
  mutation EditObservation($id: ID!, $input: UpdateObservationInput!) {
    observationEdit(id: $id, input: $input) {
      observation {
        id
        obsId
      }
      message {
        success
        errors
        info
      }
    }
  }
`;

export const OBSERVATION_EMAIL = gql`
  mutation emailObservation($id: ID, $file: Upload!) {
    observationEmail(id: $id, file: $file) {
      message {
        success
        errors
        info
      }
    }
  }
`;

/**
 * Observation edit mutation format
 * Example: 
    mutation {
      observationEdit (id: 132, input:{   // the id of the editing observation
        ownerSiteProject: 1,      // id of ownerSiteProject
        contractor: 1,            // id of company
        ha: 1,                    // id of hazardAnalysis
        date: "2022-12-22",       // string of format "YYYY-MM-DD"
        time: "23:59:59",         // string of format "HH:MM:SS"

        correctionsGiven: true,
        raScore: 80,
        raScoreMax: 98,
        raScoreCorr: 90,
        raScoreMin: 75,
        manualCheck: true,
        outcomeExpected: false,
        outcomeInjury: true,
        outcomeNearMiss: false,
        arms: [
          {id: 1},                      // id of actionRiskMitigator
          {id: 3, actionOrdering: 1}    // id of actionRiskMitigator, the action with ordering
        ],
        corrections: [
          {id: 1, isRootCause: true},   // id of actionRiskMitigator, if selected as root cause
          {id: 2}
        ],
        outcomes: [
          {id: 1},                      // id of outcome
        ]
      }) {
        observation{id}, message{success, errors, info}
      }
    }
 */

export const MARK_OBSERVATION_USER_NOTIFIED = gql`
  mutation editObservationUser($input: ObservationUserMutationInput!) {
    observationUser(input: $input) {
      observationUser {
        id
        user {
          username
        }
        observation {
          id
          name
        }
        notified
      }
    }
  }
`;
