import React from "react";
import { useNavigate } from "react-router-dom";

import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import {
  Autocomplete,
  Avatar,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";

import { useMutation, useLazyQuery, useReactiveVar } from "@apollo/client";
import { DropzoneDialog } from "mui-file-dropzone";

import { onlineVar } from "../../../../graphql/localVariables/user";
import { CREATE_COMPANY } from "../../../../graphql/mutations/admin/company";
import {
  GET_ACTIVE_INDUSTRIES,
  GET_COMPANIES,
  GET_OWNER_SITES,
} from "../../../../graphql/queries/admin/company";
import { GET_SEARCH_TEMPLATES } from "../../../../graphql/queries/ha";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../CustomComponents/OnErrorFunction";
import { CustomSwitch } from "../../../CustomComponents/Switch";
import { CustomTextField } from "../../../CustomStyles/LightTextField";
import { defaultAvatar } from "../../../CustomStyles/avatar";
import { yellowButton } from "../../../CustomStyles/buttons";
import { CustomDialog } from "../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../CustomStyles/pageTitle";
import { TemplateAccordion } from "../CompanyAccordions";

export default function CreateCompany({ open, setOpen }) {
  const online = useReactiveVar(onlineVar);
  const navigate = useNavigate();
  const [getIndustries, { data: industries }] = useLazyQuery(
    GET_ACTIVE_INDUSTRIES,
    {
      fetchPolicy: online ? "network-only" : "cache-only",
    }
  );
  const [getCompanies, { data: companies }] = useLazyQuery(GET_COMPANIES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const [getOwnerSites, { data: sites }] = useLazyQuery(GET_OWNER_SITES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const [getTemplates, { data: templates }] = useLazyQuery(
    GET_SEARCH_TEMPLATES,
    {
      fetchPolicy: online ? "network-only" : "cache-only",
    }
  );

  React.useEffect(() => {
    if (open) {
      getTemplates();
      getOwnerSites();
      getCompanies();
      getIndustries();
    }
  }, [open]);

  const [createCompany] = useMutation(CREATE_COMPANY, {
    onCompleted(data) {
      if (data.companyCreateOrEditMutation.company.id) {
        reset();
        setOpen(false);
        onCompletedFunc("Company has been created");
        navigate(
          `/admin/company/${data.companyCreateOrEditMutation.company.id}`
        );
      }
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [
      {
        query: GET_COMPANIES,
      },
    ],
  });
  const [openUpload, setOpenUpload] = React.useState(false);

  const [name, setName] = React.useState("");
  const [logo, setLogo] = React.useState("");
  const [classification, setClassification] = React.useState({ name: "" });
  const [industry, setIndustry] = React.useState({
    industry: { name: "", code: "" },
  });
  const [riskThreshold, setRiskThreshold] = React.useState("");
  const [observable, setObservable] = React.useState(false);
  const [observableSites, setObservableSites] = React.useState([]);
  const [ownedParent, setOwnedParent] = React.useState(false);
  const [parent, setParent] = React.useState({ name: "" });
  const [hasChild, setHasChild] = React.useState(false);
  const [children, setChildren] = React.useState([]);

  const [addedTemplates, setAddedTemplates] = React.useState([]);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const reset = () => {
    setName("");
    setLogo("");
    setRiskThreshold("");
    setClassification({ name: "" });
    setIndustry({ industry: { name: "", code: "" } });
    setObservable(false);
    setObservableSites([]);
    setOwnedParent(false);
    setParent({ name: "" });
    setHasChild(false);
    setChildren([]);
    setAddedTemplates([]);
  };

  const getBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(file);
  };

  const onSave = () => {
    const payload = {
      isActive: true,
      name,
      rt: Number(riskThreshold),
      ao: false,
      childCompanies: children.map((c) => Number(c.id)),
      associatedTemplate: addedTemplates.map((t) => Number(t.id)),
    };

    if (logo !== "") {
      getBase64(logo, function (base64Data) {
        payload["logo"] = base64Data.split(",")[1];
      });
    }

    if (parent.id) {
      payload["parent"] = Number(parent.id);
    }

    if (industry.industry.id) {
      payload["industry"] = Number(industry.industry.id);
    }

    if (observable && observableSites.length > 0) {
      payload["isContractor"] = observable;
      payload["ownerSiteProject"] = observableSites.map((c) => Number(c.id));
    } else {
      payload["isContractor"] = false;
    }

    createCompany({
      variables: payload,
    });
  };

  const onCancel = () => {
    setOpen(false);
    reset();
  };

  const handleSave = (photo) => {
    setOpenUpload(false);
    setLogo(photo[0]);
  };

  const isImage = (file) => {
    if (file.type && file.type.split("/")[0] === "image") {
      return true;
    }
  };

  return (
    <>
      <CustomDialog
        open={open}
        fullWidth
        maxWidth="md"
        sx={{ color: "#333" }}
        onClose={() => setOpen(false)}
      >
        <DialogTitle style={{ ...pageTitleStyles }}>
          CREATE COMPANY.
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid
              item
              xs={3}
              container
              direction="column"
              alignItems="center"
              style={{ margin: "20px 0px" }}
            >
              <Avatar
                alt={`${name}`}
                src={
                  isImage(logo) && logo.type ? URL.createObjectURL(logo) : ""
                }
                sx={{
                  ...defaultAvatar,
                  width: "75px",
                  margin: "auto",
                  height: "75px",
                  fontSize: "2.5rem",
                  marginBottom: "20px",
                }}
              />
              <Button style={yellowButton} onClick={() => setOpenUpload(true)}>
                CHANGE LOGO
              </Button>
              <DropzoneDialog
                open={openUpload}
                onSave={handleSave}
                acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                showPreviews={true}
                filesLimit={1}
                onClose={() => setOpenUpload(false)}
                maxFileSize={10000000} // 10MB
              />
            </Grid>
            <Grid item xs={9} container alignItems="center">
              <CustomTextField
                label="Company Name:"
                variant="standard"
                style={{ margin: "10px 0px", width: "75%", color: "#fff" }}
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </Grid>
            <Autocomplete
              id="select-classification"
              options={industries ? industries.classifications : []}
              value={classification}
              onChange={(event, value) => {
                setClassification(value);
                setIndustry({ industry: { name: "", code: "" } });
              }}
              getOptionLabel={(option) => option.name}
              disableClearable
              style={{ width: "45%", marginRight: "10px" }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  style={{ color: "#fff" }}
                  variant="standard"
                  label={"Classification:"}
                />
              )}
            />
            <Autocomplete
              id="select-industry"
              options={
                classification.industrialClassifications
                  ? classification.industrialClassifications
                  : []
              }
              value={industry}
              onChange={(event, value) => setIndustry(value)}
              getOptionLabel={(option) =>
                option.industry.name !== ""
                  ? `${option.industry.code} - ${option.industry.name}`
                  : ""
              }
              disableClearable
              style={{ width: "45%" }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  style={{ color: "#fff" }}
                  variant="standard"
                  label={"Industry:"}
                />
              )}
              disabled={classification.name === ""}
            />
            <CustomTextField
              label="Risk Threshold:"
              variant="standard"
              style={{ margin: "10px 0px", width: "75%", color: "#fff" }}
              value={riskThreshold}
              onChange={(event) => setRiskThreshold(event.target.value)}
            />
            <Grid
              item
              container
              alignItems="center"
              style={{ marginTop: "20px" }}
            >
              <Typography>Is observable?</Typography>
              <FormControlLabel
                style={{ color: "white", marginLeft: "10px" }}
                control={
                  <CustomSwitch
                    checked={observable}
                    onChange={(event) => setObservable(event.target.checked)}
                    light
                  />
                }
                label={observable ? "YES" : "NO"}
              />
            </Grid>
            {observable && (
              <Autocomplete
                id="select-observable-sites"
                limitTags={3}
                options={
                  sites
                    ? sites.ownerSiteProjects.sort((a, b) =>
                        a.site.name > b.site.name ? 1 : -1
                      )
                    : []
                }
                value={observableSites}
                onChange={(event, value) => setObservableSites(value)}
                getOptionLabel={(option) =>
                  `${option.site.name} [${option.owner.name}]`
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disableClearable
                style={{ width: "75%" }}
                multiple
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.site.name} [{option.owner.name}]
                  </li>
                )}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    style={{ color: "#fff" }}
                    variant="standard"
                    label={"Observable Sites:"}
                  />
                )}
              />
            )}
            <Grid
              item
              container
              alignItems="center"
              style={{ marginTop: "20px" }}
            >
              <Typography>Owned by a parent company?</Typography>
              <FormControlLabel
                style={{ color: "white", marginLeft: "10px" }}
                control={
                  <CustomSwitch
                    checked={ownedParent}
                    onChange={(event) => setOwnedParent(event.target.checked)}
                    light
                  />
                }
                label={ownedParent ? "YES" : "NO"}
              />
            </Grid>
            {ownedParent && (
              <Autocomplete
                id="select-parent-company"
                // not any of the children
                options={
                  companies
                    ? companies.companies
                        .filter(
                          (c) =>
                            children.findIndex((child) => child.id === c.id) < 0
                        )
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                    : []
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={parent}
                onChange={(event, value) => setParent(value)}
                getOptionLabel={(option) => option.name}
                disableClearable
                style={{ width: "75%" }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    variant="standard"
                    label={"Parent Company:"}
                  />
                )}
              />
            )}
            <Grid
              item
              container
              alignItems="center"
              style={{ marginTop: "20px" }}
            >
              <Typography>Owns a child company?</Typography>
              <FormControlLabel
                style={{ color: "white", marginLeft: "10px" }}
                control={
                  <CustomSwitch
                    checked={hasChild}
                    onChange={(event) => setHasChild(event.target.checked)}
                    light
                  />
                }
                label={hasChild ? "YES" : "NO"}
              />
            </Grid>
            {hasChild && (
              <Autocomplete
                id="select-child-companies"
                // not parent or any company already with parent
                limitTags={3}
                options={
                  companies
                    ? companies.companies.filter(
                        (c) => parent.id !== c.id && !c.parent
                      )
                    : []
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={children}
                onChange={(event, value) => setChildren(value)}
                getOptionLabel={(option) => option.name}
                disableClearable
                style={{ width: "75%" }}
                multiple
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    variant="standard"
                    label={"Child Companies:"}
                  />
                )}
              />
            )}
          </Grid>
          <TemplateAccordion
            expanded={expanded}
            handleChange={handleChange}
            companyTemplates={[]}
            setCompanyTemplates={() => {}}
            addedTemplates={addedTemplates}
            setAddedTemplates={setAddedTemplates}
            setRemovedTemplates={() => {}}
            templates={templates}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{
              ...yellowButton,
              height: "fit-content",
              marginRight: "10px",
            }}
            onClick={onSave}
          >
            SAVE
          </Button>
          <Button
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={onCancel}
          >
            CANCEL
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
}
