import React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { pageTitleStyles } from "../../../../../../CustomStyles/pageTitle";
import { CustomDialog } from "../../../../../../CustomStyles/dialog";
import Table from "./Table";

export default function ViewProjects({ open, setOpen, title, data, company }) {
  const onDone = () => {
    setOpen(false);
  };

  return (
    <>
      <CustomDialog
        open={open}
        fullWidth
        maxWidth="md"
        sx={{ color: "#333" }}
        onClose={() => setOpen(false)}
      >
        <DialogTitle style={{ ...pageTitleStyles }}>
          VIEW {title.toUpperCase()}.
        </DialogTitle>
        <DialogContent>
          <Table data={data} company={company} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={onDone}
          >
            DONE
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
}
