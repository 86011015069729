import React from "react";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { CustomDialog } from "../../../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../../../CustomStyles/pageTitle";
import CreateNewUser from "./CreateNewUser";

export default function CreateUserDialog({ open, setOpen, company }) {
  const onDone = () => {
    setOpen(false);
  };

  return (
    <>
      <CustomDialog
        open={open}
        fullWidth
        maxWidth="md"
        sx={{ color: "#333" }}
        onClose={() => setOpen(false)}
      >
        <DialogTitle style={{ ...pageTitleStyles }}>ADD USER.</DialogTitle>
        <DialogContent>
          <CreateNewUser companyId={company.id} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={onDone}
          >
            DONE
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
}
