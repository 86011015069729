import { gql } from "@apollo/client";

// ARMS
export const GET_COMPANY_ARMS = gql`
  query companyArmAdmin($companyId: Int, $rNull: Boolean, $mNull: Boolean) {
    companyArms(
      company_Id: $companyId
      isActive: true
      arm_IsActive: true
      arm_M_Isnull: $mNull
      arm_R_Isnull: $rNull
    ) {
      isActive
      arm {
        isActive
        isExpected
        riskScore
        id
        m {
          id
          name
          isActive
        }
        a {
          id
          name
          isActive
        }
        r {
          id
          name
          isActive
        }
      }
    }
  }
`;

export const GET_COMPANY_ARMS_NO_RBAC = gql`
  query companyArmsNoRBAC($companyId: Int, $rNull: Boolean, $mNull: Boolean) {
    companyArmsView(
      isActive: true
      company_Id: $companyId
      arm_M_Isnull: $mNull
      arm_R_Isnull: $rNull
    ) {
      arm {
        id
        isActive
        a {
          id
          name
        }
        r {
          id
          name
        }
        m {
          id
          name
        }
      }
    }
  }
`;

export const GET_ACTIONS = gql`
  query actions {
    actionRiskMitigators(
      m_Isnull: true
      r_Isnull: true
      isActive: true
      a_IsActive: true
    ) {
      isActive
      isExpected
      riskScore
      id
      m {
        id
        name
      }
      a {
        id
        name
      }
      r {
        id
        name
      }
    }
  }
`;

export const GET_RISKS = gql`
  query risks {
    actionRiskMitigators(
      m_Isnull: true
      r_Isnull: false
      a_Isnull: false
      isActive: true
      r_IsActive: true
      a_IsActive: true
    ) {
      isExpected
      isActive
      riskScore
      id
      m {
        id
        name
      }
      a {
        id
        name
      }
      r {
        id
        name
      }
    }
  }
`;

export const GET_MITIGATORS = gql`
  query mitigators {
    actionRiskMitigators(
      m_Isnull: false
      r_Isnull: false
      a_Isnull: false
      isActive: true
      m_IsActive: true
      r_IsActive: true
      a_IsActive: true
    ) {
      isActive
      isExpected
      riskScore
      id
      m {
        id
        name
      }
      a {
        id
        name
      }
      r {
        id
        name
      }
    }
  }
`;

// CONTRACTORS
export const GET_CONTRACTORS_AT_OWNER_SITE_PROJECT = gql`
  query ownerSiteProjectContractor {
    ownerSiteProjectContractors(isActive: true) {
      ownerSiteProject {
        project {
          id
        }
        site {
          id
        }
        owner {
          id
        }
      }
      contractor {
        id
        name
      }
    }
  }
`;

export const GET_LOCAL_CONTRACTORS_AT_OWNER_SITE_PROJECT = gql`
  query contractorsOSP($ownerId: Int, $siteId: Int) {
    ownerSiteProjectContractors(
      ownerSiteProject_Owner_Id: $ownerId
      ownerSiteProject_Site_Id: $siteId
      isActive: true
      contractor_IsActive: true
      ownerSiteProject_IsActive: true
      ownerSiteProject_Owner_IsActive: true
      ownerSiteProject_Site_IsActive: true
      ownerSiteProject_Project_Isnull: true
    ) {
      id
      contractor {
        id
        name
      }
    }
  }
`;

export const GET_CONTRACTORS_AT_OSPS = gql`
  query contractorsOSP($ownerId: [Int], $siteId: [Int]) {
    contractors: ownerSiteProjectContractors(
      ownerSiteProject_Owner_Id_In: $ownerId
      ownerSiteProject_Site_Id_In: $siteId
      isActive: true
      contractor_IsActive: true
      ownerSiteProject_IsActive: true
      ownerSiteProject_Owner_IsActive: true
      ownerSiteProject_Site_IsActive: true
      ownerSiteProject_Project_Isnull: true
    ) {
      id
      contractor {
        id
        name
      }
    }
  }
`;

// COMPANIES
export const GET_COMPANIES = gql`
  query companies {
    companies(isActive: true) {
      id
      name
      logoBase64
      ownerSiteProjectContractor {
        contractor {
          id
        }
      }
      isContractor @client
    }
  }
`;

export const GET_COMPANY_TREE = gql`
  query companies {
    companies(isActive: true, isDashboard: true) {
      id
      name
      logoBase64
    }
  }
`;

// CREWMEMBERS
export const GET_CREWMEMBERS = gql`
  query crewMembers($ospId: Int) {
    ownerSiteProjectCrewMembers(
      ownerSiteProject_Id: $ospId
      crewMember_IsActive: true
      isActive: true
    ) {
      ownerSiteProject {
        id
      }
      crewMember {
        lastName
        id
        firstName
        lastUpdated
        company {
          name
        }
      }
    }
  }
`;

export const GET_FILTERED_CREWMEMBERS = gql`
  query crewmembersByOSP($ospId: Int) {
    ownerSiteProjectCrewMembers(
      ownerSiteProject_Id: $ospId
      crewMember_IsActive: true
      isActive: true
    ) {
      crewMember {
        id
        firstName
        lastName
        signatureBase64
        phoneNumber
        lastUpdated
      }
    }
  }
`;

// HA
export const GET_RECENT_HAS = gql`
  query recentHAs($after_date: Date) {
    hazardAnalyses(isActive: true, first: 100, fAfterDate: $after_date) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          name
          id
          haId
          revisionNumber
          comment
          haOwnerSiteProject {
            ownerSiteProject {
              id
              owner {
                id
                name
              }
              site {
                id
                name
              }
              project {
                id
                name
              }
            }
          }
          haTemplate {
            template {
              name
            }
          }
          submitter {
            id
            username
            company {
              name
            }
          }
          haCrewMembers(isActive: true) {
            id
            crewMember {
              id
              firstName
              lastName
              jobTitle {
                id
                name
              }
              phoneNumber
              lastUpdated
            }
          }
          observation {
            edges {
              node {
                obsId
              }
            }
          }
          date
          time
          raScore
          hasInjury
          isCompleted
        }
      }
    }
  }
`;

export const GET_OBS_HAS = gql`
  query recentObsHAs {
    hazardAnalyses(isActive: true, first: 400) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          name
          haId
          id
          revisionNumber
          comment
          haOwnerSiteProject {
            ownerSiteProject {
              id
              owner {
                id
                name
              }
              site {
                id
                name
              }
              project {
                id
                name
              }
            }
          }
          haTemplate {
            template {
              name
            }
          }
          submitter {
            id
            username
          }
          date
          time
          raScore
          hasInjury
          isCompleted
          ospId @client
        }
      }
    }
  }
`;

export const GET_HA = gql`
  query HA($id: Int!) {
    hazardAnalysis(id: $id) {
      name
      id
      haId
      date
      time
      submitter {
        username
      }
      haOwnerSiteProjects {
        ownerSiteProject {
          id
          owner {
            id
            name
          }
          site {
            id
            name
          }
          project {
            id
            name
          }
        }
      }
      haArms {
        arm {
          id
          a {
            id
            name
          }
          r {
            id
            name
          }
          m {
            id
            name
          }
        }
      }
    }
  }
`;

// OBSERVATIONS
export const GET_RECENT_OBSERVATIONS = gql`
  query recentObservations($after_date: Date, $first: Int) {
    observations(isActive: true, first: $first, fAfterDate: $after_date) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          obsId
          id
          name
          date
          time
          raScoreMax
          submitter {
            id
            username
          }
          ha {
            name
            date
          }
          observationOutcomes(isActive: true) {
            outcome {
              name
            }
          }
          contractor {
            name
          }
          manualCheck
          ownerSiteProject {
            owner {
              id
              name
            }
            site {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_OBSERVATION_USERS = gql`
  query observationUsers($id: Int) {
    observationUsers(isActive: true, user_Id: $id) {
      id
      timeCreated
      notification
      notified
      user {
        id
      }
      observation {
        id
        obsId
        name
      }
    }
  }
`;

export const GET_NOTIFICATION = gql`
  query notification($id: Int) {
    observationUser(id: $id) {
      id
      timeCreated
      notification
      notified
      user {
        id
      }
      observation {
        id
        obsId
        name
        comment
        timeCreated
        submitter {
          username
        }
        observationCrewMembers {
          crewMember {
            firstName
            lastName
            email
          }
        }
        observationArms {
          isCorrection
          arm {
            a {
              name
            }
            r {
              name
            }
            m {
              name
            }
          }
          observationArmMediaFiles(isActive: true) {
            id
          }
        }
      }
    }
  }
`;

// OSP
export const GET_OSP = gql`
  query OSPId($ownerId: Int, $siteId: Int, $projectId: Int) {
    ownerSiteProjects(
      owner_Id: $ownerId
      site_Id: $siteId
      project_Id: $projectId
    ) {
      id
    }
  }
`;

export const GET_FILTERED_OSP = gql`
  query OSPOffline($ownerId: String!, $siteId: String!, $projectId: String!) {
    filterOSP(ownerId: $ownerId, siteId: $siteId, projectId: $projectId)
      @client {
      id
    }
  }
`;

export const GET_OWNERSITE = gql`
  query OwnerSite($ownerId: Int!, $siteId: Int!) {
    ownerSiteProjects(
      owner_Id: $ownerId
      site_Id: $siteId
      project_Isnull: true
    ) {
      id
    }
  }
`;

// OUTCOMES - OBS
export const GET_OUTCOMES = gql`
  query outcomes {
    outcomes(isActive: true) {
      name
      id
    }
  }
`;

// SITES
export const GET_SITES = gql`
  query sites {
    ownerSiteProjects(
      site_Isnull: false
      project_Isnull: true
      isActive: true
      owner_IsActive: true
      site_IsActive: true
    ) {
      id
      owner {
        id
        name
      }
      site {
        id
        name
      }
      location @client
    }
  }
`;

// PROJECTS
export const GET_PROJECT_BY_OWNERSITE = gql`
  query projectsBySite($siteId: Int, $ownerId: Int) {
    ownerSiteProjects(
      site_Id: $siteId
      owner_Id: $ownerId
      project_Isnull: false
      isActive: true
      owner_IsActive: true
      site_IsActive: true
      project_IsActive: true
    ) {
      id
      owner {
        id
        name
      }
      site {
        id
        name
      }
      project {
        id
        name
      }
    }
  }
`;

// USERS
export const GET_USERNAMES = gql`
  query usernames {
    users {
      username
      firstName
      lastName
      id
      image
    }
  }
`;
