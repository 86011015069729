import * as React from "react";

import { CircularProgress, Grid } from "@mui/material";

import { useQuery, useReactiveVar } from "@apollo/client";

import { onlineVar } from "../../../../../../../../graphql/localVariables/user";
import { GET_COMPANY_USERS } from "../../../../../../../../graphql/queries/admin/company";
import TableMain from "./ReusableTableLayout";
import ReusableTableToolbar from "./ReusableTableToolbar";
import TableBodyTemplate from "./TableBodyTemplate";
import TableHeadTemplate from "./TableHeadTemplate";
import { filterData } from "./searchFilterFunction";

// Props
// hasPagination - boolean
// data - array of objects
// 3 children component
//    If using the ReusableTableToolbar
//    - title - string - displayed at the top
//    - onDeleteClick - when trash can on toolbar is clicked
//    - onSearchChange - when input in search bar
//    - searchTerm - string
export default function Table({ company }) {
  const online = useReactiveVar(onlineVar);
  const { data, loading } = useQuery(GET_COMPANY_USERS, {
    variables: { companyId: Number(company.id) },
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  const onSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const onGroupChange = (event, newValue) => {
    setGroupsFilter(newValue || []);
  };

  const onInactiveChange = (event) => {
    setInActive(event.target.checked);
  };

  const clearFilters = () => {
    setGroupsFilter([]);
    setInActive(false);
  };

  const [searchTerm, setSearchTerm] = React.useState("");
  const [inactive, setInActive] = React.useState(false);
  const [groupsFilter, setGroupsFilter] = React.useState([]);

  const [filteredData, setFilteredData] = React.useState([]);

  // useEffect for filter update
  React.useEffect(() => {
    if (data) {
      setFilteredData(
        filterData(data.users, searchTerm, groupsFilter, inactive)
      );
    }
  }, [data, searchTerm, groupsFilter]);

  return (
    <>
      {loading ? (
        <Grid container justifyContent="center">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <TableMain
          CustomTableHeader={<TableHeadTemplate />}
          CustomTableBody={<TableBodyTemplate />}
          CustomTableToolBar={
            <ReusableTableToolbar
              searchTerm={searchTerm}
              onSearchChange={onSearchChange}
              groupsFilter={groupsFilter}
              onGroupChange={onGroupChange}
              inactive={inactive}
              onInactiveChange={onInactiveChange}
              clearFilters={clearFilters}
              title={"Users"}
            />
          }
          hasPagination={true}
          data={filteredData}
        />
      )}
    </>
  );
}
