import React from "react";

import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

import { useReactiveVar } from "@apollo/client";

import { permissionsVar } from "../../../../../../graphql/localVariables/user";
import { onErrorFunc } from "../../../../../CustomComponents/OnErrorFunction";
import CreateTemplate from "../../../../Planning/Template/CreateTemplate";
import CreateCompanyDialog from "../../../View/CreateCompanyDialog";
import useStyles from "../styles";
import CreateCrewDialog from "./CreateCrewDialog";
import CreateObservedSiteDialog from "./CreateObservedSiteDialog";
import CreateOwnedSiteDialog from "./CreateOwnedSiteDialog";
import CreateProjectDialog from "./CreateProjectDialog";
import CreateSupervisorDialog from "./CreateSupervisorDialog";
import CreateUserDialog from "./CreateUserDialog";

export default function CreateDialog({ title, data, company, extraData }) {
  const classes = useStyles();
  const permissions = useReactiveVar(permissionsVar);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {}, [company]);

  const renderChild = () => {
    switch (title) {
      case "Users":
        return (
          <CreateUserDialog
            open={open}
            setOpen={setOpen}
            title={title}
            data={data}
            company={company}
          />
        );
      case "Supervisors":
        return (
          <CreateSupervisorDialog
            open={open}
            setOpen={setOpen}
            title={title}
            data={data}
            company={company}
            existingSupervisorIds={data.map((u) => u.id)}
          />
        );
      case "Owned Sites":
        return (
          <CreateOwnedSiteDialog
            open={open}
            setOpen={setOpen}
            title={title}
            company={company}
            extraData={extraData}
          />
        );
      case "Contractors":
        return <CreateCompanyDialog open={open} setOpen={setOpen} />;
      case "Projects":
        return (
          <CreateProjectDialog
            open={open}
            setOpen={setOpen}
            title={title}
            data={data}
            company={company}
          />
        );
      case "CrewMembers":
        return (
          <CreateCrewDialog
            open={open}
            setOpen={setOpen}
            title={title}
            data={data}
            company={company}
          />
        );
      case "Templates":
        return (
          <CreateTemplate
            open={open}
            handleClose={() => setOpen(false)}
            company={company}
          />
        );
      case "Observed Sites":
        return (
          <CreateObservedSiteDialog
            open={open}
            handleClose={() => setOpen(false)}
            company={company}
            data={data}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <AddCircleRoundedIcon
        className={classes.cardAddIcon}
        onClick={() => {
          if (
            (title === "Users" && permissions.includes("CREATE_USER")) ||
            (title === "Owned Sites" && permissions.includes("CREATE_SITE")) ||
            (title === "Templates" &&
              permissions.includes("CREATE_TEMPLATE")) ||
            (title === "Projects" && permissions.includes("CREATE_PROJECT")) ||
            (title === "Contractors" &&
              permissions.includes("CREATE_COMPANY")) ||
            (title === "Supervisors" && permissions.includes("SUPERVISION")) ||
            (title === "CrewMembers" && permissions.includes("EDIT_SITE")) ||
            (title === "Observed Sites" && permissions.includes("EDIT_COMPANY"))
          ) {
            setOpen(true);
          } else {
            onErrorFunc(
              "Your account does not have the proper permissions to create this item. Please contact your administrator."
            );
          }
        }}
      />
      {company && renderChild()}
    </>
  );
}
